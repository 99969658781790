import React, { forwardRef } from 'react'
import { Button as ChakraButton, Box } from '@chakra-ui/react'

interface ButtonProps extends React.ComponentProps<typeof ChakraButton> {
  icon?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ icon, children, ...props }, ref) => {
  return (
    <ChakraButton
      ref={ref}
      m={0}
      size="lg"
      minWidth="100px"
      borderRadius="full"
      _focus={{ outline: 'none' }}
      {...props}
    >
      {children}
    </ChakraButton>
  )
})