import React, { useMemo } from 'react'
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { useStoreActions } from 'easy-peasy'
import { DateTime } from 'luxon'

import { ArrowRightIcon, HighRiskIcon } from '@blueprinthq/joy'
import { SessionRecapResponse } from '~/clinician-api/models'

interface CritivalEventsProps {
  clientId: string | undefined
  containerProps: any
  recap: SessionRecapResponse | undefined
}

export function CriticalEvents({
  clientId,
  recap,
  containerProps
}: CritivalEventsProps) {
  const { openModal: openAssessmentModal } = useStoreActions(
    (actions: any) => actions.modals.assessmentResult
  )

  const handleClick = (assessmentId: string, scoreId: string) => {
    openAssessmentModal({
      clientId: clientId,
      preselectedAssessmentId: assessmentId,
      preselectedAssessmentScoreId: scoreId
    })
  }

  const events = useMemo(() => {
    return recap?.criticalEvents.map(event => {
      // These categories are hardcoded in the 'trigger' table, they don't really make any sense...
      if (['High-risk', 'suicidality'].includes(event.category)) {
        return (
          <Box
            as="button"
            color={'#B5342A'}
            backgroundColor={'#FBE8E5'}
            borderRadius={'8px'}
            width={'100%'}
            p={'16px'}
            onClick={() =>
              handleClick(event.assessmentId, event.assessmentScoreId)
            }
          >
            <Flex
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <HStack spacing={'16px'}>
                <HighRiskIcon size="md" fill={'#B5342A'} />
                <Text>{`Elevated risk for suicidal ideation (${
                  event.assessmentName
                }, ${DateTime.fromISO(event.triggeredAt).toLocaleString({
                  month: 'long',
                  day: 'numeric'
                })})`}</Text>
              </HStack>
              <ArrowRightIcon size="md" fill={'#B5342A'} />
            </Flex>
          </Box>
        )
      }

      return null
    })
  }, [recap?.criticalEvents])

  if (recap?.criticalEvents.length === 0) return null

  return (
    <Box {...containerProps}>
      <VStack>{events}</VStack>
    </Box>
  )
}
