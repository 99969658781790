import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Button, TextField } from '@blueprinthq/joy'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { VStack, HStack, useToast, Heading } from '@chakra-ui/react'
import { endpoints } from '../../../../api'

const UpsertGeneralSchema = Yup.object().shape({
  clinic_name: Yup.string().required('Required')
})

export const ClinicName = ({ user, clinic }) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const hasAdminRole = user.roles.filter(
    role =>
      role.name === 'clinicadmin' ||
      role.name === 'superadmin' ||
      role.name === 'orgadmin'
  )
  const { mutateAsync: updateClinicName } = useMutation(
    endpoints.putClinicName.request,
    {
      onSuccess() {
        queryClient.invalidateQueries(endpoints.putClinicName.getCacheId())
        queryClient.invalidateQueries(
          endpoints.getOrganizationClinics.getCacheId()
        )
        toast({
          title: 'Clinic name updated',
          status: 'success',
          isClosable: true
        })
      },
      onError({ error }) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          isClosable: true
        })
      }
    }
  )

  return (
    <>
      <Formik
        validationSchema={UpsertGeneralSchema}
        validate={props => {
          const errors = {}
          if (props.clinic_name === '') {
            errors.clinic_name = 'Clinic Name is required.'
          }
          return errors
        }}
        initialValues={{
          clinic_name: clinic?.name || ''
        }}
        enableReinitialize
        onSubmit={async (values, actions) => {
          if (hasAdminRole.length === 0) {
            toast({
              title: 'You do not have permission to edit clinic name.',
              status: 'error',
              isClosable: true
            })
          } else {
            const data = {
              name: values.clinic_name
            }
            await updateClinicName({
              clinicId: clinic.id,
              data
            })
          }

          actions.setSubmitting(false)
        }}
      >
        {({ values, errors, touched, isSubmitting, isValid, dirty }) => (
          <Form>
            <Heading size="small">Clinic Name</Heading>
            <VStack spacing="small" alignItems="flex-start" mt="small">
              <HStack
                spacing="small"
                style={{ border: '1px solid #E4E5E6' }}
                borderRadius={'4px'}
                position="relative"
                p={'4'}
                height="48px"
                alignItems="center"
                width="100%"
              >
                <Field name="clinic_name">
                  {({ field }) => (
                    <TextField
                      {...field}
                      disabled={hasAdminRole.length === 0}
                      placeholder="Clinic Name"
                      variant="unstyled"
                    />
                  )}
                </Field>
              </HStack>
            </VStack>
            {hasAdminRole.length > 0 ? (
              <Button
                size="lg"
                type="submit"
                disabled={
                  values.clinic_name === '' ||
                  values.clinic_name === clinic?.name
                }
                marginTop={'small'}
              >
                Update
              </Button>
            ) : null}
          </Form>
        )}
      </Formik>
    </>
  )
}
