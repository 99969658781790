import React, { useContext } from 'react'
import { Text, Box } from '@chakra-ui/react'

import { WorkflowsContext } from '@context'
import { EditDrawerFooter } from '../../edit-drawer/edit-drawer-footer'
import { FormattedNode } from '../../format-nodes'

type Props = {
  onSubmit: (node: FormattedNode | undefined) => void
  closeDrawer: () => void
  onDelete: (node: FormattedNode | undefined) => void
}

export const MarkClientHighRisk = ({
  onSubmit,
  closeDrawer,
  onDelete
}: Props) => {
  const { selectedNode } = useContext(WorkflowsContext)

  return (
    <Box>
      <Text>
        The client will be labeled High Risk on their Blueprint Profile.
      </Text>
      <EditDrawerFooter
        onDelete={() => onDelete(selectedNode)}
        onCancel={closeDrawer}
        onSubmit={() => onSubmit(selectedNode)}
      />
    </Box>
  )
}
