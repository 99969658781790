import React, { useState } from 'react'
import {
  Box,
  Flex,
  useToast,
  Text,
  Button,
  useBreakpointValue,
  Container
} from '@chakra-ui/react'
import { DeleteConfirmation } from '@components'
import { copyToClipboard } from '@utilities'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { endpoints } from '@api'
import _ from 'lodash'
import moment from 'moment'
import { useStoreState } from 'easy-peasy'
import { CopyNoteIcon, TrashIcon } from '@icons'
import axios from 'axios'
import { RadioButtonLoading } from '@components/icons'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { useExperienceManager } from '@hooks'
import { Loading } from '@components'
import { isMobile, isDesktop, isChrome } from 'react-device-detect'

const formatTime = seconds => {
  const duration = moment.duration(seconds, 'seconds')
  const hours = duration
    .hours()
    .toString()
    .padStart(1, '0')
  const minutes = duration
    .minutes()
    .toString()
    .padStart(2, '0')
  const secondsFormatted = duration
    .seconds()
    .toString()
    .padStart(2, '0')

  return `${hours}:${minutes}:${secondsFormatted}`
}

const formatTranscript = utterances => {
  const parts = []
  let currentObject = null

  for (const utterance of utterances) {
    if (currentObject && utterance.speaker === currentObject.speaker) {
      currentObject.transcript += ` ${utterance.transcript}`
    } else {
      if (currentObject !== null) {
        parts.push(currentObject)
      }

      currentObject = {
        speaker: utterance.speaker,
        transcript: utterance.transcript,
        start: formatTime(utterance.start)
      }
    }
  }

  if (currentObject !== null) {
    parts.push(currentObject)
  }

  return parts
}

const Transcript = ({ sessionId, client }) => {
  const [rawTranscript, setRawTranscript] = useState('')
  const [transcriptError, setTranscriptError] = useState('')
  const [transcriptHasLoaded, setTranscriptHasLoaded] = useState(false)
  const [transcript, setTranscript] = useState([])
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  )
  const { height: paywallBannerHeight } = useStoreState(
    state => state.paywallBanner
  )
  const { isExtension, isWidget } = useExperienceManager()
  const queryClient = useQueryClient()
  const toast = useToast()
  const showTranscriptCopy = flagsmith.hasFeature(
    FlagsmithFeatures.TRANSCRIPT_COPY
  )
  const showText = useBreakpointValue({ base: false, md: true })

  const buttonSpacing = useBreakpointValue({ base: '8px', sm: '16px' })
  const isIntegratedUI = isExtension || isWidget

  const {
    data: sessionTranscriptData,
    isLoading: isLoadingTranscript,
    isFetching: isFetchingTranscript
  } = useQuery(
    [endpoints.getSessionTranscript.getCacheId(), sessionId],
    async () => {
      const response = await endpoints.getSessionTranscript.request({
        sessionId
      })
      const url = response?.sessionTranscript?.url

      if (!url) {
        return response
      }

      if (url) {
        const res = await axios.get(url)

        setRawTranscript(
          res?.data?.results?.channels?.[0]?.alternatives?.[0]?.transcript || ''
        )
        setTranscript(formatTranscript(res?.data?.results?.utterances || []))
        setTranscriptHasLoaded(true)
      }

      return response
    },
    {
      refetchInterval: data => {
        if (data?.sessionTranscript?.isLoading) {
          return 5000
        }

        return false
      }
    }
  )

  const {
    mutate: deleteSessionTranscript,
    isLoading: isDeletingTranscript
  } = useMutation(endpoints.deleteSessionTranscript.request, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        endpoints.getSessionTranscript.getCacheId(),
        sessionId
      ])
      queryClient.invalidateQueries(
        endpoints.canGenerateTxPlan.getCacheId(client?.id)
      )
      queryClient.setQueryData(
        [endpoints.getSessionTranscript.getCacheId(), sessionId],
        oldData => ({
          sessionTranscript: null
        })
      )
      setRawTranscript('')
      setTranscript([])
      setTranscriptHasLoaded(false)
      setIsDeleteConfirmationOpen(false)
      toast({
        description: 'Transcript deleted!',
        status: 'success',
        isClosable: true,
        duration: 2000
      })
    }
  })

  const isSafariOrChrome = isMobile && !isDesktop ? (isChrome ? 107 : 85) : 0
  const notePreferencesBaseHeight = showText ? 205 : 200 + isSafariOrChrome

  const renderTranscript = () => {
    return transcript.map((item, index) => {
      const { transcript, speaker, start } = item
      return (
        <Flex key={index} gap="8px">
          <Flex
            gap="4px"
            {...(isIntegratedUI ? { flexDirection: 'column' } : {})}
          >
            <Text minWidth="65px" color="medium_gray">
              {start}
            </Text>
            <Text minWidth="100px">Speaker #{speaker + 1}</Text>
          </Flex>
          <Flex flexGrow="1">
            <Text>{transcript}</Text>
          </Flex>
        </Flex>
      )
    })
  }

  const handleCopyRawTranscript = () => {
    copyToClipboard(rawTranscript)
  }

  const sessionTranscript = sessionTranscriptData?.sessionTranscript

  if (transcriptHasLoaded) {
    return (
      <>
        <Container
          height={
            isExtension || isWidget
              ? 'calc(100vh - 178px)'
              : `calc(100vh - ${notePreferencesBaseHeight}px - ${paywallBannerHeight}px)`
          }
          overflowY="scroll"
        >
          <Flex flexDirection="column" gap="16px" paddingY="16px">
            <Box display="flex" flexDirection="column" gap="16px" pb="16px">
              {renderTranscript()}
            </Box>
          </Flex>
        </Container>
        <Flex
          flexDirection="column"
          py="16px"
          px={isMobile ? '16px' : '32px'}
          borderTop="1px solid"
          borderColor="pale_gray"
        >
          <Flex
            justifyContent={isMobile ? 'space-between' : 'flex-end'}
            flexDirection="row"
            width="100%"
            gap={isMobile ? '8px' : '0px'}
          >
            {showTranscriptCopy && (
              <Button
                id="pendo-copy-transcript"
                onClick={handleCopyRawTranscript}
                borderRadius="4px"
                h="35px"
                m={`0 0 0 0`}
                flex={isMobile ? '1' : 'initial'}
                width={isMobile ? '50%' : 'auto'}
              >
                {!isIntegratedUI && <CopyNoteIcon />}
                <Text ml="xxsmall">
                  {isIntegratedUI || isMobile ? 'Copy' : 'Copy transcript'}
                </Text>
              </Button>
            )}
            <Button
              variant="outline"
              borderRadius="4px"
              h="35px"
              m={`0 0 0 ${buttonSpacing}`}
              _hover={{ opacity: 0.7 }}
              _focus={{ outline: 'none' }}
              onClick={() => setIsDeleteConfirmationOpen(true)}
              flex={isMobile ? '1' : 'initial'}
              width={isMobile ? '50%' : 'auto'}
            >
              <Flex align="center" justify="space-between" gap="8px">
                <TrashIcon fill="#EB5164" />
                <Text>Delete</Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>
        <DeleteConfirmation
          isOpen={isDeleteConfirmationOpen}
          onClose={() => setIsDeleteConfirmationOpen(false)}
          onConfirm={() =>
            deleteSessionTranscript({ id: sessionTranscript.id })
          }
          isConfirming={isDeletingTranscript}
          header="Delete this transcript?"
          text="Deleting the transcript for this session will disable your ability to change the note type or preferences, use the magic edit feature, and regenerate the note."
          confirmText="Delete transcript"
        />
      </>
    )
  }

  if (sessionTranscript?.url && !transcriptHasLoaded) {
    return (
      <Box mt="32px">
        <Loading />
      </Box>
    )
  }

  if (
    sessionTranscript?.isLoading ||
    isLoadingTranscript ||
    isFetchingTranscript
  ) {
    return (
      <Flex
        p="48px 32px"
        flexDirection="column"
        border="1px solid"
        borderColor="pale_gray"
        borderRadius="8px"
        mt="32px"
        mx={{
          base: '16px',
          sm: '16px',
          md: '32px'
        }}
        mb={isIntegratedUI ? '16px' : '0px'}
      >
        <Text textAlign="center" fontWeight="bold" mb="xxsmall">
          {`Transcribing audio...`}
        </Text>
        <Text textAlign="center" color="#757575">
          This process could take up to 1 minute
        </Text>
        <Box h="16px" />
        <Flex flexDirection="column" alignSelf="center" gap="16px">
          <RadioButtonLoading />
        </Flex>
      </Flex>
    )
  }

  if (!sessionTranscript) {
    return (
      <Flex
        p="16px"
        border="1px solid"
        height="200px"
        borderColor="pale_gray"
        borderRadius="8px"
        justifyContent="center"
        alignItems="center"
        color="#757575"
        mt="32px"
        mx={{
          base: '16px',
          sm: '16px',
          md: '32px'
        }}
        mb={isIntegratedUI ? '16px' : '0px'}
      >
        <Text>No transcript available</Text>
      </Flex>
    )
  }

  return (
    <>
      <Container
        height={
          isExtension || isWidget
            ? 'calc(100vh - 165px)'
            : `calc(100vh - ${notePreferencesBaseHeight}px - ${paywallBannerHeight}px)`
        }
        overflowY="scroll"
      >
        <Flex flexDirection="column" gap="16px" paddingY="16px">
          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            pb="16px"
            overflowY="scroll"
          >
            {renderTranscript()}
          </Box>
        </Flex>
      </Container>
      <Flex
        flexDirection="column"
        py="16px"
        px={isMobile ? '16px' : '32px'}
        borderTop="1px solid"
        borderColor="pale_gray"
      >
        <Flex
          justifyContent={isMobile ? 'space-between' : 'flex-end'}
          flexDirection="row"
          width="100%"
          gap={isMobile ? '8px' : '0px'}
        >
          {showTranscriptCopy && (
            <Button
              onClick={handleCopyRawTranscript}
              borderRadius="4px"
              h="35px"
              m={`0 0 0 0`}
              flex={isMobile ? '1' : 'initial'}
              width={isMobile ? '50%' : 'auto'}
            >
              {!isIntegratedUI && <CopyNoteIcon />}
              <Text ml="xxsmall">
                {isIntegratedUI || isMobile ? 'Copy' : 'Copy transcript'}
              </Text>
            </Button>
          )}
          <Button
            variant="outline"
            borderRadius="4px"
            h="35px"
            m={`0 0 0 ${buttonSpacing}`}
            _hover={{ opacity: 0.7 }}
            _focus={{ outline: 'none' }}
            onClick={() => setIsDeleteConfirmationOpen(true)}
            flex={isMobile ? '1' : 'initial'}
            width={isMobile ? '50%' : 'auto'}
          >
            <Flex align="center" justify="space-between" gap="8px">
              <TrashIcon fill="#EB5164" />
              <Text>Delete</Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        isLoading={isDeletingTranscript}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={() => deleteSessionTranscript({ id: sessionTranscript.id })}
      />
    </>
  )
}

export default Transcript
