import React, { useMemo } from 'react'
import {
  Heading,
  Text,
  Box,
  Link,
  Center,
  Stack,
  HStack,
  StackDivider
} from '@chakra-ui/react'
import moment from 'moment'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { DownloadPdfButton, DiscardButton } from './components'
import { isMobile } from 'react-device-detect'
import { Loading } from '@components'
import { endpoints } from '../../api/index'
import { sortBy } from 'lodash'
import { Button } from '@blueprinthq/joy'
import { useStoreActions } from 'easy-peasy'

import { InterventionRecommendations } from '../interventions/intervention-recommendations-summary'

export default function InterventionSelector({ patient }) {
  const location = useLocation()
  const [baseUrl] = location.pathname.split('/settings')

  const openClientInterventionListModal = useStoreActions(
    actions => actions.modals.clientInterventionList.openModal
  )

  const {
    isLoading: areInterventionsLoading,
    data: allInterventions,
    refetch: refetchInterventions
  } = useQuery(
    [endpoints.getAllInterventions.getCacheId(), patient.id],
    () =>
      endpoints.getAllInterventions.request({
        clientId: patient.id
      }),
    {
      placeholderData: []
    }
  )

  const sortedInterventions = useMemo(() => sortBy(allInterventions, 'name'), [
    allInterventions
  ])

  if (areInterventionsLoading || allInterventions.length < 1) {
    return <Loading />
  }

  function getIncompleteSubmissions(activity) {
    const incompleteSubmissions = activity.submissions.filter(
      submission => !submission.isComplete
    )
    activity.submissions = incompleteSubmissions
    return activity
  }

  function getAllIncompleteSubmissions(data) {
    return data
      .map(activity => {
        return getIncompleteSubmissions({ ...activity })
      })
      .filter(activity => activity.submissions.length > 0)
  }

  const filteredIncompleteSubmissions = getAllIncompleteSubmissions(
    sortedInterventions
  )

  function getCompleteSubmissions(activity) {
    const incompleteSubmissions = activity.submissions.filter(
      submission => submission.isComplete
    )
    activity.submissions = incompleteSubmissions
    return activity
  }

  function getAllCompleteSubmissions(data) {
    return data
      .map(activity => {
        return getCompleteSubmissions({ ...activity })
      })
      .filter(activity => activity.submissions.length > 0)
  }

  const filteredCompletedSubmissions = getAllCompleteSubmissions(
    sortedInterventions
  )

  // Function to get the latest updatedTimestamp from submissions array
  function getLatestSubmissionTimestamp(activity) {
    const latestTimestamp = activity.submissions.reduce(
      (latest, submission) => {
        const timestamp = new Date(submission.updatedTimestamp).getTime()
        return timestamp > latest ? timestamp : latest
      },
      0
    )
    return latestTimestamp
  }

  // Sorting activities based on the latest updatedTimestamp in submissions
  filteredIncompleteSubmissions.sort((a, b) => {
    const aLatestTimestamp = getLatestSubmissionTimestamp(a)
    const bLatestTimestamp = getLatestSubmissionTimestamp(b)
    return bLatestTimestamp - aLatestTimestamp
  })

  // Sorting activities based on the latest updatedTimestamp in submissions
  filteredCompletedSubmissions.sort((a, b) => {
    const aLatestTimestamp = getLatestSubmissionTimestamp(a)
    const bLatestTimestamp = getLatestSubmissionTimestamp(b)
    return bLatestTimestamp - aLatestTimestamp
  })

  return (
    <div id="pendo-intervention-list-container-tag">
      <HStack justify={'space-between'}>
        <Heading>Interventions</Heading>
        <Button
          onClick={() => {
            openClientInterventionListModal({
              clientId: patient.id,
              interventions: sortedInterventions
            })
          }}
        >
          Start Intervention
        </Button>
      </HStack>
      <Box>
        {/* Recommended */}

        <InterventionRecommendations
          clientId={patient.id}
          openInNewTab={true}
        />

        {/* In Progress */}
        {filteredIncompleteSubmissions.length !== 0 && (
          <Center mt={'24px'}>
            <Stack flex="1">
              <Stack mb={'16px'} spacing="1">
                <Text textStyle="lg" fontWeight="bold">
                  In Progress
                </Text>
              </Stack>
              <Box
                bg="bg.surface"
                border={'1px solid #E4E5E6'}
                borderRadius={'8px'}
              >
                <Stack divider={<StackDivider />} spacing="0">
                  {filteredIncompleteSubmissions.map(i => {
                    return i.submissions.map(is => {
                      return (
                        <>
                          <Box
                            key={is.id}
                            value={is.id}
                            bg="bg.surface"
                            p="4"
                            borderBottom={'1px solid #E4E5E6'}
                            _last={{ borderBottomWidth: '0px' }}
                            position="relative"
                          >
                            <Stack shouldWrapChildren spacing="4">
                              <HStack justify="space-between">
                                <HStack spacing="3">
                                  <Text
                                    textStyle="sm"
                                    fontWeight="medium"
                                    color="fg.emphasized"
                                    minWidth={!isMobile ? '150px' : '0px'}
                                  >
                                    {moment(is.updatedTimestamp).format(
                                      'ddd, MMM Do, YYYY'
                                    )}
                                  </Text>

                                  <Text
                                    textStyle="sm"
                                    fontWeight="medium"
                                    color="fg.emphasized"
                                  >
                                    {i.name}
                                  </Text>
                                </HStack>
                                <HStack>
                                  <DiscardButton
                                    intervention={i}
                                    clientIntervention={is}
                                    patient={patient}
                                    refetch={() => refetchInterventions()}
                                  />
                                  <Link
                                    id={`pendo-intervention-start-tag-${i.id}`}
                                    as={RouterLink}
                                    color="primary"
                                    ml={!isMobile ? '24px' : '0px'}
                                    _hover={{
                                      textDecoration: 'none'
                                    }}
                                    to={{
                                      pathname: `/patient/${patient.id}/interventions/${i.id}/form`,
                                      search: `?clientInterventionId=${is.id}`
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Button>
                                      <Text fontSize={'16px'}>Continue</Text>
                                    </Button>
                                  </Link>
                                </HStack>
                              </HStack>
                            </Stack>
                          </Box>
                        </>
                      )
                    })
                  })}
                </Stack>
              </Box>
            </Stack>
          </Center>
        )}

        {/* Completed  */}
        <Center mt={'24px'}>
          <Stack flex="1">
            <Stack mb={'16px'} spacing="1">
              <Text textStyle="lg" fontWeight="bold">
                Completed
              </Text>
            </Stack>
            <Box
              bg="bg.surface"
              border={'1px solid #E4E5E6'}
              borderRadius={'8px'}
            >
              <Stack spacing="0">
                {filteredCompletedSubmissions.length !== 0 &&
                  filteredCompletedSubmissions.map(i => {
                    return i.submissions.map(is => {
                      return (
                        <>
                          <Box
                            key={i.id}
                            value={i.id}
                            bg="bg.surface"
                            p="4"
                            _last={{ borderBottomWidth: '0px' }}
                            borderBottom={'1px solid #E4E5E6'}
                            position="relative"
                          >
                            <Stack shouldWrapChildren spacing="4">
                              <HStack justify="space-between">
                                <HStack spacing="3">
                                  <Text
                                    textStyle="sm"
                                    fontWeight="medium"
                                    color="fg.emphasized"
                                    minWidth={!isMobile ? '150px' : '0px'}
                                  >
                                    {moment(is.completedTimestamp).format(
                                      'ddd, MMM Do, YYYY'
                                    )}
                                  </Text>
                                  <Text
                                    textStyle="sm"
                                    fontWeight="medium"
                                    color="fg.emphasized"
                                  >
                                    {i.name}
                                  </Text>
                                </HStack>
                                <HStack>
                                  <DownloadPdfButton
                                    interventionName={i.name}
                                    clientInterventionId={is.id}
                                    interventionId={i.id}
                                    patient={patient}
                                  />
                                  <Link
                                    id={`pendo-intervention-start-tag-${i.id}`}
                                    as={RouterLink}
                                    ml={!isMobile ? '24px' : '0px'}
                                    _hover={{
                                      textDecoration: 'none'
                                    }}
                                    to={{
                                      pathname: `/patient/${patient.id}/interventions/${i.id}/form`,
                                      search: `?clientInterventionId=${is.id}`
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Button
                                      bgColor={'inherit'}
                                      color={'black'}
                                      border={'1px solid #E4E5E6'}
                                      paddingLeft={'6'}
                                      paddingRight={'6'}
                                    >
                                      <Text fontSize={'16px'}>View</Text>
                                    </Button>
                                  </Link>
                                </HStack>
                              </HStack>
                            </Stack>
                          </Box>
                        </>
                      )
                    })
                  })}

                {filteredCompletedSubmissions.length === 0 && (
                  <Box
                    bg="bg.surface"
                    p="4"
                    borderBottom={'1px solid #E4E5E6'}
                    _last={{ borderBottomWidth: '0px' }}
                    position="relative"
                  >
                    No completed interventions.
                  </Box>
                )}
              </Stack>
            </Box>
          </Stack>
        </Center>
      </Box>
    </div>
  )
}
