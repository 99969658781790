import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { makeStyles } from '@material-ui/core'
import {
  HStack,
  useOutsideClick,
  Button,
  Flex,
  Text,
  Box,
  MenuButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody
} from '@chakra-ui/react'
import { KeyboardDatePicker as MuiDatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import { ArrowDownIcon } from '@blueprinthq/joy'
import CalendarToday from '@material-ui/icons/CalendarToday'
import { endpoints } from '@api'
import moment from 'moment'
import { Chip } from './chip'
import { SelectInput } from './select-input'

const useDatePickerStyles = makeStyles(() => ({
  notchedOutline: {
    borderColor: 'black'
  }
}))

const DatePicker = ({
  label,
  value,
  onChange,
  shouldDisableDate,
  onOpen,
  onClose
}) => {
  const classes = useDatePickerStyles()

  const handleChange = moment => {
    if (!moment) onChange(null)

    if (moment && moment.isValid()) {
      onChange(moment.format('YYYY-MM-DD'))
    }
  }

  return (
    <MuiDatePicker
      onOpen={onOpen}
      onClose={onClose}
      disableToolbar
      inputVariant="outlined"
      variant="inline"
      label={label}
      value={value}
      onChange={handleChange}
      onAccept={handleChange}
      format="MM/DD/YYYY"
      shouldDisableDate={shouldDisableDate}
      autoOk
      size="small"
      keyboardIcon={
        <CalendarToday style={{ color: 'black', width: 18, height: 18 }} />
      }
      KeyboardButtonProps={{
        disableRipple: true,
        disableFocusRipple: true,
        size: 'small'
      }}
      InputProps={{
        classes
      }}
      InputLabelProps={{
        shrink: true
      }}
    />
  )
}

const CustomMenuButton = ({
  label,
  onClick,
  chips,
  sx = {},
  isMenu = false
}) => {
  const inner = (
    <Flex alignItems="center">
      {label}
      <HStack p="2" spacing="6px">
        {chips}
      </HStack>
      <ArrowDownIcon width="1em" height="1em" />
    </Flex>
  )

  return isMenu ? (
    <MenuButton
      m="0"
      as={Button}
      variant="ghost"
      borderRadius="0"
      onClick={onClick}
      sx={{
        span: {
          pointerEvents: 'all'
        },
        ...sx
      }}
    >
      {inner}
    </MenuButton>
  ) : (
    <Button onClick={onClick} variant="ghost" sx={sx} m="0">
      {inner}
    </Button>
  )
}

const OrganizationSelector = ({ organizationId, onChange }) => {
  const { data, isLoading } = useQuery(
    endpoints.getAllOrganizations.getCacheId(),
    () => endpoints.getAllOrganizations.request(),
    {
      placeholderData: {
        organizations: []
      }
    }
  )

  const handleChange = evt => {
    onChange(evt.target.value)
  }

  const options = useMemo(
    () =>
      data.organizations.map(o => ({
        value: o.id,
        label: o.name
      })),
    [data]
  )

  return (
    <SelectInput
      value={organizationId}
      options={options}
      isLoading={isLoading}
      onChange={handleChange}
    />
  )
}

export const DateRangePicker = ({
  label,
  fromDate,
  toDate,
  fromLabel,
  toLabel,
  onFromDateChange,
  onToDateChange,
  helperText
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [allowClickOutside, setAllowClickOutside] = useState(true)

  const handleFromDateChange = date => {
    onFromDateChange(date)
  }

  const handleToDateChange = date => {
    onToDateChange(date)
  }

  const handleDatePickerOpen = () => setAllowClickOutside(false)

  const handleDatePickerClose = () => setAllowClickOutside(true)

  const ref = React.useRef()

  useOutsideClick({
    ref: ref,
    enabled: allowClickOutside,
    handler: () => setIsOpen(false)
  })

  const chips = (
    <>
      {fromDate && (
        <Chip
          label={`From: ${moment(fromDate).format('MM/DD/YY')}`}
          onClose={() => handleFromDateChange(null)}
        />
      )}
      {toDate && (
        <Chip
          label={`To: ${moment(toDate).format('MM/DD/YY')}`}
          onClose={() => handleToDateChange(null)}
        />
      )}
      {!fromDate && !toDate && <Chip label="Any" />}
    </>
  )

  return (
    <Box ref={ref}>
      <Popover isOpen={isOpen}>
        <PopoverTrigger>
          <Box>
            <CustomMenuButton
              label={label}
              chips={chips}
              onClick={() => setIsOpen(true)}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader></PopoverHeader>
          <PopoverBody>
            <Box>
              <Box display="flex" pb="2">
                <Box mr="3">
                  <DatePicker
                    label={fromLabel}
                    onOpen={handleDatePickerOpen}
                    onClose={handleDatePickerClose}
                    value={fromDate}
                    onChange={handleFromDateChange}
                    shouldDisableDate={date =>
                      toDate ? date.isAfter(toDate) : false
                    }
                  />
                </Box>
                <Box>
                  <DatePicker
                    label={toLabel}
                    onOpen={handleDatePickerOpen}
                    onClose={handleDatePickerClose}
                    value={toDate}
                    onChange={handleToDateChange}
                    shouldDisableDate={date =>
                      fromDate ? date.isBefore(fromDate) : false
                    }
                  />
                </Box>
              </Box>
              <Text fontSize="xs">{helperText}</Text>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

const BaselineScorePicker = ({
  minScore,
  maxScore,
  totalScore,
  onChange,
  helperText
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [internalMinScore, setMinScore] = useState('')
  const [internalMaxScore, setMaxScore] = useState('')
  const [internalMinScoreError, setInternalMinScoreError] = useState('')
  const [internalMaxScoreError, setInternalMaxScoreError] = useState('')

  useEffect(() => {
    validate()
  }, [internalMinScore, internalMaxScore])

  const ref = React.useRef()

  const handleClose = () => {
    if (!internalMinScoreError && !internalMaxScoreError) {
      onChange(internalMinScore, internalMaxScore)
      setIsOpen(false)
    }
  }

  useOutsideClick({
    ref: ref,
    enabled: isOpen,
    handler: handleClose
  })

  const chips = (
    <>
      {!!minScore && (
        <Chip
          label={`Min: ${minScore}`}
          onClose={() => {
            onChange(null, internalMaxScore)
            setMinScore('')
          }}
        />
      )}
      {!!maxScore && (
        <Chip
          label={`Max: ${maxScore}`}
          onClose={() => {
            onChange(internalMinScore, null)
            setMaxScore('')
          }}
        />
      )}
      {!minScore && !maxScore && <Chip label="Any" />}
    </>
  )

  const validate = () => {
    setInternalMinScoreError('')
    setInternalMaxScoreError('')

    if (Number(internalMinScore) > totalScore) {
      setInternalMinScoreError(`Cannot be greater than ${totalScore}`)
    }

    if (Number(internalMaxScore) > totalScore) {
      setInternalMaxScoreError(`Cannot be greater than ${totalScore}`)
    }

    if (internalMinScore && internalMaxScore) {
      if (Number(internalMinScore) > Number(internalMaxScore)) {
        setInternalMinScoreError('Cannot be greater than max score')
      }
      if (Number(internalMaxScore) < Number(internalMinScore)) {
        setInternalMaxScoreError('Cannot be less than min score')
      }
    }
  }

  return (
    <Box ref={ref}>
      <Popover isOpen={isOpen}>
        <PopoverTrigger>
          <Box>
            <CustomMenuButton
              label="Baseline Score"
              chips={chips}
              onClick={() => setIsOpen(true)}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader></PopoverHeader>
          <PopoverBody>
            <Box>
              <Box display="flex" pb="2">
                <Box mr="3">
                  <TextField
                    variant="outlined"
                    label="Score Min"
                    value={internalMinScore}
                    size="small"
                    placeholder="0"
                    onChange={e => setMinScore(e.target.value)}
                    error={!!internalMinScoreError}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Box>
                <Box>
                  <TextField
                    variant="outlined"
                    label="Score Max"
                    value={internalMaxScore}
                    size="small"
                    placeholder={`${totalScore}`}
                    onChange={e => setMaxScore(e.target.value)}
                    error={!!internalMaxScoreError}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Box>
              </Box>
              <Text fontSize="xs">{helperText}</Text>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export {
  OrganizationSelector,
  CustomMenuButton,
  BaselineScorePicker,
  SelectInput
}
