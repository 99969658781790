import { DateTime } from 'luxon'

export function formatTimePassedText(date: DateTime) {
  const now = DateTime.now()

  if (now.toISODate() === date.toLocal().toISODate()) {
    return `Today at ${date.toLocaleString(DateTime.TIME_SIMPLE)}`
  } else if (now.minus({ day: 1 }).toISODate() === date.toLocal().toISODate()) {
    return `Yesterday at ${date.toLocaleString(DateTime.TIME_SIMPLE)}`
  } else {
    return `${date.toLocal().toFormat('LLL d')} at ${date.toLocaleString(
      DateTime.TIME_SIMPLE
    )}`
  }
}

export function formatDuration(startedAt: string, completedAt: string) {
  const start = DateTime.fromISO(startedAt).set({ second: 0, millisecond: 0 });
  const end = DateTime.fromISO(completedAt).set({ second: 0, millisecond: 0 });
  const duration = end.diff(start, ['hours', 'minutes']).shiftTo('hours', 'minutes');

  const hours = Math.floor(duration.hours);
  const minutes = Math.round(duration.minutes);

  if (hours === 0 && minutes === 0) {
    return '0 min';
  }

  const hoursDisplay = hours > 0 ? `${hours} hr` : '';
  const minutesDisplay = minutes > 0 ? `${minutes} min` : '';

  return [hoursDisplay, minutesDisplay].filter(Boolean).join(' ');
}
