import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { useStoreActions } from 'easy-peasy'
import { LayoutGrid } from '@blueprinthq/joy'

import { endpoints } from '../../../api/index'
import { ProfileSettingsNav } from '../components'
import { PatientProfileHeader } from '../patient-profile/components/patient-header/patient-header'
import {
  Account,
  AssessmentSelector,
  CheckinModuleSelector,
  Loading
} from '../../../components'
import { Summary } from './components'
import { ClientStatusBuckets } from '../../../constants/clientStatusBuckets'
import { useExperienceManager } from '@hooks'

const useStyles = makeStyles({
  summaryContainer: {
    marginTop: 30,
    marginBottom: 30,
    padding: 10,
    maxWidth: 880
  },
  error: {
    width: 200,
    color: 'red'
  },
  settingsLink: {
    color: '#2d54e8',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',
    marginLeft: 5
  }
})

const TabElements = [
  {
    key: 'summary',
    display: 'Summary',
    path: '',
    matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+$|\/.*patient\/\w+-\w+-\w+-\w+-\w+\/$/g
  },
  {
    key: 'account',
    display: 'Client Info',
    path: '/settings/account',
    RouteComponent: Account,
    matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/account/g
  },
  {
    key: 'assessments',
    display: 'Assessments',
    path: '/settings/outcome_measures',
    RouteComponent: AssessmentSelector,
    matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/outcome_measures/g
  },
  {
    key: 'symptomTrackers',
    display: 'Symptom Trackers',
    path: '/settings/symptom_tracking',
    RouteComponent: CheckinModuleSelector,
    matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/symptom_tracking/g
  },
  {
    key: 'worksheets',
    display: 'Worksheets',
    path: '/settings/worksheets',
    RouteComponent: CheckinModuleSelector,
    matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/worksheets/g
  }
]

export function PreEnrollProfile(props) {
  const classes = useStyles()
  const location = useLocation()

  const history = useHistory()

  const id = props.computedMatch.params.id

  const openArchivePatientModal = useStoreActions(
    actions => actions.modals.archivePatient.openModal
  )
  const openAdjustEnrollmentDateModal = useStoreActions(
    actions => actions.modals.adjustEnrollmentDate.openModal
  )

  const { isLoading: isAccountLoading, data, refetch, isError } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), id],
    () => endpoints.getClinicianUserAccount.request({ id })
  )

  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  useEffect(() => {
    if (data) {
      if (
        data.status === ClientStatusBuckets.INVITE_SENT ||
        data.status === ClientStatusBuckets.ACTIVE ||
        data.status === ClientStatusBuckets.PENDING
      ) {
        history.replace(
          location.pathname.replace(
            'pre-enroll-patient',
            data.status === ClientStatusBuckets.PENDING
              ? 'pending-client'
              : 'patient'
          )
        )
      }
    }
  }, [data])

  if (isError) {
    return (
      <LayoutGrid
        justify="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <div className={classes.error}>Record could not be found.</div>
      </LayoutGrid>
    )
  }

  if (isAccountLoading) {
    return <Loading />
  }

  return (
    <div>
      <PatientProfileHeader
        patientId={id}
        tabs={TabElements}
        isEvidenceBasedCareEnabled={isEvidenceBasedCareEnabled}
      />
      <Switch>
        <Route
          exact
          path={'/pre-enroll-patient/:id/'}
          render={() => (
            <Summary
              preEnrollPatient={data}
              openAdjustEnrollmentDateModal={() =>
                openAdjustEnrollmentDateModal({ patientId: data.id })
              }
              openArchiveModal={() =>
                openArchivePatientModal({ patientId: data.id })
              }
            />
          )}
        />
        <Route
          path={'/pre-enroll-patient/:id/settings'}
          render={() => (
            <ProfileSettingsNav
              user_id={id}
              patient={data}
              refetchPatient={refetch}
              location={location}
              {...props}
              tabs={TabElements}
            />
          )}
        />
      </Switch>
    </div>
  )
}
