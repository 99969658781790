import React from 'react'

const EvidenceBasedCare = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M30 15.0001L27.65 12.6334L16.6667 23.6167L12.35 19.3167L10 21.6667L16.6667 28.3334L30 15.0001ZM31.6667 5.00008H24.7C24 3.06675 22.1667 1.66675 20 1.66675C17.8333 1.66675 16 3.06675 15.3 5.00008H8.33333C8.1 5.00008 7.88333 5.01675 7.66667 5.06675C7.01667 5.20008 6.43333 5.53341 5.98333 5.98341C5.68333 6.28341 5.43333 6.65008 5.26667 7.05008C5.1 7.43341 5 7.86675 5 8.33341V31.6667C5 32.1167 5.1 32.5667 5.26667 32.9667C5.43333 33.3667 5.68333 33.7167 5.98333 34.0334C6.43333 34.4834 7.01667 34.8167 7.66667 34.9501C7.88333 34.9834 8.1 35.0001 8.33333 35.0001H31.6667C33.5 35.0001 35 33.5001 35 31.6667V8.33341C35 6.50008 33.5 5.00008 31.6667 5.00008ZM20 4.58341C20.6833 4.58341 21.25 5.15008 21.25 5.83341C21.25 6.51675 20.6833 7.08341 20 7.08341C19.3167 7.08341 18.75 6.51675 18.75 5.83341C18.75 5.15008 19.3167 4.58341 20 4.58341ZM31.6667 31.6667H8.33333V8.33341H31.6667V31.6667Z"
      fill="#2D54E8"
    />
  </svg>
)

export default EvidenceBasedCare