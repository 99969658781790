import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Flex, Box, Text } from '@chakra-ui/react'
import { DateTime } from 'luxon'

import { usePatientControllerGetSessionList } from '~/clinician-api'
import { Chevron } from '@components/icons'
import { NavBar } from '@handlers/integration-ui/components'
import { Loading } from '@components'
import { formatDuration } from '@handlers/sessions/util'

export const ClientSessions = () => {
  const history = useHistory()
  const { clientId } = useParams<{ clientId: string }>()

  const { data: sessionList, isLoading } = usePatientControllerGetSessionList(
    clientId,
    {
      query: {
        initialData: []
      }
    }
  )

  if (isLoading) return <Loading />

  return (
    <>
      <NavBar showBackButton />
      {sessionList?.map((session: any, i: number) => {
        const isCompleted = !!session.completedAt
        const duration = formatDuration(session.startedAt, session.completedAt)

        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            m="8px"
            p="8px"
            borderBottom={i !== sessionList.length - 1 ? '1px solid' : 'none'}
            borderColor="pale_gray"
            _hover={{ cursor: 'pointer' }}
            maxWidth="750px"
            margin="0 auto"
            onClick={() =>
              history.push(
                `/widget/patient/${clientId}/${
                  isCompleted ? 'completed-' : ''
                }session/${session.id}`
              )
            }
          >
            <Box>
              <Text as="b">
                {DateTime.fromISO(session.startedAt).toFormat('EEE MMM dd')}
              </Text>
              <Text>
                {`${DateTime.fromISO(session.startedAt).toFormat('h:mma')} | ${
                  isCompleted
                    ? duration
                    : 'In Progress'
                }`}
              </Text>
            </Box>
            <Box w="16px">
              <Chevron />
            </Box>
          </Flex>
        )
      })}
    </>
  )
}
