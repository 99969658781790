export const NOTIFICATION_PREFERENCES = Object.freeze([
  {
    id: 'sms',
    display: 'Text Message'
  },
  {
    id: 'email',
    display: 'Email'
  },
  {
    id: 'push',
    display: 'Push Notification'
  },
  {
    id: 'none',
    display: 'None'
  }
])
