export const FlagsmithFeatures = Object.freeze({
  EXTENSION_AUTO_SELECT_CLIENTS: 'extension_auto_select_clients',
  CLIENT_PRONOUNS: 'client_pronouns',
  ASSIST: 'assist',
  EHR_INTEGRATION_ISSUES: 'ehr_integration_issues',
  EHR_APPOINTMENTS: 'ehr_appointments',
  EHR_INTEGRATION_CLIENT_OPT_IN: 'ehr_integration_client_opt_in',
  SHOW_APPOINTMENT_WORKFLOWS: 'show_appointment_workflows',
  SHOW_IS_DEMO_CLIENTS_IN_START_SESSION:
    'show_is_demo_clients_in_start_session',
  AUDIT_REPORT: 'audit_report',
  USE_DEMO_NOTES_WITH_EBC_CONTENT: 'use_demo_notes_with_ebc_content',
  TRANSCRIPT_COPY: 'transcript_copy',
  ALWAYS_SHOW_SAMPLE_SESSIONS: 'always_show_sample_sessions',
  EBC_BILLING: 'ebc_billing',
  CHIME_DISABLED: 'chime_disabled',
  SHOW_LIBRARY_NAV: 'show_library_nav',
  DIAGNOSIS_SUGGESTIONS: 'has_diagnosis_suggestions',
  AGE_RANGE: 'age_range',
  EDIT_CHECK_INS: 'edit_check_ins',
  CLIENT_CONSENT: 'client_consent',
  SYMPTOM_TRACKER_AND_WORKSHEETS_ENABLED:
    'symptom_tracker_and_worksheets_enabled',
  ASSIST_LAUNCH: 'assist_launch_release',
  DEMO_CLINICIAN: 'demo_clinician',
  DELETE_SESSIONS: 'delete_sessions',
  IMPROVE_SESSION_RECORDING: 'improve_session_recording_ui',
  INTEGRATED_UI_OVERVIEW: 'integrated_ui_overview',
  DELETE_CLIENTS: 'delete_clients',
  INTERVENTION_SUGGESTIONS: 'intervention_suggestions'
})
