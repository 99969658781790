import React from 'react'
import { Select, HStack } from '@chakra-ui/react'
import { Field, FormikErrors, useFormikContext } from 'formik'
import _ from 'lodash'
import { Assessment } from '../assessment-submitted-trigger'

type Props = {
  assessment: Assessment
  errors: FormikErrors<any>
  resetForm: (values: object) => void
  constants: any
}

type FormikContext = {
  values: {
    assessmentId: string | undefined
    percentChangeValue: string | undefined
    percentChangeOperator: string | undefined
    percentChangeReferenceScoreType: string | undefined
  }
  errors: FormikErrors<any>
}

export const PercentChangeFilter = ({
  resetForm,
  constants
}: Props) => {
  const { values, errors }: FormikContext = useFormikContext()
  const options = () => {
    const valueArray = _.range(1, 21)

    return valueArray.map(v => ({
      display: `${v * 5}%`,
      value: `${v * 5}`
    }))
  }

  return (
    <HStack spacing="small">
      <Field name="percentChangeValue">
        {({ field }: { field: { value: string } }) => (
          <Select
            {...field}
            value={field.value}
            w="100px"
            borderColor={errors?.percentChangeValue ? 'error' : 'light_gray'}
            color={field.value ? 'black' : 'gray'}
            onChange={e => {
              resetForm({
                ...values,
                percentChangeValue: e.target.value
              })
            }}
          >
            <option value="" disabled>
              Value
            </option>
            {options().map((o: { display: string; value: string }) => (
              <option key={o.value} value={o.value}>
                {o.display}
              </option>
            ))}
          </Select>
        )}
      </Field>
      <Field name="percentChangeOperator">
        {({ field }: { field: { value: string } }) => (
          <Select
            {...field}
            value={field.value}
            w="175px"
            borderColor={errors?.percentChangeOperator ? 'error' : 'light_gray'}
            color={field.value ? 'black' : 'gray'}
            onChange={e => {
              resetForm({
                ...values,
                percentChangeOperator: e.target.value
              })
            }}
          >
            <option value="" disabled>
              Operator
            </option>
            {constants.operators.map(
              (o: { display: string; value: string }) => (
                <option key={o.value} value={o.value}>
                  {o.display}
                </option>
              )
            )}
          </Select>
        )}
      </Field>
      <Field name="percentChangeReferenceScoreType">
        {({ field }: { field: { value: string } }) => (
          <Select
            {...field}
            value={field.value}
            w="175px"
            borderColor={
              errors?.percentChangeReferenceScoreType ? 'error' : 'light_gray'
            }
            color={field.value ? 'black' : 'gray'}
            onChange={e => {
              resetForm({
                ...values,
                percentChangeReferenceScoreType: e.target.value
              })
            }}
          >
            <option value="" disabled>
              Reference score
            </option>
            {constants.referenceScoreTypes.map(
              (o: { display: string; value: string }) => (
                <option key={o.value} value={o.value}>
                  {o.display}
                </option>
              )
            )}
          </Select>
        )}
      </Field>
    </HStack>
  )
}
