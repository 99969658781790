import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'

export const AssignWorksheet = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const { worksheets }: any = config
  const [firstWorksheet, ...otherWorksheets] = worksheets

  const body = (
    <Text>
      Assign the <Text as="b">{firstWorksheet.title}</Text>{' '}
      {otherWorksheets.map((tracker: { title: string; id: string }) => (
        <Text key={tracker.id}>
          and <Text as="b">{tracker.title}</Text>
        </Text>
      ))}{' '}
      worksheet{otherWorksheets.length ? 's' : ''}
    </Text>
  )

  return (
    <WorkflowNode
      heading="Assign Worksheet Action"
      body={body}
      isParent={isParent}
      hasChildren={hasChildren}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
