import { useCallback } from 'react'
import { useQuery } from 'react-query'

import { fetchPdf } from '../utilities'

export function usePdfDownloader({ url, pdfName }) {
  const { data, isSuccess, refetch } = useQuery(url, () => fetchPdf(url), {
    enabled: false,
    onSuccess(data) {
      saveToFile(data)
    }
  })

  const saveToFile = useCallback(
    pdfBuffer => {
      const link = document.createElement('a')
      link.href = pdfBuffer
      const fileName = `${pdfName}.pdf`
      link.download = fileName
      link.click()
    },
    [pdfName]
  )

  const download = useCallback(async () => {
    // If Pdf is in cache already
    if (data && isSuccess) {
      saveToFile(data)
    } else {
      await refetch()
    }
  }, [refetch, data, isSuccess])

  return { download }
}
