import React, { useState, useCallback, useMemo } from 'react'
import { Text, Link, Flex, useToast } from '@chakra-ui/react'
import { CheckCircle, CreateIcon } from '@blueprinthq/joy'
import moment from 'moment'
import { useMutation, useQueryClient } from 'react-query'
import { useStoreActions } from 'easy-peasy'
import { Link as RouterLink } from 'react-router-dom'

import { DiscardInterventionModal } from '../components'
import { usePdfDownloader } from '@hooks'
import { endpoints } from '@api'

export function ClientInterventionBox({
  clientIntervention,
  client,
  intervention
}) {
  const {
    isComplete,
    completedTimestamp,
    updatedTimestamp,
    id: clientInterventionId
  } = clientIntervention

  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false)

  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const toast = useToast()
  const queryClient = useQueryClient()
  const { mutateAsync: executeDeleteClientIntervention } = useMutation(
    endpoints.deletePartiallyCompletedIntervention.request,
    {
      onError: () => {
        toast({
          title: 'Error',
          description: 'There was an error discarding this activity',
          status: 'error',
          isClosable: true,
          duration: 2000,
          position: 'bottom-left'
        })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          endpoints.getInterventionOverview.getCacheId(),
          {
            clientId: client.id,
            interventionId: intervention.id
          }
        ])
      }
    }
  )

  const { download: downloadIntervention } = usePdfDownloader({
    url: `${process.env.REACT_APP_NODE_API_ROOT_URL}/clinicians/clients/${client.id}/interventions/${intervention.id}/submissions/${clientInterventionId}/download`,
    pdfName: `${client.last_name}_${
      client.first_name
    }_${intervention.name.replaceAll(' ', '_')}_${moment().format('MMDDYYYY')}`
  })

  const clientInterventionTimestamp = useMemo(() => {
    return moment(isComplete ? completedTimestamp : updatedTimestamp)
  }, [completedTimestamp, updatedTimestamp, isComplete])

  const handleCloseDiscardModal = () => {
    setIsDiscardModalOpen(false)
  }

  const discardClientIntervention = useCallback(async () => {
    await executeDeleteClientIntervention({
      clientId: client.id,
      interventionId: intervention.id,
      clientInterventionId
    })
  }, [clientIntervention, intervention, client])

  const downloadClientIntervention = useCallback(async () => {
    setSnackbarMessage({
      variant: 'info',
      message: 'Downloading...',
      duration: 10000,
      position: 'bottom-left'
    })
    try {
      await downloadIntervention()
      setSnackbarMessage({
        variant: 'success',
        message: 'Finished Download!',
        duration: 3000,
        position: 'bottom-left'
      })
    } catch (e) {
      setSnackbarMessage({
        variant: 'error',
        message: 'Error downloading PDF!',
        duration: 3000,
        position: 'bottom-left'
      })
    }
  })

  // this could be better
  const LinkSection = ({ links }) => {
    return (
      <Flex w="210px">
        <Link
          id="pendo-intervention-start-activity-tag"
          color="white"
          as={RouterLink}
          textDecoration="underline"
          fontSize="small"
          mr="xsmall"
          maxWidth={{ base: '43px', sm: '150px' }}
          to={{
            pathname: `/patient/${client.id}/interventions/${intervention.id}/form`,
            search: `?clientInterventionId=${clientInterventionId}`
          }}
          target="_blank"
          rel="noopener noreferrer"
          {...links[0].props}
        >
          {links[0].title}
        </Link>
        <Text alignSelf="center" color="white" fontSize="small">
          |
        </Text>
        <Text
          color="white"
          as={Link}
          alignSelf="center"
          textDecoration="underline"
          ml="xsmall"
          fontSize="small"
          maxWidth={{ base: '65px', sm: '150px' }}
          onClick={links[1].onClick}
          {...links[1].props}
        >
          {links[1].title}
        </Text>
      </Flex>
    )
  }

  return (
    <>
      <Flex
        align="center"
        w="100%"
        h="60px"
        p="18px 15px"
        bg={isComplete ? '#377957' : '#757575'}
        boxShadow="0px 4px 8px rgba(0, 0, 0, 0.16)"
        borderRadius="4px"
        mb="small"
      >
        {isComplete ? (
          <CheckCircle
            mr="small"
            fill="white"
            sx={{
              path: {
                fill: '#377957'
              }
            }}
          />
        ) : (
          <CreateIcon mr="small" fill="white" />
        )}
        <Text color="white" mr="xsmall" fontSize="small" whiteSpace="nowrap">
          {`${
            isComplete ? 'Completed' : 'Saved'
          } on ${clientInterventionTimestamp.format(
            'MM/DD/YYYY'
          )} at ${clientInterventionTimestamp.format('h:mm a')}.`}
        </Text>
        {isComplete ? (
          <LinkSection
            links={[
              {
                title: 'View results',
                id: 'pendo-intervention-view-results-tag'
              },
              {
                title: 'Download PDF',
                id: 'pendo-intervention-download-pdf-tag',
                onClick: downloadClientIntervention
              }
            ]}
          />
        ) : (
          <LinkSection
            links={[
              {
                title: 'Continue editing',
                id: 'pendo-intervention-continue-editing-tag',
                props: {
                  maxWidth: { base: '55px', sm: '150px' }
                }
              },
              {
                title: 'Discard',
                id: 'pendo-intervention-discard-tag',
                onClick: () => setIsDiscardModalOpen(true)
              }
            ]}
          />
        )}
      </Flex>
      <DiscardInterventionModal
        isOpen={isDiscardModalOpen}
        handleClose={handleCloseDiscardModal}
        discard={discardClientIntervention}
      />
    </>
  )
}
