import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  HStack
} from '@chakra-ui/react'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ClientAccountResponse, endpoints } from '@api'
import { useStoreState } from 'easy-peasy'
import { StoreModel } from 'src/store/types'

interface ProgramProps {
  patient: ClientAccountResponse
}

const getDateFormattedForPicker = (date: string | null) => {
  if (!date) {
    return ''
  }
  return DateTime.fromISO(date)
    .setZone('UTC')
    .toFormat('yyyy-MM-dd')
}

const formatDate = (date: string | null) =>
  date
    ? DateTime.fromISO(date)
        .setZone('UTC')
        .toFormat('LLL d, yyyy')
    : null

export const Programs: React.FC<ProgramProps> = ({ patient }) => {
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const organizationId = user?.clinic?.organization?.id

  const toast = useToast()
  const queryClient = useQueryClient()
  const [selectedProgramId, setSelectedProgramId] = useState('')
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editedStartDate, setEditedStartDate] = useState<string>(
    getDateFormattedForPicker(DateTime.local().toString())
  )
  const [editedEndDate, setEditedEndDate] = useState<string | null>(null)

  const handleSelectProgramChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedProgramId(event.target.value)
  }

  const refreshProgramList = () =>
    queryClient.invalidateQueries(
      endpoints.getClinicianUserAccount.getCacheId()
    )

  const { data } = useQuery(
    [endpoints.getPrograms.getCacheId(), organizationId],
    () =>
      endpoints.getPrograms.request({
        organizationId: organizationId!
      }),
    {
      enabled: !!organizationId
    }
  )

  const handleDeleteAttempt = (programId: string) => {
    setSelectedProgramId(programId)
    deletionModal.onOpen()
  }

  const deletionModal = useDisclosure()
  const handleDeleteConfirmation = () => {
    deletionModal.onClose()
    deleteProgramEnrollment({
      clientId: patient.id,
      programId: selectedProgramId
    })
    setSelectedProgramId('')
  }

  const { mutateAsync: deleteProgramEnrollment } = useMutation(
    endpoints.deleteProgramEnrollment.request,
    {
      onSuccess() {
        refreshProgramList()
        toast({
          title: 'Successfully removed client from the program',
          status: 'success',
          duration: 1500
        })
      },
      onError() {
        toast({
          title:
            'There was a failure attemting to remove client from the program',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  const creationModal = useDisclosure()
  const handleCreationConfirmation = () => {
    creationModal.onClose()
    createProgramEnrollment({
      clientId: patient.id,
      programId: selectedProgramId,
      startDate: editedStartDate,
      endDate: editedEndDate
    })
  }

  const { mutateAsync: createProgramEnrollment } = useMutation(
    endpoints.createProgramEnrollment.request,
    {
      onSuccess() {
        refreshProgramList()
        toast({
          title: 'Successfully added client to program',
          status: 'success',
          duration: 1500
        })
      },
      onError() {
        toast({
          title:
            'There was a failure attemting to assign client to the program',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  const handleEditModalOpen = (
    programId: string,
    startDate: string,
    endDate: string | null
  ) => {
    setSelectedProgramId(programId)
    setEditedStartDate(getDateFormattedForPicker(startDate))
    setEditedEndDate(getDateFormattedForPicker(endDate))
    setIsEditModalOpen(true)
  }

  const { mutateAsync: updateProgramEnrollment } = useMutation(
    endpoints.updateProgramEnrollment.request,
    {
      onSuccess() {
        refreshProgramList()
        toast({
          title: 'Updated program',
          status: 'success',
          duration: 1500
        })
      },
      onError() {
        toast({
          title: 'There was an error attempting to update the program',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  const handleEditModalSubmit = () => {
    updateProgramEnrollment({
      programId: selectedProgramId,
      clientId: patient.id,
      startDate: editedStartDate,
      endDate: editedEndDate
    })
    handleEditModalClose()
  }

  const handleEditModalClose = () => {
    setIsEditModalOpen(false)
    setSelectedProgramId('')
    setEditedStartDate('')
    setEditedEndDate('')
  }

  return (
    <Box>
      <HStack justify={'space-between'}>
        <Heading mb="xxsmall">Programs</Heading>
        <Button onClick={creationModal.onOpen}>Assign Programs</Button>
      </HStack>
      <Box
        border="1px solid"
        borderColor="#E4E5E6"
        borderRadius="8px"
        mt={'medium'}
        bg="white"
      >
        {patient?.programs && patient.programs.length > 0 ? (
          <Table variant="simple">
            <Thead></Thead>
            <Tbody>
              {patient.programs.map(program => (
                <Tr key={program.id}>
                  <Td whiteSpace="nowrap">{program.name}</Td>
                  <Td textAlign={'right'}>
                    <span style={{ marginRight: '16px' }}>
                      {`${formatDate(program.startDate)} - ${formatDate(
                        program.endDate
                      ) || 'Present'}`}
                    </span>
                    <Link
                      color={'#2D54E8'}
                      paddingRight="16px"
                      onClick={() =>
                        handleEditModalOpen(
                          program.id,
                          program.startDate,
                          program.endDate
                        )
                      }
                    >
                      Edit
                    </Link>
                    <Link
                      color={'#2D54E8'}
                      onClick={() => handleDeleteAttempt(program.id)}
                    >
                      Remove
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text w="100%" p={'16px 24px 16px 24px'} borderRadius={'8px'}>
            No assigned programs
          </Text>
        )}
      </Box>
      <Modal isOpen={creationModal.isOpen} onClose={creationModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign client to a program</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select a program"
              value={selectedProgramId}
              onChange={handleSelectProgramChange}
            >
              {data?.programs?.map(program => (
                <option key={program.id} value={program.id}>
                  {program.name}
                </option>
              ))}
            </Select>
            {selectedProgramId && (
              <>
                <FormControl mt="small">
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type="date"
                    value={editedStartDate}
                    onChange={e => setEditedStartDate(e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>End Date</FormLabel>
                  <Input
                    type="date"
                    value={editedEndDate || ''}
                    onChange={e => setEditedEndDate(e.target.value)}
                  />
                </FormControl>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={creationModal.onClose}>
              Cancel
            </Button>
            <Button mr={3} onClick={() => handleCreationConfirmation()}>
              Assign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={deletionModal.isOpen} onClose={deletionModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove from Program</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove the client from this program? This
            action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={deletionModal.onClose}>
              Cancel
            </Button>
            <Button mr={3} onClick={() => handleDeleteConfirmation()}>
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isEditModalOpen} onClose={handleEditModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Program Dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input
                type="date"
                value={editedStartDate}
                onChange={e => setEditedStartDate(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>End Date</FormLabel>
              <Input
                type="date"
                value={editedEndDate || ''}
                onChange={e => setEditedEndDate(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={handleEditModalClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleEditModalSubmit}>
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
