import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'

export const SendAppointmentReminder = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const body = <Text>Send appointment reminder to client</Text>

  return (
    <WorkflowNode
      heading="Send Appointment Reminder Action"
      body={body}
      hasChildren={hasChildren}
      isParent={isParent}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
