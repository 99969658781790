import moment from 'moment'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowRightIcon } from '@blueprinthq/joy'
import { Pagination } from '@material-ui/lab'
import {
  Box,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react'

import { useClinicControllerGetClientList } from '~/clinician-api'
import { useExperienceManager } from '@hooks'
import { ClientListDto } from '~/clinician-api/models'

type ClientTableProps = {
  clinicId: string
  clinicianId: string | null
  status: string[]
}

interface CellProps {
  children: React.ReactNode
  width?: string
}

const Cell = ({ children, width }: CellProps) => (
  <Td verticalAlign="middle" borderColor="#E4E5E6" width={width}>
    {children}
  </Td>
)

const HeaderCell = ({ children }: { children?: React.ReactNode }) => (
  <Th verticalAlign="middle" borderColor="#E4E5E6">
    {children}
  </Th>
)

export const ClientTable = ({
  clinicId,
  clinicianId,
  status
}: ClientTableProps) => {
  const [currentPage, setCurrentPage] = React.useState(1)

  useEffect(() => {
    setCurrentPage(1)
  }, [clinicId, clinicianId])

  const {
    isEvidenceBasedCareEnabled,
    isPlusPlanEnabled
  } = useExperienceManager()

  const history = useHistory()

  const {
    data: clientList = {
      clients: [],
      pageCount: 1,
      currentPage: 1
    } as ClientListDto,
    isLoading
  } = useClinicControllerGetClientList(
    clinicId,
    {
      clinicianId: clinicianId!,
      page: currentPage,
      pageSize: 50,
      statuses: status
    },
    {
      query: { placeholderData: { clients: [], pageCount: 1, currentPage: 1 } }
    }
  )

  if (isLoading || clientList === undefined) return <div>Loading</div>

  const navToClient = (clientId: string) => {
    history.push(`/patient/${clientId}/settings/sessions`)
  }

  return (
    <Box maxW="100%">
      <TableContainer>
        <Table
          border="1px solid"
          borderBottom="none"
          borderColor="#E4E5E6"
          borderRadius="4px"
          style={{ borderCollapse: 'separate' }}
          marginBottom="medium"
          size="sm"
        >
          <Thead>
            <Tr height="32px">
              <HeaderCell>First Name</HeaderCell>
              <HeaderCell>Last Name</HeaderCell>
              {isEvidenceBasedCareEnabled && <HeaderCell>DOB</HeaderCell>}
              {isPlusPlanEnabled && <HeaderCell>Email</HeaderCell>}
              {isPlusPlanEnabled && <HeaderCell>Phone</HeaderCell>}
              <HeaderCell></HeaderCell>
            </Tr>
          </Thead>
          <Tbody>
            {clientList?.clients!.map((client, i) => (
              <Tr
                cursor="pointer"
                key={i}
                onClick={() => {
                  navToClient(client.id)
                }}
                height="38px"
              >
                <Cell>{client.firstName}</Cell>
                <Cell>{client.lastName}</Cell>
                {isEvidenceBasedCareEnabled && (
                  <Cell>{moment(client.dateOfBirth).format('MM/DD/YYYY')}</Cell>
                )}
                {isPlusPlanEnabled && <Cell>{client.email}</Cell>}
                {isPlusPlanEnabled && <Cell>{client.phoneNumber}</Cell>}
                <Cell width="40px">
                  <ArrowRightIcon size="sm" />
                </Cell>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Box display="flex" flexDir="column" alignItems="center">
        <Pagination
          page={currentPage}
          count={clientList.pageCount}
          onChange={(evt, newPage) => {
            setCurrentPage(newPage)
          }}
          color="primary"
          variant="outlined"
          size="large"
          showFirstButton
          showLastButton
        />
      </Box>
    </Box>
  )
}
