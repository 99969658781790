import React from 'react'
import { Box, Flex, Button } from '@chakra-ui/react'
import { PaymentIcon } from 'react-svg-credit-card-payment-icons'
import { PaymentMethodDto } from '~/clinician-api/models'
import { capitalize } from 'lodash'

const PaymentDetails = ({
  paymentMethod,
  onChange
}: {
  paymentMethod: PaymentMethodDto
  onChange: () => void
}) => {
  const formatCard = (brand: string) => {
    return brand
      .split('_')
      .map(capitalize)
      .join(' ')
  }

  return (
    <Box border="1px solid #E4E5E6" borderRadius="4px" py="15px" px={4}>
      <Flex alignItems="center">
        <Box mr={4}>
          <PaymentIcon
            // @ts-ignore
            type={paymentMethod.brand}
            format="flat"
            width={38}
          />
        </Box>
        <Box flex="1">
          {formatCard(paymentMethod.brand)} •••• {paymentMethod.lastFourDigits}
        </Box>
        <Box mr={6}>
          Exp {paymentMethod.expMonth}/{paymentMethod.expYear}
        </Box>
        <Box>
          <Button variant="link" textDecoration="none" onClick={onChange}>
            Change
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default PaymentDetails
