import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem
} from '@material-ui/core'
import { useQuery, useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useStoreState } from 'easy-peasy'
import { useQueryClient } from 'react-query'

import { endpoints } from '../../api'
import { PrimaryButton, SecondaryButton, Loading } from '../../components'
import { CircleCheck } from '../../components/icons'

const styles = {
  button: {
    width: 150,
    height: 41
  },
  contents: {
    paddingBottom: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '70px',
    width: '100%'
  },
  centerText: {
    textAlign: 'center'
  },
  errorText: {
    color: 'red',
    textAlign: 'center'
  },
  selector: {
    width: '100%'
  },
  icon: {
    width: '10%',
    height: '10%',
    paddingBottom: '10px'
  }
}

function AssignClinicianModal(props) {
  const { classes, closeModal, isModalOpen } = props

  const history = useHistory()
  const queryClient = useQueryClient()
  const user = useStoreState(store => store.auth.user)
  const client = useStoreState(
    store => store.patientList.assignClinicianModalClient
  )
  const [selectedClinician, setSelectedClinician] = useState('')
  const [displayError, setDisplayError] = useState(null)
  const { data: cliniciansData, isLoading } = useQuery(
    [
      endpoints.getOrganizationProviders.getCacheId(),
      user.clinic.organization_id,
      user.clinic.id,
      'cliniciansOnly'
    ],
    () =>
      endpoints.getOrganizationProviders.request({
        organizationId: user.clinic.organization_id,
        clinicId: user.clinic.id,
        cliniciansOnly: true,
        includeCounts: false
      }),
    {
      // Only fetch when modal is open
      enabled: isModalOpen && Boolean(user),
      initialData: { clinicians: [] }
    }
  )

  const {
    isSuccess,
    reset,
    isLoading: isTransferLoading,
    mutateAsync: executeAssignClinician
  } = useMutation(endpoints.postManageAssignedClinicians.request, {
    onError: ({ error }) => {
      setDisplayError(error.message)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        endpoints.getClinicianUserAccount.getCacheId(),
        client.id
      ])
      await queryClient.invalidateQueries(endpoints.getPatientList.getCacheId())
    }
  })

  const submit = useCallback(async () => {
    if (!selectedClinician) {
      setDisplayError('Please select a clinician')
      return
    }
    const data = {
      clinician_ids: [selectedClinician.id]
    }
    await executeAssignClinician({ clientId: client.id, data })
  }, [selectedClinician, client, user])

  const handleSelectClinician = c => {
    setDisplayError(null)
    setSelectedClinician(c)
  }

  // Reset error on reopen
  useEffect(() => {
    if (isModalOpen) {
      setDisplayError(null)
    }
  }, [isModalOpen])

  // For some reason the response structure is different when an admin is making the request...
  const cliniciansArray = useMemo(() => {
    if (cliniciansData && cliniciansData.clinicians) {
      return cliniciansData.clinicians.map(c => ({
        ...c,
        display_name: c.first_name + ' ' + c.last_name
      }))
    } else if (cliniciansData) {
      // when admin is requesting
      return cliniciansData.map(c => ({
        id: c.id,
        clinic_id: c.clinic_id || null,
        display_name: c.first_name + ' ' + c.last_name
      }))
    }
    return []
  }, [cliniciansData])

  return (
    <Dialog open={isModalOpen} maxWidth={'xs'} fullWidth={true}>
      {isLoading ? (
        <div style={{ height: 256 }}>
          <Loading />
        </div>
      ) : (
        <div>
          <DialogTitle className={classes.centerText}>
            Assign Clinician
          </DialogTitle>
          <DialogContent className={classes.contents}>
            {isSuccess ? (
              <>
                <CircleCheck className={classes.icon} fill="#44CD7D" />
                <DialogContentText className={classes.centerText}>
                  {`${client.name} has been assigned to ${selectedClinician.display_name}! Would you like to finish enrolling this client?`}
                </DialogContentText>
                <div className={classes.buttonContainer}>
                  <div className={classes.buttonRow}>
                    <SecondaryButton
                      fullWidth={false}
                      onClick={() => {
                        closeModal()
                        reset()
                      }}
                      round
                      className={classes.button}
                    >
                      Not now
                    </SecondaryButton>
                    <PrimaryButton
                      fullWidth={false}
                      onClick={() => {
                        closeModal()
                        reset()
                        history.push(`/pending-client/${client.id}`)
                      }}
                      round
                      className={classes.button}
                    >
                      Finish enrolling
                    </PrimaryButton>
                  </div>
                </div>
              </>
            ) : (
              <>
                <DialogContentText className={classes.centerText}>
                  Select a clincian to assign to this client
                </DialogContentText>
                <Select
                  variant="outlined"
                  className={classes.selector}
                  value={selectedClinician}
                  renderValue={value =>
                    (value && value.display_name) || 'Select a clinician'
                  }
                  displayEmpty
                >
                  {cliniciansArray.map(c => {
                    return (
                      <MenuItem
                        key={c.id}
                        onClick={() => handleSelectClinician(c)}
                      >
                        {c.display_name}
                      </MenuItem>
                    )
                  })}
                </Select>
                <div className={classes.buttonContainer}>
                  <DialogContentText className={classes.errorText}>
                    {displayError || null}
                  </DialogContentText>
                  <div className={classes.buttonRow}>
                    <SecondaryButton
                      fullWidth={false}
                      onClick={closeModal}
                      round
                      className={classes.button}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      fullWidth={false}
                      onClick={submit}
                      loading={isTransferLoading}
                      round
                      className={classes.button}
                    >
                      Confirm
                    </PrimaryButton>
                  </div>
                </div>
              </>
            )}
          </DialogContent>
        </div>
      )}
    </Dialog>
  )
}

export default withStyles(styles)(AssignClinicianModal)
