import React from 'react'
import { Select } from '@chakra-ui/react'
import { endpoints } from '@api'
import { Organization } from 'src/api/endpoints'
import { usePermissions } from '@hooks'
import { useQuery } from 'react-query'

interface OrgSelectProps {
  selectedOrganizationId: string | null | undefined
  setSelectedOrganizationId: (id: string) => void
}

export const SuperAdminOrganizationSelector: React.FC<OrgSelectProps> = ({
  selectedOrganizationId,
  setSelectedOrganizationId
}) => {
  const { hasPermission } = usePermissions()
  const isSuperAdmin = hasPermission('*:any:*:*')

  const { data, isLoading } = useQuery(
    endpoints.getAllOrganizations.getCacheId(),
    () => endpoints.getAllOrganizations.request(),
    {
      enabled: isSuperAdmin
    }
  )
  const organizations = data?.organizations || []

  if (isLoading || !isSuperAdmin || !data?.organizations) {
    return null
  }

  return (
    <Select
      value={selectedOrganizationId!}
      style={{ border: '1px solid #E4E5E6' }}
      _focus={{
        outline: 'none'
      }}
      borderRadius="4px"
      height="48px"
      onChange={e => setSelectedOrganizationId(e.target.value)}
    >
      {organizations.map((org: Organization) => (
        <option key={org.id} value={org.id}>
          {org.name}
        </option>
      ))}
    </Select>
  )
}
