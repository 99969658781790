import React from 'react'
import { FieldArray } from 'formik'
import { Box, Text } from '@chakra-ui/react'
import { FilterCatalogMap } from './lib/types'
import { FilterForm } from './filter-form'

interface FilterFieldArrayProps {
  values: any
  filterCatalog: FilterCatalogMap
  resetForm: (updates: any) => void
}

export const FilterFieldArray = (props: FilterFieldArrayProps) => {
  const { values } = props

  const allFilterOptions = Object.keys(props.filterCatalog).map(
    (filterType, i) => {
      return (
        <option
          key={`${filterType}-${i}`}
          value={filterType}
          disabled={values.filterTypes.includes(filterType)}
        >
          {props.filterCatalog[filterType].title}
        </option>
      )
    }
  )

  const onFilterTypeChange = (
    value: string,
    fieldArrayHelpers: any,
    index: number
  ) => {
    props.resetForm({
      ...values
    })
    fieldArrayHelpers.replace(index, value)
  }

  return (
    <FieldArray
      name="filterTypes"
      render={fieldArrayHelpers => (
        <Box w="100%">
          {values.filterTypes.map((filterType: string, index: number) => {
            const FilterComponent =
              filterType && props.filterCatalog[filterType].component
            return (
              <FilterForm
                key={`filter-form-${index}`}
                index={index}
                filterType={filterType}
                allFilterOptions={allFilterOptions}
                removeFilter={() => fieldArrayHelpers.remove(index)}
                onFilterTypeChange={(value: string) =>
                  onFilterTypeChange(value, fieldArrayHelpers, index)
                }
              >
                {FilterComponent && (
                  <FilterComponent
                    resetForm={props.resetForm}
                    constants={props.filterCatalog[filterType].constants}
                  />
                )}
              </FilterForm>
            )
          })}
          {values.filterTypes.length <
            Object.keys(props.filterCatalog).length && (
            <Text
              w="100px"
              pt="xsmall"
              _hover={{ cursor: 'pointer' }}
              onClick={() => fieldArrayHelpers.push('')}
              color="primary"
            >
              + Add filter
            </Text>
          )}
        </Box>
      )}
    />
  )
}
