import React from 'react'

let SectionHeaderWrapper = () => null
if (process.env.REACT_APP_THEME === 'tempus') {
  import('./tempus/wrapper').then(
    ({ default: sectionHeaderWrapper }) =>
      (SectionHeaderWrapper = sectionHeaderWrapper)
  )
} else {
  import('./blueprint/wrapper').then(
    ({ default: sectionHeaderWrapper }) =>
      (SectionHeaderWrapper = sectionHeaderWrapper)
  )
}

export default function SectionHeader(props) {
  return <SectionHeaderWrapper {...props} />
}
