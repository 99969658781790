import { useState } from 'react'

interface ModalToggle {
  isOpen: boolean
  openModal: () => void
  closeModal: () => void
}

export const useModalToggle = (): ModalToggle => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return { isOpen, openModal, closeModal }
}
