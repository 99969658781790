import config from '../config'

const enrollContactInfoSuggestion = name => {
  return `To use the ${config.mobileAppName} mobile app, ${name} will need a cell phone number and an email address on file. Don't worry, you can always add this later!`
}

const newPatientTitle = name => {
  return `${name} is enrolled!`
}

const newPatientBody = (name, commsSent) => {
  const copyArray = commsSent.map(c => {
    if (c === 'sms') return 'a text message'
    if (c === 'email') return 'an email'
  })

  if (copyArray.length) {
    return `We sent ${name} ${copyArray.join(
      ' and '
    )} inviting them to start using Blueprint.`
  }

  return `This client was enrolled without an Email or Phone, so no invite was sent.`
}

const newPreEnrollPatientBody = (name, formattedDate) => {
  return `We're all set to send ${name} an invitiation to start using Blueprint on ${formattedDate}.`
}

const unarchiveTitle = name => {
  return `${name} has been unarchived!`
}

const unarchiveBody = name => {
  return `${name} already had a Blueprint account. We've unarchived the account and re-assigned your clinic's default assessments.`
}

const unarchiveTransferTitle = name => {
  return `${name} has been unarchived!`
}

const unarchiveTransferBody = name => {
  return `${name} already had a Blueprint account assigned to a different clinician at your clinic. We've unarchived the account, re-assigned your clinic's default assessments, and transferred them to you.`
}

const transferTitle = name => {
  return `${name} is already enrolled`
}

const transferBody = name => {
  return `${name} is already enrolled but was assigned to a different clinician at your clinic. We transferred them to you.`
}

const noOpTitle = name => {
  return `${name} is already enrolled.`
}

const noOpBody = name => {
  return `${name} is already enrolled and assigned to you.`
}

const subtext = () => {
  return 'If you believe this is incorrect, please contact us to enroll this client.'
}

const tooltipTitle = () => {
  return "What's this?"
}

const BDSTooltip = () => {
  return 'The Blueprint Diagnostic Screener screens for a wide range of disorders and automatically assigns assessments based on the results. This screener is based on DSM-5 Cross-Cutting Measures Level 1. '
}

const BDSAutoUnassignSelf = () => {
  return 'Assigning this assessment unassigns the Blueprint Diagnostic Screener. Our screening diagnostic automatically chooses assessments for your client based on their results.'
}

const BDSAutoUnassignOthers = () => {
  return "Assigning the Blueprint Diagnostic Screener unassigns all other assessments. Based on your client's results, appropriate assessments will automatically be assigned."
}

const insightsLegendTooltip = () => {
  return `Effect size is calculated based on the standardized difference between a client’s baseline score and a client’s score at each treatment interval (4 weeks, 12 weeks, 20 weeks, and at discharge). A clients “baseline” score is represented by the first assessment that the client completes on Blueprint. A client’s “discharge” is represented by the date the client was archived on Blueprint with the reason “Ended Treatment Services”.`
}

const standardErrorDescription = () => {
  return `Please try again or contact support at ${process.env.REACT_APP_HELP_EMAIL}.`
}

export {
  enrollContactInfoSuggestion,
  newPatientTitle,
  newPatientBody,
  unarchiveTitle,
  unarchiveBody,
  unarchiveTransferTitle,
  unarchiveTransferBody,
  transferTitle,
  transferBody,
  noOpTitle,
  noOpBody,
  subtext,
  tooltipTitle,
  BDSTooltip,
  BDSAutoUnassignSelf,
  BDSAutoUnassignOthers,
  insightsLegendTooltip,
  newPreEnrollPatientBody,
  standardErrorDescription
}
