import { useContext } from 'react'
import { PanelManagerContext } from '@context'

const usePanelManager = () => {
  const context = useContext(PanelManagerContext)

  if (context === undefined) {
    throw new Error(
      'usePanelManager must be used within a PanelManagerProvider'
    )
  }

  return context
}

export default usePanelManager
