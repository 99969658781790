import React from 'react'
import { VStack } from '@chakra-ui/react'
import { Loading }  from '@components'

const View = () => {
  return (
    <VStack flex="1" h="calc(100vh - 208px)" justifyContent="center">
      <Loading />
    </VStack>
  )
}

export default View
