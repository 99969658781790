export const TREATMENT_MODALITIES = [
  {
    label: 'All treatment modalities',
    value: ''
  },
  {
    label: 'ACT',
    value: 'ACT'
  },
  {
    label: 'CBT',
    value: 'CBT'
  },
  {
    label: 'CPT',
    value: 'CPT'
  },
  {
    label: 'DBT',
    value: 'DBT'
  },
  {
    label: 'EFT',
    value: 'EFT'
  },
  {
    label: 'EMDR',
    value: 'EMDR'
  },
  {
    label: 'ERP',
    value: 'ERP'
  },
  {
    label: 'General',
    value: 'General'
  },
  {
    label: 'MATCH-ADTC',
    value: 'MATCH-ADTC'
  },
  {
    label: 'MBCT',
    value: 'MBCT'
  },
  {
    label: 'MI',
    value: 'MI'
  }
]
