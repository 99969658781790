import React, { useContext } from 'react'
import { WorkflowsContext } from '@context'
import { Text, Box, Button, DrawerFooter } from '@chakra-ui/react'

type Props = {
  onCancel: () => void
  onDelete?: () => void
  onSubmit?: () => void
  hideDelete?: boolean
}

export const EditDrawerFooter = ({
  onDelete,
  onCancel,
  onSubmit = () => {},
  hideDelete = false
}: Props) => {
  const { selectedNode, isCreatingAction, isCreatingTrigger } = useContext(
    WorkflowsContext
  )

  let saveText: string = 'Save'
  if (selectedNode?.hasOwnProperty('actionType') || isCreatingAction)
    saveText = 'Save Action'
  if (selectedNode?.hasOwnProperty('triggerType') || isCreatingTrigger)
    saveText = 'Save Trigger'

  return (
    <DrawerFooter mt="small" p={0} width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Text
          color="error"
          onClick={onDelete}
          _hover={{ cursor: 'pointer' }}
          visibility={hideDelete || isCreatingAction ? 'hidden' : 'visible'}
        >
          Delete
        </Text>
        <Box>
          <Button variant="outline" mr={3} size="lg" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            colorScheme="primary"
            variant="solid"
            type="submit"
            onClick={onSubmit}
            size="lg"
          >
            {saveText}
          </Button>
        </Box>
      </Box>
    </DrawerFooter>
  )
}
