import React from 'react'
import { Flex, Text, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { InfoIcon } from '@blueprinthq/joy'

export default function EnrollBanner({ pendingClientData }) {
  if (pendingClientData) {
    const { pendingClientId, sign_up_source } = pendingClientData

    return pendingClientId &&
      sign_up_source &&
      sign_up_source.match(/intake/) ? (
      <Flex w="100%" align="center" justify="center" bg="#E4E5E6" h="48px">
        <InfoIcon fill="primary" mr="xsmall" />
        <Text mr="xsmall" color="primary">
          This client was created by an EHR integration.
        </Text>
        <Link
          as={RouterLink}
          color="primary"
          textDecoration="underline"
          isExternal
          to={`/pending-client/${pendingClientId}/intake-pdf`}
        >
          View intake form
        </Link>
      </Flex>
    ) : null
  }

  return null
}
