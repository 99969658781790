import React from 'react'

export const ThumbUpIcon = ({ fill = '#A1A1A1', size = "16" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99984 14H11.9998C12.5532 14 13.0265 13.6667 13.2265 13.1867L15.2398 8.48666C15.2998 8.33332 15.3332 8.17332 15.3332 7.99999V6.66666C15.3332 5.93332 14.7332 5.33332 13.9998 5.33332H9.79317L10.4265 2.28666L10.4465 2.07332C10.4465 1.79999 10.3332 1.54666 10.1532 1.36666L9.4465 0.666656L5.05317 5.05999C4.81317 5.29999 4.6665 5.63332 4.6665 5.99999V12.6667C4.6665 13.4 5.2665 14 5.99984 14ZM5.99984 5.99999L8.89317 3.10666L7.99984 6.66666H13.9998V7.99999L11.9998 12.6667H5.99984V5.99999ZM0.666504 5.99999H3.33317V14H0.666504V5.99999Z"
        fill={fill}
      />
    </svg>
  )
}
