import React, { useState } from 'react'
import { ScheduleSuccessContent } from './content'
import { ScheduleSuccess } from './scheduleSuccess'
import {
  Layout,
  RightColumn,
  LeftColumn
} from '../../../../components/FullscreenSplitLayout'
import { Logo } from '../../../../components/icons'
import { isMobile } from 'react-device-detect'
import { VStack, Box, Flex } from '@chakra-ui/react'

export const ScheduleSuccessPage = () => {
  return (
    <Layout isMobile={isMobile}>
      <RightColumn isMobile={isMobile}>
        <VStack w="100%" align="center" spacing="40px">
          <Flex
            w="100%"
            maxWidth="450px"
            justify={isMobile ? 'center' : 'left'}
          >
            <Logo style={{ width: '190px', maxHeight: '41px' }} />
          </Flex>
          <Box w="100%" maxWidth="450px">
            <ScheduleSuccess />
          </Box>
        </VStack>
      </RightColumn>
      <LeftColumn isMobile={isMobile}>
        <ScheduleSuccessContent />
      </LeftColumn>
    </Layout>
  )
}
