import React, { useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { Heading, Box, Text, useToast } from '@chakra-ui/react'
import { useStoreActions } from 'easy-peasy'
import { ProfileForm } from './profileForm'
import { Loading } from '../../../components'
import { endpoints } from '../../../api'
import AdditionalInfoForm from './additionalInfoForm'
import { AvatarEditor } from './avatar-editor'

export const EditProfileTab = () => {
  const updateAuthedUser = useStoreActions(actions => actions.auth.updateUser)
  const toast = useToast()

  const {
    data: account,
    isLoading: isAccountLoading,
    refetch: refetchAccount
  } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const { data: licenseLevels } = useQuery(
    [endpoints.getLicenseLevels.getCacheId()],
    endpoints.getLicenseLevels.request,
    {
      initialData: []
    }
  )

  const { mutateAsync: updateUserAccount, isError } = useMutation(
    endpoints.postUserAccount.request,
    {
      onSuccess() {
        refetchAccount()
      },
      onError() {
        toast({
          title: 'Error',
          description: 'Please try again.',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  // Keep store in sync with edits
  useEffect(() => {
    if (account) {
      updateAuthedUser(account)
    }
  }, [account])

  const { isLoading: isFetchingSelectFields, data: selectOptions } = useQuery(
    [endpoints.getFormConstants.getCacheId()],
    () => {
      return endpoints.getFormConstants.request({
        keys: [
          'gender_categories',
          'race_categories',
          'is_hispanic_or_latino',
          'clinician_treatment_approaches',
          'readiness_measurement_based_care'
        ]
      })
    }
  )

  if (isAccountLoading || isFetchingSelectFields) {
    return <Loading />
  }

  const additionalInfoFormOptions = {}
  for (const field of Object.keys(selectOptions)) {
    additionalInfoFormOptions[field] = selectOptions[field].map(option => ({
      value: option.id,
      display: option.display_name
    }))
  }

  return (
    <Box>
      <Box mb={'medium'} w={'100%'}>
        <Heading mb="xxsmall">Profile</Heading>
        <Text>Manage your Blueprint profile</Text>
      </Box>

      <Text as="b">Profile picture</Text>
      <Box mb="large" mt="small">
        <AvatarEditor user={account} />
      </Box>

      <ProfileForm
        account={account}
        licenseLevels={licenseLevels}
        onSubmit={async (values, actions) => {
          const data = { ...values }
          data.license_level = values.license_level.id
          await updateUserAccount({ data })
          actions.setSubmitting(false)
        }}
      />
      <AdditionalInfoForm
        account={account}
        onSubmit={async (values, actions) => {
          const data = values
          Object.keys(data).forEach(key => {
            if (data[key] === null) {
              delete data[key]
            }
          })
          await updateUserAccount({ data: values })
          actions.setSubmitting(false)
        }}
        fieldOptions={additionalInfoFormOptions}
      />

      {isError && (
        <Box mt="medium">
          <Text color="severe">
            Oops, something went wrong. Please refresh the page and try again.
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default EditProfileTab
