import React from 'react'
import { Flex, Text, Button } from '@chakra-ui/react'

const DeleteAccount = ({ onClick }: { onClick: () => void }) => {
  return (
    <Flex
      p="24px"
      borderRadius="8px"
      border="1px solid"
      borderColor="pale_gray"
      flexDirection="column"
      width="100%"
      justify-content="flex-start"
      align-items="flex-start"
    >
      <Text fontWeight="bold">
        Want to permanently delete your organization?
      </Text>
      <Text>
        All of your clinic's data will be erased and all user accounts
        associated with your organization will be deleted.
      </Text>
      <Text>
        <Button
          variant="link"
          color="#EB5164"
          textDecoration="none"
          onClick={onClick}
          m="0"
          _hover={{
            textDecoration: 'none'
          }}
          _focus={{
            outline: 'none'
          }}
        >
          Delete your organization
        </Button>
      </Text>
    </Flex>
  )
}

export default DeleteAccount
