import { action } from 'easy-peasy'

export const clinicianSignupModel = {
  clinicianSignup: {
    formData: {},
    setFormData: action((state, payload) => {
      state.formData = payload
    }),
    persona: {},
    setPersona: action((state, payload) => {
      state.persona = payload
    }),
    hubspotBridgeId: null,
    setHubspotBridgeId: action((state, payload) => {
      state.hubspotBridgeId = payload
    })
  }
}
