import React from 'react'

const Overdue = props => {
  const { white } = props
  const src = white
    ? 'https://res.cloudinary.com/hellojoy/image/upload/v1607553145/Overdue_White_cfv15w.png'
    : 'https://res.cloudinary.com/hellojoy/image/upload/v1607553144/Overdue_Black_zf5zu7.png'
  return <img width="18px" height="18px" src={src} alt="overdue" />
}
export default Overdue
