import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import { Box, Heading, Text } from '@chakra-ui/react'
import classes from './tabletemplate.module.css'

export default function TableTemplate({
  title,
  description,
  col1_title,
  col2_title,
  content,
  errors,
  isMobile
}) {
  return (
    <Box mb="xlarge">
      <Box mb="small">
        <Heading size="lg" mb="xsmall">
          {title}
        </Heading>
        <Text>{description}</Text>
      </Box>
      {title === 'Assessments' && errors.assessments && (
        <Text className={classes.error}>{errors.assessments}</Text>
      )}
      {isMobile ? (
        content
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding={'none'}>
                <p className={classes.first_col_title}>{col1_title}</p>
              </TableCell>
              <TableCell padding={'none'}>
                <p className={classes.col_title}>{col2_title}</p>
              </TableCell>
              <TableCell padding={'none'}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{content}</TableBody>
        </Table>
      )}
    </Box>
  )
}
