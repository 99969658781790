import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  VStack,
  IconButton,
  Flex
} from '@chakra-ui/react'
import { Loading } from '@components'
import { ContentErrorBoundary } from '@containers/assessment-result-modal/components'
import { usePatientControllerGetCheckInScores } from '~/clinician-api'
import { PatientCheckInScoresDto } from '~/clinician-api/models'
import { ArrowLeftIcon, Select, Button, ArrowRightIcon } from '@blueprinthq/joy'
import { DateTime } from 'luxon'
import { WorksheetAnswersTable } from './worksheet-answers-table'
import { WorksheetWeeklyAnswersTable } from './weekly-answers-table'
import { StoreModel } from 'src/store/types'

interface WorksheetResultModalProps {
  isOpen: boolean
  patientId: string
  preselectedCheckInScoreId?: string | null
  onClose: () => void
}

export const WorksheetResultModal = ({
  isOpen,
  patientId,
  preselectedCheckInScoreId = null,
  onClose
}: WorksheetResultModalProps) => {
  const { isLoading, data } = usePatientControllerGetCheckInScores(patientId)

  const modalSize = useBreakpointValue({
    base: 'full',
    md: '6xl'
  })

  return (
    <Modal
      onClose={onClose}
      size={modalSize}
      isOpen={isOpen}
      preserveScrollBarGap
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton color="white" mt="xsmall" />
        <ModalHeader
          bg="primary"
          color="white"
          fontSize="lg"
          borderTopRadius="md"
        >
          Worksheet Results
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            <Loading />
          ) : (
            <ContentErrorBoundary>
              <WorksheetResultModalContent
                data={data!}
                preselectedCheckInScoreId={preselectedCheckInScoreId}
              />
            </ContentErrorBoundary>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const getStartOfWeekDate = (date?: string) => {
  const now = date ? DateTime.fromISO(date) : DateTime.local()
  const daysToSunday = now.weekday % 7
  const sunday = now.minus({ days: daysToSunday })

  return sunday
}

const WorksheetResultModalContent = ({
  data,
  preselectedCheckInScoreId
}: {
  data: PatientCheckInScoresDto
  preselectedCheckInScoreId: string | null
}) => {
  const [state, setState] = useState<{
    selectedCheckInId?: string | null
    selectedCheckInScoreId?: string | null
    selectedView: 'weekly' | 'daily'
    selectedStartOfWeekDate: DateTime
  }>(() => {
    if (preselectedCheckInScoreId) {
      const score = data.scores.find(s => s.id === preselectedCheckInScoreId)
      const checkIn = data.versions.find(v => score?.versionId === v.versionId)

      return {
        selectedCheckInId: checkIn?.id,
        selectedCheckInScoreId: preselectedCheckInScoreId,
        selectedView: 'daily',
        selectedStartOfWeekDate: getStartOfWeekDate()
      }
    }

    const selectedCheckInId = data.versions[0].id
    const firstScore = data.scores.find(cs => cs.moduleId === selectedCheckInId)

    return {
      selectedCheckInId: selectedCheckInId,
      selectedCheckInScoreId: firstScore?.id,
      selectedView: 'weekly',
      selectedStartOfWeekDate: getStartOfWeekDate()
    }
  })

  const optionsOrientation = useBreakpointValue({
    base: 'vertical',
    md: 'horizontal'
  })

  const optionsWidth = optionsOrientation === 'vertical' ? '100%' : 'inherit'

  const checkInOptions = useMemo(() => {
    const uniqueModuleIds = new Set()

    const uniqueCheckIns = data.versions.filter(c => {
      if (uniqueModuleIds.has(c.id)) {
        return false
      }
      uniqueModuleIds.add(c.id)
      return true
    })

    return uniqueCheckIns.map(c => ({
      checkInId: c.id,
      name: c.title
    }))
  }, [data.versions])

  const selectedCheckInScores = useMemo(() => {
    return data.scores.filter(s => s.moduleId === state.selectedCheckInId)
  }, [data.scores, state.selectedCheckInId])

  const selectedCheckInScore = useMemo(() => {
    return data.scores.find(s => s.id === state.selectedCheckInScoreId)
  }, [data.scores, state.selectedCheckInScoreId])

  const selectedCheckIn = useMemo(() => {
    return data.versions.find(
      v => v.versionId === selectedCheckInScore?.versionId
    )
  }, [data.versions, selectedCheckInScore])

  const onSelectCheckIn = (checkInId: string) => {
    const firstScore = data.scores.find(cs => cs.moduleId === checkInId)
    setState(state => ({
      ...state,
      selectedCheckInScoreId: firstScore?.id,
      selectedCheckInId: checkInId
    }))
  }

  const onSelectView = (view: 'weekly' | 'daily') => {
    setState(state => ({
      ...state,
      selectedView: view
    }))
  }

  const onPreviousDate = () => {
    setState(state => ({
      ...state,
      selectedStartOfWeekDate: state.selectedStartOfWeekDate.minus({ days: 7 })
    }))
  }

  const onNextDate = () => {
    setState(state => ({
      ...state,
      selectedStartOfWeekDate: state.selectedStartOfWeekDate.plus({ days: 7 })
    }))
  }

  const onNextScore = () => {
    setState(state => ({
      ...state,
      selectedCheckInScoreId:
        selectedCheckInScores[
          selectedCheckInScores.findIndex(
            s => s.id === state.selectedCheckInScoreId
          ) - 1
        ].id
    }))
  }

  const onPreviousScore = () => {
    setState(state => ({
      ...state,
      selectedCheckInScoreId:
        selectedCheckInScores[
          selectedCheckInScores.findIndex(
            s => s.id === state.selectedCheckInScoreId
          ) + 1
        ].id
    }))
  }

  const options = (
    <>
      <Box w={optionsWidth}>
        <Select
          options={checkInOptions}
          valueKey="checkInId"
          labelKey="name"
          label="Worksheet"
          value={checkInOptions.find(
            co => co.checkInId === state.selectedCheckInId
          )}
          onChange={option => onSelectCheckIn(option.checkInId)}
        />
      </Box>
      <HStack>
        {state.selectedView === 'weekly' ? (
          <>
            <IconButton
              aria-label="left"
              icon={<ArrowLeftIcon fill="primary" />}
              variant="ghost"
              onClick={onPreviousDate}
            />
            <Text fontWeight="bold">
              {state.selectedStartOfWeekDate.toFormat('D')} -{' '}
              {state.selectedStartOfWeekDate.plus({ days: 6 }).toFormat('D')}
            </Text>
            <IconButton
              isDisabled={
                state.selectedStartOfWeekDate.plus({ days: 6 }) > DateTime.now()
              }
              aria-label="right"
              icon={<ArrowRightIcon fill="primary" />}
              variant="ghost"
              onClick={onNextDate}
            />
          </>
        ) : (
          <>
            <IconButton
              isDisabled={
                selectedCheckInScores.findIndex(
                  s => s.id === state.selectedCheckInScoreId
                ) ===
                selectedCheckInScores.length - 1
              }
              aria-label="left"
              icon={<ArrowLeftIcon fill="primary" />}
              variant="ghost"
              onClick={onPreviousScore}
            />
            <Text fontWeight="bold">
              {DateTime.fromISO(selectedCheckInScore!.createdAt).toFormat('f')}
            </Text>
            <IconButton
              isDisabled={
                selectedCheckInScores.findIndex(
                  s => s.id === state.selectedCheckInScoreId
                ) === 0
              }
              aria-label="right"
              icon={<ArrowRightIcon fill="primary" />}
              variant="ghost"
              onClick={onNextScore}
            />
          </>
        )}
      </HStack>
      <Flex alignItems="center">
        <Text fontWeight="bold" mr="xsmall">
          View:
        </Text>
        <HStack>
          <Button
            m="0px"
            size="xs"
            fontSize="md"
            color={state.selectedView === 'daily' ? 'primary' : 'medium_gray'}
            variant="ghost"
            onClick={() => onSelectView('daily')}
          >
            Daily
          </Button>
          <Box width="1px" height="16px" backgroundColor="light_gray"></Box>
          <Button
            m="0px"
            size="xs"
            fontSize="md"
            color={state.selectedView === 'weekly' ? 'primary' : 'medium_gray'}
            variant="ghost"
            onClick={() => onSelectView('weekly')}
          >
            Weekly
          </Button>
        </HStack>
      </Flex>
    </>
  )

  return (
    <Box mb="medium">
      {optionsOrientation === 'horizontal' ? (
        <HStack spacing="small" mt="small" justify="space-between">
          {options}
        </HStack>
      ) : (
        <VStack spacing="small" mt="small" alignItems="self-start">
          {options}
        </VStack>
      )}
      <Box mt="medium">
        {state.selectedView === 'daily' &&
          selectedCheckIn &&
          selectedCheckInScore && (
            <WorksheetAnswersTable
              score={selectedCheckInScore}
              checkIn={selectedCheckIn}
            />
          )}
        {state.selectedView === 'weekly' && (
          <WorksheetWeeklyAnswersTable
            checkInVersions={data.versions.filter(
              c => c.id === state.selectedCheckInId
            )}
            scores={selectedCheckInScores}
            startOfWeekDate={state.selectedStartOfWeekDate.toJSDate()}
          />
        )}
      </Box>
    </Box>
  )
}

export const WorksheetResultModalConnected = () => {
  const history = useHistory()

  const { isOpen, patientId, preselectedCheckInScoreId } = useStoreState(
    (state: StoreModel) => state.modals.worksheetScores
  )

  // @ts-ignore
  const { closeModal } = useStoreActions(state => state.modals.worksheetScores)

  useEffect(() => {
    closeModal()
  }, [history.location.pathname])

  if (!isOpen || !patientId) return null

  return (
    <WorksheetResultModal
      isOpen={isOpen}
      patientId={patientId}
      onClose={closeModal}
      preselectedCheckInScoreId={preselectedCheckInScoreId}
    />
  )
}
