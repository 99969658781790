import React, { Component } from 'react'
import {
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  XAxis,
  YAxis
} from 'recharts'
import { Card } from '../../../../../../components'
import EmptyGraph from '../empty-graph'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import { meanBy, filter } from 'lodash'

import styles from './lifestyle-charts.module.css'

export default class LifestyleCharts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkins: props.checkins
    }
  }

  generateChart = (type, source, unit) => {
    let data = this.props.insightsData ? this.props.insightsData[type] : null
    if (data) {
      data.forEach(dataPoint => {
        dataPoint['date_unix'] = moment(dataPoint.date).format('X')
      })
    }
    return <Graph type={type} source={source} unit={unit} data={data} />
  }

  buildSubheader = (type, source, unit) => {
    if (source === 'checkin') {
      const text = this.renderLatestCheckin(type)
      return text
    }
    if (source === 'sensors') {
      const data = this.props.insightsData
        ? this.props.insightsData[type]
        : null
      if (data && data.length) {
        const average = meanBy(data, 'value')
        if (unit === 'time') {
          const hours = average / 60 / 60
          return formatDuration(hours)
        } else if (unit === 'steps') {
          return roundValue(average, 0) + ' ' + unit + ' per day'
        } else {
          return roundValue(average, 2) + ' ' + unit + ' per day'
        }
      } else {
        return 'Need more data'
      }
    }
    return '--'
  }

  formatLatestAssessment = (type, score) => {
    return labelize(type, score)
  }

  renderLatestCheckin = type => {
    const { insightsData = {} } = this.props
    if (insightsData[type] && insightsData[type].length) {
      const dimension = this.props.insightsData[type]
      const latestCheckIn = dimension[dimension.length - 1]
      const date = formatDateTime(latestCheckIn.date)
      return (
        <span>{`${formatYAxisEnergy(latestCheckIn.value)} • ${date}`}</span>
      )
    } else {
      return 'Not enough data'
    }
  }

  componentDidMount = () => {
    document.getElementById('focus').focus()
  }

  //TODO: FILL IN CHARTS
  render() {
    return (
      <div className={styles.container}>
        <p id="focus" tabIndex="0" />
        <Card
          header="Mood"
          subheader={this.buildSubheader('positivity', 'checkin')}
          sublabel="Latest check-in"
          Content={this.generateChart('positivity', 'checkin')}
          error={this.state.error_checkin}
          hideShadow
        />
        <Card
          header="Energy"
          subheader={this.buildSubheader('energy', 'checkin')}
          sublabel="Latest check-in"
          Content={this.generateChart('energy', 'checkin')}
          error={this.state.error_checkin}
          hideShadow
        />
        <Card
          header="Sleep quality"
          subheader={this.buildSubheader('sleep_quality', 'checkin')}
          sublabel="Latest check-in"
          Content={this.generateChart('sleep_quality', 'checkin')}
          error={this.state.error_checkin}
          hideShadow
        />
        <Card
          header="Social engagement"
          subheader={this.buildSubheader('social', 'checkin')}
          sublabel="Latest check-in"
          Content={this.generateChart('social', 'checkin')}
          error={this.state.error_checkin}
          hideShadow
        />
        <Card
          header="Daily Steps"
          subheader={this.buildSubheader('steps', 'sensors', 'steps')}
          sublabel="30-day rolling average"
          Content={this.generateChart('steps', 'sensors', 'steps')}
          hideShadow
        />
        <Card
          header="Distance traveled"
          description="Distance traveled in a given day by walking and/or running."
          subheader={this.buildSubheader('distance', 'sensors', 'miles')}
          sublabel="30-day rolling average"
          Content={this.generateChart('distance', 'sensors', 'miles')}
          hideShadow
        />
        <Card
          header="Sleep duration"
          description="Amount of time spent in bed each night/day."
          subheader={this.buildSubheader('sleep', 'sensors', 'time')}
          sublabel="30-day rolling average"
          Content={this.generateChart('sleep', 'sensors', 'time')}
          hideShadow
        />
      </div>
    )
  }
}

function formatDateTime(date) {
  return moment(date).format('ddd M/D')
}
function formatDateTimeFromUnix(unix) {
  return moment.unix(unix).format('MM-DD-YYYY') ===
    moment().format('MM-DD-YYYY')
    ? 'Today'
    : moment.unix(unix).format('ddd M/D')
}

const Graph = props => {
  const { source, unit, data } = props
  if (!data || !data.length) {
    return <EmptyGraph />
  }
  const r = data.length > 1 ? 0 : 3

  let filteredData = filter(data, function(o) {
    return o.value !== null
  })
  if (source === 'checkin') {
    let ticks = [-2, 0, 2]
    const y_axis_tick_formatter = formatYAxisEnergy

    return (
      <ResponsiveContainer width="100%" height={200}>
        {
          <LineChart margin={{ left: 0, top: 20 }} data={filteredData}>
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="value"
              stroke="#2d54e8"
              strokeWidth="2"
              dot={{
                strokeDasharray: '0',
                stroke: '#2d54e8',
                strokeWidth: 6,
                r: r
              }}
            />
            <XAxis
              tickFormatter={formatDateTimeFromUnix}
              padding={{ left: 50, right: 50 }}
              dataKey="date_unix"
              type="number"
              domain={['dataMin', () => moment().unix()]}
            />
            <CartesianGrid vertical={false} />
            <Tooltip content={<CustomTooltip />} />
            <YAxis
              tickFormatter={y_axis_tick_formatter}
              ticks={ticks}
              dataKey="value"
            />
          </LineChart>
        }
      </ResponsiveContainer>
    )
  }

  if (source === 'sensors') {
    return (
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={data}>
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="value"
            stroke="#2d54e8"
            strokeWidth="2"
            dot={{
              strokeDasharray: '0',
              stroke: '#2d54e8',
              strokeWidth: 6,
              r: r
            }}
          />
          <XAxis
            tickFormatter={formatDateTimeFromUnix}
            padding={{ left: 50, right: 50 }}
            dataKey="date_unix"
            type="number"
            domain={['dataMin', () => moment().unix()]}
          />
          <CartesianGrid vertical={false} />
          <Tooltip content={<CustomTooltipSensorData unit={unit} />} />
          {unit === 'time' ? <YAxis tickFormatter={formatValue} /> : <YAxis />}
        </LineChart>
      </ResponsiveContainer>
    )
  }

  return null
}

const CustomTooltip = props => {
  const { active, payload, label } = props
  if (active && payload && label && payload[0]) {
    const value = payload[0].value
    const key = payload[0].dataKey
    const color = payload[0].stroke
    const labelized = labelize(key, value)
    if (!active) return null
    return (
      <Paper className="tooltip" elevation={1}>
        <h5 className="date">{formatDateTimeFromUnix(label)}</h5>
        <h5 style={{ color: color }} className="value">
          {labelized}
        </h5>
      </Paper>
    )
  } else {
    return null
  }
}

const CustomTooltipSensorData = props => {
  const { active, payload, label } = props
  if (active && payload && label) {
    const value = payload[0].value
    const color = payload[0].stroke
    const labelized = labelizeSensorData(props.unit, value)

    if (!active) return null
    return (
      <Paper className="tooltip" elevation={1}>
        <h5 className="date">{formatDateTimeFromUnix(label)}</h5>
        <h5 style={{ color: color }} className="value">
          {labelized}
        </h5>
      </Paper>
    )
  } else {
    return null
  }
}

function labelize(key, value) {
  return formatYAxisEnergy(value)
}

function formatYAxisEnergy(value) {
  if (value < -1.34) {
    return 'Low'
  } else if (value < 1.34) {
    return 'Medium'
  } else {
    return 'High'
  }
}

function roundValue(value, decimals) {
  return (Math.round(value * 10) / 10).toFixed(decimals)
}

function formatValue(value) {
  return Math.round(convertSecondsToHours(value))
}

function convertSecondsToHours(seconds) {
  return (seconds / 60 / 60).toFixed(2)
}
function labelizeSensorData(unit, value) {
  if (unit === 'time') {
    const hours = value / 60 / 60
    return formatDuration(hours)
  } else if (unit === 'steps') {
    return roundValue(value, 0) + ' ' + unit
  } else {
    return roundValue(value, 2) + ' ' + unit
  }
}
function formatDuration(value) {
  const duration = moment.duration(value, 'hours')
  if (duration && duration.isValid && duration._data) {
    return duration._data.hours + 'h ' + duration._data.minutes + 'm'
  } else {
    return roundValue(value, 2) + ' hours'
  }
}
