import React, { useState } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Text,
  Button
} from '@chakra-ui/react'

export const DisclaimerBanner = () => {
  const localStorageKey = 'bpDisclaimerDismissed'

  const [isVisible, setIsVisible] = useState(() => {
    const storedValue = localStorage.getItem(localStorageKey)
    return storedValue === null
  })

  const handleClose = () => {
    localStorage.setItem(localStorageKey, 'true')
    setIsVisible(false)
  }

  if (!isVisible) return null

  return (
    <Box
      zIndex="100"
      border="1px solid #E4E5E6"
      borderRadius="8px"
    >
      <Alert bg="#F7F8FF" borderRadius="8px">
        <Flex alignItems="start">
          <Box display="flex" alignItems="center">
            <AlertIcon color="primary" />
          </Box>
          <Box>
            <Text mb="xsmall">
              <Text as="span" fontWeight="bold">
                Disclaimer:
              </Text>
              <Text as="span">
                {` Blueprint’s AI Assistant is not designed to take the place of mental health expertise
                or clinical decision-making. Our goal is to surface up and curate clinical insights and
                suggestions in order to enable therapists to make decisions more easily.
                Please review any suggestions Blueprint makes before utilizing them.`}
              </Text>
            </Text>
            <Button variant="ghost" margin="0px" padding="0px" color="primary" onClick={handleClose}>
              Dismiss
            </Button>
          </Box>
        </Flex>
      </Alert>
    </Box>
  )
}
