import React, { useCallback } from 'react'
import { Flex, Text, HStack, IconButton } from '@chakra-ui/react'
import { Info } from 'luxon'
import { ArrowLeftIcon, ArrowRightIcon } from '@blueprinthq/joy'

export const CalendarMonthPicker = ({
  year,
  month,
  onChange,
  isIncrementDisabled = false,
  isDecrementDisabled = false
}) => {
  const updateSelectedCalendarMonth = useCallback(
    nextCalendarMonth => {
      if (onChange) {
        onChange(nextCalendarMonth)
      }
    },
    [onChange]
  )

  const decrementSelectedCalendarMonth = useCallback(() => {
    // Goto the previous year if current month is Janurary
    const nextYear = month === 1 ? year - 1 : year
    // Set month to December if going back a year, otherwise go back a single month
    const nextMonth = nextYear === year ? month - 1 : 12
    updateSelectedCalendarMonth({
      month: nextMonth,
      year: nextYear
    })
  }, [year, month, updateSelectedCalendarMonth])

  const incrementSelectedCalendarMonth = useCallback(() => {
    // Goto the next year if current month is December
    const nextYear = month === 12 ? year + 1 : year
    // Set month to Janruary if going to next year, otherwise go forward a single month
    const nextMonth = nextYear === year ? month + 1 : 1
    updateSelectedCalendarMonth({
      month: nextMonth,
      year: nextYear
    })
  }, [year, month, updateSelectedCalendarMonth])

  return (
    <Flex width="240px" justify="space-between">
      <Text fontSize="xl">
        <Text fontSize="inherit" fontWeight="bold" as="span">
          {Info.months('short')[month - 1]}
        </Text>{' '}
        {year}
      </Text>
      <HStack alignItems="center" spacing="small">
        <IconButton
          icon={<ArrowLeftIcon />}
          size="sm"
          variant="outline"
          onClick={decrementSelectedCalendarMonth}
          disabled={isDecrementDisabled}
        />
        <IconButton
          icon={<ArrowRightIcon />}
          size="sm"
          variant="outline"
          onClick={incrementSelectedCalendarMonth}
          disabled={isIncrementDisabled}
        />
      </HStack>
    </Flex>
  )
}
