import React from 'react'
import { Image, Box, HStack } from '@chakra-ui/react'
import WhiteLogo from '../../../images/brand/white_logo.svg'

const Header = () => (
  <Box position="relative" style={{ margin: '0 16px' }}>
    <HStack
      style={{ margin: 0 }}
      justifyContent={{ base: 'center', md: 'space-between' }}
      alignItems="center"
      h={{
        base: '80px',
        sm: '80px',
        md: '16px'
      }}
      my="medium"
    >
      <Image
        position="absolute"
        top="25px"
        loading="eager"
        src={WhiteLogo}
        paddingLeft="16px"
      />
    </HStack>
  </Box>
)

export default Header
