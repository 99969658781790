import React from 'react'
import { Select } from '@chakra-ui/react'
import { Select as JoySelect } from '@blueprinthq/joy'
import { usePermissions } from '@hooks'

import { useClinicSelect } from './clinic-select.context'

export const ClinicSelect = () => {
  const { hasPermission } = usePermissions()

  const { selectedClinic, clinics, setSelectedClinic } = useClinicSelect()

  const isSuperAdmin = hasPermission('read:any:clinics:list')

  if (clinics.length <= 1) {
    return null
  }

  return isSuperAdmin ? (
    <Select
      value={selectedClinic?.id}
      style={{ border: '1px solid #E4E5E6' }}
      _focus={{
        outline: 'none'
      }}
      borderRadius="4px"
      height="48px"
      onChange={e =>
        setSelectedClinic(clinics.find(c => c.id === e.target.value))
      }
    >
      {clinics.map(c => (
        <option key={c.id} value={c.id}>
          {c.name}
        </option>
      ))}
    </Select>
  ) : (
    <JoySelect
      label=""
      value={selectedClinic}
      options={clinics}
      valueKey="id"
      labelKey="name"
      onChange={(value: any) => setSelectedClinic(value)}
    />
  )
}
