import React from 'react'

const X = props => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="X---gray"
        transform="translate(1.000000, 1.000000)"
        fill={props.fill ? props.fill : '#a1a1a1'}
      >
        <path d="M7.32701422,5.99842064 L11.7251185,1.60147407 C12.0916272,1.23506186 12.0916272,0.641221374 11.7251185,0.27480916 C11.3586098,-0.0916030534 10.764613,-0.0916030534 10.3981043,0.27480916 L6,4.67175573 L1.60189573,0.27480916 C1.23538705,-0.0916030534 0.641390205,-0.0916030534 0.274881517,0.27480916 C-0.0916271722,0.641221374 -0.0916271722,1.23506186 0.274881517,1.60147407 L4.67298578,5.99842064 L0.274881517,10.3953672 C-0.0916271722,10.7617794 -0.0916271722,11.3556199 0.274881517,11.7220321 C0.451816746,11.8989208 0.691943128,12 0.93206951,12 C1.17219589,12 1.41232227,11.9115557 1.5892575,11.7220321 L6,7.32508555 L10.3981043,11.7220321 C10.5750395,11.8989208 10.8151659,12 11.0552923,12 C11.2954186,12 11.535545,11.9115557 11.7124803,11.7220321 C12.0789889,11.3556199 12.0789889,10.7617794 11.7124803,10.3953672 L7.32701422,5.99842064 Z"></path>
      </g>
    </g>
  </svg>
)

export default X
