import { action } from 'easy-peasy'
import { ClientStatusBuckets } from '../../constants/clientStatusBuckets'
import { SortBy } from '../../constants/sortBy'
import { TABS } from '@constants/clientList'

const options = [
  {
    value: TABS.PENDING,
    status: ClientStatusBuckets.PENDING,
    defaultSort: {
      sortBy: SortBy.CREATED_AT,
      order: 'desc'
    }
  },
  {
    value: TABS.AWAITING_INVITE,
    status: ClientStatusBuckets.AWAITING_INVITE,
    defaultSort: {
      sortBy: SortBy.DATE_OF_INVITE,
      order: 'asc'
    }
  },
  {
    value: TABS.INVITE_SENT,
    status: ClientStatusBuckets.INVITE_SENT,
    defaultSort: {
      sortBy: SortBy.DATE_OF_INVITE,
      order: 'desc'
    }
  },
  {
    value: TABS.ACTIVE,
    status: ClientStatusBuckets.ACTIVE,
    defaultSort: {
      sortBy: SortBy.ASSESSMENT,
      order: 'desc'
    }
  },
  {
    value: TABS.DISCHARGED,
    status: ClientStatusBuckets.ARCHIVED,
    hideCount: true,
    defaultSort: {
      sortBy: SortBy.ARCHIVED_DATE,
      order: 'desc'
    }
  }
]

const defaultSortValues = {}
options.forEach(o => (defaultSortValues[o.value] = o.defaultSort || {}))

export const patientListModel = {
  patientList: {
    selectedPatientTypeOption: options[3],
    patientTypeOptions: options,
    setPatientTypeOption: action((state, value) => {
      const matchedOption = state.patientTypeOptions.find(
        opt => opt.value === value
      )
      state.selectedPatientTypeOption = matchedOption
    }),
    sortValues: defaultSortValues,
    setSortValue: action((state, value) => {
      state.sortValues[value.name] = value.sort
    }),
    page: 1,
    setPage: action((state, value) => {
      state.page = value
    }),
    isAssignClinicianModalOpen: false,
    setIsAssignClinicianModalOpen: action((state, value) => {
      state.isAssignClinicianModalOpen = value
    }),
    assignClinicianModalClient: null,
    setAssignClinicianModalClient: action((state, value) => {
      state.assignClinicianModalClient = value
    }),
    selectedClinician: {},
    selectClinician: action((state, value) => {
      state.selectedClinician = value
    }),
    selectedClinic: {},
    selectClinic: action((state, value) => {
      state.selectedClinic = value
    })
  }
}
