import { QuestionAnswerFilter } from './question-answer-filter/question-answer-filter'
import { TotalScoreFilter } from './total-score-filter'
import { PointChangeFilter } from './point-change-filter'
import { PercentChangeFilter } from './precent-change-filter'

type ComponentMap = {
  [key: string]: any
}

type StringMap = {
  [key: string]: string
}

const scoreComparisonConstants = {
  operators: [
    { display: 'increase', value: 'increase' },
    { display: 'decrease', value: 'decrease' }
  ],
  referenceScoreTypes: [
    { display: 'since previous', value: 'previous' },
    { display: 'since baseline', value: 'baseline' }
  ]
}

export const FilterCatalog: ComponentMap = {
  'question-answer': {
    component: QuestionAnswerFilter,
    constants: {}
  },
  'total-score': {
    component: TotalScoreFilter,
    constants: {
      operators: [
        { display: 'less than', value: 'less-than' },
        { display: 'less than or equal to', value: 'less-than-equal' },
        { display: 'greater than', value: 'greater-than' },
        {
          display: 'greater than or equal to',
          value: 'greater-than-equal'
        },
        { display: 'equal to', value: 'equal' },
        { display: 'between (inclusive)', value: 'between-inclusive' }
      ]
    }
  },
  'point-change': {
    component: PointChangeFilter,
    constants: scoreComparisonConstants
  },
  'percent-change': {
    component: PercentChangeFilter,
    constants: scoreComparisonConstants
  }
}

export const filterNames: StringMap = {
  'question-answer': 'Question/Answer',
  'total-score': 'Total Score',
  'point-change': 'Point Change',
  'percent-change': 'Percent Change'
}
