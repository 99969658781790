import React, { useMemo, useState, useEffect } from 'react'
import { TextField } from '@blueprinthq/joy'
import { makeStyles } from '@material-ui/core'
import InputMask from 'react-input-mask'
import moment from 'moment'

export default function BPDateTimePicker(props) {
  const {
    showDate,
    showTime,
    form,
    fieldName,
    initialMeridian,
    ...inputMaskProps
  } = props

  const [meridian, setMeridian] = useState(moment().hours() > 11 ? 'pm' : 'am')

  const mergedStyles = theme => ({
    button: {
      display: 'flex',
      flexDirection: 'row',
      border: 'solid',
      borderColor: '#c4c4c4',
      borderWidth: '1px',
      borderRadius: '3px',
      '&>div': {
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '2px 3px 1px 3px',
        cursor: 'pointer'
      }
    },
    activeMeridian: {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
    inactiveMeridian: {
      color: theme.palette.text.contrastOnWhite
    }
  })
  const useStyles = makeStyles(mergedStyles)
  const classes = useStyles()

  const generateMask = useMemo(
    () =>
      (showDate ? '99/99/9999' : '') +
      (showDate && showTime ? '   ' : '') +
      (showTime ? '99:99' : ''),
    [showDate, showTime]
  )

  // adjust 24 clock in Date object based on users choice of am or pm
  const shiftDateTime = dt => {
    if (meridian === 'am' && dt.hours() > 11) dt.hours(dt.hours() - 12)
    if (meridian === 'pm' && dt.hours() < 12) dt.hours(dt.hours() + 12)
    return dt
  }

  useEffect(() => {
    if (initialMeridian) setMeridian(initialMeridian)
  }, [initialMeridian])

  useEffect(() => {
    if (
      form &&
      fieldName &&
      form.values[fieldName] &&
      !form.values[fieldName].includes('_')
    ) {
      form.setFieldValue(
        fieldName,
        shiftDateTime(
          moment(form.values[fieldName], 'MM/DD/YYYY hh:mm a')
        ).format('MM/DD/YYYY hh:mm a')
      )
    }
  }, [meridian, form && form.values[fieldName]])

  const MeridianSwitch = () => (
    <button
      onMouseDown={() => {
        setMeridian(meridian === 'am' ? 'pm' : 'am')
      }}
      className={classes.button}
      tabIndex="0"
      role="button"
      onKeyDown={e => {
        const key = e.which
        if (key === 13 || key === 32) {
          setMeridian(meridian === 'am' ? 'pm' : 'am')
        }
      }}
    >
      <div
        className={
          meridian === 'am' ? classes.activeMeridian : classes.inactiveMeridian
        }
      >
        AM
      </div>
      <div
        className={
          meridian === 'pm' ? classes.activeMeridian : classes.inactiveMeridian
        }
      >
        PM
      </div>
    </button>
  )

  return (
    <InputMask {...inputMaskProps} mask={generateMask}>
      {() => (
        <TextField
          {...inputMaskProps}
          rightIcon={showTime && <MeridianSwitch />}
        />
      )}
    </InputMask>
  )
}
