import { AppointmentTypeFilter } from './appointment-type-filter'
import { ClientStatusFilter } from '../../shared-filters/client-status-filter'
import {
  ClientAgeFilter,
  CLIENT_AGE_FILTER_OPERATORS
} from '../../shared-filters/client-age-filter'
import { ProgramAssignmentFilter } from '../../shared-filters/program-assignment-filter'
import { FilterCatalogMap } from '../../shared-filters/lib/types'

const constants = {
  operators: [
    { display: 'is', value: 'is' },
    { display: 'is not', value: 'is not' }
  ]
}

export const FilterCatalog: FilterCatalogMap = {
  appointmentType: {
    component: AppointmentTypeFilter,
    constants,
    title: 'Appointment Type'
  },
  clientStatus: {
    component: ClientStatusFilter,
    constants,
    title: 'Client Status'
  },
  clientAge: {
    component: ClientAgeFilter,
    constants: {
      operators: CLIENT_AGE_FILTER_OPERATORS
    },
    title: 'Client Age'
  },
  programAssignment: {
    component: ProgramAssignmentFilter,
    constants,
    title: 'Program Assignment'
  }
}
