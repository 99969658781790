import React from 'react'
import cx from 'classnames'
import { TertiaryButton } from '@components'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  emptyGraph: {
    height: '163px',
    flex: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid #cccccc',
    borderBottom: '1px solid #cccccc',
    margin: '0 0 0 5px'
  },
  emptyGraphText: {
    color: theme.palette.text.contrastOnWhite,
    textAlign: 'center'
  },
  tall: {
    height: '320px'
  },
  pdf: {
    height: '210px'
  },
  nav_link: {
    '&:hover': {
      textDecorationLine: 'none !important'
    }
  }
}))

const EmptyGraph = props => {
  const classes = useStyles()
  return (
    <div
      className={cx(
        classes.emptyGraph,
        props.variant === 'tall' && classes.tall,
        props.variant === 'pdf' && classes.pdf
      )}
    >
      {props.type !== 'symptoms' ? (
        <h4 className={classes.emptyGraphText}>Not enough data</h4>
      ) : props.hasActiveCheckins ? (
        <h4 className={classes.emptyGraphText}>Not enough data</h4>
      ) : (
        <NavLink
          className={classes.nav_link}
          to={`${props.rootPath}/settings/symptom_tracking`}
        >
          <TertiaryButton round className={classes.button}>
            Assign a symptom tracker
          </TertiaryButton>
        </NavLink>
      )}
    </div>
  )
}

export default EmptyGraph
