import React from 'react'
import {
  AdjustableNextMeasure,
  TakeNowOutcome,
  PatientEmail,
  PatientPhoneNumber,
  AssignedClinicians,
  DateOfBirth,
  LastAssessment,
  LastCheckIn,
  SymptomTracking,
  Worksheets,
  Source,
  EnrollmentDate
} from '../../handlers/user-profiles/components/header-components'
import { PatientPreferencesMenu } from '../../containers'
import { ClientStatusBuckets } from '../../constants/clientStatusBuckets'
import { GridItem, Tooltip } from '@chakra-ui/react'

export const componentConfig = {
  PatientProfileHeader: {
    getHeaderAttributes: props => {
      const {
        data,
        activeAssessmentsData,
        activeCheckinModules,
        isSendingAssessment,
        handleSendPatientAssessmentLink,
        anchorEl,
        handleMenuOpen,
        handleMenuClose,
        nextDeliveryData,
        clinicians,
        clinicName,
        isEvidenceBasedCareEnabled
      } = props

      return [
        [
          {
            name: 'Date of Birth',
            value: <DateOfBirth patient={data} />,
            isHidden: !isEvidenceBasedCareEnabled
          },
          {
            name: 'Email',
            value: <PatientEmail patient={data} />,
            isHidden: !isEvidenceBasedCareEnabled
          },
          {
            name: 'Phone',
            value: <PatientPhoneNumber patient={data} />,
            isHidden: !isEvidenceBasedCareEnabled
          },
          {
            name: 'Source',
            value: <Source patient={data} />,
            isHidden: !isEvidenceBasedCareEnabled
          }
        ],
        [
          {
            name: <Tooltip label={`Clinic: ${clinicName}`}>Clinicians</Tooltip>,
            value: <AssignedClinicians clinicians={clinicians} />,
            isHidden: !isEvidenceBasedCareEnabled
          },
          {
            name: 'Assessments',
            value: (
              <TakeNowOutcome
                activeAssessments={activeAssessmentsData}
                patient={data}
                isSendingAssessment={isSendingAssessment}
                handleSendAssessment={handleSendPatientAssessmentLink}
                anchorEl={anchorEl}
                handleMenuOpen={handleMenuOpen}
                handleMenuClose={handleMenuClose}
              />
            ),
            isHidden: !isEvidenceBasedCareEnabled
          },
          {
            name: 'Symptom Trackers',
            value: (
              <SymptomTracking
                activeCheckinModules={activeCheckinModules}
                patient={data}
              />
            ),
            isHidden: !isEvidenceBasedCareEnabled
          },
          {
            name: 'Worksheets',
            value: (
              <Worksheets
                activeCheckinModules={activeCheckinModules}
                patient={data}
              />
            ),
            isHidden: !isEvidenceBasedCareEnabled
          }
        ],
        [
          {
            name: 'Last Assessment',
            value: <LastAssessment patient={data} />,
            isHidden: !isEvidenceBasedCareEnabled
          },
          {
            name: 'Last Check-in',
            value: <LastCheckIn patient={data} />,
            isHidden: !isEvidenceBasedCareEnabled
          },
          data.status === ClientStatusBuckets.AWAITING_INVITE
            ? {
                name: 'Enrollment Date',
                value: <EnrollmentDate patient={data} />,
                isHidden: !isEvidenceBasedCareEnabled
              }
            : {
                name: 'Next Assessment',
                value: (
                  <AdjustableNextMeasure
                    patient={data}
                    nextAdministrationDeliveryData={nextDeliveryData}
                  />
                ),
                isHidden: !isEvidenceBasedCareEnabled
              },
          {
            name: 'Programs',
            hideIfNull: true,
            value: data.programs.length
              ? data.programs.map(p => <p>{p.name}</p>)
              : null,
            isHidden: !isEvidenceBasedCareEnabled
          }
        ]
      ]
    },
    ProfileHeaderRight: function ProfileHeaderRight(props) {
      const { data, anchorEl, handleMenuOpen, handleMenuClose } = props

      return (
        <GridItem
          colStart={{
            base: 1,
            sm: 7,
            md: 12
          }}
          colEnd={{
            base: 5,
            sm: 9,
            md: 13
          }}
          mb={{ base: 'medium', sm: 0 }}
        >
          <PatientPreferencesMenu
            patient={data}
            handleMenuOpen={handleMenuOpen}
            handleMenuClose={handleMenuClose}
            anchorEl={anchorEl}
          />
        </GridItem>
      )
    }
  }
}
