import React from 'react'
import { HStack, Button, Box } from '@chakra-ui/react'
import {
  AssignWorksheetForm,
  AssignWorksheetFields
} from '../assign-worksheets-form'
import {
  AssistEnrollmentForm,
  AssistEnrollmentFormFields,
  ActionButtons
} from '../assist-enrollment-form'
import {
  DialogContainer,
  DialogHeader,
  DialogBody,
  DialogFooter
} from '@handlers/sessions/components/dialog'
import { useContentSuggestionControllerAccept } from '~/clinician-api'
import Library from '@handlers/sessions/components/shared/assist/worksheets/library'
import { AssignedCheckInDto } from '~/clinician-api/models'
import { FormikProps } from 'formik'
import { AssignableWorksheet, Patient } from '@handlers/sessions/types'

interface AssignWorksheetModalProps {
  sessionId?: string
  isOpen: boolean
  onClose: () => void
  onEnroll?: (values: any) => void
  isEnrolling?: boolean
  worksheet?: AssignableWorksheet | null
  setWorksheet?: (worksheet: any) => void
  client: Patient
  assignedCheckIn?: AssignedCheckInDto
}

export const AssignWorksheetsModal = ({
  isOpen,
  onClose,
  onEnroll = () => {},
  isEnrolling = false,
  worksheet,
  client,
  sessionId,
  assignedCheckIn,
  setWorksheet = () => {}
}: AssignWorksheetModalProps) => {
  const { mutate: acceptSuggestion } = useContentSuggestionControllerAccept()

  const handleOnAssign = () => {
    if (worksheet?.contentSuggestionId) {
      acceptSuggestion({ contentSuggestionId: worksheet.contentSuggestionId })
    }
    onClose()
  }

  const renderAssignForm = () => {
    return (
      <DialogBody p={0}>
        <AssignWorksheetForm
          onUpdate={onClose}
          onSubmit={handleOnAssign}
          sessionId={sessionId}
          checkInId={(worksheet?.checkInId || assignedCheckIn?.checkInId)!}
          patientId={client.id}
          assignedCheckIn={assignedCheckIn}
        >
          {({ isSubmitting }: FormikProps<any>) => (
            <>
              <Box p={6}>
                <AssignWorksheetFields
                  checkInId={
                    (worksheet?.checkInId || assignedCheckIn?.checkInId)!
                  }
                  client={client}
                />
              </Box>
              <DialogFooter>
                <HStack spacing={4} w="100%">
                  <Button onClick={onClose} variant="outline" isFullWidth>
                    Cancel
                  </Button>
                  <Button type="submit" isFullWidth isLoading={isSubmitting}>
                    {assignedCheckIn ? 'Save' : 'Assign'}
                  </Button>
                </HStack>
              </DialogFooter>
            </>
          )}
        </AssignWorksheetForm>
      </DialogBody>
    )
  }

  const renderEnrollmentForm = () => {
    return (
      <DialogBody p={0}>
        <AssistEnrollmentForm
          client={client}
          onSubmit={onEnroll}
          checkIn={
            worksheet
              ? {
                  checkInId: worksheet.checkInId,
                  contentSuggestionId: worksheet.contentSuggestionId
                }
              : undefined
          }
        >
          {({ formikProps, allAssessments, checkInId }: any) => (
            <>
              <Box p={6}>
                <AssistEnrollmentFormFields
                  formikProps={formikProps}
                  assessments={allAssessments}
                  client={client}
                  checkInId={checkInId}
                />
              </Box>
              <DialogFooter bg="#FAFAFA" p={6}>
                <ActionButtons
                  onCancel={onClose}
                  onSubmit={formikProps.submitForm}
                  isSubmitting={isEnrolling}
                />
              </DialogFooter>
            </>
          )}
        </AssistEnrollmentForm>
      </DialogBody>
    )
  }

  const showLibary = !worksheet && !assignedCheckIn
  const title = showLibary ? 'Worksheet Library' : 'Assign Worksheet'

  return (
    <DialogContainer isOpen={isOpen} onClose={onClose} size="2xl">
      <DialogHeader
        text={title}
        onClose={onClose}
        hideBorder={showLibary}
        onBack={
          showLibary || worksheet?.contentSuggestionId || assignedCheckIn
            ? undefined
            : () => setWorksheet(null)
        }
      />
      {showLibary ? (
        <DialogBody p={0}>
          <Library
            client={client}
            onAssign={(worksheet: any) => setWorksheet(worksheet)}
            searchContainerProps={{
              py: 1,
              pb: 6,
              height: 'auto'
            }}
          />
        </DialogBody>
      ) : client.enrollment_timestamp ? (
        renderAssignForm()
      ) : (
        renderEnrollmentForm()
      )}
    </DialogContainer>
  )
}
