import {
  Box,
  Checkbox,
  Menu,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup
} from '@chakra-ui/react'
import { FixedSizeList as List, ListChildComponentProps } from 'react-window'
import React, { useMemo, useRef } from 'react'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { CustomMenuButton } from './filters'
import { Chip } from './chip'

type ClinicMultiSelectorProps = {
  onChange: (clinicIds: string[]) => any
  organizationId: string
  selectedClinicIds: string[]
}

const selectAllOptionValue = 'all'
const selectAllOptionLabel = 'All'

export const ClinicMultiSelector: React.FC<ClinicMultiSelectorProps> = ({
  onChange,
  organizationId,
  selectedClinicIds
}) => {
  const { data: clinics } = useQuery(
    [endpoints.getOrganizationClinicsV2.getCacheId(), organizationId],
    () => endpoints.getOrganizationClinicsV2.request({ organizationId }),
    {
      enabled: !!organizationId,
      placeholderData: []
    }
  )
  const allClinicIds = clinics?.map(clinic => clinic.id) || []

  const listRef = useRef<List>(null)

  let options = useMemo(
    () => (clinics || []).map(c => ({ value: c.id, label: c.name })),
    [clinics]
  )
  options = [
    { value: selectAllOptionValue, label: selectAllOptionLabel },
    ...options
  ]

  let chips
  if (selectedClinicIds?.length === clinics?.length) {
    chips = <Chip label={selectAllOptionLabel} />
  } else {
    const selectedClinics = clinics?.filter(clinic =>
      selectedClinicIds.includes(clinic.id)
    )
    chips = selectedClinics?.map(clinic => (
      <Chip
        key={`clinic-chip-${clinic.id}`}
        label={clinic.name}
        onClose={() => onClearClinicChip(clinic.id)}
      />
    ))
  }

  const onClearClinicChip = (clinicId: string) => {
    if (selectedClinicIds.length === 1) {
      // If removing the last clinic in the filter list, revert to All clinics
      onChange(clinics?.map(clinic => clinic.id) || [])
    } else {
      onChange(selectedClinicIds?.filter(id => id !== clinicId))
    }
  }

  const Row = ({ index, style }: ListChildComponentProps) => {
    if (options[index].value === selectAllOptionValue) {
      return (
        <MenuItem key={options[index].value} style={style}>
          <Checkbox
            w="100%"
            key="all"
            value="all"
            isChecked={clinics?.length === selectedClinicIds?.length}
            onChange={() => onChange((clinics || []).map(c => c.id))}
          >
            All
          </Checkbox>
        </MenuItem>
      )
    }
    const isSelected = selectedClinicIds?.includes(options[index].value)
    const allSelected = selectedClinicIds?.length === clinics?.length
    return (
      <MenuItem key={options[index].value} style={style}>
        <Checkbox
          w="100%"
          value={options[index].value}
          isChecked={isSelected && !allSelected}
          onChange={() => handleChange(options[index].value)}
        >
          {options[index].label}
        </Checkbox>
      </MenuItem>
    )
  }

  const handleChange = (clinicId: string) => {
    if (selectedClinicIds.length === clinics?.length) {
      // If all clinics selected, selecting one clinic should mean narrowing search to that one
      return onChange([clinicId])
    }
    if (selectedClinicIds.includes(clinicId)) {
      // If clinic already selected, we should de-select it
      return onChange(selectedClinicIds.filter(id => id !== clinicId))
    }
    if (selectedClinicIds.length === 1 && selectedClinicIds[0] === clinicId) {
      // If last clinic de-selected, should revert to all clinics
      return onChange(allClinicIds)
    }
    // Add clinic to selected
    return onChange([...new Set([...selectedClinicIds, clinicId])])
  }

  const optionHeight = 46
  const numClinics = options.length

  return (
    <Box>
      <Menu onClose={() => listRef.current?.scrollToItem(0)}>
        <CustomMenuButton
          onClick={() => {}}
          label="Clinics"
          chips={chips}
          isMenu
        />
        <MenuList sx={{ maxHeight: 500, overflow: 'scroll' }}>
          <MenuOptionGroup type="checkbox">
            <List
              ref={listRef}
              height={Math.min(numClinics * optionHeight, 460)}
              itemCount={numClinics}
              itemSize={optionHeight}
              width={300}
            >
              {Row}
            </List>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  )
}
