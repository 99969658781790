import React from 'react'
import {DialogContentText, MenuItem, Select} from "@material-ui/core";

import { useStyles } from '../style'
import { PrimaryButton, SecondaryButton } from "../../../components";
import { usePermissions } from "../../../hooks"

export const TransferPatientModalContent = ({ clinicianList, selectedClinician, handleSelectClinician, submit, handleCloseModal, error }) => {
  const { hasPermission } = usePermissions()
  const classes = useStyles()
  return (
    <>
      <DialogContentText className={classes.centerText}>
        {"Select who you'd like to transfer this client to."}
      </DialogContentText>
      <Select
        variant="outlined"
        className={classes.selector}
        value={selectedClinician}
        renderValue={value =>
          (value && (`${value.first_name} ${value.last_name}`)) || 'Select a clinician'
        }
        displayEmpty
      >
        {clinicianList.map(c => {
          return (
            <MenuItem disabled={c.clinician_rank === 'primary'} key={c.id} onClick={() => handleSelectClinician(c)}>
              {`${c.first_name} ${c.last_name}`}
            </MenuItem>
          )
        })}
      </Select>
      <div className={classes.buttonContainer}>
        <DialogContentText className={classes.centerText}>
          {hasPermission('update:clinic:activeclients:profile')
            ? 'Client will be unassigned from all current clinicians.'
            : 'Client will no longer appear in your portal.'}
        </DialogContentText>
        <DialogContentText className={classes.errorText}>
          {error || null}
        </DialogContentText>
        <div className={classes.buttonRow}>
          <SecondaryButton
            onClick={handleCloseModal}
            round
            className={classes.button}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={() => submit()}
            round
            className={classes.button}
          >
            Confirm
          </PrimaryButton>
        </div>
      </div>
    </>
  )
}
