import React from 'react'
import { HStack, Stack, Box } from '@chakra-ui/react'
import { AssignWorksheetFields, AssignWorksheetForm } from '../../shared/assist/assign-worksheets-form'
import { Button } from '@blueprinthq/joy'
import { FormikProps } from 'formik'
import { useTreatmentPlan } from '@hooks'
import { useAssistPanelContext } from '../context'

export const AssignWorksheets = () => {
  const { setOverlayPanel, data } = useAssistPanelContext()
  const { client } = useTreatmentPlan()
  const onCancel = () => setOverlayPanel(null)

  const { worksheet, sessionId } = data

  return (
    <AssignWorksheetForm
      sessionId={sessionId}
      contentSuggestionId={worksheet.suggestionId}
      checkInId={worksheet.checkInId}
      patientId={client.id}
      onSubmit={() => onCancel()}
    >
       {({ isSubmitting }: FormikProps<any>) => (
        <Box>
          <Stack flex="1" spacing="0" overflowY="hidden">
              <Stack p="24px" h="calc(100vh - 262px)" overflowY="scroll">
                <AssignWorksheetFields
                  client={client}
                  checkInId={worksheet.checkInId}
                />
              </Stack>
              <HStack
                p="24px"
                borderTop="1px solid"
                borderColor="pale_gray"
                position="sticky"
              >
                <Button
                  variant="outline"
                  m="0"
                  size="lg"
                  isFullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  m="0"
                  size="lg"
                  isFullWidth
                >
                  Assign
                </Button>
              </HStack>
            </Stack>
        </Box>
      )}
    </AssignWorksheetForm>
  )
}