import React from 'react'

import styles from './summary.module.css'
import OutcomeMeasuresSection from './outcome-measures-section'
import { LifestyleSection } from './lifestyle-section'
import { TopSymptomsSection } from './top-symptoms-section'
import TreatmentActivitiesSection from './worksheets-section'
import { Container, GridItem } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import { InterventionRecommendationsBox } from '../../../../../components/interventions/intervention-recommendations-summary'

const Summary = ({ assessmentScores, ...rest }) => {
  return (
    <>
      <div id="summary-container" className={styles.container}>
        <Container mt={'12px'}>
          <LayoutGrid>
            <GridItem
              colSpan={{
                base: 12,
                md: 12,
                lg: 12
              }}
              colStart={{
                base: 0,
                md: 0,
                lg: 0
              }}
            >
              <InterventionRecommendationsBox
                clientId={rest.patient.id}
                openInNewTab={false}
              />
            </GridItem>
            <GridItem
              colSpan={{
                base: 12,
                md: 8,
                lg: 8
              }}
              colStart={{
                base: 0,
                md: 0,
                lg: 0
              }}
              marginBottom={{ base: 'large', lg: 'large' }}
            >
              <OutcomeMeasuresSection
                allAssessmentScores={assessmentScores}
                {...rest}
              />
            </GridItem>
            <GridItem
              colSpan={{
                base: 12,
                md: 4,
                lg: 4
              }}
              colStart={{
                base: 0,
                md: 9,
                lg: 9
              }}
              marginBottom={{ base: 'large', lg: 'large' }}
            >
              <LifestyleSection {...rest} />
            </GridItem>
            <GridItem
              colSpan={{
                base: 12,
                md: 6,
                lg: 6
              }}
              colStart={{
                base: 0,
                md: 0,
                lg: 0
              }}
              mb={{ base: 'large', sm: 'large', lg: 'xxlarge' }}
            >
              <TopSymptomsSection {...rest} />
            </GridItem>
            <GridItem
              colSpan={{
                base: 12,
                md: 6,
                lg: 6
              }}
              colStart={{
                base: 0,
                md: 7,
                lg: 7
              }}
              mb={{ base: 'large', sm: 'large' }}
            >
              <TreatmentActivitiesSection {...rest} />
            </GridItem>
          </LayoutGrid>
        </Container>
      </div>
    </>
  )
}

export default Summary
