import { useContext } from 'react'
import { ExtensionContext } from '@context'

export const useExtension = () => {
  const context = useContext(ExtensionContext)

  if (context === undefined) {
    throw new Error('useExtension must be used within an ExtensionProvider')
  }

  return context
}
