import React from 'react'
import { Flex } from '@chakra-ui/react'
import PlanOption from './plan-option'
import { useExperienceManager } from '@hooks'
import plans from '../constants/plans'
import { Plan } from '../types'
import { CurrentPlanResponseDto } from '~/clinician-api/models'

interface PlanSelectionProps {
  selectedPlan: string | undefined
  setSelectedPlan: (plan: Plan) => void
  currentPlan: CurrentPlanResponseDto
}

const PlanSelection: React.FC<PlanSelectionProps> = ({
  selectedPlan,
  setSelectedPlan,
  currentPlan
}) => {
  const { hasPlusPlanFreeTrial } = useExperienceManager()

  return (
    <Flex
      gap={{ base: '24px' }}
      flexDirection={{ base: 'column', sm: 'row', md: 'row' }}
    >
      {plans.map(plan => (
        <PlanOption
          key={plan.value}
          value={plan.value}
          title={plan.title}
          isChecked={selectedPlan === plan.value}
          onChange={setSelectedPlan}
          features={plan.features}
          showCurrentTrial={currentPlan.isFree && plan.value === Plan.Plus}
          showPlusPlanEnabled={
            hasPlusPlanFreeTrial && plan.value === Plan.Standard
          }
          isDisabled={hasPlusPlanFreeTrial && plan.value === Plan.Plus}
        />
      ))}
    </Flex>
  )
}

export default PlanSelection
