const styles = theme => ({
  section: {
    flex: '0 0 65%',
    boxSizing: 'border-box',
    paddingRight: '24px',
    paddingBottom: '48px',
    paddingTop: '16px'
  },
  sub_score: {
    fontSize: '14px',
    color: theme.palette.text.contrastOnWhite,
    paddingTop: '4px'
  }
})

export default styles
