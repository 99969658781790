import React from 'react'

export const CopyNoteIcon = ({ fill }: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6668 0.666656H2.66683C1.9335 0.666656 1.3335 1.26666 1.3335 1.99999V11.3333H2.66683V1.99999H10.6668V0.666656ZM12.6668 3.33332H5.3335C4.60016 3.33332 4.00016 3.93332 4.00016 4.66666V14C4.00016 14.7333 4.60016 15.3333 5.3335 15.3333H12.6668C13.4002 15.3333 14.0002 14.7333 14.0002 14V4.66666C14.0002 3.93332 13.4002 3.33332 12.6668 3.33332ZM12.6668 14H5.3335V4.66666H12.6668V14Z"
        fill={fill || 'white'}
      />
    </svg>
  )
}
