import React, { useEffect } from 'react'
import { Box, Flex, Heading, Text, SimpleGrid, Link } from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import BillingContactModal from './components/billing-contact-modal'
import { Select as JoySelect } from '@blueprinthq/joy'
import { Skeleton } from '@chakra-ui/react'
import { Select } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import {
  usePermissions,
  useExperienceManager,
  useClinicSelectControl
} from '@hooks'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import PaymentMethodModal from './components/payment-method-modal'
import { BillingTable } from './components/billing-table'
import { Loading } from '@components'
import { EvidenceBasedCareBox } from './components/ebc-box'
import { DocAutomationBox } from './components/da-box'

export const BillingTab = () => {
  const { user } = useStoreState(state => state.auth)
  const location = useLocation()
  const params = new URLSearchParams(location.search).get('openModal')
  const history = useHistory()
  const { hasPermission } = usePermissions()
  const [selectedClinic, setSelectedClinic] = React.useState(user?.clinic)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = React.useState(false)
  const [amountOfInvoicesToLoad, setAmountOfInvoicesToLoad] = React.useState(12)
  const [totalInvoiceCount, setTotalInvoiceCount] = React.useState(0)
  const { isAdmin, isEvidenceBasedCareEnabled } = useExperienceManager()

  const { data: clinics } = useQuery(
    [
      endpoints.getOrganizationClinics.getCacheId(),
      selectedClinic && selectedClinic.organization_id
    ],
    () =>
      endpoints.getOrganizationClinics.request({
        organizationId: user.clinic.organization_id
      }),
    {
      initialData: [],
      enabled: hasPermission('read:org:clinics:list'),
      keepPreviousData: true,
      onSuccess(data) {
        // default to the currently selected clinic, otherwise use the user's clinic
        setSelectedClinic(
          data.find(clinic => clinic.id === selectedClinic?.id) ||
            data.find(clinic => clinic.id === user.clinic.id)
        )
      }
    }
  )

  const { data: billing, isFetching: isBillingLoading } = useQuery(
    [
      endpoints.getClinicBilling.getCacheId(),
      selectedClinic && selectedClinic.id
    ],
    () =>
      endpoints.getClinicBilling.request({
        clinicId: selectedClinic.id
      }),
    {
      initialData: [],
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        // default to the currently selected clinic, otherwise use the user's clinic
        return data
      }
    }
  )

  const { data: invoices, isFetching: isInvoicesLoading, refetch } = useQuery(
    [
      endpoints.getClinicInvoices.getCacheId(),
      selectedClinic && selectedClinic.id
    ],
    () =>
      endpoints.getClinicInvoices.request({
        clinicId: selectedClinic.id,
        amountOfInvoicesToLoad: amountOfInvoicesToLoad
      }),
    {
      initialData: [],
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        // default to the currently selected clinic, otherwise use the user's clinic
        return data
      }
    }
  )

  useEffect(() => {
    if (params) {
      setIsPaymentModalOpen(true)
    }
  }, [params])

  useEffect(() => {
    refetch()
  }, [amountOfInvoicesToLoad])

  const onLoadMoreInvoices = async () => {
    setAmountOfInvoicesToLoad(totalInvoiceCount)
  }

  useEffect(() => {
    if (invoices.invoices && invoices.invoices.invoices.length > 0) {
      const { totalCount } = invoices.invoices
      setTotalInvoiceCount(totalCount)
    }
  }, [invoices.invoices])

  const handleContactSupport = () => {
    window.Intercom('showNewMessage', "Hi, I'd like to cancel my subscription.")
  }

  useEffect(() => {
    if (!isAdmin) {
      history.push('/dashboard')
    }
  }, [isAdmin])

  const { clinicSelectEnabled } = useClinicSelectControl(clinics.length)

  if (isBillingLoading) return <Loading />

  return (
    <>
      <Box mb="xlarge">
        <Box>
          <Flex justify="space-between" alignItems="center">
            <Box w={'100%'}>
              <Heading mb="xxsmall">Billing</Heading>
              <Text>Manage your Blueprint subscription</Text>
            </Box>
          </Flex>

          <Flex justify="space-evenly" alignItems="center" w="100%" mt="medium">
            {clinicSelectEnabled && (
              <Box w="100%" mb={'small'}>
                {hasPermission('read:any:clinics:list') ? (
                  <Select
                    value={selectedClinic.id}
                    style={{ border: '1px solid #E4E5E6' }}
                    borderRadius="4px"
                    height="48px"
                    _focus={{
                      outline: 'none'
                    }}
                    onChange={e => {
                      setAmountOfInvoicesToLoad(12)
                      setSelectedClinic(
                        clinics.find(c => c.id === e.target.value)
                      )
                    }}
                  >
                    {clinics.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      )
                    })}
                  </Select>
                ) : (
                  <JoySelect
                    value={selectedClinic}
                    options={clinics}
                    valueKey="id"
                    labelKey="name"
                    onChange={value => {
                      setAmountOfInvoicesToLoad(12)
                      setSelectedClinic(value)
                    }}
                  />
                )}
              </Box>
            )}
          </Flex>
          {isEvidenceBasedCareEnabled && (
            <EvidenceBasedCareBox
              showNewBillingFlow
              billingData={billing}
              selectedClinic={selectedClinic}
            />
          )}
          <DocAutomationBox
            billingData={billing}
            selectedClinic={selectedClinic}
          />
          <SimpleGrid
            columns={[1, 1, 2]}
            p={'24px'}
            borderRadius={'8px'}
            border={'1px solid #E4E5E6'}
          >
            <Box>
              <Text fontSize={'16px'}>Invoices sent to</Text>
              <Text fontSize={'16px'} fontWeight={'bold'}>
                {isBillingLoading && <Skeleton height="30px" width="218px" />}
                {!isBillingLoading && billing?.billingInfo?.email}
              </Text>
              {!isBillingLoading && (
                <Text
                  _focus={{ outline: 'none' }}
                  fontSize={'16px'}
                  color={'primary'}
                  _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setIsModalOpen(true)}
                >
                  Change contact
                </Text>
              )}
            </Box>
            <Box>
              <Text fontSize={'16px'}>Payment method</Text>
              <Text fontSize={'16px'} fontWeight={'bold'}>
                {isBillingLoading && <Skeleton height="30px" width="218px" />}
                {!isBillingLoading && billing?.billingInfo?.card && (
                  <>
                    <Text
                      display={'inline'}
                      fontSize={'16px'}
                      textTransform={'capitalize'}
                      fontWeight={'bold'}
                    >
                      {billing?.billingInfo?.card?.card?.brand}
                    </Text>{' '}
                    {`ending in **** ${billing?.billingInfo?.card?.card?.last4}`}
                  </>
                )}
              </Text>

              {!isBillingLoading && (
                <Text
                  _focus={{ outline: 'none' }}
                  fontSize={'16px'}
                  color={'primary'}
                  _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setIsPaymentModalOpen(true)}
                >
                  Change payment method
                </Text>
              )}
            </Box>
          </SimpleGrid>
          <Box mt={'40px'} w={'100%'}>
            <Heading fontSize={'16px'} mb="xxsmall">
              Invoices
            </Heading>
          </Box>
          <BillingTable
            billing={invoices}
            isBillingLoading={isInvoicesLoading}
            loadAll={() => onLoadMoreInvoices()}
            hasMore={invoices?.invoices?.hasMore}
          />
          {!isBillingLoading && isEvidenceBasedCareEnabled && (
            <Box
              w="100%"
              border="1px solid"
              borderColor="#E4E5E6"
              borderRadius="8px"
              mt={'40px'}
              mb={'small'}
              bg="white"
              p={'24px'}
              verticalAlign={'middle'}
            >
              <Text fontSize={'16px'}>
                {' '}
                Want to permanently delete your Blueprint account?{' '}
                {!billing.canCancel ? (
                  <Link color="#EB5164" onClick={handleContactSupport}>
                    Contact support
                  </Link>
                ) : (
                  <Link as={RouterLink} color="#EB5164" to="/delete">
                    Delete your account
                  </Link>
                )}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      {!isBillingLoading && billing && (
        <BillingContactModal
          isModalOpen={isModalOpen}
          handleModal={() => setIsModalOpen(false)}
          selectedClinic={selectedClinic}
          currentEmail={billing?.billingInfo?.email}
        />
      )}
      {!isBillingLoading && billing && (
        <PaymentMethodModal
          isModalOpen={isPaymentModalOpen}
          handleModal={() => setIsPaymentModalOpen(false)}
          selectedClinic={selectedClinic}
          invoices={
            !isBillingLoading && !isInvoicesLoading && invoices.invoices
              ? invoices.invoices.invoices
              : []
          }
        />
      )}
    </>
  )
}
