import React from 'react'

const GraphicEq = () => {
  return (
    <svg
      width="33"
      height="43"
      viewBox="0 0 33 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2801 42.4888H3.71988C1.65825 42.4888 0 40.828 0 38.7882V4.20064C0 2.14969 1.66946 0.5 3.71988 0.5H22.7787L32 10.0303V38.7994C32 40.8503 30.3305 42.5 28.2801 42.5V42.4888Z"
        fill="#323232"
      />
      <path
        opacity="0.8"
        d="M10.9595 26.75C10.9595 27.3023 11.4072 27.75 11.9595 27.75C12.5118 27.75 12.9595 27.3023 12.9595 26.75V16.75C12.9595 16.1977 12.5118 15.75 11.9595 15.75C11.4072 15.75 10.9595 16.1977 10.9595 16.75V26.75ZM14.9595 30.75C14.9595 31.3023 15.4072 31.75 15.9595 31.75C16.5118 31.75 16.9595 31.3023 16.9595 30.75V12.75C16.9595 12.1977 16.5118 11.75 15.9595 11.75C15.4072 11.75 14.9595 12.1977 14.9595 12.75V30.75ZM6.95947 22.75C6.95947 23.3023 7.40719 23.75 7.95947 23.75C8.51176 23.75 8.95947 23.3023 8.95947 22.75V20.75C8.95947 20.1977 8.51176 19.75 7.95947 19.75C7.40719 19.75 6.95947 20.1977 6.95947 20.75V22.75ZM18.9595 26.75C18.9595 27.3023 19.4072 27.75 19.9595 27.75C20.5118 27.75 20.9595 27.3023 20.9595 26.75V16.75C20.9595 16.1977 20.5118 15.75 19.9595 15.75C19.4072 15.75 18.9595 16.1977 18.9595 16.75V26.75ZM23.9595 19.75C23.4072 19.75 22.9595 20.1977 22.9595 20.75V22.75C22.9595 23.3023 23.4072 23.75 23.9595 23.75C24.5118 23.75 24.9595 23.3023 24.9595 22.75V20.75C24.9595 20.1977 24.5118 19.75 23.9595 19.75Z"
        fill="white"
      />
      <path
        opacity="0.46"
        d="M32.4173 10.04H22.6873V0L32.4173 10.04Z"
        fill="white"
      />
    </svg>
  )
}

export default GraphicEq
