import React, { useMemo } from 'react'
import { BPDateTimePicker } from '../../components'
import { endpoints } from '../../api/index'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import moment from 'moment'
import { useStoreActions, useStoreState } from 'easy-peasy'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useBreakpointValue,
  Text,
  Box,
  Flex,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'

export function AdjustEnrollmentDateModal({
  patientId,
  isOpen,
  handleCloseModal
}) {
  const queryClient = useQueryClient()
  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const { data: patient } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), patientId],
    () => endpoints.getClinicianUserAccount.request({ id: patientId }),
    {
      enabled: isOpen && !!patientId
    }
  )

  const initialDate = useMemo(() => {
    let dt = moment().add(1, 'day')
    let meridian = null
    if (
      patient &&
      patient.date_of_invite &&
      moment().isBefore(patient.date_of_invite, 'minute')
    ) {
      dt = moment(patient.date_of_invite)
      meridian = dt.format('a')
    }

    return { date: dt.format('MM-DD-YYYY hh:mm a'), meridian }
  }, [patient])

  const { mutateAsync: executeAdjustEnrollmentDate } = useMutation(
    endpoints.patchCliniciansUsersPreEnroll.request,
    {
      onError: () => {
        setSnackbarMessage({
          variant: 'error',
          message: 'Error saving enrollment date and time'
        })
        handleCloseModal()
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          endpoints.getClinicianUserAccount.getCacheId()
        )
        setSnackbarMessage({
          variant: 'success',
          message: 'Enrollment date and time saved!'
        })
        handleCloseModal()
      }
    }
  )

  const modalSize = useBreakpointValue({
    base: 'full',
    md: 'lg'
  })

  return (
    <>
      <Modal
        onClose={handleCloseModal}
        size={modalSize}
        isOpen={isOpen}
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent p="small">
          <ModalHeader>Adjust Date & Time of Enrollment</ModalHeader>
          <ModalCloseButton />
          <Formik
            validationSchema={Yup.object().shape({
              dateOfInvite: Yup.date()
                .min(moment(), 'Enrollment date must be in the future')
                .required('You must provide a date and time for enrollment')
            })}
            initialValues={{
              dateOfInvite: initialDate.date
            }}
            onSubmit={async (values, actions) => {
              const data = {
                date_of_invite: moment(
                  values.dateOfInvite,
                  'MM/DD/YYYY hh:mm a'
                )
              }

              await executeAdjustEnrollmentDate({ patientId, data })
              actions.setSubmitting(false)
              actions.resetForm({})
            }}
            initialErrors={{ dateOfInvite: '' }}
          >
            {({ errors, touched, isSubmitting, isValid, dirty }) => (
              <Form>
                <ModalBody>
                  <Text>
                    Select the date and time you want us to send{' '}
                    {patient.first_name} an invitation to start using Blueprint
                  </Text>
                  <Box mt="large">
                    <Field name="dateOfInvite" id="enrollment-date-picker">
                      {({ field, form }) => (
                        <BPDateTimePicker
                          {...field}
                          autoFocus
                          showDate
                          showTime
                          form={form}
                          fieldName={field.name}
                          initialMeridian={initialDate.meridian}
                          isRequired
                          label="Choose a date and time"
                          isInvalid={
                            (errors.dateOfInvite && touched.dateOfInvite) ||
                            !isValid
                          }
                          errorText={
                            (errors.dateOfInvite &&
                              errors.dateOfInvite.includes('Invalid') &&
                              'Invalid date') ||
                            errors.dateOfInvite
                          }
                        />
                      )}
                    </Field>
                  </Box>
                </ModalBody>
                <ModalFooter display="block">
                  <Flex flex={1} justify={'center'} align={'center'}>
                    <Button
                      size="lg"
                      isLoading={isSubmitting}
                      isDisabled={!isValid || !dirty}
                      type="submit"
                      isFullWidth
                    >
                      Confirm
                    </Button>
                  </Flex>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}

export const AdjustEnrollmentDateModalConnected = () => {
  const user = useStoreState(state => state.auth.user)
  const { isOpen, patientId } = useStoreState(
    state => state.modals.adjustEnrollmentDate
  )
  const handleCloseModal = useStoreActions(
    actions => actions.modals.adjustEnrollmentDate.closeModal
  )

  return patientId && user ? (
    <AdjustEnrollmentDateModal
      isOpen={isOpen}
      patientId={patientId}
      handleCloseModal={handleCloseModal}
    />
  ) : null
}
