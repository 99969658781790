import React, { createContext, useState, useEffect, FC } from 'react'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'

interface ExtensionContextType {
  ehrClientNames: string[]
  ehrClientName: string | undefined | null
  settings: {
    isDraggable?: boolean
    isAlwaysOpen?: boolean
    copyNoteButtonText?: string
    patientReference?: string
    hideTreatmentPlan?: string
  }
}

export const ExtensionContext = createContext<ExtensionContextType>(
  {} as ExtensionContextType
)

interface ExtensionProviderProps {
  children: React.ReactNode
}

export const ExtensionProvider: FC<ExtensionProviderProps> = ({ children }) => {
  // client names to suggest in a list
  const [ehrClientNames, setEhrClientNames] = useState<string[]>([])
  // client name to auto select for recording
  const [ehrClientName, setEhrClientName] = useState<
    string | null | undefined
  >()
  const [settings, setSettings] = useState({})

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.type === 'BP_EHR_CLIENT_INFO') {
        const clientNames = event.data.ehrClientNames

        if (clientNames?.length) {
          // @ts-ignore
          const [firstClient, secondClient, thirdClient] = [
            ...new Set(clientNames)
          ] as string[]

          // maintain a list of the last 3 visited clients
          setEhrClientNames(
            [firstClient, secondClient, thirdClient].filter(Boolean)
          )
        }
      }

      if (event.data?.type === 'BP_EHR_CLIENT_NAME') {
        setEhrClientName(event.data.ehrClientName)
      }

      if (event.data?.type === 'BP_NAVIGATED_FROM_CLIENT') {
        setEhrClientName(null)
      }

      if (event.data?.type === 'BP_SETTINGS') {
        setSettings(event.data.settings)
      }
    }

    window.addEventListener('message', handleMessage)

    // eslint-disable-next-line
    parent.postMessage({ type: 'BP_EXTENSION_CONTEXT_LOADED' }, '*')

    return () => window.removeEventListener('message', handleMessage)
  }, [])

  return (
    <ExtensionContext.Provider
      value={{
        ehrClientNames,
        ehrClientName,
        settings
      }}
    >
      {children}
    </ExtensionContext.Provider>
  )
}
