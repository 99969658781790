import React, { useMemo } from 'react'
import { Button } from '@blueprinthq/joy'
import { useStoreActions } from 'easy-peasy'
import { MeasureTypes } from '@constants/measureTypes'
import { buildCheckInPreviewUrl } from '@utilities'

export const AssignWorksheetsModal = ({
  worksheets,
  clientId,
  onAssign,
  selectedWorksheets
}) => {
  const openAssignWorksheetsModal = useStoreActions(
    actions => actions.modals.assignMeasures.openModal
  )

  const allWorksheets = useMemo(() => {
    return worksheets.map(m => ({
      measureId: m.id,
      title: m.title,
      subtitle: m.subtitle,
      numQuestions: m.num_questions,
      previewUrl: buildCheckInPreviewUrl(m.id),
      isAssigned:
        m.isActive || selectedWorksheets.map(sw => sw.id).includes(m.id)
    }))
  }, [worksheets, selectedWorksheets])

  return (
    <Button
      variant="link"
      onClick={() =>
        openAssignWorksheetsModal({
          clientId,
          measureType: MeasureTypes.WORKSHEET,
          allMeasures: allWorksheets,
          onAssign
        })
      }
    >
      Assign Worksheets
    </Button>
  )
}
