import React from 'react'
import { Flex, Text, Button } from '@chakra-ui/react'
import { useAudioCapture } from '@hooks'
import { DateTime } from 'luxon'

interface AutoStopTimerProps {
  recordingStartedAt: string | undefined | null
}

export const AutoStopTimer: React.FC<AutoStopTimerProps> = ({
  recordingStartedAt
}) => {
  const {
    stopRecordingAfter,
    setIsStopRecordingAfterEnabled
  } = useAudioCapture()

  const cutoffTime = recordingStartedAt 
    ? DateTime.fromISO(recordingStartedAt).plus({
        minutes: parseInt(stopRecordingAfter)
      })
    : DateTime.utc()

  const minutesLeft = Math.max(0, Math.ceil(
    cutoffTime.diff(DateTime.utc(), 'seconds').seconds / 60
  ))

  const handleCancel = () => {
    setIsStopRecordingAfterEnabled(false)
  }

  return (
    <Flex
      px={2}
      py={1}
      w="320px"
      h="48px"
      color="black"
      bg="white"
      borderRadius="4px"
      alignItems="center"
      justifyContent="center"
    >
      <Text color={'black'}>Recording will stop in {minutesLeft} min</Text>
      <Button
        ml={2}
        variant="link"
        onClick={handleCancel}
        textDecoration="none"
        _hover={{ textDecoration: 'none' }}
      >
        Cancel
      </Button>
    </Flex>
  )
}