import React from 'react'

const MicAI = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '88'}
    height={props.height || '88'}
    viewBox="0 0 24 24"
    fill="none"
  >
    <defs>
      <filter
        id="fillpartial"
        primitiveUnits="objectBoundingBox"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
      >
        <feFlood floodColor="#68E19F" floodOpacity="1" result="floodFill" />
        <feOffset dy={props.dy} in="floodFill" result="offsetFlood" />
        <feComposite operator="in" in="offsetFlood" in2="SourceGraphic" />
        <feComposite operator="over" in2="SourceGraphic" />
      </filter>
    </defs>
    <path
      d="M17.5 1L18.1752 2.82477L20 3.5L18.1752 4.17523L17.5 6L16.8248 4.17523L15 3.5L16.8248 2.82477L17.5 1Z"
      fill={props.fill || 'white'}
    />
    <path
      d="M12 15C13.66 15 14.99 13.66 14.99 12L15 6C15 4.34 13.66 3 12 3C10.34 3 9 4.34 9 6V12C9 13.66 10.34 15 12 15Z"
      fill={props.fill || 'white'}
      filter={props.dy > 0.9 || !props.dy ? 'none' : 'url(#fillpartial)'}
    />
    <path
      d="M17.3 12C17.3 15 14.76 17.1 12 17.1C9.24 17.1 6.7 15 6.7 12H5C5 15.42 7.72 18.23 11 18.72V22H13V18.72C16.28 18.24 19 15.42 19 12H17.3Z"
      fill={props.fill || 'white'}
    />
    <path
      d="M6.40514 7.59486L6 6.5L5.59486 7.59486L4.5 8L5.59486 8.40514L6 9.5L6.40514 8.40514L7.5 8L6.40514 7.59486Z"
      fill={props.fill || 'white'}
    />
  </svg>
)

export default MicAI
