import React, { useState, useEffect } from 'react'
import { Stack, HStack, Text, Skeleton } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { useRouteMatch } from 'react-router-dom'
import { useTreatmentPlan, useExperienceManager } from '@hooks'
import {
  useSessionControllerGetSuggestedContent,
  useSessionControllerGetDemoSuggestedContent
} from '~/clinician-api'
import ContentSuggestionCard from '@handlers/sessions/components/shared/assist/assessments/suggestion-card'
import { Pagination } from '@handlers/sessions/components/shared/pagination'

import { useAssistPanelContext } from '../context'
import { buildCheckInPreviewUrl } from '@utilities'

export const Suggestions = () => {
  const [showDemoLoading, setShowDemoLoading] = useState(true)
  const { client } = useTreatmentPlan()
  let match: any = useRouteMatch('/patient/:patientId/session/:sessionId')
  const [suggestedContentIndex, setSuggestedContentIndex] = useState(0)

  const { isDemoClinician } = useExperienceManager()

  const { setOverlayPanel } = useAssistPanelContext()

  const lastSessionQuery: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId()],
    async () => {
      return await endpoints.getClinicianClientLastSession.request({
        id: client.id
      })
    }
  )

  const sessionId = lastSessionQuery?.data?.id
  const showDemoExperience = client.is_demo && isDemoClinician

  useEffect(() => {
    if (showDemoExperience) {
      setTimeout(() => {
        setShowDemoLoading(false)
      }, 1000)
    } else {
      setShowDemoLoading(false)
    }
  }, [showDemoExperience])

  const suggestionsQuery = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      },
      enabled: !!sessionId && !showDemoExperience
    }
  })

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId || match.params.sessionId,
    { demoContext: 'in-session' },
    {
      query: {
        enabled: showDemoExperience
      }
    }
  )

  const suggestion = showDemoExperience
    ? demoData?.suggestedInterventions[suggestedContentIndex]
    : suggestionsQuery?.data?.suggestedInterventions?.[suggestedContentIndex]

  if (suggestionsQuery.isLoading || showDemoLoading) {
    return (
      <Stack mb="small">
        <HStack justifyContent="space-between">
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" width="100px" />
        </HStack>
        <Skeleton height="113px" borderRadius="8px" />
      </Stack>
    )
  }

  if (!suggestion) {
    return (
      <Stack spacing="0" gap="8px" mb="small">
        <HStack spacing={3}>
          <Text fontWeight="bold">Suggestions</Text>
          <Text
            fontSize="12px"
            color="primary"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {}}
          >
            Browse all
          </Text>
        </HStack>
        <HStack
          spacing="0"
          p="16px"
          gap="12px"
          border="1px solid"
          borderColor="pale_gray"
          borderRadius="8px"
        >
          <Text>No suggestions available.</Text>
        </HStack>
      </Stack>
    )
  }

  const count = showDemoExperience
    ? demoData?.suggestedWorksheets?.length || 0
    : suggestionsQuery?.data?.suggestedWorksheets?.length || 0
  const onPrevious = () => setSuggestedContentIndex(prev => prev - 1)
  const onNext = () => setSuggestedContentIndex(prev => prev + 1)

  const handleAssign = () => {
    if (client.enrollment_timestamp) {
      // if (suggestion) {
      //   setOverlayPanel('SAVE_INTERVENTION', {
      //     intervention: {
      //       interventionId: suggestion.interventionId,
      //       suggestionId: suggestion.contentSuggestionId
      //     },
      //     sessionId
      //   })
      // }
    } else {
      // setOverlayPanel('ENROLL_CLIENT', {
      //   intervention: {
      //     interventionId: suggestion.interventionId,
      //     suggestionId: suggestion.contentSuggestionId
      //   },
      //   sessionId
      // })
    }
  }
  return (
    <Stack spacing="0" gap="8px" mb="small">
      <HStack justifyContent="space-between">
        <HStack spacing={3}>
          <Text fontWeight="bold">Suggestions</Text>
          <Text
            fontSize="12px"
            color="primary"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {}}
          >
            Browse all
          </Text>
        </HStack>
        <Pagination
          current={suggestedContentIndex + 1}
          total={count}
          onNext={onNext}
          onPrev={onPrevious}
        />
      </HStack>
      <ContentSuggestionCard
        isDemo={showDemoExperience}
        type="intervention"
        suggestion={{ ...suggestion, name: suggestion.interventionName }}
        sessionId={sessionId}
        onAssign={handleAssign}
        previewUrl={''}
      />
    </Stack>
  )
}
