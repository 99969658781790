import React from 'react'
import {
  Checkbox,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text
} from '@chakra-ui/react'
import { SearchIcon } from '@blueprinthq/joy'

type OptionType = {
  value: string
  label: string
}

type FocusOfTreatmentPanelProps = {
  options: OptionType[]
  searchValue: string
  selectedValues: string[]
  onSearchChange: (value: string) => void
  onOptionToggle: (value: string) => void
}

const FocusOfTreatmentPanel: React.FC<FocusOfTreatmentPanelProps> = ({
  options,
  searchValue,
  selectedValues,
  onSearchChange,
  onOptionToggle
}) => {
  return (
    <Stack spacing="0" flex="1" marginRight="-24px" marginBottom="-20px">
      <InputGroup>
        <InputLeftElement h="40px" pointerEvents="none">
          <SearchIcon fill="dark_gray" />
        </InputLeftElement>
        <Input
          h="40px"
          marginRight="24px"
          placeholder="Search"
          value={searchValue}
          onChange={event => onSearchChange(event.target.value)}
          _focus={{ outline: 'none' }}
        />
      </InputGroup>
      <Stack spacing="0" h="280px" overflowY="scroll" pt="8px">
        {options.map((item, index) => (
          <HStack
            key={index}
            justifyContent="space-between"
            spacing="0"
            gap="16px"
            style={{ marginRight: '24px' }}
          >
            <Text flex="1" display="flex" alignItems="center" h="40px">
              {item.label}
            </Text>
            <Checkbox
              isChecked={selectedValues.includes(item.value)}
              onChange={() => onOptionToggle(item.value)}
            />
          </HStack>
        ))}
      </Stack>
    </Stack>
  )
}

export default FocusOfTreatmentPanel
