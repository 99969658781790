import React from 'react'

const MicAIOff = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="88px"
    height="88px"
    viewBox="0 0 88px 88px"
    fill="none"
  >
    <g opacity="0.5">
      <path
        d="M64.1667 4.16669L66.6425 10.8575L73.3333 13.3334L66.6425 15.8092L64.1667 22.5L61.6908 15.8092L55 13.3334L61.6908 10.8575L64.1667 4.16669Z"
        fill="white"
      />
      <path
        d="M22 24.3333L23.4855 28.3478L27.5 29.8333L23.4855 31.3188L22 35.3333L20.5145 31.3188L16.5 29.8333L20.5145 28.3478L22 24.3333Z"
        fill="white"
      />
      <path
        d="M69.6667 44.5H63.4333C63.4333 47.2133 62.8467 49.7433 61.8567 52.0167L66.3667 56.5267C68.42 52.9333 69.6667 48.8633 69.6667 44.5ZM54.9267 45.1233C54.9267 44.9033 55 44.72 55 44.5V22.5C55 16.4133 50.0867 11.5 44 11.5C37.9133 11.5 33 16.4133 33 22.5V23.16L54.9267 45.1233ZM15.6567 15.1667L11 19.8233L33.0367 41.86V44.5C33.0367 50.5867 37.9133 55.5 44 55.5C44.8067 55.5 45.6133 55.39 46.3833 55.2067L52.47 61.2933C49.8667 62.5033 46.97 63.2 44 63.2C33.88 63.2 24.5667 55.5 24.5667 44.5H18.3333C18.3333 57.0033 28.3067 67.3433 40.3333 69.14V81.1667H47.6667V69.14C51.0033 68.6633 54.1567 67.49 56.98 65.84L72.3433 81.1667L77 76.51L15.6567 15.1667Z"
        fill="white"
      />
    </g>
  </svg>
)

export default MicAIOff
