import React, { useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  Flex,
  VStack,
  Text,
  Box,
  useBoolean,
  Stack,
  Link
} from '@chakra-ui/react'

import { endpoints } from '../../../../../../api'
import SectionHeader from '../section-header'
import TimescaleSelector from '../timescale-selector'
import BlurSection from '../blur-section'
import { LifestyleModal } from './lifestyle-modal'

const excludedSections = [
  'homestay',
  'movement_time',
  'heart_rate_min',
  'heart_rate_max'
]
const byDaySections = ['steps', 'distance', 'sleep']

export const LifestyleSection = ({
  user_id: clientId,
  sendInvite,
  patient
}) => {
  const [isModalOpen, setModal] = useState(false)
  const [selectedTimeScale, setSelectedTimeScale] = useState('all')

  const { data } = useQuery(
    [endpoints.getClientLifestyle.getCacheId(), clientId],
    () => endpoints.getClientLifestyle.request({ clientId }),
    {
      initialData: []
    }
  )

  const handleTimescaleChange = ({ value, unit }) => {
    if (!unit) {
      setSelectedTimeScale('all')
    } else {
      setSelectedTimeScale(`${value}_${unit}`)
    }
  }

  const selectedTimeScaleEntry = useMemo(
    () => data.find(d => d.timeScale === selectedTimeScale),
    [data, selectedTimeScale]
  )

  if (data.length === 0) return null

  return (
    <>
      <Box
        borderRadius={'8px'}
        border={'1px solid #E4E5E6'}
        padding={'24px 32px 24px 32px'}
        w={'100%'}
        height={'100%'}
      >
        <Stack mb={'16px'} flex="1">
          <Stack
            justify={{
              base: 'start',
              md: 'space-between'
            }}
            direction={{
              base: 'row',
              md: 'row'
            }}
          >
            <Box w={{ base: '80%', md: '60%' }}>
              <Text textStyle="lg" fontWeight="bold">
                Lifestyle
              </Text>
            </Box>
            <Box w={{ base: '20%', md: '40%' }}>
              {patient.hasApp && (
                <Link
                  textAlign={'end'}
                  onClick={() => setModal(!isModalOpen)}
                  color={'#2D54E8'}
                >
                  <Text fontSize={'16px'} fontWeight={'430'}>
                    View all
                  </Text>
                </Link>
              )}
            </Box>
          </Stack>
          <Stack
            direction={{
              base: 'row',
              md: 'row'
            }}
          >
            <Box w={'100%'}>
              <Text fontSize={'16px'} fontWeight={'430'}>
                Generated from check-ins and phone sensor data.
              </Text>
            </Box>
          </Stack>
        </Stack>

        {patient.hasApp ? (
          <>
            <TimescaleSelector handleTimeScaleChange={handleTimescaleChange} />
            <VStack alignItems="flex-start" spacing="25.3px" mt="20px">
              {selectedTimeScaleEntry.items
                .filter(i => !excludedSections.includes(i.key))
                .map((entry, i) => (
                  <Flex key={i} justify="space-between" width="100%">
                    <Box width="60%">
                      <Text color="#4a4a4a" fontFamily="Source Sans Pro">
                        {entry.displayName}
                      </Text>
                    </Box>
                    <Box width="40%">
                      <Text
                        color="#4a4a4a"
                        fontWeight="bold"
                        align="left"
                        fontFamily="Source Sans Pro"
                      >
                        {entry.valueLabel}
                        {byDaySections.includes(entry.key) && entry.value && (
                          <Text as="span" fontSize="13px" fontWeight="400">
                            {' '}
                            /day
                          </Text>
                        )}
                        {entry.previousMeasurementComparison && entry.value && (
                          <Text as="span" fontSize="13px" fontWeight="400">
                            {' '}
                            (
                            {entry.previousMeasurementComparison.percentDiff < 0
                              ? '↓'
                              : entry.previousMeasurementComparison
                                  .percentDiff > 0 && '↑'}
                            {entry.previousMeasurementComparison.percentDiff ===
                            0
                              ? 'No change'
                              : ` ${entry.previousMeasurementComparison.percentDiff}%`}
                            )
                          </Text>
                        )}
                      </Text>
                    </Box>
                  </Flex>
                ))}
            </VStack>
          </>
        ) : (
          <BlurSection
            sectionName="lifestyle"
            action={sendInvite}
            patient={patient}
          />
        )}
      </Box>
      <LifestyleModal
        clientId={clientId}
        isOpen={isModalOpen}
        onClose={() => setModal(!isModalOpen)}
      />
    </>
  )
}
