const styles = theme => ({
  container: {
    flex: 1,
    paddingBottom: '16px'
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  posOrNeg: {
    fontFamily: "'Source Sans Pro'",
    fontWeight: 'bold',
    fontSize: '34px',
    color: '#4a4a4a',
    marginBottom: '8px'
  },
  assessmentSection: {
    display: 'flex',
    flexFlow: 'row wrap'
  },
  outlined_select: {
    '& select': {
      paddingTop: '10px',
      paddingBottom: '10px'
    }
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 20px 0'
  },
  subheader: {
    maxWidth: '250px',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'flex-start',
    fontFamily: "'Source Sans Pro'",
    fontSize: '13px',
    color: '#4a4a4a',
    lineHeight: '13px'
  },
  dot: {
    minWidth: '10px',
    height: '10px',
    marginRight: '6px',
    borderRadius: '20%'
  },
  treatmentResponse: {
    fontFamily: "'Source Sans Pro'",
    fontSize: '16px',
    color: '#4a4a4a',
    marginBottom: '32px',
    width: '150px'
  },
  percentage_change_container: {
    maxWidth: '165px',
    marginRight: '10px',
    display: 'flex',
    flexFlow: 'column',
    fontFamily: "'Source Sans Pro'",
    fontSize: '13px',
    color: theme.palette.text.constrastOnWhiteText,
    lineHeight: '13px',
    marginBottom: '15px',

    '& .percentage_change_string': {
      marginBottom: '8px',
      marginLeft: '15px',

      '& .number': {
        fontWeight: 500
      },

      '& .green': {
        color: theme.palette.trends.positive + '!important',
        marginRight: '4px'
      },

      '& .red': {
        color: theme.palette.trends.negative + '!important',
        marginRight: '4px'
      },

      '& .neutral': {
        color: 'gray !important',
        marginRight: '4px'
      }
    }
  },

  individualAssessment: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '150px'
    },
    paddingBottom: '8px'
  }
})

export default styles
