import { useField } from 'formik'
import { TextField } from '@blueprinthq/joy'

export const FormikTextField = props => {
  const [field, meta, helpers] = useField(props)

  return (
    <TextField
      {...field}
      {...props}
      isInvalid={meta.touched && meta.error}
      errorText={meta.error}
    />
  )
}

export { FormikTextField as TextField }
