/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type ClinicControllerGetCheckInsType = typeof ClinicControllerGetCheckInsType[keyof typeof ClinicControllerGetCheckInsType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClinicControllerGetCheckInsType = {
  treatment_activity: 'treatment_activity',
  symptom: 'symptom',
} as const;
