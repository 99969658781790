import React, { useMemo } from 'react'
import { useStoreActions } from 'easy-peasy'
import { Button } from '@blueprinthq/joy'
import { MeasureTypes } from '@constants/measureTypes'
import { buildCheckInPreviewUrl } from '@utilities'

export const AssignSymptomsModal = ({
  symptomTrackers,
  clientId,
  onAssign,
  selectedSymptomTrackers
}) => {
  const openAssignSymptomsModal = useStoreActions(
    actions => actions.modals.assignMeasures.openModal
  )

  const allSymptomTrackers = useMemo(() => {
    return symptomTrackers.map(m => ({
      measureId: m.id,
      title: m.title,
      subtitle: m.subtitle,
      numQuestions: m.num_questions,
      previewUrl:
        m.title === 'Lifestyle correlates'
          ? m.preview_url
          : buildCheckInPreviewUrl(m.id),
      isAssigned:
        m.isActive || selectedSymptomTrackers.map(sst => sst.id).includes(m.id)
    }))
  }, [symptomTrackers, selectedSymptomTrackers])

  return (
    <Button
      variant="link"
      onClick={() =>
        openAssignSymptomsModal({
          clientId,
          measureType: MeasureTypes.SYMPTOM,
          allMeasures: allSymptomTrackers,
          onAssign
        })
      }
    >
      Assign Symptom Trackers
    </Button>
  )
}
