import React, { createContext, FC, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { endpoints } from '@api'
import { useLocation } from 'react-router-dom'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { StoreModel } from 'src/store/types'

interface ExperienceManagerContextType {
  isEvidenceBasedCareEnabled: boolean
  isDocumentationAutomationEnabled: boolean
  isDocumentationAutomationOnly: boolean
  isPlusPlanEnabled: boolean
  hasPlusPlanFreeTrial: boolean
  daysRemainingInPlusPlanFreeTrial: number
  isDocumentationAutomationFreePlan: boolean
  documentationAutomationSessionsRemaining: number
  documentationAutomationSessionsUsed: number
  documentationAutomationSessionsAllotted: number
  hasDocumentationAutomationSubscription: boolean
  documentationAutomationFreeTierSessionLimitReached: boolean
  clinicianCount: number
  isAdmin: boolean
  isSuperAdmin: boolean
  isDocumentationAutomationEnterprise: boolean
  isDocumentationAutomationPartner: boolean
  isSafari: boolean
  isExtension: boolean
  isExtensionMinimized: boolean
  toggleIsExtensionMinimized: () => void
  isWidget: boolean
  isIntegratedUI: boolean
  paywallData: any
  isDemoClinician: boolean
  isDeleteClientsEnabled: boolean
  isSessionRecordingV2Enabled: boolean
}

export const ExperienceManagerContext = createContext<
  ExperienceManagerContextType
>({} as ExperienceManagerContextType)

interface ExperienceManagerProviderProps {
  children: React.ReactNode
}

export const ExperienceManagerProvider: FC<ExperienceManagerProviderProps> = ({
  children
}) => {
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const location = useLocation()

  const [isExtension, setIsExtension] = useState(false)
  const [isWidget, setIsWidget] = useState(false)
  const [isExtensionMinimized, setIsExtensionMinimized] = useState(false)

  const isIntegratedUI = isWidget || isExtension

  useEffect(() => {
    const extension = location.pathname.includes('/extension')
    const widget = location.pathname.includes('/widget')
    if (extension) {
      setIsExtension(true)
    }

    if (widget) {
      setIsWidget(true)
    }
  }, [location.pathname])

  const { data, isLoading } = useQuery(
    endpoints.getPaywallValidation.getCacheId(),
    endpoints.getPaywallValidation.request
  )

  if (isLoading) return null

  const {
    documentationAutomationSessionsRemaining,
    documentationAutomationSessionsUsed,
    documentationAutomationSessionsAllotted,
    isDocumentationAutomationFree,
    isDocumentationAutomationEnabled: isDocAutomationEnabled,
    clinicianCount,
    isDocumentationAutomationEnterprise,
    isDocumentationAutomationPartner,
    hasDocumentationAutomationSubscription,
    isEBCEnabled: isEvidenceBasedCareEnabled,
    hasPlusPlanFreeTrial,
    daysRemainingInPlusPlanFreeTrial,
    isPlusPlanEnabled: _isPlusPlanEnabled
  } = data as any

  const isDocumentationAutomationEnabled = isDocAutomationEnabled
  const isAdmin = !!user?.roles.find(
    (role: { name: string }) =>
      role.name === 'clinicadmin' ||
      role.name === 'superadmin' ||
      role.name === 'orgadmin'
  )

  const isSuperAdmin = !!user?.roles.find(
    (role: { name: string }) => role.name === 'superadmin'
  )
  const isDemoClinician = flagsmith.hasFeature(FlagsmithFeatures.DEMO_CLINICIAN)
  const isDeleteClientsEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.DELETE_CLIENTS
  )

  const isSafari =
    navigator.userAgent.indexOf('Safari') != -1 &&
    navigator.userAgent.indexOf('Chrome') == -1 &&
    navigator.userAgent.indexOf('CriOS') == -1

  const toggleIsExtensionMinimized = () => {
    setIsExtensionMinimized((isMinimized: boolean) => !isMinimized)
    const eventType = !isExtensionMinimized ? 'BP_MINIMIZE' : 'BP_EXPAND'
    // eslint-disable-next-line
    parent.postMessage({ type: eventType }, '*')
  }

  // Including feature flag override for now for alpha group carryover
  const isPlusPlanEnabled =
    flagsmith.hasFeature(FlagsmithFeatures.ASSIST) || _isPlusPlanEnabled

  const isSessionRecordingV2Enabled =
    flagsmith.hasFeature(FlagsmithFeatures.IMPROVE_SESSION_RECORDING) &&
    !isEvidenceBasedCareEnabled &&
    !isIntegratedUI

  return (
    <ExperienceManagerContext.Provider
      value={{
        isEvidenceBasedCareEnabled,
        isDocumentationAutomationEnabled,
        isDocumentationAutomationOnly:
          isDocumentationAutomationEnabled && !isEvidenceBasedCareEnabled,
        isPlusPlanEnabled,
        hasPlusPlanFreeTrial,
        daysRemainingInPlusPlanFreeTrial,
        hasDocumentationAutomationSubscription,
        documentationAutomationSessionsRemaining,
        documentationAutomationSessionsUsed,
        documentationAutomationSessionsAllotted,
        isDocumentationAutomationFreePlan: isDocumentationAutomationFree,
        documentationAutomationFreeTierSessionLimitReached:
          isDocumentationAutomationFree &&
          documentationAutomationSessionsRemaining < 1,
        clinicianCount: clinicianCount,
        isAdmin,
        isSuperAdmin,
        isDocumentationAutomationEnterprise,
        isDocumentationAutomationPartner,
        isSafari,
        isExtension,
        isExtensionMinimized,
        toggleIsExtensionMinimized,
        isWidget,
        isIntegratedUI,
        isSessionRecordingV2Enabled,
        paywallData: data,
        isDemoClinician,
        isDeleteClientsEnabled
      }}
    >
      {children}
    </ExperienceManagerContext.Provider>
  )
}
