import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
  Text,
  Box,
  DrawerCloseButton,
  Select
} from '@chakra-ui/react'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'

import { drawerCatalog, drawerData } from '../drawer-catalog'
import { NodeType } from '../node-catalog'
import { WorkflowsContext } from '@context'
import { FormattedNode } from '../format-nodes'
import { TriggerDrawer } from './trigger-drawer'

type DrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
}

export const EditTriggerDrawer = ({ isOpen, closeDrawer }: DrawerProps) => {
  const [selectedTrigger, setSelectedTrigger]: [
    NodeType | undefined,
    Function
  ] = useState<NodeType | undefined>()
  const [DrawerComponent, setDrawerComponent] = useState<React.FC | undefined>()
  const { selectedNode, setSelectedNode, newTriggerNode } = useContext(WorkflowsContext)

  useEffect(() => {
    setSelectedTrigger(selectedNode?.type)
  }, [selectedNode])

  useEffect(() => {
    const DrawerComponent = drawerCatalog.triggers[selectedTrigger as NodeType]
    // @ts-ignore
    setDrawerComponent(() => DrawerComponent)


    if (selectedTrigger) {
      // @ts-ignore
      setSelectedNode((currentSelectedNode) => {
        if (currentSelectedNode.type !== selectedTrigger) {
          return {...newTriggerNode, type: selectedTrigger}
        }
        return {
          ...(selectedNode as FormattedNode),
          type: selectedTrigger
        }
      })
    }
  }, [selectedTrigger])

  const showAppointmentWorkflows = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_APPOINTMENT_WORKFLOWS
  )

  return (
    <ChakraDrawer isOpen={isOpen} onClose={closeDrawer} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <Box
          height="80px"
          backgroundColor="primary"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl="small"
          pr="small"
        >
          <Text color="white">Workflow Trigger</Text>
          <DrawerCloseButton color="white" position="static" />
        </Box>
        <Box overflowY="auto" p="small" height="100%">
          <Text as="b">Choose a Workflow Trigger</Text>
          <Select
            value={selectedTrigger}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSelectedTrigger(e.target.value as NodeType)
            }}
            borderColor="light_gray"
            mt="xsmall"
            mb="small"
          >
            <option></option>
            {Object.keys(drawerCatalog.triggers)
              .filter(
                t => showAppointmentWorkflows || t !== 'upcoming-appointment-trigger'
              )
              .map(drawerType => {
                return (
                  <option key={drawerType} value={drawerType}>
                    {drawerData[drawerType].name}
                  </option>
                )
              })}
          </Select>
          {DrawerComponent && (
            <TriggerDrawer closeDrawer={closeDrawer}>
              <DrawerComponent />
            </TriggerDrawer>
          )}
        </Box>
      </DrawerContent>
    </ChakraDrawer>
  )
}
