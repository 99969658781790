import React from 'react'

let PatientSearchWrapper = () => null
if (process.env.REACT_APP_THEME === 'tempus') {
  import('./tempus/wrapper').then(
    ({ default: patientSearchWrapper }) =>
      (PatientSearchWrapper = patientSearchWrapper)
  )
} else {
  import('./blueprint/wrapper').then(
    ({ default: patientSearchWrapper }) =>
      (PatientSearchWrapper = patientSearchWrapper)
  )
}

export default function PatientSearch(baseProps) {
  return <PatientSearchWrapper {...baseProps} />
}
