import React, { useState } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { CompassOutlined, ChevronDown } from '@components/icons'
import { motion, AnimatePresence } from 'framer-motion'
import InSessionAssistPanel from './in-session-assist-panel'
import { ClientAccountResponse } from '@api'

interface MobileCollapsedAssistDrawerProps {
  client: ClientAccountResponse
}

export const MobileAssistDrawer = ({
  client
}: MobileCollapsedAssistDrawerProps) => {
  const [isAssistFullScreen, setIsAssistFullScreen] = useState(false)

  const toggleAssistFullScreen = () => {
    console.log('toggleAssistFullScreen')
    setIsAssistFullScreen(prev => !prev)
  }

  return (
    <>
      {!isAssistFullScreen && (
        <Box
          aria-label="Assist"
          bg="white"
          borderTopRadius="24px"
          h="72px"
          px={6}
          py={4}
          boxShadow="0px 25px 50px -12px #00000040"
          borderBottom="1px solid #E4E5E6"
          display="flex"
          alignItems="center"
          gap="12px"
          onClick={toggleAssistFullScreen}
        >
          <CompassOutlined fill="#282828" />
          <Text color="black" fontWeight="bold">
            Assist
          </Text>
          <Text color="#757575" fontWeight="normal">
            {`${client.first_name} ${client.last_name}`}
          </Text>
          <Box marginLeft="auto" transform="rotate(180deg)" width="24px" height="24px">
            <ChevronDown height="100%" width="100%" />
          </Box>
        </Box>
      )}

      <AnimatePresence>
        {isAssistFullScreen && (
          <Box
            data-testid="mobile-open-assist-drawer"
            as={motion.div}
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={'modal'}
            bg="white"
            initial={{ y: '100%' }}
            animate={{ 
              y: 0,
              transition: {
                type: 'spring',
                damping: 30,
                stiffness: 300
              }
            }}
            exit={{ 
              y: '100%',
              transition: {
                type: 'spring',
                damping: 30,
                stiffness: 300
              }
            }}
            style={{
              boxShadow: '0px -4px 25px rgba(0, 0, 0, 0.1)',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
              marginTop: '0px'
            }}
          >
            <InSessionAssistPanel
              client={client}
              toggleAssist={toggleAssistFullScreen}
              fullScreen={true}
            />
          </Box>
        )}
      </AnimatePresence>
    </>
  )
}