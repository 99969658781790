import React from 'react'
import { Box, Flex, Text, VStack, useBreakpointValue } from '@chakra-ui/react'
import { MicAIOn, MicDictate, MicUpload } from '@components/icons'
import { useHistory } from 'react-router-dom'
import { useExperienceManager } from '@hooks'

export const SessionTypeCTA = ({
  id,
  Icon,
  title,
  description,
  onClick,
  isHorizontal
}) => {
  return (
    <Box
      id={id}
      borderRadius="12px"
      backgroundColor="#EAEEFD"
      onClick={onClick}
      border="2px solid #EAEEFD"
      _hover={{
        cursor: 'pointer',
        border: '2px solid',
        borderColor: 'primary'
      }}
      w="100%"
      h="100%"
      flex="auto"
      padding="8px 16px"
    >
      <Flex
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        rowGap="xsmall"
        h="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          backgroundColor="primary"
          borderRadius="20px"
          mr="12px"
          w="40px"
          minW="40px"
          minH="40px"
        >
          {Icon}
        </Flex>
        <Flex flexDirection="column" alignItems="flex-start">
          <Text as="b">{title}</Text>
          <Text>{description}</Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export const SessionTypeCTAGroup = ({
  setUploadModalOpen,
  openDocAutomationSessionLimitReachedModal,
  openDocAutomationPlanModal,
  documentationAutomationFreeTierSessionLimitReached,
  isAdmin
}) => {
  const history = useHistory()
  const isHorizontal = useBreakpointValue({
    base: false,
    lg: true
  })

  const { isSessionRecordingV2Enabled } = useExperienceManager()

  const onRecordAudioClick = () => {
    if (isSessionRecordingV2Enabled) {
      history.push('/new-session')
    } else {
      history.push('/start-session/select-client?signup=true')
    }
  }

  const onDictateClick = () => {
    if (isSessionRecordingV2Enabled) {
      history.push('/dictate/new-session')
    } else {
      history.push('/start-session/dictate/select-client')
    }
  }

  return (
    <VStack spacing={4} alignItems="stretch" mb="large">
      <SessionTypeCTA
        id="pendo-sessions-start-session"
        Icon={<MicAIOn width="24px" height="24px" />}
        title="Record Audio"
        description="Use during live sessions"
        onClick={onRecordAudioClick}
        isHorizontal={isHorizontal}
      />
      <SessionTypeCTA
        id="pendo-sessions-start-session-dictate"
        Icon={<MicDictate width="24px" height="24px" />}
        title="Dictate Notes"
        description="Turn client visits into notes"
        onClick={onDictateClick}
        isHorizontal={isHorizontal}
      />
      <SessionTypeCTA
        Icon={<MicUpload width="24px" height="24px" padding="0 0 0 3px" />}
        title="Upload Recording"
        description="Convert audio into notes"
        onClick={() => {
          if (documentationAutomationFreeTierSessionLimitReached) {
            if (isAdmin) {
              openDocAutomationPlanModal()
            } else {
              openDocAutomationSessionLimitReachedModal()
            }
          } else {
            setUploadModalOpen(true)
          }
        }}
        isHorizontal={isHorizontal}
      />
    </VStack>
  )
}
