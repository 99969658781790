import React, { useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  FormControl,
  useToast,
  Select,
  Switch
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { useExperienceManager } from '@hooks'
import { useStoreState } from 'easy-peasy'
import { keyBy } from 'lodash'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { SuperAdminOrganizationSelector, Loading } from '@components'
import { NoteTypeDropdown } from '@handlers/sessions/components'

const Schema = Yup.object().shape({
  noteType: Yup.string().required('Required'),
  sessionSummariesEnabled: Yup.boolean().required()
})

const Card = ({ children }) => {
  return (
    <Box
      p="medium"
      borderRadius="6px"
      border="1px solid #E4E5E6"
      mb="medium"
      bg="white"
    >
      {children}
    </Box>
  )
}

export const ProgressNotes = () => {
  const { user } = useStoreState(state => state.auth)
  const queryClient = useQueryClient()
  const [organizationId, setOrganizationId] = useState(
    user?.clinic?.organization?.id
  )
  const toast = useToast()
  const { isAdmin } = useExperienceManager()
  const hasEditPermission = isAdmin

  const { data, isLoading } = useQuery(
    [endpoints.getProgressNoteSettings.getCacheId(), organizationId],
    () => endpoints.getProgressNoteSettings.request({ organizationId })
  )

  const {
    data: { progressNoteTypes }
  } = useQuery(
    [endpoints.getProgressNoteTypes.getCacheId(), organizationId],
    () => endpoints.getProgressNoteTypes.request({ organizationId }),
    {
      initialData: {
        progressNoteTypes: []
      }
    }
  )

  const noteTypesById = keyBy(progressNoteTypes, 'id')
  const { mutateAsync: patchProgressNoteSettings } = useMutation(
    endpoints.patchProgressNoteSettings.request
  )

  const handleSubmit = async values => {
    await patchProgressNoteSettings(
      {
        organizationId,
        data: {
          noteType: values.noteType,
          sessionSummariesEnabled: values.sessionSummariesEnabled,
          transcriptsEnabled: values.transcriptsEnabled
        }
      },
      {
        onSuccess: async data => {
          await queryClient.invalidateQueries([
            endpoints.getUserAccount.getCacheId()
          ])
          toast({
            description: 'Progress note preferences updated.',
            status: 'success',
            duration: 3000
          })
        }
      }
    )
  }

  if (isLoading) return <Loading />

  const defaultNoteType = data?.defaultNoteType
  const isSessionSummariesEnabled = data?.isSessionSummariesEnabled
  const transcriptsEnabled = data?.transcriptsEnabled

  return (
    <Box>
      <Box w="100%" mb="medium">
        <Heading mb="xxsmall">Progress Notes</Heading>
        <Text>Keep your notes consistent across your organization</Text>
      </Box>
      <Box mb="medium">
        <SuperAdminOrganizationSelector
          selectedOrganizationId={organizationId}
          setSelectedOrganizationId={setOrganizationId}
        />
      </Box>
      <Formik
        enableReinitialize
        initialValues={{
          noteType:
            noteTypesById[defaultNoteType]?.id || progressNoteTypes[0]?.id,
          sessionSummariesEnabled: isSessionSummariesEnabled,
          transcriptsEnabled: transcriptsEnabled
        }}
        validationSchema={Schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <FormControl>
              <Card>
                <Text fontWeight="bold">Default Note Type</Text>
                <Text>Set the default note type for your organization</Text>
                <Flex gap="16px" flexDirection="column">
                  <Field name="noteType">
                    {({ field }) => (
                      <NoteTypeDropdown
                        optionValue={field.value}
                        onChange={noteType =>
                          setFieldValue('noteType', noteType)
                        }
                        progressNoteTypes={progressNoteTypes}
                      />
                    )}
                  </Field>
                </Flex>
              </Card>
            </FormControl>
            <FormControl>
              <Card>
                <Flex justifyContent="space-between">
                  <Box>
                    <Text fontWeight="bold">Session Summaries</Text>
                    <Text>Generates session summaries for clinician use</Text>
                  </Box>
                  <Field name="sessionSummariesEnabled">
                    {({ field }) => (
                      <Switch
                        alignSelf="center"
                        size="lg"
                        isChecked={field.value}
                        disabled={!hasEditPermission}
                        onChange={e => {
                          setFieldValue(
                            'sessionSummariesEnabled',
                            e.target.checked
                          )
                        }}
                      />
                    )}
                  </Field>
                </Flex>
              </Card>
            </FormControl>
            <FormControl>
              <Card>
                <Flex gap="16px">
                  <Flex flex="5" flexDirection="column">
                    <Text fontWeight="bold">Session Transcripts</Text>
                    <Text>
                      Store session transcripts so we can use previous session
                      information in upcoming notes. Note: turning this “off”
                      will disable Magic edit.
                    </Text>
                  </Flex>
                  <Flex justifyContent="flex-end" alignItems="center">
                    <Field name="transcriptsEnabled">
                      {({ field }) => (
                        <Switch
                          size="lg"
                          isChecked={field.value}
                          disabled={!hasEditPermission}
                          onChange={e => {
                            setFieldValue(
                              'transcriptsEnabled',
                              e.target.checked
                            )
                          }}
                        />
                      )}
                    </Field>
                  </Flex>
                </Flex>
              </Card>
            </FormControl>
            {hasEditPermission && (
              <Button
                mt="medium"
                type="submit"
                size="lg"
                isLoading={isSubmitting}
                isDisabled={!hasEditPermission}
              >
                Update
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  )
}
