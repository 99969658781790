// DeleteModal.tsx

import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogContent,
  Button
} from '@chakra-ui/react'

interface DeleteModalProps {
  isOpen: boolean
  onClose: () => void
  onDeleteConfirm: () => void
  itemName: string
}

export const ProgramDeleteModal: React.FC<DeleteModalProps> = ({ isOpen, onClose, onDeleteConfirm, itemName }) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete {itemName}
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you want to delete this {itemName}? This action cannot be undone.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant={'ghost'} ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onDeleteConfirm} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
