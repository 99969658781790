import React, { useState, useEffect } from 'react'
import {
  MeetingStatus,
  useMeetingStatus,
  useMeetingManager,
  useToggleLocalMute,
  useAudioInputs,
  useDeviceLabelTriggerStatus,
  DeviceLabelTriggerStatus
} from 'amazon-chime-sdk-component-library-react'

export type DeviceType = {
  deviceId: string
  label: string
}

interface AudioInputContextType {
  audioInputs: DeviceType[]
  selectedAudioInput: string
  setSelectedAudioInput: (deviceId: string) => void
  hasMicAccess: boolean
  setHasMicAccess: React.Dispatch<React.SetStateAction<boolean>>
  toggleMute: () => void
  muted: boolean
  setDisableEchoCancelation: React.Dispatch<React.SetStateAction<boolean>>
}

export const AudioInputContext = React.createContext<
  AudioInputContextType | undefined
>(undefined)

interface AudioInputProviderProps {
  children: React.ReactNode
}

export const AudioInputProvider: React.FC<AudioInputProviderProps> = ({
  children
}) => {
  const [hasMicAccess, setHasMicAccess] = useState(true)
  const [disableEchoCancelation, setDisableEchoCancelation] = useState(false)
  const [selectedDeviceId, setSelectedDeviceId] = useState('')
  const { devices } = useAudioInputs()

  const meetingManager = useMeetingManager()
  const meetingStatus = useMeetingStatus()
  const deviceTriggerStatus = useDeviceLabelTriggerStatus()

  const { muted, toggleMute } = useToggleLocalMute()

  useEffect(() => {
    if (deviceTriggerStatus === DeviceLabelTriggerStatus.DENIED) {
      setHasMicAccess(false)
    }
  }, [deviceTriggerStatus])

  useEffect(() => {
    const setDefaultDevice = async () => {
      const defaultDevice = devices.find(i => !!i.deviceId)
      if (defaultDevice) {
        await meetingManager.startAudioInputDevice(defaultDevice.deviceId)
        setSelectedDeviceId(defaultDevice.deviceId)
      }
    }

    if (
      !selectedDeviceId &&
      meetingStatus === MeetingStatus.Succeeded &&
      !!meetingManager?.meetingId
    ) {
      setDefaultDevice()
    }
  }, [devices, selectedDeviceId, meetingStatus, meetingManager?.meetingId])

  useEffect(() => {
    if (!meetingManager.meetingId) {
      setSelectedDeviceId('')
    }
  }, [meetingManager?.meetingId])

  const handleInputChange = async (deviceId: string) => {
    await meetingManager.startAudioInputDevice(deviceId)
    setSelectedDeviceId(deviceId)
  }

  useEffect(() => {
    const handleEchoCancelation = async () => {
      const audioInputDevice =
        // @ts-ignore
        meetingManager?.audioVideo?.deviceController?.activeDevices?.audio

      if (audioInputDevice) {
        audioInputDevice.autoGainControl = !disableEchoCancelation
        audioInputDevice.echoCancellation = !disableEchoCancelation
        // @ts-ignore
        await meetingManager.startAudioInputDevice(audioInputDevice)
      }
    }

    if (selectedDeviceId) {
      handleEchoCancelation()
    }
  }, [disableEchoCancelation, selectedDeviceId])

  const contextValue = {
    audioInputs: devices,
    selectedAudioInput: selectedDeviceId,
    setSelectedAudioInput: handleInputChange,
    hasMicAccess,
    setHasMicAccess,
    toggleMute,
    muted,
    setDisableEchoCancelation
  }

  return (
    <AudioInputContext.Provider value={contextValue}>
      {children}
    </AudioInputContext.Provider>
  )
}

export default AudioInputProvider
