import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
  VStack,
  Box,
  Switch,
  useToast,
  useDisclosure,
  Text
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'

import { endpoints } from '@api'
import { Loading } from '@components'
import { FormattedNode } from './format-nodes'
import {
  nodeCatalog,
  NodeType,
  NewActionNode,
  NewTriggerNode
} from './node-catalog'
import { NavBar } from './navbar'
import { EditDrawer } from './edit-drawer/edit-drawer'
import { WorkflowsContext } from '@context'
import { WorkflowModal } from '../workflow-modal'
import { usePermissions } from '@hooks'

type RouteParams = {
  workflowId: string
  organizationId: string
}

export type Workflow = {
  id: string
  name: string
  description: string
  organizationId: string
  isEnabled: boolean
  nodes: FormattedNode[]
}

export const WorkflowEditor = () => {
  const { workflowId, organizationId } = useParams<RouteParams>()
  const queryClient = useQueryClient()
  const { hasPermission } = usePermissions()
  const hasEditPermission = hasPermission('*:org:workflows:*')
  const toast = useToast()
  const {
    nodes,
    workflow,
    isWorkflowLoading,
    selectedNode,
    setSelectedNode,
    isCreatingAction,
    isCreatingTrigger
  } = useContext(WorkflowsContext)
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal
  } = useDisclosure()
  const {
    isOpen: isDrawerOpen,
    onOpen: openDrawer,
    onClose: closeDrawer
  } = useDisclosure()

  useEffect(() => {
    if (selectedNode) {
      openDrawer()
    } else {
      closeDrawer()
    }
  }, [selectedNode])

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedNode()
    }
  }, [isDrawerOpen])

  const { mutate: updateWorkflow }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.updateOrganizationWorkflow.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getOrganizationWorkflow.getCacheId()
        )
        toast({
          description: 'Workflow updated!',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  if (isWorkflowLoading) return <Loading />

  return (
    <>
      <Box
        h={'100vh'}
        backgroundImage={
          'radial-gradient(circle at 1px 1px, #E4E5E6 1px, transparent 0)'
        }
        backgroundSize={'22px 22px'}
        overflowY="scroll"
      >
        <NavBar workflow={workflow} onEdit={openModal} />
        <Box
          display="flex"
          justifyContent="end"
          borderBottom="solid 3px"
          borderBottomColor="pale_gray"
          backgroundColor="white"
          padding="xsmall"
          mb="large"
          mt="80px"
        >
          <Box display="flex">
            <Text>Draft</Text>
            <Switch
              size="lg"
              isChecked={workflow?.isEnabled}
              isDisabled={!hasEditPermission}
              ml="xsmall"
              mr="xsmall"
              onChange={() =>
                updateWorkflow({
                  organizationId,
                  workflowId,
                  data: { is_enabled: !workflow?.isEnabled }
                })
              }
            />
            <Text mr="small">Published</Text>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          pl="xlarge"
          pr="xlarge"
        >
          <Switch size="lg" visibility="hidden" />
          <VStack m="auto">
            {(isCreatingTrigger || nodes?.length === 0) && <NewTriggerNode />}
            {nodes?.map((node: FormattedNode) => {
              const NodeComponent = nodeCatalog[node.type as NodeType]
              if (!NodeComponent) return null
              return (
                <NodeComponent
                  key={node.id}
                  {...node}
                  isSelected={selectedNode?.id === node.id}
                  onClick={() => setSelectedNode(node)}
                />
              )
            })}
            {isCreatingAction && <NewActionNode />}
          </VStack>
        </Box>
      </Box>
      <WorkflowModal
        workflow={workflow}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
      <EditDrawer isOpen={isDrawerOpen} closeDrawer={closeDrawer} />
    </>
  )
}
