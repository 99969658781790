import React, { useCallback } from 'react'
import { Box, Flex, VStack, Text } from '@chakra-ui/react'
import { Select } from '@blueprinthq/joy'

export const BillableUnitsPage = ({ data, setData, billingOptions }) => {
  const onSelectBillableUnit = useCallback(
    (code, billableUnit) => {
      setData({
        ...data,
        cptCodes: data.cptCodes.map(option => {
          if (code === option.code) {
            return {
              ...option,
              billableUnitsType: billableUnit
            }
          }
          return option
        })
      })
    },
    [data]
  )

  return (
    <Box>
      <VStack>
        {data.cptCodes.map(option => (
          <Flex key={option.code} align="center">
            <Text>If CPT Code is</Text>
            <Box
              width="76px"
              height="32px"
              mr="xsmall"
              ml="xsmall"
              border="1px solid"
              borderRadius="4px"
              borderColor="light_gray"
            >
              <Text align="center" lineHeight="32px">
                {option.code}
              </Text>
            </Box>
            <Text>billable units equals</Text>
            <Box width="180px" mr="xsmall" ml="xsmall">
              <Select
                placeholder="Choose"
                simple
                size="sm"
                options={billingOptions.billableUnits}
                valueKey="id"
                labelKey="displayName"
                onChange={value => onSelectBillableUnit(option.code, value)}
                value={option.billableUnitsType}
              />
            </Box>
          </Flex>
        ))}
      </VStack>
    </Box>
  )
}
