import React, { useEffect } from 'react'
import { Box, Link, Text, Container, GridItem } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { endpoints } from '../../api'
import { Loading } from '../../components'
import { Link as RouterLink } from 'react-router-dom'
import { isEmpty } from 'lodash-es'
import { useQueryParams } from '../../hooks/use-query-params'
import { useQuery } from 'react-query'
import { LayoutGrid } from '@blueprinthq/joy'

import { SearchInviteForm, AcceptInviteForm } from './components'

const FormRenderer = props => {
  const { user, isLoading, isIdle } = props
  const queryParams = useQueryParams()
  const history = useHistory()
  const email = queryParams.get('email')
    ? queryParams.get('email').replace(/ /g, '+')
    : null

  // search for user by email address
  if (!email && !isLoading) {
    return <SearchInviteForm />
  }

  if (isLoading || isIdle) {
    return <Loading />
  }

  // password already set
  if (email && !isEmpty(user) && user.is_password_set) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Text>A password has already been set for this account.</Text>
        <Link
          as={RouterLink}
          to={{ pathname: '/login', state: { email } }}
          sx={{ color: 'primary' }}
        >
          Try logging in instead
        </Link>
      </Box>
    )
  }

  // user not found
  if (email && isEmpty(user)) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Text>No user with that email exists.</Text>
        <Text>
          Contact your practice administrator for help creating your account
        </Text>
      </Box>
    )
  }

  // accept invite form
  if (email && !isEmpty(user) && !user.is_password_set) {
    const defaultRedirect =
      user?.clinic?.initial_product === 'documentation_automation'
        ? '/sessions?signup=true'
        : '/dashboard'

    return (
      <AcceptInviteForm
        user={user}
        onSuccess={() => history.push(defaultRedirect)}
      />
    )
  }
}

export const AcceptInvite = () => {
  const queryParams = useQueryParams()
  const email = queryParams.get('email')
    ? queryParams.get('email').replace(/ /g, '+')
    : null

  const { data: user, isFetching: isLoading, isIdle, refetch } = useQuery(
    [endpoints.getPublicClinician.getCacheId(), email],
    () => endpoints.getPublicClinician.request({ email }),
    {
      enabled: false,
      retry: false
    }
  )

  useEffect(() => {
    if (email) {
      refetch()
    }
  }, [email])

  return (
    <Container
      marginTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%">
            <FormRenderer user={user} isLoading={isLoading} isIdle={isIdle} />
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
