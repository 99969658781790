import React, { useState } from 'react'
import {
  Text,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Flex,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  HStack
} from '@chakra-ui/react'
import { DateTime } from 'luxon'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { AssignedCheckInDto } from '~/clinician-api/models'

import {
  usePatientControllerGetAssignedCheckIns,
  usePatientControllerUnassignCheckIn
} from '~/clinician-api'
import { AssignWorksheetsModal } from '../../shared/assist/worksheets/assign-worksheets-modal'

export const AssignedWorksheets = ({ patient }: { patient: any }) => {
  const [
    selectedAssignedCheckIn,
    setSelectedAssignedCheckIn
  ] = useState<AssignedCheckInDto | null>(null)

  const { data = [], refetch } = usePatientControllerGetAssignedCheckIns(
    patient.id
  )
  const { mutate } = usePatientControllerUnassignCheckIn({
    mutation: {
      onSuccess() {
        refetch()
      }
    }
  })

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box>
      <Box border="1px solid" borderColor="#E4E5E6" borderRadius="8px">
        {data.length ? (
          <Table variant="unstyled">
            <Thead>
              <Tr borderBottom={'1px solid #E4E5E6'}>
                {['Worksheet', 'Frequency'].map(header => (
                  <Th>
                    <Flex height="100%" alignItems="center">
                      <Text
                        fontSize="12px"
                        height="16px"
                        w="90px"
                        textTransform="none"
                        fontWeight="normal"
                      >
                        {header}
                      </Text>
                    </Flex>
                  </Th>
                ))}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map(d => (
                <Tr 
                  borderBottom={'1px solid #E4E5E6'}
                  key={d.checkInId} 
                  _last={{ borderBottom: 'none' }}
                >
                  <Td>
                    <Text>{d.title}</Text>
                  </Td>
                  <Td>
                    {d.daysOfWeek && d.timeOfDay ? (
                      <Text>{`Weekly on ${d.daysOfWeek.join(
                        ', '
                      )} at ${DateTime.fromISO(d.timeOfDay).toLocaleString(
                        DateTime.TIME_SIMPLE
                      )}`}</Text>
                    ) : (
                      <Text>{d.frequencyType === 'one_time' ? 'One-time' : '--'}</Text>
                    )}
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MoreHorizIcon />}
                        variant="ghost"
                        height="auto"
                        _focus={{
                          outline: 'none',
                          outlineStyle: 'none'
                        }}
                      />
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            setSelectedAssignedCheckIn(d)
                            onOpen()
                          }}
                        >
                          Adjust Frequency
                        </MenuItem>
                        <MenuItem
                          color="severe"
                          onClick={() =>
                            mutate({
                              checkInId: d.checkInId,
                              patientId: patient.id
                            })
                          }
                        >
                          Unassign
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <HStack spacing="0" p="16px">
            <Text>No Worksheets assigned.</Text>
          </HStack>
        )}
      </Box>
      {selectedAssignedCheckIn && (
        <AssignWorksheetsModal
          client={patient}
          isOpen={isOpen}
          onClose={onClose}
          assignedCheckIn={selectedAssignedCheckIn}
        />
      )}
    </Box>
  )
}
