import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  button: {
    height: 41,
    width: 150
  },
  contents: {
    paddingBottom: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '70px',
    width: '100%'
  },
  centerText: {
    textAlign: 'center'
  },
  errorText: {
    color: 'red',
    textAlign: 'center'
  },
  selector: {
    width: '100%'
  },
  icon: {
    width: '10%',
    height: '10%',
    paddingBottom: '10px'
  }
})
