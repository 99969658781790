import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { formatCadence } from '@utilities'
import { endpoints } from '@api'

interface Cadence {
  unit: string
  value: number
}

export const useCadenceOptions = () => {
  const [cadenceOptions, setCadenceOptions] = useState<string[]>([])
  const [cadenceMapping, setCadenceMapping] = useState<{
    [index: string]: { unit: string; value: number }
  }>({})

  const { data: srcCadences, isLoading } = useQuery<Cadence[], Error, any>(
    [endpoints.getAssessmentFrequencies.getCacheId()],
    // @ts-ignore
    endpoints.getAssessmentFrequencies.request,
    {
      initialData: []
    }
  )

  useEffect(() => {
    let tmpMapping: { [index: string]: Cadence } = {}
    const tmpCadenceStrings: string[] = []

    if (srcCadences && srcCadences.length > 0) {
      for (const cadence of srcCadences) {
        tmpMapping[formatCadence(cadence.value, cadence.unit)] = cadence
        tmpCadenceStrings.push(formatCadence(cadence.value, cadence.unit))
      }
    }

    setCadenceMapping(tmpMapping)
    setCadenceOptions(tmpCadenceStrings)
  }, [srcCadences])

  return { cadenceOptions, cadenceMapping, isLoading }
}
