import React from 'react'
import { HStack, VStack, Text, Stack, Button, Flex } from '@chakra-ui/react'
import CoolKidsStudy from '@handlers/sessions/components/shared/assist/svgs/cool-kids-study'
import { RadioButtonLoadingComplete } from '@components/icons'
import { Assist } from '@blueprinthq/joy'

const EmptyRadio = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C14.48 10 10 14.48 10 20C10 25.52 14.48 30 20 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 20 10ZM20 28C15.58 28 12 24.42 12 20C12 15.58 15.58 12 20 12C24.42 12 28 15.58 28 20C28 24.42 24.42 28 20 28Z"
      fill="#A1A1A1"
    />
  </svg>
)

const Setup = ({
  client,
  toggleOverlay,
  hasFocusOfTreatment,
  hasAgeRange,
  hasProviderType,
  hasTreatmentApproach,
  hasTreatmentSetting,
  hasHighRisk
}: {
  client: any
  toggleOverlay: () => void
  hasFocusOfTreatment: boolean
  hasAgeRange: boolean
  hasProviderType: boolean
  hasTreatmentApproach: boolean
  hasTreatmentSetting: boolean
  hasHighRisk: boolean
}) => {
  const strengthRequirements = [
    { name: 'Client risk level', complete: hasHighRisk },
    { name: 'Client age range', complete: hasAgeRange },
    { name: 'Treatment approach', complete: hasTreatmentApproach },
    { name: 'Focus of treatment', complete: hasFocusOfTreatment },
    { name: 'Treatment setting', complete: hasTreatmentSetting },
    { name: 'Provider type', complete: hasProviderType }
  ]

  return (
    <VStack spacing="0" flex="1" gap="24px">
      <CoolKidsStudy />
      <Stack spacing="0" gap="16px">
        <VStack spacing="0" gap="8px">
          <Text fontWeight="bold" fontSize="24px">
            Assist for {client.first_name}
          </Text>
          <Text textAlign="center" color="dark_gray">
            We need more information before we can generate suggestions.
          </Text>
        </VStack>
        <Stack justifyContent="center" spacing="0" style={{ margin: '0 auto' }}>
          {strengthRequirements.map((requirement, index) => (
            <HStack key={index} spacing="0" gap="8px" flex="1">
              <Flex
                h="40px"
                w="40px"
                justifyContent="center"
                alignItems="center"
              >
                {requirement.complete ? (
                  <RadioButtonLoadingComplete />
                ) : (
                  <EmptyRadio />
                )}
              </Flex>
              <Text>{requirement.name}</Text>
            </HStack>
          ))}
        </Stack>
      </Stack>
      <Button bg="primary" onClick={toggleOverlay}>
        Finish setup
      </Button>
    </VStack>
  )
}

export default Setup
