import React from 'react'
import { Box, Button, Text } from '@chakra-ui/react'
import { ChevronDown, CompassOutlined as Compass } from '@components/icons'
import { usePanelManager } from '@hooks'

interface FloatingAssistDrawerProps {
  clientFirstName: string | undefined
  clientLastName: string | undefined
}

export const FloatingAssistDrawer = ({ clientFirstName, clientLastName }: FloatingAssistDrawerProps) => {
    const { toggleAssist, isAssistOpen } = usePanelManager()
    
    return (
      <Box
        position="fixed"
        bottom="24px"
        right="24px"
      >
        <Button
          onClick={toggleAssist}
          variant="outline"
          size="lg"
          borderColor="pale_gray"
          borderRadius="8px"
          background="white"
          fontWeight="bold"
          fontSize="16px"
          height="72px"
          _hover={{ bg: 'gray.50' }}
          leftIcon={<Compass fill={isAssistOpen ? '#2D54E8' : '#282828'} />}
          rightIcon={
            <Box transform="rotate(180deg)">
              <ChevronDown />
            </Box>
          }
        >
          <Text>
            Assist
          </Text>
          <Text color="#757575" marginLeft="12px" marginRight="12px" fontWeight="normal">
            {clientFirstName && clientLastName && `${clientFirstName} ${clientLastName}`}
          </Text>
        </Button>
      </Box>
    )
  }