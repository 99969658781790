// Helper file for handling static Blueprint roles
// This should no longer be needed once roles are less static.

export const roleWeights = {
  clinician: 0,
  supervisor: 1,
  clinicadmin: 2,
  orgadmin: 3,
  superadmin: 4
}

export const roles = [
  {
    display: 'Clinician',
    name: 'clinician',
    description: 'Can access their own clients and reports.'
  },
  {
    display: 'Supervisor',
    name: 'supervisor',
    description:
      'Can access the clients and reports for the following Clinicians:'
  },
  {
    display: 'Biller',
    name: 'biller',
    description: 'Can manage billing for all clients and see financial reports.'
  },
  {
    display: 'Admin',
    name: 'clinicadmin',
    description:
      'Can make clinic-level decisions and configure account settings.'
  },
  {
    display: 'Org Admin',
    name: 'orgadmin',
    description:
      'Can make organization-level decisions and view all clinics in the org.'
  },
  {
    display: 'Super Admin',
    name: 'superadmin',
    description: 'Access to ALL data in the Blueprint system.'
  }
]

export function mapRoleToDisplayName(role) {
  if (role.name === 'supervisor_clinician')
    return roles.find(r => r.name === 'supervisor')?.display
  const displayRole = roles.find(r => r.name === role.name)?.display
  return displayRole
}

export function mapLocalRoleToOrganizationRole(roleName, organizationRoles) {
  return organizationRoles.find(orgRole => orgRole.name === roleName)
}

function getRoleWeight(role) {
  return roleWeights[role.name]
}

export function getHighestRoleWeight(roles) {
  return roles.reduce((currWeight, role) => {
    const weight = getRoleWeight(role)
    if (weight > currWeight) return weight
    return currWeight
  }, roleWeights.clinician)
}

export function isCanUserModifyProvider(user, provider) {
  const viewingUserRoleWeight = getHighestRoleWeight(user.roles)
  const providerRoleWeight = getHighestRoleWeight(provider.roles)
  return (
    viewingUserRoleWeight > providerRoleWeight ||
    (viewingUserRoleWeight === roleWeights.clinicadmin &&
      providerRoleWeight === roleWeights.clinicadmin) ||
    viewingUserRoleWeight === roleWeights.superadmin ||
    user.id === provider.id
  )
}
