import React, { Component } from 'react'
import styles from './footer.module.css'
import config from '../../config'

export default class Footer extends Component {
  render() {
    if (config.poweredByLogoURL) {
      return (
        <div className={styles.footer_container}>
          <img
            className={styles.footer_image}
            src={config.poweredByLogoURL}
            alt="powered by Blueprint"
          />
        </div>
      )
    } else {
      return null
    }
  }
}
