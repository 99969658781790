import React from 'react'
import { Box, Button } from '@chakra-ui/react'
import { ArrowLeftIcon, CreateIcon } from '@blueprinthq/joy'
import { useHistory } from 'react-router-dom'
import { Workflow } from './workflow-editor'
import { usePermissions } from '@hooks'

type NavBarProps = {
  workflow?: Workflow
  onEdit: () => void
}

export const NavBar = ({ workflow, onEdit }: NavBarProps) => {
  const history = useHistory()
  const { hasPermission } = usePermissions()
  const hasEditPermission = hasPermission('*:org:workflows:*')

  return (
    <Box
      w="100%"
      display="flex"
      zIndex={9}
      background="primary"
      h="80px"
      px="xlarge"
      position="fixed"
      justifyContent="space-between"
      alignItems="center"
    >
      <Button
        data-intercom-target={`back-to-workflows`}
        aria-label="Back to workflows"
        leftIcon={<ArrowLeftIcon fill={'white'} />}
        onClick={() => history.goBack()}
        variant={'ghost'}
        fontWeight="bold"
        color="white"
        pl="6px"
      >
        Back to Workflows
      </Button>
      <Button
        aria-label="Edit workflow"
        rightIcon={
          hasEditPermission ? (
            <CreateIcon fill={'white'} size={'sm'} />
          ) : (
            undefined
          )
        }
        onClick={hasEditPermission ? onEdit : () => {}}
        variant={'ghost'}
        fontWeight="bold"
        color="white"
      >
        {workflow?.name}
      </Button>
      <Box w="200px" />
    </Box>
  )
}
