import React, { useContext } from 'react'
import { Text, Box, Checkbox, Flex, Tooltip, Select } from '@chakra-ui/react'
import { InfoIcon } from '@blueprinthq/joy'

import { WorkflowsContext } from '@context'
import { EditDrawerFooter } from '../../edit-drawer/edit-drawer-footer'
import { FormattedNode } from '../../format-nodes'

type Props = {
  onSubmit: (node: FormattedNode | undefined) => void
  closeDrawer: () => void
  onDelete: (node: FormattedNode | undefined) => void
}

export const SendClientNotification = ({
  onSubmit,
  closeDrawer,
  onDelete
}: Props) => {
  const { selectedNode, setSelectedNode } = useContext(WorkflowsContext)
  const overrideNotificationPreferences =
    selectedNode?.config?.overrideNotificationPreferences

  return (
    <Box>
      <Text>Template:</Text>
      <Select borderColor="light_gray" my="small" value={'Your Safety Net'}>
        <option value="Your Safety Net" key="Your Safety Net" disabled>
          Your Safety Net
        </option>
      </Select>
      <Text>
        An email and text will be sent to the client prompting to review their
        safety net.
      </Text>
      <Flex>
        <Checkbox
          isChecked={overrideNotificationPreferences}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedNode({
              ...(selectedNode as FormattedNode),
              config: { overrideNotificationPreferences: event.target.checked }
            })
          }}
          mt="small"
        >
          <Text>Override client's safety net notification preferences</Text>
        </Checkbox>
        <Tooltip
          hasArrow
          label="When enabled, an email and text notification will be sent to the client regardless of their notification preferences."
        >
          <Box mt="small" ml="xxsmall">
            <InfoIcon fill="gray" />
          </Box>
        </Tooltip>
      </Flex>
      <EditDrawerFooter
        onDelete={() => onDelete(selectedNode)}
        onCancel={closeDrawer}
        onSubmit={() => onSubmit(selectedNode)}
      />
    </Box>
  )
}
