import React from 'react'

const UploadV2 = () => (
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.2458 43.2388H4.68558C2.62395 43.2388 0.965698 41.578 0.965698 39.5382V4.95064C0.965698 2.89969 2.63516 1.25 4.68558 1.25H23.7444L32.9657 10.7803V39.5494C32.9657 41.6003 31.2962 43.25 29.2458 43.25V43.2388Z"
      fill="#323232"
    />
    <path
      opacity="0.8"
      d="M11.9252 27.5C11.9252 28.0523 12.3729 28.5 12.9252 28.5C13.4775 28.5 13.9252 28.0523 13.9252 27.5V17.5C13.9252 16.9477 13.4775 16.5 12.9252 16.5C12.3729 16.5 11.9252 16.9477 11.9252 17.5V27.5ZM15.9252 31.5C15.9252 32.0523 16.3729 32.5 16.9252 32.5C17.4775 32.5 17.9252 32.0523 17.9252 31.5V13.5C17.9252 12.9477 17.4775 12.5 16.9252 12.5C16.3729 12.5 15.9252 12.9477 15.9252 13.5V31.5ZM7.92517 23.5C7.92517 24.0523 8.37289 24.5 8.92517 24.5C9.47746 24.5 9.92517 24.0523 9.92517 23.5V21.5C9.92517 20.9477 9.47746 20.5 8.92517 20.5C8.37289 20.5 7.92517 20.9477 7.92517 21.5V23.5ZM19.9252 27.5C19.9252 28.0523 20.3729 28.5 20.9252 28.5C21.4775 28.5 21.9252 28.0523 21.9252 27.5V17.5C21.9252 16.9477 21.4775 16.5 20.9252 16.5C20.3729 16.5 19.9252 16.9477 19.9252 17.5V27.5ZM24.9252 20.5C24.3729 20.5 23.9252 20.9477 23.9252 21.5V23.5C23.9252 24.0523 24.3729 24.5 24.9252 24.5C25.4775 24.5 25.9252 24.0523 25.9252 23.5V21.5C25.9252 20.9477 25.4775 20.5 24.9252 20.5Z"
      fill="white"
    />
    <path
      opacity="0.46"
      d="M32.98 10.7901L23.653 10.79L23.653 1.18152L32.98 10.7901Z"
      fill="white"
    />
    <path
      opacity="0.8"
      d="M11.9252 27.5C11.9252 28.0523 12.3729 28.5 12.9252 28.5C13.4775 28.5 13.9252 28.0523 13.9252 27.5V17.5C13.9252 16.9477 13.4775 16.5 12.9252 16.5C12.3729 16.5 11.9252 16.9477 11.9252 17.5V27.5ZM15.9252 31.5C15.9252 32.0523 16.3729 32.5 16.9252 32.5C17.4775 32.5 17.9252 32.0523 17.9252 31.5V13.5C17.9252 12.9477 17.4775 12.5 16.9252 12.5C16.3729 12.5 15.9252 12.9477 15.9252 13.5V31.5ZM7.92517 23.5C7.92517 24.0523 8.37289 24.5 8.92517 24.5C9.47746 24.5 9.92517 24.0523 9.92517 23.5V21.5C9.92517 20.9477 9.47746 20.5 8.92517 20.5C8.37289 20.5 7.92517 20.9477 7.92517 21.5V23.5ZM19.9252 27.5C19.9252 28.0523 20.3729 28.5 20.9252 28.5C21.4775 28.5 21.9252 28.0523 21.9252 27.5V17.5C21.9252 16.9477 21.4775 16.5 20.9252 16.5C20.3729 16.5 19.9252 16.9477 19.9252 17.5V27.5ZM24.9252 20.5C24.3729 20.5 23.9252 20.9477 23.9252 21.5V23.5C23.9252 24.0523 24.3729 24.5 24.9252 24.5C25.4775 24.5 25.9252 24.0523 25.9252 23.5V21.5C25.9252 20.9477 25.4775 20.5 24.9252 20.5Z"
      fill="white"
    />
    <path
      opacity="0.46"
      d="M33.3829 10.79H23.653V0.75L33.3829 10.79Z"
      fill="white"
    />
    <path
      d="M34.0279 22.1553C38.2012 22.1553 41.5844 18.7722 41.5844 14.5988C41.5844 10.4254 38.2012 7.04227 34.0279 7.04227C29.8545 7.04227 26.4713 10.4254 26.4713 14.5988C26.4713 18.7722 29.8545 22.1553 34.0279 22.1553Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.0326 4.56473C35.7297 4.56473 37.464 5.01068 39.0249 5.91499C43.819 8.67746 45.4541 14.8218 42.6917 19.6282C40.8335 22.8614 37.4516 24.6453 33.9955 24.6453C32.2983 24.6453 30.5888 24.1993 29.0032 23.295C24.2091 20.5325 22.574 14.3882 25.3364 9.58177C27.1946 6.34857 30.5764 4.56473 34.0202 4.56473H34.0326ZM32.1992 20.2724H35.8536C36.4111 20.2724 36.8446 19.8264 36.8446 19.2814C36.8446 18.7363 36.3863 18.2904 35.8536 18.2904H32.1992C31.6418 18.2904 31.2082 18.7363 31.2082 19.2814C31.2082 19.8264 31.6666 20.2724 32.1992 20.2724ZM36.8323 12.592C37.08 12.592 37.3154 12.5053 37.5136 12.3071H37.4888C37.8604 11.9107 37.8604 11.2913 37.4888 10.9196L34.7016 8.10763C34.7016 8.10763 34.6644 8.07045 34.6396 8.05806C34.5529 7.98374 34.4786 7.9218 34.3671 7.88464C34.2308 7.8227 34.107 7.82269 33.9707 7.82269C33.7229 7.82269 33.4628 7.90942 33.2646 8.10763L30.4773 10.9196C30.1057 11.316 30.1057 11.9354 30.4773 12.3071C30.6508 12.5053 30.9233 12.592 31.1587 12.592C31.394 12.592 31.6418 12.5053 31.84 12.3071L33.0168 11.1302V15.3792C33.0168 15.9367 33.4628 16.3703 34.0079 16.3703C34.5529 16.3703 34.9989 15.9119 34.9989 15.3792V11.1798L36.1262 12.3071C36.2996 12.5053 36.5721 12.592 36.8075 12.592H36.8323Z"
      fill="#2D54E8"
    />
  </svg>
)

export default UploadV2
