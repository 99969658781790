import React, { useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import { CompletedWorksheetList } from './completed-worksheets-list'
import { usePatientControllerGetCheckInScores } from '~/clinician-api'
import { useAssistPanelContext } from '../context'
import { useTreatmentPlan } from '@hooks'

export const AllWorksheetResults = () => {
  const { setOverlayPanel } = useAssistPanelContext()
  const { client } = useTreatmentPlan()

  const { data } = usePatientControllerGetCheckInScores(client.id, {
    query: {
      initialData: {
        scores: [],
        versions: []
      }
    }
  })

  const scoresWithCheckIn = useMemo(() => {
    if (data) {
      return data.scores.map(score => ({
        ...score,
        checkIn: data.versions.find(v => v.versionId === score.versionId)
      }))
    } else {
      return []
    }
  }, [data])

  return (
    <Box height="100%" p="medium" overflowY="scroll">
      <CompletedWorksheetList
        scoresWithCheckIn={scoresWithCheckIn}
        onClickWorksheet={s =>
          setOverlayPanel('WORKSHEET_RESULT', {
            checkIn: s.checkIn,
            score: s
          })
        }
      />
    </Box>
  )
}
