import {
    Box, Flex, HStack, Text
} from '@chakra-ui/react'
import React from 'react'

export const MobileTelehealthToggle = () => {
  return (
    <Box w="100%">
      <Flex
        w="100%"
        h="46px"
        align="center"
        bg="#E4E5E6"
        borderRadius="8px"
        mb="8px"
        px={1}
      >
        <HStack
          justifyContent="center"
          w="50%"
          h="38px"
          bg="white"
          borderRadius="4px"
        >
          <Text color="black">In-Person</Text>
        </HStack>
        <HStack
          justifyContent="center"
          spacing={3}
          w="50%"
          h="38px"
          bg="inherit"
          borderRadius="4px"
          cursor="not-allowed"
        >
          <Text
            color="#757575"
            textDecoration="line-through"
          >
            Telehealth
          </Text>
        </HStack>
      </Flex>
      <Text color="#757575" fontSize="12px">
        <Text as="span" color="#282828" fontWeight="bold" fontSize="12px">Note:</Text> Telehealth is not supported on mobile devices
      </Text>
    </Box>
  )
}