import { endpoints } from '@api'
import {
  Box,
  Menu,
  MenuItemOption,
  MenuList,
  MenuOptionGroup
} from '@chakra-ui/react'
import { keyBy } from 'lodash'
import React, { useMemo, useRef } from 'react'
import { useQuery } from 'react-query'
import { Chip } from './chip'
import { CustomMenuButton } from './filters'
import { FixedSizeList as List } from 'react-window'
import { OrganizationProvider } from 'src/api/endpoints'

interface ClinicianSelectorProps {
  allClinicIds?: string[]
  organizationId: string
  clinicIds: string[]
  clinicianId?: string | null
  onChange: (clinicianId: string | null) => any
}

export const ClinicianSelector: React.FC<ClinicianSelectorProps> = ({
  organizationId,
  clinicIds,
  clinicianId,
  onChange
}) => {
  const { data } = useQuery<OrganizationProvider[]>(
    [
      endpoints.getOrganizationProviders.getCacheId(),
      organizationId,
      clinicIds
    ],
    () =>
      endpoints.getOrganizationProviders.request({
        organizationId,
        clinicId: clinicIds,
        cliniciansOnly: true,
        includeCounts: false
      }),
    {
      enabled: !!(organizationId && clinicIds?.length),
      initialData: []
    }
  )

  const listRef = useRef<List>(null)

  const keyedClinicians = useMemo(() => keyBy(data, 'id'), [data])
  const handleChange = (clinicianId: string) => {
    const value = clinicianId === 'all' ? null : clinicianId
    onChange(value)
  }

  const onClear = () => {
    onChange(null)
  }

  const selectedClinician = !clinicianId ? null : keyedClinicians[clinicianId]

  const chips = (
    <>
      {selectedClinician ? (
        <Chip
          label={`${selectedClinician.first_name} ${selectedClinician.last_name}`}
          onClose={onClear}
        />
      ) : (
        <Chip label="All" />
      )}
    </>
  )

  const optionHeight = 46
  const numClinicians = data?.length || 0

  return (
    <Box>
      <Menu onClose={() => listRef.current?.scrollToItem(0)}>
        <CustomMenuButton
          onClick={() => {}}
          label="Clinicians"
          chips={chips}
          isMenu
        />
        <MenuList sx={{ maxHeight: 500, overflow: 'scroll' }}>
          <MenuOptionGroup type="radio">
            <List
              ref={listRef}
              height={Math.min(numClinicians * optionHeight, 460)}
              itemCount={numClinicians}
              itemSize={optionHeight}
              width={300}
            >
              {({ index, style }) => (
                <MenuItemOption
                  style={style}
                  value={data?.[index]?.id}
                  isChecked={data?.[index]?.id === clinicianId}
                  onClick={() => handleChange(data?.[index]?.id || '')}
                >
                  {data?.[index]?.first_name} {data?.[index]?.last_name}
                </MenuItemOption>
              )}
            </List>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  )
}
