import React, { useContext } from 'react'
import { Text, Select, VStack, HStack } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import * as Yup from 'yup'

import { WorkflowsContext } from '@context'
import { Loading } from '@components'
import { EditDrawerFooter } from '../../../edit-drawer/edit-drawer-footer'
import { endpoints } from '@api'
import {
  CLIENT_AGE_OPERATOR_SCHEMA,
  CLIENT_AGE_VALUES_SCHEMA
} from '../shared-filters/client-age-filter'
import { FilterCatalogMap } from '../shared-filters/lib/types'
import { FilterFieldArray } from '../shared-filters/filter-field-array'
import { ALL_WORKFLOW_FILTERS } from '../shared-filters/lib/constants'

type RouteParams = {
  workflowId: string
  organizationId: string
}

type Props = {
  closeDrawer: () => void
  onSubmit: (trigger: Trigger) => void
}

type Trigger = {
  workflowId: string
  triggerType: string
  config: TriggerConfig | {}
}

type TriggerConfig = {
  programId: string
}

type Program = {
  id: string
  name: string
}

const filterCatalog: FilterCatalogMap = {
  clientAge: ALL_WORKFLOW_FILTERS.clientAge
}

const schema = Yup.object().shape({
  programId: Yup.string().required(),
  clientAgeOperator: CLIENT_AGE_OPERATOR_SCHEMA,
  clientAgeValues: CLIENT_AGE_VALUES_SCHEMA
})

const determineFilterConfig = (filterType: string, values: any) => {
  switch (filterType) {
    case 'clientAge':
      return {
        filterType,
        filterOperator: values.clientAgeOperator,
        filterValues: values.clientAgeValues
      }
    default:
      return {}
  }
}

const prepareFiltersForSubmission = (values: any) => {
  return values.filterTypes.map((type: string) => {
    return determineFilterConfig(type, values)
  })
}

export const ProgramStartedTrigger = ({ closeDrawer, onSubmit }: Props) => {
  const { selectedNode } = useContext(WorkflowsContext)
  const { organizationId, workflowId } = useParams<RouteParams>()
  const config = selectedNode?.config
  const filters = config?.filters

  const initialValues = {
    programId: config?.programId || '',
    clientAgeOperator:
      filters?.find((f: any) => f.filterType === 'clientAge')?.filterOperator ||
      '',
    clientAgeValues: filters?.find((f: any) => f.filterType === 'clientAge')
      ?.filterValues || [1],
    filterTypes: filters?.map((f: any) => f.filterType) || []
  }

  const { data, isLoading } = useQuery(
    [endpoints.getPrograms.getCacheId(), organizationId],
    () =>
      endpoints.getPrograms.request({
        organizationId
      })
  )

  const allPrograms = data?.programs || []

  if (isLoading) return <Loading />

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={values => {
        const trigger: Trigger = {
          workflowId,
          triggerType: 'program-started-trigger',
          config: {
            programId: values.programId,
            filters: prepareFiltersForSubmission(values)
          }
        }
        onSubmit(trigger)
      }}
    >
      {({ values, errors, resetForm: resetFields }) => {
        const resetForm = (updates: any) => {
          const updatedValues = { ...initialValues, ...updates }
          resetFields({ values: updatedValues })
        }

        return (
          <Form>
            <VStack
              id="vstack-program-started"
              align="flex-start"
              spacing="xsmall"
              w="100%"
            >
              <HStack
                id="hstack-program-started"
                w="100%"
                spacing="small"
                justify="space-between"
              >
                <Text>Program is</Text>
                <Field name="programId">
                  {({ field }: { field: { value: string } }) => (
                    <Select
                      {...field}
                      value={field.value}
                      borderColor={errors?.programId ? 'error' : 'light_gray'}
                      color={field.value ? 'black' : 'gray'}
                      w="360px"
                      onChange={e => {
                        resetForm({ programId: e.target.value })
                      }}
                    >
                      <option value="" disabled>
                        Choose a program
                      </option>
                      {allPrograms?.map((program: Program) => (
                        <option
                          key={`program-filter-${program.id}`}
                          value={program.id}
                        >
                          {program.name}
                        </option>
                      ))}
                    </Select>
                  )}
                </Field>
              </HStack>
              {values?.programId && (
                <>
                  <Text as="b">Filters</Text>
                  <FilterFieldArray
                    values={values}
                    filterCatalog={filterCatalog}
                    resetForm={resetForm}
                  />
                </>
              )}
            </VStack>
            <EditDrawerFooter onCancel={closeDrawer} hideDelete />
          </Form>
        )
      }}
    </Formik>
  )
}
