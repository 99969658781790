import React, { useEffect, useRef, useState } from 'react'
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption
} from '@chakra-ui/react'
import { ChevronDown } from '@components/icons'

type Props = {
  optionValue: string
  onChange: (value: string) => void
  options: Array<{
    id: string
    display: string
  }>
}

export const TreatmentApproachDropdown = ({
  optionValue,
  onChange,
  options
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined)

  useEffect(() => {
    const updateWidth = () => {
      if (buttonRef.current) {
        setMenuWidth(buttonRef.current.offsetWidth)
      }
    }
    updateWidth()

    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])
  return (
    <Menu closeOnSelect>
      <MenuButton
        ref={buttonRef}
        w="100%"
        as={Button}
        value={optionValue}
        border="1px solid rgb(228, 229, 230)"
        borderColor="#C9C9C9"
        m={0}
        color="black"
        background="white"
        borderRadius="6px"
        height="48px"
        paddingInlineStart={4}
        rightIcon={<ChevronDown />}
        textAlign="start"
      >
        {options.find(o => o.id === optionValue)?.display}
      </MenuButton>
      <MenuList
        maxHeight="240px"
        overflowY="scroll"
        borderRadius="8px"
        zIndex={1000}
        width={`${menuWidth}px`}
      >
        <MenuOptionGroup
          defaultValue={optionValue}
          type="radio"
          onChange={onChange as (value: string | string[]) => void}
        >
          {options.map(option => (
            <MenuItemOption
              key={option.id}
              value={option.id}
              paddingLeft="20px"
            >
              {option.display}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
