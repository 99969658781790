import React, { useEffect } from 'react'
import { Container, GridItem } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { ClaimsManager } from './claims-manager'
import { BillingRules } from './rules'
import { Settings } from './settings'

export const ReportsClaims = () => {
  const { url } = useRouteMatch()
  const { user } = useStoreState(state => state.auth)
  const selectedClinic = useStoreState(state => state.billing.selectedClinic)
  const setSelectedClinic = useStoreActions(
    actions => actions.billing.setSelectedClinic
  )

  // Set default clinic
  useEffect(() => {
    if (!selectedClinic) {
      setSelectedClinic(user.clinic)
    }
  }, [selectedClinic, user.clinic])

  if (!selectedClinic) return null

  return (
    <Container
      sx={{
        mt: 'medium'
      }}
    >
      <LayoutGrid>
        <GridItem
          mb="xxlarge"
          colSpan={{
            base: 4,
            sm: 8,
            md: 12
          }}
        >
          <Switch>
            <Route path={`${url}/rules`} component={BillingRules} />
            <Route path={`${url}/settings`} component={Settings} />
            <Route exact path={url} component={ClaimsManager} />
          </Switch>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
