import React from 'react'
import {
  Box,
  Select,
  VStack,
  Text,
  CloseButton,
  HStack,
  Flex,
  Divider
} from '@chakra-ui/react'
import { Field, FormikErrors, useFormikContext } from 'formik'

type Props = {
  index: number
  filterType: string
  onFilterTypeChange: (value: string) => any
  removeFilter: () => void
  allFilterOptions: any
  children: any
}

type FormikContext = {
  values: any
  errors: FormikErrors<any>
}

const AndDivider = () => (
  <Flex width="100%" alignItems="center" mt="small" mb="small">
    <Divider />
    <Text color="gray" ml="xsmall" mr="xsmall">AND</Text>
    <Divider />
  </Flex>
)

export const FilterForm = ({
  index,
  filterType,
  onFilterTypeChange,
  removeFilter,
  allFilterOptions,
  children
}: Props): any => {
  const { errors }: FormikContext = useFormikContext()
  return (
    <VStack w="100%" spacing="xsmall" mt="xsmall">
      {index > 0 && (
        <AndDivider />
      )}
      <HStack w="100%" spacing="xsmall" align="center" mb="xsmall">
        <Field name={`filterTypes.${index}`}>
          {({ field }: { field: { value: string } }) => (
            <Select
              {...field}
              value={field.value}
              borderColor={
                errors && errors[`filterTypes.${index}`]
                  ? 'error'
                  : 'light_gray'
              }
              w="100%"
              color={field.value ? 'black' : 'gray'}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                onFilterTypeChange(e.currentTarget.value)
              }}
            >
              <option value="" disabled>
                Choose a filter
              </option>
             {allFilterOptions}
            </Select>
          )}
        </Field>
        <CloseButton
          onClick={() => {
            removeFilter()
          }}
        />
      </HStack>
      {filterType && (
      <Box w="100%">
        {children}
      </Box>
      )}
    </VStack>
  )
}
