import React, { useState } from 'react'
import { Button } from '@blueprinthq/joy'
import {
  HStack,
  Link,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/react'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'

import { endpoints } from '@api'

export function PTOScheduleModal({ clientId, handleModal, isModalOpen }) {
  const { data: schedule, isLoading } = useQuery(
    [endpoints.getPTOSchedule.getCacheId(), clientId],
    () => endpoints.getPTOSchedule.request({ clientId }),
    {
      initialData: []
    }
  )

  return (
    <>
      <span>N/A</span>
      {schedule && schedule.length > 0 && (
        <>
          <Text
            as={Link}
            onClick={handleModal}
            role="button"
            color="primary"
            textDecoration="underline"
            ml="xsmall"
          >
            View Schedule
          </Text>
          <Modal
            onClose={handleModal}
            isOpen={isModalOpen}
            preserveScrollBarGap
          >
            <ModalOverlay />
            <ModalContent p="small">
              <ScheduleModalContent
                clientId={clientId}
                handleModal={handleModal}
                isLoading={isLoading}
                schedule={schedule}
              />
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  )
}

function ScheduleModalContent({ clientId, handleModal, isLoading, schedule }) {
  const [isDelete, setIsDelete] = useState(false)
  const toast = useToast()

  const { mutateAsync: cancelPTOSchedule } = useMutation(
    endpoints.deletePTOSchedule.request,
    {
      onSuccess: () => {
        toast({
          title: 'Success!',
          description: 'Post Treatment Outcomes schedule cancelled.',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        handleModal()
      },
      onError: errObj => {
        console.error(
          'Error cancelling PTO schedule.',
          errObj.error ? errObj.error : errObj
        )
        toast({
          title: 'Error',
          status: 'error',
          description:
            'There was an error cancelling this Post Treatment Outcomes schedule; please try again. If this problem persists, please contact support for help.',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  return isDelete ? (
    <>
      <ModalHeader>Delete Schedule</ModalHeader>
      <Text textAlign="center">
        Are you sure you want to delete this client&apos;s upcoming assessment
        schedule? This cannot be undone.
      </Text>
      <HStack mt="small">
        <Button
          onClick={() => setIsDelete(false)}
          variant="outline"
          isFullWidth
        >
          Cancel
        </Button>
        <Button
          onClick={() => cancelPTOSchedule({ clientId })}
          bgColor="#B5342A"
          isFullWidth
        >
          Delete
        </Button>
      </HStack>
    </>
  ) : (
    <>
      <ModalHeader>Assessment Schedule</ModalHeader>
      {isLoading ? (
        <Text>Loading...</Text>
      ) : (
        <>
          {schedule.map(({ administrationDate, interval }) => (
            <Text key={administrationDate}>
              {`${moment(administrationDate).format(
                'M/D/YYYY [at] h:mmA'
              )} (${interval})`}
            </Text>
          ))}
          <Text
            as={Link}
            onClick={() => setIsDelete(true)}
            role="button"
            color="primary"
            textDecoration="underline"
            mt="xsmall"
          >
            Delete schedule
          </Text>
        </>
      )}
      <Button isFullWidth onClick={handleModal} variant="outline" mt="small">
        Close
      </Button>
    </>
  )
}
