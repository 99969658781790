import React from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  ErrorBar,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  BarChart,
  Bar,
  Label
} from 'recharts'
import {
  Heading,
  Box,
  Text,
  Flex,
  Tooltip as ChakraTooltip,
  Skeleton
} from '@chakra-ui/react'
import { Warning } from '@components/icons'
import { getEffectSizeProps, TIME_PERIODS } from '../constants'
import { COLORS } from '../../constants'
import { min, max, isNil } from 'lodash'

const ChartContainer = ({ children }) => {
  return (
    <Flex
      direction="column"
      sx={{
        width: '100%',
        height: 380
      }}
    >
      {children}
    </Flex>
  )
}

function AvgScorePopover(props) {
  const { payload } = props
  if (payload && payload.length) {
    const [data] = payload
    if (!data) return null
    const { payload: avgScore } = data

    const {
      value,
      errorHigh,
      errorLow,
      baselineAvg,
      clientCount,
      assessmentCount
    } = avgScore

    return (
      <Box
        sx={{
          background: 'white',
          padding: 'small',
          border: '1px solid',
          borderColor: 'light_gray'
        }}
      >
        <Box pb="3">
          <Text>Average score:</Text>
          <Box display="flex" alignItems="center">
            <Heading fontSize="lg">{value}</Heading>
          </Box>
        </Box>
        <Box pb="3">
          {baselineAvg && (
            <Text>
              Point change since baseline: {(value - baselineAvg).toFixed(2)}
            </Text>
          )}
          <Text>{`Upper: ${isNil(errorHigh) ? 'N/A' : errorHigh}`}</Text>
          <Text>{`Lower: ${isNil(errorLow) ? 'N/A' : errorLow}`}</Text>
        </Box>
        <Box>
          <Text fontWeight="bold">
            * Completed {assessmentCount.toLocaleString()} times by{' '}
            {clientCount.toLocaleString()} clients
          </Text>
        </Box>
      </Box>
    )
  }

  return null
}

function EffectSizePopover(props) {
  const { payload } = props

  if (payload && payload.length) {
    const [data] = payload
    if (!data) return null

    const { payload: effectSize } = data
    const {
      value,
      errorLow,
      errorHigh,
      clientCount,
      assessmentCount
    } = effectSize

    const { color, label } = getEffectSizeProps(value)

    return (
      <Box
        sx={{
          background: 'white',
          padding: 'small',
          border: '1px solid',
          borderColor: 'light_gray'
        }}
      >
        <Box pb="3">
          <Text>Treatment Response</Text>
          <Heading fontSize="lg" color={color}>
            {label}
          </Heading>
        </Box>
        <Box pb="3">
          <Text>Effect Size: {value}</Text>
          <Text>{`Upper: ${isNil(errorHigh) ? 'N/A' : errorHigh}`}</Text>
          <Text>{`Lower: ${isNil(errorLow) ? 'N/A' : errorLow}`}</Text>
        </Box>
        <Box>
          <Text fontWeight="bold">
            * Completed {assessmentCount.toLocaleString()} times by{' '}
            {clientCount.toLocaleString()} clients
          </Text>
        </Box>
      </Box>
    )
  }
  return null
}

function ClientCountPopover(props) {
  const { payload } = props

  if (payload && payload.length) {
    const [data] = payload
    if (!data) return null

    return (
      <Box
        sx={{
          background: 'white',
          padding: 'small',
          border: '1px solid',
          borderColor: 'light_gray'
        }}
      >
        <Box pb="3">
          <Heading fontSize="lg">{data.payload.label}</Heading>
        </Box>
        <Box pb="3">
          <Text>{data.payload.value || 0} Clients</Text>
        </Box>
      </Box>
    )
  }
  return null
}

const CustomizedDot = props => {
  const { cx, cy, value, fill = COLORS.BLUE } = props

  if (isNil(value)) return null

  const size = 50

  return (
    <svg x={cx - 25} y={cy - 25} width={size} height={size}>
      <circle cx={50 / 2} cy={50 / 2} r="16" fill={fill} />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        fill="white"
        fontSize="10px"
        fontFamily="Arial"
        dy=".3em"
      >
        {value}
      </text>
    </svg>
  )
}

const getPrimaryLineValue = data => {
  if (isNil(data.value)) return null
  if (data.interval === TIME_PERIODS.DISCHARGE) return null
  return data.value
}

const getDischargeLineValue = data => {
  if (isNil(data.value)) return null
  if (data.interval !== TIME_PERIODS.DISCHARGE) return null
  return data.value
}

function adjustErrorBarValues(data, lowerBound = null, upperBound = null) {
  const { errorHigh, errorLow, value } = data
  if (isNil(errorHigh) || isNil(errorLow)) return null

  const adjustedLow = value - max([errorLow, lowerBound])
  const adjustedHigh = min([errorHigh, upperBound]) - value
  return [adjustedLow, adjustedHigh]
}

const getPrimaryErrorBarValue = (
  data,
  lowerBound = null,
  upperBound = null
) => {
  if (data.interval === TIME_PERIODS.DISCHARGE) return null
  return adjustErrorBarValues(data, lowerBound, upperBound)
}

const getDischargeErrorBarValue = (
  data,
  lowerBound = null,
  upperBound = null
) => {
  if (data.interval !== TIME_PERIODS.DISCHARGE) return null
  return adjustErrorBarValues(data, lowerBound, upperBound)
}

export const AvgScoreChart = ({ data, assessment, isReverseValence }) => {
  const totalScore = assessment ? assessment.total_score : 0
  const ticks =
    totalScore > 6
      ? Array.from(Array(6)).map((_, idx) =>
          Math.ceil(parseFloat((idx * (totalScore / 5)).toFixed(1)))
        )
      : null

  const yAxisLabel = isReverseValence
    ? 'Avg Score (Higher is better)'
    : 'Avg Score (Lower is better)'

  return (
    <ChartContainer>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Heading fontSize="lg" my="2">
            Avg Score
          </Heading>
          <Skeleton isLoaded={!!assessment}>
            {assessment && (
              <Text>
                The {assessment.name} total score ranges from 0 to{' '}
                {assessment.total_score}
              </Text>
            )}
          </Skeleton>
        </Box>
      </Flex>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 28,
            right: 30,
            left: 14,
            bottom: 30
          }}
        >
          <CartesianGrid vertical={false} />
          <ReferenceLine y={totalScore} />
          <XAxis
            dataKey="label"
            padding={{ left: 100, right: 100 }}
            style={{ fill: 'black' }}
            axisLine={{ style: { stroke: 'black' } }}
          />
          <YAxis
            label={{
              value: yAxisLabel,
              angle: -90,
              position: 'insideLeft',
              style: {
                textAnchor: 'middle',
                fill: 'black'
              }
            }}
            allowDecimals={false}
            tickCount={6}
            ticks={ticks}
            domain={[0, totalScore]}
            style={{ fill: 'black' }}
            axisLine={false}
          />
          <Tooltip content={<AvgScorePopover />} />
          <Line
            strokeWidth={5}
            stroke={COLORS.BLUE}
            isAnimationActive={false}
            type="monotone"
            dataKey={getPrimaryLineValue}
            dot={<CustomizedDot fill={COLORS.BLUE} />}
            activeDot={{ r: 0 }}
            connectNulls
          >
            <ErrorBar
              dataKey={getPrimaryErrorBarValue}
              width={8}
              strokeWidth={3}
              stroke="#C9C9C9"
              direction="y"
            />
          </Line>
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey={getDischargeLineValue}
            dot={<CustomizedDot fill={COLORS.BLUE} />}
          >
            <ErrorBar
              dataKey={getDischargeErrorBarValue}
              width={8}
              strokeWidth={3}
              stroke="#C9C9C9"
              direction="y"
            />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}

export const ClientCountChart = ({ data }) => {
  const maxValue = max(data.map(i => i.value))
  return (
    <ChartContainer>
      <Flex justifyContent="space-between" alignItems="center" mb="2">
        <Box>
          <Heading fontSize="lg" my="2">
            Client Count
          </Heading>
        </Box>
      </Flex>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="label"
            padding={{ left: 25, right: 25 }}
            style={{ fill: 'black' }}
            axisLine={{ style: { stroke: 'black' } }}
          />
          <YAxis
            label={{
              value: 'Clients',
              angle: -90,
              position: 'insideLeft',
              style: {
                textAnchor: 'middle',
                fill: 'black'
              }
            }}
            style={{ fill: 'black' }}
            axisLine={false}
            tickCount={maxValue >= 10 ? 10 : 5}
            domain={[0, maxValue]}
          />
          <ReferenceLine y={maxValue} />
          <Tooltip content={<ClientCountPopover />} />
          <Bar dataKey={i => i.value || 0} fill={COLORS.BLUE} />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}

export const EffectSizeChart = ({ data }) => {
  const renderDot = props => {
    const { payload } = props
    if (payload.interval === TIME_PERIODS.BASELINE) return null

    const { color } = getEffectSizeProps(payload.value)

    return <CustomizedDot {...props} fill={color} />
  }

  const tooltip = (
    <Box p="2">
      <Text mb="3">
        Effect size is calculated based on the standardized difference between a
        client&apos;s baseline score and a client&apos;s score at each treatment
        interval. The result of this formula returns an effect size number:
      </Text>
      <Text>0.8 or above: Highly positive response</Text>
      <Text>0.5 to 0.7: Moderate positive response</Text>
      <Text>0.2 to 0.4: Mild positive response</Text>
      <Text>-.1 to 0.1: No treatment response</Text>
      <Text>-0.2 to -0.4: Mild negative response</Text>
      <Text>-0.5 to -0.7: Moderate negative response</Text>
      <Text>-0.8 or below: Highly negative response</Text>
    </Box>
  )

  const yAxisAbsolute = max(data.map(i => Math.ceil(Math.abs(i.value)))) || 0

  const yAxisMax = yAxisAbsolute
  const yAxisMin = -yAxisMax

  return (
    <ChartContainer>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Heading fontSize="lg" my="2">
            Effect Size
          </Heading>
          <Box display="inline-block">
            <ChakraTooltip
              label={tooltip}
              maxWidth="600px"
              placement="bottom-start"
              hasArrow
            >
              <Box ml="3" mt="1">
                <Warning width="20px" height="20px" fill={COLORS.GRAY} />
              </Box>
            </ChakraTooltip>
          </Box>
        </Flex>
      </Flex>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 28,
            right: 30,
            left: 14,
            bottom: 30
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="label"
            padding={{ left: 100, right: 100 }}
            style={{ fill: 'black' }}
            axisLine={{ style: { stroke: 'black' } }}
          />
          <YAxis
            label={{
              value: 'Effect Size',
              angle: -90,
              position: 'insideLeft',
              style: {
                textAnchor: 'middle',
                fill: 'black'
              }
            }}
            style={{ fill: 'black' }}
            padding={{ bottom: 10 }}
            axisLine={false}
            tickCount={9}
            interval="preserveStartEnd"
            tickFormatter={t => (t === 0 ? '' : t.toFixed(2))}
            domain={[yAxisMin, yAxisMax]}
          />
          <ReferenceLine y={0} stroke="black">
            <Label value="0" position="left" style={{ fill: 'black' }} />
          </ReferenceLine>
          <Tooltip content={<EffectSizePopover />} />
          <Line
            strokeWidth={5}
            stroke="#C9C9C9"
            isAnimationActive={false}
            type="monotone"
            dataKey={getPrimaryLineValue}
            dot={renderDot}
            activeDot={{ r: 0 }}
            connectNulls
          >
            <ErrorBar
              dataKey={data =>
                getPrimaryErrorBarValue(data, yAxisMin, yAxisMax)
              }
              width={8}
              strokeWidth={3}
              stroke="#C9C9C9"
              direction="y"
            />
          </Line>
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey={getDischargeLineValue}
            dot={renderDot}
            activeDot={{ r: 0 }}
          >
            <ErrorBar
              dataKey={data =>
                getDischargeErrorBarValue(data, yAxisMin, yAxisMax)
              }
              width={8}
              strokeWidth={3}
              stroke="#C9C9C9"
              direction="y"
            />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}
