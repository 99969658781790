import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  Flex,
  Box,
  Text,
  useBreakpointValue,
  HStack,
  useBoolean,
  Select
} from '@chakra-ui/react'
import { Button, GoForwardIcon } from '@blueprinthq/joy'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'

import { endpoints } from '../../../api'
import { usePermissions } from '../../../hooks'
import {
  CalendarMonthPicker,
  ClaimsTable,
  GetStartedPopover
} from './components'
import { Loading } from '../../../components'

export const ClaimsManager = () => {
  const queryClient = useQueryClient()
  const selectedClinic = useStoreState(state => state.billing.selectedClinic)
  const setSelectedClinic = useStoreActions(
    actions => actions.billing.setSelectedClinic
  )
  const { hasPermission } = usePermissions()
  const history = useHistory()
  const [isPopoverOpen, setPopoverOpen] = useBoolean()

  const organizationId = selectedClinic.organization_id

  const now = DateTime.now()
  const tz = now.zoneName
  const [selectedCalendarMonth, setSelectedCalendarMonth] = useState(() => ({
    month: now.month,
    year: now.year
  }))

  const { dateStart, dateEnd } = useMemo(() => {
    const selectedDate = DateTime.fromObject({
      year: selectedCalendarMonth.year,
      month: selectedCalendarMonth.month
    })

    return {
      dateStart: selectedDate
        .toLocal()
        .startOf('month')
        .toISODate(),
      dateEnd: selectedDate
        .toLocal()
        .plus({ month: 1 })
        .startOf('month')
        .toISODate()
    }
  }, [selectedCalendarMonth])

  const onChangeCalendarMonth = useCallback(
    calendarMonth => {
      setSelectedCalendarMonth(calendarMonth)
    },
    [setSelectedCalendarMonth]
  )

  const {
    data: orgBillingPreferences,
    isFetching: preferencesFetching
  } = useQuery(
    [endpoints.getOrgBillingPreferences.getCacheId(), organizationId],
    () =>
      endpoints.getOrgBillingPreferences.request({
        organizationId
      }),
    {
      initialData: {
        cptCodes: [],
        modifiers: []
      }
    }
  )

  const { data: clinics } = useQuery(
    [
      endpoints.getOrganizationClinics.getCacheId(),
      selectedClinic && selectedClinic.organization_id
    ],
    () =>
      endpoints.getOrganizationClinics.request({
        organizationId: selectedClinic.organization_id
      }),
    {
      // Only org admins and super admins can switch clinic views
      enabled: hasPermission('read:org:clinics:list'),
      initialData: [],
      onSuccess(data) {
        if (data.length && selectedClinic === null) {
          setSelectedClinic(data[0])
        }
      }
    }
  )

  const claimsCacheKey = useMemo(
    () => [
      endpoints.getClinicClaims.getCacheId(),
      selectedClinic.id,
      dateStart,
      dateEnd
    ],
    [selectedClinic.id, dateStart, dateEnd]
  )

  const {
    data: claims,
    isFetching: isClaimsFetching,
    isPlaceholderData: isClaimsPlaceholderData,
    dataUpdatedAt: claimsUpdatedAt
  } = useQuery(
    claimsCacheKey,
    () =>
      endpoints.getClinicClaims.request({
        clinicId: selectedClinic.id,
        dateStart,
        dateEnd,
        tz
      }),
    {
      placeholderData: []
    }
  )

  const {
    data: { nodes: orgInsurancePayers }
  } = useQuery(
    [endpoints.getInsurancePayers.getCacheId(), selectedClinic.organization_id],
    () =>
      endpoints.getInsurancePayers.request({
        organizationId: selectedClinic.organization_id,
        shortList: true
      }),
    {
      initialData: { nodes: [] }
    }
  )

  const { mutateAsync: executeChangeClaim } = useMutation(
    endpoints.putChangeClaimStatus.request,
    {
      onMutate: async ({ data, claimId }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(claimsCacheKey)

        const previousClaimsList = queryClient.getQueryData(claimsCacheKey)

        const nextClaimsList = previousClaimsList.map(claim => {
          if (claimId === claim.claimId) {
            if (data.status) {
              claim.status = data.status
            }
            if (data.modifier) {
              claim.modifier = `${data.modifier}`
            }
            if (data.cptCode) {
              claim.cptCode = `${data.cptCode}`
            }
          }
          return claim
        })

        queryClient.setQueryData(claimsCacheKey, nextClaimsList)

        return nextClaimsList
      },
      onSuccess: async (updates, { data }) => {
        const insurancePayer = orgInsurancePayers.find(
          payer => payer.id === data.insurancePayerId
        )
        const updatedClaims = [
          updates.claim,
          ...updates.associatedClaimUpdates
        ].reduce((obj, claim) => {
          obj[claim.id] = claim
          return obj
        }, {})

        await queryClient.cancelQueries(claimsCacheKey)
        const previousClaimsList = queryClient.getQueryData(claimsCacheKey)
        const nextClaimsList = previousClaimsList.map(claim => {
          const matchedClaimUpdate = updatedClaims[claim.claimId]
          if (matchedClaimUpdate) {
            claim.cptCode = matchedClaimUpdate.cptCode
            claim.modifier = matchedClaimUpdate.modifier
            claim.units = matchedClaimUpdate.units
            claim.status = matchedClaimUpdate.status
            if (insurancePayer) claim.insurancePayer = insurancePayer
          }
          return claim
        })

        queryClient.setQueryData(claimsCacheKey, nextClaimsList)

        return nextClaimsList
      }
    }
  )

  const onChangeClaim = useCallback(
    (claimId, { status, cptCode, modifier, insurancePayerId }) => {
      const data = {
        status,
        modifier,
        cptCode,
        insurancePayerId
      }

      executeChangeClaim({
        data,
        claimId
      })
    },
    [executeChangeClaim]
  )

  const isShowGetStartedPopover = useMemo(() => {
    return !preferencesFetching && !orgBillingPreferences.cptCodes.length
  }, [orgBillingPreferences, preferencesFetching])

  useEffect(() => {
    isShowGetStartedPopover ? setPopoverOpen.on() : setPopoverOpen.off()
  }, [isShowGetStartedPopover])

  const onClickBillingRules = useCallback(() => {
    if (isShowGetStartedPopover) {
      history.push('/reports/claims/settings')
    } else {
      history.push('/reports/claims/rules')
    }
  }, [isShowGetStartedPopover])

  const headerFlexDirection = useBreakpointValue({
    base: 'column',
    sm: 'row'
  })

  return (
    <Box>
      <Flex
        justify="space-between"
        alignItems="center"
        flexDirection={headerFlexDirection}
      >
        <Box
          width={{
            base: '100%',
            sm: 'auto'
          }}
          mb={{
            base: 'medium',
            sm: '0px'
          }}
        >
          <CalendarMonthPicker
            year={selectedCalendarMonth.year}
            month={selectedCalendarMonth.month}
            onChange={onChangeCalendarMonth}
            isIncrementDisabled={
              selectedCalendarMonth.month === now.month &&
              selectedCalendarMonth.year === now.year
            }
            isDecrementDisabled={false}
          />
        </Box>
        <Box
          width={{
            base: '100%',
            sm: 'initial'
          }}
        >
          <HStack spacing="small" alignItems="normal">
            {hasPermission('read:org:clinics:list') && (
              <Select
                w="300px"
                value={selectedClinic.id}
                onChange={e => {
                  const clinic = clinics.find(c => c.id === e.target.value)
                  setSelectedClinic(clinic)
                }}
              >
                {clinics &&
                  clinics.map(c => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
              </Select>
            )}
            <GetStartedPopover
              isOpen={isPopoverOpen}
              onClose={setPopoverOpen.off}
            >
              <Button
                minWidth="auto"
                borderRadius="4px"
                margin="0px"
                height="auto"
                rightIcon={<GoForwardIcon fill="white" size="sm" />}
                onClick={onClickBillingRules}
              >
                Billing Rules
              </Button>
            </GetStartedPopover>
          </HStack>
        </Box>
      </Flex>
      <Flex mt="xlarge" width="100%" flexDirection="column">
        <Box
          width="100%"
          overflowX={{
            base: 'auto',
            md: 'initial'
          }}
        >
          <ClaimsTable
            data={claims}
            onChangeClaim={onChangeClaim}
            dataUpdatedAt={claimsUpdatedAt}
            orgBillingPreference={orgBillingPreferences}
            orgInsurancePayers={orgInsurancePayers}
          />
        </Box>
        {isClaimsFetching && isClaimsPlaceholderData && (
          <Flex width="100%" justify="center" mt="xlarge">
            <Loading />
          </Flex>
        )}
        {claims.length === 0 && !isClaimsFetching && !isClaimsPlaceholderData && (
          <Flex width="100%" justify="center" mt="xlarge">
            <Box>
              <Text>No claims occured in this month.</Text>
            </Box>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
