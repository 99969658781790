import React, { useMemo } from 'react'
import { useQuery } from 'react-query'

import { endpoints } from '@api'
import { formatCadence, convertWeeksToCorrectUnit } from '@utilities'
import { CadenceUnits } from '@constants/cadenceUnits'
import { Select } from '@blueprinthq/joy'

export const AssessmentCadenceMenu = ({
  assessment,
  onChange,
  index,
  isChildAssignee,
  assigneeIdentifier
}) => {
  const { data: cadences } = useQuery(
    [endpoints.getAssessmentFrequencies.getCacheId()],
    endpoints.getAssessmentFrequencies.request,
    {
      initialData: []
    }
  )

  const formattedCadences = useMemo(() => {
    return cadences.map(cadence => ({
      ...cadence,
      name: formatCadence(cadence.value, cadence.unit)
    }))
  }, [cadences])

  const selectedCadence = useMemo(() => {
    if (isChildAssignee) {
      return { name: 'In session only', unit: CadenceUnits.TIME, value: 1 }
    }

    if (assessment.isScreener) {
      return { name: 'One time', unit: CadenceUnits.TIME, value: 1 }
    }

    const assessmentCadence = convertWeeksToCorrectUnit({
      unit: assessment.cadence_unit,
      value: assessment.cadence_value
    })

    const formattedCadence = formattedCadences.find(
      ({ value, unit }) =>
        value === assessmentCadence.value && unit === assessmentCadence.unit
    )
    if (formattedCadence) {
      return formattedCadence
    }

    return {
      // if its not one of the allowed cadences from the backend, still display it, but will not be an option in dropdown
      ...assessmentCadence,
      name: formatCadence(assessmentCadence.value, assessmentCadence.unit)
    }
  }, [assessment, formattedCadences, isChildAssignee])

  return (
    <Select
      simple
      style={{ border: '1px solid #E4E5E6' }}
      isDisabled={isChildAssignee || assessment.isScreener}
      options={formattedCadences}
      valueKey="name"
      labelKey="name"
      label="Select cadence"
      value={selectedCadence}
      onChange={value => onChange(value, assigneeIdentifier, index)}
    />
  )
}
