import React, { Component } from 'react'
import styles from './enrollmentoutcomes.module.css'
import { CircleCheck, Caution } from '../icons'
import { renderSubstringAsLink } from '../../utilities'

export default class EnrollmentOutcomes extends Component {
  constructor(props) {
    super(props)
  }

  renderIcon = () => {
    const { icon } = this.props
    switch (icon) {
      case 'warning':
        return <Caution className={styles.icon} fill="#f3a53c" />

      default:
        return <CircleCheck className={styles.icon} fill="#44CD7D" />
    }
  }

  render() {
    const { message, title, subtext } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.iconContainer}>{this.renderIcon()}</div>
        <p className={styles.title}>{title}</p>
        <p className={styles.body}>
          {renderSubstringAsLink(
            message,
            'contact us',
            `mailto:${process.env.REACT_APP_HELP_EMAIL}`,
            false
          )}
        </p>
        <p className={styles.body}>
          {renderSubstringAsLink(
            subtext,
            'contact us',
            `mailto:${process.env.REACT_APP_HELP_EMAIL}`,
            false
          )}
        </p>
      </div>
    )
  }
}
