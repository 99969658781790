import React, { useCallback, useState, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { makeStyles } from '@material-ui/core'
import cx from 'classnames'
import { LayoutGrid, CalendarIcon, CheckIcon } from '@blueprinthq/joy'
import { Flex, Text, Heading, GridItem } from '@chakra-ui/react'
import { Dialog } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'

import {
  isPatientMissingContactInfo,
  formatDateOfInvite
} from '../../../../utilities'
import { endpoints } from '../../../../api/index'
import { PrimaryButton } from '../../../../components'

const useStyles = makeStyles({
  title: {
    marginBottom: 32,
    textAlign: 'center',
    fontSize: '24px',
    lineHeight: '36px'
  },
  bold: {
    fontWeight: 'bold'
  },
  buttonContainer: {
    marginBottom: 24,
    lineHeight: '20px'
  },
  link: {
    color: '#2d54e8',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginTop: 16
  },
  button: {
    height: 48,
    width: 343
  },
  icon: {
    marginBottom: 16
  },
  error: {
    color: '#DB1544'
  }
})

export function Summary({
  preEnrollPatient,
  openAdjustEnrollmentDateModal,
  openArchiveModal
}) {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)
  const [displayError, setDisplayError] = useState(null)
  const classes = useStyles()
  const formattedDateOfInvite = formatDateOfInvite(
    preEnrollPatient.date_of_invite
  )

  const openContactInfoModal = useStoreActions(
    actions => actions.modals.contactInfo.openModal
  )

  const {
    mutateAsync: executePreEnrollPatient,
    data: enrollResult,
    isLoading: isPreEnrollLoading
  } = useMutation(endpoints.postCliniciansUsersPreEnrollInvite.request, {
    onError: () => {
      setDisplayError(
        'There was an error enrolling this client. Please refresh the page and try again.'
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries(endpoints.getPatientList.getCacheId())
      setSuccessModalOpen(true)
    }
  })

  const navigateToEnrolledPatient = useCallback(() => {
    if (enrollResult && enrollResult.enrollment_successes.length) {
      const enrolledPatientId = enrollResult.enrollment_successes[0].patient_id
      history.push(`/patient/${enrolledPatientId}`)
    }
  }, [enrollResult])

  const enrollNow = async () => {
    await executePreEnrollPatient({ patientId: preEnrollPatient.id })
  }

  const dialogText = useMemo(() => {
    const { email, phone_number, first_name } = preEnrollPatient
    let inviteMethodMsg = 'an email'
    if (email && phone_number) {
      inviteMethodMsg = 'an email and a text message'
    } else if (!email && phone_number) {
      inviteMethodMsg = 'a text message'
    }

    return `We sent ${first_name} (${inviteMethodMsg}) inviting them to join Blueprint and take their first assessment.`
  }, [preEnrollPatient])

  const enrollmentText = isPatientMissingContactInfo(preEnrollPatient) ? (
    <p className={cx(classes.title, classes.error)}>
      We need either an email address or cell phone number on file in order to
      send {preEnrollPatient.first_name} an invitation to start using Blueprint
      on <span className={classes.bold}> {formattedDateOfInvite}</span>.
    </p>
  ) : (
    <p className={classes.title}>
      We&apos;re all set to send {preEnrollPatient.first_name} an invitiation to
      start using Blueprint on{' '}
      <span className={classes.bold}> {formattedDateOfInvite}</span>.
    </p>
  )

  return (
    <LayoutGrid>
      <GridItem
        colStart={{
          base: 1,
          sm: 2,
          md: 4
        }}
        colEnd={{
          base: 5,
          sm: 8,
          md: 10
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
            mt: 'xlarge',
            mb: 'xlarge'
          }}
        >
          <CalendarIcon
            size="lg"
            className={classes.icon}
            fill={
              isPatientMissingContactInfo(preEnrollPatient)
                ? '#DB1544'
                : 'black'
            }
          />
          {enrollmentText}
          {displayError && <p>{displayError}</p>}
          <div className={classes.buttonContainer}>
            {isPatientMissingContactInfo(preEnrollPatient) ? (
              <PrimaryButton
                round
                className={classes.button}
                loading={isPreEnrollLoading}
                onClick={() =>
                  openContactInfoModal({
                    patientId: preEnrollPatient.id,
                    contactInfoRequirement: 'any'
                  })
                }
              >
                Add Contact Info
              </PrimaryButton>
            ) : (
              <PrimaryButton
                round
                className={classes.button}
                loading={isPreEnrollLoading}
                onClick={() => enrollNow()}
              >
                Enroll now
              </PrimaryButton>
            )}
          </div>
          <div className={classes.buttonContainer}>
            <PrimaryButton
              onClick={openAdjustEnrollmentDateModal}
              round
              className={classes.button}
            >
              Adjust enrollment date/time
            </PrimaryButton>
          </div>
          <div className={classes.link}>
            <p onClick={openArchiveModal}>
              {preEnrollPatient.is_archived ? 'Unarchive' : 'Discharge'}{' '}
              {preEnrollPatient.first_name}
            </p>
          </div>
        </Flex>
        <Dialog
          open={isSuccessModalOpen}
          onClose={() => setSuccessModalOpen(false)}
          maxWidth={'xs'}
          fullWidth={true}
          fullScreen={isMobile}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              py: 'large',
              px: 'xlarge'
            }}
          >
            <Flex
              sx={{
                backgroundColor: 'primary',
                width: 58,
                height: 58,
                borderRadius: '50%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CheckIcon fill={'white'} />
            </Flex>
            <Heading sx={{ mt: 'small' }}>Invite Sent!</Heading>
            <Text
              sx={{
                textAlign: 'center',
                my: 'small'
              }}
            >
              {dialogText}
            </Text>
            <PrimaryButton
              onClick={navigateToEnrolledPatient}
              round
              className={classes.button}
            >
              Close
            </PrimaryButton>
          </Flex>
        </Dialog>
      </GridItem>
    </LayoutGrid>
  )
}
