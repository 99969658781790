import React, { useCallback } from 'react'
import {
  Flex,
  Text,
  VStack,
  Box,
  Divider,
  Button,
  Link
} from '@chakra-ui/react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

interface ClientInterventionListProps {
  onClick: () => void
  interventionList: any[] | undefined
  title: string
}

export const ClientInterventionList = ({
  onClick,
  interventionList,
  title
}: ClientInterventionListProps) => {
  const location = useLocation()
  const [baseUrl] = location.pathname.split('/settings')

  if (!Boolean(interventionList?.length)) return null

  return (
    <>
      <Box
        p="4px 24px"
        bg="pale_gray"
        width="calc(100% + 3rem)"
        my="xsmall"
        transform="translateX(-1.5rem)"
      >
        <Text fontWeight="bold" textTransform="uppercase">
          {title}
        </Text>
      </Box>
      <VStack divider={<Divider color="pale_gray" />}>
        {interventionList?.map((intervention: any) => (
          <Flex
            justify="space-between"
            key={intervention.id}
            w="100%"
            display="flex"
          >
            <Flex align="center" flex="1" minW="0">
              <Box flex="1" minW="0">
                <Text>{intervention.name}</Text>
                <Text
                  color="dark_gray"
                  sx={{
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word'
                  }}
                >
                  {intervention.short_description}
                </Text>

                <Box
                  p="2px 4px"
                  bg="pale_gray"
                  width="fit-content"
                  borderRadius="3px"
                >
                  <Text fontSize="12px" textTransform="uppercase">
                    {intervention.treatment_modality}
                  </Text>
                </Box>
              </Box>
            </Flex>

            <Flex align="center">
              <Link
                id={`pendo-intervention-view-link-tag-${intervention.id}`}
                as={RouterLink}
                color="primary"
                onClick={onClick}
                flexShrink={0}
                to={{
                  pathname: `${baseUrl}/interventions/${intervention.id}/summary`,
                  state: { prevLocation: location.pathname }
                }}
              >
                <Text fontSize={'16px'}>Learn more</Text>
              </Link>
              <Link
                id={`pendo-intervention-start-tag-${intervention.id}`}
                as={RouterLink}
                color="primary"
                onClick={onClick}
                flexShrink={0}
                ml={'4'}
                _hover={{
                  textDecoration: 'none'
                }}
                to={{
                  pathname: `${baseUrl}/interventions/${intervention.id}/form`
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button paddingLeft={'6'} paddingRight={'6'}>
                  <Text fontSize={'16px'}>Start</Text>
                </Button>
              </Link>
            </Flex>
          </Flex>
        ))}
      </VStack>
    </>
  )
}
