import React from 'react'
import { Box, HStack, Image, Stack } from '@chakra-ui/react'
import UnionSVG from '../../images/brand/union.svg'
import { HeaderV2 } from './components'
import { Wrapper, NavBar } from '../integration-ui/components'
import { useAudioCapture, useExperienceManager } from '@hooks'
import BlueprintLogo from '../../images/brand/blue_logo.svg'

type Props = {
  isExtensionMinimized: boolean
  hasStartedRecording: boolean
  children: any
}

export const SessionWrapper = ({
  isExtensionMinimized,
  hasStartedRecording,
  children
}: Props) => {
  const { isIntegratedUI, isSessionRecordingV2Enabled } = useExperienceManager()

  const { isMuted } = useAudioCapture()

  if (isIntegratedUI) {
    return (
      <Wrapper>
        <NavBar hasStartedRecording={hasStartedRecording} />
        {!isExtensionMinimized && children}
      </Wrapper>
    )
  }

  if (!isSessionRecordingV2Enabled) {
    return (
      <Stack
        bg="primary"
        backgroundRepeat="no-repeat"
        backgroundImage={UnionSVG}
        backgroundPosition="left top"
        height={{ base: '100%', md: '100vh' }}
      >
        <HeaderV2 />
        {children}
      </Stack>
    )
  }

  return (
    <Stack
      backgroundColor={isMuted ? '#E4E5E6' : '#F6F7FF'}
      height={{ base: '100%', md: '100vh' }}
    >
      <Box position="relative" style={{ margin: '0 16px' }}>
        <HStack
          style={{ margin: 0 }}
          justifyContent={{ base: 'center', md: 'space-between' }}
          alignItems="center"
          h={{
            base: '80px',
            sm: '80px',
            md: '16px'
          }}
          my="medium"
        >
          <Image
            position="absolute"
            top="25px"
            loading="eager"
            src={BlueprintLogo}
            paddingLeft="16px"
          />
        </HStack>
      </Box>
      {children}
    </Stack>
  )
}
