import React from 'react'
import { Box } from '@chakra-ui/react'
import { NavBar } from '.'
import { SessionNotesV2 } from '../../sessions/session-notes-v2'
import { Wrapper } from '../components'

export function ExtensionSessionNotes() {
  return (
    <Wrapper>
      <NavBar showBackButton />
      <Box backgroundColor="white" height="100%">
        <SessionNotesV2 />
      </Box>
    </Wrapper>
  )
}
