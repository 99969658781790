import { action } from 'easy-peasy'

export const snackbarModel = {
  snackbar: {
    isOpen: false,
    variant: null,
    message: null,
    duration: 3000,
    position: 'bottom',
    setMessage: action(
      (state, { message, variant, duration = 3000, position = 'bottom' }) => {
        state.message = message
        state.variant = variant
        state.duration = duration
        state.position = position
        state.isOpen = true
      }
    ),
    closeSnackbar: action(state => {
      state.isOpen = false
    })
  }
}
