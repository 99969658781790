import React, { useMemo, useCallback } from 'react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'

import {
  Button,
  PendingCircle,
  CheckCircle,
  CloseCircle
} from '@blueprinthq/joy'

const statusOptions = [
  {
    label: 'Ready to submit',
    value: 'ready_to_submit',
    icon: <PendingCircle fill="dark_gray" size="sm" />
  },
  {
    label: 'Submitted',
    value: 'submitted',
    icon: <CheckCircle fill="primary" size="sm" />
  },
  {
    label: "Won't submit",
    value: 'no_submit',
    icon: <CloseCircle fill="dark_gray" size="sm" />
  }
]

export const ClaimStatusSelect = ({ value, onSelect }) => {
  const selectedOption = useMemo(
    () => statusOptions.find(option => option.value === value),
    [value]
  )

  const onChange = useCallback(
    selected => {
      if (onSelect) {
        onSelect(selected)
      }
    },
    [onSelect]
  )

  return (
    <Menu isLazy>
      <MenuButton
        sx={{
          height: 'auto',
          m: '0px'
        }}
        variant="ghost"
        as={Button}
        leftIcon={selectedOption.icon}
      >
        {selectedOption.label}
      </MenuButton>
      <MenuList>
        {statusOptions.map(option => (
          <MenuItem
            icon={option.icon}
            key={option.value}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
