import React from 'react'
import { Flex, GridItem } from "@chakra-ui/react"

import { TakeNowOutcome, DateOfBirth } from '../../handlers/user-profiles/components/header-components'
import { MenuButtons } from '../../handlers/user-profiles/patient-profile/components/patient-header/components/MenuButtons'
import { SummaryTabs } from '../../handlers/user-profiles/patient-profile/components/patient-header/components/SummaryTabs'

export const componentConfig = {
  PatientProfileHeader: {
    getHeaderAttributes: function (props) {
      const {
        data,
        activeAssessmentsData,
        isSendingAssessment,
        handleSendPatientAssessmentLink,
        anchorEl,
        handleMenuOpen,
        handleMenuClose
      } = props

      return [
        {
          name: 'DOB',
          value: <DateOfBirth patient={data} />
        },
        {
          name: 'outcome measures',
          value: <TakeNowOutcome
            hideTakeNow
            activeAssessments={activeAssessmentsData}
            patient={data}
            isSendingAssessment={isSendingAssessment}
            handleSendAssessment={handleSendPatientAssessmentLink}
            anchorEl={anchorEl}
            handleMenuOpen={handleMenuOpen}
            handleMenuClose={handleMenuClose}
          />
        },
      ]
    },
    ProfileHeaderRight: function ProfileHeaderRight(props) {
      const { data, anchorEl, activeAssessmentsData, handleMenuOpen, handleMenuClose } = props

      return(
        <>
          <GridItem colStart={{
            base: 4,
            sm: 7,
            md: 8,
          }} colEnd={{
            base: 5,
            sm: 9,
            md: 12
          }}>
            <Flex sx={{ alignItems: 'center', flexDirection: 'row' }}>
              <MenuButtons
                patient={data}
                activeAssessments={activeAssessmentsData}
                handleMenuOpen={handleMenuOpen}
                handleMenuClose={handleMenuClose}
                anchorEl={anchorEl}
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={{
            base: 5,
            sm: 9,
            md: 12
          }}>
            <Flex sx={{ alignItems: 'center', flexDirection: 'row', flex: 1, justifyContent: 'center' }}>
              <SummaryTabs />
            </Flex>
          </GridItem>
        </>
      )
    }
  }
}
