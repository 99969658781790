import React from 'react'
import { Field, FormikErrors, useFormikContext } from 'formik'
import { Select } from '@chakra-ui/react'

import { Assessment } from '../../assessment-submitted-trigger'

export const AnswerField = ({
  assessment,
  index,
  values
}: {
  assessment: Assessment | undefined
  index: number
  replace: Function
  values: any
}) => {
  const { errors }: { errors: FormikErrors<any> } = useFormikContext()
  return (
    <Field name={`triggerAnswers.${index}`}>
      {({ field, form }: { field: { value: number }; form: any }) => (
        <Select
          {...field}
          value={field.value}
          borderColor={errors?.triggerAnswers ? 'error' : 'light_gray'}
          color={field.value ? 'black' : 'gray'}
          w="380px"
          mb="xsmall"
        >
          <option value="" disabled>
            Choose an answer
          </option>
          {assessment?.questions
            ?.find(q => q.key === form.values.questionKey)
            ?.answers?.map((a, i) => (
              <option
                key={`${i}-${a.value}`}
                value={a.value}
                disabled={values.includes(a.value.toString())}
              >
                {a.title}
              </option>
            ))}
        </Select>
      )}
    </Field>
  )
}
