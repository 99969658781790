import React from 'react'
import {
  Box,
  Text,
  SimpleGrid,
  Button,
  HStack,
  useToast
} from '@chakra-ui/react'
import { useStoreActions } from 'easy-peasy'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { useExperienceManager } from '@hooks'
import moment from 'moment'
import { formatDollarAmount } from '@utilities'
import * as clinicianTracking from '@lib/clinician-tracking'
import { InfoIcon } from '@blueprinthq/joy'
import { standardErrorDescription } from '../../../../constants/Copy'
import { PlusFreeTrialStatus } from '@handlers/settings/plans/components/plus-free-trial-status'
import { useHistory } from 'react-router-dom'

export const DocAutomationBox = ({
  billingData,
  selectedClinic
}: {
  billingData: any
  selectedClinic: any
}) => {
  const history = useHistory()

  const hasActiveDocAutomationPlan = Boolean(
    billingData?.activeDocAutomationPlan
  )
  const hasCanceledDocAutomationPlan = Boolean(
    billingData?.canceledDocAutomationSubscription
  )
  const hasAnyDocAutomationPlan =
    hasActiveDocAutomationPlan || hasCanceledDocAutomationPlan

  const hasDowngradeData = Boolean(
    billingData?.activeDocAutomationPlan?.downgradeData
  )

  const schedulePhases =
    billingData?.activeDocAutomationPlan?.downgradeData?.phases

  const {
    isDocumentationAutomationFreePlan,
    hasPlusPlanFreeTrial,
    isEvidenceBasedCareEnabled
  } = useExperienceManager()

  const toast = useToast()
  const queryClient = useQueryClient()

  const openDAModal = useStoreActions(
    (actions: any) =>
      actions.modals.documentationAutomationMarketingModal.openModal
  )
  const openManageScribePlanModal = useStoreActions(
    (actions: any) => actions.modals.manageScribePlan.openModal
  )
  const openCancelIndividualPlanModal = useStoreActions(
    (actions: any) => actions.modals.cancelIndividualPlan.openModal
  )

  const { mutateAsync: reactivatePlan, isLoading } = useMutation(
    endpoints.patchIndividualBillingPlan.request,
    {
      onSuccess() {
        toast({
          title: `Your Documentation Automation plan has been reactivated.`,
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        )
        queryClient.invalidateQueries(endpoints.getClinicBilling.getCacheId())
      },
      onError(err: any) {
        toast({
          title: `We ran into an issue reactivating your Documentation Automation plan.`,
          description: standardErrorDescription(),
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  const renderHasAnyDAPlanContent = () => {
    const plan =
      billingData?.activeDocAutomationPlan ||
      billingData?.canceledDocAutomationSubscription

    return (
      <>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 0'
          }}
        >
          <Text fontSize={'16px'}>Plan</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {plan.planName}
          </Text>
          {renderManageAndCancel(plan)}
          {hasPlusPlanFreeTrial && (
            <Box pt={4} pb={4}>
              <PlusFreeTrialStatus />
            </Box>
          )}
        </Box>
        {hasActiveDocAutomationPlan
          ? renderActivePlanContent()
          : renderCanceledPlanContent()}
      </>
    )
  }

  const renderCanceledPlanContent = () => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          {billingData?.canceledDocAutomationSubscription.endedAt ? null : (
            <>
              <Text fontSize={'16px'}>Sessions used</Text>
              <Text fontSize={'16px'} fontWeight={'bold'}>
                {billingData?.canceledDocAutomationSubscription.sessionsUsed +
                  '/' +
                  billingData?.canceledDocAutomationSubscription
                    .sessionsAllotted}
              </Text>
            </>
          )}
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '220px',
            justifyContent: 'center'
          }}
        >
          {billingData?.canceledDocAutomationSubscription?.endedAt ? (
            <Button
              onClick={() =>
                openManageScribePlanModal({ clinicId: selectedClinic.id })
              }
            >
              Choose Plan
            </Button>
          ) : (
            <Button
              isLoading={isLoading}
              onClick={() => {
                clinicianTracking.trackEvent(
                  `Billing page doc automation plan -> Clicked reactivate canceled plan`
                )
                return reactivatePlan({
                  data: {
                    action: 'reactivate',
                    subscriptionId:
                      billingData?.canceledDocAutomationSubscription
                        ?.subscriptionId,
                    planType: 'da'
                  }
                })
              }}
            >
              Reactivate
            </Button>
          )}
        </Box>
      </>
    )
  }

  const renderActivePlanContent = () => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          <Text fontSize={'16px'}>Next invoice</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {billingData.nextDueDocAutomationInvoice &&
              moment
                .unix(
                  billingData.nextDueDocAutomationInvoice
                    .next_payment_attempt !== null
                    ? billingData.nextDueDocAutomationInvoice
                        .next_payment_attempt
                    : billingData.nextDueDocAutomationInvoice.period_end
                )
                .format('MMM D, YYYY')}
          </Text>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          <Text fontSize={'16px'}>Invoice total</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {billingData?.nextDueDocAutomationInvoice &&
              formatDollarAmount(
                billingData?.nextDueDocAutomationInvoice.amount_due / 100
              )}
          </Text>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          <Text fontSize={'16px'}>Sessions used</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {billingData?.activeDocAutomationPlan.sessionsUsed +
              '/' +
              billingData?.activeDocAutomationPlan.sessionsAllotted}
          </Text>
        </Box>
      </>
    )
  }

  const renderManageAndCancel = (plan: any) => (
    <>
      {hasCanceledDocAutomationPlan && !hasActiveDocAutomationPlan ? (
        plan.endedAt ? (
          <Text>
            Expired on {moment.unix(plan.endedAt).format('MMMM D, YYYY')}
          </Text>
        ) : (
          <Text color="light_gray">
            Expires on {moment.unix(plan.cancelAt).format('MMMM D, YYYY')}
          </Text>
        )
      ) : billingData?.canCancel ? (
        <HStack spacing="small">
          <Text
            _focus={{ outline: 'none' }}
            fontSize={'16px'}
            color={'primary'}
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => {
              if (isEvidenceBasedCareEnabled) {
                openManageScribePlanModal({ clinicId: selectedClinic.id })
              } else {
                history.push('/settings/plans')
              }
            }}
          >
            Manage Plan
          </Text>
        </HStack>
      ) : (
        <Text
          _focus={{ outline: 'none' }}
          fontSize={'16px'}
          color={'primary'}
          _hover={{
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
          onClick={() => {
            if (isDocumentationAutomationFreePlan) {
              openManageScribePlanModal({ clinicId: selectedClinic.id })
            } else {
              //@ts-ignore
              window.Intercom(
                'showNewMessage',
                "Hi, I'd like to change my Documentation Automation plan."
              )
            }
          }}
        >
          {isDocumentationAutomationFreePlan ? 'Upgrade' : 'Contact support'}
        </Text>
      )}
    </>
  )

  const renderHasNoDAPlansContent = () => (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 0'
        }}
      >
        <Text fontSize={'16px'} fontWeight={'bold'}>
          Documentation Automation
        </Text>
        <Text fontSize={'16px'} fontWeight={430}>
          Use an audio recorder during session to automate progress notes.
        </Text>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '220px'
        }}
      >
        <Button onClick={openDAModal}>Try for free</Button>
      </Box>
    </>
  )

  return (
    <>
      {hasDowngradeData && (
        <HStack w="100%" p="xsmall" borderRadius="8px" mb="xsmall" bg="#EB5164">
          <InfoIcon fill="white" />
          <Text as="span" color="white" fontSize="16px">
            This plan is scheduled to be downgraded to
          </Text>
          <Text as="span" color="white" fontSize="16px" fontWeight="bold">
            {' '}
            {
              billingData?.activeDocAutomationPlan?.downgradeData?.productName
            }{' '}
          </Text>
          <Text as="span" color="white" fontSize="16px">
            on{' '}
            {moment
              .unix(schedulePhases[schedulePhases.length - 1]?.start_date)
              .format('MMMM D, YYYY')}
          </Text>
        </HStack>
      )}
      <SimpleGrid
        mb={'small'}
        columns={[1, 1, hasAnyDocAutomationPlan ? 5 : 3]}
        p={'24px'}
        borderRadius={'8px'}
        border={'1px solid #E4E5E6'}
        display={{ base: '', md: 'flex' }}
        justifyContent={'center'}
        gap={'24px'}
        alignSelf={'stretch'}
      >
        {hasAnyDocAutomationPlan
          ? renderHasAnyDAPlanContent()
          : renderHasNoDAPlansContent()}
      </SimpleGrid>
    </>
  )
}
