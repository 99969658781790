import { action, actionOn } from 'easy-peasy'

export interface InsightsModel {
  clientSatisfaction: ClientSatisfactionModule
  insights: InsightsModule
}

interface InsightsModule extends InsightsState {
  onUserLoaded: any
  setSelectedAssessment: any
  updateFiltersV2: any
  updateFilters: any
  setFiltersLoaded: any
}

interface ClientSatisfactionModule extends ClientSatisfactionState {
  updateFilters: any
  onUserLoaded: any
}

interface InsightsState {
  loaded?: boolean
  selectedAssessment?: any
  filtersV2?: InsightFilter
  filters?: LegacyInsightFilter[]
}

interface ClientSatisfactionState {
  filters?: ClientSatisfactionFilter
}

interface ClientSatisfactionFilter {
  organizationId?: string | null
  clinicId?: string | null
  clinicianId?: string | null
  ages?: number[] | null
  fromTreatmentStartDate?: string | null
  toTreatmentStartDate?: string | null
}

export interface InsightFilter {
  allClinicIds: string[]
  assessmentId: string | null
  baselineScoreMin: number | null
  baselineScoreMax: number | null
  organizationId: string | null
  clinicIds: string[] | null
  clinicianId: string | null
  ages: (number | null)[] | null
  fromTreatmentStartDate: Date | null
  toTreatmentStartDate: Date | null
  startDate: string | null
  endDate: string | null
  programIds: string[]
}

interface LegacyInsightFilter {
  id: string
  selected: any
}

export const insightsModel: InsightsModel = {
  clientSatisfaction: {
    filters: {
      organizationId: null,
      clinicId: null,
      clinicianId: null,
      ages: [],
      fromTreatmentStartDate: null,
      toTreatmentStartDate: null
    },
    updateFilters: action(
      (state: ClientSatisfactionState, values: ClientSatisfactionFilter) => {
        state.filters = {
          ...state.filters,
          ...values
        }
      }
    ),
    onUserLoaded: actionOn(
      (actions, storeActions: any) => [storeActions.auth.updateUser],
      (state: ClientSatisfactionState, target) => {
        if (state.filters) {
          state.filters.organizationId = target.payload.clinic.organization_id
          state.filters.clinicId = target.payload.clinic_id
        }
      }
    )
  },
  insights: {
    loaded: false,
    selectedAssessment: null,
    filtersV2: {
      allClinicIds: [],
      fromTreatmentStartDate: null,
      toTreatmentStartDate: null,
      assessmentId: null,
      organizationId: null,
      clinicIds: [],
      clinicianId: null,
      ages: [],
      startDate: null,
      endDate: null,
      baselineScoreMin: null,
      baselineScoreMax: null,
      programIds: []
    },
    filters: [
      {
        id: 'clinics',
        selected: []
      },
      {
        id: 'outcomes',
        selected: {
          label: '---',
          value: null
        }
      },
      {
        id: 'clinicians',
        selected: []
      },
      {
        id: 'ages',
        selected: []
      }
    ],
    onUserLoaded: actionOn(
      (actions, storeActions: any) => [storeActions.auth.updateUser],
      (state: InsightsState, target) => {
        if (state.filtersV2) {
          state.filtersV2.organizationId = target.payload.clinic.organization_id
          state.filtersV2.clinicIds = [target.payload.clinic_id]
        }
      }
    ),
    setSelectedAssessment: action((state: InsightsState, assessment) => {
      state.selectedAssessment = assessment
    }),
    updateFiltersV2: action((state: InsightsState, values: InsightFilter) => {
      state.filtersV2 = {
        ...state.filtersV2,
        ...values
      }
    }),
    updateFilters: action((state: InsightsState, filtersToUpdate: any) => {
      state.filters = (state.filters || []).map(
        (filter: LegacyInsightFilter) => {
          const match = filtersToUpdate.find((ftu: any) => ftu.id === filter.id)
          if (match) {
            return match
          }
          return filter
        }
      )
    }),
    setFiltersLoaded: action((state: InsightsState, isLoaded: boolean) => {
      state.loaded = isLoaded
    })
  }
}
