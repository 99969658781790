import React, { useMemo, useState, useCallback, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Input,
  Text
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { MeasureRow } from './components'
import { MeasureTypes } from '@constants/measureTypes'

function AssignMeasuresModal({
  isOpen,
  handleCloseModal,
  measureType,
  allMeasures,
  onAssign,
  assigneeUser
}) {
  const [selectedMeasures, setSelectedMeasures] = useState([])
  const [searchText, setSearchText] = useState('')

  const modifiedData = useMemo(() => {
    return allMeasures
      .map(m => ({
        ...m,
        isSelected: selectedMeasures.includes(m.measureId)
      }))
      .filter(m =>
        `${m.title.toLowerCase()} ${m.subtitle.toLowerCase()}`.includes(
          searchText.toLowerCase()
        )
      )
  }, [measureType, selectedMeasures, searchText, allMeasures])

  const measureTypeText = useMemo(() => {
    switch (measureType) {
      case MeasureTypes.SYMPTOM:
        return 'Symptom Trackers'
      case MeasureTypes.ASSESSMENT:
        return `Assessments to ${assigneeUser.info.firstName}`
      case MeasureTypes.WORKSHEET:
        return 'Worksheets'
      default:
        return 'Measures'
    }
  }, [measureType])

  const buttonText = useMemo(() => {
    const numSelected = selectedMeasures.length
    const first = `Assign ${numSelected ? numSelected : ''}`
    const second = `${
      numSelected === 1
        ? measureType !== MeasureTypes.ASSESSMENT
          ? measureTypeText.slice(0, measureTypeText.length - 1)
          : 'Assessment to ' + assigneeUser.info.firstName
        : measureTypeText
    }`
    return `${first} ${second}`
  }, [selectedMeasures, measureTypeText])

  const onSelect = useCallback(
    id => {
      const updatedSelectedMeasures = selectedMeasures.includes(id)
        ? selectedMeasures.filter(m => m !== id) // deselect
        : [...selectedMeasures, id] // select
      setSelectedMeasures(updatedSelectedMeasures)
    },
    [selectedMeasures]
  )

  useEffect(() => {
    setSearchText('')
    setSelectedMeasures([])
  }, [measureType])

  const _handleCloseModal = useCallback(() => {
    if (handleCloseModal) {
      handleCloseModal()
    }
    setSearchText('')
    setSelectedMeasures([])
  }, [handleCloseModal])

  return (
    <>
      <Modal
        size="2xl"
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={_handleCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="24px" borderBottom="1px solid #E4E5E6">
            Assign {measureTypeText}
          </ModalHeader>
          <ModalCloseButton mr="xsmall" mt="10px" borderRadius="25px" />
          <ModalBody pt="0">
            <Flex h="100px" align="center">
              <Input
                placeholder={`Search ${
                  measureType === MeasureTypes.SYMPTOM
                    ? 'symptom tracker'
                    : measureType
                }s`}
                onChange={e => setSearchText(e.currentTarget.value)}
                value={searchText}
              />
            </Flex>
            {modifiedData.length ? (
              <Flex direction="column" w="100%">
                {modifiedData.map(m => (
                  <MeasureRow key={m.measureId} onSelect={onSelect} {...m} />
                ))}
              </Flex>
            ) : (
              <Text fontWeight="bold">No results found</Text>
            )}
          </ModalBody>

          <ModalFooter
            h="large"
            py="small"
            pr="small"
            borderTop="1px solid #E4E5E6"
          >
            <Button
              size="lg"
              mr="small"
              variant="outline"
              onClick={_handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              disabled={!selectedMeasures.length}
              size="lg"
              onClick={() => {
                if (onAssign) {
                  onAssign(
                    selectedMeasures.map(sm => ({ id: sm })),
                    measureType === MeasureTypes.ASSESSMENT
                      ? assigneeUser.id
                      : measureType
                  )
                  setSearchText('')
                  setSelectedMeasures([])
                }
                _handleCloseModal()
              }}
            >
              {buttonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export const AssignMeasuresModalConnected = () => {
  const {
    isOpen,
    clientId,
    measureType,
    allMeasures,
    onAssign,
    assigneeUser
  } = useStoreState(state => state.modals.assignMeasures)
  const handleCloseModal = useStoreActions(
    actions => actions.modals.assignMeasures.closeModal
  )

  return measureType ? (
    <AssignMeasuresModal
      isOpen={isOpen}
      clientId={clientId}
      handleCloseModal={handleCloseModal}
      measureType={measureType}
      allMeasures={allMeasures}
      onAssign={onAssign}
      assigneeUser={assigneeUser}
    />
  ) : null
}
