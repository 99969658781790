import React, { Component } from 'react'
import {
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Label
} from 'recharts'
import { JoyTable, Pill } from '../../../../../../components'
import EmptyGraph from '../empty-graph'
import { find, union } from 'lodash'
import moment from 'moment'
import {
  SYMPTOMS,
  SYMPTOM_ANSWERS,
  HEX_CODES
} from '../../../../../../constants'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import styles from './symptoms-block.module.css'
import { withRouter } from 'react-router-dom'

class SymptomsBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_check_in_id: props.selected_check_in_id,
      selected_symptoms: [],
      open: false,
      mounted: null
    }
  }

  componentDidMount = () => {
    this.setState({ mounted: true })
    this.getPatientActiveCheckins()
  }

  componentDidUpdate = prevProps => {
    if (this.props.selected_check_in_id !== prevProps.selected_check_in_id) {
      this.setState({
        selected_check_in_id: this.props.selected_check_in_id
      })
    }
  }

  renderDateOptions = type => {
    if (type === 'checkins') {
      return this.props.checkins.map(checkin => {
        return (
          <option key={checkin.id} value={checkin.id}>
            {formatDateTime(checkin.created_at)}
          </option>
        )
      })
    }
    return null
  }

  handleChange = e => {
    this.setState({
      selected_check_in_id: e.target.value
    })
  }

  handleChangeMulti = event => {
    this.setState({ selected_symptoms: event.target.value })
  }

  //Gets all symptom keys based on completed check-ins
  getSymptomList = () => {
    const data = this.props.data

    let symptomsList = []
    for (var i = 0; i < data.length; i++) {
      const keys = data[i].scores.map(score => score.display)
      symptomsList = union(symptomsList, keys)
    }
    return symptomsList
  }

  getSymptomsByCheckinId = id => {
    const checkin = find(this.props.checkins, { id: id })
    return checkin && checkin.symptoms ? checkin.symptoms : null
  }

  generateTopSymptomsTable = () => {
    let symptoms = this.getSymptomsByCheckinId(this.state.selected_check_in_id)
    if (symptoms) {
      symptoms = symptoms.map(symptom => {
        return {
          key: formatSymptoms(symptom.key),
          value: formatSymptomAnswer(symptom.value)
        }
      })
    }
    return (
      <div className={styles.symptoms_table}>
        <Select
          variant="outlined"
          native
          value={this.state.selected_check_in_id}
          onChange={e => this.handleChange(e, 'checkins')}
          input={
            <OutlinedInput className={styles.outlined_select} labelWidth={0} />
          }
        >
          {this.renderDateOptions('checkins')}
        </Select>
        <JoyTable mini headers={[]} data={symptoms} />
      </div>
    )
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleRemovePill = (e, value) => {
    e.stopPropagation()
    this.setState({
      selected_symptoms: this.state.selected_symptoms.filter(s => s !== value)
    })
  }

  renderPillSelectors = () => {
    const pills = this.getSymptomList()
    return (
      <Select
        open={this.state.open}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        className={styles.symptomSelector}
        multiple
        value={this.state.selected_symptoms}
        onChange={this.handleChangeMulti}
        input={<OutlinedInput labelWidth={0} className={styles.test} />}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
        displayEmpty
        renderValue={selected => {
          if (selected.length === 0) {
            return (
              <span
                style={{
                  color: '#757575',
                  fontSize: '13px'
                }}
              >
                Select a symptom from the dropdown menu
              </span>
            )
          }
          return (
            <div className={styles.pills}>
              {selected.map((value, index) => {
                return (
                  <Pill
                    onClick={e => this.handleRemovePill(e, value)}
                    color={HEX_CODES[index % HEX_CODES.length]}
                    key={value}
                    label={formatSymptoms(value)}
                  />
                )
              })}
            </div>
          )
        }}
      >
        {pills.map((symptom, index) => {
          return (
            <MenuItem key={index} value={symptom}>
              <Checkbox
                style={{ color: '#757575' }}
                checked={this.state.selected_symptoms.indexOf(symptom) > -1}
              />
              <ListItemText primary={formatSymptoms(symptom)} />
            </MenuItem>
          )
        })}
      </Select>
    )
  }

  renderLines = () => {
    const lines = this.state.selected_symptoms.map((symptom, index) => {
      const color = HEX_CODES[index % HEX_CODES.length]
      return (
        <Line
          key={index}
          type="monotone"
          dataKey={symptom}
          strokeWidth="2"
          isAnimationActive={false}
          stroke={color}
          connectNulls
          dot={{
            strokeDasharray: '0',
            stroke: color,
            strokeWidth: 6,
            r: 0.5
          }}
        />
      )
    })
    return lines
  }

  getPatientActiveCheckins = () => {
    const { api, user_id } = this.props
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      `/clinicians/clients/${user_id}/check_in_modules`
    api.GET(path).then(json => {
      if (this.state.mounted) {
        // subtract one bc lifestyle correlates are always active and dont count for the symptoms chart
        this.setState({
          hasActiveCheckins:
            json.filter(mod => mod.type === 'symptom' && mod.isActive === true)
              .length -
              1 >
            0
        })
      }
    })
  }

  generateChart = type => {
    let ticks = [0, 1]
    if (type === 'top_symptoms') {
      let data = this.props.symptom_distribution // need to change this from symptom_distribution.all
      if (!data || !data.length) {
        const rootPath = this.props.location.pathname
        return (
          <EmptyGraph
            variant={this.props.variant}
            type="symptoms"
            hasActiveCheckins={this.state.hasActiveCheckins}
            rootPath={rootPath}
          />
        )
      }
      ticks = [0, 100]
      return (
        <ResponsiveContainer
          width="100%"
          height={this.props.variant === 'pdf' ? 250 : 210}
        >
          <BarChart layout={'vertical'} barCategoryGap="5%" data={data}>
            <Bar
              radius={[0, 5, 5, 0]}
              layout={'vertical'}
              isAnimationActive={this.props.variant === 'pdf' ? false : true}
              type="monotone"
              dataKey={'value'}
              fill={'#2d54e8'}
              maxBarSize={30}
            />
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis
              style={
                this.props.variant === 'pdf'
                  ? {
                      fontFamily: 'Arial, Helvetica, sans-serif',
                      fontSize: 14
                    }
                  : {}
              }
              type={'number'}
              tickFormatter={formatYAxisTopSymptoms}
              ticks={ticks}
            >
              <Label
                style={
                  this.props.variant === 'pdf'
                    ? {
                        fontFamily: 'Arial, Helvetica, sans-serif',
                        fontSize: 14,
                        fill: '#666565'
                      }
                    : {}
                }
                value="Weighted frequency"
                offset={0}
                position="insideBottom"
              />
            </XAxis>
            <YAxis
              style={
                this.props.variant === 'pdf'
                  ? {
                      fontFamily: 'Arial, Helvetica, sans-serif',
                      fontSize: 12
                    }
                  : { fontSize: 12 }
              }
              width={90}
              type={'category'}
              interval={0}
              dataKey="display"
              tickFormatter={
                this.props.variant === 'pdf' ? null : formatSymptomString
              }
            />
            <Tooltip
              cursor={false}
              content={<SymptomBarTooltip data={data} />}
            />
          </BarChart>
        </ResponsiveContainer>
      )
    } else if (type === 'totalSymptomSeverity') {
      let data = this.props.totalSymptomSeverity
      if (!data || !data.length) {
        return <EmptyGraph variant={this.props.variant} />
      }
      return (
        <ResponsiveContainer
          width="100%"
          height={this.props.variant === 'pdf' ? 230 : 180}
        >
          <LineChart data={data}>
            <XAxis
              style={
                this.props.variant === 'pdf'
                  ? {
                      fontFamily: 'Arial, Helvetica, sans-serif',
                      fontSize: 14
                    }
                  : {}
              }
              tickFormatter={
                this.props.variant === 'pdf'
                  ? formatDateTimePdf
                  : formatDateTime
              }
              padding={{ left: 10, right: 10 }}
              dataKey="date"
            />
            <YAxis
              width={65}
              style={
                this.props.variant === 'pdf'
                  ? {
                      fontFamily: 'Arial, Helvetica, sans-serif',
                      fontSize: 14
                    }
                  : {}
              }
              tickFormatter={formatSeverityScore}
              ticks={[0, 5, 10, 15, 20]}
              padding={{ top: 20 }}
            />
            <CartesianGrid vertical={false} />
            <Line
              type="monotone"
              dataKey={'value'}
              strokeWidth="2"
              isAnimationActive={this.props.variant === 'pdf' ? false : true}
              animationDuration={600}
              stroke={'#2d54e8'}
              dot={{
                strokeDasharray: '0',
                stroke: '#2d54e8',
                strokeWidth: 6,
                r: 0.5
              }}
            />
            {this.props.variant === 'pdf' ? null : (
              <Tooltip content={<SeverityCustomTooltip data={data} />} />
            )}
          </LineChart>
        </ResponsiveContainer>
      )
    } else if (type === 'symptomsOverTime') {
      let data = this.props.symptomsOverTime
      if (!data || !data.length) {
        return <EmptyGraph />
      }
      data.forEach(dataPoint => {
        dataPoint['created_at_unix'] = moment(dataPoint.created_at).format('X')
      })
      return (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart margin={{ left: 15, right: 20 }} data={data}>
            <XAxis
              tickFormatter={formatDateTimeFromUnix}
              padding={{ left: 50, right: 50 }}
              dataKey="created_at_unix"
              type="number"
              domain={['dataMin', () => moment().unix()]}
            />
            <YAxis
              tickFormatter={formatSymptomAnswer}
              ticks={[0, 1, 2, 3, 4]}
              padding={{ top: 20 }}
            />
            <CartesianGrid vertical={false} />
            {this.renderLines()}
            <Tooltip content={<SymptomCustomTooltip data={data} />} />
          </LineChart>
        </ResponsiveContainer>
      )
    }
    return null
  }

  render() {
    const { pdf_subheader } = this.props

    return (
      <div>
        {this.props.chartType === 'top_symptoms' ? (
          <div>{this.generateChart(this.props.chartType)}</div>
        ) : this.props.chartType === 'symptomsOverTime' ? (
          <div className={styles.row}>
            {this.renderPillSelectors()}
            {this.generateChart(this.props.chartType)}
          </div>
        ) : (
          <div>
            {pdf_subheader && (
              <p className={styles.pdf_subheader}>{pdf_subheader}</p>
            )}
            {this.generateChart(this.props.chartType)}
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(SymptomsBlock)

function formatSymptoms(symptom) {
  const standardized = find(SYMPTOMS, { key: symptom })
  if (standardized && standardized.value) {
    return standardized.value
  } else {
    return symptom
  }
}

function formatSymptomAnswer(answer) {
  if (typeof answer === 'string') {
    const standardized = find(SYMPTOM_ANSWERS, { count: parseInt(answer) })
    if (standardized && standardized.value) {
      return standardized.value
    } else {
      return answer
    }
  } else if (typeof answer === 'number') {
    const standardized = find(SYMPTOM_ANSWERS, { count: answer })
    if (standardized && standardized.value) {
      return standardized.value
    } else {
      return answer
    }
  } else {
    return answer
  }
}

function formatYAxisTopSymptoms(value) {
  return value + '%'
}

function formatDateTime(date) {
  return moment(date).format('ddd M/D h:mma')
}

function formatDateTimePdf(date) {
  return moment(date).format('M/DD')
}

function formatDateTimeFromUnix(unix) {
  return moment.unix(unix).format('MM-DD-YYYY') ===
    moment().format('MM-DD-YYYY')
    ? 'Today'
    : moment.unix(unix).format('ddd M/D h:mma')
}

function formatSeverityScore(val) {
  switch (val) {
    case 0:
      return 'None'
    case 10:
      return 'Moderate'
    case 20:
      return 'Severe'
    default:
      return ''
  }
}

function convertSymptomSeverityScoreToLabel(score) {
  if (score < 5) {
    return 'None'
  } else if (score < 10) {
    return 'Mild'
  } else if (score < 15) {
    return 'Moderate'
  } else if (score < 19) {
    return 'Moderately Severe'
  } else {
    return 'Severe'
  }
}

const SymptomCustomTooltip = props => {
  const { active, payload, label } = props
  if (active && payload && label) {
    return (
      <Paper className="tooltip" elevation={1}>
        <h5 className="date">{formatDateTimeFromUnix(label)}</h5>
        {payload.map((val, index) => {
          const key = val.name
          const value = val.payload[key]
          return (
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center'
              }}
              key={index}
            >
              <span
                style={{ backgroundColor: val.stroke }}
                className="dot"
              ></span>
              <span style={{ fontSize: '16px' }}>{`${formatSymptoms(
                key
              )}: ${formatSymptomAnswer(value)}`}</span>
            </div>
          )
        })}
      </Paper>
    )
  } else {
    return null
  }
}

const SymptomBarTooltip = props => {
  const { active, payload, label, data } = props
  if (active && payload && label) {
    return (
      <Paper style={{ width: '200px' }} className="tooltip" elevation={1}>
        <div
          style={{
            height: '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}
        >
          <p
            style={{
              fontSize: '14px',
              marginBottom: '10px',
              fontWeight: 'bold'
            }}
          >
            {label}
          </p>
          <p style={{ fontSize: '14px' }}>{`${Math.round(
            data.find(datum => datum.display === label).value
          )}%`}</p>
        </div>
      </Paper>
    )
  } else {
    return null
  }
}

const SeverityCustomTooltip = props => {
  const { active, payload, label } = props
  if (active && payload && label) {
    return (
      <Paper className="tooltip" elevation={1}>
        <h5 className="date">{formatDateTime(label)}</h5>
        {payload.map((val, index) => {
          const raw_value = Math.round(val.payload['value'])
          const display_name = 'Severity'
          return (
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center'
              }}
              key={index}
            >
              {/* <span style={{ backgroundColor: val.stroke }} className="dot"></span> */}
              <span
                style={{ fontSize: '16px' }}
              >{`${display_name}: ${raw_value} (${convertSymptomSeverityScoreToLabel(
                raw_value
              )})`}</span>
            </div>
          )
        })}
      </Paper>
    )
  } else {
    return null
  }
}

// formatting for symptom names on y-axis
function formatSymptomString(string) {
  const arrayOfWords = string.split(' ')
  const shortWordAdjustedArray = arrayOfWords.map((word, idx, array) => {
    if (word === 'in' || word === 'of' || word === 'to' || word === 'or') {
      word = ''
    }
    if (
      array[idx + 1] === 'in' ||
      array[idx + 1] === 'of' ||
      array[idx + 1] === 'to' ||
      array[idx + 1] === 'or'
    ) {
      word = word + ' ' + array[idx + 1]
    }
    return word
  })

  const cleanedArray = shortWordAdjustedArray.filter(word => word.length)
  let finalArray
  if (cleanedArray.length > 4) {
    const truncatedArray = cleanedArray.filter((el, idx) => idx < 3)
    truncatedArray.push('...')
    finalArray = truncatedArray
  } else {
    finalArray = cleanedArray
  }
  return finalArray.join('\n')
}
