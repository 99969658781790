import React from 'react'

let MenuButtonsWrapper = () => null
if (process.env.REACT_APP_THEME === 'tempus') {
  import('./tempus/wrapper').then(
    ({ default: menuButtonsWrapper }) =>
      (MenuButtonsWrapper = menuButtonsWrapper)
  )
} else {
  import('./blueprint/wrapper').then(
    ({ default: menuButtonsWrapper }) =>
      (MenuButtonsWrapper = menuButtonsWrapper)
  )
}

export default function MenuButtons(baseProps) {
  return <MenuButtonsWrapper {...baseProps} />
}
