import React from 'react'
import { Box, Text, Image, VStack, HStack } from '@chakra-ui/react'

import BlueprintLogo from '../../../../images/brand/white_logo.svg'

const LogoPreview = ({ heading, logoUrl, height, isEmailPreview }) => {
  return (
    <>
      <Text mt="small" mb="small">
        {heading}
      </Text>
      <Box
        rounded="lg"
        w={'100%'}
        h={height}
        bg="primary"
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        <VStack spacing="3px">
          <Image maxH="32px" src={logoUrl || BlueprintLogo} alt="" />
          {isEmailPreview && logoUrl && (
            <HStack>
              <Text color="white" fontSize="10px">
                Powered by
              </Text>
              <Image
                maxH="11px"
                ms="xxsmall"
                style={{ marginLeft: '4px' }}
                src={BlueprintLogo}
                alt=""
              />
            </HStack>
          )}
        </VStack>
      </Box>
    </>
  )
}

export default LogoPreview
