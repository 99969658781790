import React, { useMemo } from 'react'
import { Stack, Text, Box } from '@chakra-ui/react'
import moment from 'moment'
import { useTreatmentPlan } from '@hooks'

const ClientDetails = () => {
  const { client, treatmentPlan } = useTreatmentPlan()
  const { first_name, last_name, date_of_birth, age_range } = client
  const { updatedAt } = treatmentPlan

  const dobText = useMemo(() => {

    if (date_of_birth) {
      return moment(date_of_birth).format('M/D/YYYY')
    } else if (age_range) {
      return `(not specified) - ${age_range}`
    } else  {
      return '(not specified)'
    }
  }, [age_range, date_of_birth])

  return (
    <Stack spacing="0">
      <Text>
        <b>Name: </b>
        {`${first_name} ${last_name}`}
      </Text>
      <Text>
        <b>DOB: </b>
        {dobText}
      </Text>
      <Text>
        <b>Last Updated: </b>
        {updatedAt ? moment(updatedAt).format('M/D/YYYY') : 'N/A'}
      </Text>
    </Stack>
  )
}

export default ClientDetails
