import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useInfiniteQuery } from 'react-query'
import { Box, VStack, Divider, Text, Flex } from '@chakra-ui/react'
import { TextField, SearchIcon, Checkbox } from '@blueprinthq/joy'
import _ from 'lodash'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import { endpoints } from '../../../../../api'

export const InsurancePayersPage = ({ organizationId, setData, data }) => {
  const [search, setSearch] = useState('')

  const {
    data: insurancePayers,
    fetchNextPage: refetch,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(
    [endpoints.getInsurancePayers.getCacheId(), search, organizationId],
    ({ pageParam = 1 }) => {
      return endpoints.getInsurancePayers.request({
        organizationId,
        search,
        page: pageParam
      })
    },
    {
      initialData: {
        pages: []
      },
      keepPreviousData: true,
      getNextPageParam: (lastPage, pages) => {
        return lastPage && pages.length === lastPage.meta.totalPageCount
          ? undefined
          : pages.length + 1
      }
    }
  )

  const throttledSearch = _.throttle(() => refetch(), 150)

  useEffect(() => {
    if (search.length >= 1) {
      throttledSearch()
    }
  }, [search])

  const onAddInsurancePayer = useCallback(
    insurancePayer => {
      setData({
        ...data,
        insurancePayers: [...data.insurancePayers, insurancePayer]
      })
    },
    [data]
  )

  const onRemoveInsurancePayer = useCallback(
    insurancePayer => {
      setData({
        ...data,
        insurancePayers: data.insurancePayers.filter(
          ip => ip.id !== insurancePayer.id
        )
      })
    },
    [data]
  )

  const loadMoreItems = isFetching
    ? () => {}
    : () => {
        refetch()
      }

  const totalNodeCount = useMemo(
    () =>
      insurancePayers.pages.reduce((count, page) => {
        return page.nodes.length + count
      }, 0),
    [insurancePayers.pages]
  )

  const itemCount = hasNextPage ? totalNodeCount + 1 : totalNodeCount

  const isItemLoaded = index => !hasNextPage || index < totalNodeCount
  const selectedPayerIds = useMemo(() => data.insurancePayers.map(p => p.id), [
    data.insurancePayers
  ])

  const Item = ({ index, style }) => {
    if (!isItemLoaded(index)) {
      return <Box style={style}>Loading...</Box>
    }

    const pageSize = insurancePayers.pages[0].meta.pageSize
    const currentPage = insurancePayers.pages[Math.floor(index / pageSize)]
    const insurancePayer = currentPage.nodes[index % pageSize]
    const isSelected = selectedPayerIds.includes(insurancePayer.id)

    return (
      <Flex
        style={style}
        align="center"
        borderBottom="1px solid"
        borderColor="pale_gray"
      >
        <Checkbox
          isChecked={isSelected}
          onChange={() =>
            isSelected
              ? onRemoveInsurancePayer(insurancePayer)
              : onAddInsurancePayer(insurancePayer)
          }
        >
          {insurancePayer.name}
        </Checkbox>
      </Flex>
    )
  }

  return (
    <Box>
      <TextField
        showClear
        onClear={() => setSearch('')}
        placeholder={'Search by payer'}
        leftIcon={<SearchIcon />}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      <Box mt="medium">
        {search.length === 0 && (
          <Box>
            <Text fontWeight="bold">
              {data.insurancePayers.length} selected
            </Text>
            <Divider pt="xxsmall" mb="xsmall" />
            <Box maxHeight="300px" overflowY="auto">
              <VStack mt="xsmall" align="flex-start" divider={<Divider />}>
                {data.insurancePayers.map(insurancePayer => (
                  <Box key={insurancePayer.id}>
                    <Checkbox
                      isChecked
                      onChange={() => onRemoveInsurancePayer(insurancePayer)}
                    >
                      {insurancePayer.name}
                    </Checkbox>
                  </Box>
                ))}
              </VStack>
            </Box>
          </Box>
        )}
        <Box mt="medium">
          {search.length ? (
            <Text>
              {totalNodeCount} results for{' '}
              <Text as="span" fontWeight="bold">
                {search}
              </Text>
            </Text>
          ) : (
            <Text fontWeight="bold">All payers A to Z</Text>
          )}
          <Divider pt="xxsmall" mb="xsmall" />
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={ref}
                height={400}
                itemSize={48}
              >
                {Item}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        </Box>
      </Box>
    </Box>
  )
}
