import React, { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'
import {
  Box,
  Flex,
  Heading,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td
} from '@chakra-ui/react'
import { ArrowDownIcon, ArrowUpIcon } from '@blueprinthq/joy'
import { SURVEY_RESPONSES } from './constants'
import { Section } from '@handlers/reports/components'

const ClinicianBreakdownTable = ({ data, isLoading, emptyState }) => {
  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Clinician',
        accessor: 'clinicianName'
      },
      {
        id: 'totalClients',
        Header: 'Total Sent',
        accessor: 'totalClients'
      },
      {
        id: 'totalAssessments',
        Header: 'Total Completed',
        accessor: 'totalAssessments'
      },
      {
        id: 'verySatisfied',
        Header: 'Very Satisfied',
        accessor: row => {
          const res = row.responseBreakdown.find(
            response => response.label === SURVEY_RESPONSES.VERY_SATISFIED
          )
          return res ? res.count : 0
        }
      },
      {
        id: 'satisfied',
        Header: 'Satisfied',
        accessor: row => {
          const res = row.responseBreakdown.find(
            response => response.label === SURVEY_RESPONSES.SATISFIED
          )
          return res ? res.count : 0
        }
      },
      {
        id: 'neutral',
        Header: 'Neutral',
        accessor: row => {
          const res = row.responseBreakdown.find(
            response => response.label === SURVEY_RESPONSES.NEUTRAL
          )
          return res ? res.count : 0
        }
      },
      {
        id: 'unsatisfied',
        Header: 'Unsatisfied',
        accessor: row => {
          const res = row.responseBreakdown.find(
            response => response.label === SURVEY_RESPONSES.UNSATISFIED
          )
          return res ? res.count : 0
        }
      },
      {
        id: 'veryUnsatisfied',
        Header: 'Very Unsatisfied',
        accessor: row => {
          const res = row.responseBreakdown.find(
            response => response.label === SURVEY_RESPONSES.VERY_UNSATISFIED
          )
          return res ? res.count : 0
        }
      },
      {
        id: 'avgScore',
        Header: 'Avg Score',
        accessor: 'avgScore'
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: {
        sortBy: [{ id: 'avgScore', desc: true }]
      }
    },
    useSortBy
  )
  return (
    <Section isLoading={isLoading}>
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Heading fontSize="lg" my="2">
              Avg Score by Clinician
            </Heading>
          </Box>
        </Flex>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup, i) => (
              <Tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, j) => (
                  <Th
                    key={j}
                    borderColor="light_gray"
                    verticalAlign="middle"
                    userSelect="none"
                    sx={column.style}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <Flex alignItems="center">
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownIcon size="sm" fill={'gray.600'} />
                          ) : (
                            <ArrowUpIcon size="sm" fill={'gray.600'} />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <Tr
                  key={i}
                  {...row.getRowProps()}
                  cursor="pointer"
                  _hover={{
                    bg: 'pale_gray'
                  }}
                >
                  {row.cells.map((cell, j) => (
                    <Td
                      key={j}
                      {...cell.getCellProps()}
                      borderColor="light_gray"
                      verticalAlign="middle"
                    >
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
        {!isLoading && !rows.length && emptyState}
      </Box>
    </Section>
  )
}

export default ClinicianBreakdownTable
