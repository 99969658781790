import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Container,
  Flex,
  Text,
  Button,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
  useBreakpointValue
} from '@chakra-ui/react'
import NoteBox from './note-box'
import { ProgressNoteExplanation } from '.'
import { useExperienceManager } from '@hooks'
import { NoteSettings } from '@components/icons'
import { isChrome, isDesktop, isMobile } from 'react-device-detect'
import LowWordCountBanner from './low-word-count-banner'

interface ProgressNoteProps {
  progressNote: {
    id: string
    note: any
    nextNote: any
    noteType: string
    template: any
  }
  handleTrackEvent: (event: string, properties?: any) => void
  isNext?: boolean
  staticHeight?: boolean
  emptyNote: boolean
  transcriptionError: boolean
  paywallBannerHeight: number
  uploadAlert: () => React.ReactNode
  showNotePreferencesText: boolean | undefined
  buildHeader: () => React.ReactNode
  buildSubHeader: () => React.ReactNode
  isDocumentationAutomationEnabled: boolean
  isTranscriptTooShort: boolean
  sessionTranscriptLoading: boolean
  sessionTranscriptData: any
  handleChangeNoteTypeClick: () => void
  openNoteSettingsDrawer: () => void
  buttonSpacing: string | undefined
  isIntegratedUI: boolean
  onStartNewDictationSessionClick: () => void
}

const ProgressNote: React.FC<ProgressNoteProps> = ({
  progressNote,
  handleTrackEvent,
  isNext,
  staticHeight,
  emptyNote,
  transcriptionError,
  paywallBannerHeight,
  uploadAlert,
  showNotePreferencesText,
  buildHeader,
  buildSubHeader,
  isDocumentationAutomationEnabled,
  isTranscriptTooShort,
  sessionTranscriptLoading,
  sessionTranscriptData,
  handleChangeNoteTypeClick,
  openNoteSettingsDrawer,
  buttonSpacing,
  isIntegratedUI,
  onStartNewDictationSessionClick
}) => {
  const {
    id,
    note: v1Note,
    nextNote: v2Note,
    noteType,
    template
  } = progressNote
  const { isExtension, isWidget } = useExperienceManager()

  const buttonsHaveTextBreakpoint = useBreakpointValue({
    base: false,
    sm: true
  })
  const [parentWidth, setParentWidth] = useState(0)
  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateWidth = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth)
      }
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()

    return () => window.removeEventListener('resize', updateWidth)
  }, [])
  const overrideButtonsHaveText = !parentRef?.current || parentWidth > 600
  const buttonsHaveText = buttonsHaveTextBreakpoint && overrideButtonsHaveText

  const note = isNext ? v2Note : v1Note
  const isSafariOrChrome = isMobile && !isDesktop ? (isChrome ? 107 : 85) : 0
  const notePreferencesBaseHeight = showNotePreferencesText
    ? 205
    : 200 + isSafariOrChrome

  return (
    <Container
      height={
        isExtension || isWidget
          ? 'calc(100vh - 165px)'
          : `calc(100vh - ${notePreferencesBaseHeight}px - ${paywallBannerHeight}px)`
      }
      overflowY="scroll"
      marginTop="0px !important"
      ref={parentRef}
    >
      <Flex flexDirection="column" flex="1">
        {(transcriptionError || isTranscriptTooShort) && (
          <Box mt="16px">
            <LowWordCountBanner
              onStartNewDictationSessionClick={onStartNewDictationSessionClick}
            />
          </Box>
        )}
        <Flex justifyContent="space-between" pt="16px">
          <Flex
            flexDirection="column"
            mb={showNotePreferencesText ? '24px' : '16px'}
          >
            <Box>
              <Text as="h2" fontSize="24px" fontWeight="bold">
                {buildHeader()}
              </Text>
            </Box>

            <Flex alignItems="center">
              <Text as="h1" fontWeight="430">
                {buildSubHeader()}
              </Text>
              {isDocumentationAutomationEnabled && !sessionTranscriptLoading ? (
                sessionTranscriptData?.sessionTranscript?.id ? (
                  <Button
                    color="primary"
                    onClick={handleChangeNoteTypeClick}
                    variant="link"
                    textDecoration="none"
                    _focus={{ outline: 'none' }}
                    id="pendo-change-note-type"
                  >
                    Change
                  </Button>
                ) : (
                  <Popover
                    trigger="hover"
                    placement={isIntegratedUI ? 'bottom' : 'top'}
                  >
                    <PopoverContent
                      _focus={{ outline: 'none' }}
                      borderColor="pale_gray"
                    >
                      <PopoverArrow />
                      <PopoverBody>
                        Changing the note type requires a transcript.
                      </PopoverBody>
                    </PopoverContent>
                    <PopoverTrigger>
                      <Button
                        as="div"
                        variant="link"
                        disabled
                        textDecoration="none"
                        color="disabled"
                      >
                        Change
                      </Button>
                    </PopoverTrigger>
                  </Popover>
                )
              ) : null}
            </Flex>
          </Flex>
          {isDocumentationAutomationEnabled ? (
            !!sessionTranscriptData?.sessionTranscript?.id ? (
              <>
                <Button
                  variant="outline"
                  borderRadius="4px"
                  borderColor="pale_gray"
                  m={`0 0 0 ${buttonSpacing}`}
                  pl={!buttonsHaveText ? '0px' : '8px'}
                  pr={!buttonsHaveText ? '0px' : '8px'}
                  h="35px"
                  {...(!buttonsHaveText
                    ? {
                        w: '35px',
                        minW: '35px',
                        maxW: '35px'
                      }
                    : {})}
                  onClick={openNoteSettingsDrawer}
                  {...(isIntegratedUI ? { padding: '8px' } : {})}
                >
                  <Flex align="center" justify="space-between" gap="8px">
                    <NoteSettings fill="#282828" />
                    {buttonsHaveText && <Text>Note preferences</Text>}
                  </Flex>
                </Button>
              </>
            ) : (
              <Popover
                trigger="hover"
                placement={isIntegratedUI ? 'bottom' : 'top'}
              >
                <PopoverContent
                  _focus={{ outline: 'none' }}
                  borderColor="pale_gray"
                >
                  <PopoverArrow />
                  <PopoverBody>
                    Changing note settings requires a transcript.
                  </PopoverBody>
                </PopoverContent>
                <PopoverTrigger>
                  <Button
                    as="div"
                    variant="outline"
                    h="35px"
                    disabled
                    borderColor="pale_gray"
                    borderRadius="4px"
                    textDecoration="none"
                    color="disabled"
                    pl={!showNotePreferencesText ? '0px' : '8px'}
                    pr={!showNotePreferencesText ? '0px' : '8px'}
                  >
                    <Flex align="center" justify="space-between" gap="8px">
                      <NoteSettings fill="#282828" />
                      {buttonsHaveText && <Text>Note preferences</Text>}
                    </Flex>{' '}
                  </Button>
                </PopoverTrigger>
              </Popover>
            )
          ) : null}
        </Flex>
        {uploadAlert && <Box>{uploadAlert()}</Box>}
        {template.sections.map((section: any) => (
          <NoteBox
            noteId={id}
            key={`${progressNote.id}-${section.key}`}
            type={noteType}
            field={section.key}
            isEditable={!isNext}
            buttonId={`pendo-copy-${noteType}-${section.key}-tag`}
            label={section.displayName}
            value={note[section.key] || ''}
            emptyMessage={`No ${section.key} note`}
            trackEvent={handleTrackEvent}
            isNext={isNext}
            staticHeight={staticHeight}
          />
        ))}
        {!emptyNote && (
          <Box mb="16px">
            <ProgressNoteExplanation />
          </Box>
        )}
      </Flex>
    </Container>
  )
}

export default ProgressNote
