import React, { useEffect, useRef } from 'react'
import { useFormikContext, Formik, Form, Field, FieldArray, ErrorMessage, FieldProps, FormikProps } from 'formik'
import { Box, Container, Text, Button, FormControl, FormLabel, Textarea, Flex, Stack, Divider } from '@chakra-ui/react'
import * as Yup from 'yup'
import { Diagnosis, FocusOfTreatment, OptionType, FormValues } from './types'
import { SearchSelect } from '@components'
import { TrashIcon } from '@icons'
import { RegenerateAlertV2 as RegenerateAlert } from './regenerate-alert'
import useAutosizeTextArea from './use-auto-size-text-area'
import SelectionField from './selection-field'
import ReviewAndAcceptBanner from './review-and-accept-banner'
import ClientDetails from './client-details'
import { useTreatmentPlan } from '@hooks'

const TreatmentPlanText: React.FC = () => {
  const { values } = useFormikContext<any>()
  const textAreaRef = useAutosizeTextArea(values.treatmentPlanText)

  return (
    <FormControl>
      <FormLabel fontWeight="bold">Treatment Plan:</FormLabel>
      <Field name="treatmentPlanText">
        {({ field }: FieldProps<string>) => (
          <Textarea
            {...field}
            mt="8px"
            ref={textAreaRef}
            borderColor="pale_gray"
            overflow="hidden"
          />
        )}
      </Field>
    </FormControl>
  )
}

const TreatmentPlanForm = () => {  
  const {
    diagnosisOptions,
    focusOptions,
    diagnosisSuggestions,
    focusSuggestions,
    diagnosisSuggestionsEnabled,
  } = useTreatmentPlan()

  return (
    <Container h="calc(100vh - 306px)" overflowY="scroll" overflowX="hidden">
      <Stack flex="1" py="16px" spacing="0">
        <ReviewAndAcceptBanner />
        <Stack spacing="0" gap="24px" pb="16px">
          <ClientDetails />
          <SelectionField
            name="selectedDiagnoses"
            label="Diagnosis"
            options={diagnosisOptions}
            suggestions={diagnosisSuggestions}
            enabled={diagnosisSuggestionsEnabled}
          />
          <SelectionField
            name="selectedFocuses"
            label="Focus of Treatment"
            options={focusOptions}
            suggestions={focusSuggestions}
            enabled={true}
          />
          <RegenerateAlert />
          <TreatmentPlanText />
        </Stack>
      </Stack>
    </Container>
  )
}

export default TreatmentPlanForm