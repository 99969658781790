import React, { useCallback } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Checkbox, Select } from '@blueprinthq/joy'
import { FormControl, FormLabel, VStack } from '@chakra-ui/react'
import _ from 'lodash'

export const FilterSelect = ({
  filterId,
  options,
  multiple = false,
  type = 'select',
  label = ''
}) => {
  const { updateFilters } = useStoreActions(actions => actions.insights)
  const { filters } = useStoreState(state => state.insights)

  const updateMultipleFilters = useCallback(
    (options, selectedOption) => {
      const selected =
        selectedOption.value === 'all'
          ? [selectedOption]
          : options.filter(option => option.value !== 'all')
      updateFilters([
        {
          id: filterId,
          selected
        }
      ])
    },
    [updateFilters, filterId]
  )

  const updateSingleFilter = useCallback(
    value => {
      updateFilters([
        {
          id: filterId,
          selected: value
        }
      ])
    },
    [updateFilters, filterId]
  )

  if (type === 'checkbox') {
    const values = filters
      .find(filter => filter.id === filterId)
      .selected.map(selected => selected.value)
    return (
      <FormControl>
        <FormLabel fontWeight="bold">{label}</FormLabel>
        <VStack spacing="small" alignItems="normal">
          {options.map(option => (
            <Checkbox
              key={option.value}
              value={option.value}
              isChecked={values.includes(option.value)}
              onChange={event => {
                let selected
                let isAllSelected = false
                if (
                  !event.target.checked &&
                  values.includes(event.target.value)
                ) {
                  selected = values.filter(
                    value => value !== event.target.value
                  )
                } else if (values.length) {
                  selected = [event.target.value, ...values]
                }

                if (selected.length === 0) {
                  isAllSelected = true
                }

                updateMultipleFilters(
                  options.filter(option => selected.includes(option.value)),
                  options.find(option =>
                    isAllSelected
                      ? 'all' === option.value
                      : option.value === event.target.value
                  )
                )
              }}
            >
              {option.label}
            </Checkbox>
          ))}
        </VStack>
      </FormControl>
    )
  }

  if (multiple) {
    const values = filters.find(filter => filter.id === filterId).selected
    return (
      <FormControl>
        <FormLabel fontWeight="bold">{label}</FormLabel>
        <Select
          multiple
          valueKey="value"
          labelKey="label"
          options={options}
          value={values}
          onChange={selected => {
            if (selected.length === 0) {
              updateMultipleFilters(
                options.filter(option =>
                  selected.map(s => s.value).includes(option.value)
                ),
                options.find(option => option.value === 'all')
              )
            } else {
              let difference
              if (selected.length > values.length) {
                difference = _.difference(selected, values)
              } else {
                difference = _.difference(values, selected)
              }
              updateMultipleFilters(
                options.filter(option =>
                  selected.map(s => s.value).includes(option.value)
                ),
                options.find(option => option.value === difference[0].value)
              )
            }
          }}
        />
      </FormControl>
    )
  }

  return (
    <FormControl>
      <FormLabel fontWeight="bold">{label}</FormLabel>
      <Select
        valueKey="value"
        labelKey="label"
        value={filters.find(filter => filter.id === filterId).selected}
        options={options}
        onChange={option => updateSingleFilter(option)}
      />
    </FormControl>
  )
}
