import React from 'react'
import { FieldArray, useFormikContext, ErrorMessage } from 'formik'
import { FormControl, FormLabel, Flex, Button, Text, Divider } from '@chakra-ui/react'
import { TrashIcon } from '@icons'
import { SearchSelect } from '@components'
import { OptionType, FormValues } from './types'
import { useTreatmentPlan } from '@hooks'
import { set } from 'lodash'

const PlusIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
      fill="#282828"
    />
  </svg>
)

const Suggestions = ({ suggestions, selectedItems, name, replace, push, setTouched  }: {
  suggestions: OptionType[],
  selectedItems: OptionType[],
  name: string,
  replace: (index: number, value: OptionType) => void,
  push: (value: OptionType) => void
  setTouched: (values: { [key: string]: boolean }) => void
  enabled?: boolean
}) => {
  const unSelectedSuggestions = suggestions.filter(
    suggestion => !selectedItems.find(item => item?.value === suggestion.value)
  )

  if (!unSelectedSuggestions.length) return null

  return (
    <Flex flexDirection="column" mt="8px">
      <Text pb="8px" color="dark_gray" fontSize="12px">
        Suggestions:
      </Text>
      <Flex
        flexDirection="column"
        border="1px solid"
        borderColor="pale_gray"
        px="16px"
        borderRadius="8px"
      >
        {unSelectedSuggestions.map((suggestion, index) => (
          <Flex flexDirection="column" key={index}>
            <Text py="8px">
              {suggestion.label}
              <Button
                variant="link"
                margin="0 4px"
                style={{ textDecoration: 'none' }}
                _focus={{ outline: 'none' }}
                onClick={() => {
                  const undefinedIndex = selectedItems.findIndex(item => item === undefined)
                  if (undefinedIndex !== -1) {
                    replace(undefinedIndex, suggestion)
                  } else {
                    push(suggestion)
                  }
                  setTouched({ [name]: true })
                }}
              >
                Add
              </Button>
            </Text>
            {index < unSelectedSuggestions.length - 1 && <Divider />}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

const SelectionField: React.FC<{
  name: string
  label: string
  options: OptionType[],
  suggestions?: OptionType[],
  enabled?: boolean
}> = ({ name, label, options, suggestions, enabled }) => {
  const { values, setFieldValue, setTouched } = useFormikContext<FormValues>()
  const { treatmentPlan, isUnaccepted } = useTreatmentPlan()
  const selectedItems = ((values as any)[name] as OptionType[]) || [undefined]
  const filteredOptions = options.filter(
    option => !selectedItems.find((item: OptionType) => item?.value === option.value)
  )

  return (
    <FormControl>
      <FormLabel fontWeight="bold">{label}:</FormLabel>
      <FieldArray name={name}>
        {({ replace, push, remove }) => (
          <Flex flexDirection="column">
            {selectedItems.map((item, index) => (
              <Flex key={index} gap="16px">
                <SearchSelect
                  key={`${index}-${item?.value}`}
                  value={item}
                  placeholder={`Choose ${label.toLowerCase()}`}
                  errors={null}
                  onChange={(value: OptionType) => {
                    setTouched({ [name]: true })
                    replace(index, value)
                  }}
                  options={filteredOptions}
                />
                {(item || index > 0) && (
                  <>
                    <Button
                      variant="link"
                      margin="0"
                      minWidth="0"
                      onClick={() => {
                        if (selectedItems.length === 1) {
                          replace(0, undefined)
                        } else {
                          remove(index)
                        }
                        setTouched({ [name]: true })
                      }}
                    >
                      <TrashIcon fill="black" />
                    </Button>
                    {selectedItems.length - 1 === index ? (
                      <Button
                        variant="link"
                        m="0px"
                        minWidth="0"
                        onClick={() => { push(undefined) }}
                      >
                        <PlusIcon />
                      </Button>
                    ) : (
                      <Flex width="20px" />
                    )}
                  </>
                )}
              </Flex>
            ))}
            <ErrorMessage name={name}>
              {msg => <Text color="red">{msg}</Text>}
            </ErrorMessage>
            {suggestions && isUnaccepted && enabled && (
              <Suggestions
                suggestions={suggestions}
                selectedItems={selectedItems}
                name={name}
                replace={replace}
                push={push}
                setTouched={setTouched}
              />
            )}
          </Flex>
        )}
      </FieldArray>
    </FormControl>
  )
}

export default SelectionField