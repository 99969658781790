import React from 'react'
import { useQueryClient } from 'react-query'
import { Stack, Box, useToast } from '@chakra-ui/react'
import { AssistEnrollmentForm, AssistEnrollmentFormFields, ActionButtons } from '../../shared/assist/assist-enrollment-form'
import { useTreatmentPlan } from '@hooks'
import { useAssistPanelContext } from '../context'
import { usePatientControllerAssistEnroll, getSessionControllerGetSuggestedContentQueryKey } from '~/clinician-api'
import { useCadenceOptions } from '@handlers/sessions/components/shared/use-cadence-options'
import { AssistEnrollmentFormValues } from '@handlers/sessions/types'
import { endpoints } from '@api'

const Enroll = () => {
  const toast = useToast()
  const { setOverlayPanel, data } = useAssistPanelContext()
  const { cadenceMapping } = useCadenceOptions()
  const { client } = useTreatmentPlan()
  const onCancel = () => setOverlayPanel(null)
  const queryClient = useQueryClient()

  const { assessment, checkIn, sessionId } = data
  const { mutateAsync: enrollInAssist, isLoading: isEnrolling } = usePatientControllerAssistEnroll()

  const handleSubmit = async (values: AssistEnrollmentFormValues) => {
    let data: any = {
      email: values.email,
      phone: values.phone,
      assessments: [],
      checkIns: [],
    }

    if (values.assessment && assessment) {
      data = {
        ...data,
        assessments: [{
          clinicAssessmentId: values.assessment.clinicAssessmentId,
          cadence: cadenceMapping[values.assessment.cadence],
          suggestionId: assessment.suggestionId
        }]
      }
    }

    if (values.checkIn && checkIn) {
      data = {
        ...data,
        checkIns: [{
          daysOfWeek: values.checkIn.daysOfWeek,
          timeOfDay: values.checkIn.timeOfDay,
          frequencyType: values.checkIn.frequency,
          suggestionId: checkIn.suggestionId,
          checkInId: checkIn.checkInId,
        }]
      }
    }

    enrollInAssist(
      {
        patientId: client.id,
        data
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            endpoints.getClinicianUserAccount.getCacheId(),
            client.id
          ])
          if (sessionId) {
            const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
              sessionId
            )
            queryClient.invalidateQueries({ queryKey: suggestionListKey })
          }

          toast({
            title: 'Assessment assigned',
            status: 'success',
            isClosable: true,
            duration: 1200
          })
          setOverlayPanel(null)
        },
        onError: () => {
          toast({
            title: 'Error',
            description: 'There was an error assigning this assessment',
            status: 'error',
            isClosable: true,
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <AssistEnrollmentForm
      client={client}
      checkIn={checkIn}
      assessment={assessment}
      onSubmit={handleSubmit}
    >
      {({ formikProps, allAssessments, checkInId }: any) => (
        <Stack flex="1" spacing="0" overflowY="hidden">
          <Stack p="24px" h="calc(100vh - 262px)" overflowY="scroll">
            <AssistEnrollmentFormFields
              formikProps={formikProps}
              client={client}
              assessments={allAssessments}
              checkInId={checkInId}
            />
          </Stack>
          <Box
            p="24px"
            borderTop="1px solid"
            borderColor="pale_gray"
            position="sticky"
            bg="#FAFAFA"
          >
            <ActionButtons
              onCancel={onCancel}
              onSubmit={formikProps.submitForm}
              isSubmitting={isEnrolling}
            />
          </Box>
        </Stack>
      )}
    </AssistEnrollmentForm>
  )
}

export default Enroll
