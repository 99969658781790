import React from 'react'

const Warning = props => (
  <svg
    viewBox="0 0 16 16"
    width={props.width || '16'}
    height={props.height || '16'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={props.fill}>
      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 14.4A6.4 6.4 0 1 1 8 1.6a6.4 6.4 0 0 1 0 12.8z" />
      <path d="M8 4a.8.8 0 0 0-.8.8v4a.8.8 0 1 0 1.6 0v-4A.8.8 0 0 0 8 4z" />
      <circle cx="8" cy="11.5" r="1" />
    </g>
  </svg>
)
export default Warning
