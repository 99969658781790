import { api } from './api'

class AuthedApi {
  constructor(api, defaultHost) {
    this.api = api
    this.host = defaultHost
  }

  formHostPath = route => {
    return new URL(route, this.host)
  }

  makeRequest = (funcReference, route, ...args) => {
    return funcReference.call(this.api, this.formHostPath(route), ...args)
  }

  GET = route => {
    return this.makeRequest(this.api.GET, route)
  }

  POST = (route, body, options) => {
    return this.makeRequest(this.api.POST, route, body, options)
  }

  PUT = (route, body) => {
    return this.makeRequest(this.api.PUT, route, body)
  }

  PATCH = (route, body) => {
    return this.makeRequest(this.api.PATCH, route, body)
  }

  DELETE = (route, body) => {
    return this.makeRequest(this.api.DELETE, route, body)
  }
}

export const authedApi = new AuthedApi(
  api,
  process.env.REACT_APP_NODE_API_ROOT_URL
)
