export const ClientStatusBuckets = Object.freeze({
  PENDING: 'pending',
  AWAITING_INVITE: 'awaiting_invite',
  INVITE_SENT: 'invite_sent',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DECLINED: 'declined',
  UNKNOWN: 'unknown'
})

export const reportUnrecognizedStatus = status => {
  const err = new UnrecognizedClientStatus(status)
  console.error(err)
}

export class UnrecognizedClientStatus extends Error {
  constructor(status) {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnrecognizedClientStatus)
    }

    this.name = 'UnrecognizedClientStatus'
    this.status = status
    this.date = new Date()
  }
}
