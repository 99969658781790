import React from 'react'
import {
  VStack,
  HStack,
  Box,
  Text,
  Heading,
  Stack,
  Image
} from '@chakra-ui/react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Button } from '@blueprinthq/joy'
import { isMobile, isTablet } from 'react-device-detect'

const FreemiumTrialType = 'freemium'

export const Persona = ({ onSelect }) => {
  const { setPersona } = useStoreActions(actions => actions.clinicianSignup)
  const { formData } = useStoreState(state => state.clinicianSignup)

  const options = [
    {
      key: 'solo',
      value: 'Individual practitioner',
      description: 'I run my own solo private practice',
      image:
        'https://res.cloudinary.com/hellojoy/image/upload/v1678329210/Solo_zq7eby.png',
      path: '/signup/plan',
      type: 'button'
    },
    {
      key: 'clinician_within_group',
      value: 'Clinician within a group',
      description:
        'I’m a clinician working at a group practice, agency, or mental health organization',
      image:
        'https://res.cloudinary.com/hellojoy/image/upload/v1678329210/Group_iwdbtw.png',
      path: '/signup/plan',
      type: 'button'
    },
    {
      key: 'group_leader',
      value: 'Owner / leader',
      description:
        'I’m an owner or leader at a group practice, agency, or mental health organization',
      image:
        'https://res.cloudinary.com/hellojoy/image/upload/v1678329210/Enterprise_fkwgei.png',
      path: '/signup/plan',
      type: 'button'
    }
  ]

  const handleClick = async option => {
    setPersona({ persona: option.key })

    await onSelect({
      ...formData,
      initialProduct: 'documentation_automation',
      trialType: FreemiumTrialType
    })
  }

  return (
    <VStack w="100%" maxWidth="1000px" ml="auto" mr="auto">
      <Box w="100%" pb="medium">
        <Heading w="100%" mb="4px" size="lg">
          How would you describe yourself?
        </Heading>
        <Text>We'll use this to personalize your onboarding process.</Text>
      </Box>
      <Stack
        w="100%"
        direction="column"
        align="top"
        justify="center"
        wrap="wrap"
        spacing="small"
        style={{ marginTop: 0 }}
      >
        {options
          .filter(option => option.type === 'button')
          .map(option => (
            <Box
              onClick={() => handleClick(option)}
              key={option.key}
              w="100%"
              as="button"
              p="small"
              borderColor={'#CCC'}
              borderWidth="1px"
              borderRadius={'8px'}
              _hover={{
                background:
                  !isMobile && !isTablet ? 'rgba(45, 84, 232, 0.04)' : ''
              }}
            >
              <HStack w="100%" spacing="4" align="center">
                <Image src={option.image} alt={option.key} boxSize="64px" />
                <VStack align="left" spacing="0">
                  <Text align="left" as="b">
                    {option.value}
                  </Text>
                  <Text align="left">{option.description}</Text>
                </VStack>
              </HStack>
            </Box>
          ))}
      </Stack>
      <VStack>
        {options
          .filter(option => option.type === 'link')
          .map(option => (
            <Button
              key={option.key}
              onClick={() => handleClick(option)}
              variant="link"
            >
              {option.value}
            </Button>
          ))}
      </VStack>
    </VStack>
  )
}
