import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@chakra-ui/layout'
import { usePermissions } from '../../../hooks'
import { useQuery } from 'react-query'
import { endpoints } from '../../../api'

const NotificationBadge = ({ isMobile }) => {
  const {
    data: { count }
  } = useQuery(
    endpoints.getUnreadNotificationCount.getCacheId(),
    endpoints.getUnreadNotificationCount.request,
    {
      initialData: { count: 0 }
    }
  )

  const { hasPermission } = usePermissions()

  // Adjust badge colors for Superadmin users
  const colorVariant = hasPermission('*:any:*:*')
    ? {
        backgroundColor: 'white',
        color: '#282828'
      }
    : {
        backgroundColor: '#282828',
        color: 'white'
      }

  const styleConfig = isMobile
    ? {
        width: '20px',
        height: '20px',
        lineHeight: '20px',
        fontSize: '10px',
        position: 'absolute',
        top: '0',
        left: '18px'
      }
    : {
        width: '24px',
        height: '24px',
        lineHeight: '24px',
        fontSize: 'sm'
      }

  return count === 0 ? null : (
    <Badge
      sx={{
        ...colorVariant,
        borderRadius: '50%',
        textAlign: 'center',
        padding: '0 3px',
        ...styleConfig
      }}
    >
      {count > 99 ? '!' : count}
    </Badge>
  )
}

NotificationBadge.propTypes = {
  isMobile: PropTypes.bool
}

export default NotificationBadge
