import React from 'react'
import { useStoreState } from 'easy-peasy'
import {
  Box,
  VStack,
  Flex,
  Text,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  HStack
} from '@chakra-ui/react'
import { Mic } from '@components/icons'
import { ArrowDownIcon } from '@blueprinthq/joy'
import { times } from 'lodash'
import { useExperienceManager, useAudioCapture } from '@hooks'
import { trackEvent } from '@lib/clinician-tracking'
import { StoreModel } from 'src/store/types'

export const AudioLevel = () => {
  const { audioLevel } = useStoreState((state: StoreModel) => state.volume)
  const { isWidget } = useExperienceManager()
  const { isMuted } = useAudioCapture()
  const numBars = isWidget ? 10 : 15

  return (
    <Flex>
      {times(numBars, i => (
        <Box
          key={i}
          h="16px"
          w="6px"
          borderRadius="4px"
          bg={
            audioLevel >= (i + 1) * (1 / numBars) && !isMuted
              ? '#68E19F'
              : '#E4E5E6'
          }
          mr="6px"
        />
      ))}
    </Flex>
  )
}

export const MicrophoneSelector = ({
  isExtension = false,
  isWidget = false
}: {
  isExtension?: boolean
  isWidget?: boolean
}) => {
  const {
    audioInputs,
    selectedAudioInput,
    setSelectedAudioInput,
    permissionStatus
  } = useAudioCapture()

  const selectedInput = audioInputs.find(
    input => input.deviceId === selectedAudioInput
  )

  const handleMicChange = (event: any) => {
    setSelectedAudioInput(event.target.value)
    trackEvent('Confirm Audio Settings Dialog -> Changed Microphone Input', {
      newMicrophoneSelection: JSON.stringify(
        audioInputs.find(ai => ai.deviceId === event.target.value)
      )
    })
  }

  return (
    <VStack spacing={4} textAlign="left" w="100%">
      <Box w="100%">
        {!isWidget && (
          <Text
            w="100%"
            fontWeight="bold"
            mb={2}
            color={isExtension ? 'white' : 'black'}
          >
            Microphone:
          </Text>
        )}
        <Menu>
          <MenuButton
            border="1px solid #C9C9C9"
            m="0"
            w="100%"
            h={isExtension || isWidget ? '40px' : '48px'}
            bg="white"
            borderRadius="6px"
            as={Button}
            rightIcon={permissionStatus !== 'denied' && <ArrowDownIcon />}
            disabled={permissionStatus === 'denied'}
          >
            <HStack spacing={3}>
              <Flex w="24px" h="24px" align="center" justify="center">
                <Mic fill={permissionStatus === 'denied' ? 'red' : 'black'} />
              </Flex>
              <Text
                color={
                  audioInputs.length
                    ? 'black'
                    : permissionStatus === 'denied'
                    ? 'red'
                    : 'black'
                }
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {!audioInputs.length &&
                  permissionStatus !== 'denied' &&
                  'Select a microphone'}
                {!audioInputs.length &&
                  permissionStatus === 'denied' &&
                  'Microphone access blocked'}
                {selectedInput?.label}
              </Text>
            </HStack>
          </MenuButton>
          <MenuList w="100%">
            {audioInputs.map(input => (
              <MenuItem
                isDisabled={input.deviceId === selectedAudioInput}
                h="48px"
                w="100%"
                key={input.deviceId}
                onClick={handleMicChange}
                value={input.deviceId}
                color="black"
                icon={
                  input.deviceId === selectedAudioInput ? (
                    <Mic filterId="startRecordingMenuItem" />
                  ) : (
                    <Box w="large" mr="medium" />
                  )
                }
              >
                {input.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
      {!isExtension && (
        <Box
          border="1px solid #C9C9C9"
          w="100%"
          bg="white"
          borderRadius="6px"
          sx={{ mt: isWidget ? '8px !important' : '' }}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            py={2}
            px={4}
            h={isWidget ? '40px' : '48px'}
          >
            <Text
              fontWeight="normal"
              color="black"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {isWidget ? 'Test microphone:' : 'Test your microphone:'}
            </Text>
            <AudioLevel />
          </Flex>
        </Box>
      )}
    </VStack>
  )
}
