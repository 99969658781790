import React from 'react'
import { WorksheetAnswersTable } from '@containers/worksheet-result-modal/worksheet-answers-table'
import { Box, Flex, Text } from '@chakra-ui/react'
import { CheckInDtoV2, CheckInScoreDtoV1 } from '~/clinician-api/models'
import { timeToRelativeOrDate } from '@utilities'

interface WorksheetResultProps {
  checkIn: CheckInDtoV2
  score: CheckInScoreDtoV1
}

export const WorksheetResult = ({ checkIn, score }: WorksheetResultProps) => {
  return (
    <Box p="medium" height="100%" overflowY="scroll">
      <Flex mb="xsmall" justify="flex-start" gap="small">
        <Text fontWeight="bold">{checkIn.title}</Text>
        <Text color="gray">({timeToRelativeOrDate(score.createdAt)})</Text>
      </Flex>
      <WorksheetAnswersTable checkIn={checkIn} score={score} />
    </Box>
  )
}
