import React from 'react'
import {
  Box,
  Text,
  Avatar,
  Tag,
  TagLabel,
  useBreakpointValue
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@blueprinthq/joy'
import { useExperienceManager } from '@hooks'
import moment from 'moment'

export const PatientOption = ({
  onClick,
  firstName,
  lastName,
  isDemo,
  dateOfBirth
}) => {
  const isSmallScreen = useBreakpointValue({ base: true, sm: false })

  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  return (
    <Box
      borderRadius="8px"
      bg="white"
      h={['40px', '64px']}
      p={4}
      w="100%"
      display="flex"
      alignItems="center"
      onClick={onClick}
      cursor="pointer"
    >
      <Box display="flex" alignItems="center" flex={1}>
        {!isSmallScreen && (
          <Avatar
            name={`${firstName} ${lastName}`}
            sx={{
              marginRight: 'small',
              background: 'rgba(45, 84, 232, 0.04)',
              color: 'black',
              fontWeight: 'bold'
            }}
          />
        )}
        <Box>
          <Box display="flex" alignItems="center">
            <Text fontWeight="bold">
              {firstName} {lastName}
            </Text>
            {isDemo && (
              <Tag
                ml={4}
                w="66px"
                h="20px"
                minH="20px"
                fontSize="9px"
                fontWeight="430"
                borderRadius="full"
                variant="solid"
                bg="black"
                color="white"
              >
                <TagLabel>Demo client</TagLabel>
              </Tag>
            )}
          </Box>
          {!isSmallScreen && isEvidenceBasedCareEnabled && dateOfBirth && (
            <Text fontSize="12px" color="#757575">
              {moment(dateOfBirth).format('M/D/YYYY')}
            </Text>
          )}
        </Box>
      </Box>
      <Box>
        <ArrowRightIcon size="md" />
      </Box>
    </Box>
  )
}
