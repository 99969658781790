import React from 'react'
import { Text, Box, Select, VStack, HStack, Flex } from '@chakra-ui/react'
import { Field, FieldArray, useFormikContext } from 'formik'
import { AnswerField } from './answer-field'
import { Assessment } from '../../assessment-submitted-trigger'

type Props = {
  assessment: Assessment
  resetForm: (values: object) => void
}

type FormikContext = {
  values: {
    assessmentId: string | undefined
    questionKey: string | undefined
    triggerAnswers: number[] | undefined
  }
  errors: {
    questionKey?: any | undefined
    answerIds?: any | undefined
    assessmentId?: string | undefined
  }
}

export const QuestionAnswerFilter = ({
  assessment,
  resetForm,
}: Props) => {
  const { values, errors }: FormikContext = useFormikContext()
  return (
    <VStack spacing="small">
      <HStack spacing="small">
        <Text>Question is</Text>
        <Field name="questionKey">
          {({ field, form }: { field: { value: string }; form: any }) => (
            <Select
              {...field}
              value={field.value}
              borderColor={errors?.questionKey ? 'error' : 'light_gray'}
              color={field.value ? 'black' : 'gray'}
              w="380px"
              onChange={e => {
                resetForm({
                  ...values,
                  questionKey: e.target.value,
                  triggerAnswers: ['']
                })
              }}
            >
              <option value="" disabled>
                Choose question
              </option>
              {assessment.questions.map(q => (
                <option key={q.key} value={q.key}>
                  {q.title}
                </option>
              ))}
            </Select>
          )}
        </Field>
      </HStack>
      <Flex w="100%" justify="space-between" align="flex-start">
        <Text pt="xsmall">Answer is</Text>
        <VStack spacing="small">
          <FieldArray
            name="triggerAnswers"
            render={({ replace, push }): React.ReactNode => (
              <Box>
                {!Boolean(values?.triggerAnswers?.length) && (
                  <AnswerField
                    index={0}
                    assessment={assessment}
                    replace={replace}
                    values={values.triggerAnswers}
                  />
                )}
                {values.triggerAnswers?.map((_: number, index: number) => (
                  <AnswerField
                    index={index}
                    assessment={assessment}
                    replace={replace}
                    values={values.triggerAnswers}
                  />
                ))}
                {Boolean(values?.triggerAnswers?.length) && (
                  <Box display="flex" justifyContent="flex-end">
                    <Text
                      _hover={{ cursor: 'pointer' }}
                      onClick={() => push('')}
                      color="primary"
                    >
                      Add
                    </Text>
                  </Box>
                )}
              </Box>
            )}
          />
        </VStack>
      </Flex>
    </VStack>
  )
}
