import React from 'react'
import {
  Box,
  Text,
  VStack,
  HStack,
  Divider,
  Menu,
  MenuButton,
  IconButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@blueprinthq/joy'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import { useTreatmentPlan } from '@hooks'

export const SavedForLater = () => {
  const { client } = useTreatmentPlan()

  const savedForLater = [
    { id: 1, name: 'Leaves on a Stream' },
    { id: 2, name: 'Challenging Unhelpful Thinking' },
    { id: 3, name: 'Model of Emotions' }
  ]

  return (
    <Box mb="small">
      <Text fontWeight="bold" mb="xsmall">
        Saved for Later
      </Text>
      <Box border="1px solid" borderColor="pale_gray" rounded="md">
        {savedForLater?.length === 0 ? (
          <Text p="small">No saved interventions.</Text>
        ) : (
          <VStack
            flex={1}
            alignItems="flex-start"
            divider={
              <Divider
                color="pale_gray"
                mt="0px !important"
                mb="0px !important"
              />
            }
          >
            {savedForLater.map(s => (
              <HStack
                key={s.id}
                p="small"
                alignItems="center"
                justify="space-between"
                w="100%"
                _hover={{
                  bg: 'gray.200',
                  cursor: 'pointer'
                }}
                onClick={() => {}}
              >
                <Box>
                  <Text>{s.name}</Text>
                </Box>
                <HStack spacing={2}>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<MoreHorizIcon />}
                      variant="ghost"
                      height="auto"
                      _focus={{
                        outline: 'none',
                        outlineStyle: 'none'
                      }}
                    />
                    <MenuList>
                      <MenuItem onClick={() => {}}>Remove from Saved</MenuItem>
                    </MenuList>
                  </Menu>
                  <ArrowRightIcon />
                </HStack>
              </HStack>
            ))}
          </VStack>
        )}
      </Box>
    </Box>
  )
}
