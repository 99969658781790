import React, { useState } from 'react'
import { HStack, Container } from '@chakra-ui/react'

const Toolbar: React.FC = ({ children }) => {
  return (
    <Container borderTop="1px solid" borderColor="pale_gray">
      <HStack position="sticky" h="96px" gap="8px" flex="1">
        {children}
      </HStack>
    </Container>
  )
}

export default Toolbar