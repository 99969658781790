import React from 'react'

const Compass = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <rect
      y="0.75"
      width="32"
      height="32"
      rx="16"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M28 16.75C28 23.3774 22.6271 28.75 16 28.75C9.37294 28.75 4 23.3771 4 16.75C4 10.1229 9.37294 4.75 16 4.75C22.6271 4.75 28 10.1229 28 16.75Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 16.75C4 10.1311 9.38105 4.75 16 4.75C22.6189 4.75 28 10.1311 28 16.75C28 23.3689 22.6189 28.75 16 28.75C9.38105 28.75 4 23.3689 4 16.75ZM19.2842 18.8721L22.1642 12.2532C22.6189 11.1921 21.5578 10.1312 20.4968 10.5859L13.8779 13.4659C13.3725 13.6934 12.943 14.0975 12.7157 14.6281L9.86105 21.2723C9.38101 22.3079 10.4672 23.3689 11.5031 22.9143L18.122 20.0343C18.6274 19.8068 19.0569 19.4027 19.2842 18.8721ZM15.7472 18.1393C16.6541 18.1393 17.3893 17.4041 17.3893 16.4973C17.3893 15.5905 16.6541 14.8553 15.7472 14.8553C14.8404 14.8553 14.1052 15.5905 14.1052 16.4973C14.1052 17.4041 14.8404 18.1393 15.7472 18.1393Z"
      fill="#282828"
    />
  </svg>
)

export default Compass
