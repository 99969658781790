/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type CheckInSliderVerticalNodeType = typeof CheckInSliderVerticalNodeType[keyof typeof CheckInSliderVerticalNodeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckInSliderVerticalNodeType = {
  free_text: 'free_text',
  multiple_choice: 'multiple_choice',
  heading: 'heading',
  likert: 'likert',
  slider_vertical: 'slider_vertical',
} as const;
