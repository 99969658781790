import React, { useState } from 'react'
import { Card } from './components'
import {
  Hide,
  Container,
  Heading,
  Text,
  Box,
  Flex,
  ListItem,
  List,
  ListIcon,
  Avatar,
  HStack,
  Link,
  useToast,
  VStack,
  Spinner
} from '@chakra-ui/react'
import { Button, CheckIcon } from '@blueprinthq/joy'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import queryString from 'query-string'
import { cloudinaryAssetToUrl } from '../../utilities'

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const CheckoutDetails = ({ clinicians, amount, interval, count }) => {
  const subtotal = numberWithCommas(count * (amount / 100))
  const subtotalMath = `$${amount / 100} per ${interval} x ${count} ${
    count > 1 ? 'Clinicians' : 'Clinician'
  } =`
  const subtotalString = `$${subtotal} per ${interval}`

  return (
    <HStack justify="space-between" align="top" mt="large" mb="42px">
      <HStack>
        <Link
          fontSize="13px"
          as={RouterLink}
          to="/settings/users"
          mr="xsmall"
          borderBottom="1px solid #cccccc"
          _hover={{
            textDecoration: 'none',
            color: '#2d54e8',
            borderBottom: '1px solid #2d54e8'
          }}
        >
          {count} {count > 1 ? `Clinicians` : `Clinician`}
        </Link>
        {count < 7 &&
          clinicians.map(clinician => {
            return (
              <span key={clinician.id} style={{ marginLeft: '-4px' }}>
                <Avatar
                  width="24px"
                  height="24px"
                  src={cloudinaryAssetToUrl(clinician.avatar, {
                    transformations: {
                      resize: {
                        height: 128,
                        width: 128,
                        type: 'scale'
                      }
                    }
                  })}
                  name={`${clinician.first_name} ${clinician.last_name}`}
                  bg="pale_gray"
                  size="xs"
                  fontWeight="bold"
                />
              </span>
            )
          })}
      </HStack>
      <HStack spacing="4px" align="center">
        <Text fontSize="13px">{subtotalMath}</Text>
        <Text as="b" fontSize="13px">
          {subtotalString}
        </Text>
      </HStack>
    </HStack>
  )
}

const Features = ({ product }) => {
  if (!product) {
    return null
  }
  const { metadata: features, name } = product

  return (
    <Box p="24px" bg="#F7F8FE" borderRadius="4px">
      <Text fontSize="14px" as="b">
        Great choice! With {name}, you'll get{' '}
      </Text>
      {features.everything_plus && (
        <Text fontSize="14px" as="b">
          {features.everything_plus.charAt(0).toLowerCase() +
            features.everything_plus.slice(1)}
        </Text>
      )}
      <List align="left" mt="4px">
        {Object.keys(features).map(key => {
          if (key !== 'everything_plus') {
            return (
              <ListItem fontSize="14px" lineHeight="2" key={key}>
                <ListIcon opacity="0.5" as={CheckIcon} />
                {features[key]}
              </ListItem>
            )
          } else {
            return null
          }
        })}
      </List>
    </Box>
  )
}

const PlanDetails = ({ product, price }) => {
  const openPlansModal = useStoreActions(
    actions => actions.modals.choosePlan.openModal
  )

  if (!product || !price) {
    return null
  }
  const { name } = product
  const { unit_amount: amount, recurring } = price
  const { interval } = recurring
  const intervally = interval === 'month' ? 'monthly' : 'annually'
  const emphasizedStyle = {
    fontWeight: 'bold'
  }
  return (
    <Box mt="small">
      <Text fontSize="18px">
        You've selected the <span style={emphasizedStyle}>{name}</span> plan
        billed <span style={emphasizedStyle}>{intervally}</span> at{' '}
        <span style={emphasizedStyle}>${amount / 100}</span> per Clinician per{' '}
        {interval}.
        <Button
          fontSize="18px"
          fontWeight="bold"
          variant="link"
          onClick={openPlansModal}
        >
          Change
        </Button>
      </Text>
    </Box>
  )
}

export const Upgrade = props => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const history = useHistory()
  const { planId } = queryString.parse(props.location.search)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isError, setIsError] = useState(false)

  const { isLoading: arePlansLoading, data: plan } = useQuery(
    [endpoints.getPlan.getCacheId(), planId],
    () => endpoints.getPlan.request({ planId: planId }),
    {
      initialData: []
    }
  )

  const { isLoading: areCliniciansLoading, data: clinicians } = useQuery(
    [endpoints.getClinicians.getCacheId()],
    endpoints.getClinicians.request,
    {
      initialData: []
    }
  )
  const { mutateAsync: upsertSubscription, err } = useMutation(
    endpoints.postUpsertSubscription.request,
    {
      onSuccess() {
        //wait for stripe to refresh before we invalidate queries...this is messy...open to other suggestions...
        setTimeout(() => {
          queryClient.invalidateQueries(
            endpoints.getPaywallValidation.getCacheId()
          )
          setIsError(false)
          toast({
            title: 'You have successfully updated your plan.',
            description: 'Thanks for your business!',
            status: 'success',
            duration: 3000,
            isClosable: true
          })
          history.push('/')
        }, 3000)
      },
      onError() {
        setIsSubmitting(false)
        setIsError(
          `Oops, something went wrong. Please try again or contact support at ${process.env.REACT_APP_HELP_EMAIL}`
        )
        console.error(err)
      }
    }
  )

  const { price, product } = plan

  if (arePlansLoading || areCliniciansLoading || !clinicians || !price) {
    return (
      <VStack mt="32px" justify="center" h="384px">
        <Spinner color="primary" />
      </VStack>
    )
  }

  const { unit_amount: amount, recurring } = price
  const { interval } = recurring
  const count = clinicians.length
  const amountWithCommasPerUser = numberWithCommas(amount / 100)
  const subtotal = numberWithCommas(count * (amount / 100))

  const handleFormSubmit = async (stripeCustomerId, paymentMethodId) => {
    setIsError(false)
    const data = {
      stripeCustomerId: stripeCustomerId,
      planId: planId,
      paymentMethodId: paymentMethodId,
      isDowngrade: false,
      quantity: count
    }
    await upsertSubscription({
      data
    })
  }

  return (
    <Container
      maxWidth="70rem"
      overflowY="clip"
      height="100%"
      centerContent
      paddingTop={{
        base: '24px',
        sm: '32px',
        md: '48px',
        lg: '90px',
        xl: '90px'
      }}
      paddingLeft={{
        base: '24px'
      }}
      paddingRight={{
        base: '24px'
      }}
    >
      <Flex ml="auto" mr="auto">
        <Box w="100%" maxW="500px" pl="24px" pr="24px">
          <Box w="100%">
            <Heading>Checkout</Heading>
            <PlanDetails product={product} price={price} />
            <CheckoutDetails
              clinicians={clinicians}
              amount={amount}
              interval={interval}
              count={count}
            />
            <Card
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              upsertSubscription={handleFormSubmit}
              amount={amountWithCommasPerUser}
              interval={interval}
              subtotal={subtotal}
            />
            {isError && (
              <div className="errorMessage" role="alert">
                {isError}
              </div>
            )}
          </Box>
        </Box>
        <Hide below="md">
          <Box maxW="360px" ml="120px" pl="24px" pr="24px">
            <Features product={product} />
          </Box>
        </Hide>
      </Flex>
    </Container>
  )
}
