import React from 'react'
import { Box, FormControl, GridItem } from '@chakra-ui/react'
import { TextField, Checkbox, LayoutGrid } from '@blueprinthq/joy'
import { Field, FastField } from 'formik'
import _ from 'lodash'

import { BPDateTimePicker } from '@components'
import { CLIENT_TYPE } from '@constants/index'

const ClientInfoComponent = ({ formHelpers }) => {
  const { values, errors, touched, resetConflict } = formHelpers
  const { conflicts } = values

  return (
    <Box mt="small">
      {process.env.REACT_APP_ENV !== 'production' && (
        <>
          <FastField name="overrideContactInfo" type="checkbox">
            {({ field }) => (
              <FormControl>
                <Checkbox
                  {...field}
                  isChecked={values.overrideContactInfo}
                  fontWeight="bold"
                >
                  (DEV ONLY) override pre-existing
                </Checkbox>
              </FormControl>
            )}
          </FastField>
          <Box mt="small" />
        </>
      )}
      <LayoutGrid gridColumnGap="small" gridRowGap="small">
        <GridItem
          colSpan={{
            base: 4,
            sm: 8,
            md: 4
          }}
          mb={errors.firstName && touched.firstName && 'small'}
        >
          <FastField name="firstName">
            {({ field }) => (
              <TextField
                {...field}
                label="First Name"
                isInvalid={errors.firstName && touched.firstName}
                errorText={errors.firstName}
                isRequired
              />
            )}
          </FastField>
        </GridItem>
        <GridItem
          colSpan={{
            base: 4,
            sm: 8,
            md: 4
          }}
          colStart={{
            md: 5
          }}
          rowStart={{
            base: 2,
            md: 1
          }}
          mb={
            ((errors.lastName && touched.lastName) ||
              conflicts.includes('last_name')) &&
            'small'
          }
        >
          <Field name="lastName">
            {({ field }) => (
              <TextField
                {...field}
                label="Last Name"
                isInvalid={
                  (errors.lastName && touched.lastName) ||
                  conflicts.includes('last_name')
                }
                errorText={
                  errors.lastName ||
                  (conflicts.includes('last_name') && 'Conflict')
                }
                onChange={e => resetConflict('last_name', field.onChange, e)}
                isRequired
              />
            )}
          </Field>
        </GridItem>
        <GridItem
          colSpan={{
            base: 4,
            sm: 8,
            md: 4
          }}
          colStart={{
            md: 9
          }}
          rowStart={{
            base: 3,
            md: 1
          }}
          mb={
            ((errors.dateOfBirth && touched.dateOfBirth) ||
              conflicts.includes('date_of_birth')) &&
            'small'
          }
        >
          <Field name="dateOfBirth">
            {({ field }) => (
              <BPDateTimePicker
                {...field}
                showDate
                isRequired
                label="Date of Birth"
                isInvalid={
                  (errors.dateOfBirth && touched.dateOfBirth) ||
                  conflicts.includes('date_of_birth')
                }
                errorText={
                  (errors.dateOfBirth &&
                    errors.dateOfBirth.includes('Invalid') &&
                    'Invalid date') ||
                  errors.dateOfBirth ||
                  (conflicts.includes('date_of_birth') && 'Conflict')
                }
                onChange={e =>
                  resetConflict('date_of_birth', field.onChange, e)
                }
              />
            )}
          </Field>
        </GridItem>
        {values.clientType === CLIENT_TYPE.ADULT && (
          <>
            <GridItem
              colSpan={{
                base: 4,
                sm: 8,
                md: 6
              }}
              rowStart={{
                base: 4,
                md: 2
              }}
              mb={
                ((errors.email && touched.email) ||
                  conflicts.includes('email')) &&
                'small'
              }
            >
              <Field name="email">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Email Address"
                    isInvalid={
                      (errors.email && touched.email) ||
                      conflicts.includes('email')
                    }
                    errorText={
                      errors.email ||
                      (conflicts.includes('email') && 'Conflict')
                    }
                    onChange={e => {
                      e.target.value = e.target.value.trim()
                      resetConflict('email', field.onChange, e)
                    }}
                  />
                )}
              </Field>
            </GridItem>
            <GridItem
              colSpan={{
                base: 4,
                sm: 8,
                md: 6
              }}
              colStart={{
                md: 7
              }}
              rowStart={{
                base: 5,
                md: 2
              }}
              mb={
                ((errors.phoneNumber && touched.phoneNumber) ||
                  conflicts.includes('phone_number')) &&
                'small'
              }
            >
              <Field name="phoneNumber">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Cell Phone"
                    leftIcon={'+1'}
                    isInvalid={
                      (errors.phoneNumber && touched.phoneNumber) ||
                      conflicts.includes('phone_number')
                    }
                    errorText={
                      errors.phoneNumber ||
                      (conflicts.includes('phone_number') && 'Conflict')
                    }
                    type="tel"
                    onChange={e =>
                      resetConflict('phone_number', field.onChange, e)
                    }
                  />
                )}
              </Field>
            </GridItem>
            {!_.isEmpty(values.medicalRecord) && (
              <GridItem
                colSpan={{ base: 4, sm: 8, md: 6 }}
                colStart={{ md: 7 }}
                rowStart={{ base: 6, md: 3 }}
              >
                <Field name="medicalRecord">
                  {({ field }) => (
                    <TextField
                      {...field}
                      isDisabled={true}
                      label="Medical Record Number"
                    />
                  )}
                </Field>
              </GridItem>
            )}
            <GridItem
              colSpan={{
                base: 4,
                sm: 8,
                md: 12
              }}
              rowStart={{
                base: 7,
                md: 4
              }}
            >
              <Box w="100%">
                Important: A contact method is required to receive Assessment
                reminders.
              </Box>
            </GridItem>
          </>
        )}
      </LayoutGrid>
    </Box>
  )
}

export const ClientInfo = React.memo(ClientInfoComponent, (props, nextProps) =>
  _.isEqual(props, nextProps)
)
