import React from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useModalToggle } from '@hooks'
import {
  Box,
  Text,
  Heading,
  Flex,
  Link,
  Select,
  Switch
} from '@chakra-ui/react'

import { Resource } from './resource'
import { ResourceForm } from './resource-form'
import { endpoints } from '@api'
import { Loading } from '@components'
import { AddCircleIcon, Select as JoySelect } from '@blueprinthq/joy'
import { SafetyNetPreviewModal } from './components/safety-net-preview-modal'
import { usePermissions, useClinicSelectControl } from '@hooks'

export interface SupportResource {
  id?: string | undefined
  type: string
  displayText: string
  value: string
}

type Props = {
  clinics: any[]
  selectedClinic: any
  setSelectedClinic: Function
}

export const SafetyNetEditor = ({
  clinics,
  selectedClinic,
  setSelectedClinic
}: Props) => {
  const [isAddingResource, setIsAddingResource] = React.useState(false)
  const { hasPermission } = usePermissions()
  const { clinicSelectEnabled } = useClinicSelectControl(clinics.length)
  const queryClient = useQueryClient()
  const {
    data: resources,
    isLoading
  }: { data: SupportResource[] | undefined; isLoading: boolean } = useQuery(
    [endpoints.getClinicSafetyNetResources.getCacheId(), selectedClinic.id],
    () => {
      // @ts-ignore
      return endpoints.getClinicSafetyNetResources.request({
        clinicId: selectedClinic.id
      })
    }
  )

  const {
    mutate: toggleShowSafetyPlanning
  }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.toggleShowSafetyPlanning.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getOrganizationClinics.getCacheId()
        )
      }
    }
  )

  const isSuperAdmin = hasPermission('read:any:clinics:list')
  const isEditView = hasPermission('update:clinic:settings:safety-net')

  const {
    isOpen: isPreviewOpen,
    openModal: openPreviewModal,
    closeModal: closePreviewModal
  } = useModalToggle()

  if (isLoading) return <Loading />

  return (
    <Box>
      <SafetyNetPreviewModal
        isOpen={isPreviewOpen}
        supportResources={
          selectedClinic.show_client_safety_planning
            ? [
                {
                  type: 'web',
                  displayText: 'Safety Plan',
                  value: window.location.origin
                },
                ...(resources as SupportResource[])
              ]
            : resources
        }
        onClose={closePreviewModal}
      />
      <Box w="100%" mb="medium">
        <Heading mb="xxsmall">Safety Net</Heading>
        <Text>
          Manage the client safety net resources for your organization
        </Text>
      </Box>
      {clinicSelectEnabled && (
        <Box mb="small">
          {isSuperAdmin ? (
            <Select
              value={selectedClinic.id}
              style={{ border: '1px solid #E4E5E6' }}
              _focus={{
                outline: 'none'
              }}
              borderRadius="4px"
              height="48px"
              onChange={e =>
                setSelectedClinic(clinics.find(c => c.id === e.target.value))
              }
            >
              {clinics.map(c => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Select>
          ) : (
            <JoySelect
              label=""
              value={selectedClinic}
              options={clinics}
              valueKey="id"
              labelKey="name"
              onChange={(value: any) => setSelectedClinic(value)}
            />
          )}
        </Box>
      )}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        p="small"
        mb="small"
        border="1px solid"
        borderColor="pale_gray"
        borderRadius="4px"
      >
        <Box>
          <Text as="b">Show completed Safety Planning Intervention</Text>
          <Text>
            Display a link to the client's completed Safety Planning
            Intervention
          </Text>
        </Box>
        <Switch
          isDisabled={!isEditView}
          size="lg"
          isChecked={selectedClinic.show_client_safety_planning}
          onChange={() =>
            toggleShowSafetyPlanning({
              clinicId: selectedClinic.id,
              showClientSafetyPlanning: !selectedClinic.show_client_safety_planning
            })
          }
        />
      </Flex>
      <Box w="100%" mb="medium">
        <Flex justify="space-between" alignItems="center" flexDirection="row">
          <Heading as="h2" size="md">
            Crisis Resources
          </Heading>
          <Box>
            <Link
              _focus={{
                outline: 'none'
              }}
              onClick={() => openPreviewModal()}
              color={'primary'}
              p={0}
              ml={1}
            >
              Preview the Client Safety Net
            </Link>
          </Box>
        </Flex>
      </Box>
      {resources?.map((r: SupportResource) => (
        <Resource
          id={r.id}
          displayText={r.displayText}
          type={r.type}
          value={r.value}
          clinicId={selectedClinic.id}
        />
      ))}
      {!Boolean(resources?.length) && <Text>No crisis resources found.</Text>}
      {isAddingResource && (
        <ResourceForm
          onSave={() => setIsAddingResource(false)}
          clinicId={selectedClinic.id}
          onCancel={() => setIsAddingResource(false)}
        />
      )}
      {!isAddingResource && isEditView && (
        <Flex
          _hover={{ cursor: isAddingResource ? 'initial' : 'pointer' }}
          mt="small"
          mb="small"
          onClick={() => setIsAddingResource(!isAddingResource)}
        >
          <AddCircleIcon fill={isAddingResource ? 'gray' : 'primary'} />
          <Text color={isAddingResource ? 'gray' : 'primary'} ml="xxsmall">
            Add resource
          </Text>
        </Flex>
      )}
    </Box>
  )
}
