import React from 'react'
import {
  Select,
  HStack,
  Text,
  Box,
  Flex,
  CloseButton,
  VStack
} from '@chakra-ui/react'
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik'
import { AddCircleIcon } from '@blueprinthq/joy'
import { ClientStatusBuckets } from '@constants/clientStatusBuckets'
import { snakeToCapital } from '@utilities'

type Props = {
  errors: FormikErrors<any>
  resetForm: (values: object) => void
  constants: any
}

type FormikContext = {
  values: {
    clientStatuses: string[]
    clientStatusOperator: string | undefined
  }
  errors: FormikErrors<any>
}

export const ClientStatusFilter = ({
  resetForm,
  constants
}: Props) => {
  const { values, errors }: FormikContext = useFormikContext()
  const allStatuses = Object.values(ClientStatusBuckets)

  const options = () =>
    allStatuses.map(v => ({
      display: snakeToCapital(v),
      value: v
    }))

  return (
    <HStack spacing="small" align="flex-start" justify="flex-start" w="100%">
      <HStack spacing="xsmall" justify="flex-start" align="center" w="100%">
        <Text w="100px">Client status</Text>
        <Field name="clientStatusOperator">
          {({ field }: { field: { value: string } }) => (
            <Select
              {...field}
              value={field.value}
              borderColor={
                errors?.clientStatusOperator ? 'error' : 'light_gray'
              }
              color={field.value ? 'black' : 'gray'}
              w="90px"
              onChange={e => {
                resetForm({
                  ...values,
                  clientStatusOperator: e.target.value
                })
              }}
            >
              <option value="" disabled></option>
              {constants.operators.map(
                (o: { display: string; value: string }) => (
                  <option
                    key={`client-status-filter${o.value}`}
                    value={o.value}
                  >
                    {o.display}
                  </option>
                )
              )}
            </Select>
          )}
        </Field>
      </HStack>
      <VStack
        w="100%"
        spacing="small"
        display="flex"
        alignItems={values.clientStatuses[0] ? 'flex-end' : 'flex-start'}
      >
        <FieldArray
          name="clientStatuses"
          render={({ push, remove, replace }) => (
            <Box>
              {!Boolean(values.clientStatuses.length) && (
                <Field name="clientStatuses.0">
                  {({ field }: { field: { value: string } }) => (
                    <Select
                      {...field}
                      value={field.value}
                      borderColor={
                        errors?.clientStatuses ? 'error' : 'light_gray'
                      }
                      color={field.value ? 'black' : 'gray'}
                      mt="xsmall"
                    >
                      <option value="" disabled>
                        Choose status
                      </option>
                      {options().map(option => (
                        <option key={option.value} value={option.value}>
                          {option.display}
                        </option>
                      ))}
                    </Select>
                  )}
                </Field>
              )}
              {values?.clientStatuses?.map((type: string, index: number) => (
                <Flex mb="xsmall">
                  {index > 0 && (
                    <Text mt="xsmall" mr="xsmall">
                      or
                    </Text>
                  )}
                  <Field
                    name={`clientStatuses.${index}`}
                    key={`clientStatuses.${index}`}
                  >
                    {({ field }: { field: { value: string } }) => (
                      <Select
                        {...field}
                        borderColor={
                          errors?.clientStatuses && !type
                            ? 'error'
                            : 'light_gray'
                        }
                        color={field.value ? 'black' : 'gray'}
                        w={index > 0 ? '167px' : '190px'}
                      >
                        <option value="" disabled>
                          Choose status
                        </option>
                        {options().map(option => (
                          <option
                            key={option.value}
                            value={option.value}
                            disabled={values.clientStatuses.includes(
                              option.value
                            )}
                          >
                            {option.display}
                          </option>
                        ))}
                      </Select>
                    )}
                  </Field>
                  {type && (
                    <CloseButton
                      mb="xsmall"
                      ml="5px"
                      alignSelf={'flex-end'}
                      onClick={() => {
                        if (values.clientStatuses.length === 1) {
                          replace(0, '')
                        } else {
                          remove(index)
                        }
                      }}
                    />
                  )}
                </Flex>
              ))}
              {Boolean(values?.clientStatuses[0]) && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => push('')}
                  mt="xsmall"
                  mr="xsmall"
                >
                  <AddCircleIcon fill="primary" mr="5px" />
                  <Text color="primary">OR</Text>
                </Box>
              )}
            </Box>
          )}
        />
      </VStack>
    </HStack>
  )
}
