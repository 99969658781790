import React from 'react'
import {
  Stack,
  Text,
  Divider,
  HStack,
  Flex,
  Button,
  UnorderedList,
  ListItem,
  SkeletonText,
  Tooltip
} from '@chakra-ui/react'
import { useClinicControllerChangePlanPreview } from '~/clinician-api'
import { ChangePlanPreviewResponseDto } from '~/clinician-api/models'
import { DateTime } from 'luxon'
import { formatPrice } from '../utils'

interface SummaryItem {
  title: string
  description: string
}

const summaryItems: SummaryItem[] = [
  {
    title: 'Auto-Renewal',
    description: 'Your subscription will remain active unless you cancel.'
  },
  {
    title: 'Shared Sessions',
    description: 'Sessions can be used by any user on your account.'
  },
  {
    title: 'No Rollover',
    description: 'Sessions do not roll over from one month to the next.'
  },
  {
    title: 'Auto Upgrade',
    description:
      'Plans auto-upgrade to the next pricing tier if you reach your limit.'
  }
]

const Summary = ({
  clinicId,
  currentPlan,
  priceId,
  onSubmit,
  isSubmitting,
  isValid
}: {
  clinicId: string
  currentPlan: any
  priceId: string | null
  onSubmit: () => void
  isSubmitting: boolean
  isValid: boolean
}) => {
  const {
    data: planPreview = {
      newPlan: {},
      totalCents: 0,
      creditAmountCents: 0,
      notesRemaining: 0
    } as ChangePlanPreviewResponseDto,
    isLoading: isLoadingPlanPreview
  } = useClinicControllerChangePlanPreview(
    clinicId,
    {
      // @ts-ignore
      priceId
    },
    {
      query: {
        enabled: !!priceId
      }
    }
  )

  const isSamePlan = priceId === currentPlan.priceId

  const { newPlan, totalCents, creditAmountCents, notesRemaining } = planPreview

  return (
    <Stack
      spacing="0"
      gap="24px"
      px="32px"
      pt="48px"
      bg="rgba(45, 84, 232, 0.04)"
      flex={{ base: 1, sm: 1, md: '1' }}
      height={{ base: 'auto', sm: 'auto', md: 'calc(100vh - 80px)' }}
      mx={{ base: '-16px', sm: '-24px', md: '-32px' }}
      overflowY={{ base: 'auto', sm: 'auto', md: 'scroll' }}
      width={{ base: 'auto', sm: 'auto', md: '469px' }}
    >
      <Text fontWeight="bold">Summary</Text>
      <Divider />
      <Stack spacing={0}>
        <Text color="#757575" fontSize="13px">
          Current Plan
        </Text>
        <Text fontWeight="bold">
          {currentPlan?.isFree ? 'Blueprint Starter' : currentPlan.planName}
        </Text>
        {!currentPlan.isFree && (
          <>
            <HStack justifyContent="space-between">
              <Text>{currentPlan?.sessionCount} sessions per month</Text>
              <Text>Paid {formatPrice(currentPlan?.priceCents)}</Text>
            </HStack>
            {priceId && !isSamePlan && (
              <SkeletonText
                isLoaded={!isLoadingPlanPreview}
                noOfLines={1}
                skeletonHeight="3"
              >
                <HStack justifyContent="space-between">
                  <Text>Credit for {notesRemaining} unused sessions</Text>
                  {planPreview && (
                    <Text>-{formatPrice(creditAmountCents)}</Text>
                  )}
                </HStack>
              </SkeletonText>
            )}
          </>
        )}
      </Stack>
      {priceId && !isSamePlan ? (
        <>
          <Stack spacing={0}>
            <Text color="#757575" fontSize="13px">
              New Plan
            </Text>
            <SkeletonText
              isLoaded={!isLoadingPlanPreview}
              noOfLines={2}
              skeletonHeight="3"
            >
              <Text fontWeight="bold">{newPlan.planName}</Text>
              <HStack justifyContent="space-between">
                <Text>{newPlan.sessionCount} sessions per month</Text>
                <Text>{formatPrice(newPlan.priceCents)}</Text>
              </HStack>
            </SkeletonText>
          </Stack>
          <Divider />
          <SkeletonText
            isLoaded={!isLoadingPlanPreview}
            noOfLines={1}
            skeletonHeight="3"
          >
            <HStack justifyContent="space-between">
              <Text fontWeight="bold">
                {totalCents >= 0 ? 'Total due now' : 'Total refund now'}
              </Text>
              <Text fontWeight="bold">
                {formatPrice(Math.abs(planPreview?.totalCents))}
              </Text>
            </HStack>
          </SkeletonText>
          <Divider />
          <SkeletonText
            isLoaded={!isLoadingPlanPreview}
            noOfLines={1}
            skeletonHeight="3"
          >
            <Text>
              You will be billed {formatPrice(newPlan.priceCents)} at your next
              renewal on{' '}
              {DateTime.now()
                .plus({ months: 1 })
                .toFormat('MMM dd, yyyy')}
            </Text>
          </SkeletonText>
        </>
      ) : (
        <Divider />
      )}
      <Stack pb="48px" gap="24px">
        <Flex justifyContent="center" flex="1">
          <Tooltip
            label={
              isSamePlan
                ? 'Please select a different tier.'
                : !priceId
                ? 'Please select a tier'
                : ''
            }
            shouldWrapChildren
          >
            <Button
              size="lg"
              w={{ base: '100%', sm: '100%', md: 'auto' }}
              maxW={{ md: '215px' }}
              onClick={onSubmit}
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
            >
              Update subscription
            </Button>
          </Tooltip>
        </Flex>
        <Stack maxW={{ base: 'none', md: '405px' }}>
          <UnorderedList>
            {summaryItems.map(({ title, description }, index) => (
              <ListItem key={index}>
                <Text lineHeight="16px" fontSize="12px">
                  <Text as="b" fontSize="inherit">
                    {title}:{' '}
                  </Text>
                  {description}
                </Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Summary
