import React, { useMemo } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { usePermissions } from '@hooks'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useQuery } from 'react-query'
import { Page, HorizontalFilterBar, CompletionCount } from '../components'
import { OrganizationSelector, DateRangePicker } from '../components/filters'
import { AgeRangeSelector } from '../components/age-range-selector'
import { ClinicianSelector } from '../components/clinician-selector'
import ClinicianBreakdownTable from './clinician-breakdown-table'
import ClientSatisfactionPieChart from './pie-chart'
import { endpoints } from '@api'
import ClinicSelector from '../components/clinic-selector'

const Header = () => {
  const { hasPermission } = usePermissions()
  const { organizationId, clinicId } = useStoreState(
    state => state.clientSatisfaction.filters
  )
  const { updateFilters } = useStoreActions(
    actions => actions.clientSatisfaction
  )

  const handleOrganizationChange = organizationId =>
    updateFilters({ organizationId })

  const handleClinicChange = clinicId => updateFilters({ clinicId })

  return (
    <Flex my="3" justify="space-between" alignItems="center">
      <Flex alignItems="center" flex="1">
        <Heading my="5">Client Satisfaction</Heading>
      </Flex>
      <Flex flexWrap="wrap">
        {hasPermission('read:any:*:*') && (
          <Box w="275px" mr="3">
            <OrganizationSelector
              organizationId={organizationId}
              onChange={handleOrganizationChange}
            />
          </Box>
        )}
        {hasPermission('read:org:reports:outcomes') && (
          <Box w="275px">
            <ClinicSelector
              organizationId={organizationId}
              clinicId={clinicId}
              onChange={handleClinicChange}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

const FilterBar = () => {
  const { hasPermission } = usePermissions()
  const {
    organizationId,
    clinicId,
    clinicianId,
    ages,
    fromTreatmentStartDate,
    toTreatmentStartDate
  } = useStoreState(state => state.clientSatisfaction.filters)

  const { updateFilters } = useStoreActions(
    actions => actions.clientSatisfaction
  )

  const handleClearAllFilters = () => {
    updateFilters({
      clinicianId: null,
      ages: [],
      startDate: null,
      endDate: null
    })
  }

  const handleClinicianChange = clinicianId => updateFilters({ clinicianId })

  const handleAgeChange = ages => updateFilters({ ages })

  const handleFromTreatmentStartDateChange = date =>
    updateFilters({ fromTreatmentStartDate: date })

  const handleToTreatmentStartDateChange = date =>
    updateFilters({ toTreatmentStartDate: date })

  const datePickerHelperText =
    'Enabling this filter only shows clients that completed their first assessment within the date range selected.'

  return (
    <HorizontalFilterBar onClearAll={handleClearAllFilters}>
      {hasPermission('read:clinic:reports:outcomes') && (
        <ClinicianSelector
          organizationId={organizationId}
          clinicIds={[clinicId]}
          clinicianId={clinicianId}
          onChange={handleClinicianChange}
        />
      )}
      <AgeRangeSelector ages={ages} onChange={handleAgeChange} />
      <DateRangePicker
        label="Treatment Start"
        fromDate={fromTreatmentStartDate}
        toDate={toTreatmentStartDate}
        fromLabel="From"
        toLabel="To"
        onFromDateChange={handleFromTreatmentStartDateChange}
        onToDateChange={handleToTreatmentStartDateChange}
        helperText={datePickerHelperText}
      />
    </HorizontalFilterBar>
  )
}

const ClientSatisfaction = () => {
  const {
    filters: {
      clinicId,
      clinicianId,
      ages,
      fromTreatmentStartDate,
      toTreatmentStartDate
    }
  } = useStoreState(state => state.clientSatisfaction)

  const query = useMemo(() => {
    if (clinicId) {
      return {
        clinic_ids: [clinicId],
        age_range: ages,
        clinician_ids: [clinicianId],
        treatment_start_date: fromTreatmentStartDate,
        treatment_end_date: toTreatmentStartDate
      }
    } else {
      return null
    }
  }, [
    clinicId,
    clinicianId,
    ages,
    fromTreatmentStartDate,
    toTreatmentStartDate
  ])

  const { data, isFetching, isLoading } = useQuery(
    [endpoints.getClientSatisfaction.getCacheId(), JSON.stringify(query)],
    () => endpoints.getClientSatisfaction.request(query),
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
      placeholderData: {
        clientCount: 0,
        assessmentCount: 0,
        responseBreakdown: [],
        clinicianPerformance: []
      }
    }
  )

  const pieChartData = useMemo(
    () =>
      data.responseBreakdown.map(response => ({
        label: response.label,
        value: (response.count / data.assessmentCount) * 100
      })),
    [data]
  )

  const dataLoaded = !isFetching && !isLoading

  const emptyState = (
    <Flex justifyContent="center" alignItems="center" h={300}>
      <Text color="#757575">No client satisfaction results found</Text>
    </Flex>
  )

  return (
    <Page>
      <Header />
      <FilterBar />
      <Box>
        <CompletionCount
          isLoaded={dataLoaded}
          assessmentCount={data.assessmentCount}
          clientCount={data.clientCount}
        />
        <ClientSatisfactionPieChart
          data={pieChartData}
          isLoading={!dataLoaded}
          emptyState={emptyState}
        />
        <ClinicianBreakdownTable
          data={data.clinicianPerformance}
          isLoading={!dataLoaded}
          emptyState={emptyState}
        />
      </Box>
    </Page>
  )
}

export { ClientSatisfaction }
