import React, { useState } from 'react'

import { Box } from '@chakra-ui/react'

import { NavBar } from './nav-bar'
import SelectClient from '../../start-session/select-client'
import { SelectClient as SelectClientV2 } from './select-client'
import { AudioCaptureProvider } from '@context'

export const WidgetStartSession = () => {
  const [sessionId, setSessionId] = useState<string | undefined>()

  return (
    <Box
      bg="white"
      display="flex"
      flexDirection="column"
      height="100%"
      overflowY="auto"
    >
      <NavBar />
      <AudioCaptureProvider sessionId={sessionId}>
        <SelectClientV2 sessionId={sessionId} setSessionId={setSessionId} />
      </AudioCaptureProvider>
    </Box>
  )
}
