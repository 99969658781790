import React from 'react'
import { Link } from 'react-router-dom'
import { Text, Grid, GridItem, Flex } from '@chakra-ui/react'
import {
  AutocompleteSuggestionList,
  AutocompleteSuggestionListItem
} from '@blueprinthq/joy'
import moment from 'moment'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {
  ClientStatusBuckets,
  reportUnrecognizedStatus
} from '@constants/clientStatusBuckets'
import { getClientStatusLabel } from '@utilities'
import { useExperienceManager } from '@hooks'

const StatusPill = ({ status }) => {
  const { isEvidenceBasedCareEnabled } = useExperienceManager()
  const bucket = Object.keys(ClientStatusBuckets).find(
    b => ClientStatusBuckets[b] === status
  )

  if (bucket === undefined) {
    reportUnrecognizedStatus(status)
  }

  let textColor
  let pillColor

  switch (status) {
    case ClientStatusBuckets.ACTIVE:
      textColor = 'white'
      pillColor = '#2D54E8'
      break
    case ClientStatusBuckets.ARCHIVED:
      textColor = 'white'
      pillColor = '#282828'
      break
    default:
      textColor = 'black'
      pillColor = '#E4E5E6'
      break
  }

  return (
    <Text
      ms="xsmall"
      px="small"
      py="xxsmall"
      d="inline"
      backgroundColor={pillColor}
      casing="uppercase"
      color={textColor}
      fontWeight="bold"
      letterSpacing="widest"
      fontSize="small"
      borderRadius="full"
    >
      {getClientStatusLabel(status, isEvidenceBasedCareEnabled)}
    </Text>
  )
}

export const SearchInputSuggestionList = ({
  suggestions,
  getItemProps,
  highlightedIndex,
  ...rest
}) => {
  const { isEvidenceBasedCareEnabled } = useExperienceManager()
  return (
    <AutocompleteSuggestionList
      sx={{
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px'
      }}
      {...rest}
    >
      {suggestions.map((suggestion, index) => {
        return (
          <AutocompleteSuggestionListItem
            as={Link}
            to={`/patient/${suggestion.id}`}
            key={index}
            highlightedIndex={highlightedIndex}
            index={index}
            isLast={suggestions.length - 1 === index}
            {...getItemProps({ item: suggestion, index })}
            sx={{
              '*': {
                fontFamily: 'TofinoPersonal'
              },
              '* mark': {
                backgroundColor: 'transparent',
                fontWeight: 'bold'
              }
            }}
          >
            <Grid w="100%">
              <GridItem>
                <Flex w="100%" justify="space-between">
                  <Text fontWeight="bold">{suggestion.name}</Text>
                  {suggestion && suggestion.status && (
                    <StatusPill status={suggestion.status} />
                  )}
                </Flex>
              </GridItem>
              {isEvidenceBasedCareEnabled && (
                <GridItem>
                  <Text>
                    {`${moment
                      .utc(suggestion.date_of_birth)
                      .format('MM/DD/YYYY')} `}
                    <Text as="span" color="#767676">{`(Age ${moment().diff(
                      moment.utc(suggestion.date_of_birth),
                      'years',
                      false
                    )})`}</Text>
                  </Text>
                </GridItem>
              )}
              <GridItem>
                <Text>{suggestion.email}</Text>
              </GridItem>
              <GridItem>
                <Text>
                  {suggestion.phone_number &&
                    parsePhoneNumberFromString(
                      suggestion.phone_number
                    ).formatNational()}
                </Text>
              </GridItem>
            </Grid>
          </AutocompleteSuggestionListItem>
        )
      })}
    </AutocompleteSuggestionList>
  )
}
