import React from 'react'
import {
  Text,
  Flex,
  Box,
  VStack,
  Divider,
  Stack,
  HStack,
  Collapse,
  Button
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { SessionRecapResponse } from '~/clinician-api/models'
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Note, Compass } from '@components/icons'
import { isEmpty } from 'lodash'
import { useExperienceManager } from '@hooks'

interface RecapLastSessionProps {
  clientId: string
  recap: SessionRecapResponse | undefined
}

type CollapsibleComponentProps = {
  icon?: React.ReactNode
  title: string
  children: React.ReactNode
  isEmpty?: boolean
  emptyText?: string
}

const CollapsibleComponent: React.FC<CollapsibleComponentProps> = props => {
  const [show, setShow] = React.useState(!props.isEmpty || false)

  const handleToggle = () => {
    setShow(!show)
  }

  return (
    <Stack gap="10px">
      <Button
        _focus={{ outline: 'none' }}
        variant="unstyled"
        onClick={handleToggle}
        isDisabled={props.isEmpty}
        _disabled={{ opacity: 1, cursor: 'default' }}
      >
        <HStack gap="small">
          <Flex>{props.icon}</Flex>
          <HStack
            style={{ marginLeft: 0 }}
            width="100%"
            lineHeight="48px"
            justifyContent="space-between"
          >
            <Text fontWeight="bold">{props.title}</Text>
            {props.isEmpty ? (
              <Text color="medium_gray">{props.emptyText}</Text>
            ) : show ? (
              <ArrowUpIcon />
            ) : (
              <ArrowDownIcon />
            )}
          </HStack>
        </HStack>
      </Button>
      <Collapse in={show}>
        <Flex>{props.children}</Flex>
      </Collapse>
    </Stack>
  )
}

const LastSessionProgressNote = (props: any) => {
  const { isDocumentationAutomationEnabled } = useExperienceManager()

  if (!isDocumentationAutomationEnabled) {
    return null
  }

  const { progressNote } = props

  return (
    <CollapsibleComponent
      isEmpty={
        !progressNote || isEmpty(progressNote?.note) || progressNote?.deletedAt
      }
      emptyText="No progress note"
      title="Progress Note"
      icon={<Note />}
    >
      <Stack width="100%" gap="small">
        {progressNote?.template?.sections?.map(
          ({ key, displayName }: { key: string; displayName: string }) => (
            <Stack>
              <Text fontWeight="bold">{displayName}</Text>
              <Flex
                key={key}
                border="1px solid"
                borderColor="pale_gray"
                p="small"
                borderRadius="md"
              >
                {progressNote?.note[key] ? (
                  <Text>{progressNote.note[key]}</Text>
                ) : (
                  <Text color="dark_gray">No {displayName} note</Text>
                )}
              </Flex>
            </Stack>
          )
        )}
      </Stack>
    </CollapsibleComponent>
  )
}

export const RecapLastSession = ({
  recap,
  clientId
}: RecapLastSessionProps) => {
  const { isDocumentationAutomationEnabled } = useExperienceManager()

  const summary = recap?.previousSession?.sessionSummary?.summary

  return (
    <Box w="100%">
      <Text color="gray">
        Review the interventions and topics that were covered last session
      </Text>
      <Stack
        my="small"
        gap="18px"
        border="1px solid"
        borderColor="pale_gray"
        borderRadius="md"
        p="small"
        divider={<Divider style={{ margin: 0 }} />}
      >
        {isDocumentationAutomationEnabled && (
          <CollapsibleComponent
            isEmpty={!summary}
            title="Session Summary"
            emptyText="No session summary"
            icon={<Note />}
          >
            <Stack w="100%">
              <Text whiteSpace="pre-wrap">{summary}</Text>
            </Stack>
          </CollapsibleComponent>
        )}
        <CollapsibleComponent
          title="Interventions"
          isEmpty={!recap?.previousSession?.completedInterventions?.length}
          emptyText="No interventions"
          icon={<Compass />}
        >
          <Flex
            border="1px solid"
            borderColor="pale_gray"
            w="100%"
            borderRadius="lg"
          >
            <VStack w="100%" spacing="none" divider={<Divider />}>
              {recap?.previousSession?.completedInterventions.map(i => {
                return (
                  <Flex justify="space-between" p="medium" w="100%">
                    <Flex flex={1} align="center">
                      <Text lineHeight="40px">{i.intervention.name}</Text>
                    </Flex>
                    {i.intervention.isGuided && (
                      <Button
                        as={Link}
                        variant="outline"
                        target="_blank"
                        to={{
                          pathname: `/patient/${clientId}/interventions/${i.intervention.id}/form`,
                          search: queryString.stringify({
                            clientInterventionId: i.id
                          })
                        }}
                      >
                        View
                      </Button>
                    )}
                  </Flex>
                )
              })}
            </VStack>
          </Flex>
        </CollapsibleComponent>
        <CollapsibleComponent
          isEmpty={!recap?.previousSession?.psychotherapyNote}
          emptyText="No psychotherapy note"
          title="Psychotherapy Note"
          icon={<Note />}
        >
          <Flex
            p="small"
            bgColor="#FEFAF3"
            border="1px solid"
            borderColor="pale_gray"
            borderRadius="8px"
          >
            {recap?.previousSession?.psychotherapyNote}
          </Flex>
        </CollapsibleComponent>
        <LastSessionProgressNote
          progressNote={recap?.previousSession?.progressNote}
        />
      </Stack>
    </Box>
  )
}
