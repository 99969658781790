import React from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  GridItem,
  Tooltip,
  Skeleton,
  Text
} from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import { Warning } from '@components/icons'
import { Loading } from '@components'
import { COLORS } from '../constants'

const Section = ({ isLoading, children }) => (
  <Box
    sx={{
      position: 'relative',
      borderRadius: 8,
      mb: 'large',
      backgroundColor: 'white'
    }}
  >
    <Box p="5">
      {isLoading && (
        <Flex
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute'
          }}
        >
          <Loading />
        </Flex>
      )}
      {children}
    </Box>
  </Box>
)

const ChartContainer = ({ children }) => {
  return (
    <Flex
      direction="column"
      sx={{
        width: '100%',
        height: 380
      }}
    >
      {children}
    </Flex>
  )
}

const Page = ({ children }) => {
  return (
    <Box bg="#F5F5F7" pb="xxlarge" minH="100vh">
      <Container>
        <LayoutGrid>
          <GridItem colSpan={12}>
            <Flex direction="column" flex="1">
              {children}
            </Flex>
          </GridItem>
        </LayoutGrid>
      </Container>
    </Box>
  )
}

const HorizontalFilterBar = ({ children, onClearAll, sx }) => {
  return (
    <Box
      bg="white"
      borderRadius="md"
      w="100%"
      display="flex"
      py="2"
      px="5"
      alignItems="center"
      sx={{
        overflow: 'auto',
        ...sx
      }}
    >
      {children}
      <Box ml="auto">
        <Button colorScheme="blue" variant="link" w="100%" onClick={onClearAll}>
          Clear filters
        </Button>
      </Box>
    </Box>
  )
}

const InfoTooltip = ({ text }) => {
  return (
    <Tooltip label={text} maxWidth="300px" placement="bottom-start" hasArrow>
      <Box>
        <Warning width="20px" height="20px" fill={COLORS.GRAY} />
      </Box>
    </Tooltip>
  )
}

const CompletionCount = ({ isLoaded, assessmentCount, clientCount }) => {
  return (
    <Skeleton isLoaded={isLoaded}>
      <Box my="small">
        <Text fontSize="lg">
          Completed{' '}
          <Box as="span" sx={{ fontWeight: 'bold' }}>
            {assessmentCount.toLocaleString()}{' '}
          </Box>
          times by{' '}
          <Box as="span" sx={{ fontWeight: 'bold' }}>
            {clientCount.toLocaleString()}{' '}
          </Box>
          clients
        </Text>
      </Box>
    </Skeleton>
  )
}

export {
  Page,
  HorizontalFilterBar,
  InfoTooltip,
  ChartContainer,
  Section,
  CompletionCount
}
