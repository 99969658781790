import React, { useCallback } from 'react'
import { Box, HStack, Text, Link } from '@chakra-ui/react'
import { usePdfDownloader } from '@hooks'
import { useStoreActions } from 'easy-peasy'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import moment from 'moment'
export const DownloadPdfButton = ({
  interventionName,
  clientInterventionId,
  interventionId,
  patient
}) => {
  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )
  const { download: downloadIntervention } = usePdfDownloader({
    url: `${process.env.REACT_APP_NODE_API_ROOT_URL}/clinicians/clients/${patient.id}/interventions/${interventionId}/submissions/${clientInterventionId}/download`,
    pdfName: `${patient.last_name}_${
      patient.first_name
    }_${interventionName.replaceAll(' ', '_')}_${moment().format('MMDDYYYY')}`
  })

  const downloadClientIntervention = useCallback(async () => {
    setSnackbarMessage({
      variant: 'info',
      message: 'Downloading...',
      duration: 10000,
      position: 'bottom-left'
    })
    try {
      await downloadIntervention()
      setSnackbarMessage({
        variant: 'success',
        message: 'Finished Download!',
        duration: 3000,
        position: 'bottom-left'
      })
    } catch (e) {
      console.log(e)
      setSnackbarMessage({
        variant: 'error',
        message: 'Error downloading PDF!',
        duration: 3000,
        position: 'bottom-left'
      })
    }
  })

  return (
    <Link
      id={`pendo-intervention-view-link-tag-${interventionId}`}
      as={RouterLink}
      color="primary"
      onClick={() => downloadClientIntervention()}
      ml={!isMobile ? '24px' : '0px'}
    >
      <Text fontSize={'16px'}>Download PDF</Text>
    </Link>
  )
}
