import React from 'react'
import { Formik, Field, Form } from 'formik'
import { Box, VStack, HStack, Text } from '@chakra-ui/react'
import { TextField, Button, Select } from '@blueprinthq/joy'
import * as Yup from 'yup'
import { useExperienceManager } from '@hooks'

const ProfileSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string()
    .email()
    .required('Required'),
  display_name: Yup.string()
    .nullable()
    .optional(),
  license_level: Yup.object()
    .shape({
      id: Yup.string(),
      display: Yup.string()
    })
    .required()
})

export const ProfileForm = ({ account, licenseLevels, onSubmit }) => {
  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  return (
    <Formik
      initialValues={{
        first_name: account.first_name,
        last_name: account.last_name,
        email: account.email,
        display_name: account.display_name,
        license_level: account.license_level
          ? licenseLevels.find(level => level.id === account.license_level)
          : null
      }}
      enableReinitialize
      validationSchema={ProfileSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, isValid, dirty }) => (
        <Form>
          <VStack alignItems="flex-start" spacing="small">
            <Text as="b">Full name</Text>
            <HStack spacing="large" width="100%">
              <Field name="first_name">
                {({ field }) => (
                  <TextField
                    {...field}
                    isDisabled={account.organization.isDirectorySync}
                    label="First name"
                    errorText={errors.first_name}
                    isInvalid={errors.first_name && touched.first_name}
                    isRequired
                  />
                )}
              </Field>
              <Field name="last_name">
                {({ field }) => (
                  <TextField
                    {...field}
                    isDisabled={account.organization.isDirectorySync}
                    label="Last name"
                    errorText={errors.last_name}
                    isInvalid={errors.last_name && touched.last_name}
                    isRequired
                  />
                )}
              </Field>
            </HStack>

            <Text as="b">Email</Text>
            <Field name="email">
              {({ field }) => (
                <TextField
                  {...field}
                  isDisabled={account.organization.isDirectorySync}
                  label="Email"
                  errorText={errors.email}
                  isInvalid={errors.email && touched.email}
                  isRequired
                />
              )}
            </Field>
            {isEvidenceBasedCareEnabled && (
              <>
                <Text>
                  <b>Preferred name</b> - What clients call you
                </Text>
                <Field name="display_name">
                  {({ field }) => (
                    <TextField
                      mt="0"
                      {...field}
                      label="What do clients call you?"
                      errorText={errors.display_name}
                      isInvalid={errors.display_name && touched.display_name}
                    />
                  )}
                </Field>
              </>
            )}
            <Text as="b">License type</Text>
            <Field name="license_level">
              {({ field, form }) => (
                <Select
                  {...field}
                  onChange={value => form.setFieldValue('license_level', value)}
                  options={licenseLevels}
                  valueKey="id"
                  labelKey="display"
                  label=""
                  isInvalid={errors.license_level && touched.license_level}
                  errorText={errors.license_level}
                />
              )}
            </Field>

            <Box>
              <Button
                isDisabled={
                  !isValid || !dirty || account.organization.isSSOEnabled
                }
                mt="xsmall"
                size="lg"
                isLoading={isSubmitting}
                type="submit"
              >
                Save
              </Button>
            </Box>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}
