import React from 'react'
import { VStack, Text, Stack, Container } from '@chakra-ui/react'
import { CoolKidsFeedback } from '../svgs'

const EmptyPrep = ({ isFirstSession }: { isFirstSession: boolean }) => (
  <Container>
    <Stack flex="1" h="calc(100vh - 208px)" justifyContent="center">
      <VStack h="232px">
        <CoolKidsFeedback />
        <Text textAlign="center" fontWeight="bold" mb="xxsmall">
          {isFirstSession ? 'No prep insights yet' : 'No prep available'}
        </Text>
        <Text color="dark_gray" maxWidth="400px">
         {isFirstSession ? (
          "Complete your first progress note to see AI-powered insights and reminders before every session!" 
         ) : (
          "We don’t have any prep materials yet."
         )}
        </Text>
      </VStack>
    </Stack>
  </Container>
)

export default EmptyPrep
