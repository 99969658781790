import React, { useEffect } from 'react'
import {
  Flex,
  Divider,
  Container,
  Heading,
  Text,
  Box,
  ListItem,
  UnorderedList,
  VStack,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { useQuery, useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { endpoints } from '@api'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Select, Button, Checkbox } from '@blueprinthq/joy'

export const DeleteV2 = () => {
  const { user } = useStoreState(state => state.auth)
  const history = useHistory()
  const toast = useToast()

  const { data: billing, isFetching: isBillingLoading } = useQuery(
    [endpoints.getClinicBilling.getCacheId(), user.clinic && user.clinic.id],
    () =>
      endpoints.getClinicBilling.request({
        clinicId: user.clinic.id
      }),
    {
      initialData: [],
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        // default to the currently selected clinic, otherwise use the user's clinic
        return data
      }
    }
  )

  const logout = useStoreActions(actions => actions.auth.logout)
  const { data: deletionReasons } = useQuery(
    [endpoints.getAccountDeletionReasons.getCacheId()],
    endpoints.getAccountDeletionReasons.request,
    {
      initialData: []
    }
  )

  const { mutateAsync: deleteAccount } = useMutation(
    endpoints.postDeleteAccount.request,
    {
      onSuccess() {
        logout()
      },
      onError(err) {
        toast({
          title: 'We ran into an issue deleting your account.',
          description: `Please try again or contact support at ${process.env.REACT_APP_HELP_EMAIL}.`,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  useEffect(() => {
    if (!isBillingLoading && billing) {
      if (!billing?.canCancel) {
        history.push('/dashboard')
      }
    }
  }, [billing])

  const handleSubmit = async values => {
    await deleteAccount(values)
  }

  return (
    <Container
      maxWidth="672px"
      overflowY="clip"
      height="100%"
      centerContent
      paddingTop="40px"
      paddingLeft={{
        base: 'medium'
      }}
      paddingRight={{
        base: 'medium'
      }}
    >
      <Flex flexDirection="column" w="100%" gap="24px">
        <Flex flexDirection="column" gap="32px">
          <Heading fontSize="24px">Delete your Blueprint account</Heading>
          <Divider />
          <Flex flexDirection="column" gap="8px">
            <Text as="b">When you delete your Blueprint account...</Text>
            <UnorderedList>
              <ListItem>
                All clinician accounts will be automatically deleted.
              </ListItem>
              <ListItem>
                All enrolled clients will be automatically deleted.
              </ListItem>
              <ListItem>
                You will no longer be able to log in to the Blueprint portal.
              </ListItem>
              <ListItem>
                You will no longer be billed (unless you have
                outstanding/overdue invoices).
              </ListItem>
            </UnorderedList>
          </Flex>
        </Flex>
        <Formik
          style={{ width: '100%' }}
          initialValues={{
            cancellation_reason: null
          }}
          onSubmit={async values => {
            await handleSubmit(values)
          }}
          validationSchema={Yup.object().shape({
            cancellation_reason: Yup.object()
              .shape({
                id: Yup.string().required('Deletion Reason is required')
              })
              .required('Deletion Reason is required')
          })}
        >
          {({ values, errors, touched, isValid, isSubmitting }) => (
            <Form style={{ width: '100%' }}>
              <VStack align="left" gap="24px" spacing="0">
                <VStack align="left" spacing="0" gap="8px" w="100%">
                  <Text as="b">Why are you deleting your account?</Text>
                  <Field name="cancellation_reason">
                    {({ field, form }) => (
                      <Select
                        {...field}
                        onChange={value =>
                          form.setFieldValue('cancellation_reason', value)
                        }
                        options={deletionReasons}
                        valueKey="id"
                        labelKey="display"
                        label="Select a reason"
                        isRequired
                        isInvalid={
                          errors.cancellation_reason &&
                          touched.cancellation_reason
                        }
                        errorText={'Deletion reason is required'}
                      />
                    )}
                  </Field>
                </VStack>
                <VStack align="left" spacing="0" gap="8px" w="100%">
                  <Text as="b">
                    Do you have any other feedback for our team on how we can
                    improve Blueprint?
                  </Text>
                  <Field name="cancellation_feedback">
                    {({ form }) => (
                      <Textarea
                        placeholder="Leave feedback..."
                        _placeholder={{ color: 'medium_gray' }}
                        borderColor="light_gray"
                        _focus={{
                          outline: 'none'
                        }}
                        onChange={e =>
                          form.setFieldValue(
                            'cancellation_feedback',
                            e.target.value
                          )
                        }
                      ></Textarea>
                    )}
                  </Field>
                </VStack>
                <Field name="confirmation">
                  {({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={values.confirmation}
                      isRequired
                    >
                      I understand that deleting my account is permanent and
                      cannot be reversed.
                    </Checkbox>
                  )}
                </Field>
                <Flex gap="16px">
                  <Button
                    variant="outline"
                    size="lg"
                    m="0"
                    onClick={() => history.push('/')}
                  >
                    Cancel
                  </Button>
                  <Button
                    m="0"
                    bg="#EB5164"
                    size="lg"
                    isLoading={isSubmitting}
                    isDisabled={
                      isSubmitting ||
                      isBillingLoading ||
                      !values.confirmation ||
                      !isValid
                    }
                    type="submit"
                  >
                    Permanently delete account
                  </Button>
                </Flex>
              </VStack>
            </Form>
          )}
        </Formik>
      </Flex>
    </Container>
  )
}
