import React from 'react'
import {
  Select,
  HStack,
  Text,
  Box,
  Flex,
  CloseButton,
  VStack
} from '@chakra-ui/react'
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { AddCircleIcon } from '@blueprinthq/joy'
import { Loading } from '@components'
import { endpoints } from '@api'

type Props = {
  errors: FormikErrors<any>
  resetForm: (values: object) => void
  constants: any
}

type RouteParams = {
  workflowId: string
  organizationId: string
}

type FormikContext = {
  values: {
    programAssignments: string[]
    programAssignmentOperator: string | undefined
  }
  errors: FormikErrors<any>
}

export const ProgramAssignmentFilter = ({
  resetForm,
  constants
}: Props) => {
  const { values, errors }: FormikContext = useFormikContext()
  const { organizationId } = useParams<RouteParams>()

  const { data, isLoading } = useQuery(
    [endpoints.getPrograms.getCacheId(), organizationId],
    () =>
      endpoints.getPrograms.request({
        organizationId
      })
  )

  if (isLoading) return <Loading />

  const allPrograms = data?.programs || []

  const options = allPrograms.map(p => ({
    display: p.name,
    value: p.id
  }))

  return (
    <HStack spacing="small" align="flex-start" justify="flex-start" w="100%">
      <HStack spacing="xsmall" justify="flex-start" align="center" w="100%">
        <Text w="100px">Program</Text>
        <Field name="programAssignmentOperator">
          {({ field }: { field: { value: string } }) => (
            <Select
              {...field}
              value={field.value}
              borderColor={
                errors?.programAssignmentOperator ? 'error' : 'light_gray'
              }
              color={field.value ? 'black' : 'gray'}
              w="90px"
              onChange={e => {
                resetForm({
                  ...values,
                  programAssignmentOperator: e.target.value
                })
              }}
            >
              <option value="" disabled></option>
              {constants.operators.map(
                (o: { display: string; value: string }) => (
                  <option key={o.value} value={o.value}>
                    {o.display}
                  </option>
                )
              )}
            </Select>
          )}
        </Field>
      </HStack>
      <VStack
        w="100%"
        spacing="small"
        display="flex"
        alignItems={values.programAssignments[0] ? 'flex-end' : 'flex-start'}
      >
        <FieldArray
          name="programAssignments"
          render={({ push, remove, replace }) => (
            <Box>
              {!Boolean(values.programAssignments.length) && (
                <Field name="programAssignments.0">
                  {({ field }: { field: { value: string } }) => (
                    <Select
                      {...field}
                      value={field.value}
                      borderColor={
                        errors?.programAssignments ? 'error' : 'light_gray'
                      }
                      color={field.value ? 'black' : 'gray'}
                      mt="xsmall"
                    >
                      <option value="" disabled>
                        Choose program
                      </option>
                      {options.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.display}
                        </option>
                      ))}
                    </Select>
                  )}
                </Field>
              )}
              {values?.programAssignments?.map(
                (type: string, index: number) => (
                  <Flex key={`${type}-${index}`}>
                    {index > 0 && (
                      <Text mt="xsmall" mr="xsmall">
                        or
                      </Text>
                    )}
                    <Field
                      name={`programAssignments.${index}`}
                      key={`programAssignments.${index}`}
                    >
                      {({ field }: { field: { value: string } }) => (
                        <Select
                          {...field}
                          borderColor={
                            errors?.programAssignments && !type
                              ? 'error'
                              : 'light_gray'
                          }
                          color={field.value ? 'black' : 'gray'}
                          mb="xsmall"
                          w={index > 0 ? '167px' : '190px'}
                        >
                          <option value="" disabled>
                            Choose program
                          </option>
                          {options.map(option => (
                            <option
                              key={option.value}
                              value={option.value}
                              disabled={values.programAssignments.includes(
                                option.value
                              )}
                            >
                              {option.display}
                            </option>
                          ))}
                        </Select>
                      )}
                    </Field>
                    {type && (
                      <CloseButton
                        mb="xsmall"
                        ml="5px"
                        alignSelf={'start'}
                        onClick={() => {
                          if (values.programAssignments.length === 1) {
                            replace(0, '')
                          } else {
                            remove(index)
                          }
                        }}
                      />
                    )}
                  </Flex>
                )
              )}
              {Boolean(values?.programAssignments?.[0]) && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => push('')}
                  mt="xsmall"
                  mr="xsmall"
                >
                  <AddCircleIcon fill="primary" mr="5px" />
                  <Text color="primary">OR</Text>
                </Box>
              )}
            </Box>
          )}
        />
      </VStack>
    </HStack>
  )
}
