import React from 'react'

const Info = ({ fill = 'white', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0.333374C3.32 0.333374 0.333336 3.32004 0.333336 7.00004C0.333336 10.68 3.32 13.6667 7 13.6667C10.68 13.6667 13.6667 10.68 13.6667 7.00004C13.6667 3.32004 10.68 0.333374 7 0.333374ZM7.66667 6.33337V10.3334H6.33334V6.33337H7.66667ZM7.66667 5.00004V3.66671H6.33334V5.00004H7.66667Z"
        fill={fill}
      />
    </svg>
  )
}

export default Info
