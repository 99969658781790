import React from 'react'

export const ThumbDownIcon = ({ fill = '#A1A1A1', size = "16" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 2H3.99984C3.4465 2 2.97317 2.33333 2.77317 2.81333L0.759837 7.51333C0.699837 7.66667 0.666504 7.82667 0.666504 8V9.33333C0.666504 10.0667 1.2665 10.6667 1.99984 10.6667H6.2065L5.57317 13.7133L5.55317 13.9267C5.55317 14.2 5.6665 14.4533 5.8465 14.6333L6.55317 15.3333L10.9465 10.94C11.1865 10.7 11.3332 10.3667 11.3332 10V3.33333C11.3332 2.6 10.7332 2 9.99984 2ZM9.99984 10L7.1065 12.8933L7.99984 9.33333H1.99984V8L3.99984 3.33333H9.99984V10ZM12.6665 2H15.3332V10H12.6665V2Z"
        fill={fill}
      />
    </svg>
  )
}
