import React, { useMemo } from 'react'
import {
  TableContainer,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Box,
  Flex,
  Text
} from '@chakra-ui/react'
import { DateTime } from 'luxon'

import { SessionRecapResponse } from '~/clinician-api/models'
import { rotateArray } from '@utilities'
import { Mobile } from '@components/icons'

const correlates = [
  {
    type: 'positivity',
    display: 'Mood'
  },
  {
    type: 'energy',
    display: 'Energy'
  },
  {
    type: 'sleep',
    display: 'Sleep'
  },
  {
    type: 'social',
    display: 'Social'
  }
]

function Indicator({ value }: any) {
  let color = '#E4E5E6' // gray

  if (value !== null) {
    if (value < -1.34) {
      color = '#B5342A' // red
    } else if (value < 1.34) {
      color = '#EFE950' // yellow
    } else {
      color = '#03D596' // green
    }
  }

  return (
    <Box
      width={'16px'}
      height={'16px'}
      borderRadius={'8px'}
      backgroundColor={color}
    />
  )
}

interface LifestyleCorrelatesChartProps {
  recap: SessionRecapResponse | undefined
  containerProps: any
}

export function LifestyleCorrelatesChart({
  recap,
  containerProps
}: LifestyleCorrelatesChartProps) {
  const headers = useMemo(() => {
    const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
    const weekDay = DateTime.now().weekday

    // rotate days of week around current day of week so 'Today' is last
    daysOfWeek[weekDay] = 'Today'
    const orderedDays = rotateArray(daysOfWeek, weekDay + 1)

    return orderedDays.map((day: string, i: number) => {
      return (
        <Th
          key={i}
          color={'#A1A1A1'}
          fontSize={'11px'}
          border={0}
          textAlign={'center'}
        >
          {day}
        </Th>
      )
    })
  }, [])

  // figure out way to hide last divider
  const rows = useMemo(() => {
    const correlateDays: { date: string; values: any }[] = []
    // we know that we need 7 data points, if any are missing, we iterate through each day
    // were expecting to have data for. If we dont have data for that day, we add a null
    // data point. Either way this could be better
    let idx = 0
    while (idx < 7) {
      const lookingForDate = DateTime.now().minus({ days: idx })
      const dataForDay = recap?.lifestyleTimeline.find(
        day => lookingForDate.day === DateTime.fromISO(day.date).day
      )
      if (!dataForDay) {
        const skippedDayData = {
          date: lookingForDate.toISO(),
          values: null
        }
        correlateDays.unshift(skippedDayData)
      } else {
        correlateDays.unshift(dataForDay)
      }
      idx++
    }

    return correlates.map((correlate, n) => {
      return (
        <Tr key={n}>
          <Td>{correlate.display}</Td>
          {correlateDays.map((day, i) => {
            return (
              <Td key={i}>
                {/* needed to center indicator */}
                <Flex justifyContent={'center'}>
                  <Indicator
                    value={
                      (day && day.values && day.values[correlate.type]) ?? null
                    }
                  />
                </Flex>
              </Td>
            )
          })}
        </Tr>
      )
    })
  }, [recap?.lifestyleTimeline])

  return (
    <TableContainer
      p="small"
      border={'1px solid'}
      borderColor="pale_gray"
      borderRadius={'8px'}
      position="relative"
      {...containerProps}
    >
      {recap?.lifestyleTimeline.length === 0 && (
        <Flex
          justify="center"
          align="center"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bg: 'rgba(255, 255, 255, 0.9)'
          }}
        >
          <Flex align="center" direction="column">
            <Flex
              borderRadius="50%"
              align="center"
              justify="center"
              width="40px"
              height="40px"
              bg="primary"
              sx={{
                '*': {
                  fill: 'white'
                }
              }}
            >
              <Mobile />
            </Flex>
            <Text fontWeight="bold" mt="xsmall" mb="xsmall">
              No lifestyle data
            </Text>
            <Text align="center" maxWidth="350px" whiteSpace="break-spaces">
              Remind your client to use the Blueprint mobile app to complete
              daily check-in’s.
            </Text>
          </Flex>
        </Flex>
      )}
      <Table size="sm">
        <Thead>
          <Tr>
            {/* for emmpty space above correlate names */}
            <Th border={0}></Th>
            {headers}
          </Tr>
        </Thead>
        <Tbody
          sx={{
            'tr > td': {
              borderColor: 'pale_gray'
            },
            'tr:last-child td': {
              borderBottom: 0
            }
          }}
        >
          {rows}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
