import { action } from 'easy-peasy'

export const quickEnrollModel = {
  quickEnroll: {
    isQuickEnrollOpen: false,
    setIsQuickEnrollOpen: action((state, payload) => {
      state.isQuickEnrollOpen = payload
    }),
    viewProfileUrl: '',
    title: '',
    message: '',
    subtext: '',
    setSubmittedViewState: action(
      (state, { viewProfileUrl, title, message, subtext }) => {
        state.viewProfileUrl = viewProfileUrl
        state.title = title
        state.message = message
        state.subtext = subtext
      }
    )
  }
}
