import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  HStack,
  Text,
  VStack
} from '@chakra-ui/react'
import { ArrowDownIcon } from '@blueprinthq/joy'

export type SelectOption = {
  value: string
  label: string
  icon?: React.ReactElement
  isDisabled?: boolean
}

type SelectMenuProps = {
  options: SelectOption[]
  value: string | undefined
  onChange: (value: string) => void
  renderSelectedOption?: (option: SelectOption) => React.ReactNode
  errorMessage?: string
  placeholder?: string
}

export const SelectMenu: React.FC<SelectMenuProps> = ({
  options,
  value,
  onChange,
  renderSelectedOption,
  errorMessage,
  placeholder = 'Select an option'
}) => {
  const selectedOption = options.find(option => option.value === value)

  const defaultRenderSelectedOption = (option: SelectOption) => (
    <HStack spacing={3}>
      {option.icon}
      <Text color={errorMessage ? '#EB5164' : 'black'}>{option.label}</Text>
    </HStack>
  )

  const renderPlaceholder = () => (
    <Text color="#757575" textAlign="left">
      {placeholder}
    </Text>
  )

  return (
    <VStack spacing={1} align="stretch" width="100%">
      <Menu placement="bottom-start" matchWidth>
        <MenuButton
          border={errorMessage ? '1px solid #EB5164' : '1px solid #E4E5E6'}
          m="0"
          h="48px"
          w="100%"
          bg="white"
          borderRadius="8px"
          as={Button}
          rightIcon={
            <ArrowDownIcon color={errorMessage ? '#EB5164' : undefined} />
          }
        >
          {selectedOption
            ? (renderSelectedOption || defaultRenderSelectedOption)(
                selectedOption
              )
            : renderPlaceholder()}
        </MenuButton>
        <MenuList w="100%">
          {options.map(option => (
            <MenuItem
              key={option.value}
              isDisabled={option.isDisabled}
              onClick={() => onChange(option.value)}
              value={option.value}
              color="black"
              w="100%"
              icon={option.icon}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      {errorMessage && (
        <Text color="#EB5164" fontSize="sm" textAlign="left" fontWeight="440">
          {errorMessage}
        </Text>
      )}
    </VStack>
  )
}
