import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { usePermissions } from '@hooks'
import {
  useCSVReader,
  useCSVDownloader,
  lightenDarkenColor
} from 'react-papaparse'
import {
  Box,
  Text,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Progress,
  useToast,
  Flex,
  Heading
} from '@chakra-ui/react'
import { Redirect } from 'react-router-dom'
import { Button } from '@blueprinthq/joy'
import { endpoints } from '@api'
import { chunk } from 'lodash'

const GREY = '#CCC'
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)'
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919'
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
)

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: 100,
    justifyContent: 'center',
    padding: 20
  },
  file: {
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex'
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em'
  },
  zoneHover: {
    borderColor: '#686868'
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23
  }
}

const BulkClinicianUpload = () => {
  const { CSVReader } = useCSVReader()
  const { CSVDownloader, Type } = useCSVDownloader()
  const { hasPermission } = usePermissions()

  const [rows, setRows] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  const [success, setSuccess] = useState([])
  const [existed, setExisted] = useState([])
  const [failed, setFailed] = useState([])

  const [progress, setProgress] = useState(0)
  const [zoneHover, setZoneHover] = useState(false)
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  )

  const { mutateAsync: uploadClinicians } = useMutation(
    endpoints.bulkClinicianUpload.request,
    {
      async onSuccess(response) {
        setSuccess([...success, ...response.success])
        setExisted([...existed, ...response.existed])
        setFailed([...failed, ...response.errors])
      }
    }
  )

  const toast = useToast()

  const handleUpload = async () => {
    const batches = chunk(rows, 50)
    setIsUploading(true)

    for (const batch of batches) {
      const idx = batches.indexOf(batch)
      await uploadClinicians({ clinicians: batch })
      setProgress(((idx + 1) / batches.length) * 100)
    }

    setIsUploading(false)
    toast({
      title: 'Success!',
      description: 'Upload complete',
      status: 'success',
      isClosable: true,
      duration: 1200
    })
  }

  const renderResult = email => {
    if (success.includes(email)) return 'Success'
    if (existed.includes(email)) return 'Already Exists'
    if (failed.includes(email)) return 'Failed'
  }

  if (!hasPermission('*:any:*:*')) {
    return <Redirect to="/" />
  }

  return (
    <Box mb="medium">
      <Flex justify="space-between" alignItems="center" flexDirection="row">
        <Box w="100%">
          <Heading mb="xxsmall">Bulk Uploader</Heading>
          <Text pb="small">
            Important: Please work with engineering when uploading clinicians.
          </Text>
        </Box>
        <CSVDownloader
          type={Type.Button}
          filename="clinician-upload-template"
          bom={true}
          config={{
            delimiter: ';'
          }}
          data={[
            {
              first_name: 'Test',
              last_name: 'Clinician',
              email: 'test+clinician@blueprint-health.com',
              clinic_id: '12345',
              send_invite: 'true'
            }
          ]}
        >
          <Button mb="medium" size="lg">
            Download Template
          </Button>
        </CSVDownloader>
      </Flex>
      <CSVReader
        config={{ header: true, worker: true }}
        onUploadAccepted={async results => {
          const clinicians = results.data
            .filter(c => c.first_name && c.last_name && c.email && c.clinic_id)
            .map(r => ({
              firstName: r.first_name.trim(),
              lastName: r.last_name.trim(),
              email: r.email.trim().toLowerCase(),
              clinicId: r.clinic_id.trim(),
              sendInvite: Boolean(r.send_invite)
            }))
          setRows(clinicians)
          setZoneHover(false)
        }}
        onDragOver={event => {
          event.preventDefault()
          setZoneHover(true)
        }}
        onDragLeave={event => {
          event.preventDefault()
          setZoneHover(false)
        }}
      >
        {({ getRootProps, acceptedFile, getRemoveFileProps, Remove }) => (
          <>
            <div
              {...getRootProps()}
              style={Object.assign(
                {},
                styles.zone,
                zoneHover && styles.zoneHover
              )}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <span style={styles.name}>{acceptedFile.name}</span>
                    <div
                      {...getRemoveFileProps()}
                      onClick={event => {
                        getRemoveFileProps().onClick(event)
                        setRows(null)
                        setProgress(0)
                        setSuccess([])
                        setExisted([])
                        setFailed([])
                      }}
                      style={styles.remove}
                      onMouseOver={event => {
                        event.preventDefault()
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT)
                      }}
                      onMouseOut={event => {
                        event.preventDefault()
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR)
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                </>
              ) : (
                'Drop CSV file here or click to upload'
              )}
            </div>
          </>
        )}
      </CSVReader>
      {rows && (
        <Box>
          <Button
            onClick={handleUpload}
            disabled={isUploading}
            isFullWidth
            my={5}
          >
            Upload
          </Button>
          {isUploading && <Progress value={Math.ceil(progress)} />}
          <Table>
            <Thead>
              <Tr>
                <Th>First Name</Th>
                <Th>Last Name</Th>
                <Th>Email</Th>
                <Th>Clinic ID</Th>
                <Th>Send Invite</Th>
                <Th>Result</Th>
              </Tr>
            </Thead>
            <Tbody>
              {rows.map((r, idx) => {
                return (
                  <Tr key={idx}>
                    <Td>{r.firstName}</Td>
                    <Td>{r.lastName}</Td>
                    <Td>{r.email}</Td>
                    <Td>{r.clinicId}</Td>
                    <Td>{r.sendInvite.toString()}</Td>
                    <Td>{renderResult(r.email)}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  )
}

export { BulkClinicianUpload }
