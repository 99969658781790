import React, { useState, useEffect } from 'react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import { VStack, Box, Button, Text } from '@chakra-ui/react'
import { ConfirmAudioSettingsDialog } from './confirm-audio-settings-dialog'
import { useExperienceManager, useAudioCapture } from '@hooks'
import { MicOff } from '@components/icons'

type StartRecordingDialogPropsT = {
  isOpen: boolean
  onClose: () => void
  startRecording: () => void
  onShareAudio: () => Promise<void>
  isDictation: boolean
}

const StartRecordingDialog = ({
  isOpen,
  onClose,
  onShareAudio,
  startRecording,
  isDictation
}: StartRecordingDialogPropsT) => {
  const [isMicEnabled, setIsMicEnabled] = useState(false)

  const { isReady, audioInputs, promptForDevicePermissions } = useAudioCapture()
  const { isExtension, isWidget } = useExperienceManager()

  useEffect(() => {
    if (audioInputs.some(d => d.deviceId)) {
      setIsMicEnabled(true)
    }
  }, [audioInputs])

  const handleShareAudio = async () => {
    try {
      await onShareAudio()
      startRecording()
    } catch (err) {
      console.log({ err })
    }
  }

  return isReady ? (
    <DialogContainer isOpen={isOpen} onClose={onClose}>
      {isMicEnabled ? (
        <ConfirmAudioSettingsDialog
          isExtension={isExtension}
          isWidget={isWidget}
          onClose={onClose}
          handleShareAudio={handleShareAudio}
          startRecording={startRecording}
          isDictation={isDictation}
        />
      ) : (
        <>
          <DialogHeader text="Enable your microphone" onClose={onClose} />
          <DialogBody>
            <VStack spacing={6}>
              <Box>
                <MicOff width="80px" height="80px" />
              </Box>
              <Box textAlign="center">
                <Text>
                  Allow your browser to use your microphone so we can record
                  audio.
                </Text>
              </Box>
              <Button onClick={promptForDevicePermissions} m={0} isFullWidth>
                Enable microphone
              </Button>
            </VStack>
          </DialogBody>
        </>
      )}
    </DialogContainer>
  ) : null
}

export default StartRecordingDialog
