import React from 'react'
import { Box, Select, Text, HStack } from '@chakra-ui/react'
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik'
import _ from 'lodash'
import { Assessment } from '../assessment-submitted-trigger'
import { formatScoreToInterpretation } from '@utilities'

type Props = {
  assessment: Assessment
  resetForm: (values: object) => void
  constants: any
}

type FormikContext = {
  values: {
    assessmentId: string | undefined
    totalScoreOperator: string | undefined
    totalScoreValues: number[]
  }
  errors: FormikErrors<any>
}

export const TotalScoreFilter = ({
  assessment,
  resetForm,
  constants
}: Props) => {
  const { values, errors }: FormikContext = useFormikContext()
  
  const options = () => {
    const totalScore = assessment?.totalScore || 0

    const valueArray = _.range(0, totalScore + 1)

    return valueArray.map(v => ({
      title: formatScoreToInterpretation(assessment, v),
      value: `${v}`
    }))
  }

  return (
    <HStack spacing="small">
      <Field name="totalScoreOperator">
        {({ field }: { field: { value: string } }) => (
          <Select
            {...field}
            value={field.value}
            w="310px"
            borderColor={errors?.totalScoreOperator ? 'error' : 'light_gray'}
            color={field.value ? 'black' : 'gray'}
            onChange={e => {
              resetForm({
                  ...values,
                  totalScoreValues:
                    e.target.value === 'between-inclusive'
                      ? [values.totalScoreValues[0], '']
                      : [values.totalScoreValues[0]],
                  totalScoreOperator: e.target.value
              })
            }}
          >
            <option value="" disabled>
              Operator
            </option>
            {constants.operators.map(
              (o: { display: string; value: string }) => (
                <option key={o.value} value={o.value}>
                  {o.display}
                </option>
              )
            )}
          </Select>
        )}
      </Field>
      <FieldArray name="totalScoreValues">
        {({ replace }) => {
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Field
                w={
                  values.totalScoreOperator !== 'between-inclusive'
                    ? '150px'
                    : '130px'
                }
                mx="xsmall"
                name="totalScoreValues.0"
              >
                {({ field }: { field: { value: string } }) => (
                  <Select
                    {...field}
                    value={field.value}
                    borderColor={
                      errors?.totalScoreValues ? 'error' : 'light_gray'
                    }
                    color={field.value ? 'black' : 'gray'}
                    onChange={e => {
                      replace(0, e.target.value)
                    }}
                  >
                    <option value="" disabled>
                      Value
                    </option>
                    {options().map(o => (
                      <option key={o.value} value={o.value}>
                        {`${o.value} - ${o.title}`}
                      </option>
                    ))}
                  </Select>
                )}
              </Field>
              {values.totalScoreOperator === 'between-inclusive' && (
                <>
                  <Text mx="xsmall">and</Text>
                  <Field w="130px" mx="xsmall" name="totalScoreValues.1">
                    {({ field }: { field: { value: string }; form: any }) => (
                      <Select
                        {...field}
                        value={field.value}
                        borderColor={
                          errors?.totalScoreValues ? 'error' : 'light_gray'
                        }
                        color={field.value ? 'black' : 'gray'}
                        onChange={e => {
                          replace(1, e.target.value)
                        }}
                      >
                        <option value="" disabled>
                          Value
                        </option>
                        {options()
                          .filter(
                            o =>
                              Number(o.value) >
                              Number(values.totalScoreValues[0])
                          )
                          .map(o => (
                            <option key={o.value} value={o.value}>
                              {`${o.value} - ${o.title}`}
                            </option>
                          ))}
                      </Select>
                    )}
                  </Field>
                </>
              )}
            </Box>
          )
        }}
      </FieldArray>
    </HStack>
  )
}
