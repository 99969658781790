import history from './history'
import {
  AuthenticationDetails,
  CookieStorage,
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js'
import store from 'store'
//import { api } from './api'
import { getCookie } from '@utilities'

const MINUTES_UNITL_AUTO_LOGOUT = 20160 // 14 days in mins
const CHECK_INTERVAL = 1000 // in ms
const STORE_KEY = 'lastAction'

const REACT_APP_DOMAIN = window.location.hostname.includes('blueprint.ai')
  ? process.env.REACT_APP_DOMAIN
  : process.env.REACT_APP_LEGACY_DOMAIN

const POOL_DATA = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  Storage: new CookieStorage({
    domain: REACT_APP_DOMAIN,
    secure: true,
    sameSite: 'none'
  })
}

export default class Auth {
  constructor() {
    this.autoLogoutTimer = null
  }

  login = (email, password) => {
    return new Promise((resolve, reject) => {
      const authenticationData = {
        Username: email.toLowerCase().trim(),
        Password: password
      }
      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      )
      const userPool = new CognitoUserPool(POOL_DATA)
      const userData = {
        Username: email.toLowerCase().trim(),
        Pool: userPool,
        Storage: new CookieStorage({
          domain: REACT_APP_DOMAIN,
          secure: true,
          sameSite: 'none'
        })
      }
      const cognitoUser = new CognitoUser(userData)

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          this.resetAutoLogoutTime()
          resolve(result)
        },
        onFailure: err => {
          reject(err)
        }
      })
    })
  }

  logout = () => {
    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool(POOL_DATA)
      const cognitoUser = userPool.getCurrentUser()

      if (cognitoUser !== null) {
        cognitoUser.getSession(err => {
          if (err) {
            console.error(err.message || JSON.stringify(err))
            return
          }

          cognitoUser.globalSignOut({
            onSuccess: () => {
              const isWidget = window.location.pathname.includes('/widget')
              const isExtension = window.location.pathname.includes(
                '/extension'
              )
              this.clearAutoLogoutTimer()
              store.remove(STORE_KEY)
              if (isWidget) history.replace('/widget')
              else if (isExtension) history.replace('/extension')
              else history.replace('/login')
              resolve()
            },
            onFailure: err => {
              console.error(err.message || JSON.stringify(err))
              reject(err)
            }
          })
        })
      } else {
        resolve()
      }
    })
  }

  widgetLogin = (authTokens, username) => {
    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool(POOL_DATA)
      const userData = {
        Username: username,
        Pool: userPool,
        Storage: new CookieStorage({
          domain: REACT_APP_DOMAIN,
          secure: true,
          sameSite: 'none'
        })
      }
      const cognitoUser = new CognitoUser(userData)

      const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: authTokens.IdToken }),
        AccessToken: new CognitoAccessToken({
          AccessToken: authTokens.AccessToken
        }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: authTokens.RefreshToken
        })
      })

      cognitoUser.setSignInUserSession(session)

      this.resetAutoLogoutTime()
      resolve(session)
    })
  }

  // checks if the user is authenticated
  isAuthenticated = () => {
    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool(POOL_DATA)
      const cognitoUser = userPool.getCurrentUser()

      if (cognitoUser !== null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error(err.message || JSON.stringify(err))
            reject(err)
          } else {
            this.startAutoLogoutTimer(false)
            resolve(session)
          }
        })
      } else {
        const ssoToken = getCookie('ssoAccessToken')
        if (ssoToken) {
          const {
            auth: { resumeSession }
          } = store.getActions()
          resumeSession()
          resolve()
        } else {
          // eslint-disable-next-line
          parent.postMessage({ type: 'BP_CLINICIAN_NOT_AUTHENTICATED' }, '*')
          reject(new Error('User has not authenticated.'))
        }
      }
    })
  }

  //AUTO LOGOUT FUNCTIONALITY
  get lastAction() {
    return parseInt(store.get(STORE_KEY))
  }
  set lastAction(value) {
    store.set(STORE_KEY, value)
  }
  clearAutoLogoutTimer = () => {
    clearInterval(this.autoLogoutTimer)
  }

  startAutoLogoutTimer = shouldResetTime => {
    this.initListener()
    this.initInterval()
    if (shouldResetTime) {
      this.resetAutoLogoutTime()
    }
  }

  initListener = () => {
    document.body.addEventListener('click', () => this.resetAutoLogoutTime())
  }

  resetAutoLogoutTime = () => {
    this.lastAction = Date.now()
  }

  initInterval = () => {
    this.autoLogoutTimer = setInterval(() => {
      this.checkAutoLogout()
    }, CHECK_INTERVAL)
  }

  checkAutoLogout = () => {
    const now = Date.now()
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000
    const diff = timeleft - now
    const isTimeout = diff < 0

    if (isTimeout) {
      // this.logout();
    }
  }
}

export const auth = new Auth()
