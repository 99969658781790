import React, { useState, useEffect } from 'react'
import { Checkbox, Chip } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const useStyles = makeStyles({
  formInput: {
    width: '100%'
  }
})

export default function CheckboxSelector({
  items,
  label,
  selectAllLabel,
  limitTags,
  onChange,
  alreadySelected
}) {
  const classes = useStyles()
  const [selectedOptions, setSelectedOptions] = useState(alreadySelected || [])
  const allSelected = items.length === selectedOptions.length

  useEffect(() => {
    if (alreadySelected) {
      setSelectedOptions(alreadySelected)
    }
  }, [alreadySelected])

  const handleToggleOption = selectedOptions =>
    setSelectedOptions(selectedOptions)
  const getOptionLabel = option => `${option.label}`

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      if (selectedOptions.find(option => option.value === 'select-all')) {
        let result = []
        if (allSelected) {
          result = items.filter(i => i.disabled)
        } else {
          result = items
        }
        result = result.filter(el => el.value !== 'select-all')
        handleToggleOption(result)
        return onChange(result)
      } else {
        const newOptions = [
          ...items.filter(i => i.disabled),
          ...selectedOptions.filter(i => !i.disabled)
        ]
        handleToggleOption(newOptions)
        return onChange(newOptions)
      }
    } else if (reason === 'clear') {
      const newOptions = items.filter(i => i.disabled)
      handleToggleOption(newOptions)
      return onChange(newOptions)
    }
  }
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      items.length > 0 && option.value === 'select-all' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {}
    return (
      <>
        <Checkbox
          disabled={option.disabled}
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    )
  }
  const inputRenderer = params => (
    <TextField {...params} label={label} variant="outlined" />
  )

  const filter = createFilterOptions()
  return (
    <Autocomplete
      className={classes.formInput}
      multiple
      limitTags={limitTags}
      options={items}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => option.value === value.value}
      filterOptions={(options, params) => {
        if (items.length > 0) {
          const filtered = filter(options, params)
          return [{ label: selectAllLabel, value: 'select-all' }, ...filtered]
        } else if (items.length === 0) {
          const filtered = filter(options, params)
          return [...filtered]
        }
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={index}
            label={option.label}
            {...getTagProps({ index })}
            disabled={option.disabled}
          />
        ))
      }
    />
  )
}
