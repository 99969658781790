import React from 'react'
import { Basics } from './basics'
import { Container, GridItem, Box } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'

export const BasicsPage = ({ onSubmit, isSubmitting, isError }) => {
  return (
    <Container
      marginTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%">
            <Basics
              isSubmitting={isSubmitting}
              isError={isError}
              onSubmit={onSubmit}
            />
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
