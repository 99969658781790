import React, { useContext, useState } from 'react'
import { Text, Box, Select, Flex, CloseButton, Checkbox, Tooltip, Icon } from '@chakra-ui/react'
import InfoIcon from '@material-ui/icons/Info'
import { Formik, Form, Field, FieldArray } from 'formik'
import { useParams } from 'react-router-dom'
import { AddCircleIcon } from '@blueprinthq/joy'
import * as Yup from 'yup'

import { WorkflowsContext } from '@context'
import { EditDrawerFooter } from '../../edit-drawer/edit-drawer-footer'
import { FormattedNode } from '../../format-nodes'
import { Loading, SearchSelect } from '@components'
import {
  useOrganizationAssessments,
  useOrganizationAssessmentCollections
} from '../../queries'
import { Assessment } from '../triggers/assessment-submitted-trigger/assessment-submitted-trigger'

type RouteParams = {
  workflowId: string
  organizationId: string
}

type Props = {
  onSubmit: (node: FormattedNode) => void
  closeDrawer: () => void
  onDelete: (node: FormattedNode | undefined) => void
}

type Cadence = {
  unit: string
  value: number
}

const schema = Yup.object().shape({
  assignee: Yup.string()
    .oneOf(['client', 'participant'])
    .required(),
  assessments: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .oneOf(['assessment', 'assessmentCollection'])
          .required(),
        value: Yup.string().required(),
        label: Yup.string().required()
      })
    )
    .min(1)
    .required()
})

export const cadenceMap = {
  'One time': { unit: 'time', value: 1 },
  Weekly: { unit: 'weeks', value: 1 },
  'Every 2 weeks': { unit: 'weeks', value: 2 },
  'Every 4 weeks': { unit: 'weeks', value: 4 },
  'Every 8 weeks': { unit: 'weeks', value: 8 },
  'Every 24 weeks': { unit: 'weeks', value: 24 },
  'Every 3 months': { unit: 'weeks', value: 12 },
  'Every 6 months': { unit: 'weeks', value: 26 },
  Yearly: { unit: 'weeks', value: 52 }
}

const friendlyCadenceName = (cadence: Cadence) =>
  Object.keys(cadenceMap).find(
    key =>
      cadenceMap[key as keyof typeof cadenceMap].unit ===
        cadence.unit.toLowerCase() &&
      cadenceMap[key as keyof typeof cadenceMap].value === cadence.value
  )

const initialCadences = (assessments: Assessment[]) =>
  assessments
    ? assessments.reduce(
        (acc: any, curr: any) => ({
          ...acc,
          ...{
            [curr.assessmentId]: friendlyCadenceName(curr.cadence)
          }
        }),
        {}
      )
    : {}

export const AssignAssessment = ({
  onSubmit,
  closeDrawer,
  onDelete
}: Props) => {
  const { selectedNode } = useContext(WorkflowsContext)
  const [cadences, setCadences] = useState(
    initialCadences(selectedNode?.config?.assessments)
  )

  const config = selectedNode?.config
  const { organizationId } = useParams<RouteParams>()

  const {
    data: allAssessments,
    isLoading: assessmentsIsLoading
  } = useOrganizationAssessments(organizationId)

  const {
    data: allAssessmentCollections,
    isLoading: collectionsIsLoading
  } = useOrganizationAssessmentCollections(organizationId)

  if (assessmentsIsLoading || collectionsIsLoading) return <Loading />

  const initialValues = {
    assignee: config?.assignee || 'client',
    assessments: [
      ...(config?.assessments || []),
      ...(config?.assessmentCollections || [])
    ],
    shouldOverrideCadence: config?.shouldOverrideCadence ?? true
  }

  const handleConfig = (
    assignee: string[],
    selectedAssessments: { value: string }[],
    shouldOverrideCadence: boolean,
  ) => {
    const assessments = selectedAssessments
      .filter(selected =>
        allAssessments?.map(a => a.id).includes(selected.value)
      )
      .map(a => ({
        assessmentId: a.value,
        cadence:
          cadenceMap[cadences[a.value] as keyof typeof cadenceMap] ||
          allAssessments?.find(assessment => assessment.id === a.value)?.cadence
      }))

    const assessmentCollections = selectedAssessments
      .filter(selected =>
        allAssessmentCollections?.map(a => a.id).includes(selected.value)
      )
      .map(a => ({ assessmentCollectionId: a.value }))

    return { assignee, assessments, assessmentCollections, shouldOverrideCadence }
  }

  const getDefaultCadence = (assessmentId: string) => {
    const cadence = allAssessments?.find(
      assessment => assessment.id === assessmentId
    )?.cadence

    return cadence ? friendlyCadenceName(cadence) : 'One time'
  }

  const assessmentsForDropdown = () => {
    const assessments = allAssessments?.map(assessment => ({
      label: assessment.fullName,
      shortLabel: assessment.name,
      value: assessment.id
    }))

    const assessmentCollections = allAssessmentCollections?.map(assessment => ({
      label: assessment.name,
      value: assessment.id
    }))

    return assessmentCollections?.length
      ? [
          {
            label: 'Assessment Collections',
            options: assessmentCollections
          },
          {
            label: 'Assessments',
            options: assessments
          }
        ]
      : assessments
  }

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={values =>
          onSubmit({
            ...selectedNode,
            config: handleConfig(values.assignee, values.assessments, values.shouldOverrideCadence)
          } as FormattedNode)
        }
      >
        {({ values, errors }) => {
          return (
            <Form>
              <Text as="b">Assign to</Text>
              <Field name="assignee">
                {({ field }: { field: { value: string } }) => (
                  <Select
                    {...field}
                    mb="small"
                    value={field.value}
                    borderColor={errors?.assignee ? 'error' : 'light_gray'}
                    color={field.value ? 'black' : 'gray'}
                    mt="xsmall"
                  >
                    <option value="client">Client</option>
                    <option value="participant">Participants</option>
                  </Select>
                )}
              </Field>
              <FieldArray
                name="assessments"
                render={({ push, remove, replace }) => (
                  <Box>
                    <Text as="b">Assessments</Text>
                    {!Boolean(values.assessments.length) && (
                      <Field name="assessments.0">
                        {({
                          field,
                          form
                        }: {
                          field: {
                            value: { value: string; label: string }
                            name: string
                          }
                          form: any
                        }) => (
                          <SearchSelect
                            {...field}
                            /* @ts-ignore */
                            options={assessmentsForDropdown()}
                            placeholder="Select an Assessment"
                            isOptionDisabled={(o: any) =>
                              values.assessments
                                .map(a => a.value)
                                .includes(o.value)
                            }
                            onChange={(option: any) =>
                              form.setFieldValue(field.name, {
                                type: allAssessments?.find(
                                  a => a.id === option.value
                                )
                                  ? 'assessment'
                                  : 'assessmentCollection',
                                value: option.value,
                                label: option?.shortLabel || option.label
                              })
                            }
                            value={field.value}
                            errors={errors?.assessments}
                          />
                        )}
                      </Field>
                    )}
                    {values?.assessments?.map(
                      ({ value, type }, index: number) => (
                        <Flex>
                          <Field
                            name={`assessments.${index}`}
                            key={`assessments.${index}`}
                          >
                            {({
                              field,
                              form
                            }: {
                              field: {
                                value: { label: string; value: string }
                                name: string
                              }
                              form: any
                            }) => (
                              <SearchSelect
                                {...field}
                                /* @ts-ignore */
                                options={assessmentsForDropdown()}
                                placeholder="Select an Assessment"
                                isOptionDisabled={(o: any) =>
                                  values.assessments
                                    .map(a => a.value)
                                    .includes(o.value)
                                }
                                onChange={(option: any) =>
                                  form.setFieldValue(field.name, {
                                    type: allAssessments?.find(
                                      a => a.id === option.value
                                    )
                                      ? 'assessment'
                                      : 'assessmentCollection',
                                    value: option.value,
                                    label: option?.shortLabel || option.label
                                  })
                                }
                                value={field.value}
                                errors={errors?.assessments}
                              />
                            )}
                          </Field>
                          {value && type === 'assessment' && (
                            <Select
                              maxWidth={170}
                              ml="small"
                              mr="xsmall"
                              mt="xsmall"
                              // @ts-ignore
                              value={
                                cadences[value] || getDefaultCadence(value)
                              }
                              borderColor={'light_gray'}
                              onChange={e =>
                                setCadences({
                                  ...cadences,
                                  ...{ [value]: e.target.value }
                                })
                              }
                            >
                              {Object.keys(cadenceMap).map(cadence => (
                                <option key={cadence} value={cadence}>
                                  {cadence}
                                </option>
                              ))}
                            </Select>
                          )}
                          {value && (
                            <CloseButton
                              mt="xsmall"
                              alignSelf="center"
                              onClick={() => {
                                if (values.assessments.length === 1) {
                                  replace(0, '')
                                } else {
                                  remove(index)
                                }
                              }}
                            />
                          )}
                        </Flex>
                      )
                    )}
                    {Boolean(values?.assessments[0]) && (
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => push('')}
                        mt="xsmall"
                      >
                        <AddCircleIcon fill="primary" mr="xsmall" />
                        <Text color="primary">Add Assessment</Text>
                      </Box>
                    )}
                  </Box>
                )}
              />
              <Field name="shouldOverrideCadence">
                {({ field, form }: { field: any, form: any }) => (
                  <Flex alignItems="center" mt="small" mb="small">
                    <Checkbox
                      isChecked={field.value}
                      onChange={() => form.setFieldValue(field.name, !field.value)}
                    >
                      Override custom cadence
                    </Checkbox>
                    <Tooltip label="If the clinician has set a custom cadence for a specific client, this will reset the cadence specified in this workflow action" aria-label="Override Cadence Tooltip">
                      <span>
                        <Icon as={InfoIcon} ml="2" mt="2" boxSize="5" cursor="pointer" />
                      </span>
                    </Tooltip>
                  </Flex>
                )}
              </Field>
              <EditDrawerFooter
                onDelete={() => onDelete(selectedNode)}
                onCancel={closeDrawer}
              />
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}
