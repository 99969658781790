import React from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import Pill from './pill'

interface TreatmentApproachOption {
  value: string
  label: string
}

interface TreatmentApproachGridProps {
  options: TreatmentApproachOption[]
  value: string | undefined
  onClick: (value: string) => void
}

const TreatmentApproachGrid: React.FC<TreatmentApproachGridProps> = ({
  options,
  value,
  onClick
}) => {
  return (
    <SimpleGrid columns={5}>
      {options.map(item => (
        <Pill
          key={item.value}
          activeValue={item.value}
          value={value}
          onClick={onClick}
          gridColumn={item.label.length > 10 ? 'span 3' : 'span 1'}
        >
          {item.label}
        </Pill>
      ))}
    </SimpleGrid>
  )
}

export default TreatmentApproachGrid
