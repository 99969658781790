import React from 'react'
import {
  VStack,
  HStack,
  Box,
  Text,
  Heading,
  Stack,
  Flex
} from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { Button } from '@blueprinthq/joy'
import { EvidenceBasedCare, MicAIOn } from '@components/icons'
import Cookies from 'js-cookie'
import { isMobile, isTablet } from 'react-device-detect'

const FreemiumTrialType = 'freemium'

export const Plan = ({ onSelect }) => {
  const [selectedPlan, setSelectedPlan] = React.useState(null)
  const { formData } = useStoreState(state => state.clinicianSignup)

  const options = [
    {
      key: 'documentation_automation',
      value: 'Documentation Automation',
      description:
        'An AI Notetaker that listens, transcribes, and writes automated progress notes for you.',
      SVG: () => <MicAIOn fill="#2D54E8" height="40px" width="40px" />,
      type: 'button',
      onNext: async () => {
        await onSelect({
          ...formData,
          initialProduct: 'documentation_automation',
          trialType: FreemiumTrialType
        })
      }
    },
    {
      key: 'docAutoStarter',
      value: 'Evidence-Based Care',
      description:
        'Measure outcomes and assign homework to clients between sessions.',
      SVG: EvidenceBasedCare,
      onNext: async () => {
        await onSelect({
          ...formData,
          initialProduct: 'evidence_based_care',
          trialType: Cookies.get('trialType') ?? 'gatedTrial' //fall back to gatedTrial if no cookie is set
        })
      },
      type: 'button'
    }
  ]

  return (
    <VStack w="100%" maxWidth="1000px" ml="auto" mr="auto">
      <Box w="100%" pb="medium">
        <Heading w="100%" mb="4px" size="lg">
          What would you like to try first?
        </Heading>
        <Text> Pick one to start, you can always change this later.</Text>
      </Box>
      <Stack
        w="100%"
        direction="column"
        align="top"
        justify="center"
        wrap="wrap"
        spacing="small"
        style={{ marginTop: 0 }}
      >
        {options
          .filter(option => option.type === 'button')
          .map(option => {
            const isSelected = selectedPlan?.key === option.key

            return (
              <Box
                onClick={() => setSelectedPlan(option)}
                key={option.key}
                w="100%"
                as="button"
                p="small"
                borderWidth={isSelected ? '2px' : '1px'}
                borderColor={isSelected ? '#2D54E8' : '#CCC'}
                borderRadius={'8px'}
                bg={isSelected ? 'rgba(45, 84, 232, 0.04)' : 'transparent'}
                _focus={{ outline: 'none' }}
                _hover={{
                  background:
                    !isSelected && !isMobile && !isTablet
                      ? 'rgba(45, 84, 232, 0.04)'
                      : ''
                }}
              >
                <HStack w="100%" spacing="4" align="center">
                  <Flex
                    align={'center'}
                    height={'64px'}
                    minH={'64px'}
                    minW={'64px'}
                    width={'64px'}
                    justify={'center'}
                  >
                    <option.SVG />
                  </Flex>
                  <VStack align="left" spacing="0">
                    <Text align="left" as="b">
                      {option.value}
                    </Text>
                    <Text align="left">{option.description}</Text>
                  </VStack>
                </HStack>
              </Box>
            )
          })}
      </Stack>
      <Box height="16px" />
      <Button
        isFullWidth
        style={{
          marginTop: 0
        }}
        size="lg"
        colorScheme="primary"
        isDisabled={!selectedPlan}
        onClick={selectedPlan?.onNext}
      >
        Next
      </Button>
    </VStack>
  )
}
