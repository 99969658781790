import React from 'react'
import { Divider, HStack, Stack, Text } from '@chakra-ui/react'
import StepNumber from './step-number'

interface StepSectionProps {
  number: number
  title: string
  children: React.ReactNode
  paddingBottom?: any
}

const StepSection: React.FC<StepSectionProps> = ({
  number,
  title,
  children,
  paddingBottom
}) => (
  <>
    <Divider borderColor="pale_gray" />
    <HStack gap="16px" alignItems="flex-start" pb={paddingBottom}>
      <StepNumber number={number} />
      <Stack flex="1" gap="16px">
        <Text as="b" display="flex" h="32px" alignItems="center">
          {title}
        </Text>
        {children}
      </Stack>
    </HStack>
  </>
)

export default StepSection
