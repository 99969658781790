import React from 'react'

export const MinimizeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0709 5.41421L18.6566 4L14.9999 7.65674V5L12.9999 5V9L12.9999 11H14.9999L18.9999 11L18.9999 9H16.4851L20.0709 5.41421ZM3.99976 18.5858L5.41397 20L9.07071 16.3433V19H11.0707V15V13H9.07071H5.07071V15H7.58554L3.99976 18.5858Z"
      fill="white"
    />
  </svg>
)
