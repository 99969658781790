import React from 'react'
import {
  Text,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
export function DiscardInterventionModal({ handleClose, isOpen, discard }) {
  return (
    <Modal onClose={handleClose} isOpen={isOpen} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent p="small">
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>You will lose all saved progress on this activity.</Text>
        </ModalBody>
        <ModalFooter>
          <Flex justify="space-between" w="100%">
            <Button size="lg" onClick={handleClose} bg="grey">
              Cancel
            </Button>
            <Button
              size="lg"
              onClick={() => {
                discard()
                handleClose()
              }}
              bg="error"
            >
              Delete
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
