import React from 'react'

interface ChevronDownProps extends React.SVGProps<SVGSVGElement> {
  width?: string | number
  height?: string | number
}

const ChevronDown: React.FC<ChevronDownProps> = ({ width = '20px', height = '20px', style, ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      role="presentation"
      className="chakra-select__icon"
      focusable="false"
      aria-hidden="true"
      style={{ width, height, color: 'currentcolor', ...style }}
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
      ></path>
    </svg>
  )
}

export default ChevronDown
