import React, { Component } from 'react'
import styles from './BPTooltip.module.css'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { CircleQuestion } from '../icons'

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    fontSize: '14px',
    color: '#4a4a4a',
    letterSpacing: 0,
    lineHeight: 1.3,
    fontWeight: 'normal',
    padding: '10px',
    maxWidth: '250px'
  }
}))(Tooltip)

export default class BPTooltip extends Component {
  render() {
    const { main, hoverTitle, hoverBody } = this.props
    return (
      <CustomTooltip
        placement="bottom"
        title={
          <div className={styles.tooltipHover}>
            <p className={styles.tooltipHoverTitle}>{hoverTitle}</p>
            <p>{hoverBody}</p>
          </div>
        }
      >
        <span className={styles.tooltipMain}>
          {main + ' '}
          <span>
            <CircleQuestion fill="#dbdbdb" />
          </span>
        </span>
      </CustomTooltip>
    )
  }
}
