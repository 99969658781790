import React, { useMemo, useCallback } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Box, Text, Heading, HStack } from '@chakra-ui/react'
import { SettingsIcon, Button, GoBackwardIcon } from '@blueprinthq/joy'
import { useStoreState } from 'easy-peasy'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'

import { endpoints } from '../../../../api'
import { BillingRulesTable } from './components'

export const BillingRules = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { selectedClinic } = useStoreState(state => state.billing)
  const organizationId = selectedClinic.organization_id

  const {
    data,
    dataUpdatedAt: rulesUpdatedAt,
    isLoading: isBillingRulesLoading
  } = useQuery(
    [endpoints.getOrgBillingRules.getCacheId(), organizationId],
    () =>
      endpoints.getOrgBillingRules.request({
        organizationId
      }),
    {
      initialData: {
        licenseLevels: [],
        orgInsurancePayers: [],
        rules: []
      }
    }
  )

  const {
    data: orgBillingPreferences,
    dataUpdatedAt: preferencesUpdatedAt
  } = useQuery(
    [endpoints.getOrgBillingPreferences.getCacheId(), organizationId],
    () =>
      endpoints.getOrgBillingPreferences.request({
        organizationId
      }),
    {
      initialData: {
        cptCodes: [],
        modifiers: []
      }
    }
  )

  const { mutateAsync: postOrgBillingRule } = useMutation(
    endpoints.postOrgBillingRule.request,
    {
      onMutate: async ({ data, organizationId }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([
          endpoints.getOrgBillingRules.getCacheId(),
          organizationId
        ])

        const previousBillingRules = queryClient.getQueryData([
          endpoints.getOrgBillingRules.getCacheId(),
          organizationId
        ])

        let nextRules = previousBillingRules.rules
        const matchingRule = nextRules.find(
          rule =>
            rule.licenseLevel === data.licenseLevel &&
            rule.organizationInsurancePayerId ===
              data.organizationInsurancePayerId
        )

        if (matchingRule) {
          nextRules = nextRules.map(rule => {
            if (
              rule.licenseLevel === data.licenseLevel &&
              rule.organizationInsurancePayerId ===
                data.organizationInsurancePayerId
            ) {
              return { ...rule, ...data.rules }
            }
            return rule
          })
        } else {
          nextRules = [
            ...nextRules,
            {
              licenseLevel: data.licenseLevel,
              organizationInsurancePayerId: data.organizationInsurancePayerId,
              ...data.rules
            }
          ]
        }

        const nextBillingRules = {
          ...previousBillingRules,
          rules: nextRules
        }

        queryClient.setQueryData(
          [endpoints.getOrgBillingRules.getCacheId(), organizationId],
          nextBillingRules
        )

        return { nextBillingRules, previousBillingRules }
      }
    }
  )

  const licenseTables = useMemo(() => {
    return _.flatten(
      data.licenseLevels.map(licenseLevel => {
        return data.orgInsurancePayers.map(orgIP => {
          const foundRule = data.rules.find(
            rule =>
              rule.organizationInsurancePayerId === orgIP.id &&
              rule.licenseLevel === licenseLevel.id
          )
          return {
            orgInsurancePayer: orgIP,
            submitClaim: true,
            singleCptCode: null,
            singleModifier: null,
            multipleCptCode: null,
            multipleModifier: null,
            ...foundRule,
            licenseLevel
          }
        })
      })
    )
  }, [data])

  const onChangeBillingRule = useCallback(
    (orgInsurancePayerId, licenseLevel, rules) => {
      postOrgBillingRule({
        organizationId,
        data: {
          organizationInsurancePayerId: orgInsurancePayerId,
          licenseLevel,
          rules: {
            ...rules
          }
        }
      })
    },
    [organizationId]
  )

  const orgCptCodes = useMemo(() => {
    return [null].concat(orgBillingPreferences.cptCodes.map(o => o.code))
  }, [orgBillingPreferences.cptCodes])

  const orgModifiers = useMemo(() => {
    return [null].concat(orgBillingPreferences.modifiers)
  }, [orgBillingPreferences.modifiers])

  return (
    <Box>
      <Box mb="small">
        <Button
          pl="0px"
          variant="link"
          leftIcon={<GoBackwardIcon size="sm" fill="primary" />}
          onClick={() => history.push('/reports/claims')}
        >
          Back to claims
        </Button>
      </Box>
      <Box
        mb={{
          base: '0px',
          md: '-44px'
        }}
      >
        <HStack spacing="small" justify="space-between">
          <Heading>Billing Rules</Heading>
          <Button
            leftIcon={<SettingsIcon size="sm" />}
            variant="outline"
            background="white"
            border="1px solid"
            borderColor="light_gray"
            onClick={() => history.push('/reports/claims/settings')}
          >
            Billing setup
          </Button>
        </HStack>
        <Text mt="xsmall">Manage billing rules and scenarios</Text>
      </Box>
      <Box
        overflowX={{
          base: 'auto',
          md: 'initial'
        }}
      >
        {isBillingRulesLoading || data.licenseLevels.length === 0 ? null : (
          <BillingRulesTable
            data={licenseTables}
            licenseLevels={data.licenseLevels}
            orgCptCodes={orgCptCodes}
            orgModifiers={orgModifiers}
            onChangeBillingRule={onChangeBillingRule}
            rulesUpdatedAt={rulesUpdatedAt}
            preferencesUpdatedAt={preferencesUpdatedAt}
          />
        )}
      </Box>
    </Box>
  )
}
