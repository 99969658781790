import React from 'react'
import { useStoreState } from 'easy-peasy'
import {
  Box,
  VStack,
  Flex,
  Text,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  HStack
} from '@chakra-ui/react'
import { Mic } from '@components/icons'
import { ArrowDownIcon } from '@blueprinthq/joy'
import { times } from 'lodash'
import { useAudioCapture } from '@hooks'
import { trackEvent } from '@lib/clinician-tracking'
import { StoreModel } from 'src/store/types'

const CompactAudioLevel = () => {
  const { audioLevel } = useStoreState((state: StoreModel) => state.volume)
  const { isMuted } = useAudioCapture()
  const numBars = 15

  return (
    <Flex ml="8px">
      {times(numBars, i => (
        <Box
          key={i}
          h="16px"
          w="2.5px"
          borderRadius="4px"
          bg={
            audioLevel >= (i + 1) * (1 / numBars) && !isMuted
              ? '#68E19F'
              : '#E4E5E6'
          }
          mr="2px"
        />
      ))}
    </Flex>
  )
}

export const MicrophoneSelectorV2 = () => {
  const {
    audioInputs,
    selectedAudioInput,
    setSelectedAudioInput,
    permissionStatus
  } = useAudioCapture()

  const selectedInput = audioInputs.find(
    input => input.deviceId === selectedAudioInput
  )

  const handleMicChange = (event: any) => {
    setSelectedAudioInput(event.target.value)
    trackEvent('Confirm Audio Settings Dialog -> Changed Microphone Input', {
      newMicrophoneSelection: JSON.stringify(
        audioInputs.find(ai => ai.deviceId === event.target.value)
      )
    })
  }

  return (
    <VStack spacing={4} textAlign="left" w="100%">
      <Box w="100%">
        <Menu id="microphone-selector-menu" matchWidth>
          <MenuButton
            border={`1px solid #e4e5e6`}
            m="0"
            w="100%"
            h="48px"
            bg="white"
            borderRadius="8px"
            as={Button}
            rightIcon={<ArrowDownIcon />}
          >
            <Flex w="100%" alignItems="center">
              <HStack spacing={3} minWidth="0" flex="1">
                <Flex w="24px" h="24px" align="center" justify="center" flexShrink={0}>
                  <Mic fill="black" />
                </Flex>
                <Text
                  color="black"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  minWidth="0"
                >
                  {!audioInputs.length && permissionStatus !== 'denied'
                    ? 'No mic inputs detected'
                    : selectedInput?.label}
                </Text>
              </HStack>
              <CompactAudioLevel />
            </Flex>
          </MenuButton>
          <MenuList w="100%" minWidth="unset" style={{ width: 'inherit' }}>
            {audioInputs.map(input => (
              <MenuItem
                isDisabled={input.deviceId === selectedAudioInput}
                h="48px"
                w="100%"
                key={input.deviceId}
                onClick={handleMicChange}
                value={input.deviceId}
                color="black"
                maxWidth="100%"
                icon={
                  input.deviceId === selectedAudioInput ? (
                    <Mic filterId="startRecordingMenuItem" />
                  ) : (
                    <Box w="large" mr="medium" />
                  )
                }
              >
                {input.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </VStack>
  )
}