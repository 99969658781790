import React from 'react'
import { DialogBody, DialogContainer, DialogHeader } from '../../../dialog'
import { Button, Text, VStack } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useTreatmentPlan } from '@hooks'
import { FormValues } from './types'

const RegenerationConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: any) => {
  const { regenerateMutation } = useTreatmentPlan()
  const { values } = useFormikContext<FormValues>()

  return (
    <DialogContainer onClose={onClose} isOpen={isOpen}>
      <DialogHeader text="Are you sure?" onClose={onClose} />
      <DialogBody>
        <VStack spacing={6}>
          {regenerateMutation.isError && (
            <Text color="red">
              An error occurred while regenerating the treatment plan. Please
              try again.
            </Text>
          )}
          <Text textAlign="center">
            Are you sure you want to regenerate this Treatment Plan? This will
            overwrite the current treatment plan.
          </Text>
          <VStack w="100%">
            <Button
              bg="primary"
              isFullWidth
              size="lg"
              onClick={
                async () => {
                  await regenerateMutation.mutateAsync({
                    diagnosisIds: values.selectedDiagnoses
                      .filter(d => d)
                      .map(d => d?.value),
                    focusOfTreatmentIds: values.selectedFocuses
                      .filter(f => f)
                      .map(f => f?.value)
                  })
                  onConfirm()
                }
              }
              isLoading={regenerateMutation.isLoading}
            >
              Regenerate Treatment plan
            </Button>
            <Button
              _focus={{ outline: 'none' }}
              variant="outline"
              isFullWidth
              size="lg"
              onClick={onClose}
              isDisabled={regenerateMutation.isLoading}
            >
              Cancel
            </Button>
          </VStack>
        </VStack>
      </DialogBody>
    </DialogContainer>
  )
}

export default RegenerationConfirmationDialog