import react from 'react'

const Note = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <rect
      y="0.5"
      width="32"
      height="32"
      rx="16"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M28 16.5C28 23.1274 22.6271 28.5 16 28.5C9.37294 28.5 4 23.1271 4 16.5C4 9.87293 9.37294 4.5 16 4.5C22.6271 4.5 28 9.87293 28 16.5Z"
      fill="#282828"
    />
    <rect x="11" y="11.5" width="7" height="2" fill="white" />
    <rect x="11" y="15.5" width="10" height="2" fill="white" />
    <rect x="11" y="19.5" width="10" height="2" fill="white" />
  </svg>
)

export default Note
