import React, { useMemo } from 'react'
import { BPDateTimePicker } from '../../components'
import { useStyles } from './styles'
import { endpoints } from '../../api/index'
import { useMutation, useQueryClient } from 'react-query'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import { DateTime } from 'luxon'
import { useStoreActions, useStoreState } from 'easy-peasy'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useBreakpointValue,
  Text,
  Box,
  Flex,
  ModalBody,
  ModalFooter,
  Tooltip
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'

export function AdjustDeliveryModal({
  patient,
  isModalOpen,
  handleModal,
  soonestSendDatesByUser,
  user_id,
  checkedAssessments,
  resetAllTaskBar
}) {
  const location = useLocation()
  const classes = useStyles()
  const queryClient = useQueryClient()
  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const deliveryAssignees = useMemo(() => {
    return soonestSendDatesByUser.map(delivery => {
      const assigneeUser = patient.assigneeUsers.find(
        user => user.id === delivery.assigneeUser.id
      )

      const date = DateTime.fromISO(delivery.nextAdministrationDate)
      return {
        user: assigneeUser,
        date
      }
    })
  }, [soonestSendDatesByUser, patient.assigneeUsers])

  const { mutateAsync: executeAdjustDeliveryDate } = useMutation(
    endpoints.patchNextAssessmentDeliveryDate.request,
    {
      onError: () => {
        setSnackbarMessage({
          variant: 'error',
          message: 'Error saving next assessment date and times'
        })
        handleModal()
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          endpoints.getClinicianUserNextDeliveryDate.getCacheId()
        )
        await queryClient.invalidateQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])
        setSnackbarMessage({
          variant: 'success',
          message: 'Next assessment date and time saved!'
        })
        handleModal()
      }
    }
  )

  function getSoonestNextSendDate(assessments) {
    let closestAssessment = null
    const todaysDate = new Date()
    let minTimeDifference = Infinity

    for (let assessment of assessments) {
      const assessmentDate = new Date(assessment.date)
      const timeDifference = Math.abs(todaysDate - assessmentDate)

      if (timeDifference < minTimeDifference) {
        minTimeDifference = timeDifference
        closestAssessment = assessment
      }
    }

    return closestAssessment ? [closestAssessment] : []
  }

  const filteredAssignees = deliveryAssignees.filter(item => {
    return Object.values(checkedAssessments).some(array =>
      array.some(filterItem => filterItem.id === item.user.id)
    )
  })

  const soonestNextSend = getSoonestNextSendDate(filteredAssignees)

  const validiationSchema = useMemo(() => {
    const shape = soonestNextSend.reduce((o, deliveryAssignee) => {
      o[deliveryAssignee.user.id] = Yup.date()
        .min(DateTime.now(), 'Next assessment date must be in the future')
        .required(
          'You must provide a date and time for the next assessment to be delivered'
        )
        .typeError('Please enter a valid date')
      return o
    }, {})

    return Yup.object().shape(shape)
  }, [soonestNextSend])

  const initialValues = useMemo(() => {
    return soonestNextSend.reduce((o, deliveryAssignee) => {
      o[deliveryAssignee.user.id] = deliveryAssignee.date.toFormat(
        'MM-DD-YYYY hh:mm a'
      )
      return o
    }, {})
  }, [soonestNextSend])
  return (
    <>
      <Modal
        onClose={handleModal}
        size={'md'}
        isOpen={isModalOpen}
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'24px'}>Adjust Next Send</ModalHeader>
          <ModalCloseButton
            h={'24px'}
            w={'24px'}
            top={'18px'}
            right={'18px'}
            fontWeight={'bold'}
          />
          <Formik
            validationSchema={validiationSchema}
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              const date = Object.values(values)[0]
              const data = Object.entries(filteredAssignees).map(
                ([key, value]) => {
                  return {
                    assigneeUserId: value.user.id,
                    date: moment(date, 'MM/DD/YYYY hh:mm a'),
                    data: checkedAssessments || []
                  }
                }
              )
              await executeAdjustDeliveryDate({
                patientId: patient.id,
                data: {
                  assigneeOverrideDates: data
                }
              })
              if (checkedAssessments) {
                resetAllTaskBar()
              }
              actions.setSubmitting(false)
              actions.resetForm({})
            }}
          >
            {({ errors, isSubmitting, isValid, dirty }) => {
              return (
                <Form>
                  <ModalBody>
                    <Text fontSize={'16px'}>
                      Update the Next Send date and time for the selected
                      Assessments.
                    </Text>
                    {soonestNextSend.map(deliveryAssignee => (
                      <Box mt="small" key={deliveryAssignee.user.id}>
                        <Field
                          name={deliveryAssignee.user.id}
                          id="enrollment-date-picker"
                        >
                          {({ field, form }) => (
                            <BPDateTimePicker
                              {...field}
                              autoFocus
                              showDate
                              showTime
                              form={form}
                              fieldName={field.name}
                              initialMeridian={deliveryAssignee.date
                                .toFormat('a')
                                .toLowerCase()}
                              isRequired
                              label="Choose a date and time"
                              isInvalid={errors[deliveryAssignee.user.id]}
                              errorText={errors[deliveryAssignee.user.id]}
                            />
                          )}
                        </Field>
                      </Box>
                    ))}
                  </ModalBody>
                  <ModalFooter display="block">
                    <Flex flex={1} justify={'center'} align={'center'}>
                      <Button
                        size="lg"
                        backgroundColor="white"
                        onClick={handleModal}
                        isFullWidth
                        variant="outline"
                      >
                        Cancel
                      </Button>
                      <Button
                        size="lg"
                        isLoading={isSubmitting}
                        isDisabled={!isValid || !dirty}
                        type="submit"
                        isFullWidth
                      >
                        Update
                      </Button>
                    </Flex>
                  </ModalFooter>
                </Form>
              )
            }}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}

export const AdjustDeliveryModalConnected = () => {
  const user = useStoreState(state => state.auth.user)
  const { isOpen, patientId } = useStoreState(
    state => state.modals.adjustDeliveryDate
  )
  const handleCloseModal = useStoreActions(
    actions => actions.modals.adjustDeliveryDate.closeModal
  )

  return patientId && user ? (
    <AdjustDeliveryModal
      isModalOpen={isOpen}
      patientId={patientId}
      handleModal={handleCloseModal}
    />
  ) : null
}
