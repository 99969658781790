import React from 'react'
import { Stack, Box, Text, Button } from '@chakra-ui/react'
import { useAssistPanelContext } from '../context'
import { useTreatmentPlan } from '@hooks'
import { CompletedInterventions } from './completed-intervention-list'
import { SavedForLater } from './saved-for-later'
import { Suggestions } from './suggestions'
import { InProgress } from './in-progress'

export const Interventions = () => {
  const { client } = useTreatmentPlan()
  const { setOverlayPanel } = useAssistPanelContext()

  // const handleBrowseLibraryClick = () => {
  //   setOverlayPanel('INTERVENTION_LIBRARY', {
  //     onAssign: handleAssignWorksheet
  //   })
  // }

  return (
    <Stack
      px="24px"
      spacing="0"
      flex="1"
      py="24px"
      gap="24px"
      h="calc(100vh - 208px)"
      overflowY="scroll"
    >
      <Box>
        <InProgress />
        <SavedForLater />
        <Suggestions />
        <CompletedInterventions />
      </Box>
    </Stack>
  )
}
