import * as Nodes from './nodes'
import { NewActionNode } from './nodes/actions/new-action-node'
import { NewTriggerNode } from './nodes/triggers/new-trigger-node'

export { NewActionNode }
export { NewTriggerNode }

export type NodeProps = {
  type: NodeType
  config: any
  hasChildren: boolean
  isParent: boolean
  isSelected: boolean
  onClick: Function
}

export const triggers = {
  'assessment-submitted-trigger': Nodes.AssessmentSubmittedTrigger,
  'upcoming-appointment-trigger': Nodes.UpcomingAppointmentTrigger,
  'program-started-trigger': Nodes.ProgramStartedTrigger,
  'new-trigger-node': Nodes.NewTriggerNode
}

export const actions = {
  'assign-assessment': Nodes.AssignAssessment,
  'mark-client-high-risk': Nodes.MarkClientHighRisk,
  'show-assessment-prompt': Nodes.ShowAssessmentPrompt,
  'recommend-intervention': Nodes.RecommendIntervention,
  'new-action-node': Nodes.NewActionNode,
  'show-safety-net': Nodes.ShowSafetyNet,
  'send-client-notification': Nodes.SendClientNotification,
  'send-email': Nodes.SendEmail,
  'assign-symptom-tracker': Nodes.AssignSymptomTracker,
  'assign-worksheet': Nodes.AssignWorksheet,
  'assign-program': Nodes.AssignProgram,
  'enroll-ehr-client': Nodes.EnrollEHRClient,
  'send-appointment-reminder': Nodes.SendAppointmentReminder,
  'unarchive-ehr-client': Nodes.UnarchiveEHRClient
}

export const nodeCatalog = { ...actions, ...triggers }

export type NodeType = keyof typeof triggers | keyof typeof actions
