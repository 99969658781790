import React from 'react'
import { Box, Text, VStack, HStack, Divider } from '@chakra-ui/react'
import { ArrowRightIcon, Button } from '@blueprinthq/joy'

import { useTreatmentPlan } from '@hooks'

export const InProgress = () => {
  const { client } = useTreatmentPlan()

  const interventionsInProgress = [
    { id: 1, name: 'Leaves on a Stream' },
    { id: 3, name: 'Model of Emotions' }
  ]

  return (
    <Box mb="small">
      <Text fontWeight="bold" mb="xsmall">
        In Progress
      </Text>
      <Box border="1px solid" borderColor="pale_gray" rounded="md">
        {interventionsInProgress?.length === 0 ? (
          <Text p="small">No interventions in progress.</Text>
        ) : (
          <VStack
            flex={1}
            alignItems="flex-start"
            divider={
              <Divider
                color="pale_gray"
                mt="0px !important"
                mb="0px !important"
              />
            }
          >
            {interventionsInProgress.map(s => (
              <HStack
                key={s.id}
                px="small"
                py="xsmall"
                alignItems="center"
                justify="space-between"
                w="100%"
              >
                <Box>
                  <Text>{s.name}</Text>
                </Box>
                <HStack spacing={3}>
                  <Text
                    fontSize="12px"
                    color="#C6162C"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => {}}
                  >
                    Discard
                  </Text>
                  <Button
                    fontSize="12px"
                    px="medium"
                    py="xsmall"
                    size="small"
                    onClick={() => {}}
                  >
                    Resume
                  </Button>
                </HStack>
              </HStack>
            ))}
          </VStack>
        )}
      </Box>
    </Box>
  )
}
