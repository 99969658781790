import React, { useState, useMemo, useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Heading,
  HStack,
  VStack
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { Button } from '@blueprinthq/joy'
import { TextField } from '@components/forms/formik_inputs'

import { useQuery, useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'
import { PrimaryButton } from '../../components'
import { X } from '../../components/icons'
import { endpoints } from '../../api'
import { useStoreActions, useStoreState } from 'easy-peasy'

// The contact info modal may require all contact info or only one piece
// of contact info depending on where it is launched from. This object contains
// the pieces of configuration which may change based on that.
const contactInfoRequirements = {
  all: {
    schema: Yup.object().shape({
      phone_number: Yup.string()
        .phone(['US', 'CA'], true, 'Invalid U.S. or Canadian phone number.')
        .required('Phone is required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Email is required')
    }),
    request:
      'Please enter a valid and unique email address and phone number below. After submitting, please try again.'
  },

  any: {
    schema: Yup.object().shape({
      phone_number: Yup.string()
        .phone(['US', 'CA'], true, 'Invalid U.S. or Canadian phone number.')
        .test(function(value) {
          const { email } = this.parent
          if (!email) return value != null
          return true
        }),
      email: Yup.string().test(function(value) {
        const { phone_number } = this.parent
        if (!phone_number) return value != null
        return true
      })
    }),
    request:
      'Please enter a valid and unique email address or phone number below.'
  }
}

const renderError = error => {
  if (error) {
    return <Box color="error">{error}</Box>
  }

  return null
}

export const ContactInfoModal = ({
  isOpen,
  patientId,
  handleCloseModal,
  contactInfoRequirement
}) => {
  const queryClient = useQueryClient()
  const [error, setError] = useState(null)
  const requirements = contactInfoRequirements[contactInfoRequirement]

  const { data: patient } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), patientId],
    () => endpoints.getClinicianUserAccount.request({ id: patientId }),
    {
      enabled: isOpen && !!patientId
    }
  )

  const { mutate: updateUserInfo, isLoading, isSuccess } = useMutation(
    endpoints.patchClinicianUserInfo.request,
    {
      onError() {
        setError(
          "We're sorry, something went wrong trying to process your request. Please refresh the page and try again."
        )
      },
      onSuccess() {
        queryClient.invalidateQueries([
          endpoints.getClinicianUserAccount.getCacheId(),
          patientId
        ])
        queryClient.invalidateQueries([endpoints.getPatientList.getCacheId()])
        handleCloseModal()
      }
    }
  )

  const submit = values => {
    updateUserInfo({
      patientId: patient.id,
      data: values
    })
  }

  if (!patient) return null

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent p="small">
        <ModalHeader>More Contact Info Required</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              email: (patient && patient.email) || '',
              phone_number: (patient && patient.phone_number) || ''
            }}
            onSubmit={submit}
            validationSchema={requirements.schema}
          >
            {({ errors, touched, isSubmitting, dirty }) => (
              <Form>
                <VStack spacing="medium" alignItems="flex-start">
                  <Box>
                    In order to do that, we need additional contact information
                    for this client.
                  </Box>
                  <Box>{requirements.request}</Box>
                  {renderError(error)}

                  <TextField name="phone_number" type="text" label="Phone" />
                  <TextField name="email" type="text" label="Email" />
                  <HStack w="100%">
                    <Button
                      onClick={handleCloseModal}
                      mt="xsmall"
                      size="lg"
                      variant="outline"
                      isFullWidth
                    >
                      Cancel
                    </Button>
                    <Button
                      isDisabled={!dirty}
                      mt="xsmall"
                      size="lg"
                      isLoading={isSubmitting}
                      type="submit"
                      isFullWidth
                    >
                      Save
                    </Button>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const ContactInfoModalConnected = () => {
  const user = useStoreState(state => state.auth.user)
  const { isOpen, patientId, contactInfoRequirement } = useStoreState(
    state => state.modals.contactInfo
  )
  const handleCloseModal = useStoreActions(
    actions => actions.modals.contactInfo.closeModal
  )

  return patientId && user ? (
    <ContactInfoModal
      isOpen={isOpen}
      patientId={patientId}
      handleCloseModal={handleCloseModal}
      contactInfoRequirement={contactInfoRequirement}
    />
  ) : null
}
