import React from 'react'
import { Dialog, DialogTitle, Toolbar, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { isMobile } from 'react-device-detect'
import JotformEmbed from '../JotformEmbed'

export default function IframeModal(props) {
  const handleClose = () => {
    props.handleClose()
  }

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      open={props.isOpen}
      onClose={props.handleClose}
      maxWidth="xl"
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{props.title}</DialogTitle>
      </Toolbar>
      <JotformEmbed src={props.src} scrolling />
    </Dialog>
  )
}
