import React, { useState, useEffect } from 'react'
import {
  Box,
  Flex,
  Text,
  Button,
  IconButton,
  Link,
  HStack
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import { Clipboard } from '@components/icons'
import {
  AssessmentSuggestionDTO,
  InterventionSuggestionDTO,
  WorksheetSuggestionDTO
} from '~/clinician-api/models'
import {
  getSessionControllerGetSuggestedContentQueryKey,
  useContentSuggestionControllerSubmitFeedback
} from '~/clinician-api'
import Feedback from './feedback'
import { ThumbUpIcon, ThumbDownIcon } from '@icons'

interface ContentSuggestionItemProps {
  type: 'worksheet' | 'assessment' | 'intervention'
  suggestion: (
    | AssessmentSuggestionDTO
    | WorksheetSuggestionDTO
    | InterventionSuggestionDTO
  ) & {
    name: string
  }
  sessionId: string
  onAssign: () => void
  previewUrl: string
  isDemo?: boolean
}

export const ContentSuggestionItem = ({
  isDemo,
  type,
  suggestion,
  sessionId,
  onAssign,
  previewUrl
}: ContentSuggestionItemProps) => {
  const queryClient = useQueryClient()
  const [commentsOpen, setCommentsOpen] = useState(false)
  const [rating, setRating] = useState<null | string>(
    suggestion.feedback?.rating || null
  )
  const [comments, setComments] = useState<string>('')

  useEffect(() => {
    setRating(suggestion.feedback?.rating || null)
  }, [suggestion.feedback?.rating])

  useEffect(() => {
    setComments('')
    setCommentsOpen(false)
  }, [suggestion.contentSuggestionId])

  const {
    mutateAsync: submitFeedback
  } = useContentSuggestionControllerSubmitFeedback({
    mutation: {
      onSuccess: () => {
        const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
          sessionId
        )
        queryClient.invalidateQueries({ queryKey: suggestionListKey })
      }
    }
  })

  const handleFeedback = async (value: string) => {
    setRating(value)
    setComments('')
    setCommentsOpen(true)
    await submitFeedback({
      contentSuggestionId: suggestion.contentSuggestionId,
      data: { rating: value }
    })
  }

  return (
    <Box bg="white" borderRadius="8px" border="1px solid #E4E5E6">
      <Box p={4}>
        <Flex alignItems="center" justifyContent="space-between" mb={2}>
          <Flex alignItems="center">
            <Clipboard fill="black" />
            <Text ml={2} fontWeight="bold">
              {suggestion.name}
            </Text>
          </Flex>
        </Flex>
        <Box mb={4}>
          <Text color="dark_gray" fontSize="12px" lineHeight="16px">
            {suggestion.rationale}
          </Text>
        </Box>
        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <HStack alignItems="center">
                <IconButton
                  aria-label="thumbs-up"
                  isRound={false}
                  variant="outline"
                  color="primary"
                  _focus={{
                    boxShadow: 'none'
                  }}
                  _hover={{
                    background: '#F5F5F7'
                  }}
                  m={0}
                  size="sm"
                  px="8px"
                  borderRadius="4px"
                  onClick={isDemo ? () => {} : () => handleFeedback('good')}
                  bg={rating === 'good' ? '#F5F5F7' : 'white'}
                  icon={<ThumbUpIcon fill="#2D54E8" size="14" />}
                />
                <IconButton
                  aria-label="thumbs-down"
                  isRound={false}
                  variant="outline"
                  color="primary"
                  _focus={{
                    boxShadow: 'none'
                  }}
                  _hover={{
                    background: '#F5F5F7'
                  }}
                  m={0}
                  size="sm"
                  px="8px"
                  borderRadius="4px"
                  onClick={isDemo ? () => {} : () => handleFeedback('bad')}
                  bg={rating === 'bad' ? '#F5F5F7' : 'white'}
                  icon={<ThumbDownIcon fill="#2D54E8" size="14" />}
                />
              </HStack>
            </Box>
            <Box>
              {previewUrl && (
                <Link
                  href={previewUrl}
                  target="_blank"
                  color="primary"
                  textDecoration="none"
                  _focus={{ outline: 'none' }}
                  mr={4}
                >
                  Preview
                </Link>
              )}
              <Button
                textDecoration="none"
                borderRadius="4px"
                size="sm"
                _focus={{ outline: 'none' }}
                onClick={onAssign}
              >
                {type === 'intervention' ? 'Save for Later' : 'Assign'}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>
      {commentsOpen && rating && (
        <Feedback
          rating={rating}
          setCommentsOpen={setCommentsOpen}
          comments={comments}
          setComments={setComments}
          submitFeedback={submitFeedback}
          suggestionId={suggestion.contentSuggestionId}
        />
      )}
    </Box>
  )
}
