import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Text, Flex } from '@chakra-ui/react'
import { CheckCircle } from '@blueprinthq/joy'

export function InterventionComplete() {
  const location = useLocation()
  const type = new URLSearchParams(location.search).get('type')

  const turnOffUnloadAlert = () => {
    window.onbeforeunload = () => null
  }

  const turnOnUnloadAlert = () => {
    window.onbeforeunload = e => {
      e.preventDefault()
      return e.returnValue
    }
  }

  useEffect(() => {
    if (type) turnOffUnloadAlert()
    else turnOnUnloadAlert()
  }, [type])

  return (
    <Container centerContent height={'xl'} mt={'64px'}>
      <Flex
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <CheckCircle size={'lg'} fill={'#2d54e8'} mb={'32px'} />
        <Text fontWeight={'bold'} fontSize={'24px'} mb={'16px'}>
          {type === 'draft' ? 'Intervention Saved' : 'Intervention Completed'}
        </Text>
        <Text>You can close this window</Text>
      </Flex>
    </Container>
  )
}
