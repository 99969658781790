import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'

export const MarkClientHighRisk = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const body = (
    <Text>
      Mark client as <Text as="b">High-Risk</Text>
    </Text>
  )

  return (
    <WorkflowNode
      heading="Mark as high-risk Action"
      body={body}
      hasChildren={hasChildren}
      isParent={isParent}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
