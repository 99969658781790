import React from 'react'
import { HStack, Button, Box, useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import {
  AssignAssessmentsForm,
  AssignAssessmentsFormFields
} from '../shared/assist/assign-assessments-form'
import {
  AssistEnrollmentForm,
  AssistEnrollmentFormFields,
  ActionButtons
} from '../shared/assist/assist-enrollment-form'
import {
  DialogContainer,
  DialogHeader,
  DialogBody,
  DialogFooter
} from '@handlers/sessions/components/dialog'
import Library from '@handlers/sessions/components/shared/assist/assessments/library'
import { useCadenceOptions } from '@handlers/sessions/components/shared/use-cadence-options'
import {
  usePatientControllerAssistEnroll,
  useContentSuggestionControllerAccept,
} from '~/clinician-api'
import { AssignableAssessment, AssignAssessmentsFormValues, Patient, AssistEnrollmentFormValues } from '@handlers/sessions/types'
import { endpoints } from '@api'


interface AssignAssessmentsModalProps {
  isOpen: boolean
  onClose: () => void
  assessment: AssignableAssessment | null
  selectAssessment: (assessment: any) => void
  client: Patient
  onSuccess?: () => void
}

export const AssignAssessmentsModal = ({
  isOpen,
  onClose,
  assessment,
  client,
  selectAssessment,
  onSuccess,
}: AssignAssessmentsModalProps) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { cadenceMapping } = useCadenceOptions()

  const { mutate: acceptSuggestion } = useContentSuggestionControllerAccept()

  const {
    mutateAsync: enrollInAssist,
    isLoading: isEnrolling
  } = usePatientControllerAssistEnroll()

  const invalidateQueries = () => {
    queryClient.invalidateQueries([
      endpoints.getClinicianUserAccount.getCacheId(),
      client.id
    ])
    queryClient.invalidateQueries([
      endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
      client.id
    ])
    queryClient.invalidateQueries([
      endpoints.getClinicianUserActiveAssessments.getCacheId(),
      client.id
    ])
    queryClient.invalidateQueries([
      endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
      client.id
    ])
  }

  const { mutate: assignAssessment, isLoading: isAssigning } = useMutation(
    endpoints.postClientAssignAssessments.request,
    {
      onSuccess: async () => {
        if (assessment?.contentSuggestionId) {
          acceptSuggestion({
            contentSuggestionId: assessment.contentSuggestionId
          })
        }
        if (onSuccess) onSuccess()
        invalidateQueries()
        onClose()
        toast({
          title: 'Assessment assigned',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
      }
    }
  )

  const handleEnroll = async (values: AssistEnrollmentFormValues) => {
    if (values.assessment) {
      enrollInAssist(
        {
          patientId: client.id,
          data: {
            email: values.email,
            phone: values.phone,
            assessments: [{
              clinicAssessmentId: values.assessment.clinicAssessmentId,
              cadence: cadenceMapping[values.assessment.cadence],
              suggestionId: assessment?.contentSuggestionId
            }],
            checkIns: []
          }
        },
        {
          onSuccess: () => {
            if (onSuccess) onSuccess()
            invalidateQueries()
            onClose()
            toast({
              title: 'Assessment assigned',
              status: 'success',
              isClosable: true,
              duration: 1200
            })
          },
          onError: () => {
            toast({
              title: 'Error',
              description: 'There was an error assigning this assessment',
              status: 'error',
              isClosable: true,
              duration: 2000
            })
          }
        }
      )
    }
  }

  const handleAssignAssessment = async (values: AssignAssessmentsFormValues) => {
    assignAssessment({
      clientId: client.id,
      data: {
        assignments: [{
          clinicAssessmentId: values.clinicAssessmentId,
          cadence: cadenceMapping[values.cadence],
          suggestionId: assessment?.contentSuggestionId,
          assigneeUserId: client.user_id
        }],
        administerNow: !values.nextAdministrationDate
      }
    })
  }

  const renderEnrollmentForm = () => {
    return (
      <DialogBody p={0}>
        <AssistEnrollmentForm
          client={client}
          onSubmit={handleEnroll}
          assessment={assessment}
        >
          {({ formikProps, allAssessments }: any) => (
            <>
              <Box p={6}>
                <AssistEnrollmentFormFields
                  formikProps={formikProps}
                  assessments={allAssessments}
                  client={client}
                />
              </Box>

              <DialogFooter bg="#FAFAFA" p={6}>
                <ActionButtons
                  onCancel={onClose}
                  onSubmit={formikProps.submitForm}
                  isSubmitting={isAssigning || isEnrolling}
                />
              </DialogFooter>
            </>
          )}
        </AssistEnrollmentForm>
      </DialogBody>
    )
  }

  const renderAssignForm = () => {
    if (!assessment) return null

    return (
      <DialogBody p={0}>
        <AssignAssessmentsForm
          client={client}
          assessment={assessment}
          onSubmit={handleAssignAssessment}
        >
          {({ formikProps, allAssessments }: any) => (
            <>
              <Box p={6}>
                <AssignAssessmentsFormFields
                  formikProps={formikProps}
                  assessments={allAssessments}
                  client={client}
                />
              </Box>
              <DialogFooter>
                <HStack spacing={4} w="100%">
                  <Button onClick={onClose} variant="outline" isFullWidth>
                    Cancel
                  </Button>
                  <Button
                    isFullWidth
                    isLoading={isAssigning || isEnrolling}
                    onClick={formikProps.submitForm}
                  >
                    Assign
                  </Button>
                </HStack>
              </DialogFooter>
            </>
          )}
        </AssignAssessmentsForm>
      </DialogBody>
    )
  }

  const showLibary = !assessment
  const title = showLibary ? 'Assessment Library' : 'Assign Assessment'
  return (
    <DialogContainer isOpen={isOpen} onClose={onClose} size="2xl">
      <DialogHeader
        text={title}
        onClose={onClose}
        hideBorder={showLibary}
        onBack={showLibary || !!assessment?.contentSuggestionId ? undefined : () => selectAssessment(null)}
      />
      {showLibary ? (
        <DialogBody p={0}>
          <Library
            client={client}
            onAssign={selectAssessment}
            searchContainerProps={{
              py: 1,
              pb: 6,
              height: 'auto'
            }}
          />
        </DialogBody>
      ) : (
        client.enrollment_timestamp ? (
          renderAssignForm()
        ) : (
          renderEnrollmentForm()
        )
      )}
    </DialogContainer>
  )
}