import React, { useState, useMemo, useCallback } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Box, Flex, Heading, Text, Portal, useToast } from '@chakra-ui/react'
import { Button, GoBackwardIcon } from '@blueprinthq/joy'
import { useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import {
  InsurancePayersPage,
  CptCodePage,
  BillableUnitsPage,
  ModifiersPage
} from './pages'
import { endpoints } from '../../../../api'
import { Loading } from '../../../../components'

const pages = [
  {
    title: 'Payers',
    subtitle: 'Select all the payers that apply to your practice',
    description: 'You can always add and update this list later!',
    Component: InsurancePayersPage
  },
  {
    title: 'CPT Codes',
    subtitle: 'Which CPT codes do you plan on using?',
    description: 'Must select at least one. Select all that apply.',
    Component: CptCodePage
  },
  {
    title: 'Units',
    subtitle: 'How are billable units determined?',
    description:
      'For each CPT code, determine how we should calculate the units.',
    Component: BillableUnitsPage
  },
  {
    title: 'Modifiers',
    subtitle: 'Which modifiers do you plan on using?',
    description: 'You can always add and update this later.',
    Component: ModifiersPage
  }
]

export const Settings = () => {
  const history = useHistory()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { selectedClinic } = useStoreState(state => state.billing)

  const organizationId = selectedClinic.organization_id

  const [currentPage, setCurrentPage] = useState(0)
  const [data, setData] = useState({
    insurancePayers: [],
    cptCodes: [],
    modifiers: []
  })

  const {
    mutateAsync: patchBillingPreferences,
    isLoading: isPatchingBillingPreferences,
    isSuccess: isPatchBillingPreferencesSuccess
  } = useMutation(endpoints.patchOrgBillingPreferences.request, {
    onSuccess() {
      toast({
        title: 'Success!',
        description: 'Billing preferences have been saved!',
        duration: 3000,

        isClosable: true,
        status: 'success'
      })
      queryClient.invalidateQueries([
        [
          endpoints.getOrgBillingPreferences.getCacheId(),
          selectedClinic.organization_id
        ],
        [
          endpoints.getOrgBillingRules.getCacheId(),
          selectedClinic.organization_id
        ],
        [
          endpoints.getInsurancePayers.getCacheId(),
          selectedClinic.organization_id
        ]
      ])
      history.push('/reports/claims/rules')
    },
    onError() {
      toast({
        title: 'Error',
        description:
          'Billing preferences could not be saved. Please contact support for help.',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
    }
  })

  const {
    isLoading: isPreferencesLoading,
    isSuccess: isPreferencesSuccess
  } = useQuery(
    [endpoints.getOrgBillingPreferences.getCacheId(), organizationId],
    () =>
      endpoints.getOrgBillingPreferences.request({
        organizationId
      }),
    {
      onSuccess(preferences) {
        setData({
          ...data,
          modifiers: _.uniq([...data.modifiers, ...preferences.modifiers]),
          cptCodes: _.uniqBy(
            [...data.cptCodes, ...preferences.cptCodes],
            'code'
          )
        })
      }
    }
  )

  const { isLoading: isOrgPayersLoading } = useQuery(
    [endpoints.getInsurancePayers.getCacheId(), organizationId, true],
    () =>
      endpoints.getInsurancePayers.request({ organizationId, shortList: true }),
    {
      enabled: isPreferencesSuccess && data.cptCodes.length > 0,
      initialData: {
        meta: {},
        nodes: []
      },

      onSuccess(orgInsurancePayers) {
        setData({
          ...data,
          insurancePayers: _.uniqBy(
            [...data.insurancePayers, ...orgInsurancePayers.nodes],
            'id'
          )
        })
      }
    }
  )

  const { data: billingOptions } = useQuery(
    [endpoints.getBillingOptions.getCacheId()],
    () => endpoints.getBillingOptions.request(),
    {
      initialData: {
        cptCodes: []
      }
    }
  )

  const onSubmit = useCallback(() => {
    patchBillingPreferences({
      organizationId,
      data: {
        cptCodes: data.cptCodes.map(option => {
          option.billableUnitsType = option.billableUnitsType.id
          return option
        }),
        modifiers: data.modifiers,
        insurancePayerIds: data.insurancePayers.map(payer => payer.id)
      }
    })
  }, [data, organizationId])

  const page = useMemo(() => pages[currentPage], [currentPage])

  const hasNextPage = currentPage !== pages.length - 1
  const hasPreviousPage = currentPage !== 0

  const actionButtonText = hasNextPage
    ? `Next: ${pages[currentPage + 1].title}`
    : 'Save & Close'
  const actionButtonFunc = hasNextPage
    ? () => setCurrentPage(currentPage + 1)
    : () => onSubmit()
  const backButtonFunc = hasPreviousPage
    ? () => setCurrentPage(currentPage - 1)
    : () => history.goBack()

  const isActionButtonDisabled = useMemo(() => {
    if (page.title === 'Payers') {
      return data.insurancePayers.length === 0
    } else if (page.title === 'CPT Codes') {
      return data.cptCodes.length === 0
    } else if (page.title === 'Units') {
      return !data.cptCodes.every(option => option.billableUnitsType !== null)
    }
    return false
  }, [data, page])

  return (
    <>
      <Box>
        <Flex justify="space-between" alignItems="center">
          <Box>
            <Button
              variant="link"
              leftIcon={<GoBackwardIcon size="sm" fill="primary" />}
              onClick={backButtonFunc}
            >
              Back
            </Button>
          </Box>
          <Box>
            {currentPage + 1} of 4 - {page.title}
          </Box>
          <Box>
            <Button variant="link" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Box>
        </Flex>
        <Flex justify="center" alignItems="center" flexDirection="column">
          {isPreferencesLoading || isOrgPayersLoading ? (
            <Loading />
          ) : (
            <>
              <Box mt="large">
                <Heading
                  as="h1"
                  width={{
                    base: '320px',
                    sm: '500px'
                  }}
                  align="center"
                >
                  {page.subtitle}
                </Heading>
                <Text align="center" mt="medium">
                  {page.description}
                </Text>
              </Box>
              <Box
                mt="large"
                width={{
                  base: '320px',
                  sm: '600px'
                }}
              >
                <page.Component
                  organizationId={organizationId}
                  data={data}
                  setData={setData}
                  billingOptions={billingOptions}
                />
              </Box>
            </>
          )}
        </Flex>
      </Box>
      <Portal>
        <Box
          position="fixed"
          bottom="0"
          bg="white"
          width="100%"
          height="96px"
          borderTop="1px solid"
          borderColor="pale_gray"
        >
          <Flex align="center" justify="center" height="100%">
            <Button
              isDisabled={isActionButtonDisabled}
              size="lg"
              width="296px"
              onClick={actionButtonFunc}
              isLoading={
                isPatchingBillingPreferences || isPatchBillingPreferencesSuccess
              }
            >
              {actionButtonText}
            </Button>
          </Flex>
        </Box>
      </Portal>
    </>
  )
}
