import {
  DialogBody, DialogContainer, DialogHeader
} from '@handlers/sessions/components/dialog'
import React from 'react'
import { useExperienceManager } from '@hooks'
import { AddClientForm } from '@handlers/sessions/components/add-client-form'

interface AddClientDialogProps {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onSubmit: ({
    firstName,
    lastName,
    dateOfBirth,
    pronoun

  }: {
    firstName: string
    lastName: string
    dateOfBirth: string | null
    pronoun: string | null
  }) => void
  showBackButton?: boolean
}

export const AddClientDialog = ({
  isOpen,
  isLoading,
  onClose,
  onSubmit,
  showBackButton = false
}: AddClientDialogProps) => {
  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  return (
    <DialogContainer isOpen={isOpen} onClose={onClose}>
      <DialogHeader
        text="Add Client"
        onBack={showBackButton ? onClose : undefined}
        onClose={!showBackButton ? onClose : undefined}
      />
      <DialogBody>
        <AddClientForm
          isLoading={isLoading}
          onSubmit={onSubmit}
          isEvidenceBasedCareEnabled={isEvidenceBasedCareEnabled}
        />
      </DialogBody>
    </DialogContainer>
  )
}
