export const themeConfig = {
  palette: {
    primary: {
      main: '#39ABD1',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#ffffff'
    },
    success: {
      main: '#36bca2',
      dark: '#36bca2' // kinda hacky, forces the app to break WCAG aXe in favor of tempus' preference
    },
    error: {
      main: '#D7303B'
    },
    disabled: {
      main: '#d1cfcf'
    },
    text: {
      contrastOnWhite: '#757575',
      contrastOnLightGray: '#6e6e6e',
      contrastOnGray: '#616161',
      contrastOnDarkGray: '#5c6366'
    },
    overdue: {
      main: '#ffb870'
    },
    trends: {
      positive: '#2e8552',
      negative: '#e02030'
    }
  },
  typography: {
    fontFamily: ['Nolan Next', 'Helvetica', 'Arial', 'sans-serif'],
    button: {
      textTransform: 'none'
    }
  },
  componentOverrides: {
    appBar: {
      paletteColor: 'secondary'
    },
    engagementChart: {
      positiveColor: '#39abd1',
      negativeColor: '#39abd1'
    },
    patientSettings: {
      backLinkColor: '#000000'
    },
  },
  styles: {
    loginForm: {
      '& a': {
        color: 'black'
      }
    }
  }
}