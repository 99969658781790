import _ from 'lodash'
import * as Yup from 'yup'
import moment from 'moment'

export const YupPhone = Yup.string().phone(
  ['US', 'CA'],
  true,
  'Invalid U.S. or Canadian phone number.'
)

export const validationSchema = (customizedFields = {}) => {
  const defaultSchema = {
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    dateOfBirth: Yup.date()
      .max(moment(), 'Date of birth must be in the past')
      .required('Required'),
    phoneNumber: Yup.string()
      .phone(['US', 'CA'], true, 'Invalid U.S. or Canadian phone number.')
      .notRequired(),
    email: Yup.string()
      .email('Invalid email')
      .notRequired(),
    clinician: Yup.object()
      .shape({
        id: Yup.string().required('Must assign clinician')
      })
      .required('Must assign clinician'),
    dateOfInvite: Yup.date()
      .min(moment(), 'Enrollment date must be in the future')
      .required('Required')
  }

  return Yup.object().shape(_.merge(defaultSchema, customizedFields))
}

export const newClientEnrollmentValidationSchema = Yup.object().shape({
  clientType: Yup.string(),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dateOfBirth: Yup.date()
    .max(moment(), 'Date of birth must be in the past')
    .required('Required'),
  phoneNumber: Yup.string()
    .phone(['US', 'CA'], true, 'Invalid U.S. or Canadian phone number.')
    .notRequired(),
  email: Yup.string()
    .email('Invalid email')
    .notRequired(),
  participants: Yup.array().of(
    Yup.object().shape(
      {
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string()
          .email('Invalid email')
          .when('phoneNumber', {
            is: value => Boolean(value),
            then: schema => schema.optional(),
            otherwise: schema => schema.required('Email and/or Phone required')
          }),
        phoneNumber: Yup.string()
          .phone(['US', 'CA'], true, 'Invalid U.S. or Canadian phone number')
          .when('email', {
            is: value => Boolean(value),
            then: schema => schema.optional(),
            otherwise: schema => schema.required('Phone and/or Email required')
          }),
        type: Yup.object()
          .shape({
            id: Yup.string(),
            displayName: Yup.string()
          })
          .required()
      },
      ['email', 'phoneNumber']
    )
  ),
  nowOrLater: Yup.string(),
  dateOfInvite: Yup.date().when('nowOrLater', {
    is: 'later',
    then: Yup.date()
      .min(moment(), 'Enrollment date must be in the future')
      .required('Required')
  }),
  clinician: Yup.object()
    .shape({
      id: Yup.string().required('Must assign clinician')
    })
    .required('Must assign clinician'),
  clinic: Yup.object()
    .shape({
      id: Yup.string().required('Must assign clinic')
    })
    .required('Must assign clinic')
})
