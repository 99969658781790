import React from 'react'
import { Grid, GridItem, Input, useToast, IconButton } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'

import { TrashIcon } from '@icons'
import { endpoints } from '@api'
import { SupportResource } from './safety-net-editor'
import { usePermissions } from '../../../hooks'

interface Props extends SupportResource {
  clinicId: string
}

const typeMap = {
  web: "Web",
  call: "Call",
  sms: "Text"
}

export const Resource = ({ id, displayText, type, value, clinicId }: Props) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { hasPermission }= usePermissions()

  const isEditView = hasPermission('update:clinic:settings:safety-net')

  const { mutate: deleteResource }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.deleteSupportResource.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getClinicSafetyNetResources.getCacheId()
        )
        toast({
          description: 'Resource removed!',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  return (
    <Grid templateColumns="repeat(18, 1fr)" gap={3} w="100%" mb="xsmall">
      <GridItem colSpan={6}>
        <Input
          _hover={{ cursor: 'initial' }}
          borderColor="pale_gray"
          focusBorderColor="pale_gray"
          value={displayText}
          isReadOnly
        />
      </GridItem>
      <GridItem colSpan={3}>
        <Input
          _hover={{ cursor: 'initial' }}
          borderColor="pale_gray"
          focusBorderColor="pale_gray"
          value={typeMap[type as keyof typeof typeMap]}
          isReadOnly
        />
      </GridItem>
      <GridItem colSpan={isEditView ? 8 : 9}>
        <Input
          _hover={{ cursor: 'initial' }}
          borderColor="pale_gray"
          focusBorderColor="pale_gray"
          value={value}
          isReadOnly
        />
      </GridItem>
      {isEditView && (
        <GridItem colSpan={1} display="flex" alignItems="center">
          <IconButton
            aria-label="Delete resource"
            isRound={true}
            icon={<TrashIcon />}
            variant='ghost'
            _hover={{ bg: "pale_gray" }}
            onClick={() => deleteResource({ clinicId, resourceId: id })}
          />
        </GridItem>
      )}
    </Grid>
  )
}
