import React, { useContext } from 'react'
import { Text, Box } from '@chakra-ui/react'
import { Formik, Form, Field, FieldArray } from 'formik'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { WorkflowsContext } from '@context'
import { Loading, SearchSelect } from '@components'
import { useOrganizationAssessments } from '../../../queries'
import { EditDrawerFooter } from '../../../edit-drawer/edit-drawer-footer'
import { FilterForm } from '../shared-filters/filter-form'
import { FilterCatalog, filterNames } from './filters/filter-catalog'

type RouteParams = {
  workflowId: string
  organizationId: string
}

type Props = {
  closeDrawer: () => void
  onSubmit: (trigger: Trigger) => void
}

type Trigger = {
  workflowId: string
  triggerType: string
  config: TriggerConfig | {}
}

type TriggerConfig = {
  assessmentId: string
  filters?: (
    | QuestionAnswerFilter
    | TotalScoreFilter
    | PointChangeFilter
    | PercentChangeFilter
  )[]
}

type QuestionAnswerFilter = {
  type: string
  triggerAnswers: number[]
  questionKey: string
}

type TotalScoreFilter = {
  type: string
  totalScoreOperator: string
  totalScoreValues: number[]
}

type PointChangeFilter = {
  type: string
  pointChangeOperator: string
  pointChangeValue: number
  pointChangeReferenceScoreType: string
}

type PercentChangeFilter = {
  type: string
  percentChangeOperator: string
  percentChangeValue: number
  percentChangeReferenceScoreType: string
}

type Answer = {
  title: string
  value: number
}

type Question = {
  key: string
  title: string
  answers: Answer[]
}

type Mapping = {
  min: number
  max: number
  title?: string
  label?: string
}

export type Assessment = {
  id: string
  name: string
  fullName: string
  questions: Question[]
  mapping: Mapping[] | undefined
  totalScore: number
  cadence: { unit: string; value: number }
}

type QueryResult = {
  data: Assessment[] | undefined
  isLoading: boolean
}

const schema = Yup.object().shape({
  assessment: Yup.object().shape({
    value: Yup.string().required(),
    label: Yup.string().required()
  }),
  questionKey: Yup.string().when('filterTypes', {
    is: (types: string[]) => types.includes('question-answer'),
    then: Yup.string().required()
  }),
  triggerAnswers: Yup.array().when('filterTypes', {
    is: (types: string[]) => types.includes('question-answer'),
    then: Yup.array()
      .of(Yup.number().required())
      .min(1)
      .required()
  }),
  totalScoreOperator: Yup.string().when('filterTypes', {
    is: (types: string[]) => types.includes('total-score'),
    then: Yup.string().required()
  }),
  totalScoreValues: Yup.array().when('filterTypes', {
    is: (types: string[]) => types.includes('total-score'),
    then: Yup.array().when('totalScoreOperator', {
      is: 'between-inclusive',
      then: Yup.array()
        .of(Yup.string().required())
        .min(2)
        .required(),
      otherwise: Yup.array()
        .of(Yup.string().required())
        .min(1)
        .required()
    }),
    otherwise: Yup.array().optional()
  }),
  pointChangeOperator: Yup.string().when('filterTypes', {
    is: (types: string[]) => types.includes('point-change'),
    then: Yup.string().required()
  }),
  pointChangeValue: Yup.number().when('filterTypes', {
    is: (types: string[]) => types.includes('point-change'),
    then: Yup.number().required()
  }),
  pointChangeReferenceScoreType: Yup.string().when('filterTypes', {
    is: (types: string[]) => types.includes('point-change'),
    then: Yup.string().required()
  }),
  percentChangeOperator: Yup.string().when('filterTypes', {
    is: (types: string[]) => types.includes('percent-change'),
    then: Yup.string().required()
  }),
  percentChangeValue: Yup.number().when('filterTypes', {
    is: (types: string[]) => types.includes('percent-change'),
    then: Yup.number().required()
  }),
  percentChangeReferenceScoreType: Yup.string().when('filterTypes', {
    is: (types: string[]) => types.includes('percent-change'),
    then: Yup.string().required()
  }),
  filterTypes: Yup.array()
    .of(Yup.string().optional())
    .optional()
})

const determineFilterConfig = (filterType: string, values: any) => {
  switch (filterType) {
    case 'question-answer':
      return {
        type: filterType,
        triggerAnswers: values.triggerAnswers.map((v: any) => Number(v)),
        questionKey: values.questionKey
      }
    case 'total-score':
      return {
        type: filterType,
        totalScoreOperator: values.totalScoreOperator,
        totalScoreValues: values.totalScoreValues.map((v: any) => Number(v))
      }
    case 'point-change':
      return {
        type: filterType,
        pointChangeOperator: values.pointChangeOperator,
        pointChangeValue: Number(values.pointChangeValue),
        pointChangeReferenceScoreType: values.pointChangeReferenceScoreType
      }
    case 'percent-change':
      return {
        type: filterType,
        percentChangeOperator: values.percentChangeOperator,
        percentChangeValue: Number(values.percentChangeValue),
        percentChangeReferenceScoreType: values.percentChangeReferenceScoreType
      }
    default:
      return {}
  }
}

export const AssessmentSubmittedTrigger = ({
  closeDrawer,
  onSubmit
}: Props) => {
  const { selectedNode } = useContext(WorkflowsContext)
  const { organizationId, workflowId } = useParams<RouteParams>()
  const config = selectedNode?.config
  const filters = config?.filters
  // for backwards compatibility
  const filter = config?.filter

  // if config has "filters" property use that otherwise try backwards compatible "filter" property
  const initialValues = {
    assessment: { value: config?.assessmentId, label: config?.assessmentName },
    questionKey:
      filters?.find((f: any) => (f?.type === 'question-answer'))?.questionKey ||
      filter?.questionKey ||
      '',
    triggerAnswers: filters
      ?.find((f: any) => (f?.type === 'question-answer'))
      ?.answers?.map((a: any) => a.value) ||
      filter?.triggerAnsers || [''],
    totalScoreOperator:
      filters?.find((f: any) => (f?.type === 'total-score'))?.totalScoreOperator ||
      filter?.totalScoreOperator ||
      '',
    totalScoreValues: filters?.find((f: any) => (f?.type === 'total-score'))
      ?.totalScoreValues ||
      filter?.totalScoreValues || [''],
    filterTypes:
      filters?.map((f: any) => f?.type) || (filter ? [filter.type] : []),
    pointChangeOperator:
      filters?.find((f: any) => (f?.type === 'point-change'))
        ?.pointChangeOperator ||
      filter?.pointChangeOperator ||
      '',
    pointChangeValue:
      filters?.find((f: any) => (f?.type === 'point-change'))?.pointChangeValue ||
      filter?.pointChangeValue ||
      '',
    pointChangeReferenceScoreType:
      filters?.find((f: any) => (f?.type === 'point-change'))
        ?.pointChangeReferenceScoreType ||
      filter?.pointChangeReferenceScoreType ||
      '',
    percentChangeOperator:
      filters?.find((f: any) => (f?.type === 'percent-change'))
        ?.percentChangeOperator ||
      filter?.percentChangeOperator ||
      '',
    percentChangeValue:
      filters?.find((f: any) => (f?.type === 'percent-change'))
        ?.percentChangeValue ||
      filter?.percentChangeValue ||
      '',
    percentChangeReferenceScoreType:
      filters?.find((f: any) => (f?.type === 'percent-change'))
        ?.percentChangeReferenceScoreType ||
      filter?.percentChangeReferenceScoreType ||
      ''
  }

  const {
    data: assessments,
    isLoading
  }: QueryResult = useOrganizationAssessments(organizationId)

  const prepareFiltersForSubmission = (values: any) => {
    return values.filterTypes.map((type: string) => {
      return determineFilterConfig(type, values)
    })
  }

  if (isLoading) return <Loading />

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validate={values => {
        const errors: any = {}
        const badFilterTypeIndex = values.filterTypes.indexOf('')
        if (badFilterTypeIndex > -1) {
          errors[`filterTypes.${badFilterTypeIndex}`] =
            'Please select a filter type'
        }

        return errors
      }}
      onSubmit={values => {
        const trigger: Trigger = {
          workflowId,
          triggerType: 'assessment-submitted-trigger',
          config: {
            assessmentId: values.assessment.value,
            ...(values.filterTypes && values.filterTypes.length
              ? {
                  filters: prepareFiltersForSubmission(values)
                }
              : {})
          }
        }

        onSubmit(trigger)
      }}
    >
      {({ values, errors, resetForm: resetFields }) => {
        const resetForm = (updates: any) => {
          const updatedValues = { ...initialValues, ...updates }
          resetFields({ values: updatedValues })
        }
        
        const onFilterTypeChange = (value: string, fieldArrayHelpers: any, index: number) => {
          const assessment = assessments?.find(a => a.id === values?.assessment?.value)
          resetForm({
            assessment: { value: assessment?.id, label: assessment?.name },
          })
          fieldArrayHelpers.replace(index, value)
        }

        const allFilterOptions = Object.keys(FilterCatalog).map(filterType => {
          return (
            <option key={filterType} value={filterType}>
              {filterNames[filterType]}
            </option>
          )
        })

        return (
          <Form>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="medium"
              >
                <Text minWidth="115px" marginTop="xsmall">Assessment is</Text>
                <Field name="assessment">
                  {({
                    field,
                    form
                  }: {
                    field: {
                      value: { value: string; label: string }
                      name: string
                    }
                    form: any
                  }) => (
                    <SearchSelect
                      {...field}
                      options={assessments?.map(assessment => ({
                        label: assessment.fullName,
                        value: assessment.id
                      }))}
                      placeholder="Select an Assessment"
                      onChange={(option: any) =>
                        form.setFieldValue(field.name, {
                          value: option.value,
                          label: option.label
                        })
                      }
                      value={field.value}
                      errors={errors?.assessment}
                    />
                  )}
                </Field>
              </Box>
              {values?.assessment && (
                <>
                  <Text as="b">Filter</Text>
                  <FieldArray
                    name="filterTypes"
                    render={fieldArrayHelpers => (
                      <Box w="100%">
                        {values.filterTypes.map(
                          (filterType: string, index: number) => {
                            const FilterComponent =
                              filterType && FilterCatalog[filterType].component
                            return (
                              <FilterForm
                                key={index}
                                index={index}
                                filterType={filterType}
                                onFilterTypeChange={(value: string) =>
                                  onFilterTypeChange(
                                    value,
                                    fieldArrayHelpers,
                                    index
                                  )
                                }
                                removeFilter={() =>
                                  fieldArrayHelpers.remove(index)
                                }
                                allFilterOptions={allFilterOptions}
                              >
                                {FilterComponent && (
                                  <FilterComponent
                                    resetForm={resetForm}
                                    assessment={assessments?.find(
                                      a => a.id === values?.assessment?.value
                                    )}
                                    constants={
                                      FilterCatalog[filterType].constants
                                    }
                                  />
                                )}
                              </FilterForm>
                            )
                          }
                        )}
                        {values.filterTypes.length < 1 && (
                          <Text
                            w="100px"
                            pt="xsmall"
                            _hover={{ cursor: 'pointer' }}
                            onClick={() => fieldArrayHelpers.push('')}
                            color="primary"
                          >
                            + Add filter
                          </Text>
                        )}
                      </Box>
                    )}
                  />
                </>
              )}
            </Box>
            <EditDrawerFooter onCancel={closeDrawer} hideDelete />
          </Form>
        )
      }}
    </Formik>
  )
}
