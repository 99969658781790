const styles = theme => ({
  container: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',

    [theme.breakpoints.down(414)]: {
      padding: '10px'
    }
  },
  header_container: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '16px',
    fontWeight: 430,
    lineheight: '21.82px',

    [theme.breakpoints.down(414)]: {
      padding: '10px'
    }
  },
  link_button: {
    textDecoration: 'underline',
    marginLeft: '8px',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  amber_container: {
    background: theme.palette.primary.banner
  },
  black_container: {
    background: 'black',
    color: 'white'
  },
  red_container: {
    background: '#DB1544'
  },
  button: {
    marginLeft: '45px !important',
    background: '#fff !important',
    textTransform: 'none !important',
    fontSize: '16px !important',
    height: '35px !important',

    '&:hover': {
      background: '#ececec !important'
    }
  },
  navLink: {
    '&:hover': {
      extDecoration: 'none !important'
    }
  },
  nonBold: {
    fontWeight: 'normal'
  }
})

export default styles
