import React from 'react'
import { Box, GridItem } from '@chakra-ui/react'
import { RadioGroup, Radio, LayoutGrid } from '@blueprinthq/joy'
import moment from 'moment'
import { Field } from 'formik'
import _ from 'lodash'

import { BPDateTimePicker } from '@components'

const InviteComponent = ({ formHelpers }) => {
  const { values, errors, touched } = formHelpers

  return (
    <Box
      as="span"
      display="inline-block"
      fontSize="16px"
      fontWeight="normal"
      ml="medium"
      verticalAlign="middle"
      w={{ md: '83%' }}
    >
      <LayoutGrid
        gridColumnGap="medium"
        gridRowGap="small"
        alignItems="center"
        justifyContent="flex-start"
      >
        <GridItem
          colSpan={{
            base: 4,
            sm: values.nowOrLater === 'now' ? 5 : 3,
            md: 4
          }}
        >
          <Field name="nowOrLater">
            {({ field, form }) => (
              <RadioGroup
                {...field}
                stackProps={{ spacing: { base: 'small', sm: 'medium' } }}
                defaultValue="now"
                onChange={value => {
                  form.setFieldValue('nowOrLater', value)
                  if (value === 'now') {
                    form.setFieldValue(
                      'dateOfInvite',
                      moment()
                        .add(1, 'd')
                        .format('MM/DD/YYYY hh:mm a')
                    )
                  }
                }}
              >
                <Radio value="now">Now</Radio>
                <Radio value="later">Later</Radio>
              </RadioGroup>
            )}
          </Field>
        </GridItem>
        {values.nowOrLater === 'later' ? (
          <GridItem
            colStart={{
              base: 1,
              sm: 4,
              md: 6
            }}
            colSpan={{
              base: 4,
              sm: 5,
              md: 7
            }}
            rowStart={{
              base: 2,
              sm: 1
            }}
            justifyContent="flex-start"
          >
            <Box mb={errors.dateOfInvite && touched.dateOfInvite && 'small'}>
              <Field name="dateOfInvite" id="enrollment-date-picker">
                {({ field, form }) => (
                  <BPDateTimePicker
                    {...field}
                    form={form}
                    fieldName={field.name}
                    initialMeridian={moment().format('a')}
                    showDate
                    showTime
                    isInvalid={errors.dateOfInvite && touched.dateOfInvite}
                    errorText={
                      (errors.dateOfInvite &&
                        errors.dateOfInvite.includes('Invalid') &&
                        'Invalid date') ||
                      errors.dateOfInvite
                    }
                  />
                )}
              </Field>
            </Box>
          </GridItem>
        ) : null}
      </LayoutGrid>
    </Box>
  )
}

export const Invite = React.memo(InviteComponent, (props, nextProps) =>
  _.isEqual(props, nextProps)
)
