import { action } from 'easy-peasy'

export const paywallBannerModel = {
  paywallBanner: {
    height: 40,
    setHeight: action((state, height) => {
      state.height = height
    })
  }
}
