import React, { useState, useEffect } from 'react'
import {
  Box,
  Heading,
  Text,
  Link,
  Divider,
  VStack,
  HStack,
  Textarea,
  Button,
  useToast
} from '@chakra-ui/react'
import {
  RadioGroup,
  Radio,
  RadioSelectedIcon,
  RadioOutlineIcon
} from '@blueprinthq/joy'
import { useStoreState } from 'easy-peasy'
import {
  useOrganizationControllerUpdateOrganizationConsentSetting,
  useOrganizationControllerGetOrganizationConsentSetting
} from '~/clinician-api'
import { StoreModel } from 'src/store/types'

type ConsentSettingType = 'none' | 'default' | 'custom'

export const ConsentPage = () => {
  const user = useStoreState((state: StoreModel) => state.auth.user)
  const toast = useToast()
  const defaultCopy = `Blueprint is a HIPAA - compliant mobile platform that helps you and your provider measure progress throughout treatment and make adjustments to treatment as needed. Blueprint respects the privacy of all users and will never sell any personally identifiable data. You own your data at all times and can always request your data and account to be deleted by sending an email to clients@blueprint - health.com with the subject of “Account Deletion”. You can view Blueprint's privacy policy in detail at ${process.env.REACT_APP_WWW_URL}/privacy.\n\nMeasuring your progress through Blueprint may be considered a billable service by your provider towards your insurance plan and as a result may result in additional co-payments, amounts applied to deductibles, and other amounts that may be deemed the responsibility of the patient as required by contract with the insurance plan and state regulations.`
  const [consentSettingType, setConsentSettingType] = useState<
    ConsentSettingType
  >('none')
  const [consentCopy, setConsentCopy] = useState<string | null>(defaultCopy)
  const [customConsentValue, setCustomConsentValue] = useState<string | null>(
    null
  )

  useEffect(() => {
    if (consentSettingType === 'default') {
      setConsentCopy(defaultCopy)
    } else {
      setConsentCopy(customConsentValue || defaultCopy)
    }
  }, [consentSettingType, customConsentValue])

  const {
    data: organizationConsentSettings
  } = useOrganizationControllerGetOrganizationConsentSetting(
    user!.clinic.organization_id
  )

  useEffect(() => {
    if (organizationConsentSettings) {
      if (organizationConsentSettings.type === 'none') {
        setConsentSettingType('none')
      } else {
        setConsentSettingType(organizationConsentSettings.type)
        setCustomConsentValue(
          organizationConsentSettings.customCopy?.toString() || null
        )
        setConsentCopy(
          organizationConsentSettings.customCopy?.toString() || defaultCopy
        )
      }
    }
  }, [organizationConsentSettings])

  const {
    mutateAsync: executeUpdateOrganizationConsentSettings,
    isLoading
  } = useOrganizationControllerUpdateOrganizationConsentSetting()

  const handleSubmit = async () => {
    try {
      if (consentSettingType === 'none') {
        await executeUpdateOrganizationConsentSettings({
          organizationId: user!.clinic.organization_id,
          data: {
            type: 'none',
            // @ts-ignore
            customCopy: customConsentValue
          }
        })
      } else {
        await executeUpdateOrganizationConsentSettings({
          organizationId: user!.clinic.organization_id,
          data: {
            type: consentSettingType,
            // @ts-ignore
            customCopy: customConsentValue
          }
        })
      }
      toast({
        title: 'Consent settings updated',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    } catch (error) {
      toast({
        title: 'Failed to update consent settings',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  return (
    <Box mb="xlarge">
      <Box>
        <Box w={'100%'}>
          <Heading mb="xxsmall">Consent</Heading>
          <Text mb="medium">
            Choose how to request and collect client consent to record
          </Text>
          <VStack
            mb={'small'}
            p={'24px'}
            borderRadius={'4px'}
            border={'1px solid #E4E5E6'}
            alignItems={'flex-start'}
            alignSelf={'stretch'}
            spacing={1}
          >
            <HStack
              spacing={2}
              onClick={() => setConsentSettingType('none')}
              _hover={{ cursor: 'pointer' }}
            >
              {consentSettingType === 'none' ? (
                <RadioSelectedIcon fill="primary" />
              ) : (
                <RadioOutlineIcon fill="pale_gray" />
              )}
              <Text fontSize={'16px'} fontWeight={'bold'}>
                Manage consent on your own
              </Text>
            </HStack>
            <Text color="gray" fontSize={'16px'} pl="large" pb="xsmall">
              You may start the process by using our Blueprint Informed Consent
              Automated Notetaker template
            </Text>
            <Link
              pl="large"
              id="pendo-sessions-consent"
              data-click-event-name="Clicked Consent Template Link"
              href="https://docs.google.com/document/d/1YOpiLkjcj5c68Tf15jexv0ISiRLWGQOHCNzoOptA2Qc/edit"
              isExternal
              color="primary"
              textDecoration="underline"
              _focus={{ outline: 'none' }}
            >
              Get the template
            </Link>
          </VStack>
          <VStack
            mb={'small'}
            p={'24px'}
            borderRadius={'4px'}
            border={'1px solid #E4E5E6'}
            alignItems={'flex-start'}
            alignSelf={'stretch'}
            spacing={1}
          >
            <HStack
              spacing={2}
              onClick={() =>
                setConsentSettingType(customConsentValue ? 'custom' : 'default')
              }
              _hover={{ cursor: 'pointer' }}
            >
              {consentSettingType !== 'none' ? (
                <RadioSelectedIcon fill="primary" />
              ) : (
                <RadioOutlineIcon fill="pale_gray" />
              )}
              <Text fontSize={'16px'} fontWeight={'bold'}>
                Use Blueprint to request and collect client consent to record
              </Text>
            </HStack>
            <Text color="gray" pl="large" fontSize={'16px'}>
              Choose from default or automated consent language and select the
              preferred method of request delivery all within the Bluprint
              platform
            </Text>
            {consentSettingType !== 'none' && (
              <>
                <Divider pt="small" />
                <Text pt="small" fontSize="16px" fontWeight="bold">
                  Consent language: EBC
                </Text>
                <Text pb="xsmall" color="gray" fontSize={'16px'}>
                  A short description could go here if needed.
                </Text>
                <RadioGroup
                  value={consentSettingType}
                  stackProps={{
                    direction: 'row',
                    spacing: 5,
                    pt: 'xsmall',
                    pb: 'small'
                  }}
                  onChange={(val: 'default' | 'custom') =>
                    setConsentSettingType(val)
                  }
                >
                  <Radio value="default">Default</Radio>
                  <Radio value="custom">Custom</Radio>
                </RadioGroup>
                <Textarea
                  p="small"
                  borderRadius={'9px'}
                  border={'1px solid #E4E5E6'}
                  bg="#F7F8F9"
                  variant="filled"
                  minHeight="300px"
                  isReadOnly={consentSettingType === 'default'}
                  onChange={e => {
                    setCustomConsentValue(e.target.value)
                    setConsentCopy(e.target.value)
                  }}
                  //@ts-ignore
                  value={consentCopy}
                  resize={'none'}
                />
              </>
            )}
          </VStack>
          <Button
            bg="primary"
            w="100%"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Save preference
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
