import React, { useState, useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  Box,
  Container,
  GridItem,
  Heading,
  Flex,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Center
} from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import { Loading } from '../../components'
import Pagination from '@material-ui/lab/Pagination'
import { useStoreActions } from 'easy-peasy'
import { usePermissions } from '@hooks'
import { keyBy } from 'lodash'
import moment from 'moment'
import {
  OrganizationSelector,
  SelectInput
} from '@handlers/reports/components/filters'

import { endpoints } from '../../api'

const perPage = 50
const defaultFilterOption = {
  label: 'All',
  value: 'all'
}

export function EHRAppointments() {
  const [page, setPage] = useState(1)
  const [orgIdFilter, setOrgIdFilter] = useState('')
  const [visitTypeFilter, setVisitTypeFilter] = useState(
    defaultFilterOption.value
  )
  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const queryClient = useQueryClient()
  const { hasPermission } = usePermissions()

  const { data: user } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  useEffect(() => {
    if (user) {
      setOrgIdFilter(user.clinic.organization_id)
    }
  }, [user])

  const {
    data: {
      ehrAppointments,
      patients,
      appointmentTypes,
      meta: { totalCount }
    },
    isLoading,
    isFetching
  } = useQuery(
    [
      endpoints.getEhrAppointments.getCacheId(),
      page,
      visitTypeFilter,
      orgIdFilter
    ],
    () =>
      endpoints.getEhrAppointments.request({
        organizationId: orgIdFilter,
        page,
        perPage,
        visitType: visitTypeFilter === 'all' ? null : visitTypeFilter
      }),
    {
      enabled: !!user && !!orgIdFilter,
      placeholderData: {
        ehrAppointments: [],
        patients: [],
        appointmentTypes: [],
        meta: {
          page: 1,
          perPage,
          totalCount: 0
        }
      }
    }
  )

  const { mutate: updateEhrAppointment } = useMutation(
    endpoints.updateEhrAppointment.request,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          endpoints.getEhrAppointments.getCacheId(),
          page,
          visitTypeFilter,
          orgIdFilter
        ])
        setSnackbarMessage({
          variant: 'success',
          message: `Visit Type Updated`
        })
      }
    }
  )

  const keyedPatients = keyBy(patients, 'id')

  const apptTypeOptions = appointmentTypes.map(t => ({
    label: t,
    value: t
  }))

  const handleVisitTypeChange = (ehrAppointmentId, visitTypeOverride) => {
    updateEhrAppointment({
      ehrAppointmentId,
      visitTypeOverride: visitTypeOverride || null
    })
  }

  const handlePageChange = (evt, page) => {
    setPage(page)
  }

  const filterOptions = [defaultFilterOption, ...apptTypeOptions]
  const overrideOptions = [{ label: '', value: '' }, ...apptTypeOptions]

  return (
    <Container
      sx={{
        marginTop: 'large',
        paddingBottom: 'xxlarge'
      }}
    >
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 4,
            sm: 8,
            md: 12
          }}
        >
          <Heading my="5">EHR Appointment Management</Heading>
          <Flex justifyContent="flex-end" mb={5}>
            {hasPermission('*:any:*:*') && (
              <Box w={400}>
                <OrganizationSelector
                  organizationId={orgIdFilter}
                  onChange={orgId => setOrgIdFilter(orgId)}
                />
              </Box>
            )}
            <Box w={400} ml={2}>
              <SelectInput
                value={visitTypeFilter}
                options={filterOptions}
                onChange={evt => setVisitTypeFilter(evt.target.value)}
              />
            </Box>
          </Flex>
          {isLoading ? (
            <Center mt={5} h={300}>
              <Loading />
            </Center>
          ) : ehrAppointments.length === 0 ? (
            <Center mt={5} h={300}>
              No upcoming appointments
            </Center>
          ) : (
            <>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Client Name</Th>
                    <Th>MRN</Th>
                    <Th>Time</Th>
                    <Th>EHR Visit Type</Th>
                    <Th>Visit Type Override</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {ehrAppointments.map(a => {
                    const patient = keyedPatients[a.patientId] || {}
                    return (
                      <Tr key={a.id}>
                        <Td>
                          {patient.firstName} {patient.lastName}
                        </Td>
                        <Td>{patient.medicalRecordNumber}</Td>
                        <Td>{moment(a.datetime).format('MM/DD/yy hh:mm a')}</Td>
                        <Td>{a.visitType}</Td>
                        <Td>
                          <SelectInput
                            value={a.visitTypeOverride || ''}
                            options={overrideOptions}
                            onChange={evt =>
                              handleVisitTypeChange(a.id, evt.target.value)
                            }
                          />
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
              <Flex justifyContent="center" mt={5}>
                <Pagination
                  page={page}
                  count={Math.ceil(totalCount / perPage)}
                  size="large"
                  variant="outlined"
                  showFirstButton
                  showLastButton
                  onChange={handlePageChange}
                />
              </Flex>
            </>
          )}
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
