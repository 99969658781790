import React from 'react'
import { Flex, Text, Button, Box } from '@chakra-ui/react'
import { Compass2 as Compass } from '@components/icons'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'

const Check = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="white" />
    <path
      d="M13 20.17L8.83 16L7.41 17.41L13 23L25 11L23.59 9.59003L13 20.17Z"
      fill="#2D54E8"
    />
  </svg>
)

const SmallCheck = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.17L4.83 12L3.41 13.41L9 19L21 7.00003L19.59 5.59003L9 16.17Z"
      fill="#2D54E8"
    />
  </svg>
)

const feature = 'assist'

const InSessionAssistWaitlistPanel = () => {
  const queryClient = useQueryClient()

  const { data: featureWaitlists, isLoading } = useQuery(
    endpoints.getFeatureWaitlist.getCacheId(),
    endpoints.getFeatureWaitlist.request
  )

  const { mutateAsync: joinWaitlist, isLoading: isJoining } = useMutation(
    endpoints.postJoinWaitlist.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(endpoints.getFeatureWaitlist.getCacheId())
      }
    }
  )

  const onJoinWaitlist = async () => {
    await joinWaitlist({ feature })
  }

  const waitListRequested = (featureWaitlists as any)?.includes(feature)

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="center">
        <Compass size={64} />
      </Flex>
      <Text my="8px" fontSize="36px" fontWeight="bold" textAlign="center">
        Assist
      </Text>
      <Text
        fontSize="20px"
        lineHeight="30px"
        color="dark_gray"
        textAlign="center"
      >
        Your AI-enabled clinical companion supporting you before, during and
        after each session.
      </Text>
      <Flex mb="32px" mt="32px" gap="24px" flexDirection="column">
        <Flex gap="16px">
          <Flex alignItems="center">
            <Check />
          </Flex>
          <Text>
            See last session recaps and current session focus area suggestions
            before session.
          </Text>
        </Flex>
        <Flex gap="16px">
          <Flex alignItems="center">
            <Check />
          </Flex>
          <Text>
            Access smart suggestions for evidence-based interventions during
            session.
          </Text>
        </Flex>
        <Flex gap="16px">
          <Flex alignItems="center">
            <Check />
          </Flex>
          <Text>
            Receive assessment, worksheet and intervention suggestions after
            each session
          </Text>
        </Flex>
      </Flex>
      {!isLoading && (
        <>
          {waitListRequested ? (
            <>
              <Flex flexDirection="column" alignItems="center">
                <Button
                  bg="white"
                  color="inherit"
                  _hover={{
                    cursor: 'default'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                >
                  <Flex gap="16px">
                    <Flex alignItems="center">
                      <SmallCheck />
                    </Flex>
                    <Flex alignItems="center">You're on the waitlist!</Flex>
                  </Flex>
                </Button>
              </Flex>
              <Box h="16px" />
              <Text textAlign="center" color="dark_gray">
                We’ll let you know when a spot opens up.
              </Text>
            </>
          ) : (
            <Flex mb="16px" flexDirection="column" alignItems="center">
              <Button isLoading={isJoining} onClick={onJoinWaitlist}>
                Join waitlist
              </Button>
            </Flex>
          )}
        </>
      )}
    </Flex>
  )
}

export default InSessionAssistWaitlistPanel
