const styles = theme => ({
  container: {
    background: '#ffffff',
    padding: '30px 40px 20px',
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',

    [theme.breakpoints.down(414)]: {
      padding: '30px 20px 20px'
    }
  },
  half: {
    flex: '1 1 39%'
  },
  header: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& h2': {
      fontSize: '20px',
      fontWeight: 600
    }
  },
  description: {
    color: theme.palette.text.contrastOnWhite,
    fontSize: '14px',
    marginTop: '-10px',
    marginBottom: '20px'
  },
  upperRight: {
    fontSize: '14px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  subheader: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: '5px',
    display: 'flex',
    flexFlow: 'row wrap'
  },
  sublabel: {
    fontSize: '14px',
    color: theme.palette.text.contrastOnWhite,
    marginBottom: '20px',
    display: 'flex'
  },
  expandableButton: {
    fontFamily: "'Roboto'",
    background: theme.palette.primary.main,
    boxShadow: '0 4px 4px 0 rgba(0,0,0,0.10), 0 0 4px 1px rgba(0,0,0,0.10)',
    color: '#ffffff',
    fontWeight: 300,
    fontSize: '13px',
    border: '0',
    outline: 'none',
    borderRadius: '20px',
    padding: '5px 10px',
    margin: '20px auto 0',
    cursor: 'pointer',

    '&:active': {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10), 0 0 4px 1px rgba(0,0,0,0.10)',
      transform: 'translateY(2px)'
    }
  },
  expandable: {
    overflow: 'hidden',
    paddingTop: '30px'
  },
  error: {
    color: '#E02031',
    fontSize: '13px',
    margin: '10px 0'
  },
  zeroPadding: {
    padding: '0',
    margin: '16px 0 48px'
  }
})

export default styles
