import React from 'react'
import { Box, Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'
import { FilterCatalog } from '../../drawers/triggers/assessment-submitted-trigger/filters/filter-catalog'

type Answer = {
  title: string
  value: number
}

export const AssessmentSubmittedTrigger = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const { assessmentName, filters }: any = config
  
  let filter = filters && filters[0]
  let body

  if (!assessmentName) {
    body = (
      <Box>
        <Text>Please configure this Trigger</Text>
      </Box>
    )
  } else if (!filter) {
    body = (
      <Box>
        <Text>
          Client submits the <Text as="b">{assessmentName}</Text>
        </Text>
      </Box>
    )
  } else if (filter.type === 'question-answer') {
    const { answers, question } = filter
    body = (
      <Box>
        <Text mb={5}>
          Client submits the <Text as="b">{assessmentName}</Text>
        </Text>
        <Text mb={5}>
          Question is <Text as="b">{question}</Text>
        </Text>
        <Text>
          Answer is{' '}
          <Text as="b">
            {answers.map((answer: Answer) => answer.title).join(' or ')}
          </Text>
        </Text>
      </Box>
    )
  } else if (filter.type === 'total-score') {
    const { type, totalScoreOperator, totalScoreValues } = filter
    body = (
      <Box>
        <Text mb={5}>
          Client submits the <Text as="b">{assessmentName}</Text>
        </Text>
        <Text>
          Total score is{' '}
          <Text as="b">
            {
              FilterCatalog[type].constants.operators.find(
                (o: { display: string; value: string }) =>
                  o.value === totalScoreOperator
              ).display
            }{' '}
            {totalScoreOperator === 'between-inclusive'
              ? `${totalScoreValues[0]} and ${totalScoreValues[1]}`
              : totalScoreValues[0]}
          </Text>
        </Text>
      </Box>
    )
  } else if (filter.type === 'point-change') {
    const {
      type,
      pointChangeOperator,
      pointChangeValue,
      pointChangeReferenceScoreType
    } = filter
    body = (
      <Box>
        <Text mb={5}>
          Client submits the <Text as="b">{assessmentName}</Text>
        </Text>
        <Text>
          Total score has{' '}
          <Text as="b">
            {
              FilterCatalog[type].constants.operators.find(
                (o: { display: string; value: string }) =>
                  o.value === pointChangeOperator
              ).display
            }
            {'d '}
          </Text>
          <Text as="span">by</Text>
          <Text as="b">
            {' '}
            {pointChangeValue}{' '}
            {`${pointChangeValue === 1 ? 'point' : 'points'}`}{' '}
          </Text>
          <Text as="span"> or more </Text>
          <Text as="b">
            {' '}
            {
              FilterCatalog[type].constants.referenceScoreTypes.find(
                (o: { display: string; value: string }) =>
                  o.value === pointChangeReferenceScoreType
              ).display
            }{' '}
          </Text>
          <Text as="span">score</Text>
        </Text>
      </Box>
    )
  } else if (filter.type === 'percent-change') {
    const {
      type,
      percentChangeOperator,
      percentChangeValue,
      percentChangeReferenceScoreType
    } = filter
    body = (
      <Box>
        <Text mb={5}>
          Client submits the <Text as="b">{assessmentName}</Text>
        </Text>
        <Text>
          Total score has{' '}
          <Text as="b">
            {
              FilterCatalog[type].constants.operators.find(
                (o: { display: string; value: string }) =>
                  o.value === percentChangeOperator
              ).display
            }
            {'d '}
          </Text>
          <Text as="span">by</Text>
          <Text as="b">
            {' '}
            {percentChangeValue}
            {'% '}
          </Text>
          <Text as="span"> or more </Text>
          <Text as="b">
            {' '}
            {
              FilterCatalog[type].constants.referenceScoreTypes.find(
                (o: { display: string; value: string }) =>
                  o.value === percentChangeReferenceScoreType
              ).display
            }{' '}
          </Text>
          <Text as="span">score</Text>
        </Text>
      </Box>
    )
  }

  return (
    <WorkflowNode
      heading="Assessment submitted Trigger"
      body={body}
      isParent={isParent}
      hasChildren={hasChildren}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
