import { useEffect, useState } from 'react'
import { usePermissions } from './use-permissions'

export function useClinicSelectControl(nClinics: number) {
  const [clinicSelectEnabled, setClinicSelectEnabled] = useState(false)

  const { hasPermission } = usePermissions()

  useEffect(() => {
    if (hasPermission('update:org:clinics:*') && nClinics > 1) {
      setClinicSelectEnabled(true)
    } else {
      setClinicSelectEnabled(false)
    }
  }, [nClinics, hasPermission])

  return { clinicSelectEnabled }
}
