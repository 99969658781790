import { useContext } from 'react'
import { AudioInputContext } from '@context'

const useAudioInputs = () => {
  const context = useContext(AudioInputContext)

  if (context === undefined) {
    throw new Error('useAudioInputs must be used within an AudioInputProvider')
  }

  return context
}

export default useAudioInputs
