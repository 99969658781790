import React, { useEffect, useContext } from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { WorkflowsContext } from '@context'

export const NewTriggerNode = () => {
  const { nodes, selectedNode, setSelectedNode, newTriggerNode } = useContext(
    WorkflowsContext
  )
  const body = <Text>Add a Workflow Trigger.</Text>

  useEffect(() => {
    setSelectedNode(newTriggerNode())
  }, [nodes])

  return (
    <WorkflowNode
      heading={`Workflow Trigger`}
      body={body}
      hasChildren={false}
      isParent={true}
      isSelected={selectedNode?.type === 'new-trigger-node'}
      onClick={() => setSelectedNode(newTriggerNode())}
      isNew
    />
  )
}
