import React from 'react'

const GoldenThreadLetter = () => {
  return (
    <svg
      width="146"
      height="168"
      viewBox="0 0 146 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1_922)">
        <rect
          x="8.12219"
          y="14.3641"
          width="120"
          height="144"
          rx="4"
          transform="rotate(-4 8.12219 14.3641)"
          fill="white"
        />
        <rect
          x="8.65585"
          y="14.828"
          width="119"
          height="143"
          rx="3.5"
          transform="rotate(-4 8.65585 14.828)"
          stroke="#E4E5E6"
        />
        <rect
          width="19"
          height="2"
          transform="translate(25.1993 29.209) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="12"
          height="2"
          transform="translate(25.4784 33.1993) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="32"
          height="2"
          transform="translate(26.1759 43.175) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(26.455 47.1652) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(26.734 51.1555) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="32"
          height="2"
          transform="translate(27.4315 61.1311) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(27.7106 65.1214) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(27.9896 69.1116) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          x="29.7545"
          y="80.0151"
          width="4"
          height="4"
          transform="rotate(-4 29.7545 80.0151)"
          fill="white"
          stroke="#E4E5E6"
          stroke-width="2"
        />
        <rect
          width="74"
          height="2"
          transform="translate(42.6531 78.1107) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="74"
          height="2"
          transform="translate(42.9321 82.1009) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          x="30.731"
          y="93.981"
          width="4"
          height="4"
          transform="rotate(-4 30.731 93.981)"
          fill="white"
          stroke="#E4E5E6"
          stroke-width="2"
        />
        <rect
          width="74"
          height="2"
          transform="translate(43.6296 92.0766) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="74"
          height="2"
          transform="translate(43.9087 96.0668) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          x="31.7077"
          y="107.947"
          width="4"
          height="4"
          transform="rotate(-4 31.7077 107.947)"
          fill="white"
          stroke="#E4E5E6"
          stroke-width="2"
        />
        <rect
          width="74"
          height="2"
          transform="translate(44.6063 106.042) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="74"
          height="2"
          transform="translate(44.8854 110.033) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="32"
          height="2"
          transform="translate(31.6169 120.985) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(31.896 124.975) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(32.175 128.965) rotate(-4)"
          fill="#E4E5E6"
        />
        <rect
          width="24"
          height="2"
          transform="translate(32.8726 138.941) rotate(-4)"
          fill="#E4E5E6"
        />
      </g>
      <g filter="url(#filter1_d_1_922)">
        <rect x="17" y="11" width="120" height="144" rx="4" fill="white" />
        <rect
          x="17.5"
          y="11.5"
          width="119"
          height="143"
          rx="3.5"
          stroke="#E4E5E6"
        />
        <rect
          width="19"
          height="2"
          transform="translate(33 27)"
          fill="#E4E5E6"
        />
        <rect
          width="12"
          height="2"
          transform="translate(33 31)"
          fill="#E4E5E6"
        />
        <rect
          width="32"
          height="2"
          transform="translate(33 41)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(33 45)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(33 49)"
          fill="#E4E5E6"
        />
        <rect
          width="32"
          height="2"
          transform="translate(33 59)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(33 63)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(33 67)"
          fill="#E4E5E6"
        />
        <rect
          x="34"
          y="78"
          width="4"
          height="4"
          fill="white"
          stroke="#E4E5E6"
          stroke-width="2"
        />
        <rect
          width="74"
          height="2"
          transform="translate(47 77)"
          fill="#E4E5E6"
        />
        <rect
          width="74"
          height="2"
          transform="translate(47 81)"
          fill="#E4E5E6"
        />
        <rect
          x="34"
          y="92"
          width="4"
          height="4"
          fill="white"
          stroke="#E4E5E6"
          stroke-width="2"
        />
        <rect
          width="74"
          height="2"
          transform="translate(47 91)"
          fill="#E4E5E6"
        />
        <rect
          width="74"
          height="2"
          transform="translate(47 95)"
          fill="#E4E5E6"
        />
        <rect
          x="34"
          y="106"
          width="4"
          height="4"
          fill="white"
          stroke="#E4E5E6"
          stroke-width="2"
        />
        <rect
          width="74"
          height="2"
          transform="translate(47 105)"
          fill="#E4E5E6"
        />
        <rect
          width="74"
          height="2"
          transform="translate(47 109)"
          fill="#E4E5E6"
        />
        <rect
          width="32"
          height="2"
          transform="translate(33 119)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(33 123)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(33 127)"
          fill="#E4E5E6"
        />
        <rect
          width="24"
          height="2"
          transform="translate(33 137)"
          fill="#E4E5E6"
        />
      </g>
      <path
        d="M112.2 45.4156C104.854 53.373 99.1217 63.0727 87.61 65.4274C81.4725 66.6828 75.7531 66.9278 74.138 60.1083C72.535 53.3403 73.3534 48.2495 81.2882 47.0288C84.7531 46.4957 91.003 44.8822 93.6266 48.3803C95.5543 50.9507 94.8026 57.194 94.8909 60.1955C95.0947 67.1258 95.1062 74.1577 94.7601 81.0793C94.136 93.5612 91.7563 108.534 80.1982 115.522C76.0608 118.024 71.6153 119.097 66.8134 119.097C58.7116 119.097 55.1406 116.073 48.6328 112.121"
        stroke="#F8D557"
        stroke-width="3"
        stroke-linecap="round"
        stroke-dasharray="6 6"
      />
      <path
        d="M117 21C111.48 21 107 25.48 107 31C107 36.52 111.48 41 117 41C122.52 41 127 36.52 127 31C127 25.48 122.52 21 117 21ZM115 36L110 31L111.41 29.59L115 33.17L122.59 25.58L124 27L115 36Z"
        fill="#F8D557"
      />
      <defs>
        <filter
          id="filter0_d_1_922"
          x="0.122192"
          y="-0.00665283"
          width="145.753"
          height="168.02"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_922"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_922"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1_922"
          x="9"
          y="5"
          width="136"
          height="160"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_922"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_922"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default GoldenThreadLetter
