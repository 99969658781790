import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Box, Flex, Text, Link } from '@chakra-ui/react'
import { Button, CopyIcon, IconButton, TextField } from '@blueprinthq/joy'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { VStack, useToast, Heading } from '@chakra-ui/react'
import { endpoints } from '../../../../api'

import { LearnMoreModal } from './learn-more-modal'

import { copyToClipboard } from '../../../../utilities'

const UpsertGeneralSchema = Yup.object().shape({
  kiosk_url: Yup.string()
    .trim()
    .matches(/^[-a-zA-Z0-9_]+$/, { message: 'Kiosk URL must be valid URL.' })
    .required('Required')
})

export const KioskUrl = ({ user, clinic }) => {
  const domain = process.env.REACT_APP_CLIENT_DOMAIN
  const defaultUrl = `${domain}/clinics/`
  const queryClient = useQueryClient()
  const toast = useToast()
  const hasAdminRole = user.roles.filter(
    role =>
      role.name === 'clinicadmin' ||
      role.name === 'superadmin' ||
      role.name === 'orgadmin'
  )
  const [isModalOpen, setModalOpen] = useState(false)

  const onOpenModal = () => setModalOpen(true)
  const onCloseModal = () => setModalOpen(false)

  const { mutateAsync: updateClinicURL } = useMutation(
    endpoints.putClinicURL.request,
    {
      onSuccess() {
        queryClient.invalidateQueries(endpoints.putClinicURL.getCacheId())
        queryClient.invalidateQueries(
          endpoints.getOrganizationClinics.getCacheId()
        )
        toast({
          title: 'Kiosk URL updated',
          status: 'success',
          isClosable: true
        })
      },
      onError({ error }) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          isClosable: true
        })
      }
    }
  )

  const handleCopyLink = url => {
    try {
      copyToClipboard(url)
      toast({
        title: 'Kiosk URL copied to clipboard!',
        status: 'success',
        isClosable: true
      })
    } catch (e) {
      toast({
        title: 'Error',
        description:
          "We're sorry, something went wrong, please refresh and try again",
        status: 'error',
        isClosable: true
      })
    }
  }

  return (
    <>
      <Formik
        validationSchema={UpsertGeneralSchema}
        validate={props => {
          const errors = {}
          if (props.kiosk_url === '') {
            errors.kiosk_url = 'Kiosk URL is required.'
          }
          return errors
        }}
        initialValues={{
          kiosk_url: clinic.display_id
        }}
        enableReinitialize
        onSubmit={async (values, actions) => {
          if (hasAdminRole.length === 0) {
            toast({
              title: 'You do not have permission to edit kiosk URL.',
              status: 'error',
              isClosable: true
            })
          } else {
            const data = {
              display_id: values.kiosk_url
            }
            await updateClinicURL({
              clinicId: clinic.id,
              data
            })
          }

          actions.setSubmitting(false)
        }}
      >
        {({ errors, touched, values }) => (
          <Form>
            <Heading size="small">Kiosk URL</Heading>
            <Text display={'inline-flex'}>
              A client-friendly URL to capture assessments in the waiting room.
              <Link
                _focus={{
                  outline: 'none'
                }}
                onClick={() => onOpenModal()}
                color={'primary'}
                p={0}
                ml={1}
              >
                Learn more
              </Link>
            </Text>
            <VStack
              spacing="small"
              alignItems="flex-start"
              mt="small"
              position="relative"
            >
              <Flex
                style={{ border: '1px solid #E4E5E6' }}
                borderRadius={'4px'}
                position="relative"
                width="100%"
                p="4"
                height="48px"
                alignItems="center"
              >
                <Box color="medium_gray" minWidth="max-content">
                  {defaultUrl}
                </Box>
                <Field name="kiosk_url">
                  {({ field }) => (
                    <TextField
                      {...field}
                      variant="unstyled"
                      disabled={hasAdminRole.length === 0}
                      isInvalid={errors.kiosk_url && touched.kiosk_url}
                      required
                    />
                  )}
                </Field>
                <IconButton
                  background={'white'}
                  _hover={{
                    bg: '#E4E5E6'
                  }}
                  size="sm"
                  onClick={() =>
                    handleCopyLink(`${defaultUrl}${clinic.display_id}`)
                  }
                  aria-label="copy"
                  style={{
                    margin: '0 4px'
                  }}
                >
                  <CopyIcon size="sm" />
                </IconButton>
              </Flex>
              <Text
                fontSize="xs"
                color="var(--chakra-colors-error)"
                position="absolute"
                bottom="-20px"
                left={4}
              >
                {errors.kiosk_url}
              </Text>
            </VStack>

            {hasAdminRole.length > 0 && (
              <Button
                size="lg"
                type="submit"
                marginTop={'small'}
                disabled={values.kiosk_url === clinic.display_id}
              >
                Update
              </Button>
            )}
          </Form>
        )}
      </Formik>

      <LearnMoreModal isOpen={isModalOpen} handleCloseModal={onCloseModal} />
    </>
  )
}
