import React from 'react'

export const InterventionIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1" />
      <path
        d="M28 16C28 22.6274 22.6271 28 16 28C9.37294 28 4 22.6271 4 16C4 9.37293 9.37294 4 16 4C22.6271 4 28 9.37293 28 16Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 16C4 9.38105 9.38105 4 16 4C22.6189 4 28 9.38105 28 16C28 22.6189 22.6189 28 16 28C9.38105 28 4 22.6189 4 16ZM19.2842 18.1221L22.1642 11.5032C22.6189 10.4421 21.5578 9.38123 20.4968 9.83588L13.8779 12.7159C13.3725 12.9434 12.943 13.3475 12.7157 13.8781L9.86105 20.5223C9.38101 21.5579 10.4672 22.6189 11.5031 22.1643L18.122 19.2843C18.6274 19.0568 19.0569 18.6527 19.2842 18.1221ZM15.7472 17.3893C16.6541 17.3893 17.3893 16.6541 17.3893 15.7473C17.3893 14.8405 16.6541 14.1053 15.7472 14.1053C14.8404 14.1053 14.1052 14.8405 14.1052 15.7473C14.1052 16.6541 14.8404 17.3893 15.7472 17.3893Z"
        fill="#282828"
      />
    </svg>
  )
}
