import React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import config from '../../../../../../../config'
import {useStoreActions, useStoreState} from "easy-peasy";

let tabs = []

export default function SummaryTabsBase() {
  const activeTab = useStoreState(state => state.patientProfile.activeTab)
  const setActiveTab = useStoreActions(actions => actions.patientProfile.setActiveTab)

  if (Array.isArray(config.summaryTabs) && config.summaryTabs.length) {
    tabs = config.summaryTabs
  }

  if (tabs.length === 0) {
    return null
  }

  return (
    <Tabs
      value={activeTab}
      onChange={(event, value ) => setActiveTab(value)}
      indicatorColor="primary"
      aria-label="summary tabs"
      centered
    >
      {tabs.map((t, i) => (
        <Tab key={i} label={t} />
      ))}
    </Tabs>
  )
}
