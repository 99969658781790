import React from 'react'
import { Flex } from '@chakra-ui/react'

import { PdfEmbed } from '../../../containers'

export function IntakeProfile(props) {
  const { id: clientId } = props.computedMatch.params

  return (
    <Flex justify="center" align="center" w="100%" h="100%">
      <PdfEmbed
        cacheKey={['intake_patient_pdf', clientId]}
        url={`${process.env.REACT_APP_NODE_API_ROOT_URL}/clinicians/clients/${clientId}/intake-pdf`}
        errorMessage="An issue occured while retrieving the client's intake PDF."
      />
    </Flex>
  )
}
