import { action } from 'easy-peasy'

export const billingModel = {
  billing: {
    selectedClinic: null,
    setSelectedClinic: action((state, clinic) => {
      state.selectedClinic = clinic
    })
  }
}
