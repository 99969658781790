import React from 'react'

const DashedCircle = ({ size = 24, stroke = '#A1A1A1' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="9"
      stroke={stroke}
      stroke-width="2"
      stroke-dasharray="2 2"
    />
  </svg>
)

export default DashedCircle
