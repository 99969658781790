import React from 'react'
import { Flex, Box, Text, UnorderedList, ListItem } from '@chakra-ui/react'
import { Clock, SessionNotes } from '@components/icons'

export const DictationTipsPanel = ({}) => {
  return (
    <Box>
      <Flex mb="small">
        <Box w="20px">
          <Clock />
        </Box>
        <Box ml="small">
          <Text as="b">Ideal Length</Text>
          <Text>3-6 Minutes</Text>
        </Box>
      </Flex>
      <Flex mb="small">
        <Box w="20px">
          <SessionNotes />
        </Box>
        <Box ml="small">
          <Text as="b">Take it slow</Text>
          <Text>
            Speak clearly, use common terms, and long pauses while you think are
            encouraged!
          </Text>
        </Box>
      </Flex>
      <Flex>
        <Box w="20px">
          <SessionNotes />
        </Box>
        <Box ml="small">
          <Text as="b">Plan what you will cover</Text>
          <Text mb="xsmall">
            Before you begin, prepare your thoughts in an outline.
          </Text>
          <Text as="b">Examples Below:</Text>
          <UnorderedList ml="0">
            <ListItem>
              What were the presenting issue(s) and main topics discussed?
            </ListItem>
            <ListItem>
              What was the client's emotional state, symptoms and behaviors?
            </ListItem>
            <ListItem>
              What are the client's goals, and what progress did they make on
              them?
            </ListItem>
            <ListItem>
              What interventions did you deliver during the session? If you used
              a specific EBP, comment on which ones you used specifically.
            </ListItem>
            <ListItem>
              Were there any safety or risk issues during the session?
            </ListItem>
            <ListItem>What other session content is worth mentioning?</ListItem>
            <ListItem>What other observations are worth mentioning?</ListItem>
            <ListItem>
              What did you discuss as the plan for future sessions?
            </ListItem>
            <ListItem>Final comments on safety and risk assessment?</ListItem>
          </UnorderedList>
        </Box>
      </Flex>
    </Box>
  )
}
