import React from 'react'
import { Route, useLocation, Switch } from 'react-router-dom'
import { LayoutGrid } from '@blueprinthq/joy'
import { Container, GridItem } from '@chakra-ui/react'

export const ProfileSettingsNav = props => {
  const { tabs } = props
  const location = useLocation()
  const rootMatch = location.pathname.match(/\/.*patient\/\w+-\w+-\w+-\w+-\w+/g)
  const rootPath = rootMatch[0]

  return (
    <Container>
      <LayoutGrid sx={{ pt: 'xlarge' }}>
        <GridItem
          colStart={{
            base: 1,
            sm: 1,
            md: 3
          }}
          colEnd={{
            base: 5,
            sm: 9,
            md: 11
          }}
        >
          <Switch>
            {tabs.map(tab => {
              let type
              if (tab.key.match(/symptom|worksheet/gi)) {
                type = tab.key
              }

              return (
                <Route key={tab.key} path={`${rootPath}${tab.path}`} exact>
                  {tab.RouteComponent && (
                    <tab.RouteComponent
                      {...props}
                      rootPath={rootPath}
                      type={type}
                    />
                  )}
                </Route>
              )
            })}
          </Switch>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
