import React from 'react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody,
  DialogFooter
} from '@handlers/sessions/components/dialog'
import { VStack, HStack, Text, Box, Button } from '@chakra-ui/react'
import { RadioSelectedIcon, RadioOutlineIcon } from '@blueprinthq/joy'
import { UnassignOption } from '../types'

const Option = ({
  onClick,
  isSelected,
  title,
  description
}: {
  onClick: () => void
  isSelected: boolean
  title: string
  description: string
}) => {
  return (
    <VStack
      mb={'small'}
      p={'24px'}
      borderRadius={'4px'}
      border={'1px solid #E4E5E6'}
      alignItems={'flex-start'}
      alignSelf={'stretch'}
      spacing={1}
    >
      <HStack spacing={2} onClick={onClick} _hover={{ cursor: 'pointer' }}>
        {isSelected ? (
          <RadioSelectedIcon fill="primary" />
        ) : (
          <RadioOutlineIcon fill="pale_gray" />
        )}
        <Text fontSize={'16px'} fontWeight={'bold'}>
          {title}
        </Text>
      </HStack>
      <Text fontSize={'16px'} pl="large" pb="xsmall">
        {description}
      </Text>
    </VStack>
  )
}

interface Props {
  isOpen: boolean
  onClose: () => void
  value: UnassignOption | null
  onChange: (value: UnassignOption) => void
  onConfirm: () => void
  patientCount: number
}

const BeforeYouDowngradeModal = ({
  isOpen,
  onClose,
  value,
  onChange,
  onConfirm,
  patientCount
}: Props) => {
  return (
    <DialogContainer isOpen={isOpen} onClose={onClose} size="2xl">
      <DialogHeader text="Before you downgrade" onClose={onClose} hideBorder />
      <DialogBody>
        <VStack spacing={4}>
          <Text>
            You have{' '}
            <Text as="span" fontWeight="bold">
              {patientCount} clients
            </Text>{' '}
            who have assessments and worksheets assigned to them. How do you
            want to manage these active assignments?
          </Text>
          <Box>
            <VStack spacing={4}>
              <Option
                title="Unassign everything immediately"
                description="We will unassign all Assessments and Worksheets immediately."
                onClick={() => onChange(UnassignOption.UnassignAll)}
                isSelected={value === UnassignOption.UnassignAll}
              />
              <Option
                title="Keep everything assigned"
                description="Existing clients will continue to receive assessment and worksheet reminders. You can unassign at any time on a client’s profile."
                onClick={() => onChange(UnassignOption.KeepAssigned)}
                isSelected={value === UnassignOption.KeepAssigned}
              />
            </VStack>
          </Box>
        </VStack>
      </DialogBody>
      <DialogFooter borderTop="none">
        <HStack spacing={4} w="100%">
          <Button onClick={onClose} variant="outline" isFullWidth>
            Cancel
          </Button>
          <Button isFullWidth onClick={onConfirm} bg="#EB5164">
            Confirm and Downgrade
          </Button>
        </HStack>
      </DialogFooter>
    </DialogContainer>
  )
}

export default BeforeYouDowngradeModal
