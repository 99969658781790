import React, { useContext } from 'react'
import { Text, Box, Input, Textarea, Flex } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Select } from '@chakra-ui/react'

import { WorkflowsContext } from '@context'
import { EditDrawerFooter } from '../../edit-drawer/edit-drawer-footer'
import { FormattedNode } from '../../format-nodes'
import { cloneDeep } from 'lodash'

type Props = {
  onSubmit: (node: FormattedNode | undefined) => void
  closeDrawer: () => void
  onDelete: (node: FormattedNode | undefined) => void
}

const configSchema = Yup.object().shape({
  recipientType: Yup.string().required(),
  email: Yup.string()
    .email()
    .when('recipientType', {
      is: (recipientType: string) => recipientType === 'other-email',
      then: schema => schema.required()
    }),
  template: Yup.string().required(),
  customEmailSubject: Yup.string().when('template', {
    is: (template: string) => template === 'custom-email',
    then: schema => schema.required()
  }),
  customEmailBody: Yup.string().when('template', {
    is: (template: string) => template === 'custom-email',
    then: schema => schema.required()
  })
})

export const SendEmail = ({ onSubmit, closeDrawer, onDelete }: Props) => {
  const { selectedNode } = useContext(WorkflowsContext)

  const initialValues = selectedNode?.config?.recipientType
    ? selectedNode?.config
    : {
        recipientType: '',
        template: 'safety-net'
      }

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={configSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          const node = cloneDeep(selectedNode) as FormattedNode
          node.config = values || {}
          onSubmit(node)
        }}
      >
        {({ values, errors, resetForm }) => (
          <Form>
            <Text>Send To:</Text>
            <Field name="recipientType">
              {({ field }: { field: { value: string } }) => (
                <Select
                  {...field}
                  borderColor={errors?.recipientType ? 'error' : 'light_gray'}
                  color={field.value ? 'black' : 'gray'}
                  w="100%"
                  mt="xsmall"
                  mb="small"
                  onChange={e => {
                    resetForm({
                      values: {
                        recipientType: e.target.value,
                        template: 'safety-net'
                      }
                    })
                  }}
                >
                  <option value="" disabled>
                    Choose recipient
                  </option>
                  <option value="primary-clinician">Primary Clinician</option>
                  <option value="all-clinicians">
                    All Assigned Clinicians
                  </option>
                  <option value="other-email">Other Email Address</option>
                </Select>
              )}
            </Field>
            {values.recipientType === 'other-email' && (
              <Field name="email">
                {({ field }: { field: { value: string } }) => (
                  <Input
                    {...field}
                    w="100%"
                    mb="xsmall"
                    borderColor={errors?.email ? 'error' : 'pale_gray'}
                    focusBorderColor={errors?.email ? 'error' : 'pale_gray'}
                    placeholder="Enter email address"
                  />
                )}
              </Field>
            )}
            {values.recipientType && (
              <>
                <Text>Template:</Text>
                <Field name="template">
                  {({ field }: { field: { value: string } }) => (
                    <Select
                      {...field}
                      borderColor={
                        errors?.recipientType ? 'error' : 'light_gray'
                      }
                      color={field.value ? 'black' : 'gray'}
                      w="100%"
                      mt="xsmall"
                      mb="small"
                    >
                      <option value="" disabled>
                        Choose a template
                      </option>
                      <option value="safety-net">
                        A client safety net has been triggered on Blueprint
                      </option>
                      <option value="custom-email">Custom Email</option>
                    </Select>
                  )}
                </Field>
                {values.template === 'custom-email' && (
                  <Field name="customEmailSubject">
                    {({ field }: { field: { value: string } }) => (
                      <>
                        <Text>Subject:</Text>
                        <Flex direction="column">
                          <Input
                            {...field}
                            placeholder="Enter email subject"
                            borderColor={
                              errors?.customEmailSubject
                                ? 'error'
                                : 'light_gray'
                            }
                            color={field.value ? 'black' : 'gray'}
                            maxLength={60}
                            w="100%"
                            mt="xsmall"
                            mb="xxsmall"
                          />
                          <Text
                            mb="small"
                            color="dark_gray"
                            alignSelf="end"
                            fontSize="sm"
                          >{`${60 -
                            (field?.value?.length ||
                              0)} characters left`}</Text>
                        </Flex>
                      </>
                    )}
                  </Field>
                )}
                {values.template === 'custom-email' && (
                  <Field name="customEmailBody">
                    {({ field }: { field: { value: string } }) => (
                      <>
                        <Text>Body:</Text>
                        <Textarea
                          {...field}
                          placeholder="Enter email body"
                          borderColor={
                            errors?.customEmailBody ? 'error' : 'light_gray'
                          }
                          color={field.value ? 'black' : 'gray'}
                          minHeight="160px"
                          w="100%"
                          mt="xsmall"
                          mb="small"
                        />
                      </>
                    )}
                  </Field>
                )}
              </>
            )}
            <EditDrawerFooter
              onDelete={() => onDelete(selectedNode)}
              onCancel={closeDrawer}
            />
          </Form>
        )}
      </Formik>
    </Box>
  )
}
