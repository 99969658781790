import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'
import { cadenceMap } from '../../drawers/actions/assign-assessment'

const assigneeMap = {
  client: 'Client',
  participant: 'Participant'
}

export const AssignAssessment = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const { assignee, assessments, assessmentCollections } = config

  const body = [...assessments, ...assessmentCollections].map(
    (assessment: any) => (
      <Text mb="small">
        Assign{' '}
        <Text as="b">{assigneeMap[assignee as keyof typeof assigneeMap]}</Text>{' '}
        the <Text as="b">{assessment.label}</Text>
        {assessment?.cadence ? (
          <>
            <Text>to be sent{' '}
              <Text as="b">
                {Object.keys(cadenceMap).find(
                  key =>
                    cadenceMap[key as keyof typeof cadenceMap].unit ===
                      assessment.cadence.unit &&
                    cadenceMap[key as keyof typeof cadenceMap].value ===
                      assessment.cadence.value
                )}
              </Text>
            </Text>
          </>
        ) : (
          <Text>assessment collection</Text>
        )}
      </Text>
    )
  )

  return (
    <WorkflowNode
      heading="Assign Assessment Action"
      body={body}
      hasChildren={hasChildren}
      isParent={isParent}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
