import React, { useMemo } from 'react'
import {
  Flex,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Text,
  Box,
  Tooltip
} from '@chakra-ui/react'
import { ArrowDownIcon, ArrowUpIcon, Select } from '@blueprinthq/joy'
import { useTable, useSortBy, usePagination } from 'react-table'
import { DateTime } from 'luxon'
import Pagination from '@material-ui/lab/Pagination'

import { ClaimStatusSelect } from './claim-status-select'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

export const ClaimsTable = props => {
  const {
    data,
    onChangeClaim,
    dataUpdatedAt,
    orgBillingPreference,
    orgInsurancePayers
  } = props

  const showBilling = useMemo(() => orgBillingPreference.cptCodes.length > 0, [
    orgBillingPreference
  ])
  const cptCodes = useMemo(
    () => [
      null,
      ...orgBillingPreference.cptCodes.map(({ code }) => code.toString())
    ],
    [orgBillingPreference]
  )
  const modifiers = useMemo(
    () => [null, ...orgBillingPreference.modifiers.map(mod => mod.toString())],
    [orgBillingPreference]
  )
  const history = useHistory()

  const columns = useMemo(() => {
    const billingColums = [
      {
        Header: 'Payer',
        accessor: 'insurancePayer',
        style: {
          width: 180
        },
        headerStyle: {
          justifyContent: 'start',
          pl: '12px'
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => {
          const isDisabled = row.original.status !== 'ready_to_submit'
          const addOption = {
            name: (
              <Text textDecoration="underline" color="primary">
                Add new payer
              </Text>
            ),
            id: 'ADD_NEW'
          }
          return (
            <TooltipWhenDisabled
              isDisabled={isDisabled}
              tooltipText="Cannot change payer for submitted claim"
            >
              <Box>
                <Select
                  size="sm"
                  simple
                  variant="ghost"
                  sx={{
                    _disabled: {
                      bg: 'none',
                      color: 'gray'
                    }
                  }}
                  menuProps={{ isLazy: true }}
                  options={orgInsurancePayers.concat(addOption)}
                  value={value}
                  valueKey="id"
                  labelKey="name"
                  onChange={val => {
                    if (val.id === 'ADD_NEW') {
                      history.push('/reports/claims/settings')
                    } else {
                      onChangeClaim(row.original.claimId, {
                        insurancePayerId: val.id
                      })
                    }
                  }}
                  isDisabled={isDisabled}
                />
              </Box>
            </TooltipWhenDisabled>
          )
        }
      },
      {
        Header: 'CPT Code',
        accessor: 'cptCode',
        headerStyle: {
          pl: '12px'
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => {
          const isDisabled = row.original.status !== 'ready_to_submit'
          return (
            <TooltipWhenDisabled
              isDisabled={isDisabled}
              tooltipText="Cannot change CPT code for submitted claim"
            >
              <Box width="90px">
                <Select
                  size="sm"
                  menuProps={{ isLazy: true }}
                  variant="ghost"
                  sx={{
                    _disabled: {
                      bg: 'none',
                      color: 'gray'
                    }
                  }}
                  simple
                  options={cptCodes}
                  value={value}
                  onChange={cptCode =>
                    onChangeClaim(row.original.claimId, { cptCode })
                  }
                  isDisabled={isDisabled}
                />
              </Box>
            </TooltipWhenDisabled>
          )
        }
      },
      {
        Header: 'Modifier',
        accessor: 'modifier',
        headerStyle: {
          pl: '12px'
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => {
          const isDisabled = row.original.status !== 'ready_to_submit'
          return (
            <TooltipWhenDisabled
              isDisabled={isDisabled}
              tooltipText="Cannot change modifier for submitted claim"
            >
              <Box width="75px">
                <Select
                  size="sm"
                  menuProps={{ isLazy: true }}
                  variant="ghost"
                  sx={{
                    _disabled: {
                      bg: 'none',
                      color: 'gray'
                    }
                  }}
                  simple
                  options={modifiers}
                  value={value}
                  onChange={modifier =>
                    onChangeClaim(row.original.claimId, { modifier })
                  }
                  isDisabled={isDisabled}
                />
              </Box>
            </TooltipWhenDisabled>
          )
        }
      },
      {
        Header: 'Units',
        accessor: 'units',
        style: {
          textAlign: 'center'
        },
        headerStyle: {
          justifyContent: 'center'
        }
      }
    ]

    const cols = [
      {
        Header: 'DOS',
        accessor: 'dateOfService',
        Cell: ({ value }) => (
          <Box>
            <Tooltip
              label={DateTime.fromISO(value).toLocaleString(
                DateTime.DATETIME_FULL
              )}
            >
              <Text>{DateTime.fromISO(value).toFormat('D')}</Text>
            </Tooltip>
          </Box>
        ),
        defaultCanSort: true
      },
      {
        Header: 'Clinician',
        accessor: 'clinician',
        style: {
          maxWidth: 180
        }
      },
      {
        Header: 'Client',
        accessor: 'client.name',
        style: {
          maxWidth: 180
        }
      },
      {
        Header: 'Client Dob',
        accessor: 'client.dob',
        style: {
          maxWidth: 150
        },
        Cell: ({ value }) =>
          DateTime.fromISO(value)
            .toUTC()
            .toFormat('D')
      },
      {
        Header: '# of ASMTS',
        accessor: 'numAssessments',
        style: {
          textAlign: 'center'
        },
        headerStyle: {
          justifyContent: 'center'
        }
      },
      {
        Header: 'Claim Status',
        accessor: 'status',
        style: {
          textAlign: 'start'
        },
        headerStyle: {
          pl: 'small'
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => (
          <Box width="150px">
            <ClaimStatusSelect
              value={value}
              onSelect={status =>
                onChangeClaim(row.original.claimId, { status })
              }
            />
          </Box>
        )
      }
    ]
    if (showBilling) {
      cols.splice(5, 0, ...billingColums)
    }
    return cols
  }, [
    data,
    onChangeClaim,
    dataUpdatedAt,
    showBilling,
    orgInsurancePayers,
    cptCodes,
    modifiers
  ])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [{ id: 'dateOfService', desc: false }]
      }
    },
    useSortBy,
    usePagination
  )

  const handlePageChange = (event, page) => {
    gotoPage(page - 1)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup, i) => (
            <Tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <Th
                  key={j}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  borderColor="light_gray"
                  sx={column.style}
                  pl="xsmall"
                  pr="xsmall"
                >
                  <Flex sx={column.headerStyle}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownIcon size="sm" fill={'gray.600'} />
                        ) : (
                          <ArrowUpIcon size="sm" fill={'gray.600'} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, i) => (
            <MemoizedRow
              row={row}
              prepareRow={prepareRow}
              orgInsurancePayers={orgInsurancePayers}
              key={i}
            />
          ))}
        </Tbody>
      </Table>
      {pageOptions.length > 0 && (
        <Flex justify="center" w="100%" mt="small">
          <Pagination
            page={pageIndex + 1}
            count={pageOptions.length}
            size="large"
            color={'primary'}
            variant="outlined"
            showFirstButton
            showLastButton
            onChange={handlePageChange}
          />
        </Flex>
      )}
    </>
  )
}

const TooltipWhenDisabled = ({ children, tooltipText, isDisabled }) =>
  isDisabled ? <Tooltip label={tooltipText}>{children}</Tooltip> : children

// eslint-disable-next-line react/display-name
const MemoizedRow = React.memo(
  ({ row, prepareRow }) => {
    prepareRow(row)
    return (
      <Tr {...row.getRowProps()}>
        {row.cells.map((cell, j) => (
          <Td
            key={j}
            {...cell.getCellProps()}
            textTransform="capitalize"
            borderColor="light_gray"
            verticalAlign="middle"
            paddingBottom="6px"
            paddingTop="6px"
            paddingLeft="xsmall"
            paddingRight="xsmall"
            sx={cell.column.style}
          >
            {cell.render('Cell')}
          </Td>
        ))}
      </Tr>
    )
  },
  (props, nextProps) => {
    return (
      _.isEqual(props.row.values, nextProps.row.values) &&
      props.orgInsurancePayers.length === nextProps.orgInsurancePayers.length
    )
  }
)
