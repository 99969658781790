import React, { useCallback, useMemo } from 'react'
import { Box, VStack } from '@chakra-ui/react'

import { SelectableButton } from './components'

export const ModifiersPage = ({ data, setData, billingOptions }) => {
  const onAddModifier = useCallback(
    selectedModifier => {
      setData({
        ...data,
        modifiers: [...data.modifiers, selectedModifier]
      })
    },
    [data]
  )

  const onRemoveModifier = useCallback(
    selectedModifier => {
      setData({
        ...data,
        modifiers: data.modifiers.filter(
          modifier => modifier !== selectedModifier
        )
      })
    },
    [data]
  )

  const options = useMemo(() => {
    return billingOptions.modifiers.map(modifier => ({
      modifier,
      selected: data.modifiers.includes(modifier)
    }))
  }, [billingOptions, data])

  return (
    <Box>
      <VStack>
        {options.map(option => (
          <SelectableButton
            selected={option.selected}
            onClick={() =>
              option.selected
                ? onRemoveModifier(option.modifier)
                : onAddModifier(option.modifier)
            }
            key={option.modifier}
          >
            {option.modifier}
          </SelectableButton>
        ))}
      </VStack>
    </Box>
  )
}
