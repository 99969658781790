import { endpoints } from '@api'
import { AddCircleIcon, CloseIcon } from '@blueprinthq/joy'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select
} from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { FieldArray, useFormikContext } from 'formik'
import { DateTime } from 'luxon'
import React from 'react'
import { useQuery } from 'react-query'
import { StoreModel } from 'src/store/types'

interface ProgramEnrollment {
  programId: string
  startDate: string
  endDate: string
}

interface FormValues {
  programEnrollments?: ProgramEnrollment[]
  organizationId?: string
}

export const ProgramEnrollments: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const organizationId = user?.clinic?.organization?.id

  const { data, isLoading } = useQuery(
    [endpoints.getPrograms.getCacheId(), organizationId],
    () =>
      endpoints.getPrograms.request({
        organizationId: values.organizationId || organizationId!
      }),
    {
      initialData: { programs: [] }
    }
  )
  const programs = data?.programs || []

  const getAvailablePrograms = (currentProgramId: string, index: number) => {
    return (
      data?.programs.filter(
        program =>
          program.id === currentProgramId ||
          !values.programEnrollments?.some(
            (enrollment, idx) =>
              idx !== index && enrollment.programId === program.id
          )
      ) || []
    )
  }

  const findNextAvailableProgram = () => {
    const selectedProgramIds = new Set(
      values.programEnrollments?.map(enrollment => enrollment.programId)
    )
    return (
      programs.find(program => !selectedProgramIds.has(program.id))?.id || ''
    )
  }

  return (
    <Box mt={4}>
      <FieldArray name="programEnrollments">
        {({ remove, push }) => (
          <div>
            {values.programEnrollments?.map((programEnrollment, index) => (
              <Flex key={index} align={'center'} mb={4}>
                <FormControl isRequired mr={2}>
                  <FormLabel htmlFor={`programEnrollments.${index}.programId`}>
                    Program
                  </FormLabel>
                  <Select
                    name={`programEnrollments.${index}.programId`}
                    value={programEnrollment.programId}
                    onChange={e =>
                      setFieldValue(
                        `programEnrollments.${index}.programId`,
                        e.target.value
                      )
                    }
                    isDisabled={isLoading}
                    borderColor="#C9C9C9"
                    minHeight="48px"
                  >
                    {getAvailablePrograms(
                      programEnrollment.programId,
                      index
                    ).map(program => (
                      <option key={program.id} value={program.id}>
                        {program.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl isRequired mr={1}>
                  <FormLabel htmlFor={`programEnrollments.${index}.startDate`}>
                    Start Date
                  </FormLabel>
                  <Input
                    type="date"
                    name={`programEnrollments.${index}.startDate`}
                    value={programEnrollment.startDate}
                    onChange={e =>
                      setFieldValue(
                        `programEnrollments.${index}.startDate`,
                        e.target.value
                      )
                    }
                  />
                </FormControl>
                <FormControl mr={2}>
                  <FormLabel htmlFor={`programEnrollments.${index}.endDate`}>
                    End Date (optional)
                  </FormLabel>
                  <Input
                    type="date"
                    name={`programEnrollments.${index}.endDate`}
                    value={programEnrollment.endDate}
                    onChange={e =>
                      setFieldValue(
                        `programEnrollments.${index}.endDate`,
                        e.target.value
                      )
                    }
                  />
                </FormControl>
                <Box
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => remove(index)}
                  marginTop={'24px'}
                >
                  <CloseIcon
                    aria-label="Remove enrollment"
                    ml="small"
                    cursor={'pointer'}
                  />
                </Box>
              </Flex>
            ))}
            <Button
              variant={'link'}
              textDecoration={'none'}
              leftIcon={<AddCircleIcon fill={'primary'} />}
              disabled={values.programEnrollments?.length === programs?.length}
              mt={4}
              onClick={() => {
                const nextAvailableProgramId = findNextAvailableProgram()
                push({
                  programId: nextAvailableProgramId,
                  startDate: DateTime.now().toFormat('yyyy-MM-dd'),
                  endDate: ''
                })
              }}
            >
              Add Enrollment
            </Button>
          </div>
        )}
      </FieldArray>
    </Box>
  )
}
