import React from 'react'
import { RadioButtonLoadingComplete } from '@components/icons'
import { Tag } from '@components'
import { Stack, Box, Text, HStack, Flex } from '@chakra-ui/react'
import { InfoIcon } from '@blueprinthq/joy'
import { PlusFreeTrialStatus } from '@handlers/settings/plans/components/plus-free-trial-status'

const PlanOption = ({
  value,
  title,
  isChecked,
  onChange,
  features = [],
  showCurrentTrial,
  showPlusPlanEnabled,
  isDisabled
}: any) => {
  return (
    <Stack
      boxShadow={isChecked ? '0 0 0 2px #2D54E8' : '0 0 0 1px #E4E5E6'}
      borderRadius="12px"
      borderStyle="solid"
      p="24px"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      onClick={!isDisabled ? () => onChange(value) : undefined}
      minH="200px"
      flex="1"
      gap="16px"
      spacing="0"
      opacity={isDisabled ? 0.6 : 1}
      bg={isDisabled ? '#f9f9f9' : 'white'}
    >
      <HStack justifyContent="space-between">
        <Box display="flex" flex="1" alignItems="center">
          <Text fontWeight="bold">
            {title} {isDisabled && '(Coming Soon)'}
          </Text>
          {showCurrentTrial && <Tag ml={2}>Current Trial</Tag>}
        </Box>
        {isChecked && <RadioButtonLoadingComplete />}
      </HStack>
      <Stack spacing="0" gap="8px">
        {features.map((feature: string) => (
          <HStack key={feature} spacing="0" justifyContent="space-between">
            <Flex gap="8px">
              <InfoIcon fill="medium_gray" size="sm" />
              <Text lineHeight="16px" fontSize="14px">
                {feature}
              </Text>
            </Flex>
          </HStack>
        ))}
      </Stack>
      {showPlusPlanEnabled && <PlusFreeTrialStatus />}
    </Stack>
  )
}

export default PlanOption
