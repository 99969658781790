import { Box, Flex, Avatar, Text, Button } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import PropTypes from 'prop-types'
import { Hidden } from '@blueprinthq/joy'
import { DateTime } from 'luxon'
import { Warning } from '../../../components/icons'
import { withRouter } from 'react-router-dom'
import * as clinicianTracking from '@lib/clinician-tracking'

const PatientName = ({ patientId, name, isHighRisk }) => (
  <>
    <Text
      sx={{
        fontSize: 'md',
        fontWeight: 'bold',
        display: 'inline',
        wordBreak: 'break-word'
      }}
    >
      <Link to={`/patient/${patientId}`}>{name}</Link>
    </Text>
    {isHighRisk && (
      <Box
        as="span"
        sx={{
          display: 'inline-flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          marginLeft: 'xsmall'
        }}
      >
        {/* #B5342A is 'error' token, can't pass through here. */}
        <Warning fill="#B5342A" width="14px" height="14px" />
        <Hidden sm>
          <Text
            as="span"
            sx={{
              color: 'error',
              fontSize: 'xsm',
              fontWeight: 'normal',
              marginLeft: 'xsmall'
            }}
          >
            High-Risk
          </Text>
        </Hidden>
      </Box>
    )}
  </>
)

const NOTIFICATION_CONTENT_WIDTH = '50%'

const Notification = props => {
  const {
    id,
    patient_id,
    client_name,
    occurred_at,
    parts,
    is_read,
    is_high_risk,
    type: variant,
    markAsRead,
    history,
    additionalInfo
  } = props

  // Validate the variant.
  const validVariants = Object.values(NotificationVariants).map(v => v.key)
  if (!(validVariants.indexOf(variant) >= 0)) {
    console.error(
      new Error(
        `invalid NotificationVariant: ${
          typeof variant === undefined ? 'undefined' : variant
        }`
      )
    )
  }

  const { openModal: openAssessmentModal } = useStoreActions(
    state => state.modals.assessmentResult
  )

  const { openModal: openWorksheetModal } = useStoreActions(
    state => state.modals.worksheetScores
  )

  const openProfile = patientId => history.push(`/patient/${patientId}`)

  const backgroundColor = is_read ? 'transparent' : 'rgba(45, 84, 232, 0.04)'

  const { buttonText } = NotificationVariants[variant.toUpperCase()]

  const buttonVariant =
    variant === NotificationVariants.PENDING.key
      ? 'solid'
      : is_read
      ? 'outline'
      : 'solid'

  const handleClick = id => {
    if (!is_read) {
      markAsRead({ id })
    }

    switch (variant) {
      case NotificationVariants.CLIENT_SUBMITTED_ASSESSMENTS.key:
      case NotificationVariants.PARTICIPANT_SUBMITTED_ASSESSMENTS.key:
        clinicianTracking.trackEvent(
          'Dashboard -> Clicked Review (Assessment)',
          { assessmentScoreId: parts[0].id }
        )

        openAssessmentModal({
          clientId: patient_id,
          preselectedAssessmentId: parts[0].assessment_id,
          preselectedAssessmentScoreId: parts[0].id
        })
        break
      case NotificationVariants.CLIENT_SUBMITTED_WORKSHEETS.key:
        openWorksheetModal({
          patientId: patient_id,
          preselectedCheckInScoreId: parts[0].id
        })
        break
      case NotificationVariants.CLIENT_ASSIGNED_TO_CLINICIAN.key:
      case NotificationVariants.CLIENT_TRANSFERRED_TO_CLINICIAN.key:
        openProfile(patient_id)
        break
      default:
        return
    }
  }

  const timeRelative = DateTime.fromISO(occurred_at).toRelative()

  return (
    <Flex
      sx={{
        padding: 'small',
        borderRadius: '4px',
        backgroundColor
      }}
    >
      <Box>
        <Link to={`/patient/${patient_id}`}>
          <Avatar
            name={client_name}
            sx={{
              marginRight: 'small',
              background: 'rgba(45, 84, 232, 0.04)',
              color: 'black',
              fontWeight: 'bold'
            }}
          />
        </Link>
      </Box>
      <Flex grow="1" wrap="wrap" justifyContent="space-between">
        <Flex
          w={{
            base: '100%',
            sm: NOTIFICATION_CONTENT_WIDTH
          }}
          sx={{
            paddingBottom: 'xsmall'
          }}
        >
          <Box>
            <PatientName
              patientId={patient_id}
              name={client_name}
              isHighRisk={is_high_risk}
            />
            <NotificationContent
              parts={parts}
              variant={variant}
              additionalInfo={additionalInfo}
            />
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent={{
            base: 'flex-start',
            sm: 'flex-end'
          }}
          wrap={{
            base: 'wrap',
            sm: 'nowrap'
          }}
          w={{
            base: '100%',
            sm: `calc(100% - ${NOTIFICATION_CONTENT_WIDTH})`
          }}
        >
          <Flex
            w={{
              base: '100%',
              sm: 'auto'
            }}
          >
            <Text
              sx={{
                fontSize: 'xsm',
                color: 'gray',
                marginRight: 'small',
                whiteSpace: 'nowrap'
              }}
            >
              {timeRelative}
            </Text>
          </Flex>
          <Flex
            w={{
              base: '100%',
              sm: 'auto'
            }}
          >
            <Button
              variant={buttonVariant}
              onClick={() => handleClick(id)}
              sx={{
                borderRadius: '4px',
                width: '108px',
                fontSize: 'xsm'
              }}
            >
              {buttonText}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

Notification.propTypes = {
  client_name: PropTypes.string.isRequired,
  occurred_at: PropTypes.string.isRequired,
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string
    })
  ),
  is_read: PropTypes.bool,
  is_high_risk: PropTypes.bool,
  type: PropTypes.string.isRequired,
  markAsRead: PropTypes.func.isRequired,
  additionalInfo: PropTypes.object
}

const NotificationContent = ({ parts = [], variant, additionalInfo = {} }) => {
  const { key, text } = NotificationVariants[variant.toUpperCase()]

  return (
    <Text
      sx={{
        fontSize: 'xsm'
      }}
    >
      {renderParticipantInfo(key, additionalInfo)}
      {text}
      <Text
        as="span"
        sx={{
          fontWeight: 'bold',
          fontSize: 'inherit'
        }}
      >
        {' '}
        {parts.map(i => i.display).join(', ')}
      </Text>
    </Text>
  )
}

const renderParticipantInfo = (key, additionalInfo) => {
  if (
    key === NotificationVariants.PARTICIPANT_SUBMITTED_ASSESSMENTS.key &&
    additionalInfo.participant
  ) {
    return `${additionalInfo.participant.fullName} (${additionalInfo.participant.type}) `
  }
}

export const NotificationVariants = {
  CLIENT_ASSIGNED_TO_CLINICIAN: {
    key: 'client_assigned_to_clinician',
    text: 'was assigned to you',
    buttonText: 'View Profile'
  },
  CLIENT_SUBMITTED_ASSESSMENTS: {
    key: 'client_submitted_assessments',
    text: 'completed the',
    buttonText: 'Review'
  },
  PARTICIPANT_SUBMITTED_ASSESSMENTS: {
    key: 'participant_submitted_assessments',
    text: 'completed the',
    buttonText: 'Review'
  },
  CLIENT_SUBMITTED_WORKSHEETS: {
    key: 'client_submitted_worksheets',
    text: 'completed the',
    buttonText: 'Review'
  },
  PENDING: {
    key: 'pending',
    text: 'was assigned to you',
    buttonText: 'Enroll'
  },
  CLIENT_TRANSFERRED_TO_CLINICIAN: {
    key: 'client_transferred_to_clinician',
    text: 'was transferred to you',
    buttonText: 'View Profile'
  }
}

export default withRouter(Notification)
