import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'

export const AssignProgram = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const { programs } = config
  const [firstProgram, ...otherPrograms] = programs

  const body = (
    <Text>
      Enroll the client in the <Text as="b">{firstProgram.name}</Text> program
      {otherPrograms.map((program: { name: string }) => (
        <Text>
          and the <Text as="b">{program.name}</Text> program
        </Text>
      ))}
    </Text>
  )

  return (
    <WorkflowNode
      heading="Assign Program Action"
      body={body}
      hasChildren={hasChildren}
      isParent={isParent}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
