import { useField } from 'formik'
import { Heading, Box, FormLabel } from '@chakra-ui/react'
import { Select } from '@blueprinthq/joy'

const getValueWithOptOut = (selectedValues, optOutValue, field) => {
  const optOutSelected = selectedValues.includes(optOutValue)

  const optOutPreviouslySelected = field.value.includes(optOutValue)

  if (!optOutPreviouslySelected && optOutSelected) {
    // clear out any other options and only select rather not say
    return [optOutValue]
  } else if (optOutPreviouslySelected && selectedValues.length > 1) {
    // if the opt out was previously selected, and then the user selected a different option, unselect the opt out
    return selectedValues.filter(v => v !== optOutValue)
  } else {
    return selectedValues
  }
}

export const SelectWithOptout = ({ ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers

  const value = props.multiple // our select component expects the full option(s) for its value
    ? field.value.map(v => props.options.find(o => o.value === v))
    : props.options.find(o => o.value === field.value)

  const onChange = selectedOptions => {
    let value
    if (!props.multiple) {
      value = selectedOptions.value
    } else {
      const selectedValues = selectedOptions.map(o => o.value)

      if (props.optOutValue) {
        value = getValueWithOptOut(selectedValues, props.optOutValue, field)
      } else {
        value = selectedOptions.map(o => o.value)
      }
    }

    if (props.onChange) {
      props.onChange(value)
    } else {
      setValue(value)
    }
  }

  return (
    <Box width="100%" mb="3">
      <FormLabel aria-label={props.name}>{props.labelText}</FormLabel>
      <Select
        {...field}
        {...props}
        valueKey="value"
        labelKey="display"
        value={value}
        onChange={onChange}
        style={{ backgroundColor: '#ffffff' }}
      />
    </Box>
  )
}
