import React, { useCallback } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { VStack, Flex, Box, Heading, Text, Divider } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { Skeleton } from '@chakra-ui/react'
import classes from './assessments.module.css'
import { Loading, AssessmentCadenceMenu } from '@components'
import { endpoints } from '@api'

const AssessmentPreference = ({ assessment, onUpdateCadence }) => {
  return (
    <Flex justify="space-between" width="100%" p="small" align="center">
      <Box>
        <Heading mb="xsmall" size="sm">
          {assessment.full_name} ({assessment.name})
        </Heading>
        <Text>
          {assessment.disorder} | {assessment.num_questions} questions
        </Text>
      </Box>
      <Box width="180px">
        <AssessmentCadenceMenu
          assessment={assessment}
          onChange={c => onUpdateCadence(assessment.id, c)}
        />
      </Box>
    </Flex>
  )
}

export const AssessmentPreferencesTab = () => {
  const queryClient = useQueryClient()
  const { data: assessments, isLoading, refetch } = useQuery(
    [endpoints.getClinicianAssessments.getCacheId()],
    endpoints.getClinicianAssessments.request
  )

  const { mutateAsync: updateAssessmentCadence } = useMutation(
    endpoints.postClinicianAssessments.request,
    {
      onError() {
        // Clean up cached assessment state if it fails
        refetch()
      }
    }
  )

  const onUpdateCadence = useCallback(async (clinicAssessmentId, data) => {
    // Just modifying the cache directly at the moment, since update assessment cadence endpoint doesn't properly return the new updated entry but just an empty object.
    queryClient.setQueryData(
      endpoints.getClinicianAssessments.getCacheId(),
      cachedAssessments =>
        cachedAssessments.map(cachedAssessment => {
          if (cachedAssessment.id === clinicAssessmentId) {
            return {
              ...cachedAssessment,
              cadence_unit: data.unit,
              cadence_value: data.value
            }
          }
          return cachedAssessment
        })
    )

    await updateAssessmentCadence({
      data: {
        clinic_assessment_id: clinicAssessmentId,
        cadence_unit: data.unit,
        cadence_value: data.value
      }
    })
  }, [])

  return (
    <Box>
      <Box mb={'medium'} w={'100%'}>
        <Heading mb="xxsmall">Assessments</Heading>
        <Text>Choose the default frequencies for each Assessment</Text>
      </Box>
      <Box
        w="100%"
        border="1px solid"
        borderColor="#E4E5E6"
        borderRadius="4px"
        mb={'small'}
        bg="white"
        verticalAlign={'middle'}
      >
        <Table variant="unstyled">
          <Thead>
            <Tr borderBottom={'1px solid #E4E5E6'}>
              <Th letterSpacing={'none'}>
                <Box height="100%" display="flex" alignItems="center">
                  <Heading textTransform={'none'} fontSize="16px">
                    Name
                  </Heading>
                </Box>
              </Th>
              <Th letterSpacing={'none'}>
                <Box height="100%" display="flex" alignItems="center">
                  <Heading textTransform={'none'} fontSize="16px">
                    Short Name
                  </Heading>
                </Box>
              </Th>
              <Th letterSpacing={'none'}>
                <Box height="100%" display="flex" alignItems="center">
                  <Heading textTransform={'none'} fontSize="16px">
                    Category
                  </Heading>
                </Box>
              </Th>
              <Th letterSpacing={'none'}>
                <Box height="100%" display="flex" alignItems="center">
                  <Heading textTransform={'none'} fontSize="16px">
                    Questions
                  </Heading>
                </Box>
              </Th>
              <Th pr={0} letterSpacing={'none'}>
                <Box height="100%" display="flex" alignItems="center">
                  <Heading textTransform={'none'} fontSize="16px">
                    Frequency
                  </Heading>
                </Box>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {assessments &&
              assessments.map((assessment, index) => {
                return (
                  <Tr
                    _last={{ borderBottom: 'none' }}
                    borderBottom={'1px solid #E4E5E6'}
                    key={index}
                  >
                    <Td className={classes.tablechange}>
                      {assessment.full_name}
                    </Td>
                    <Td className={classes.tablechange}>{assessment.name}</Td>
                    <Td className={classes.tablechange}>
                      {assessment.disorder}
                    </Td>
                    <Td className={classes.tablechange}>
                      {assessment.num_questions}
                    </Td>
                    <Td
                      style={{ width: '240px' }}
                      className={classes.tablechange}
                    >
                      {assessment.cadence_unit !== 'integration_driven' && (
                        <AssessmentCadenceMenu
                          assessment={assessment}
                          onChange={c => onUpdateCadence(assessment.id, c)}
                        />
                      )}
                      {assessment.cadence_unit === 'integration_driven' && (
                        <Heading
                          color={'black'}
                          textTransform={'none'}
                          fontWeight={'normal'}
                          size="small"
                          m={0}
                        >
                          {'Managed by Integration'}
                        </Heading>
                      )}
                    </Td>
                  </Tr>
                )
              })}
            {isLoading && (
              <>
                <Tr
                  _last={{ borderBottom: 'none' }}
                  borderBottom={'1px solid #E4E5E6'}
                  key={1}
                >
                  <Td className={classes.tablechange}>
                    <Skeleton height="40px" />
                  </Td>
                  <Td className={classes.tablechange}>
                    <Skeleton height="40px" />
                  </Td>
                  <Td className={classes.tablechange}>
                    <Skeleton height="40px" />
                  </Td>
                  <Td className={classes.tablechange}>
                    <Skeleton height="40px" />
                  </Td>
                  <Td className={classes.tablechange}>
                    <Skeleton height="40px" />
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
      </Box>
    </Box>
  )
}

export default AssessmentPreferencesTab
