import React, { Component } from 'react'
import { Logo } from '../icons'
import { Link } from 'react-router-dom'
import { NavigationLinks } from './NavigationLinks'
import { AppBar, withTheme } from '@material-ui/core'
import styles from './navigation.module.css'
import cx from 'classnames'
import { PatientSearch } from '../../components'
import Snackbar from '@material-ui/core/Snackbar'
import { JoySnackbarContent } from '..'
import { store } from '../../store'

class Navigation extends Component {
  state = {
    menuAnchorEl: null,
    snackBar: false,
    snackbarVariant: null,
    snackbarMessage: null
  }

  logout = () => {
    this.setState({ menuAnchorEl: null })
    // Using raw store here because the component isn't using hooks
    store.getActions().auth.logout()
    this.props.context.clearPatientList()
  }

  handleMenuOpen = event => {
    this.setState({ menuAnchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null })
  }

  handleSnackbar = (variant, message) => {
    this.setState({
      snackbar: true,
      snackbarVariant: variant,
      snackbarMessage: message
    })
  }

  handleMenuCloseSnackbar = () => {
    this.setState({
      snackbar: false
    })
  }

  render() {
    const { user_role } = this.props
    const is_admin = user_role === 'admin' ? true : false

    const appBarOverride =
      this.props.theme.componentOverrides &&
      this.props.theme.componentOverrides.appBar

    return (
      <div className={cx(styles.nav, is_admin && styles.admin)}>
        <AppBar
          color={
            is_admin
              ? 'inherit'
              : appBarOverride
              ? appBarOverride.paletteColor
              : 'primary'
          }
          position="fixed"
        >
          <div className={styles.nav_inner}>
            <div className={styles.logoContainer}>
              <Link className={styles.logo} to="/">
                {is_admin ? <Logo /> : <Logo color="white" />}
              </Link>
              {is_admin && <div className={styles.adminPill}>ADMIN</div>}
            </div>
            <PatientSearch />
            <NavigationLinks
              {...this.props}
              menuAnchorEl={this.state.menuAnchorEl}
              handleMenuOpen={this.handleMenuOpen}
              handleMenuClose={this.handleMenuClose}
              handleSnackbar={this.handleSnackbar}
              logout={this.logout}
            />
          </div>
        </AppBar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.snackbar}
          autoHideDuration={3000}
          onClose={this.handleMenuCloseSnackbar}
        >
          <JoySnackbarContent
            onClose={this.handleMenuCloseSnackbar}
            variant={this.state.snackbarVariant}
            message={this.state.snackbarMessage}
          />
        </Snackbar>
      </div>
    )
  }
}

export default withTheme(Navigation)
