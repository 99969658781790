import React from 'react'
import { Badge, Flex, CloseButton } from '@chakra-ui/react'

type ChipProps = {
  label: string
  onClose?: (event: React.MouseEvent) => void
}

export const Chip: React.FC<ChipProps> = ({ label, onClose }) => {
  const handleClose = (evt: React.MouseEvent) => {
    evt.stopPropagation()
    if (onClose) {
      onClose(evt)
    }
  }

  return (
    <Badge
      sx={{
        backgroundColor: onClose ? '#2D54E8' : '#E4E5E6',
        color: onClose ? 'white' : 'black',
        fontWeight: 400,
        textTransform: 'none',
        paddingTop: onClose ? 0 : '3px',
        paddingBottom: onClose ? 0 : '3px',
        borderRadius: 5
      }}
      pl="2"
      pr={onClose ? '0' : '2'}
    >
      <Flex alignItems="center">
        {label}
        {onClose && (
          <CloseButton
            as="div"
            size="sm"
            pr="0"
            onClick={handleClose}
            sx={{ cursor: 'pointer' }}
          />
        )}
      </Flex>
    </Badge>
  )
}
