import React from 'react'

let PatientsListHeaderWrapper = () => null
if (process.env.REACT_APP_THEME === 'tempus') {
  import('./tempus/wrapper').then(
    ({ default: patientsListHeaderWrapper }) =>
      (PatientsListHeaderWrapper = patientsListHeaderWrapper)
  )
} else {
  import('./blueprint/wrapper').then(
    ({ default: patientsListHeaderWrapper }) =>
      (PatientsListHeaderWrapper = patientsListHeaderWrapper)
  )
}

export default function PatientsListHeader(props) {
  return <PatientsListHeaderWrapper {...props} />
}
