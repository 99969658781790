import React, { useMemo } from 'react'
import { Flex, Text, VStack, HStack, Link } from '@chakra-ui/react'
import { AddCircleIcon, CheckCircle } from '@blueprinthq/joy'
export const MeasureRow = ({
  measureId,
  title,
  subtitle,
  numQuestions,
  previewUrl,
  takeNowUrl,
  isSelected,
  onSelect,
  isAssigned
}) => {
  const icon = useMemo(() => {
    let i = (
      <AddCircleIcon
        onClick={() => onSelect(measureId)}
        onKeyDown={e => {
          const key = e.code
          if (key === 13 || key === 32) {
            onSelect(measureId)
          }
        }}
        role="button"
        aria-label="Select Assessment"
        tabIndex="0"
        cursor="pointer"
      />
    )

    if (isAssigned) {
      i = <CheckCircle w="22px" h="22px" fill="#E4E5E6" />
    } else if (isSelected) {
      i = (
        <CheckCircle
          onClick={() => onSelect(measureId)}
          onKeyDown={e => {
            const key = e.code
            if (key === 13 || key === 32) {
              onSelect(measureId)
            }
          }}
          w="22px"
          h="22px"
          role="button"
          aria-label="Deselect Assessment"
          tabIndex="0"
          cursor="pointer"
          fill="primary"
        />
      )
    }

    return i
  }, [measureId, isAssigned, isSelected, onSelect])

  return (
    <Flex
      minHeight="100px"
      py="10px"
      borderTop="1px solid #E4E5E6"
      align="center"
      justify="space-between"
    >
      <VStack spacing="none" align="left" maxWidth="400px">
        <Text fontWeight="bold" mb="xsmall">
          {title}
        </Text>
        <Text>{subtitle}</Text>
        <Text>{numQuestions} questions</Text>
      </VStack>
      <HStack spacing="small">
        {previewUrl && (
          <Link
            as="a"
            target="_blank"
            mr="xsmall"
            color="primary"
            textDecoration="underline"
            href={previewUrl}
          >
            Preview
          </Link>
        )}
        {takeNowUrl && (
          <Link
            as="a"
            target="_blank"
            mr="xsmall"
            color="primary"
            textDecoration="underline"
            href={takeNowUrl}
          >
            Take now
          </Link>
        )}
        {icon}
      </HStack>
    </Flex>
  )
}
