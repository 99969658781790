import React from 'react'
import {
  Tooltip,
  Box,
  Button,
  ButtonProps,
  forwardRef,
  Text,
  VStack,
  CloseButton,
  Stack,
  HStack,
  Flex
} from '@chakra-ui/react'
import {
  EndSession,
  SessionNotes as SessionNotesIcon,
  SessionAgenda as SessionAgendaIcon,
  LightBulb,
  Mic,
  MicOff,
  CompassOutlined as Compass
} from '@components/icons'
import { SettingsIcon } from '@blueprinthq/joy'
import WarningSharp from '@material-ui/icons/WarningSharp'
import { useExperienceManager, usePanelManager, useAudioCapture } from '@hooks'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { trackEvent } from '@lib/clinician-tracking'
import { StoreModel } from 'src/store/types'

export const ToolbarButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return (
    <Button
      ref={ref}
      variant="transparent"
      p="8px"
      m="0px"
      h="54px"
      minW={{
        base: '75px',
        md: '85px'
      }}
      borderRadius="8px"
      style={{ marginInlineStart: 0 }}
      _hover={{ bg: 'pale_gray' }}
      _loading={{ bg: 'white' }}
      _focus={{ outline: 'none' }}
      {...props}
    />
  )
})

type RecordButtonProps = {
  isRecordingLoading: boolean
  hasStartedRecording: boolean
  startRecording: () => void
  isDictation?: boolean
}

const MicIcon = (props: any) => <Mic fill="#282828" {...props} />

const AudioActivity = ({ isRecording }: { isRecording: boolean }) => {
  const { audioLevel } = useStoreState((state: StoreModel) => state.volume)

  return <MicIcon dy={isRecording ? 1 - audioLevel : null} filterId="footer" />
}

export const RecordButton = ({
  isRecordingLoading,
  hasStartedRecording,
  startRecording,
  isDictation = false
}: RecordButtonProps) => {
  const { isExtension, isWidget } = useExperienceManager()
  const {
    isReady,
    hasMicAccess,
    isMuted,
    toggleMute,
    showMicWarning,
    setShowMicWarning,
    isRecording
  } = useAudioCapture()

  const renderMicIcon = () => {
    if (!isMuted) {
      return <AudioActivity isRecording={isRecording} />
    }
    return <MicOff fill="#EB5164" />
  }

  const handleRecordingClick = () => {
    if (isReady && hasStartedRecording) {
      toggleMute()
    } else {
      startRecording()
    }
  }

  return (
    <Tooltip
      isOpen={showMicWarning}
      hasArrow
      pointerEvents="all"
      label={
        <Box display="flex" alignItems="flex-start">
          <Box mr={2} flex="0">
            <WarningSharp />
          </Box>
          <Text>
            We're not detecting audio from your microphone. Please check that
            you have the correct input option selected.
          </Text>
          <CloseButton
            onClick={() => setShowMicWarning(false)}
            size="sm"
            color="#757575"
          />
        </Box>
      }
      zIndex="tooltip"
      maxW={isExtension || isWidget ? '345px' : '438px'}
      bg="black"
      placement="top"
      px={2}
      py={4}
      border="1px solid white"
      sx={{
        '--popper-arrow-shadow-color': 'white'
      }}
      borderRadius="16px"
    >
      <ToolbarButton
        position="relative"
        onClick={() => {
          trackEvent('Session -> Clicked Start Recording')
          handleRecordingClick()
        }}
        isDisabled={!hasMicAccess || isRecordingLoading}
      >
        <Stack>
          <HStack>
            <Flex flex={1} justifyContent="center">
              {renderMicIcon()}
            </Flex>
          </HStack>
          <Text fontSize="10px" style={{ marginTop: 0 }}>
            {isMuted
              ? 'Resume Recording'
              : !hasStartedRecording
              ? isDictation
                ? 'Record'
                : 'Record'
              : 'Pause Recording'}
          </Text>
        </Stack>
      </ToolbarButton>
    </Tooltip>
  )
}

export const EndSessionButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <ToolbarButton
      onClick={() => {
        trackEvent('Session -> Clicked End Session')
        onClick()
      }}
    >
      <VStack>
        <EndSession />
        <Text fontSize="10px" style={{ marginTop: 0 }}>
          End Session
        </Text>
      </VStack>
    </ToolbarButton>
  )
}

export const SettingsButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <ToolbarButton
      onClick={() => {
        trackEvent('Session -> Clicked Settings')
        onClick()
      }}
    >
      <VStack>
        <SettingsIcon />
        <Text fontSize="10px" style={{ marginTop: 0 }}>
          Settings
        </Text>
      </VStack>
    </ToolbarButton>
  )
}

export const SessionPlanButton = () => {
  const { isEvidenceBasedCareEnabled } = useExperienceManager()
  const { isSessionAgendaOpen, toggleSessionAgenda } = usePanelManager()
  const { user } = useStoreState((state: any) => state.auth)
  const openEbcMarketingModal = useStoreActions(
    (actions: any) => actions.modals.ebcMarketingModal.openModal
  )
  return (
    <ToolbarButton
      onClick={
        isEvidenceBasedCareEnabled
          ? toggleSessionAgenda
          : () => openEbcMarketingModal({ clinicId: user.clinicId })
      }
    >
      <VStack>
        <SessionAgendaIcon fill={isSessionAgendaOpen ? '#2D54E8' : '#282828'} />
        <Text fontSize="10px" style={{ marginTop: 0 }}>
          Session plan
        </Text>
      </VStack>
    </ToolbarButton>
  )
}

export const PrivateNotesButton = () => {
  const { toggleSessionNotes, isSessionNotesOpen } = usePanelManager()
  return (
    <ToolbarButton onClick={toggleSessionNotes}>
      <VStack>
        <SessionNotesIcon fill={isSessionNotesOpen ? '#2D54E8' : '#282828'} />
        <Text fontSize="10px" style={{ marginTop: 0 }}>
          Psychotherapy note
        </Text>
      </VStack>
    </ToolbarButton>
  )
}

export const DictationTipsButton = () => {
  const { toggleDictationTips, isDictationTipsOpen } = usePanelManager()
  return (
    <ToolbarButton onClick={toggleDictationTips}>
      <VStack>
        <LightBulb fill={isDictationTipsOpen ? '#2D54E8' : '#282828'} />
        <Text fontSize="10px" style={{ marginTop: 0 }}>
          Tips
        </Text>
      </VStack>
    </ToolbarButton>
  )
}

export const AssistButton = () => {
  const { isAssistOpen, toggleAssist } = usePanelManager()

  return (
    <ToolbarButton onClick={toggleAssist}>
      <VStack>
        <Compass fill={isAssistOpen ? '#2D54E8' : '#282828'} />
        <Text fontSize="10px" style={{ marginTop: 0 }}>
          Assist
        </Text>
      </VStack>
    </ToolbarButton>
  )
}
