import React, { useEffect } from 'react'
import { useStoreState } from 'easy-peasy'
import { Route } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { ErrorBoundary, Footer } from '../components'
import { api } from '../api'
import { auth } from '../auth'
import { PublicNavBar } from '@containers/nav-bar/public-nav-bar'

export function PublicRoute({
  component: Component,
  hideNavBar = false,
  ...rest
}) {
  const { isAuthenticated } = useStoreState(state => state.auth)

  const initializeGTM = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID
      })
    }
  }

  // Only ran on mount
  useEffect(() => {
    initializeGTM()
  }, [])

  return (
    <ErrorBoundary>
      <Route
        {...rest}
        render={() => (
          <div style={{ width: '100%', height: '100%' }}>
            {!hideNavBar && <PublicNavBar />}
            <Component
              auth={auth}
              api={api}
              {...rest}
              isAuthenticated={isAuthenticated}
            />
            <Footer />
          </div>
        )}
      />
    </ErrorBoundary>
  )
}
