import { useEffect } from 'react'
import { useStoreActions } from 'easy-peasy'
import { useHistory } from 'react-router-dom'

export function Logout() {
  const logout = useStoreActions(actions => actions.auth.logout)
  const history = useHistory()

  useEffect(() => {
    logout()
    history.replace('/')
  }, [])

  return null
}
