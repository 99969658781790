import React from 'react'
import { endpoints } from '@api'
import { useQuery, useMutation } from 'react-query'
import { useStoreActions } from 'easy-peasy'
import { groupBy, sortBy } from 'lodash'
import {
  Heading,
  Box,
  VStack,
  HStack,
  Stack,
  Text,
  List,
  ListItem,
  ListIcon,
  Spinner,
  Flex,
  Tooltip,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useToast
} from '@chakra-ui/react'
import { Button, CheckIcon } from '@blueprinthq/joy'
import { useHistory } from 'react-router-dom'
import { CircleQuestion } from '../../components/icons'
import { ACTIVE_STRIPE_SELF_SERVE_PRICES } from '@constants/stripePlans'
import { useExperienceManager } from '@hooks'

const Features = ({ features }) => {
  return (
    <Box>
      {features.everything_plus && (
        <Text align="left" color="#757575" fontSize="13px">
          {features.everything_plus}
        </Text>
      )}
      <List align="left" mt="4px">
        {Object.keys(features).map(key => {
          if (key !== 'everything_plus') {
            return (
              <ListItem fontSize="13px" lineHeight="24px" key={key}>
                <HStack>
                  <ListIcon opacity="0.5" as={CheckIcon} />
                  <span>{features[key]}</span>
                  {key === 'tooltip_implementation' && (
                    <Tooltip
                      label="A Blueprint specialist will help onboard you and your staff through a series of trainings to effectively implement measurement-based care across your practice"
                      aria-label="A tooltip"
                    >
                      <span>
                        <CircleQuestion fill="#757575" />
                      </span>
                    </Tooltip>
                  )}
                </HStack>
              </ListItem>
            )
          } else {
            return null
          }
        })}
      </List>
    </Box>
  )
}

const Package = ({
  priceBundle,
  clinicianCount,
  clientCount,
  subscribedPriceIds,
  interval,
  activeSubscriptionInterval,
  activeSubscriptionAmount,
  stripeCustomerId,
  isSoloPlan,
  isLegacySoloPlan,
  isTrialing,
  daysRemainingInTrial
}) => {
  const history = useHistory()
  const toast = useToast()

  const handleCloseModal = useStoreActions(
    actions => actions.modals.choosePlan.closeModal
  )

  const submitDowngrade = async priceId => {
    const data = {
      stripeCustomerId: stripeCustomerId,
      planId: priceId,
      isDowngrade: true
    }
    await downgradeSubscription({
      data
    })
  }

  const { mutateAsync: downgradeSubscription, err } = useMutation(
    endpoints.postUpsertSubscription.request,
    {
      onSuccess() {
        toast({
          title: 'You have successfully updated your plan.',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        handleCloseModal()
      },
      onError() {
        toast({
          title: 'Oops, something went wrong.',
          description: `Please try again or contact support at ${process.env.REACT_APP_HELP_EMAIL}`,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  const handleClick = (
    priceId,
    productName,
    isEnterprise,
    isContactSupport,
    isDowngrade
  ) => {
    const isSoloDisabled = clinicianCount > 1 ? true : false
    const isStarterDisabled = clientCount > 5 ? true : false

    if (productName === 'Solo' && isSoloDisabled) {
      toast({
        title: 'You have too many Clinician Users for the Solo Plan.',
        description: 'Please remove users or select a different plan.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } else if (productName === 'Starter' && isStarterDisabled) {
      toast({
        title: 'You have too many Clients for the Starter Plan.',
        description: 'Please discharge clients or select a different plan.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } else if (isEnterprise) {
      window.open(
        'https://meetings.hubspot.com/blueprint/upgrade-to-enterprise',
        '_blank'
      )
    } else if (isContactSupport || (isDowngrade && productName === 'Starter')) {
      //must contact support to downgrade to starter  OR to go from annual -> monthly plan
      toast({
        title: 'Please contact support to make this change.',
        description:
          "Use the support chat widget to let us know your desired changes and we'll get back to you ASAP.",
        status: 'warning',
        duration: 3000,
        isClosable: true
      })
      window.Intercom('showNewMessage', "Hi, I'd like to change my plan!")
      handleCloseModal()
    } else if (isDowngrade) {
      submitDowngrade(priceId)
    } else {
      handleCloseModal()
      history.push('/upgrade/payment?planId=' + priceId)
    }
  }

  return priceBundle.map(price => {
    if (price.recurring.interval === interval) {
      const stripeInterval = interval === 'month' ? 'monthly' : 'annual'
      const isEnterprise =
        price.id ===
        ACTIVE_STRIPE_SELF_SERVE_PRICES[process.env.REACT_APP_ENV].enterprise[
          stripeInterval
        ]
          ? true
          : false
      const isStarter =
        price.id ===
        ACTIVE_STRIPE_SELF_SERVE_PRICES[process.env.REACT_APP_ENV].starter[
          stripeInterval
        ]
          ? true
          : false
      const isSolo =
        price.id ===
        ACTIVE_STRIPE_SELF_SERVE_PRICES[process.env.REACT_APP_ENV].solo[
          stripeInterval
        ]
          ? true
          : false
      const isActivePrice = isTrialing
        ? false
        : subscribedPriceIds && subscribedPriceIds.includes(price.id)
        ? true
        : activeSubscriptionAmount === 0 && isStarter //handle starter monthly/annual --> starter
        ? true
        : !subscribedPriceIds && isStarter // handle no subscriptions --> starter
        ? true
        : subscribedPriceIds && // handle legacy basic plans --> solo
          !subscribedPriceIds.includes(price.id) &&
          isSoloPlan &&
          isSolo &&
          isLegacySoloPlan
        ? true
        : false
      const isContactSupport =
        activeSubscriptionInterval === 'year' && interval === 'month'
          ? true
          : false
      const isDowngrade =
        activeSubscriptionAmount > price.unit_amount ? true : false

      const displayPrice = isEnterprise
        ? "Let's Talk"
        : isStarter
        ? 'Free'
        : price.recurring.interval === 'year'
        ? '$' + Math.round(price.unit_amount / 100 / 12)
        : '$' + price.unit_amount / 100

      const productName = price.productObject.name.replace(
        /Evidence-Based Care:?\s?/,
        ''
      )

      return (
        <Flex key={price.id} flex="1" pl="8px" pr="8px">
          <Flex
            direction="column"
            padding="16px"
            height="100%"
            minHeight="420px"
            width={{ base: '250px', sm: '350px', md: '100%' }}
            align="left"
            border="1px solid #E4E5E6"
            borderRadius="8px"
            key={price.id}
          >
            <Box>
              <Box mb="24px">
                <Heading size="lg" color="#282828">
                  {productName}
                </Heading>
                <Text textAlign="left" mt="12px" fontSize="13px">
                  {price.productObject.description}
                </Text>
              </Box>
              <Features features={price.productObject.metadata} />
            </Box>
            <Box minHeight="112px" mt="auto">
              <VStack
                minHeight="45px"
                mb="16px"
                align="left"
                justify="center"
                spacing="0"
              >
                <HStack pl="8px" pr="8px">
                  <Heading color="#282828" size="md">
                    {displayPrice}
                  </Heading>
                  {!isEnterprise && !isStarter && (
                    <Text lineHeight="md" fontSize="13px">
                      per Clinician per month
                    </Text>
                  )}
                </HStack>
              </VStack>
              <VStack justify="end" h="70px">
                {!isEnterprise && !isContactSupport && interval !== 'month' && (
                  <Text color="#757575" fontSize="13px">
                    {'$' +
                      Math.floor(
                        (price.unit_amount / 100) * clinicianCount
                      )}{' '}
                    (saves $
                    {Math.floor(
                      ((clinicianCount * price.unit_amount) / 100) * 0.2
                    )}
                    )
                  </Text>
                )}
                {isTrialing && isSolo && interval === 'month' && (
                  <Text color="#757575" fontSize="13px">
                    {daysRemainingInTrial} days remaining in Free Trial
                  </Text>
                )}
                {isActivePrice ? (
                  <HStack
                    mt="4px"
                    spacing="6px"
                    align="center"
                    justify="center"
                    w="100%"
                    borderRadius="20px"
                    border="1px solid #E4E5E6"
                    h="40px"
                  >
                    <CheckIcon size="md" fill="primary" />
                    <Text color="primary">Current plan</Text>
                  </HStack>
                ) : (
                  <Button
                    w="100%"
                    m="0"
                    onClick={() =>
                      handleClick(
                        price.id,
                        price.productObject.name,
                        isEnterprise,
                        isContactSupport,
                        isDowngrade
                      )
                    }
                  >
                    {isEnterprise || isContactSupport ? 'Contact Us' : 'Choose'}
                  </Button>
                )}
              </VStack>
            </Box>
          </Flex>
        </Flex>
      )
    }
    return null
  })
}

const Packages = ({ paywallData, prices, interval }) => {
  const {
    clinicianCount,
    clientCount,
    subscribedPrices,
    stripeCustomerId,
    isStarterPlan,
    isSoloPlan,
    isLegacySoloPlan,
    isTrialing,
    daysRemainingInTrial
  } = paywallData
  const subscribedPriceIds =
    subscribedPrices && subscribedPrices.map(price => price.id)
  const sortedPrices = sortBy(prices, ['recurring.interval', 'unit_amount'])
  const checkForStarter = !isStarterPlan
    ? sortedPrices.filter(price => price.unit_amount !== 0)
    : sortedPrices
  const pricesbyProduct = groupBy(checkForStarter, price => price.product)
  const productIds = Object.keys(pricesbyProduct).map(key => key)
  const activeSubscriptionInterval =
    subscribedPrices && subscribedPrices[0].interval
  const activeSubscriptionAmount =
    subscribedPrices && subscribedPrices[0].amount
  if (!prices) {
    return null
  }

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      mt="16px"
      height="100%"
      align={{ base: 'center', md: 'start' }}
      spacing={{ base: '32px', md: 0 }}
      justify="center"
    >
      {productIds.map((productId, index) => {
        const priceBundle = pricesbyProduct[productId]
        return (
          <Package
            key={index}
            priceBundle={priceBundle}
            clinicianCount={clinicianCount}
            clientCount={clientCount}
            subscribedPriceIds={subscribedPriceIds}
            interval={interval}
            activeSubscriptionInterval={activeSubscriptionInterval}
            activeSubscriptionAmount={activeSubscriptionAmount}
            stripeCustomerId={stripeCustomerId}
            isSoloPlan={isSoloPlan}
            isLegacySoloPlan={isLegacySoloPlan}
            isTrialing={isTrialing}
            daysRemainingInTrial={daysRemainingInTrial}
          />
        )
      })}
    </Stack>
  )
}

export const Plans = () => {
  const history = useHistory()
  const toast = useToast()
  const { paywallData } = useExperienceManager()

  const { data: prices } = useQuery(
    [endpoints.getPrices.getCacheId()],
    endpoints.getPrices.request,
    {
      initialData: []
    }
  )

  const handleCloseModal = useStoreActions(
    actions => actions.modals.choosePlan.closeModal
  )

  const { subscribedPrices } = paywallData
  const subscribedInterval = subscribedPrices && subscribedPrices[0].interval
  const initialTab = subscribedInterval === 'month' ? 0 : 1

  return (
    <Box pb="12px" pt="12px" w="100%">
      <Heading align="center" mb="24px">
        Choose Plan
      </Heading>
      <Tabs defaultIndex={initialTab} align="center" variant="unstyled">
        <TabList w="266px" bg="#E4E5E6" padding="4px" borderRadius="50px">
          <Tab borderRadius="100px" _selected={{ bg: 'white' }}>
            Monthly
          </Tab>
          <Tab borderRadius="100px" _selected={{ bg: 'white' }}>
            Annually (20% off)
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {prices && prices.length ? (
              <Packages
                paywallData={paywallData}
                prices={prices}
                interval="month"
              />
            ) : (
              <VStack mt="32px" justify="center" h="384px">
                <Spinner color="primary" />
              </VStack>
            )}
          </TabPanel>
          <TabPanel>
            {prices && prices.length ? (
              <Packages
                paywallData={paywallData}
                prices={prices}
                interval="year"
              />
            ) : (
              <VStack mt="32px" justify="center" h="400px">
                <Spinner color="primary" />
              </VStack>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
