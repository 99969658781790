import React, { useRef } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Heading,
  PopoverArrow,
  Text
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { useHistory } from 'react-router-dom'

export const GetStartedPopover = ({ children, ...rest }) => {
  const history = useHistory()
  const getStartedRef = useRef()
  return (
    <Popover
      initialFocusRef={getStartedRef}
      arrowSize={24}
      placement="top-end"
      {...rest}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        sx={{
          boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.24)',
          marginTop: 'small',
          padding: 'medium',
          border: 0
        }}
      >
        <PopoverArrow />
        <PopoverHeader fontWeight="bold" border="0">
          <Heading as="h3">Billing Rules</Heading>
        </PopoverHeader>
        <PopoverBody>
          <Text mb="small">
            Setup your organizations billing strategy to help you streamline the
            claim submission process.
          </Text>
          <Button
            ref={getStartedRef}
            size="lg"
            onClick={() => history.push('/reports/claims/settings')}
            isFullWidth
          >
            Get Started
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
