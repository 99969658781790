import React, { useContext } from 'react'
import { Heading, Box } from '@chakra-ui/react'

import { WorkflowsContext } from '@context'
import { CirclePlus } from '@icons'
import { usePermissions } from '@hooks'

type WorkflowNodeProps = {
  heading: string
  body: React.ReactNode
  isParent: boolean
  hasChildren: boolean
  isSelected: boolean
  onClick: any
  isNew?: boolean
}

type DottedLineProps = {
  withPlusIcon: boolean
}

export const DottedLine = ({ withPlusIcon }: DottedLineProps) => {
  const { setSelectedNode, newActionNode } = useContext(WorkflowsContext)
  const height = withPlusIcon ? '13px' : '25px'
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      onClick={() => setSelectedNode(newActionNode())}
      _hover={{ cursor: withPlusIcon ? 'pointer' : 'inherit' }}
      sx={{
        ...(withPlusIcon ? { marginBottom: '40px !important' } : {})
      }}
    >
      <Box
        sx={{
          borderLeft: '3px dashed',
          borderColor: 'pale_gray',
          height
        }}
      />
      {withPlusIcon && <CirclePlus />}
    </Box>
  )
}

export const WorkflowNode = ({
  heading,
  body,
  isParent,
  hasChildren,
  isSelected,
  onClick,
  isNew
}: WorkflowNodeProps) => {
  const { isCreatingAction } = useContext(WorkflowsContext)
  const { hasPermission } = usePermissions()
  const hasEditPermission = hasPermission('*:org:workflows:*')

  const hasDottedLine = hasChildren || (!isNew && hasEditPermission)

  return (
    <>
      <Box
        data-intercom-target={`workflows-node-${
          isParent ? 'trigger' : 'action'
        }`}
        {...(hasEditPermission ? { onClick } : {})}
        _hover={hasEditPermission ? { cursor: 'pointer' } : {}}
        sx={{
          width: '500px',
          border: '2px solid',
          borderRadius: '12px',
          borderColor: 'pale_gray',
          ...(isSelected
            ? {
                borderColor: 'primary'
              }
            : {}),
          ...(isParent && !isSelected
            ? {
                borderColor: '#282828'
              }
            : {})
        }}
      >
        <Box
          sx={{
            borderTopRadius: '8px',
            bgColor: 'pale_gray',
            color: '#282828',
            ...(isSelected
              ? {
                  bgColor: 'primary',
                  color: 'white'
                }
              : {}),
            ...(isParent && !isSelected
              ? {
                  bgColor: '#282828',
                  color: 'white'
                }
              : {})
          }}
        >
          <Heading size="md" textAlign="center" p={3}>
            {heading}
          </Heading>
        </Box>
        <Box
          p={5}
          textAlign="center"
          backgroundColor="white"
          borderRadius="12px"
        >
          {body}
        </Box>
      </Box>
      {hasDottedLine && (
        <DottedLine withPlusIcon={!hasChildren && !isCreatingAction} />
      )}
    </>
  )
}
