import React, { Component } from 'react'
import { PrimaryButton, Loading } from '../components'
import { Text } from '@chakra-ui/react'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import { withRouter } from 'react-router-dom'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import styles from './assessment.module.css'
import { isMobile } from 'react-device-detect'
import { isEmpty, filter, includes, xor, cloneDeep } from 'lodash'
import { FormGroup } from '@material-ui/core'
import queryString from 'query-string'
import * as uuid from 'uuid'

class Assessment extends Component {
  constructor(props) {
    const urlParams = new URLSearchParams(props.location.search)
    const more = urlParams.get('more')
    let finalLink = more !== null ? more.split(',')[0] : []
    if (more !== null && more.split(',').length > 1) {
      const additionalLinks = more
        .split(',')
        .slice(1)
        .join(',')
      finalLink = `${finalLink}&more=${encodeURIComponent(additionalLinks)}`
    }

    super(props)
    this.state = {
      user_id: props.match.params.id,
      patient: {},
      assessments: [],
      preview: false,
      assessmentResponses: {},
      newAssessments: [],
      newAssessmentIds: [],
      more: more !== null && more.split(',').length !== 0 ? true : false,
      nextLink: finalLink ? finalLink : null
    }
  }

  componentDidMount() {
    this.setState(
      {
        assessments:
          this.props.assessments || this.props.location.state.assessments,
        preview: this.props.preview
      },
      () => {
        if (!this.state.preview) {
          this.fetchAccountData()
        }
      }
    )
  }

  fetchAccountData = () => {
    const { user_id } = this.state
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL + '/clinicians/clients/' + user_id
    this.props.api
      .GET(path)
      .then(({ first_name, last_name }) => {
        this.setState({
          patient: {
            first_name,
            last_name
          }
        })
      })
      .catch(err => {
        console.error(err) //TODO
      })
  }

  postAssessment = data => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      `/clinicians/clients/${data.user_id}/assessment-scores`
    const { assigneeUserId } = queryString.parse(this.props.location.search)
    const body = JSON.stringify({
      scores: data.scores,
      assigneeUserId
    })
    this.setState(
      {
        isSubmitting: true,
        error: null
      },
      () => {
        this.props.api
          .POST(path, body, this.props.user_role)
          .then(json => {
            this.setState({
              isSubmitting: false,
              isSubmitted: true
            })
            if (json.has_new_assessments) {
              this.startNewAssessments()
            }
            if (this.state.more) {
              this.props.history.push(this.state.nextLink)
              this.props.history.go()
            }
          })
          .catch(() => {
            this.setState({
              error:
                'Oops, something went wrong. Please refresh the page and try again.'
            })
          })
      }
    )
  }

  startNewAssessments = () => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      `/clinicians/clients/${this.state.user_id}/assessments/active?includeFreeTextQuestions=true`
    this.props.api
      .GET(path)
      .then(json => {
        const newAssessmentIds = json.map(assessment => assessment.id)
        this.props.history.push(
          `/patient/${this.state.user_id}/assessment/${newAssessmentIds}/take-now`
        )
        this.props.history.go()
      })
      .catch(err => {
        console.error(err)
      })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { user_id } = this.state
    const isValid = await this.validateForm()
    if (isValid) {
      const assessmentResponses = this.extractAssessmentResponsesFromState()
      const data = {
        user_id: user_id,
        scores: assessmentResponses
      }
      this.postAssessment(data)
    } else {
      this.setState({
        error: 'Please answer all the questions!'
      })
    }
  }

  extractAssessmentResponsesFromState = () => {
    const assessmentResponses = []
    Object.keys(this.state.assessmentResponses).map(aResponse => {
      assessmentResponses.push({
        id: aResponse,
        answers: Object.keys(this.state.assessmentResponses[aResponse]).map(
          key => {
            const assessmentRefObject = this.state.assessments.find(
              a => a.id === aResponse
            )
            const questionRefObject = assessmentRefObject.content.sections[0].questions.find(
              q => q.key === key
            )

            const answerValue = this.state.assessmentResponses[aResponse][key]

            if (
              questionRefObject.type === 'multi-select' &&
              answerValue.every(uuid.validate)
            ) {
              return {
                key,
                answerValue: {
                  answerIds: answerValue
                }
              }
            } else if (uuid.validate(answerValue)) {
              return {
                key,
                answerValue: {
                  answerId: answerValue
                }
              }
            }

            return {
              key: key,
              value: this.state.assessmentResponses[aResponse][key]
            }
          }
        )
      })
      return null
    })
    return assessmentResponses
  }

  fetchAnswerRefObjectByValue = (assessmentId, questionKey, answerValue) => {
    const assessmentRefObject = this.state.assessments.find(
      a => a.id === assessmentId
    )
    const questionRefObject = assessmentRefObject.content.sections[0].questions.find(
      q => q.key === questionKey
    )
    const answers =
      questionRefObject.answers ||
      assessmentRefObject.content.sections[0].answers
    return answers.find(a => {
      if (a.id) {
        return a.id === answerValue
      }
      return a.value === answerValue
    })
  }

  handleResponseChange = (event, questionKey, isMultiSelect, isFreeText) => {
    const assessmentId = event.target.closest('#question').getAttribute('data')

    if (!isFreeText) {
      const value = uuid.validate(event.target.value)
        ? event.target.value
        : parseFloat(event.target.value)
      const currentResponses = this.state.assessmentResponses
      const currentValue = currentResponses[assessmentId]
        ? currentResponses[assessmentId][questionKey]
        : null

      const hasQuestionBeenAnswered = !!currentValue
      const wasPrevAnswerAnOverride = hasQuestionBeenAnswered
        ? Array.isArray(currentValue) && currentValue.length >= 1
          ? isMultiSelect
            ? !!this.fetchAnswerRefObjectByValue(
                assessmentId,
                questionKey,
                currentValue[0]
              ).isOverride
            : !!this.fetchAnswerRefObjectByValue(
                assessmentId,
                questionKey,
                currentValue
              ).isOverride
          : false
        : false
      const isNewAnswerAnOverride = !!this.fetchAnswerRefObjectByValue(
        assessmentId,
        questionKey,
        value
      ).isOverride

      let valueToInsert
      if (
        isMultiSelect &&
        (!hasQuestionBeenAnswered ||
          wasPrevAnswerAnOverride ||
          isNewAnswerAnOverride)
      )
        valueToInsert = [value]
      else if (isMultiSelect)
        valueToInsert = this.determineMultiSelectValue(currentValue, value)
      else valueToInsert = value

      const updatedAssessmentResponses = {
        ...this.state.assessmentResponses[assessmentId],
        [questionKey]: valueToInsert
      }
      if (Array.isArray(valueToInsert) && !valueToInsert.length)
        delete updatedAssessmentResponses[questionKey]
      this.setState({
        assessmentResponses: {
          ...this.state.assessmentResponses,
          [assessmentId]: updatedAssessmentResponses
        }
      })
    } else {
      this.setState(prevState => ({
        ...prevState,
        assessmentResponses: {
          ...prevState.assessmentResponses,
          [assessmentId]: {
            ...prevState.assessmentResponses[assessmentId],
            [questionKey]: event.target.value
          }
        }
      }))
    }
  }

  determineMultiSelectValue = (current, value) => {
    let cleansedCurrent = Array.isArray(current) ? current : []
    return xor(cleansedCurrent, [value])
  }

  validateForm = () => {
    const assessmentResponses = this.state.assessmentResponses
    return this.state.assessments.every(assessment => {
      // get assessment question data
      const allQuestions = assessment.content.sections.flatMap(
        section => section.questions
      )
      const numQuestions = assessment.num_questions

      // look for assessment in reponses
      const match = assessmentResponses[assessment.id]
      if (!match) return false

      // get questions that were answered
      const answeredQuestionKeys = Object.keys(match)
      if (answeredQuestionKeys.length >= numQuestions) return true

      // look through skipped questions, set to null when questions is skippable
      const skippedQuestions = filter(
        allQuestions,
        question => !includes(answeredQuestionKeys, question.key)
      )

      let isValid = true
      let newAssessmentResponses = cloneDeep(this.state.assessmentResponses)
      skippedQuestions.forEach(question => {
        if (question.skippable)
          newAssessmentResponses[assessment.id][question.key] = null
        // dont break early so all skippable questions can be marked null anyways
        else isValid = false
      })
      this.setState({ assessmentResponses: newAssessmentResponses })
      return isValid
    })
  }

  renderSections = assessment => {
    const { content } = assessment
    return content.sections.map((section, index) => {
      const { title, questions } = section
      const questionsNode = questions.map(question => {
        const isMultiSelect = question.type === 'multi-select'
        const isFreeText = question.type === 'free_text'
        const FormController =
          isMultiSelect || isFreeText ? FormGroup : RadioGroup
        return (
          <div className={styles.question} key={question.key}>
            <p className={styles.question_title}>
              {question.title}
              {question.skippable
                ? ' You may optionally skip this question.'
                : null}
            </p>
            <FormController
              aria-label={question.title}
              name={question.key}
              className={styles.answers}
              onChange={e =>
                this.handleResponseChange(
                  e,
                  question.key,
                  isMultiSelect,
                  isFreeText
                )
              }
            >
              {this.renderAnswers(
                assessment.id,
                question,
                section,
                isMultiSelect,
                isFreeText
              )}
            </FormController>
          </div>
        )
      })
      return (
        <div
          className={styles.section}
          id="question"
          data={assessment.id}
          key={index}
        >
          <Text lineHeight={1.5} fontSize="lg" whiteSpace="pre-line">
            {title}
          </Text>
          <div className={styles.questions}>{questionsNode}</div>
        </div>
      )
    })
  }

  renderAnswers = (
    assessmentId,
    question,
    section,
    isMultiSelect,
    isFreeText
  ) => {
    let answers = section.answers
    if (question.answers) {
      answers = question.answers
    }

    if (isFreeText) {
      return <TextField fullWidth multiline rows={4} />
    }

    return answers.map((answer, index) => {
      return (
        <FormControlLabel
          key={index}
          // className={styles.label}
          value={answer.id || answer.value.toString()}
          label={answer.title}
          control={
            isMultiSelect ? (
              <Checkbox
                color="primary"
                checked={this.isAnswerChecked(
                  assessmentId,
                  question.key,
                  answer.value
                )}
              />
            ) : (
              <Radio color="primary" />
            )
          }
          disabled={this.state.preview}
        />
      )
    })
  }

  isAnswerChecked = (assessmentId, questionKey, answerValue) => {
    const { assessmentResponses: responses } = this.state
    const isAnswerChecked = Boolean(
      responses[assessmentId] &&
        responses[assessmentId][questionKey] &&
        responses[assessmentId][questionKey].includes(answerValue)
    )
    return isAnswerChecked
  }

  renderAssessments = () => {
    const { assessments, preview, error } = this.state
    if (assessments.length) {
      return (
        <form
          onSubmit={this.handleSubmit}
          className={isMobile ? styles.mobile_container : styles.container}
        >
          {assessments.map(assessment => {
            return (
              <div className={styles.assessment} key={assessment.id}>
                {!preview && (
                  <p
                    className={styles.assessment_name}
                  >{`${assessment.name} | ${assessment.num_questions} questions`}</p>
                )}
                {this.renderSections(assessment)}
              </div>
            )
          })}
          {error ? (
            <p className={styles.error}>{error}</p>
          ) : (
            <p className={styles.error}></p>
          )}
          {!preview && (
            <PrimaryButton
              className={
                isMobile ? styles.mobile_submit_button : styles.submit_button
              }
              loading={this.state.isSubmitting}
              round
              success={this.state.isSubmitted}
              fullWidth
              type="submit"
            >
              Complete assessment
            </PrimaryButton>
          )}
        </form>
      )
    } else {
      return null
    }
  }

  render() {
    const { patient, assessments, preview } = this.state
    return (
      <div className={styles.page}>
        {preview ? (
          <div className={styles.header}>
            <h1>{assessments[0].name}</h1>
            <h2>{`${assessments[0].num_questions} questions`}</h2>
          </div>
        ) : isEmpty(patient) ? (
          <div style={{ height: '100vh' }}>
            <Loading />
          </div>
        ) : (
          <div className={styles.header}>
            <h1>{`${patient.first_name} ${patient.last_name}'s Assessment`}</h1>
            <p>{`Assigned assessments: ${assessments
              .map(assessment => assessment.name)
              .join(', ')}`}</p>
          </div>
        )}
        {this.renderAssessments()}
      </div>
    )
  }
}

export default withRouter(Assessment)
