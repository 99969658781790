import { Button, ButtonProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

interface PillProps extends Omit<ButtonProps, 'onClick' | 'value'> {
  activeValue: any
  value: string | string[] | boolean | undefined
  onClick: (value: any) => void
  children: ReactNode
}

const Pill: React.FC<PillProps> = ({
  activeValue,
  value,
  onClick,
  children,
  ...buttonProps
}) => {
  const isActive = Array.isArray(value)
    ? value.includes(activeValue)
    : value === activeValue

  return (
    <Button
      flex="1"
      borderRadius="8px"
      variant="outline"
      bg={isActive ? 'primary' : 'white'}
      color={isActive ? 'white' : 'black'}
      onClick={() => onClick(activeValue)}
      _hover={isActive ? undefined : { bg: 'pale_gray' }}
      _focus={{ outline: 'none' }}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}

export default Pill
