import React from 'react'
import { Flex } from '@chakra-ui/react'

export function StickyFooter({ children }) {
  return (
    <Flex
      position={'fixed'}
      width={'100%'}
      height={'80px'}
      backgroundColor={'white'}
      bottom={'0'}
      right={'0'}
      left={'auto'}
      borderTop={'solid'}
      borderTopColor={'#E4E5E6'}
      zIndex={10}
    >
      {children}
    </Flex>
  )
}
