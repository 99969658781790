import { useQuery } from 'react-query'
import { endpoints } from '@api'

type AppointmentTypes = {
  appointmentTypes: string[]
}

type QueryResult = {
  data: AppointmentTypes | undefined
  isLoading: boolean
}

export const useOrganizationAppointmentTypes = (organizationId: string): QueryResult =>
  useQuery(
    [endpoints.getOrganizationAppointmentTypes.getCacheId(), organizationId],
    () =>
      // @ts-ignore
      endpoints.getOrganizationAppointmentTypes.request({ organizationId })
  )
