import React from 'react'
import { VStack, Flex, Box, Heading, Text } from '@chakra-ui/react'
import { CircleCheck } from '../../../../components/icons'

const Feature = ({ title, description }) => {
  return (
    <Flex>
      <Box mr="16px">
        <CircleCheck width="24px" height="24px" fill="#F8D78A" />
      </Box>
      <VStack style={{ marginLeft: '0px' }} align="left" ml="0">
        <Heading
          align="left"
          color="white"
          style={{ fontSize: '20px' }}
          mb="2px"
        >
          {title}
        </Heading>
        <Text style={{ marginTop: '0px' }} align="left" color="white">
          {description}
        </Text>
      </VStack>
    </Flex>
  )
}

export const ScheduleContent = () => {
  return (
    <VStack maxWidth="500px" spacing="40px">
      <Heading marginTop="32px" color="white" align="center" size="xl">
        Level up your behavioral health organization
      </Heading>
      <VStack spacing="32px">
        <Feature
          title="Keep clients engaged"
          description="Reduce cancellations and no-shows by 20% by keeping clients more connected and confident through the ups and downs of care."
        />
        <Feature
          title="Improve quality of care"
          description="Clients respond to treatment up to 44% quicker when their clinician is using Blueprint."
        />
        <Feature
          title="Reduce clinician burnout"
          description="Give your clinicians a new tool that helps them do their best work while reducing documentation by 2-3 hours per week."
        />
        <Feature
          title="Increase reimbursement revenue"
          description="Better justify medical necessity with measurable outcomes, take advantage of new CPT codes, and negotiate better rates by leveraging outcomes data."
        />
      </VStack>
    </VStack>
  )
}
