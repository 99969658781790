import React from 'react'
import {
  Flex,
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useExperienceManager } from '@hooks'
import { StoreModel } from 'src/store/types'

export const EbcMarketingModal = () => {
  const { isAdmin } = useExperienceManager()

  const { isOpen, clinicId } = useStoreState(
    (state: StoreModel) => state.modals.ebcMarketingModal
  )
  const { closeModal } = useStoreActions<StoreModel>(
    (actions: any) => actions.modals.ebcMarketingModal
  )

  const { openModal: openEbcUpgradePlanModal } = useStoreActions(
    (actions: any) => actions.modals.ebcUpgradePlan
  )

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton _focus={{ outline: 'none' }} />
        <ModalBody padding="24px 24px 0 24px">
          <Flex flexDirection="column">
            <Text fontWeight="bold" fontSize="24px" textAlign="center">
              Evidence-Based Care
            </Text>
            <Box height="40px" />
            <Text>
              Automatically assign, administer, and document assessments,
              homework, and guided evidence-based interventions. Blueprint's
              powerful Evidence-Based Care platform gives you progress insight
              and helps keep clients engaged between sessions with zero
              additional admin time.
            </Text>
          </Flex>
          <Box height="16px" />
          <Flex justifyContent="center">
            {isAdmin ? (
              <Button
                bg="#282828"
                width="241px"
                onClick={() => {
                  closeModal()
                  openEbcUpgradePlanModal({ clinicId })
                }}
              >
                Start a 30 day free trial
              </Button>
            ) : (
              <Text fontWeight="bold">
                Contact your admin to turn on this feature
              </Text>
            )}
          </Flex>
          <Box height="33px" />
          <Flex style={{ marginTop: 0 }} justifyContent="center">
            <img src="https://res.cloudinary.com/hellojoy/image/upload/v1706630973/ebc-marketing.png" />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const EbcMarketingModalConnected = () => {
  const user = useStoreState((state: StoreModel) => state.auth.user)

  if (!user) {
    return null
  }

  return <EbcMarketingModal />
}
