import React from 'react'
import {
  Heading,
  Box,
  Stack,
  Flex,
  HStack,
  VStack,
  Link,
  Text,
  Select
} from '@chakra-ui/react'
import { TextField, Button } from '@blueprinthq/joy'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQuery } from 'react-query'
import { useStoreActions } from 'easy-peasy'

import { DEFAULT_PASSWORD_SCHEMA } from '../../../components/passwordrules'
import { endpoints } from '../../../api'
import { PasswordRules } from '../../../components'
import config from '../../../config'
import { useQueryParams } from '../../../hooks/use-query-params'

const AccountSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Required')
    .trim(),
  last_name: Yup.string()
    .required('Required')
    .trim(),
  license_level: Yup.object()
    .shape({
      id: Yup.string().required('License level is required'),
      display: Yup.string().required('License level is required')
    })
    .required('License level is required')
    .nullable(),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: DEFAULT_PASSWORD_SCHEMA
})

export const AcceptInviteForm = props => {
  const { user, onSuccess } = props
  const queryParams = useQueryParams()
  const email = queryParams.get('email')
    ? queryParams.get('email').replace(/ /g, '+')
    : null
  const authLogin = useStoreActions(actions => actions.auth.login)

  const { data: licenseLevels } = useQuery(
    [endpoints.getLicenseLevels.getCacheId()],
    endpoints.getLicenseLevels.request,
    {
      initialData: []
    }
  )

  const { mutateAsync: setUserAccount, isError } = useMutation(
    endpoints.patchClinicianAccount.request,
    {
      onSuccess: async (_, variables) => {
        const credentials = { email, password: variables.password }
        await authLogin(credentials)
        onSuccess()
      }
    }
  )

  return (
    <Box w="100%">
      <Formik
        initialValues={{
          first_name: user && user.first_name,
          last_name: user && user.last_name,
          email: user && user.email,
          password: '',
          license_level:
            user && user.license_level
              ? licenseLevels.find(level => level.id === user.license_level)
              : null
        }}
        enableReinitialize
        validationSchema={AccountSchema}
        onSubmit={async (values, actions) => {
          const credentials = { password: values.password }
          const userInfo = {
            id: user.id,
            firstName: values.first_name,
            lastName: values.last_name,
            license_level: values.license_level.id
          }
          await setUserAccount({ ...credentials, ...userInfo })
          actions.setSubmitting(false)
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <VStack spacing="small">
              <Box w="100%" pb="medium">
                <Heading w="100%" mb="4px" size="lg">
                  Set your password
                </Heading>
                <Text>
                  Finish creating your account and get access to Blueprint
                </Text>
              </Box>
              <HStack
                w="100%"
                gap={'small'}
                style={{ marginTop: 0 }}
                pb={
                  ((errors.first_name && touched.first_name) ||
                    (errors.last_name && touched.last_name)) &&
                  'small'
                }
              >
                <Box w="100%">
                  <Field name="first_name">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="First name"
                        isInvalid={errors.first_name && touched.first_name}
                        errorText={errors.first_name}
                      />
                    )}
                  </Field>
                </Box>
                <Box
                  style={{ marginInlineStart: 0, WebkitMarginStart: 0 }}
                  w="100%"
                >
                  <Field name="last_name">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Last name"
                        isInvalid={errors.last_name && touched.last_name}
                        errorText={errors.last_name}
                      />
                    )}
                  </Field>
                </Box>
              </HStack>
              <Field name="email">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    disabled={user && user.email}
                    sx={{
                      opacity: '1 !important',
                      backgroundColor: 'pale_blue'
                    }}
                    isInvalid={errors.email && touched.email}
                    errorText={errors.email}
                  />
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <Box width="100%">
                    <TextField
                      {...field}
                      label="Set a password"
                      type="password"
                      autoFocus
                      isInvalid={errors.password && touched.password}
                      errorText={!field.value && errors.password}
                    />
                    <PasswordRules password={field.value} />
                  </Box>
                )}
              </Field>
              <Field name="license_level">
                {({ field, form }) => (
                  <Select
                    {...field}
                    placeholder="License type"
                    sx={{
                      // Apply styles to the select itself
                      color: field.value ? 'black' : 'gray.500' // Default color for options
                    }}
                    onChange={event => {
                      const selectedState = licenseLevels.find(
                        state => state.id === event.target.value
                      )
                      form.setFieldValue('license_level', selectedState || null)
                    }}
                    value={field.value ? field.value.id : ''}
                    style={{
                      marginTop: 0,
                      border: '1px solid #C9C9C9',
                      borderRadius: '6px',
                      height: '48px'
                    }}
                  >
                    {licenseLevels &&
                      licenseLevels.map(state => (
                        <option
                          key={state.id}
                          style={{ color: 'black' }}
                          value={state.id}
                        >
                          {state.display}
                        </option>
                      ))}
                  </Select>
                )}
              </Field>
              {errors.license_level && touched.license_level && (
                <Text
                  sx={{
                    textAlign: 'left',
                    width: '100%',
                    display: 'block',
                    paddingLeft: '16px',
                    fontSize: '12px'
                  }}
                  color="error"
                  style={{ marginTop: 0 }}
                >
                  License level is required
                </Text>
              )}

              <Box sx={{ width: '100%' }}>
                {isError && (
                  <Box mb="xsmall">
                    <Text color="severe">
                      Oops, something went wrong. Please refresh the page and
                      try again.
                    </Text>
                  </Box>
                )}
                <Button
                  isLoading={isSubmitting}
                  size="lg"
                  type="submit"
                  margin={0}
                  isFullWidth
                  spinnerPlacement="start"
                >
                  Set password and log in
                </Button>
              </Box>
              <Text sx={{ fontSize: 'md', textAlign: 'center', mt: 'small' }}>
                By using Blueprint you are agreeing to our{' '}
                <Link
                  sx={{ color: 'primary' }}
                  href={config.privacyPolicyURL}
                  rel="noopener noreferrer"
                  isExternal
                >
                  privacy policy,
                </Link>{' '}
                <Link
                  sx={{ color: 'primary' }}
                  href={config.termsAndConditionsURL}
                  rel="noopener noreferrer"
                  isExternal
                >
                  terms
                </Link>
                {' and '}
                <Link
                  sx={{ color: 'primary' }}
                  href={config.baaURL}
                  rel="noopener noreferrer"
                  isExternal
                >
                  BAA.
                </Link>
              </Text>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
