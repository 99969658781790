import React from 'react'
import { Stack } from '@chakra-ui/react'
import Pill from './pill'

type OptionType = {
  value: string
  label: string
}

type OptionPanelProps = {
  options: OptionType[]
  value: string | string[] | undefined
  onClick: (value: string) => void
}

const OptionPanel: React.FC<OptionPanelProps> = ({
  options,
  value,
  onClick
}) => {
  return (
    <Stack spacing="0" gap="8px" flex="1">
      {options.map((item, index) => (
        <Pill
          key={index}
          activeValue={item.value}
          value={value}
          onClick={onClick}
        >
          {item.label}
        </Pill>
      ))}
    </Stack>
  )
}

export default OptionPanel
