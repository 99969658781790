import React, { useContext } from 'react'
import { Text, Box, Select, Flex, CloseButton } from '@chakra-ui/react'
import { Formik, Form, Field, FieldArray } from 'formik'
import { useQuery } from 'react-query'
import { AddCircleIcon } from '@blueprinthq/joy'
import * as Yup from 'yup'

import { WorkflowsContext } from '@context'
import { EditDrawerFooter } from '../../edit-drawer/edit-drawer-footer'
import { FormattedNode } from '../../format-nodes'
import { Loading } from '@components'
import { endpoints } from '@api'

type Props = {
  onSubmit: (node: FormattedNode) => void
  closeDrawer: () => void
  onDelete: (node: FormattedNode | undefined) => void
}

type CheckInModule = {
  id: string
  title: string
  type: string
}

const schema = Yup.object().shape({
  worksheetIds: Yup.array()
    .of(Yup.string().test(str => Boolean(str)))
    .min(1)
    .required()
})

export const AssignWorksheet = ({ onSubmit, closeDrawer, onDelete }: Props) => {
  const { selectedNode } = useContext(WorkflowsContext)
  const config = selectedNode?.config

  const {
    data: allCheckInModules,
    isLoading
  }: {
    data: CheckInModule[] | undefined
    isLoading: boolean
  } = useQuery([endpoints.getClinicianCheckInModules.getCacheId()], () =>
    // @ts-ignore
    endpoints.getClinicianCheckInModules.request()
  )

  if (isLoading) return <Loading />

  const initialValues = {
    worksheetIds: config?.worksheets?.map((w: CheckInModule) => w.id) || ['']
  }

  const allWorksheets = allCheckInModules?.filter(
    c => c.type === 'treatment_activity'
  )

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={values =>
          onSubmit({
            ...selectedNode,
            config: { worksheetIds: values.worksheetIds }
          } as FormattedNode)
        }
      >
        {({ values, errors }) => (
          <Form>
            <FieldArray
              name="worksheetIds"
              render={({ push, remove, replace }) => (
                <Box>
                  {!Boolean(values.worksheetIds.length) && (
                    <Field name="worksheetIds.0">
                      {({ field }: { field: { value: string } }) => (
                        <Select
                          {...field}
                          value={field.value}
                          borderColor={
                            errors?.worksheetIds ? 'error' : 'light_gray'
                          }
                          color={field.value ? 'black' : 'gray'}
                          mt="xsmall"
                        >
                          <option value="" disabled>
                            Choose Worksheet
                          </option>
                          {allWorksheets?.map((worksheet: CheckInModule) => (
                            <option
                              key={worksheet.id}
                              value={worksheet.id}
                              disabled={values.worksheetIds.includes(
                                worksheet.id
                              )}
                            >
                              {worksheet.title}
                            </option>
                          ))}
                        </Select>
                      )}
                    </Field>
                  )}
                  {values?.worksheetIds?.map(
                    (worksheetId: string, index: number) => (
                      <Flex>
                        <Field
                          name={`worksheetIds.${index}`}
                          key={`worksheetIds.${index}`}
                        >
                          {({ field }: { field: { value: string } }) => (
                            <Select
                              {...field}
                              borderColor={
                                errors?.worksheetIds && !worksheetId
                                  ? 'error'
                                  : 'light_gray'
                              }
                              color={field.value ? 'black' : 'gray'}
                              mb="xsmall"
                            >
                              <option value="" disabled>
                                Choose Worksheet
                              </option>
                              {allWorksheets?.map(
                                (worksheet: CheckInModule) => (
                                  <option
                                    key={worksheet.id}
                                    value={worksheet.id}
                                    disabled={values.worksheetIds.includes(worksheet.id)}
                                  >
                                    {worksheet.title}
                                  </option>
                                )
                              )}
                            </Select>
                          )}
                        </Field>
                        {worksheetId && (
                          <CloseButton
                            ml="xsmall"
                            onClick={() => {
                              if (values.worksheetIds.length === 1) {
                                replace(0, '')
                              } else {
                                remove(index)
                              }
                            }}
                          />
                        )}
                      </Flex>
                    )
                  )}
                  {Boolean(values?.worksheetIds[0]) && (
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      _hover={{ cursor: 'pointer' }}
                      onClick={() => push('')}
                      mt="xsmall"
                    >
                      <AddCircleIcon fill="primary" mr="xsmall" />
                      <Text color="primary">Add Worksheet</Text>
                    </Box>
                  )}
                </Box>
              )}
            />
            <EditDrawerFooter
              onDelete={() => onDelete(selectedNode)}
              onCancel={closeDrawer}
            />
          </Form>
        )}
      </Formik>
    </Box>
  )
}
