import React from 'react'

const CirclePlus = props => {
  return (
    <svg
      fill={props.fill || '#2F56DF'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="22px"
      height="22px"
    >
      <g
        id="circle-plus"
        stroke={props.fill || '#2F56DF'}
        strokeWidth=".5"
        fill={props.fill || '#2F56DF'}
      >
        <path d="M 7.5 1 C 3.917969 1 1 3.917969 1 7.5 C 1 11.082031 3.917969 14 7.5 14 C 11.082031 14 14 11.082031 14 7.5 C 14 3.917969 11.082031 1 7.5 1 Z M 7.5 2 C 10.542969 2 13 4.457031 13 7.5 C 13 10.542969 10.542969 13 7.5 13 C 4.457031 13 2 10.542969 2 7.5 C 2 4.457031 4.457031 2 7.5 2 Z M 7 4 L 7 7 L 4 7 L 4 8 L 7 8 L 7 11 L 8 11 L 8 8 L 11 8 L 11 7 L 8 7 L 8 4 Z" />
      </g>
    </svg>
  )
}

export default CirclePlus
