import { Flex, HStack, useRadio, useRadioGroup } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

const PillTabs = ({ tabs, onChange, selected, ...rest }) => {
  const memoizedUseRadioGroup = useCallback(
    defaultValue =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useRadioGroup({
        defaultValue,
        onChange
      }),
    [onChange]
  )

  const { getRootProps, getRadioProps } = memoizedUseRadioGroup(selected)
  const group = getRootProps()

  return (
    <HStack {...group} mb="medium">
      {tabs.map(tab => {
        const radio = getRadioProps(tab)
        return (
          <Tab key={tab.value} rest={rest} {...radio}>
            {tab.display}
          </Tab>
        )
      })}
    </HStack>
  )
}

PillTabs.propTypes = {
  // Array of tabs.
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired
    })
  ).isRequired,
  // Callback to set selected tab.
  onChange: PropTypes.func.isRequired,
  // Selected tab.
  selected: PropTypes.string
}

const Tab = props => {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Flex as="label" flexGrow={0} flexShrink={0} flexBasis="auto">
      <input {...input} />
      <Flex
        {...checkbox}
        height="32px"
        cursor="pointer"
        color="#2D54E8"
        p="8px 16px"
        bg="#FFFFFF"
        borderRadius="16px"
        fontWeight="bold"
        fontSize="xsm"
        _hover={{
          bg: `#28282808`
        }}
        _checked={{
          bg: '#2D54E8',
          color: 'white'
        }}
        justifyContent="center"
        alignItems="center"
        textTransform="capitalize"
        {...props.rest}
      >
        {props.children}
      </Flex>
    </Flex>
  )
}

export default PillTabs
