const treatmentSettingOptions = [
  { label: 'Outpatient', value: 'outpatient' },
  { label: 'Inpatient', value: 'inpatient' },
  { label: 'Residential', value: 'residential' },
  { label: 'Intensive outpatient', value: 'intensive_outpatient' },
  {
    label: 'Partial hospitalization program',
    value: 'partial_hospitalization_program'
  }
]

export default treatmentSettingOptions
