import React from 'react'
import { Box, useToast, UseToastOptions } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { endpoints } from '@api'

type RouteParams = {
  workflowId: string
  organizationId: string
}

type Props = {
  closeDrawer: () => void
  children: React.ReactElement
}

const toastContent: UseToastOptions = {
  description: 'Workflow updated!',
  status: 'success',
  isClosable: true,
  duration: 2000
}

export const TriggerDrawer = ({ closeDrawer, children }: Props) => {
  const { organizationId, workflowId } = useParams<RouteParams>()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutate: update }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.upsertOrganizationWorkflowTrigger.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getOrganizationWorkflow.getCacheId()
        )
        toast(toastContent)
        closeDrawer()
      }
    }
  )

  const upsertWorkflowTrigger = (trigger: {
    workflowId: string
    triggerType: string
    config: {
      assessmentId: string
      filter?: {
        type: string
        questionKey?: string
        triggerAnswers?: number[]
        totalScoreOperator?: string
        totalScoreValues?: number[]
      }
    }
  }) => {
    return update({ organizationId, workflowId: workflowId, data: trigger })
  }

  return (
    <Box>
      {React.cloneElement(children, {
        onSubmit: upsertWorkflowTrigger,
        closeDrawer
      })}
    </Box>
  )
}
