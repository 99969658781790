import React from 'react'

const Sociicertified = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M24.8327 10.6666H23.4993V7.99992C23.4993 4.31992 20.5127 1.33325 16.8327 1.33325C13.1527 1.33325 10.166 4.31992 10.166 7.99992V10.6666H8.83268C7.36602 10.6666 6.16602 11.8666 6.16602 13.3333V26.6666C6.16602 28.1333 7.36602 29.3333 8.83268 29.3333H24.8327C26.2993 29.3333 27.4993 28.1333 27.4993 26.6666V13.3333C27.4993 11.8666 26.2993 10.6666 24.8327 10.6666ZM16.8327 22.6666C15.366 22.6666 14.166 21.4666 14.166 19.9999C14.166 18.5333 15.366 17.3333 16.8327 17.3333C18.2993 17.3333 19.4993 18.5333 19.4993 19.9999C19.4993 21.4666 18.2993 22.6666 16.8327 22.6666ZM20.966 10.6666H12.6993V7.99992C12.6993 5.71992 14.5527 3.86659 16.8327 3.86659C19.1127 3.86659 20.966 5.71992 20.966 7.99992V10.6666Z"
      fill="#282828"
    />
  </svg>
)

export default Sociicertified
