import { action } from 'easy-peasy'

export const workflowsModel = {
  workflows: {
    selectedOrganizationId: null,
    setSelectedOrganizationId: action((state, value) => {
      state.selectedOrganizationId = value
    })
  }
}
