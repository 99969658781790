import React from 'react'
import { Box, useToast, UseToastOptions } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { WorkflowsContext } from '@context'
import { endpoints } from '@api'
import { FormattedNode } from '../format-nodes'

type RouteParams = {
  workflowId: string
  organizationId: string
}

type Props = {
  closeDrawer: () => void
  children: React.ReactElement
}

const toastContent: UseToastOptions = {
  description: 'Workflow updated!',
  status: 'success',
  isClosable: true,
  duration: 2000
}

export const ActionDrawer = ({ closeDrawer, children }: Props) => {
  const { setSelectedNode } = React.useContext(WorkflowsContext)
  const { organizationId, workflowId } = useParams<RouteParams>()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutate: create }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.createOrUpdateWorkflowAction.request,
    {
      onSuccess: () => {
        setSelectedNode()
        queryClient.invalidateQueries(
          endpoints.getOrganizationWorkflow.getCacheId()
        )
        toast(toastContent)
        closeDrawer()
      }
    }
  )

  const { mutate: remove }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.deleteWorkflowAction.request,
    {
      onSuccess: () => {
        setSelectedNode()
        queryClient.invalidateQueries(
          endpoints.getOrganizationWorkflow.getCacheId()
        )
        toast(toastContent)
        closeDrawer()
      }
    }
  )

  const updateOrCreateAction = (node: FormattedNode | undefined) => {
    return create({ organizationId, workflowId: workflowId, data: node })
  }

  const deleteAction = (node: FormattedNode | undefined) => {
    return remove({ organizationId, workflowId, actionId: node?.id })
  }

  return (
    <Box>
      {React.cloneElement(children, {
        onSubmit: updateOrCreateAction,
        onDelete: deleteAction,
        closeDrawer
      })}
    </Box>
  )
}
