import React, { useCallback } from 'react'
import { Flex, Text, VStack, Box, Divider } from '@chakra-ui/react'
import {
  IconButton,
  AddCircleIcon,
  CheckCircle,
  StarCheck
} from '@blueprinthq/joy'

interface InterventionListProps {
  clientId: string
  interventionList: any[] | undefined
  idOrInterventionId: 'id' | 'interventionId'
  newSelectedInterventionIds: string[]
  setNewSelectedInterventionIds: Function
  selectedInterventionIds: string[]
  title: string
}

export const InterventionList = ({
  clientId,
  interventionList,
  idOrInterventionId,
  newSelectedInterventionIds,
  setNewSelectedInterventionIds,
  selectedInterventionIds,
  title
}: InterventionListProps) => {
  const selectNewIntervention = useCallback(
    (interventionId: string, interventionRecommendationId?: string) => {
      setNewSelectedInterventionIds([
        ...newSelectedInterventionIds,
        interventionId
      ])
    },
    [clientId, newSelectedInterventionIds, setNewSelectedInterventionIds]
  )

  const unselectNewIntervention = useCallback(
    (interventionId: string) => {
      setNewSelectedInterventionIds(
        newSelectedInterventionIds.filter(id => id !== interventionId)
      )
    },
    [newSelectedInterventionIds, setNewSelectedInterventionIds]
  )

  if (!Boolean(interventionList?.length)) return null

  return (
    <>
      <Box
        p="4px 24px"
        bg="pale_gray"
        width="calc(100% + 3rem)"
        my="xsmall"
        transform="translateX(-1.5rem)"
      >
        <Text fontWeight="bold" textTransform="uppercase">
          {title}
        </Text>
      </Box>
      <VStack divider={<Divider color="pale_gray" />}>
        {interventionList?.map((intervention: any) => (
          <Flex justify="space-between" key={intervention.id} w="100%">
            <Flex align="center">
              <Box mr="small" width="24px">
                {intervention.is_guided && (
                  <StarCheck mt="xxsmall" fill="primary" />
                )}
              </Box>
              <Box>
                <Text>{intervention.name}</Text>
                <Text color="dark_gray">{intervention.short_description}</Text>
                <Box
                  p="2px 4px"
                  bg="pale_gray"
                  width="fit-content"
                  borderRadius="3px"
                >
                  <Text fontSize="12px" textTransform="uppercase">
                    {intervention.treatment_modality}
                  </Text>
                </Box>
              </Box>
            </Flex>

            <Flex align="center">
              {// @ts-ignore
              !newSelectedInterventionIds.includes(
                intervention[idOrInterventionId]
              ) &&
                !selectedInterventionIds.includes(
                  intervention[idOrInterventionId]
                ) && (
                  <IconButton
                    aria-label="add"
                    variant="ghost"
                    icon={<AddCircleIcon />}
                    onClick={() =>
                      selectNewIntervention(
                        intervention[idOrInterventionId],
                        intervention.id
                      )
                    }
                  />
                )}
              {// @ts-ignore
              newSelectedInterventionIds.includes(
                intervention[idOrInterventionId]
              ) &&
                !selectedInterventionIds.includes(
                  intervention[idOrInterventionId]
                ) && (
                  <IconButton
                    aria-label="add"
                    variant="ghost"
                    icon={<CheckCircle fill="primary" />}
                    onClick={() =>
                      unselectNewIntervention(intervention[idOrInterventionId])
                    }
                  />
                )}
              {// @ts-ignore
              !newSelectedInterventionIds.includes(
                intervention[idOrInterventionId]
              ) &&
                selectedInterventionIds.includes(
                  intervention[idOrInterventionId]
                ) && (
                  <IconButton
                    disabled
                    aria-label="add"
                    variant="ghost"
                    icon={<CheckCircle fill="dark_gray" />}
                    onClick={() =>
                      unselectNewIntervention(intervention[idOrInterventionId])
                    }
                  />
                )}
            </Flex>
          </Flex>
        ))}
      </VStack>
    </>
  )
}
