import React from 'react'
import { Container, Flex, Skeleton } from '@chakra-ui/react'

export const SessionPrepLoading = () => (
  <Flex flexDirection="column" gap="16px">
    <Skeleton height="20px" width="200px" />
    <Skeleton height="20px" />
    <Skeleton height="20px" />
  </Flex>
)

const Loading = () => (
  <Container>
    <Flex
      flexDirection="column"
      gap="16px"
      py="16px"
      flex="1"
      h="calc(100vh - 208px)"
    >
      <SessionPrepLoading />
      <SessionPrepLoading />
      <SessionPrepLoading />
    </Flex>
  </Container>
)

export default Loading
