import React from 'react'
import { Text, Flex, Button, Link } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { useStoreState } from 'easy-peasy'
import { InterventionIcon } from '@icons'
import { endpoints } from '@api'
import { StoreModel } from 'src/store/types'

type InterventionRecommendation = {
  id: string
  name: string
  interventionId: string
  addedAt: Date
}

export const InterventionRecommendations = ({
  clientId,
  sessionId,
  startInterventions
}: {
  clientId: string
  sessionId: string
  startInterventions: Function
}) => {
  const location = useLocation()
  const patientUrl = `/patient/${clientId}`
  const queryClient = useQueryClient()
  const { selectedInterventionIds } = useStoreState(
    (state: StoreModel) => state.modals.interventionList
  )

  const selectNewIntervention = (ir: any) => {
    const newSelectedInterventions = [
      ...selectedInterventionIds,
      ir.interventionId
    ]
    startInterventions({
      id: clientId,
      data: {
        interventionIds: newSelectedInterventions,
        sessionId
      }
    })
    selectInterventionRecommendation({
      clientId,
      interventionRecommendationId: ir.id
    })
  }

  const {
    data: interventionRecommendations
  }: { data: InterventionRecommendation[] | undefined } = useQuery(
    [endpoints.getInterventionRecommendations.getCacheId(), clientId],
    // @ts-ignore
    () => endpoints.getInterventionRecommendations.request({ clientId })
  )

  const {
    mutate: selectInterventionRecommendation
  }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.selectInterventionRecommendation.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getInterventionRecommendations.getCacheId()
        )
      }
    }
  )

  if (!Boolean(interventionRecommendations?.length)) return null

  return (
    <>
      <Text fontWeight="bold" mb="small" mt="small">
        Recommended
      </Text>

      <div>
        {interventionRecommendations
          ?.filter(ir => !ir.addedAt)
          .map((ir: InterventionRecommendation) => (
            <Flex
              key={`intervention-recommendation-${ir.id}`}
              justify="space-between"
              p={5}
              border="1px dashed #e4e5e6"
              borderTop="none"
              _first={{
                borderTop: '1px dashed #e4e5e6',
                borderTopRadius: '10px'
              }}
              _last={{
                borderBottom: '1px dashed #e4e5e6',
                borderBottomRadius: '10px'
              }}
            >
              <Flex alignItems="center">
                <InterventionIcon />
                <Text ml="small">{ir.name}</Text>
              </Flex>
              <Flex alignItems="center">
                <Link
                  target="_blank"
                  as={RouterLink}
                  color="primary"
                  mr="small"
                  to={{
                    pathname: `${patientUrl}/interventions/${ir.interventionId}/summary`,
                    state: { prevLocation: location.pathname }
                  }}
                >
                  Learn More
                </Link>
                <Button onClick={() => selectNewIntervention(ir)}>Add</Button>
              </Flex>
            </Flex>
          ))}
      </div>
    </>
  )
}
