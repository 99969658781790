import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'

enum RecipientType {
  PRIMARY_CLINICIAN = 'primary-clinician',
  ALL_CLINICIANS = 'all-clinicians',
  OTHER_EMAIL = 'other-email'
}

export const SendEmail = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const { recipientType, email } = config
  const template = config?.template

  const templateText = template === 'safety-net'
    ? 'A client safety net has been triggered on Blueprint'
    : 'A custom email'

  const recipientText = () => {
    switch (recipientType) {
      case RecipientType.PRIMARY_CLINICIAN:
        return 'Primary Clinician'
      case RecipientType.ALL_CLINICIANS:
        return 'All Assigned Clinicians'
      case RecipientType.OTHER_EMAIL:
        return email
    }
  }

  const body = (
    <Text>
      Send <Text as="b">{templateText}</Text> to{' '}
      <Text as="b">{recipientText()}</Text>
    </Text>
  )

  return (
    <WorkflowNode
      heading="Send email Action"
      body={body}
      hasChildren={hasChildren}
      isParent={isParent}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
