import React, { useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box
} from '@chakra-ui/react'

export const LearnMoreModal = ({
  isOpen,
  handleCloseModal
}: {
  isOpen: boolean
  handleCloseModal: () => void
}) => {
  const _handleCloseModal = useCallback(() => {
    handleCloseModal()
  }, [handleCloseModal])

  return (
    <Modal
      size="2xl"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={_handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textTransform="capitalize" fontSize="24px">
          Blueprint Kiosk Experience
        </ModalHeader>
        <ModalCloseButton mr="xsmall" mt="10px" borderRadius="25px" />
        <ModalBody py="0" overflowX="hidden">
          Allow your clients to use Blueprint from a shared tablet in a waiting
          room or on their own devices (e.g. via a QR code) using the Blueprint
          Kiosk Experience. Just load your provided URL on any device and
          clients simply enter their name and date of birth to access their
          personalized assessments.
          <Box mt={10} mx={20}>
            <img
              src="https://res.cloudinary.com/hellojoy/image/upload/v1698686613/tablet_wlsurg.png"
              alt="Demo of Kiosk URL on iPad"
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
