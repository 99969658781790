import React from 'react'
import { Select, HStack } from '@chakra-ui/react'
import { Field, FormikErrors, useFormikContext } from 'formik'
import _ from 'lodash'
import { Assessment } from '../assessment-submitted-trigger'

type Props = {
  assessment: Assessment
  resetForm: (values: object) => void
  constants: any
}

type FormikContext = {
  values: {
    assessmentId: string | undefined
    pointChangeValue: string | undefined
    pointChangeOperator: string | undefined
    pointChangeReferenceScoreType: string | undefined
  }
  errors: FormikErrors<any>
}

export const PointChangeFilter = ({
  assessment,
  resetForm,
  constants
}: Props) => {
  const { values, errors }: FormikContext = useFormikContext()
  const options = () => {
    const totalScore = assessment?.totalScore || 0

    const valueArray = _.range(1, totalScore + 1)

    return valueArray.map(v => ({
      display: `${v} pt`,
      value: `${v}`
    }))
  }

  return (
    <HStack spacing="small">
      <Field name="pointChangeValue">
        {({ field }: { field: { value: string } }) => (
          <Select
            {...field}
            value={field.value}
            w="100px"
            borderColor={errors?.pointChangeValue ? 'error' : 'light_gray'}
            color={field.value ? 'black' : 'gray'}
            onChange={e => {
              resetForm({
                ...values,
                pointChangeValue: e.target.value
              })
            }}
          >
            <option value="" disabled>
              Value
            </option>
            {options().map((o: { display: string; value: string }) => (
              <option key={o.value} value={o.value}>
                {o.display}
              </option>
            ))}
          </Select>
        )}
      </Field>
      <Field name="pointChangeOperator">
        {({ field }: { field: { value: string } }) => (
          <Select
            {...field}
            value={field.value}
            w="175px"
            borderColor={errors?.pointChangeOperator ? 'error' : 'light_gray'}
            color={field.value ? 'black' : 'gray'}
            onChange={e => {
              resetForm({
                ...values,
                pointChangeOperator: e.target.value
              })
            }}
          >
            <option value="" disabled>
              Operator
            </option>
            {constants.operators.map(
              (o: { display: string; value: string }) => (
                <option key={o.value} value={o.value}>
                  {o.display}
                </option>
              )
            )}
          </Select>
        )}
      </Field>
      <Field name="pointChangeReferenceScoreType">
        {({ field }: { field: { value: string } }) => (
          <Select
            {...field}
            value={field.value}
            w="175px"
            borderColor={
              errors?.pointChangeReferenceScoreType ? 'error' : 'light_gray'
            }
            color={field.value ? 'black' : 'gray'}
            onChange={e => {
              resetForm({
                ...values,
                pointChangeReferenceScoreType: e.target.value
              })
            }}
          >
            <option value="" disabled>
              Reference score
            </option>
            {constants.referenceScoreTypes.map(
              (o: { display: string; value: string }) => (
                <option key={o.value} value={o.value}>
                  {o.display}
                </option>
              )
            )}
          </Select>
        )}
      </Field>
    </HStack>
  )
}
