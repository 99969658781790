import React, { useRef } from 'react'
import {
  Button,
  Box,
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useBreakpointValue
} from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'

export const AlertDialog = ({
  isOpen,
  onClose,
  onConfirm,
  isConfirming,
  header,
  text,
  confirmText
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isConfirming: boolean
  header: string
  text: React.ReactNode
  confirmText: string
}) => {
  const { isExtension, isWidget } = useExperienceManager()
  const size = useBreakpointValue({
    base: 'full',
    sm: 'md'
  })
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <ChakraAlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={isExtension || isWidget ? 'full' : size}
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={6} borderRadius={size === 'full' ? '0px' : '4px'}>
        <AlertDialogHeader fontSize="24px" p={0} mb={6}>
          {header}
        </AlertDialogHeader>
        <AlertDialogCloseButton position="absolute" top="18px" />
        <AlertDialogBody p={0}>
          <Box>{text}</Box>
        </AlertDialogBody>
        <AlertDialogFooter p={0} mt={4}>
          <Button
            variant="outline"
            isFullWidth
            size="lg"
            onClick={onClose}
            ref={cancelRef}
            _focus={{
              outline: 'none'
            }}
          >
            Cancel
          </Button>
          <Button
            isFullWidth
            size="lg"
            onClick={onConfirm}
            _focus={{
              outline: 'none'
            }}
            isLoading={isConfirming}
          >
            {confirmText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </ChakraAlertDialog>
  )
}
