import React, { useRef } from 'react'
import {
  Flex,
  Text,
  Button,
  Box,
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useBreakpointValue
} from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import Warning from '@material-ui/icons/Warning'

export const DeleteConfirmation = ({
  isOpen,
  onClose,
  onConfirm,
  isConfirming,
  header,
  text,
  confirmText
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isConfirming: boolean
  header: string
  text: string
  confirmText: string
}) => {
  const { isExtension } = useExperienceManager()
  const size = useBreakpointValue({
    base: 'full',
    sm: 'md'
  })
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={isExtension ? 'full' : size}
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={6} borderRadius={size === 'full' ? '0px' : '4px'}>
        <AlertDialogHeader fontSize="24px" p={0} mb={6}>
          {header}
        </AlertDialogHeader>
        <AlertDialogCloseButton position="absolute" top="18px" />
        <AlertDialogBody p={0}>
          <Box> {text}</Box>
          <Alert bg="#FFF9E5" borderRadius="8px" p={4} mt={4}>
            <Flex alignItems="start">
              <Box display="flex" alignItems="center" mr={2}>
                <Warning style={{ color: '#F8D557' }} />
              </Box>
              <Box>
                <Text>
                  <Text as="span" fontWeight="bold">
                    Warning:
                  </Text>
                  <Text as="span">{` This action cannot be undone.`}</Text>
                </Text>
              </Box>
            </Flex>
          </Alert>
        </AlertDialogBody>
        <AlertDialogFooter p={0} mt={4}>
          <Button
            variant="outline"
            isFullWidth
            size="lg"
            onClick={onClose}
            ref={cancelRef}
            _focus={{
              outline: 'none'
            }}
          >
            Cancel
          </Button>
          <Button
            bg="error"
            isFullWidth
            size="lg"
            onClick={onConfirm}
            _focus={{
              outline: 'none'
            }}
            isLoading={isConfirming}
          >
            {confirmText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
