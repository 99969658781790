import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast
} from '@chakra-ui/react'
import { Card } from '../components/card'
import { standardErrorDescription } from '../../../../constants/Copy'

export default function PaymentMethodModal({
  isModalOpen,
  handleModal,
  selectedClinic,
  invoices
}) {
  function filterAndCalculate(invoices) {
    // Filter for open (unpaid) invoices and failed payment (void) invoices
    const filteredInvoices = invoices.filter(
      invoice =>
        invoice.invoice.status === 'open' &&
        invoice.invoice.attempted &&
        invoice.invoice.payment_intent.status === 'requires_payment_method'
    )

    // Sum the amount_due property of each invoice
    const totalAmount = filteredInvoices.reduce((sum, invoice) => {
      return sum + invoice.invoice.amount_due
    }, 0)

    // Convert the total amount to dollars (assuming amount_due is in cents)
    const totalAmountInDollars = totalAmount / 100

    return {
      filteredInvoices,
      totalAmountInDollars
    }
  }
  const result = filterAndCalculate(invoices)

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const toast = useToast()
  const queryClient = useQueryClient()
  const handleFormSubmit = async card => {
    const data = {
      clinicId: selectedClinic.id,
      paymentMethodId: card
    }
    await changeBilling({
      data
    })
  }

  const { mutateAsync: changeBilling, err } = useMutation(
    endpoints.updateClinicBilling.request,
    {
      async onSuccess() {
        if (result.totalAmountInDollars === 0) {
          queryClient.invalidateQueries([
            endpoints.getClinicBilling.getCacheId(),
            selectedClinic && selectedClinic.id
          ])
          toast({
            title: 'You have successfully updated your default payment method.',
            status: 'success',
            duration: 3000,
            isClosable: true
          })
          handleModal()
        } else {
          const data = {
            invoices: result.filteredInvoices
          }
          await retryInvoice({
            data
          })
        }
      },
      onError() {
        toast({
          title: 'Oops, something went wrong.',
          description: standardErrorDescription(),
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  const { mutateAsync: retryInvoice, err: errors } = useMutation(
    endpoints.postRetryInvoicePayments.request,
    {
      onSuccess() {
        queryClient.invalidateQueries([
          endpoints.getClinicBilling.getCacheId(),
          selectedClinic && selectedClinic.id
        ])
        handleModal()
      },
      onError() {
        toast({
          title: 'Oops, something went wrong.',
          description: standardErrorDescription(),
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  return (
    <>
      <Modal
        onClose={handleModal}
        size={'md'}
        isOpen={isModalOpen}
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'24px'}>
            Enter credit or debit card
          </ModalHeader>
          <ModalCloseButton
            h={'24px'}
            w={'24px'}
            top={'18px'}
            right={'18px'}
            fontWeight={'bold'}
          />
          <ModalBody pb={'16px'}>
            <Card
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              upsertSubscription={handleFormSubmit}
              amount={result.totalAmountInDollars}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
