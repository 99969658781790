import React from 'react'
import { Flex } from '@chakra-ui/react'
import { TrashIcon } from '@icons'
import { useTreatmentPlan } from '@hooks'
import { Button } from '../Button'
import Toolbar from '../Toolbar'

const Actions: React.FC = () => {
  const { openDeleteConfirmationModal } = useTreatmentPlan()

  return (
    <Toolbar>
      <Flex justifyContent="flex-end" gap="16px" flex="1">
        <Button
          variant="outline"
          onClick={openDeleteConfirmationModal}
          leftIcon={<TrashIcon fill="#EB5164" />}
        >
          Delete
        </Button>
      </Flex>
    </Toolbar>
  )
}

export default Actions
