import {
  genericColumn,
  clinicianColumn,
  dateOfBirthColumn,
  phoneColumn,
  timestampColumn,
  timeSinceColumn,
  actionColumn,
  clientPageLinkColumn,
  activeClientIconColumns,
  assignClinicianColumn,
  nameColumn
} from './column-types'
import { ClientListActions } from '../../constants/clientListActions'

export const pendingColumns = columnProps => {
  const { openAssignClinicianModal, onRowClick } = columnProps

  return [
    genericColumn('source', 'source', { disableSortBy: true }),
    nameColumn(),
    dateOfBirthColumn(),
    phoneColumn(),
    genericColumn('email', 'email', { disableSortBy: true }),
    assignClinicianColumn(openAssignClinicianModal),
    timestampColumn({ label: 'invite date', accessor: 'date_of_invite' }),
    timestampColumn({ label: 'Intake Date', accessor: 'created_at' }),
    actionColumn([ClientListActions.FINISH_ENROLLING]),
    clientPageLinkColumn(onRowClick)
  ]
}

export const awaitingInviteColumns = columnProps => {
  const { onRowClick } = columnProps

  return [
    nameColumn(),
    dateOfBirthColumn(),
    phoneColumn(),
    genericColumn('email', 'email', { disableSortBy: true }),
    clinicianColumn(),
    timestampColumn({ label: 'invite date', accessor: 'date_of_invite' }),
    clientPageLinkColumn(onRowClick)
  ]
}

// same as awaiting invite, worth keeping explicit despite being identical?
export const inviteSentColumns = columnProps => {
  const { sendInvite, onRowClick } = columnProps

  return [
    nameColumn(),
    dateOfBirthColumn(),
    phoneColumn(),
    genericColumn('email', 'email', { disableSortBy: true }),
    clinicianColumn(),
    timestampColumn({ label: 'invite date', accessor: 'date_of_invite' }),
    actionColumn([ClientListActions.REINVITE_TO_DOWNLOAD], { sendInvite }),
    clientPageLinkColumn(onRowClick)
  ]
}

export const activeColumns = columnProps => {
  const { onRowClick } = columnProps

  return [
    ...activeClientIconColumns(),
    nameColumn(),
    clinicianColumn(),
    timeSinceColumn({
      label: 'last assessment',
      accessor: 'assessment_date',
      disableSortBy: false
    }),
    timeSinceColumn({ label: 'last check-in', accessor: 'check_in_date' }),
    timestampColumn({
      label: 'next assessment',
      accessor: 'next_administration_date',
      disableSortBy: true
    }),
    actionColumn([ClientListActions.VIEW_SCREENER_RESULTS]),
    clientPageLinkColumn(onRowClick)
  ]
}

export const dischargedColumns = columnProps => {
  const { onRowClick } = columnProps

  return [
    nameColumn(),
    timestampColumn({ label: 'last assessment', accessor: 'assessment_date' }),
    timestampColumn({ label: 'last check-in', accessor: 'check_in_date' }),
    timestampColumn({ label: 'discharge date', accessor: 'archived_date' }),
    genericColumn('discharge reason', 'archived_reason', {
      disableSortBy: true
    }),
    clientPageLinkColumn(onRowClick)
  ]
}
