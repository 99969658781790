import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'

export const ShowAssessmentPrompt = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const {
    prompt: { title: prompt }
  } = config

  const body = (
    <Text>
      Show <Text as="b">{prompt}</Text> to client
    </Text>
  )

  return (
    <WorkflowNode
      heading="Show assessment prompt"
      body={body}
      hasChildren={hasChildren}
      isParent={isParent}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
