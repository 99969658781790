import React, { useState, useCallback, useMemo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import {
  Button,
  GoBackwardIcon,
  ZoomInIcon,
  ZoomOutIcon,
  IconButton
} from '@blueprinthq/joy'
import Cropper from 'react-easy-crop'

import { getCroppedImg } from './utils'

const IMAGE_HEIGHT = 500
const IMAGE_WIDTH = 500
const DEFAULT_CROP = { x: 0, y: 0 }
const DEFAULT_ZOOM = 1.2

const ImageCropper = ({ ...rest }) => {
  return (
    <Box position="relative" height={IMAGE_HEIGHT} width={IMAGE_WIDTH}>
      <Cropper aspect={1} {...rest} zoomWithScroll={true} />
    </Box>
  )
}

export const BasicImageEditor = ({
  imageFile,
  isOpen,
  onClose,
  onApplyImage
}) => {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [crop, setCrop] = useState(DEFAULT_CROP)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const localImageSrc = useMemo(
    () => imageFile && window.URL.createObjectURL(imageFile),
    [imageFile]
  )

  const resetCropping = () => {
    setZoom(DEFAULT_ZOOM)
    setCroppedAreaPixels(null)
    setCrop(DEFAULT_CROP)
  }

  const _onApplyImage = useCallback(async () => {
    const croppedImage = await getCroppedImg(localImageSrc, croppedAreaPixels)
    onApplyImage(croppedImage)
    resetCropping()
  }, [croppedAreaPixels, imageFile, onApplyImage])

  const _onClose = useCallback(() => {
    resetCropping()
    onClose()
  }, [onClose])

  const modalSize = useBreakpointValue({
    base: 'full',
    md: '2xl'
  })

  return (
    <>
      <Modal isOpen={isOpen} onClose={_onClose} size={modalSize}>
        <ModalOverlay />
        <ModalContent maxWidth={IMAGE_WIDTH}>
          <ModalHeader>
            <SimpleGrid columns={3}>
              <Flex align="center">
                <IconButton
                  icon={<GoBackwardIcon />}
                  variant="ghost"
                  onClick={_onClose}
                />
              </Flex>
              <Flex justify="center" align="center">
                <Text fontSize="lg">Edit Photo</Text>
              </Flex>
              <Flex justify="flex-end" align="center">
                <Button size="md" width="90px" onClick={_onApplyImage}>
                  Apply
                </Button>
              </Flex>
            </SimpleGrid>
          </ModalHeader>
          <ModalBody padding={0} paddingInline={0}>
            <Flex justify="center">
              <ImageCropper
                image={localImageSrc}
                crop={crop}
                onCropChange={setCrop}
                zoom={zoom}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex width="100%" justify="space-between">
              <Box>
                <ZoomOutIcon />
              </Box>
              <Box width="80%">
                <Slider
                  colorScheme="blue"
                  defaultValue={1}
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.05}
                  onChange={v => setZoom(v)}
                  onChangeEnd={v => setZoom(v)}
                >
                  <SliderTrack>
                    <Box position="relative" right={10} />
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={6} bg="primary" />
                </Slider>
              </Box>
              <Box>
                <ZoomInIcon />
              </Box>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
