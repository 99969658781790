import React from 'react'
import { NavBar as WidgetNavBar } from '../widget/nav-bar'
import { NavBar as ExtensionNavBar } from '../extension/nav-bar'
import { useExperienceManager } from '@hooks'

export const NavBar = ({
  hasStartedRecording,
  showBackButton
}: {
  hasStartedRecording?: boolean
  showBackButton?: boolean
}) => {
  const { isWidget } = useExperienceManager()

  if (isWidget) {
    return <WidgetNavBar showBackButton={showBackButton} />
  }

  return (
    <ExtensionNavBar
      hasStartedRecording={hasStartedRecording}
      showBackButton={showBackButton}
    />
  )
}
