import React from 'react'
import { Flex, Container } from '@chakra-ui/react'
import { useTreatmentPlan } from '@hooks'
import Actions from './actions'

const Body: React.FC<{}> = () => {
  const {
    treatmentPlan
  } = useTreatmentPlan()

  return (
    <>
      <Container h="calc(100vh - 306px)">
        <Flex flex="1" pt="16px">
          <iframe
            src={treatmentPlan.url}
            title="Treatment Plan"
            style={{ width: '100%', height: '100%' }}
          />
        </Flex>
      </Container>
      <Actions />
    </>
  )
}

export default Body
