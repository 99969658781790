import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, makeStyles } from '@material-ui/core'
import { Check } from '../icons'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
  base: {
    maxWidth: 335,
    border: '1px solid #cccccc',
    boxShadow: 'none',
    color: '#4a4a4a',
    height: 50,
    fontWeight: '400',

    '&:hover': {
      backgroundColor: theme.palette.secondary.hover
    }
  },
  round: {
    borderRadius: 25
  },
  success: {
    backgroundColor: theme.palette.success.main
  },
  small: {
    height: 36,
    fontSize: 13
  },
  grayText: {
    color: '#757575'
  },
  full: {
    width: '100%',
  }
}))

export default function SecondaryButton(props) {
  const classes = useStyles(props)
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={props.onClick}
      className={cx(classes.base, props.className, {
        [classes.round]: props.round,
        [classes.success]: props.success,
        [classes.disabled]: props.disabled,
        [classes.small]: props.small,
        [classes.grayText]: props.grayText,
        [classes.full]: props.fullWidth
      })}
      disabled={props.disabled || props.loading || props.success}
      type={props.type ? props.type : 'button'}
    >
      {props.loading ? (
        <CircularProgress color="inherit" size={20} />
      ) : props.success ? (
        <Check fill="#ffffff" />
      ) : (
        props.children
      )}
    </Button>
  )
}
