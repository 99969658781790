// @ts-nocheck

import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react'
import { useQuery } from 'react-query'
import _ from 'lodash'
import moment from 'moment'
import { endpoints } from '@api'
import { formatAssessmentAnswers } from '@utilities'

const AssessmentResultsContext = createContext()

export const AssessmentResultsProvider = ({
  children,
  client,
  preselectedAssessmentId = null,
  preselectedAssessmentScoreId = null,
  assessmentScoreIds = null
}) => {
  const clientId = client.id

  const { data: assessmentScores, isLoading } = useQuery(
    [
      endpoints.getPatientAssessmentScores.getCacheId(),
      clientId,
      assessmentScoreIds
    ],
    () =>
      endpoints.getPatientAssessmentScores.request({
        patientId: clientId,
        ids: assessmentScoreIds,
        includeFreeTextQuestions: true
      }),
    {
      enabled: clientId !== null,
      initialData: []
    }
  )

  const [state, setState] = useState({
    selectedAssessmentId: preselectedAssessmentId,
    selectedAssigneeUserId: null,
    selectedAssessmentScoreId: preselectedAssessmentScoreId
  })

  useEffect(() => {
    if (assessmentScores.length) {
      const firstAssessment = assessmentScores[0]
      const selectedAssessmentId = preselectedAssessmentId || firstAssessment.assessment_id
      const selectedAssessment = assessmentScores.find(
        as => as.assessment_id === selectedAssessmentId
      )

      const selectedScoreId = preselectedAssessmentScoreId || (selectedAssessment.scores.length && selectedAssessment.scores.at(-1).id)
      const selectedScore = selectedAssessment.scores.find(
        score => score.id === selectedScoreId
      )
      
      setState({
        selectedAssessmentId,
        selectedAssigneeUserId: selectedScore?.assigneeUser?.id || null,
        selectedAssessmentScoreId: selectedScoreId
      })
    }
  }, [assessmentScores, preselectedAssessmentId, preselectedAssessmentScoreId])

  const selectedAssessment = useMemo(() => {
    return assessmentScores.find(
      as => as.assessment_id === state.selectedAssessmentId
    )
  }, [assessmentScores, state.selectedAssessmentId])

  const selectedAssessmentScores = useMemo(() => {
    return selectedAssessment ? _.sortBy(selectedAssessment.scores, 'created_at').reverse() : []
  }, [selectedAssessment])

  const selectedAssessmentAssigneeScores = useMemo(() => {
    return selectedAssessmentScores.filter(
      score => score.assigneeUser.id === state.selectedAssigneeUserId
    )
  }, [selectedAssessmentScores, state.selectedAssigneeUserId])

  const selectedAssessmentScore = useMemo(() => {
    return selectedAssessmentScores.find(
      as => as.id === state.selectedAssessmentScoreId
    )
  }, [selectedAssessmentScores, state.selectedAssessmentScoreId])

  const assigneeUsers = useMemo(() => {
    const uniqUsers = _.uniqBy(
      selectedAssessmentScores.map(score => score.assigneeUser),
      'id'
    )

    return uniqUsers.map(user => ({
      userId: user.id,
      name: `${user.info.firstName} ${user.info.lastName}`
    }))
  }, [selectedAssessmentScores])

  const assessmentOptions = useMemo(() => {
    return assessmentScores.map(as => ({
      assessmentId: as.assessment_id,
      name: as.name
    }))
  }, [assessmentScores])

  const assessmentDateOptions = useMemo(() => {
    return selectedAssessmentAssigneeScores.map(as => ({
      assessmentScoreId: as.id,
      date: moment(as.created_at).format('ddd MM/DD/YY h:mm a')
    }))
  }, [selectedAssessmentAssigneeScores])

  const formattedAssessmentScoreAnswers = useMemo(() => {
    return selectedAssessmentScore
      ? formatAssessmentAnswers(
        selectedAssessmentScore.answers,
        selectedAssessment
      )
      : []
  }, [selectedAssessmentScore, selectedAssessment])

  const onSelectAssessment = useCallback(
    assessmentId => {
      const selectedAssessment = assessmentScores.find(
        as => as.assessment_id === assessmentId
      )
      const assigneeUserId = selectedAssessment.scores[0].assigneeUser.id
      setState({
        selectedAssessmentId: selectedAssessment.assessment_id,
        selectedAssigneeUserId: assigneeUserId,
        selectedAssessmentScoreId: selectedAssessment.scores
          .filter(s => s.assigneeUser.id === assigneeUserId)
          .at(-1).id
      })
    },
    [assessmentScores]
  )

  const onSelectAssignee = useCallback(
    assigneeUserId => {
      const selectedAssessmentScore = selectedAssessmentScores.find(
        as => as.assigneeUser.id === assigneeUserId
      )
      setState(state => ({
        ...state,
        selectedAssigneeUserId: assigneeUserId,
        selectedAssessmentScoreId: selectedAssessmentScore.id
      }))
    },
    [selectedAssessmentScores]
  )

  const onSelectScore = useCallback(assessmentScoreId => {
    setState(state => ({
      ...state,
      selectedAssessmentScoreId: assessmentScoreId
    }))
  }, [])

  return (
    <AssessmentResultsContext.Provider
      value={{
        state,
        isLoading,
        assessmentOptions,
        assigneeUsers,
        assessmentDateOptions,
        formattedAssessmentScoreAnswers,
        selectedAssessment,
        selectedAssessmentScore,
        onSelectAssessment,
        onSelectAssignee,
        onSelectScore
      }}
    >
      {children}
    </AssessmentResultsContext.Provider>
  )
}

export const useAssessmentResults = () => {
  const context = useContext(AssessmentResultsContext)
  if (!context) {
    throw new Error('useAssessmentResults must be used within an AssessmentProvider')
  }
  return context
}
