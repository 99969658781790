import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { Assessment } from '../drawers/triggers/assessment-submitted-trigger/assessment-submitted-trigger'

type QueryResult = {
  data: Assessment[] | undefined
  isLoading: boolean
}

export const useOrganizationAssessments = (
  organizationId: string
): QueryResult =>
  useQuery(
    [endpoints.getOrganizationAssessments.getCacheId(), organizationId],
    () =>
      // @ts-ignore
      endpoints.getOrganizationAssessments.request({ organizationId })
  )
