import React, { useCallback, useMemo } from 'react'
import { Box, VStack } from '@chakra-ui/react'

import { SelectableButton } from './components'

export const CptCodePage = ({ data, setData, billingOptions }) => {
  const onAddCptCode = useCallback(
    cptCode => {
      setData({
        ...data,
        cptCodes: [
          ...data.cptCodes,
          {
            code: cptCode,
            billableUnitsType: null
          }
        ]
      })
    },
    [data]
  )

  const onRemoveCptCode = useCallback(
    cptCode => {
      setData({
        ...data,
        cptCodes: data.cptCodes.filter(option => option.code !== cptCode)
      })
    },
    [data]
  )

  const options = useMemo(() => {
    const selectedCodes = data.cptCodes.map(codeObj => codeObj.code)
    return billingOptions.cptCodes.map(cptCode => ({
      code: cptCode,
      selected: selectedCodes.includes(cptCode)
    }))
  }, [billingOptions, data])

  return (
    <Box>
      <VStack>
        {options.map(option => (
          <SelectableButton
            selected={option.selected}
            onClick={() =>
              option.selected
                ? onRemoveCptCode(option.code)
                : onAddCptCode(option.code)
            }
            key={option.code}
          >
            {option.code}
          </SelectableButton>
        ))}
      </VStack>
    </Box>
  )
}
