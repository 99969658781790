import React, { Component } from 'react'
import cx from 'classnames'
import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import styles from './card-styles'

class Card extends Component {
  state = {
    expanded: false
  }

  handleClick = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render() {
    const {
      header,
      description,
      subheader,
      sublabel,
      Content,
      expandable,
      error,
      half,
      upperRight,
      hideShadow,
      classes
    } = this.props

    return (
      <Paper
        className={cx(classes.container, {
          [classes.half]: !!half,
          [classes.zeroPadding]: !!hideShadow
        })}
        elevation={hideShadow ? 0 : 1}
      >
        <div className={classes.header}>
          <h2>{header}</h2>
          <span
            onClick={this.props.onUpperRightClick}
            className={classes.upperRight}
          >
            {upperRight}
          </span>
        </div>
        {description && (
          <span className={classes.description}>{description}</span>
        )}
        {error ? (
          <p className={classes.error}>{error}</p>
        ) : (
          subheader && (
            <div>
              <Typography className={classes.subheader} color="primary">
                {subheader}
              </Typography>
              <span className={classes.sublabel}>{sublabel}</span>
            </div>
          )
        )}
        {Content}
        {expandable && this.state.expanded && (
          <div
            className={cx(classes.expandable, {
              [classes.expanded]: !!this.state.expanded
            })}
          >
            {this.props.children}
          </div>
        )}
        {expandable && (
          <button
            className={classes.expandableButton}
            onClick={this.handleClick}
          >
            {this.state.expanded ? '↑ See less' : '↓ See more'}
          </button>
        )}
      </Paper>
    )
  }
}

export default withStyles(styles)(Card)
