import {
  Flex,
  Box,
  Divider,
  Text,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Grid,
  GridItem
} from '@chakra-ui/react'
import React from 'react'
import { useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import { endpoints } from '../../../api'
import Loading from '../../../components/loading'
import PillTabs from '../../../components/pill-tabs'
import { sortBy } from 'lodash'

const formatClient = count => (count === 1 ? 'client' : 'clients')

const CaseloadInsightsContainer = ({ overrideClinicianId }) => {
  const {
    data: { activeCount, overdueCount, highRiskCount },
    isLoading: countIsLoading
  } = useQuery(
    [endpoints.getCaseloadOverview.getCacheId(), overrideClinicianId],
    () => endpoints.getCaseloadOverview.request({ overrideClinicianId }),
    {
      initialData: { activeCount: 0, overdueCount: 0, highRiskCount: 0 }
    }
  )

  const {
    data: { ages, assessments, domains },
    isLoading: breakdownIsLoading
  } = useQuery(
    [endpoints.getCaseloadBreakdown.getCacheId(), overrideClinicianId],
    () => endpoints.getCaseloadBreakdown.request({ overrideClinicianId }),
    {
      initialData: { ages: [], assessments: [], domains: [] }
    }
  )

  return (
    <>
      <Text as="h2" fontWeight="bold" fontSize="lg" mb="small">
        Caseload Insights
      </Text>
      <Flex justify="space-between" wrap="wrap">
        <CaseloadOverview
          countIsLoading={countIsLoading}
          activeCount={activeCount}
          highRiskCount={highRiskCount}
          overdueCount={overdueCount}
        />
        <CaseloadBreakdown
          breakdownIsLoading={breakdownIsLoading}
          countIsLoading={countIsLoading}
          activeCount={activeCount}
          ages={ages}
          assessments={assessments}
          domains={domains}
        />
      </Flex>
    </>
  )
}

const CaseloadOverview = ({
  countIsLoading,
  activeCount,
  highRiskCount,
  overdueCount
}) => {
  if (countIsLoading) {
    return <Loading />
  }

  return (
    <Box
      borderRadius="8px"
      sx={{
        width: '100%',
        marginBottom: 'large',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)'
      }}
    >
      <StatGroup justify="space-evenly" flexWrap="nowrap">
        <Stat flex="0 1 120px" p="medium">
          <StatNumber fontSize="xl">{activeCount}</StatNumber>
          <StatLabel fontSize="md">
            Active {formatClient(activeCount)}
          </StatLabel>
        </Stat>
        <Divider orientation="vertical" height="74px" alignSelf="center" />
        <Stat flex="0 1 120px" p="medium">
          <StatNumber fontSize="xl">{overdueCount}</StatNumber>
          <StatLabel fontSize="md">
            Overdue {formatClient(overdueCount)}
          </StatLabel>
        </Stat>
        <Divider orientation="vertical" height="74px" alignSelf="center" />
        <Stat
          flex="0 1 120px"
          p="medium"
          color={highRiskCount === 0 ? 'black' : '#B5342A'}
        >
          <StatNumber fontSize="xl">{highRiskCount}</StatNumber>
          <StatLabel fontSize="md">
            High-Risk {formatClient(highRiskCount)}
          </StatLabel>
        </Stat>
      </StatGroup>
    </Box>
  )
}

const CaseloadBreakdown = ({
  breakdownIsLoading,
  countIsLoading,
  activeCount,
  ages,
  assessments,
  domains
}) => {
  const [section, setSection] = useState('clinical_domains')

  const SECTION_CONFIG = useMemo(
    () => ({
      clinical_domains: sortBy(domains, 'count').reverse(),
      assessments: sortBy(assessments, 'count').reverse(),
      age_range: ages
    }),
    [assessments, domains, ages]
  )

  const isMissingData = useMemo(
    () => [assessments, domains, ages].some(c => c.length < 1),
    [assessments, domains, ages]
  )

  const sectionData = useMemo(() => SECTION_CONFIG[section], [
    SECTION_CONFIG,
    section
  ])

  const tabs = Object.keys(SECTION_CONFIG).map(key => ({
    value: key,
    display: key.toString().replace('_', ' ')
  }))

  if (countIsLoading || breakdownIsLoading) {
    return <Loading />
  }

  if (isMissingData) {
    return null
  }

  return (
    <Box
      borderRadius="8px"
      sx={{
        width: '100%',
        marginBottom: 'large',
        padding: 'medium',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)'
      }}
    >
      <PillTabs tabs={tabs} selected={section} onChange={setSection} />
      <Flex direction="column" mb="48px">
        {sectionData.map(({ name, count }, idx) => (
          <RatioBar key={idx} label={name} value={count} total={activeCount} />
        ))}
      </Flex>

      <Divider mb="14px" />
      <Text fontSize="xsm" color="#757575">
        Based on {activeCount} active {formatClient(activeCount)}
      </Text>
    </Box>
  )
}

const RatioBar = ({ label, value, total }) => {
  const MIN_OPACITY = 0.2

  const ratio = ((value * 1.0) / total).toFixed(2)
  const percent = `${Math.round(ratio * 100)}%`
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap="16px">
      <GridItem w="100%">
        <Text textAlign="right">{label}</Text>
      </GridItem>
      <GridItem w="100%" display="flex" alignItems="center">
        <Box
          width={percent}
          height="12px"
          bg={`rgb(54, 83, 223, ${Math.max(ratio, MIN_OPACITY)})`}
          display="inline-block"
        />
        <Text fontSize="xsm" display="inline-block" ml="xsmall">
          {percent}
        </Text>
      </GridItem>
    </Grid>
  )
}

export default CaseloadInsightsContainer
