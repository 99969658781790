import React, { useContext } from 'react'
import { Text, Select, HStack, VStack } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { WorkflowsContext } from '@context'
import { EditDrawerFooter } from '../../../edit-drawer/edit-drawer-footer'
import { FilterCatalog } from './filters/filter-catalog'
import { FilterFieldArray } from '../shared-filters/filter-field-array'
import {
  CLIENT_AGE_OPERATOR_SCHEMA,
  CLIENT_AGE_VALUES_SCHEMA
} from '../shared-filters/client-age-filter'

type RouteParams = {
  workflowId: string
  organizationId: string
}

type Props = {
  closeDrawer: () => void
  onSubmit: (trigger: Trigger) => void
}

type Trigger = {
  workflowId: string
  triggerType: string
  config: TriggerConfig | {}
}

type AppointmentTypeFilter = {
  type: string
  appointmentTypeOperator: string
  appointmentTypes: string[]
}

type ClientStatusFilter = {
  type: string
  clientStatusOperator: string
  clientStatuses: string[]
}

type TriggerConfig = {
  appointmentStatus: string
  filters?: (AppointmentTypeFilter | ClientStatusFilter)[]
}

const schema = Yup.object().shape({
  appointmentTimeValue: Yup.number().required(),
  appointmentTimeUnit: Yup.string().required(),
  appointmentTypeOperator: Yup.string().when('filterTypes', {
    is: (types: string[]) => types.includes('appointmentType'),
    then: Yup.string().required()
  }),
  appointmentTypes: Yup.array().when('filterTypes', {
    is: (types: string[]) => types.includes('appointmentType'),
    then: Yup.array()
      .of(
        Yup.string()
          .min(1)
          .required()
      )
      .min(1)
      .required()
  }),
  clientStatusOperator: Yup.string().when('filterTypes', {
    is: (types: string[]) => types.includes('clientStatus'),
    then: Yup.string()
      .min(1)
      .required()
  }),
  clientStatuses: Yup.array().when('filterTypes', {
    is: (types: string[]) => types.includes('clientStatus'),
    then: Yup.array()
      .of(
        Yup.string()
          .min(1)
          .required()
      )
      .min(1)
      .required()
  }),
  clientAgeOperator: CLIENT_AGE_OPERATOR_SCHEMA,
  clientAgeValues: CLIENT_AGE_VALUES_SCHEMA,
  programAssignments: Yup.array().when('filterTypes', {
    is: (types: string[]) => types.includes('programAssignment'),
    then: Yup.array()
      .of(
        Yup.string()
          .min(1)
          .required()
      )
      .min(1)
      .required()
  }),
  programAssignmentOperator: Yup.string().when('filterTypes', {
    is: (types: string[]) => types.includes('programAssignment'),
    then: Yup.string()
      .min(1)
      .required()
  }),
  filterTypes: Yup.array()
    .of(Yup.string().optional())
    .optional()
})

const determineFilterConfig = (filterType: string, values: any) => {
  switch (filterType) {
    case 'appointmentType':
      return {
        filterType,
        filterOperator: values.appointmentTypeOperator,
        filterValues: values.appointmentTypes
      }
    case 'clientStatus':
      return {
        filterType,
        filterOperator: values.clientStatusOperator,
        filterValues: values.clientStatuses
      }
    case 'clientAge':
      return {
        filterType,
        filterOperator: values.clientAgeOperator,
        filterValues: values.clientAgeValues
      }
    case 'programAssignment':
      return {
        filterType,
        filterOperator: values.programAssignmentOperator,
        filterValues: values.programAssignments
      }
    default:
      return {}
  }
}

const prepareFiltersForSubmission = (values: any) => {
  return values.filterTypes.map((type: string) => {
    return determineFilterConfig(type, values)
  })
}

export const UpcomingAppointmentTrigger = ({
  closeDrawer,
  onSubmit
}: Props) => {
  const { selectedNode } = useContext(WorkflowsContext)
  const { workflowId } = useParams<RouteParams>()
  const config = selectedNode?.config
  const filters = config?.filters

  const initialValues = {
    appointmentTimeValue: 24,
    appointmentTimeUnit: 'hours',
    appointmentTypeOperator:
      filters?.find((f: any) => f.filterType === 'appointmentType')
        ?.filterOperator || '',
    appointmentTypes: filters?.find(
      (f: any) => f.filterType === 'appointmentType'
    )?.filterValues || [''],
    clientStatuses: filters?.find((f: any) => f.filterType === 'clientStatus')
      ?.filterValues || [''],
    clientStatusOperator:
      filters?.find((f: any) => f.filterType === 'clientStatus')
        ?.filterOperator || '',
    clientAgeOperator:
      filters?.find((f: any) => f.filterType === 'clientAge')?.filterOperator ||
      '',
    clientAgeValues: filters?.find((f: any) => f.filterType === 'clientAge')
      ?.filterValues || [1],
    programAssignments: filters
      ?.find((f: any) => f.filterType === 'programAssignment')
      ?.filterValues?.map((f: any) => f.id) || [''],
    programAssignmentOperator:
      filters?.find((f: any) => f.filterType === 'programAssignment')
        ?.filterOperator || '',
    filterTypes: filters?.map((f: any) => f.filterType) || []
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validate={values => {
        const errors: any = {}
        const badFilterTypeIndex = values.filterTypes.indexOf('')
        if (badFilterTypeIndex > -1) {
          errors[`filterTypes.${badFilterTypeIndex}`] =
            'Please select a filter type'
        }

        return errors
      }}
      onSubmit={values => {
        const trigger: Trigger = {
          workflowId,
          triggerType: 'upcoming-appointment-trigger',
          config: {
            timeValue: values.appointmentTimeValue,
            timeUnit: values.appointmentTimeUnit,
            ...(values.filterTypes && values.filterTypes.length
              ? {
                  filters: prepareFiltersForSubmission(values)
                }
              : {})
          }
        }

        onSubmit(trigger)
      }}
    >
      {({ values, errors, resetForm: resetFields }) => {
        const resetForm = (updates: any) => {
          const updatedValues = { ...initialValues, ...updates }
          resetFields({ values: updatedValues })
        }

        return (
          <Form>
            <VStack align="flex-start" spacing="xsmall" w="100%">
              <HStack w="100%" spacing="small" justify="space-between">
                <Text width="230px">Appointment is upcoming in</Text>
                <Field name="appointmentTimeValue">
                  {({ field }: { field: { value: string } }) => (
                    <Select
                      {...field}
                      isDisabled
                      value={field.value}
                      borderColor={
                        errors?.appointmentTimeValue ? 'error' : 'light_gray'
                      }
                      color={field.value ? 'black' : 'gray'}
                      w="90px"
                      onChange={e => {}}
                    >
                      <option value="24">24</option>
                    </Select>
                  )}
                </Field>
                <Field name="appointmentTimeUnit">
                  {({ field }: { field: { value: string } }) => (
                    <Select
                      {...field}
                      isDisabled
                      value={field.value}
                      borderColor={
                        errors?.appointmentTimeUnit ? 'error' : 'light_gray'
                      }
                      color={field.value ? 'black' : 'gray'}
                      w="150px"
                      onChange={e => {}}
                    >
                      <option value="hours">hours</option>
                    </Select>
                  )}
                </Field>
              </HStack>
              {values?.appointmentTimeValue && (
                <>
                  <Text as="b">Filters</Text>
                  <FilterFieldArray
                    values={values}
                    filterCatalog={FilterCatalog}
                    resetForm={resetForm}
                  />
                </>
              )}
            </VStack>
            <EditDrawerFooter onCancel={closeDrawer} hideDelete />
          </Form>
        )
      }}
    </Formik>
  )
}
