import { useContext } from 'react'
import { AudioCaptureContext } from '@context'

const useAudioCapture = () => {
  const context = useContext(AudioCaptureContext)

  if (context === undefined) {
    throw new Error(
      'useAudioCapture must be used within an AudioCaptureProvider'
    )
  }

  return context
}

export default useAudioCapture
