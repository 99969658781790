import React from 'react'
import * as Actions from './drawers/actions'
import * as Triggers from './drawers/triggers'
import {
  AssessmentIcon,
  EmailFilledIcon,
  InterventionIcon,
  TextFilledIcon,
  WorksheetIcon,
  SafetyIcon,
  SwitchIcon,
  SymptomTrackerIcon,
  PersonAddIcon,
  PhoneAppInstalledIcon,
  CheckIcon
} from '@blueprinthq/joy'

type NodeMap = {
  [key: string]: React.FC<{
    closeDrawer: () => void
    onSubmit: () => void
    onDelete: () => void
  }>
}

type ComponentMap = {
  [key: string]: NodeMap
}

type DataMap = {
  [key: string]: DrawerData
}

type DrawerData = {
  name: string
  icon: React.FC | null
  description: string
  userType: string
}

export const drawerCatalog: ComponentMap = {
  actions: {
    'assign-assessment': Actions.AssignAssessment,
    'assign-symptom-tracker': Actions.AssignSymptomTracker,
    'assign-worksheet': Actions.AssignWorksheet,
    'mark-client-high-risk': Actions.MarkClientHighRisk,
    'recommend-intervention': Actions.RecommendIntervention,
    'send-client-notification': Actions.SendClientNotification,
    'send-email': Actions.SendEmail,
    'show-safety-net': Actions.ShowSafetyNet,
    'assign-program': Actions.AssignProgram,
    'enroll-ehr-client': Actions.EnrollEHRClient,
    'send-appointment-reminder': Actions.SendAppointmentReminder,
    'unarchive-ehr-client': Actions.UnarchiveEHRClient
  },
  triggers: {
    'assessment-submitted-trigger': Triggers.AssessmentSubmittedTrigger,
    'upcoming-appointment-trigger': Triggers.UpcomingAppointmentTrigger,
    'program-started-trigger': Triggers.ProgramStartedTrigger
  }
}

export const drawerData: DataMap = {
  'assign-assessment': {
    name: 'Assign Assessments',
    icon: AssessmentIcon,
    description: 'Assign Assessments to the Client or Participants',
    userType: 'client'
  },
  'assign-symptom-tracker': {
    name: 'Assign Symptom Tracker',
    icon: SymptomTrackerIcon,
    description: 'Assign Symptom Trackers to the Client',
    userType: 'client'
  },
  'assign-worksheet': {
    name: 'Assign Worksheet',
    icon: WorksheetIcon,
    description: 'Assign Worksheets to the Client',
    userType: 'client'
  },
  'mark-client-high-risk': {
    name: 'Mark As High Risk',
    icon: SwitchIcon,
    description: 'Mark the Client as High Risk',
    userType: 'client'
  },
  'recommend-intervention': {
    name: 'Recommend Intervention',
    icon: InterventionIcon,
    description: 'Recommend Interventions to the Clinician',
    userType: 'clinician'
  },
  'show-safety-net': {
    name: 'Show Safety Net',
    icon: SafetyIcon,
    description: 'Show a Safety Net prompt in the Client app',
    userType: 'client'
  },
  'send-client-notification': {
    name: 'Send Notification',
    icon: TextFilledIcon,
    description: 'Send an email and text message to the Client',
    userType: 'client'
  },
  'send-email': {
    name: 'Send Email',
    icon: EmailFilledIcon,
    description: 'Send an email to the assigned Clinician(s)',
    userType: 'clinician'
  },
  'new-action-node': {
    name: 'Actions',
    icon: null,
    description: 'Pick an Action for this step',
    userType: ''
  },
  'assign-program': {
    name: 'Assign Program',
    icon: AssessmentIcon,
    description: 'Enroll the client in a program',
    userType: 'client'
  },
  'enroll-ehr-client': {
    name: 'Enroll EHR Client',
    icon: PersonAddIcon,
    description: 'Enroll and invite the client to Blueprint',
    userType: 'client'
  },
  'send-appointment-reminder': {
    name: 'Send Appointment Reminder',
    icon: PhoneAppInstalledIcon,
    description: 'Remind the client to complete overdue assessments',
    userType: 'client'
  },
  'unarchive-ehr-client': {
    name: 'Unarchive EHR Client',
    icon: CheckIcon,
    description: "Move the client from 'archived' to 'pending'",
    userType: 'client'
  },
  'assessment-submitted-trigger': {
    name: 'Assessment Submitted',
    icon: AssessmentIcon,
    description: '',
    userType: 'client'
  },
  'upcoming-appointment-trigger': {
    name: 'Upcoming Appointment',
    icon: null,
    description: '',
    userType: ''
  },
  'program-started-trigger': {
    name: 'Program Started',
    icon: null,
    description: '',
    userType: ''
  }
}
