import React from 'react'
import { Box, SimpleGrid, Container, Show } from '@chakra-ui/react'

interface ColumnProps {
  children?: React.ReactNode | React.ReactNode[]
  isMobile: boolean
  containerProps: Object
}

interface LayoutProps {
  children?: React.ReactNode | React.ReactNode[]
  isMobile: boolean
}

export const Layout = ({ children, isMobile }: LayoutProps) => {
  let columns = [1, 1, 2, 2, 2, 2]

  return (
    <SimpleGrid h="100%" spacing="0" columns={isMobile ? 1 : columns}>
      {children}
    </SimpleGrid>
  )
}

export const LeftColumn = ({ children, isMobile }: ColumnProps) => {
  let background: string = 'primary'
  let bgImage =
    "url('https://res.cloudinary.com/hellojoy/image/upload/v1661702363/logo-bg_bp3z5n.png')"
  let bgPosition: string = 'left'
  let bgSize = 'cover'
  let bgRepeat = 'no-repeat'

  if (isMobile) {
    return null
  }

  return (
    <Show above="md">
      <Box
        height="100%"
        width="100%"
        bg={background}
        bgImage={bgImage}
        bgPosition={bgPosition}
        bgSize={bgSize}
        bgRepeat={bgRepeat}
        overflowY="hidden"
      >
        <Container
          centerContent
          paddingTop={{
            base: '24px',
            sm: '32px',
            md: '48px',
            lg: '90px',
            xl: '90px'
          }}
          paddingLeft={{
            base: '24px'
          }}
          paddingRight={{
            base: '24px'
          }}
        >
          {children}
        </Container>
      </Box>
    </Show>
  )
}

export const RightColumn = ({ children, containerProps = {} }: ColumnProps) => {
  return (
    <Box height="100%">
      <Container
        height="100%"
        alignSelf="center"
        overflowY="scroll"
        centerContent={true}
        paddingTop={{
          base: '24px',
          sm: '32px',
          md: '48px',
          lg: '90px',
          xl: '90px'
        }}
        paddingLeft={{
          base: '24px'
        }}
        paddingRight={{
          base: '24px'
        }}
        {...containerProps}
      >
        {children}
      </Container>
    </Box>
  )
}
