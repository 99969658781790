import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'

export const ShowSafetyNet = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const body = (
    <Text>
      Show <Text as="b">Your safety net</Text> to client
    </Text>
  )

  return (
    <WorkflowNode
      heading="Show safety net Action"
      body={body}
      hasChildren={hasChildren}
      isParent={isParent}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
