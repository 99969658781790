import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { SessionGetStarted } from './session-get-started'
import SessionList from './session-list'
import { useComponentRenderTracker } from '@hooks/utilities/use-component-render-tracker'

const SessionDashboard = () => {
  useComponentRenderTracker('Viewed Session Dashboard')

  return (
    <Switch>
      <Route path="/sessions" component={SessionList} exact />
      <Route path="/sessions/get-started" component={SessionGetStarted} exact />
    </Switch>
  )
}

export default SessionDashboard
