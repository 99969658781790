import React from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts'
import { SURVEY_RESPONSES } from './constants'
import { ChartContainer, Section } from '@handlers/reports/components'

const colorMap = {
  [SURVEY_RESPONSES.VERY_SATISFIED]: '#2D54E8',
  [SURVEY_RESPONSES.SATISFIED]: '#2d54e880',
  [SURVEY_RESPONSES.NEUTRAL]: '#E4E5E6',
  [SURVEY_RESPONSES.UNSATISFIED]: '#28282880',
  [SURVEY_RESPONSES.VERY_UNSATISFIED]: '#282828'
}

const renderActiveShape = props => {
  // this was all taken from an example in the recharts docs
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  const { value } = payload.payload

  const percentage = value % 1 !== 0 ? value.toFixed(2) : value

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
        fontWeight="bold"
      >
        {payload.payload.label} ({percentage} %)
      </text>
    </g>
  )
}

const ClientSatisfactionPieChart = ({ data, isLoading, emptyState }) => {
  return (
    <Section isLoading={isLoading}>
      <ChartContainer>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Heading fontSize="lg" my="2">
              Client Satisfaction
            </Heading>
            <Text>
              What % of clients were satisfied with their care experience?
            </Text>
          </Box>
        </Flex>
        {!isLoading && data.length === 0 ? (
          emptyState
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={800} height={800}>
              <Pie
                activeIndex={[0, 1, 2, 3, 4, 5]}
                data={data}
                innerRadius={70}
                outerRadius={90}
                paddingAngle={5}
                dataKey="value"
                activeShape={renderActiveShape}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colorMap[entry.label]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}
        <Box>
          <Text>
            *Based on the{' '}
            <Text as="span" fontWeight="bold">
              Satisfaction With Care
            </Text>{' '}
            survey
          </Text>
        </Box>
      </ChartContainer>
    </Section>
  )
}

export default ClientSatisfactionPieChart
