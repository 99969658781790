import React from 'react'
import { Flex, Text, Link as CLink, useBreakpointValue } from '@chakra-ui/react'

export const ConsentModule = ({ isGetStarted }) => {
  const Title = ({ children }) => {
    let SpanDiv = useBreakpointValue({ base: 'div', md: 'span' })
    if (!isGetStarted) SpanDiv = 'div'
    return <SpanDiv style={{ marginRight: '8px' }}>{children}</SpanDiv>
  }

  return (
    <Flex direction="column" width="100%">
      <Flex>
        <Text fontWeight="bold" mb="xsmall">
          More resources
        </Text>
      </Flex>
      <Flex
        direction="row"
        justifyContent="left"
        alignItems="center"
        p="16px"
        border="1px solid"
        borderColor="pale_gray"
        borderRadius="8px"
        gap="16px"
        width="100%"
      >
        <Flex p="12px" borderRadius="8px" bg="#F9F9F9">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            width="20"
            viewBox="0 0 36 36"
          >
            <g fill="none" fillRule="evenodd">
              <path d="m0 0h36v36h-36z" />
              <g fillRule="nonzero" transform="translate(6 1.5)">
                <path d="m4.875 16.875h14.25v2.625h-14.25z" fill="#fff" />
                <path d="m4.875 21.375h10.5v2.625h-10.5z" fill="#fff" />
                <path
                  d="m15.75 8.25 4.524375.855 3.725625-.855-8.25-8.25-1.138125 3.91125z"
                  fill="#1967d2"
                />
                <path d="m4.875 12.375h14.25v2.625h-14.25z" fill="#fff" />
                <path
                  d="m15.75 8.25v-8.25h-13.5c-1.243125 0-2.25 1.006875-2.25 2.25v28.5c0 1.243125 1.006875 2.25 2.25 2.25h19.5c1.243125 0 2.25-1.006875 2.25-2.25v-22.5zm-.75 15.375h-9.75v-1.875h9.75zm3.75-4.5h-13.5v-1.875h13.5zm0-4.5h-13.5v-1.875h13.5z"
                  fill="#4285f4"
                />
              </g>
            </g>
          </svg>
        </Flex>
        <Flex direction="column">
          <Text fontWeight="bold">Client Consent</Text>
          <CLink
            id={
              isGetStarted
                ? 'pendo-sessions-get-started-consent'
                : 'pendo-sessions-consent'
            }
            data-click-event-name="Clicked Consent Template Link"
            href="https://docs.google.com/document/d/1YOpiLkjcj5c68Tf15jexv0ISiRLWGQOHCNzoOptA2Qc/edit"
            isExternal
            color="primary"
            _focus={{ outline: 'none' }}
          >
            Use our template
          </CLink>
        </Flex>
      </Flex>
    </Flex>
  )
}
