/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type SessionControllerGetSessionPrepActions = typeof SessionControllerGetSessionPrepActions[keyof typeof SessionControllerGetSessionPrepActions];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SessionControllerGetSessionPrepActions = {
  VIEW_TREATMENT_PLAN: 'VIEW_TREATMENT_PLAN',
  VIEW_ASSESSMENT_SUGGESTIONS: 'VIEW_ASSESSMENT_SUGGESTIONS',
  VIEW_PATIENT_HIGH_RISK_DETAILS: 'VIEW_PATIENT_HIGH_RISK_DETAILS',
  TAKE_OVERDUE_ASSESSMENTS: 'TAKE_OVERDUE_ASSESSMENTS',
  REVIEW_ASSESSMENT_RESULTS: 'REVIEW_ASSESSMENT_RESULTS',
  VIEW_WORKSHEET_SUGGESTIONS: 'VIEW_WORKSHEET_SUGGESTIONS',
  REVIEW_WORKSHEET_RESULTS: 'REVIEW_WORKSHEET_RESULTS',
} as const;
