import React, { useMemo } from 'react'
import { useStoreActions } from 'easy-peasy'
import { Button } from '@blueprinthq/joy'
import { MeasureTypes } from '@constants/measureTypes'
import {
  buildAssessmentTakeNowUrl,
  buildAssessmentPreviewUrl
} from '@utilities'

export const AssignAssessmentsModal = ({
  assessments,
  assigneeUser,
  clientId,
  onAssign,
  selectedAssessments,
  isEnrollFlow
}) => {
  const openAssignAssessmentsModal = useStoreActions(
    actions => actions.modals.assignMeasures.openModal
  )

  const activeAssessmentCount = assessments.filter(
    assessment => assessment.isActive
  ).length

  const allAssessments = useMemo(() => {
    return assessments.map(a => ({
      measureId: a.id,
      title: `${a.full_name} ${
        a.name !== a.full_name ? '(' + a.name + ')' : ''
      }`,
      subtitle: a.disorder,
      numQuestions: a.num_questions,
      previewUrl: buildAssessmentPreviewUrl(a.assessment_id),
      takeNowUrl: isEnrollFlow
        ? null
        : buildAssessmentTakeNowUrl(assigneeUser.id, a.assessment_id),
      isAssigned:
        (a.isActive && a.assigneeUser.id === assigneeUser.id) ||
        (selectedAssessments &&
          selectedAssessments.map(sa => sa.id).includes(a.id))
    }))
  }, [assessments, selectedAssessments, activeAssessmentCount])

  return (
    <Button
      variant="link"
      textDecoration="none"
      onClick={() =>
        openAssignAssessmentsModal({
          clientId,
          measureType: MeasureTypes.ASSESSMENT,
          allMeasures: allAssessments,
          onAssign,
          assigneeUser: assigneeUser.info.firstName
            ? assigneeUser
            : {
                ...assigneeUser,
                info: { ...assigneeUser.info, firstName: 'client' }
              }
        })
      }
    >
      {`Assign Assessments to ${assigneeUser.info.firstName || 'client'}`}
    </Button>
  )
}
