import React from 'react'
import {
  Box,
  Select,
  Text,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react'
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik'
import * as Yup from 'yup'

type Props = {
  resetForm: (values: object) => void
  constants: any
}

type FormikContext = {
  values: {
    clientAgeOperator: string | undefined
    clientAgeValues: number[]
  }
  errors: FormikErrors<any>
}

export const CLIENT_AGE_OPERATOR_SCHEMA = Yup.string().when('filterTypes', {
  is: (types: string[]) => types.includes('clientAge'),
  then: Yup.string()
    .min(1)
    .required()
})

export const CLIENT_AGE_VALUES_SCHEMA = Yup.array().when('filterTypes', {
  is: (types: string[]) => types.includes('clientAge'),
  then: Yup.array().when('clientAgeOperator', {
    is: 'between-inclusive',
    then: Yup.array()
      .of(Yup.number().required())
      .min(2)
      .required(),
    otherwise: Yup.array()
      .of(Yup.number().required())
      .min(1)
      .required()
  }),
  otherwise: Yup.array().optional()
})

export const CLIENT_AGE_FILTER_OPERATORS = [
  { display: 'less than', value: 'less-than' },
  { display: 'less than or equal to', value: 'less-than-equal' },
  { display: 'greater than', value: 'greater-than' },
  {
    display: 'greater than or equal to',
    value: 'greater-than-equal'
  },
  { display: 'equal to', value: 'equal' },
  { display: 'between (inclusive)', value: 'between-inclusive' }
]

export const ClientAgeFilter = ({ resetForm, constants }: Props) => {
  const { values, errors }: FormikContext = useFormikContext()

  return (
    <HStack spacing="small">
      <Field name="clientAgeOperator">
        {({ field }: { field: { value: string } }) => (
          <Select
            {...field}
            value={field.value}
            w="310px"
            borderColor={errors?.clientAgeOperator ? 'error' : 'light_gray'}
            color={field.value ? 'black' : 'gray'}
            onChange={e => {
              resetForm({
                ...values,
                clientAgeValues:
                  e.target.value === 'between-inclusive'
                    ? [values.clientAgeValues[0], '']
                    : [values.clientAgeValues[0]],
                clientAgeOperator: e.target.value
              })
            }}
          >
            <option value="" disabled>
              Operator
            </option>
            {CLIENT_AGE_FILTER_OPERATORS.map(
              (o: { display: string; value: string }) => (
                <option key={o.value} value={o.value}>
                  {o.display}
                </option>
              )
            )}
          </Select>
        )}
      </Field>
      <FieldArray name="clientAgeValues">
        {({ replace }) => {
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Field
                w={
                  values.clientAgeOperator !== 'between-inclusive'
                    ? '150px'
                    : '130px'
                }
                mx="xsmall"
                name="clientAgeValues.0"
              >
                {({ field }: { field: { value: string } }) => (
                  <NumberInput
                    {...field}
                    min={1}
                    max={150}
                    value={field.value}
                    borderColor={
                      errors?.clientAgeValues ? 'error' : 'light_gray'
                    }
                    color={field.value ? 'black' : 'gray'}
                    onChange={(_, value) => {
                      replace(0, value)
                    }}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper borderInlineStart="none" />
                      <NumberDecrementStepper borderInlineStart="none" />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              </Field>
              {values.clientAgeOperator === 'between-inclusive' && (
                <>
                  <Text mx="xsmall">and</Text>
                  <Field w="130px" mx="xsmall" name="clientAgeValues.1">
                    {({ field }: { field: { value: string }; form: any }) => (
                      <NumberInput
                        {...field}
                        min={1}
                        max={150}
                        value={field.value}
                        borderColor={
                          errors?.clientAgeValues ? 'error' : 'light_gray'
                        }
                        color={field.value ? 'black' : 'gray'}
                        onChange={(_, value) => {
                          replace(1, value)
                        }}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper borderInlineStart="none" />
                          <NumberDecrementStepper borderInlineStart="none" />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  </Field>
                </>
              )}
            </Box>
          )
        }}
      </FieldArray>
    </HStack>
  )
}
