import React from 'react'

const Letter = () => {
  return (
    <svg
      width="136"
      height="160"
      viewBox="0 0 136 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_21_113)">
        <rect x="8" y="6" width="120" height="144" rx="4" fill="white" />
        <rect
          x="8.5"
          y="6.5"
          width="119"
          height="143"
          rx="3.5"
          stroke="#E4E5E6"
        />
        <rect
          width="19"
          height="2"
          transform="translate(24 22)"
          fill="#E4E5E6"
        />
        <rect
          width="12"
          height="2"
          transform="translate(24 26)"
          fill="#E4E5E6"
        />
        <rect
          width="32"
          height="2"
          transform="translate(24 36)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(24 40)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(24 44)"
          fill="#E4E5E6"
        />
        <rect
          width="32"
          height="2"
          transform="translate(24 54)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(24 58)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(24 62)"
          fill="#E4E5E6"
        />
        <rect
          x="25"
          y="73"
          width="4"
          height="4"
          fill="white"
          stroke="#E4E5E6"
          stroke-width="2"
        />
        <rect
          width="74"
          height="2"
          transform="translate(38 72)"
          fill="#E4E5E6"
        />
        <rect
          width="74"
          height="2"
          transform="translate(38 76)"
          fill="#E4E5E6"
        />
        <rect
          x="25"
          y="87"
          width="4"
          height="4"
          fill="white"
          stroke="#E4E5E6"
          stroke-width="2"
        />
        <rect
          width="74"
          height="2"
          transform="translate(38 86)"
          fill="#E4E5E6"
        />
        <rect
          width="74"
          height="2"
          transform="translate(38 90)"
          fill="#E4E5E6"
        />
        <rect
          x="25"
          y="101"
          width="4"
          height="4"
          fill="white"
          stroke="#E4E5E6"
          stroke-width="2"
        />
        <rect
          width="74"
          height="2"
          transform="translate(38 100)"
          fill="#E4E5E6"
        />
        <rect
          width="74"
          height="2"
          transform="translate(38 104)"
          fill="#E4E5E6"
        />
        <rect
          width="32"
          height="2"
          transform="translate(24 114)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(24 118)"
          fill="#E4E5E6"
        />
        <rect
          width="88"
          height="2"
          transform="translate(24 122)"
          fill="#E4E5E6"
        />
        <rect
          width="24"
          height="2"
          transform="translate(24 132)"
          fill="#E4E5E6"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_21_113"
          x="0"
          y="0"
          width="136"
          height="160"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_21_113"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_21_113"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Letter
