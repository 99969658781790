import React from 'react'
import { VStack, Link, Flex, Text, Stack, Image, Box } from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import CompleteIcon from '../../../../images/brand/complete.svg'
import { Container, GridItem } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'

export const SuccessPage = () => {
  const { formData } = useStoreState(state => state.clinicianSignup)

  return (
    <Container
      marginTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%">
            <VStack h="100%">
              <Stack bg="rgba(45, 84, 232, 0.04)" p="24px" borderRadius="8px">
                <Box alignSelf={'center'} pb={'small'}>
                  <Image loading="eager" src={CompleteIcon} height={'64px'} />
                </Box>

                <Box
                  style={{
                    marginInlineStart: 0,
                    margin: 0,
                    padding: 0,
                    height: '33px'
                  }}
                >
                  <Text
                    fontSize="24px"
                    lineHeight={'32px'}
                    align="center"
                    fontWeight="bold"
                  >
                    Check your email
                  </Text>
                </Box>

                <Stack
                  pt={'medium'}
                  style={{ marginInlineStart: 0, margin: 0 }}
                  gap="16px"
                >
                  <Flex>
                    <Text style={{ marginInlineStart: 0 }}>
                      We sent you an email
                      {formData.email && (
                        <>
                          <Text display="inline"> to </Text>
                          <Text display="inline" style={{ fontWeight: 'bold' }}>
                            {formData.email}
                          </Text>
                        </>
                      )}{' '}
                      with a link to confirm your email address. Once your email
                      address is confirmed, you’ll be signed in.
                    </Text>
                  </Flex>
                  <Flex>
                    <Text style={{ marginInlineStart: 0 }}>
                      If you didn’t receive an email, please double check that
                      you entered your email address correctly or check your
                      spam folder.
                    </Text>
                  </Flex>

                  <Flex>
                    <Text style={{ marginInlineStart: 0 }}>
                      If you continue to experience trouble, please reach out to{' '}
                      <Link
                        href={`mailto:${process.env.REACT_APP_HELP_EMAIL}`}
                        isExternal
                        color="primary"
                      >
                        support
                      </Link>
                      .
                    </Text>
                  </Flex>
                </Stack>
              </Stack>
            </VStack>
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
