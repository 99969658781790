import React from 'react'

let NavigationLinksWrapper = () => null
if (process.env.REACT_APP_THEME === 'tempus') {
  import('./tempus/wrapper').then(
    ({ default: navigationLinksWrapper }) =>
      (NavigationLinksWrapper = navigationLinksWrapper)
  )
} else {
  import('./blueprint/wrapper').then(
    ({ default: navigationLinksWrapper }) =>
      (NavigationLinksWrapper = navigationLinksWrapper)
  )
}

export default function NavigationLinks(baseProps) {
  return <NavigationLinksWrapper {...baseProps} />
}
