import React from 'react'
import { Text, Box } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

export const AssessmentResultSubScores = ({
  assessmentScore,
  clientId,
  handleCloseModal
}) => {
  const isBPDS = assessmentScore.name === 'BPDS'
  // we cant just change the shaape of snapshot_scores bc that data is pulled directly
  // from bpds_results.domain_resuts so we can just merge these two collections instead
  const subScores = assessmentScore.snapshot_scores
    ? assessmentScore.snapshot_scores
        .map(snapshot => {
          const matchingSubscore = assessmentScore.sub_scores.find(
            sub => sub.key === snapshot.domain
          )

          return matchingSubscore
            ? {
                ...snapshot,
                maxValue: matchingSubscore.maxValue
              }
            : null
        })
        .filter(snapshot => snapshot)
    : assessmentScore.sub_scores

  if (isBPDS && assessmentScore.snapshot_scores) {
    return (
      <Box>
        {subScores.map(subScore => {
          if (subScore.follow_up_asmts && subScore.follow_up_asmts.length > 0) {
            const [followUpAssessment] = subScore.follow_up_asmts
            return (
              <Box>
                <span>
                  {subScore.domain === 'default' && <br />}
                  {subScore.domain === 'default' ? (
                    'Client did not screen positive for any of the above domains, so we assigned a general well-being measure'
                  ) : (
                    <Text as="span">
                      <Text fontWeight="bold" as="span">
                        {subScore.domain_display_name}:
                      </Text>{' '}
                      {subScore.interpretation} ({subScore.score} out of{' '}
                      {subScore.maxValue})
                    </Text>
                  )}
                </span>
                <Text as="span" fontWeight="bold">
                  {`  → ${followUpAssessment.name}`}
                  <Text as="span" fontWeight="normal">
                    {` | ${followUpAssessment.num_questions} Questions | ${followUpAssessment.cadence} `}
                  </Text>
                  <Text
                    as="span"
                    fontWeight="normal"
                    textDecoration="underline"
                    color="primary"
                  >
                    <NavLink
                      onClick={handleCloseModal}
                      to={`/patient/${clientId}/settings/outcome_measures`}
                    >
                      View
                    </NavLink>
                  </Text>
                </Text>
              </Box>
            )
          }

          return (
            <Text key={subScore.key}>
              <Text fontWeight="bold" as="span">
                {subScore.domain_display_name}:
              </Text>{' '}
              {subScore.interpretation || 'None'} ({subScore.score} out of{' '}
              {subScore.maxValue})
            </Text>
          )
        })}
      </Box>
    )
  }

  return (
    <Box>
      {subScores.map(subScore => (
        <Text key={subScore.key}>
          <Text fontWeight="bold" as="span">
            {subScore.display}:
          </Text>{' '}
          {subScore.label}{' '}
          {subScore.maxValue
            ? `(${subScore.value} out of ${subScore.maxValue})`
            : `(${subScore.value})`}
        </Text>
      ))}
    </Box>
  )
}
