import React from 'react'
import { VStack, HStack, Box, Text } from '@chakra-ui/react'
import { CloseIcon } from '@blueprinthq/joy'
import { FieldArray } from 'formik'
import _ from 'lodash'

const WorksheetsComponent = ({ formHelpers }) => {
  const { values } = formHelpers

  return (
    <Box
      borderTop={values.worksheets.length && '1px solid #E4E5E6'}
      mt={values.worksheets.length && 'small'}
    >
      <FieldArray
        name="worksheets"
        render={arrayHelpers => {
          return values.worksheets.length ? (
            <Box>
              {values.worksheets.map((w, index) => (
                <HStack key={w.id} py="xsmall" borderBottom="1px solid #E4E5E6">
                  <VStack flex={6} align="flex-start">
                    <Text fontWeight="bold">{w.title}</Text>
                    <Text>{`${w.subtitle} | ${w.numQuestions} questions`}</Text>
                  </VStack>
                  <CloseIcon
                    fill={!w.canToggle && '#E4E5E6'}
                    ml="small"
                    cursor={!w.canToggle ? 'not-allowed' : 'pointer'}
                    onClick={() => {
                      if (!w.canToggle) return
                      arrayHelpers.remove(index)
                    }}
                  />
                </HStack>
              ))}
            </Box>
          ) : null
        }}
      />
    </Box>
  )
}

export const Worksheets = React.memo(WorksheetsComponent, (props, nextProps) =>
  _.isEqual(props, nextProps)
)
