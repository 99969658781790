import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useLocation, useHistory } from 'react-router-dom'
import {
  Container,
  GridItem,
  Box,
  Heading,
  Text,
  VStack,
  Link,
  Image,
  Flex
} from '@chakra-ui/react'
import { Button, LayoutGrid, TextField } from '@blueprinthq/joy'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { PasswordRules } from '../components'
import { endpoints } from '../api'
import config from '../config'
import BlueprintLogoRound from '../images/brand/blueprint_logo_round.svg'

function ForgotPasswordCode() {
  const [showPasswordRules, setShowPasswordRules] = useState(false)
  const [confirmError, setConfirmError] = useState(null)
  const location = useLocation()
  const history = useHistory()

  const email = new URLSearchParams(location.search).get('email')

  useEffect(() => {
    if (!email) {
      history.push('/forgot-password')
    }
  }, [email])

  const { mutateAsync: confirmReset } = useMutation(
    endpoints.postForgotPasswordConfirm.request,
    { onSuccess: () => history.push('/login') }
  )

  return (
    <Container
      marginTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Heading size="lg" mb="xxsmall">
            Reset your password
          </Heading>
          <Text mb="medium">
            If your account was found, an email was sent with a password reset
            code. Please enter this code and choose a new password. If you
            cannot find this email, check in your spam folder.
          </Text>
          <Formik
            validationSchema={Yup.object({
              code: Yup.string().required(
                'Please enter the reset code sent to your email'
              ),
              password: Yup.string()
                .password()
                .required('Required'),
              confirmPassword: Yup.string().oneOf(
                [Yup.ref('password')],
                'Passwords do not match'
              )
            })}
            initialValues={{
              code: '',
              password: '',
              confirmPassword: ''
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={() => setConfirmError(null)}
            onSubmit={async (values, actions) => {
              const data = {
                user_type: 'clinician',
                code: values.code.trim(),
                email: email.replace(/ /g, '+'),
                new_password: values.password
              }
              try {
                await confirmReset({ data })
                actions.resetForm({})
              } catch (e) {
                setConfirmError(
                  e.message ||
                    (e.error && e.error.message) ||
                    'Something went wrong. Please try again.'
                )
              }
              actions.setSubmitting(false)
            }}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form>
                <VStack mb="medium" spacing="small" alignItems="flex-start">
                  <Box w="100%" mb={errors.code && touched.code && 'small'}>
                    <Field name="code">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Reset code from your email"
                          isRequired
                          isInvalid={errors.code && touched.code}
                          errorText={errors.code}
                        />
                      )}
                    </Field>
                  </Box>
                  <Field name="password">
                    {({ field }) => (
                      <TextField
                        {...field}
                        onFocus={() => setShowPasswordRules(true)}
                        label="New password"
                        type="password"
                        isRequired
                        isInvalid={errors.password && touched.password}
                        errorText={errors.password}
                      />
                    )}
                  </Field>
                  {showPasswordRules && (
                    <Box w="100%">
                      <PasswordRules password={values.password} />
                    </Box>
                  )}
                  <Box
                    w="100%"
                    pb={
                      errors.confirmPassword &&
                      touched.confirmPassword &&
                      'small'
                    }
                  >
                    <Field name="confirmPassword">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Confirm new password"
                          type="password"
                          isRequired
                          isInvalid={
                            errors.confirmPassword && touched.confirmPassword
                          }
                          errorText={errors.confirmPassword}
                        />
                      )}
                    </Field>
                  </Box>
                  {confirmError && (
                    <Text w="100%" color="error">
                      {confirmError}
                    </Text>
                  )}
                  <Button
                    size="lg"
                    isLoading={isSubmitting}
                    type="submit"
                    isFullWidth
                  >
                    Update
                  </Button>
                  <Text mt="small">
                    Need help? Contact{' '}
                    <Link color="primary" href={`mailto:${config.helpEmail}`}>
                      support
                    </Link>
                  </Text>
                </VStack>
              </Form>
            )}
          </Formik>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}

function ForgotPassword() {
  const [requestCodeError, setRequestCodeError] = useState(null)
  const location = useLocation()
  const history = useHistory()

  const { mutateAsync: requestCode } = useMutation(
    endpoints.postForgotPasswordRequestCode.request
  )
  const triggerEmailReset = async (data, path) => {
    try {
      await requestCode({ data })
      history.push(path)
    } catch (e) {
      setRequestCodeError(
        e.message || 'Something went wrong. Please try again.'
      )
    }
  }

  return (
    <Container
      marginTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Heading size="lg" mb="xxsmall">
            Forgot your password?
          </Heading>
          <Text mb="medium">
            Enter your email address and we&apos;ll send you instructions for
            resetting your password.
          </Text>
          <Formik
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Invalid email')
                .required('Required')
            })}
            initialValues={{
              email: (location.state && location.state.email) || ''
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={() => setRequestCodeError(null)}
            onSubmit={async (values, actions) => {
              const path = `/forgot-password-code?email=${values.email}`
              const data = {
                user_type: 'clinician',
                email: values.email
              }
              await triggerEmailReset(data, path)
              actions.resetForm({})
              actions.setSubmitting(false)
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <VStack
                  spacing={errors.email && touched.email ? 'large' : 'small'}
                  mb="medium"
                >
                  <Field name="email">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        isRequired
                        isInvalid={errors.email && touched.email}
                        errorText={errors.email}
                      />
                    )}
                  </Field>
                  {requestCodeError && (
                    <Box w="100%">
                      <Text color="error">{requestCodeError}</Text>
                    </Box>
                  )}
                  <Button
                    size="lg"
                    isLoading={isSubmitting}
                    type="submit"
                    isFullWidth
                  >
                    Send reset instructions
                  </Button>
                </VStack>
              </Form>
            )}
          </Formik>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}

export { ForgotPassword, ForgotPasswordCode }
