import React from 'react'
import { Tag as ChakraTag, TagProps } from '@chakra-ui/react'

export const Tag = ({ children, ...rest }: TagProps) => {
  return (
    <ChakraTag
      color="#2D54E8"
      fontSize="12px"
      fontWeight="bold"
      bg="rgba(45, 84, 232, 0.15)"
      borderRadius="2px"
      padding={1}
      textTransform="uppercase"
      {...rest}
    >
      {children}
    </ChakraTag>
  )
}
