import React, { createContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { endpoints } from '@api'
import { Workflow } from '@handlers/settings/workflows/workflow-editor/workflow-editor'
import {
  actions,
  triggers
} from '@handlers/settings/workflows/workflow-editor/node-catalog'
import { FormattedNode } from '@handlers/settings/workflows/workflow-editor/format-nodes'

type RouteParams = {
  organizationId: string
  workflowId: string
}

type ProviderProps = {
  children: React.ReactNode
}

type Context = {
  nodes: FormattedNode[] | undefined
  workflow: Workflow | undefined
  isWorkflowLoading: boolean
  selectedNode: FormattedNode | undefined
  setSelectedNode: (node?: FormattedNode) => void
  isCreatingAction: boolean
  isCreatingTrigger: boolean
  newActionNode: () => FormattedNode
  newTriggerNode: () => FormattedNode
}

const defaultContext: Context = {
  nodes: undefined,
  workflow: undefined,
  isWorkflowLoading: true,
  selectedNode: undefined,
  setSelectedNode: () => {},
  isCreatingAction: false,
  isCreatingTrigger: false,
  newActionNode: () => ({} as FormattedNode),
  newTriggerNode: () => ({} as FormattedNode)
}

type QueryResult = {
  data: Workflow | undefined
  isLoading: boolean
}

export const useWorkflow = (
  organizationId: string,
  workflowId: string
): QueryResult =>
  useQuery(
    [
      endpoints.getOrganizationWorkflow.getCacheId(),
      organizationId,
      workflowId
    ],
    () =>
      // @ts-ignore
      endpoints.getOrganizationWorkflow.request({ organizationId, workflowId })
  )

export const WorkflowsContext = createContext(defaultContext)

export const WorkflowsContextProvider = ({ children }: ProviderProps) => {
  const [nodes, setNodes] = useState<FormattedNode[] | undefined>()
  const [selectedNode, setSelectedNode] = useState<FormattedNode | undefined>()
  const [isCreatingAction, setIsCreatingAction] = useState(false)
  const [isCreatingTrigger, setIsCreatingTrigger] = useState(false)
  const { organizationId, workflowId } = useParams<RouteParams>()

  const {
    data: workflow,
    isLoading: isWorkflowLoading
  }: QueryResult = useWorkflow(organizationId, workflowId)

  useEffect(() => {
    if (workflow) {
      setNodes(workflow.nodes)
      setIsCreatingTrigger(workflow.nodes.length === 0)
    }
  }, [workflow])

  useEffect(() => {
    setIsCreatingAction(
      Boolean(
        selectedNode &&
          Object.keys(actions).includes(selectedNode.type) &&
          !selectedNode?.id
      )
    )

    setIsCreatingTrigger(
      Boolean(
        selectedNode &&
          Object.keys(triggers).includes(selectedNode?.type) &&
          !selectedNode?.id &&
          nodes?.length === 0
      )
    )
  }, [selectedNode])

  const newActionNode = (): FormattedNode => ({
    workflowId,
    id: undefined,
    type: 'new-action-node',
    config: {},
    hasChildren: false,
    isParent: false,
    parentId:
      nodes && nodes?.length > 1 ? nodes[nodes.length - 1].id : undefined
  })

  const newTriggerNode = (): FormattedNode => ({
    workflowId,
    id: undefined,
    type: 'new-trigger-node',
    config: {},
    hasChildren: false,
    isParent: true,
    parentId: undefined
  })

  const context: Context = {
    nodes,
    workflow,
    isWorkflowLoading,
    selectedNode,
    setSelectedNode,
    isCreatingAction,
    isCreatingTrigger,
    newActionNode,
    newTriggerNode
  }

  return (
    <WorkflowsContext.Provider value={context}>
      {children}
    </WorkflowsContext.Provider>
  )
}
