import React from 'react'
import { Text, Button, Stack, Link } from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'

const SubmitIdea = () => {
  const { isDocumentationAutomationEnabled } = useExperienceManager()

  if (!isDocumentationAutomationEnabled) {
    return null
  }

  return (
    <Stack
      p="small"
      border="1px solid"
      borderColor="pale_gray"
      borderRadius="8px"
      textAlign="center"
      gap="16px"
    >
      <Stack>
        <Text>
          How can we improve the
          <br />
          AI Notetaker?
        </Text>
      </Stack>
      <Button
        style={{ marginTop: 0 }}
        as={Link}
        _hover={{ textDecoration: 'none' }}
        href="https://blueprint-health.canny.io/ai-notetaker"
        variant="outline"
        isExternal
      >
        Submit idea
      </Button>
    </Stack>
  )
}

export default SubmitIdea
