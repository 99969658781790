import React from 'react'

const NoteSettings = ({ fill = '#2D54E8', width = '16', height = '15' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.99776 0.39624C3.07451 0.39624 2.28571 1.03265 2.06162 1.89316H0.501961C0.22409 1.89316 0 2.11725 0 2.39512C0 2.67299 0.22409 2.89708 0.501961 2.89708H2.07059C2.29468 3.75758 3.07451 4.394 4.00672 4.394C4.93893 4.394 5.71877 3.74862 5.94286 2.89708H15.507C15.7849 2.89708 16.009 2.67299 16.009 2.39512C16.009 2.11725 15.7849 1.89316 15.507 1.89316H5.93389C5.7098 1.03265 4.92997 0.39624 3.99776 0.39624ZM3.99776 1.40016C4.5535 1.40016 5.00168 1.84834 5.00168 2.40408C5.00168 2.95983 4.5535 3.408 3.99776 3.408C3.44202 3.408 2.99384 2.95983 2.99384 2.40408C2.99384 1.84834 3.44202 1.40016 3.99776 1.40016ZM12.0022 5.49652C11.079 5.49652 10.2902 6.1419 10.0661 6.99344H0.501961C0.22409 7.0024 0 7.22649 0 7.4954C0 7.77327 0.22409 7.99736 0.501961 7.99736H10.0661C10.2902 8.85786 11.07 9.49428 12.0022 9.49428C12.9345 9.49428 13.7143 8.85786 13.9384 7.99736H15.507C15.7849 7.99736 16.009 7.77327 16.009 7.4954C16.009 7.21753 15.7849 6.99344 15.507 6.99344H13.9384C13.7143 6.13293 12.9345 5.49652 12.0022 5.49652ZM12.0022 6.50044C12.558 6.50044 13.0062 6.94862 13.0062 7.50436C13.0062 8.06011 12.558 8.50828 12.0022 8.50828C11.4465 8.50828 10.9983 8.06011 10.9983 7.50436C10.9983 6.94862 11.4465 6.50044 12.0022 6.50044ZM5.99664 10.6058C5.07339 10.6058 4.28459 11.2422 4.0605 12.1027H0.501961C0.22409 12.1027 0 12.3268 0 12.6046C0 12.8825 0.22409 13.1066 0.501961 13.1066H4.06947C4.29356 13.9671 5.07339 14.6035 6.0056 14.6035C6.93781 14.6035 7.71765 13.9581 7.94174 13.1066H15.507C15.7849 13.1066 16.009 12.8825 16.009 12.6046C16.009 12.3268 15.7849 12.1027 15.507 12.1027H7.94174C7.71765 11.2422 6.93781 10.6058 6.0056 10.6058H5.99664ZM5.99664 11.6007C6.55238 11.6007 7.00056 12.0489 7.00056 12.6046C7.00056 13.1604 6.55238 13.6086 5.99664 13.6086C5.4409 13.6086 4.99272 13.1604 4.99272 12.6046C4.99272 12.0489 5.4409 11.6007 5.99664 11.6007Z"
        fill={fill}
      />
    </svg>
  )
}

export default NoteSettings
