import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  dialog: {
    padding: '12px 0px',
    '& .content': {
      paddingBottom: '24px'
    },

    '& .button': {
      width: '150px',
      height: '41px'
    }
  },
  centerText: {
    textAlign: 'center'
  },
  reasonInput: {
    width: '100%',
    marginTop: '24px'
  },
  unassignText: {
    marginTop: '35px',
    color: theme.palette.text.contrastOnWhite,
    lineHeight: 1.25,
    textAlign: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '20px',

    [theme.breakpoints.down(414)]: {
      flexDirection: 'column',

      '& .button': {
        width: '100% !important',
        marginTop: '20px'
      }
    }
  }
}))
