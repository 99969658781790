import React, { useMemo } from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  ErrorBar,
  ResponsiveContainer
} from 'recharts'
import { Box, Text, Flex, VStack } from '@chakra-ui/react'
import { getEffectSizeLabel } from './outcomes-content'
import { Loading } from '../../../../components'

function mapNormalizedEffectSizeToLabel(effectSize) {
  switch (effectSize) {
    case 100:
      return ''
    case 80:
      return 'Highly Effective'
    case 50:
      return 'Moderate'
    case 20:
      return 'Mild'
    default:
      return 'None'
  }
}

const CustomizedYAxisTick = function(props) {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text className="recharts-text" x={-8} y={3} textAnchor="end" fill="#666">
        {mapNormalizedEffectSizeToLabel(payload.value)
          .split(' ')
          .map((line, i) => (
            <tspan key={i} x="0" dy={`${i * 1.2}em`}>
              {line}
            </tspan>
          ))}
      </text>
    </g>
  )
}

function weekTooltipLabel(val) {
  if (val === 0) {
    return 'First Assessment'
  } else if (val === 40) {
    return 'Discharge'
  } else {
    return `${val} Weeks`
  }
}

function TooltipContent({ payload }) {
  if (payload && payload.length) {
    const [data] = payload
    if (!data) return null

    return (
      <Box
        sx={{
          background: 'white',
          padding: 'small',
          border: '1px solid',
          borderColor: 'light_gray'
        }}
      >
        <Box>
          <Text>
            Treatment response at{' '}
            <Text as="span" fontWeight="bold">
              {weekTooltipLabel(data.payload.week)}:
            </Text>
          </Text>
        </Box>
        <Box mb="xsmall">
          <Text color="primary" fontSize="lg" fontWeight="bold">
            {getEffectSizeLabel(data.payload.effect_size.mid)}
          </Text>
        </Box>
        <Box>
          <Text>{`Avg. Score: ${data.payload.effect_size.avg_score ||
            0}`}</Text>
          <Text>{`Effect size: ${data.payload.effect_size.mid}`}</Text>
          <Text>{`Low: ${data.payload.effect_size.low} | High: ${data.payload.effect_size.high}`}</Text>
        </Box>
        <Box mt="xsmall">
          <Text fontWeight="bold">{`* Based on ${data.payload.effect_size
            .count || 0} clients`}</Text>
        </Box>
      </Box>
    )
  }

  return null
}

function EmptyState({ selectedOutcome }) {
  return (
    <Flex
      sx={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        zIndex: 10,
        filter: 'blur(0px)'
      }}
    >
      <Box
        sx={{
          background: 'white',
          padding: 'medium',
          border: '1px solid',
          borderColor: 'light_gray'
        }}
      >
        <VStack spacing="xsmall">
          <Text>
            {selectedOutcome.name}: {selectedOutcome.disorder}
          </Text>
          <Text color="primary" fontSize="lg" fontWeight="bold">
            Not enough assessment data yet
          </Text>
          <Text>
            <Text fontWeight="bold" as="span">
              Tip:{' '}
            </Text>{' '}
            For the most accurate reporting, make sure to discharge clients in
            Blueprint with the reason “Ended Treatment Services” at discharge.
            Otherwise, that data will be excluded from this report.
          </Text>
        </VStack>
      </Box>
    </Flex>
  )
}

function LoadingState() {
  return (
    <Flex
      sx={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        zIndex: 10,
        filter: 'blur(0px)'
      }}
    >
      <Loading />
    </Flex>
  )
}

export const OutcomesChart = ({
  width,
  height,
  data,
  selectedOutcome,
  isLoading
}) => {
  // If only week 0 is available, then it's empty
  const isEmptyState = useMemo(() => data.length <= 1, [data])

  return (
    <Flex
      sx={{
        width,
        height,
        position: 'relative',
        '>*': {
          filter: isEmptyState || isLoading ? 'blur(3px)' : 'none',
          transition: 'all 250ms'
        }
      }}
    >
      {isLoading ? (
        <LoadingState />
      ) : (
        isEmptyState && <EmptyState selectedOutcome={selectedOutcome} />
      )}
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={isEmptyState ? [] : data}
          margin={{
            top: 28,
            right: 30,
            left: 14,
            bottom: 30
          }}
        >
          <XAxis
            interval={0}
            dataKey="week"
            ticks={[4, 12, 20, 40]}
            tickCount={4}
            domain={[0, 40]}
            allowDataOverflow={false}
            label={{
              value: 'Weeks from Intake',
              position: 'bottom',
              offset: 5
            }}
            tickFormatter={i => (i === 40 ? 'Discharge' : i)}
          />
          <YAxis
            type="number"
            ticks={[0, 20, 50, 80, 100]}
            allowDataOverflow={false}
            tick={<CustomizedYAxisTick />}
            domain={[0, 100]}
          />
          <Tooltip content={<TooltipContent />} />
          <Line
            animationDuration={1000}
            name={'Effect size'}
            type="monotone"
            dataKey="effect_size.normalized_mid"
            stroke="#2D54E8"
            strokeWidth={2}
            dot={{ fill: '#2D54E8' }}
          >
            <ErrorBar dataKey="error" stroke={'#2D54E8'} direction={'y'} />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </Flex>
  )
}
