import React from 'react'
import { VStack, Stack, Text, Button } from '@chakra-ui/react'
import { CoolKidsLivingRoom } from '@handlers/sessions/components/in-session-assist-panel/svgs'
import { Assist } from '@blueprinthq/joy'

type TreatmentPlanGateProps = {
  treatmentPlan: null | { fileKey?: string }
  canGenerateQuery: { isLoading: boolean; data?: { canGenerate: boolean } }
  generateMutation: { isLoading: boolean; mutate: () => void }
}

const TreatmentPlanGate: React.FC<TreatmentPlanGateProps> = ({
  treatmentPlan,
  canGenerateQuery,
  generateMutation
}) => {
  if (!treatmentPlan) {
    const isDisabled =
      canGenerateQuery.isLoading || !canGenerateQuery?.data?.canGenerate

    return (
      <VStack spacing="0" textAlign="center" gap="32px">
        <CoolKidsLivingRoom />
        <Stack spacing="0">
          <Text fontWeight="bold">No treatment plan</Text>
          <Text color="dark_gray">A treatment plan is required for Assist</Text>
        </Stack>
        <Button
          bg="primary"
          leftIcon={<Assist size="sm" fill="white" />}
          isDisabled={isDisabled}
          isLoading={generateMutation.isLoading}
          onClick={generateMutation.mutate}
        >
          Generate treatment plan
        </Button>
      </VStack>
    )
  }

  if (treatmentPlan && treatmentPlan.fileKey) {
    return (
      <VStack spacing="0" textAlign="center" gap="32px">
        <CoolKidsLivingRoom />
        <Stack spacing="0">
          <Text fontWeight="bold">Uploaded treatment plan not supported</Text>
          <Text color="dark_gray">
            Assist requires a Blueprint generated treatment plan
          </Text>
        </Stack>
      </VStack>
    )
  }

  return null
}

export default TreatmentPlanGate
