import React from 'react'
import { DialogContentText } from "@material-ui/core";

import { CircleCheck } from "../../../components/icons";
import { PrimaryButton } from "../../../components";
import { useStyles } from '../style'


export const TransferPatientModalSuccess = ({ displaySuccess, goBack }) => {
  const classes = useStyles()
  return (
    <>
      <CircleCheck className={classes.icon} fill="#44CD7D" />
      <DialogContentText className={classes.centerText}>
        {displaySuccess}
      </DialogContentText>
      <PrimaryButton
        fullWidth
        onClick={goBack}
        round
        className={classes.button}
      >
        Take me back to my client list
      </PrimaryButton>
    </>
  )
}
