import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Text
} from '@chakra-ui/react'
import {
  AccountIcon,
  ArrowDownIcon,
  PersonAddIcon,
  SearchIcon
} from '@blueprinthq/joy'
import { ClientSearchResult, endpoints } from '@api'
import { useQuery } from 'react-query'
import { FlagsmithFeatures } from '@constants/flagsmith'
import flagsmith from 'flagsmith'
import { throttle } from 'lodash'
import { trackEvent } from '@lib/clinician-tracking'
import { SelectClientDropdownItem } from './select-client-dropdown-item'

export interface SelectedClient {
  id: string
  firstName: string
  lastName: string
}

interface SelectClientDropdownProps {
  allClientsList: ClientSearchResult[] | undefined
  setIsAddClientModalOpen: Function
  clinicianId: string
  selectedClient: SelectedClient | undefined
  onChange: (clientId: string) => void
  errorMessage?: string
}

export const SelectClientDropdown = ({
  setIsAddClientModalOpen,
  clinicianId,
  allClientsList,
  selectedClient,
  onChange,
  errorMessage
}: SelectClientDropdownProps) => {
  const [searchInput, setSearchInput] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchInput(value)
  }

  let {
    data: preferredClientList,
    isLoading: isPreferredClientListLoading
  } = useQuery(
    [endpoints.getPreferredClientList.getCacheId()],
    () => endpoints.getPreferredClientList.request({ clinicianId }),
    {
      enabled: !!clinicianId
    }
  )
  const isInternalDemoOrg = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_IS_DEMO_CLIENTS_IN_START_SESSION
  )

  const {
    refetch,
    data: searchResults,
    isLoading: isSearchLoading
  } = useQuery(
    [
      endpoints.getPatientSearch.getCacheId(),
      'session_client_search',
      searchInput
    ],
    () =>
      endpoints.getPatientSearch.request({
        search: searchInput!,
        include_discharged: false
      }),
    {
      enabled: searchInput !== null,
      select: data => data.filter(n => !n.is_demo || isInternalDemoOrg)
    }
  )

  const handleClientSelect = (clientId: string) => {
    onChange(clientId)
    setIsOpen(false)
  }

  const onBlur = () => {
    setIsOpen(false)
    setSearchInput(
      selectedClient
        ? `${selectedClient.firstName} ${selectedClient.lastName}`
        : null
    )
  }

  useEffect(() => {
    setSearchInput(
      selectedClient
        ? `${selectedClient.firstName} ${selectedClient.lastName}`
        : null
    )
  }, [selectedClient])

  useEffect(() => {
    if (searchInput && searchInput.length >= 1) {
      throttledSearch()
    }
  }, [searchInput])

  const throttledSearch = throttle(() => refetch(), 150)

  const hasSearchResults = searchResults && searchResults.length > 0

  return (
    <Box position="relative" width="100%" ref={dropdownRef}>
      <InputGroup
        cursor="pointer"
        color="black"
        onClick={() => setIsOpen(true)}
      >
        {selectedClient && !isOpen ? (
          <InputLeftElement ml="4px">
            <AccountIcon color="gray.300" />
          </InputLeftElement>
        ) : (
          <InputLeftElement ml="4px">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
        )}
        <Input
          placeholder="Select or add your client"
          value={searchInput || ''}
          borderRadius="8px"
          bg="white"
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onBlur={onBlur}
          borderColor={errorMessage ? '#EB5164' : '#e4e5e6'}
          _hover={{
            borderColor: errorMessage ? '#EB5164' : '#e4e5e6'
          }}
          _focus={{
            borderColor: errorMessage ? '#EB5164' : '#2D54E8'
          }}
          _placeholder={{
            color: '#757575'
          }}
        />
        <InputRightElement mr="5px">
          <ArrowDownIcon color="gray.300" />
        </InputRightElement>
      </InputGroup>
      {errorMessage && (
        <Text
          color="#EB5164"
          fontSize="14px"
          mt="4px"
          ml="4px"
          textAlign="left"
        >
          {errorMessage}
        </Text>
      )}
      {isOpen && (
        <Box
          position="absolute"
          bg="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          mt="2"
          width="100%"
          maxHeight="300px"
          overflowY="auto"
          zIndex="1"
          boxShadow="0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A"
        >
          <List spacing={1}>
            <ListItem
              color="black"
              px="8px"
              py="8px"
              _hover={{ bg: 'gray.100', cursor: 'pointer' }}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              height="48px"
              onMouseDown={() => setIsAddClientModalOpen(true)}
            >
              <PersonAddIcon fill="#2D54E8" marginTop="6px" marginRight="8px" />
              <Text color="#2D54E8" fontSize="16px">
                Add Client
              </Text>
            </ListItem>
            {searchInput?.length && !hasSearchResults && (
              <ListItem px="16px" py="16px">
                <Text fontSize="16px" color="#282828" textAlign="left">
                  No results found
                </Text>
              </ListItem>
            )}
            {searchInput?.length && hasSearchResults && (
              <>
                <ListItem px="16px" pt="8px">
                  <Text fontSize="12px" color="gray.600" textAlign="left">
                    Search Results
                  </Text>
                </ListItem>
                {searchResults.map((client, index) => (
                  <SelectClientDropdownItem
                    key={`search-result-${client.id}`}
                    firstName={client.first_name}
                    lastName={client.last_name}
                    clientId={client.id}
                    onSelect={handleClientSelect}
                    trackingLabel="Search Results"
                    isDemo={client.is_demo}
                  />
                ))}
              </>
            )}
            {!searchInput?.length &&
              preferredClientList &&
              preferredClientList.clientsWithSessionLastWeek?.length > 0 && (
                <>
                  <ListItem
                    px="16px"
                    pt="8px"
                    borderTop="1px solid"
                    borderColor="#E4E5E6"
                  >
                    <Text fontSize="12px" color="gray.600" textAlign="left">
                      Clients you met with last week
                    </Text>
                  </ListItem>
                  {preferredClientList?.clientsWithSessionLastWeek.map(
                    (client, index) => (
                      <SelectClientDropdownItem
                        key={`last-week-${client.id}`}
                        firstName={client.firstName}
                        lastName={client.lastName}
                        clientId={client.id}
                        onSelect={handleClientSelect}
                        trackingLabel="Session Last Week"
                        isDemo={client.isDemo}
                      />
                    )
                  )}
                </>
              )}
            {!searchInput?.length &&
              allClientsList &&
              allClientsList.length > 0 && (
                <>
                  <ListItem
                    px="16px"
                    pt="8px"
                    borderTop="1px solid"
                    borderColor="#E4E5E6"
                  >
                    <Text fontSize="12px" color="gray.600" textAlign="left">
                      All Clients
                    </Text>
                  </ListItem>
                  {allClientsList.map((client, index) => (
                    <SelectClientDropdownItem
                      key={`all-clients-${client.id}`}
                      firstName={client.firstName}
                      lastName={client.lastName}
                      clientId={client.id}
                      onSelect={handleClientSelect}
                      trackingLabel="All Clients"
                      isDemo={client.isDemo}
                    />
                  ))}
                </>
              )}
          </List>
        </Box>
      )}
    </Box>
  )
}
