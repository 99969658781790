import React, { useState, useCallback } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import {
  Box,
  RadioGroup,
  Radio,
  Stack,
  Heading,
  Text,
  Divider,
  Checkbox,
  Tooltip
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { isMobile } from 'react-device-detect'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useStoreActions } from 'easy-peasy'
import { Loading, AlertDialog } from '@components'
import { Warning } from '@components/icons'
import { endpoints } from '@api'
import { useLocation, useHistory } from 'react-router-dom'
import { useStyles } from '../styles'
import { useExperienceManager } from '@hooks'
import { getPatientControllerGetAssignedCheckInsQueryKey } from '~/clinician-api'

const ArchivePatientModal = ({ patient, isOpen, handleCloseModal }) => {
  const queryClient = useQueryClient()
  const [selectedReason, setSelectedReason] = useState('')
  const [sendEndOfCareAssessments, setSendEndOfCareAssessments] = useState(true)
  const [errorMsg, setErrorMsg] = useState(null)
  const classes = useStyles()
  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  const location = useLocation()
  const history = useHistory()

  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const { isLoading: isArchiveReasonsLoading, data } = useQuery(
    endpoints.getClinicianArchiveReasons.getCacheId(),
    endpoints.getClinicianArchiveReasons.request,
    {
      enabled: !!patient,
      placeholderData: []
    }
  )

  const resetAndCloseModal = useCallback(() => {
    handleCloseModal()
    // Avoid user from seeing value being changed well closing modal
    setTimeout(() => {
      setSelectedReason('')
      setErrorMsg('')
    }, 150)
  })

  const {
    mutate: executeArchivePatient,
    isLoading: isArchiveLoading
  } = useMutation(endpoints.postCliniciansUsersArchive.request, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(endpoints.getPatientList.getCacheId()),
        queryClient.invalidateQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          patient.id
        ]),
        queryClient.invalidateQueries(
          endpoints.getClinicianUserActiveAssessments.getCacheId()
        ),
        queryClient.invalidateQueries(
          endpoints.getCheckInModulesForUser.getCacheId()
        ),
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        ),
        queryClient.invalidateQueries(
          endpoints.getUnreadNotificationCount.getCacheId()
        ),
        queryClient.invalidateQueries(
          getPatientControllerGetAssignedCheckInsQueryKey(patient.id)
        )
      ])
      queryClient.invalidateQueries([
        endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
        patient.id
      ])

      resetAndCloseModal()
      await queryClient.invalidateQueries(
        endpoints.getClinicianUserAccount.getCacheId()
      )

      const message = isEvidenceBasedCareEnabled
        ? `${patient.first_name} ${patient.last_name} was successfully discharged!`
        : `${patient.first_name} ${patient.last_name} was successfully archived!`
      setSnackbarMessage({
        variant: 'success',
        message
      })

      if (location.pathname.includes('pre-enroll-patient')) {
        history.replace(
          location.pathname.replace('pre-enroll-patient', 'patient')
        )
      }
    },
    onError: () =>
      setErrorMsg(
        'Oops, something went wrong. Please refresh the page and try again.'
      )
  })

  const handleSelectReason = useCallback(reason => setSelectedReason(reason), [
    setSelectedReason
  ])

  const submit = useCallback(() => {
    executeArchivePatient({
      clientId: patient.id,
      data: {
        archive_reason: selectedReason,
        send_end_of_care_assessments:
          isEvidenceBasedCareEnabled && sendEndOfCareAssessments
      }
    })
  }, [
    patient,
    selectedReason,
    executeArchivePatient,
    sendEndOfCareAssessments,
    isEvidenceBasedCareEnabled
  ])

  return isEvidenceBasedCareEnabled ? (
    <Dialog
      open={isOpen}
      onClose={resetAndCloseModal}
      maxWidth="xs"
      fullWidth={true}
      fullScreen={isMobile}
    >
      {isArchiveReasonsLoading ? (
        <div style={{ height: '256px' }}>
          <Loading />
        </div>
      ) : (
        <div className={classes.dialog}>
          <DialogTitle>
            <Heading className={classes.centerText}>Discharge Client?</Heading>
          </DialogTitle>
          <DialogContent className="content">
            <Text align="center">
              Are you sure you want to discharge this client?
            </Text>
            {errorMsg && <p className="errorMessage">{errorMsg}</p>}
            <Heading size="sm" mt="5" mb="3">
              Select a reason:
            </Heading>
            <RadioGroup
              value={selectedReason}
              onChange={value => handleSelectReason(value)}
            >
              <Stack>
                {data.map(reason => (
                  <Radio key={reason} value={reason}>
                    {reason}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
            {isEvidenceBasedCareEnabled && (
              <>
                <Divider my="3" />
                <Box display="flex" alignItems="center">
                  <Checkbox
                    isChecked={sendEndOfCareAssessments}
                    onChange={e =>
                      setSendEndOfCareAssessments(e.target.checked)
                    }
                  >
                    Send end of care assessments to client
                  </Checkbox>
                  <Tooltip
                    label="When a client is discharged, they will be asked to complete their assigned assessments one last time along with a 1-question satisfaction with care survey."
                    maxWidth="300px"
                    placement="bottom-start"
                    hasArrow
                  >
                    <Box ml="2">
                      <Warning width="20px" height="20px" fill="black" />
                    </Box>
                  </Tooltip>
                </Box>
              </>
            )}
            <div className={classes.buttonContainer}>
              <Button
                onClick={resetAndCloseModal}
                size="lg"
                variant="outline"
                width="100%"
              >
                Cancel
              </Button>
              <Button
                isLoading={isArchiveLoading}
                size="lg"
                onClick={submit}
                disabled={!selectedReason}
                bg="severe"
                width="100%"
              >
                Discharge
              </Button>
            </div>
          </DialogContent>
        </div>
      )}
    </Dialog>
  ) : (
    <AlertDialog
      header="Archive this client?"
      isOpen={isOpen}
      onClose={handleCloseModal}
      onConfirm={submit}
      text={
        <>
          Archiving a client removes them from the list of active clients, but
          keeps their data intact and allows you to restore them at any time.
          <br />
          <br />
          Archiving a client can be useful when you no longer see a client but
          want to keep their files and information
        </>
      }
      isConfirming={isArchiveLoading}
      confirmText="Archive client"
    />
  )
}

export default ArchivePatientModal
