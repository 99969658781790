import React, { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { endpoints } from '@api'
import { TertiaryButton } from '@components'
import config from '../../../../../../../../config'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  blurSection: {
    height: ({ sectionName }) =>
      sectionName === 'lifestyle' || sectionName === 'outcomeMeasures'
        ? 350
        : 200,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'top',
    backgroundImage: ({ sectionName }) =>
      `url(${config.blurredImageURLs[sectionName]})`
  },
  inviteText: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
    width: 300,
    lineHeight: 1.2
  }
})

export default function BlurSection(props) {
  const { patient, copy, action, buttonTitle } = props
  const classes = useStyles(props)
  const toast = useToast()

  const { mutateAsync: executeReinvite } = useMutation(
    endpoints.postReinviteToDownload.request,
    {
      onSuccess: () =>
        toast({
          status: 'success',
          description: 'Invitation was sent!'
        }),
      onError: () =>
        toast({
          status: 'error',
          description: 'Invitation was not sent.'
        })
    }
  )

  const sendPatientDownloadInvite = useCallback(async () => {
    await executeReinvite({ userId: patient.id })
  }, [patient])

  return (
    <div className={classes.blurSection}>
      <p className={classes.inviteText}>
        {copy || `${patient.first_name} has not completed any check-in's`}
      </p>
      {(patient.email || patient.phone_number) && (
        <Button
          variant="outline"
          bg="white"
          onClick={() => sendPatientDownloadInvite()}
        >
          Resend Invite
        </Button>
      )}
    </div>
  )
}
