import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'

export const NewActionNode = () => {
  const body = <Text>Add a Workflow Action.</Text>

  return (
    <WorkflowNode
      heading={`Workflow Action`}
      body={body}
      hasChildren={false}
      isParent={false}
      isSelected={true}
      onClick={() => {}}
      isNew
    />
  )
}
