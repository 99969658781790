import React from 'react'
import { Flex, Stack, Text, Button } from '@chakra-ui/react'
import { InfoIcon, CheckIcon } from '@blueprinthq/joy'
import { PlusFreeTrialStatus } from './plus-free-trial-status'
import { Tag } from '@components'

interface PlanCardProps {
  title: string
  price: string
  features: string[]
  buttonText: string
  buttonAction: () => void
  isCurrentPlan?: boolean
  priceEmbelishment?: string
  hasPlusPlanFreeTrial?: boolean
  buttonVariant?: string
  showCurrentTrial?: boolean
  isDisabled?: boolean
}

const PlanCard: React.FC<PlanCardProps> = ({
  title,
  price,
  priceEmbelishment,
  features,
  buttonText,
  buttonVariant = 'solid',
  buttonAction,
  hasPlusPlanFreeTrial,
  isCurrentPlan,
  showCurrentTrial,
  isDisabled
}) => {
  return (
    <Flex
      border="1px solid"
      flexDirection="column"
      borderRadius="8px"
      borderColor="pale_gray"
      gap="24px"
      p={6}
      flex="1"
      minH="461px"
      opacity={isDisabled ? 0.6 : 1}
      cursor={isDisabled ? 'not-allowed' : 'auto'}
    >
      <Stack flex="1" spacing="0" gap="16px">
        <Flex gap="8px" alignItems="center">
          <Text fontWeight="bold">{title}</Text>
          {isCurrentPlan && <Tag>Your Current Plan</Tag>}
          {showCurrentTrial && <Tag>Current Trial</Tag>}
        </Flex>
        <Stack spacing="0">
          <Text color="dark_gray">{priceEmbelishment}</Text>
          <Text color="primary" fontWeight="bold" fontSize="24px">
            {price}
          </Text>
        </Stack>
        <Stack spacing="0" gap="16px">
          <Text fontSize="14px">
            <Text as="b" fontSize="inherit">
              {title}
            </Text>{' '}
            plan includes:
          </Text>
          <Stack spacing="0" gap="8px">
            {features.map((feature, index) => (
              <Flex key={index} alignItems="center" justifyContent="space-between">
                <Flex gap="8px">
                  <InfoIcon fill="medium_gray" size="sm" />
                  <Text fontSize="14px" lineHeight="16px">
                    {feature}
                  </Text>
                </Flex>
                {isCurrentPlan && <CheckIcon fill="primary" size="sm" />}
              </Flex>
            ))}
          </Stack>
        </Stack>
        {isCurrentPlan && hasPlusPlanFreeTrial && <PlusFreeTrialStatus />}
      </Stack>
      <Button
        m="0"
        size="md"
        isFullWidth
        onClick={!isDisabled ? buttonAction : undefined}
        variant={buttonVariant}
        borderColor="pale_gray"
        isDisabled={isDisabled}
      >
        {isDisabled ? 'Coming Soon' : buttonText}
      </Button>
    </Flex>
  )
}

export default PlanCard
