import React from 'react'
import {
  VStack,
  Box,
  Text,
  Divider,
  Button,
  Heading,
  Flex
} from '@chakra-ui/react'
import { useHistory, Link, useRouteMatch } from 'react-router-dom'
import { DateTime } from 'luxon'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { useExperienceManager } from '@hooks'
import {
  usePatientControllerGetSessionList,
  useSessionControllerPostSession
} from '~/clinician-api'
import { SessionListItem } from '@components'
import moment from 'moment'

export function SessionList(props: { patient: { is_archived: boolean } }) {
  const match: any = useRouteMatch('/patient/:clientId/settings/sessions')
  const { patient } = props
  const {
    data: sessionList,
    isLoading: isSessionListLoading,
    refetch: refetchSessionList
  } = usePatientControllerGetSessionList(match.params.clientId, {
    query: {
      initialData: []
    }
  })

  const history = useHistory()
  const { isExtension, isPlusPlanEnabled } = useExperienceManager()

  const isChimeDisabled = flagsmith.hasFeature(FlagsmithFeatures.CHIME_DISABLED)
  const isDeleteSessionsEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.DELETE_SESSIONS
  )

  const { mutateAsync: createSessionDraft } = useSessionControllerPostSession()

  const onStartSessionClick = async () => {
    const sessionDraft = await createSessionDraft({
      data: {
        patientId: match.params.clientId,
        isChime: !isChimeDisabled,
        isExtension,
        extensionInitiatedFrom: isExtension ? document.referrer : null,
        resumeInProgress: true,
        isDictation: false,
        isAssistEnabled: isPlusPlanEnabled
      }
    })
    history.push(`/patient/${match.params.clientId}/session/${sessionDraft.id}`)
  }

  return (
    <Box w="100%" id="pendo-session-list-container-tag">
      <Box mb="small">
        <Heading>Sessions</Heading>
      </Box>
      <Box
        border="solid 1px"
        borderColor="pale_gray"
        w="100%"
        borderRadius="8px"
        overflow="hidden"
      >
        {!isSessionListLoading && sessionList?.length === 0 ? (
          <Flex align="center" justify="center" height="250px">
            <Flex align="center" direction="column">
              <Text fontWeight="bold" mt="xsmall" mb="xsmall">
                No sessions yet
              </Text>
              {!patient.is_archived && (
                <>
                  <Text
                    align="center"
                    maxWidth="350px"
                    whiteSpace="break-spaces"
                  >
                    Ready to run a great therapy session and save time on
                    writing progress notes?
                  </Text>
                  <Button
                    mt="medium"
                    id="pendo-start-session-tag"
                    paddingLeft="37.5px"
                    paddingRight="37.5px"
                    _focus={{
                      boxShadow: 'none'
                    }}
                    onClick={onStartSessionClick}
                  >
                    New Session
                  </Button>
                </>
              )}
            </Flex>
          </Flex>
        ) : (
          <VStack w="100%" divider={<Divider />} spacing={0}>
            {sessionList?.map(session => {
              return isDeleteSessionsEnabled ? (
                <SessionListItem
                  key={`session-list-${session.id}`}
                  sessionId={session.id}
                  patientId={session.patientId}
                  header={DateTime.fromISO(session.date).toFormat('LLL dd')}
                  startedAt={moment(
                    session.recordingStartedAt || session.startedAt
                  )
                    .local()
                    .format()}
                  completedAt={moment(session.completedAt)
                    .local()
                    .format()}
                  isUpload={session.isUpload}
                  clinicianName={session.clinicianFullName}
                  inProgress={session.isRecordingInProgress}
                  isDictation={session.isDictation}
                  onDelete={() => refetchSessionList()}
                />
              ) : (
                <Box key={`session-list-${session.id}`} w="100%">
                  <Box
                    p="medium"
                    w="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Text fontWeight="bold">
                        {DateTime.fromISO(session.date).toFormat('LLL dd')}
                      </Text>
                      <Text>{session.clinicianFullName}</Text>
                    </Box>
                    <Box>
                      {/* @ts-ignore */}
                      {session.isRecordingInProgress ? (
                        <Button
                          as={Link}
                          w="177px"
                          to={
                            // @ts-ignore
                            session.isDictation
                              ? `/patient/${session.patientId}/dictate/session/${session.id}`
                              : `/patient/${session.patientId}/session/${session.id}`
                          }
                        >
                          {/* @ts-ignore */}
                          {session.isDictation
                            ? 'Resume Dictation'
                            : 'Resume Session'}
                        </Button>
                      ) : (
                        <Button
                          variant="outline"
                          as={Link}
                          w="177px"
                          to={`/patient/${session.patientId}/completed-session/${session.id}`}
                        >
                          View Progress Note
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </VStack>
        )}
      </Box>
    </Box>
  )
}
