import React, { useState, useEffect } from 'react'
import { Stack, Text, HStack, Button, useDisclosure } from '@chakra-ui/react'
import AssignedAssessments from '@handlers/sessions/components/in-session-assist-panel/assessments/assigned/selector'
import Progress from '@handlers/sessions/components/in-session-assist-panel/assessments/progress'
import Results from '@handlers/sessions/components/in-session-assist-panel/assessments/results'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import { useExperienceManager } from '@hooks'
import { AssignAssessmentsModal } from '@handlers/sessions/components/assist-panel/assign-assessments-modal'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { AssignableAssessment } from '@handlers/sessions/types'

const ResultsModal = ({
  client,
  isOpen,
  onClose,
  assessmentId,
  assessmentScoreId
}: any) => {
  return (
    <DialogContainer isOpen={isOpen} onClose={onClose} size="6xl">
      <DialogHeader text="Assessment Results" onClose={onClose} />
      <DialogBody p={0}>
        <Results
          client={client}
          preselectedAssessmentId={assessmentId}
          preselectedAssessmentScoreId={assessmentScoreId}
        />
      </DialogBody>
    </DialogContainer>
  )
}

const AssistAssessments = ({ patient: client }: { patient: any }) => {
  const [
    selectedAssessment,
    setSelectedAssessment
  ] = useState<AssignableAssessment | null>(null)
  const { isPlusPlanEnabled } = useExperienceManager()
  const patientId = client.id
  const { isOpen, onClose, onOpen } = useDisclosure()

  const {
    isOpen: isAssignAssessmentsModalOpen,
    onOpen: openAssignAssessmentsModal,
    onClose: closeAssignAssessmentsModal
  } = useDisclosure()

  useEffect(() => {
    if (!isAssignAssessmentsModalOpen) {
      setSelectedAssessment(null)
    }
  }, [isAssignAssessmentsModalOpen])

  const { isLoading, data: assessmentScores } = useQuery(
    [endpoints.getPatientAssessmentScores.getCacheId(), patientId],
    () =>
      endpoints.getPatientAssessmentScores.request({
        patientId,
        includeFreeTextQuestions: true
      })
  )

  const handleViewResults = (data = {}) => {
    onOpen()
  }

  const handleBrowseLibraryClick = () => {
    openAssignAssessmentsModal()
  }

  return (
    <Stack px="24px" spacing="0" flex="1" pt="24px" gap="24px">
      <Progress
        isLoading={isLoading}
        allAssessmentScores={assessmentScores}
        patient={client}
        onViewResults={handleViewResults}
      />
      <Stack spacing="0">
        <HStack justifyContent="space-between">
          <Text fontWeight="bold">Assigned</Text>
          {isPlusPlanEnabled && !client.is_archived && (
            <Button
              variant="link"
              textDecoration="none"
              onClick={handleBrowseLibraryClick}
            >
              Browse library
            </Button>
          )}
        </HStack>
        <AssignedAssessments patient={client} user_id={client.id} />
      </Stack>
      <ResultsModal client={client} isOpen={isOpen} onClose={onClose} />
      {isAssignAssessmentsModalOpen && (
        <AssignAssessmentsModal
          client={client}
          isOpen={isAssignAssessmentsModalOpen}
          onClose={closeAssignAssessmentsModal}
          assessment={selectedAssessment}
          selectAssessment={setSelectedAssessment}
        />
      )}
    </Stack>
  )
}

export default AssistAssessments
