import React, { useState } from 'react'
import { Flex, Box, Image } from '@chakra-ui/react'
import { CloseIcon } from '@blueprinthq/joy'
import { useStoreState } from 'easy-peasy'

import WhiteLogo from '../../../images/brand/white_logo.svg'
import { MinimizeIcon, ExpandIcon, BIcon } from '@icons'
import { useExperienceManager, useAudioCapture } from '@hooks'
import { Mic, MicOff, HamburgerIcon, ArrowBack } from '@components/icons'
import { useHistory } from 'react-router-dom'
import { useExtension } from '@hooks'
import { StoreModel } from 'src/store/types'

const MinimizedNavBar = () => {
  const { toggleIsExtensionMinimized } = useExperienceManager()
  const { isMuted, toggleMute } = useAudioCapture()
  const { audioLevel } = useStoreState((state: StoreModel) => state.volume)
  const [isDragging, setIsDragging] = useState(false)
  const [initialCursorPosition, setInitialCursorPosition] = useState({
    x: 0,
    y: 0
  })

  return (
    <Flex
      height="100%"
      justifyContent="space-between"
      alignItems="center"
      m="0 20px"
    >
      <Box
        id="bp-copilot-header"
        _hover={{ cursor: 'move' }}
        onMouseDown={e => {
          setIsDragging(true)
          setInitialCursorPosition({ x: e.clientX, y: e.clientY })
        }}
        onMouseUp={() => setIsDragging(false)}
        onMouseMove={e => {
          if (isDragging) {
            // Calculate the distance the cursor has moved
            const differenceX = e.clientX - initialCursorPosition.x
            const differenceY = e.clientY - initialCursorPosition.y

            // eslint-disable-next-line
            parent.postMessage(
              {
                type: 'BP_DRAG',
                differenceX,
                differenceY
              },
              '*'
            )
          }
        }}
      >
        <BIcon />
      </Box>
      <Flex
        backgroundColor="white"
        borderRadius="50px"
        width="40px"
        height="40px"
        justifyContent="center"
        alignItems="center"
        _hover={{ cursor: 'pointer' }}
        onClick={toggleMute}
      >
        {isMuted ? <MicOff /> : <Mic dy={1 - audioLevel} />}
      </Flex>
      <Box
        _hover={{ cursor: 'pointer' }}
        onClick={() => toggleIsExtensionMinimized()}
      >
        <ExpandIcon />
      </Box>
    </Flex>
  )
}

export const NavBar = ({
  hasStartedRecording = false,
  showBackButton = false,
  showMenuButton = false,
  onMenuButtonClicked = () => {}
}: {
  hasStartedRecording?: boolean
  onSessionEnd?: () => void
  showBackButton?: boolean
  showMenuButton?: boolean
  onMenuButtonClicked?: () => void
}) => {
  const history = useHistory()
  const { settings } = useExtension()
  const {
    isExtensionMinimized,
    isExtension,
    toggleIsExtensionMinimized
  } = useExperienceManager()

  const [isDragging, setIsDragging] = useState(false)
  const [initialCursorPosition, setInitialCursorPosition] = useState({
    x: 0,
    y: 0
  })

  if (isExtensionMinimized) {
    return <MinimizedNavBar />
  }

  return (
    <Flex
      id="bp-copilot-header"
      justifyContent="space-between"
      alignItems="center"
      p={'16px 12px'}
      position="sticky"
      top="0"
      backgroundColor="primary"
      zIndex="999"
      _hover={settings?.isDraggable ? { cursor: 'move' } : {}}
      onMouseDown={e => {
        setIsDragging(true)
        setInitialCursorPosition({ x: e.clientX, y: e.clientY })
      }}
      onMouseUp={() => setIsDragging(false)}
      onMouseMove={e => {
        if (isDragging) {
          // Calculate the distance the cursor has moved
          const differenceX = e.clientX - initialCursorPosition.x
          const differenceY = e.clientY - initialCursorPosition.y

          // eslint-disable-next-line
          parent.postMessage(
            {
              type: 'BP_DRAG',
              differenceX,
              differenceY
            },
            '*'
          )
        }
      }}
    >
      <Box
        w="24px"
        onClick={() => {
          if (showBackButton) {
            history.push('/extension/start-session')
          } else {
            onMenuButtonClicked()
          }
        }}
        _hover={{ cursor: 'pointer' }}
      >
        {showBackButton && <ArrowBack />}
        {showMenuButton && <HamburgerIcon />}
      </Box>
      <Image h="22px" src={WhiteLogo} pointerEvents="none" />
      <Flex w="24px" justifyContent="flex-end">
        {hasStartedRecording && (
          <Box
            _hover={{ cursor: 'pointer' }}
            onClick={() => toggleIsExtensionMinimized()}
          >
            <MinimizeIcon />
          </Box>
        )}
        {!hasStartedRecording && (
          <Box
            _hover={{ cursor: 'pointer' }}
            // eslint-disable-next-line
            onClick={() => parent.postMessage({ type: 'BP_CLOSE' }, '*')}
            ml="xxsmall"
            display={settings?.isAlwaysOpen ? 'none' : 'block'}
          >
            <CloseIcon fill="white" />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
