import React from 'react'
import { Box, Select, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { FastField, Field } from 'formik'
import _ from 'lodash'

import { endpoints } from '@api'

const ClinicComponent = ({ formHelpers }) => {
  const { values, errors, touched, resetConflict } = formHelpers

  const { user } = useStoreState(state => state.auth)

  const { data: clinics } = useQuery(
    [endpoints.getOrganizationClinicsV2.getCacheId()],
    () =>
      endpoints.getOrganizationClinicsV2.request({
        organizationId: values.organizationId || user.clinic.organization_id
      }),
    {
      initialData: [],
      select: data =>
        data.map(c => ({
          id: c.id,
          name: c.name,
          displayId: c.display_id
        })),
      enabled: Boolean(user)
    }
  )

  return (
    <Box
      w={{
        base: '180px',
        sm: 'auto'
      }}
    >
      <FormControl isInvalid={!!errors.clinic && touched.clinic}>
        <Field name="clinic">
          {({ field, form }) => (
            <Select
              {...field}
              value={field.value.id}
              onChange={evt => {
                const value = clinics.find(c => c.id === evt.target.value)
                form.setFieldValue('clinic', value)
                // when the clinic is changed, a new clinician must be selected
                form.setFieldValue('clinician', null)
                resetConflict('clinic_id')
              }}
              borderColor="#C9C9C9"
              width="244px"
              maxWidth="244px"
              minHeight="48px"
            >
              {clinics.map(option => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Select>
          )}
        </Field>
        <FormErrorMessage>Must select clinic</FormErrorMessage>
      </FormControl>
    </Box>
  )
}

export const Clinic = React.memo(ClinicComponent, (props, nextProps) =>
  _.isEqual(props, nextProps)
)
