import React from 'react'
import { Box, Text, Button, Link } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import {
  Flex,
  Heading,
  Tooltip,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import classes from './assessment-list.module.css'
import { buildCheckInPreviewUrl } from '@utilities'

const DataTableListComponent = ({ column, data, label, type, onChange }) => {
  return (
    <>
      {data && data.length > 0 ? (
        <>
          <Box
            w="100%"
            border="1px solid"
            borderColor="#E4E5E6"
            borderRadius="8px"
            mt={'medium'}
            bg="white"
            verticalAlign={'middle'}
          >
            <Table variant="unstyled">
              <Thead>
                <Tr borderBottom={'1px solid #E4E5E6'}>
                  <Th letterSpacing={'none'} className={classes.tablechange}>
                    {' '}
                    <Flex height="100%" alignItems="center">
                      <Heading textTransform={'none'} fontSize="16px">
                        {column}
                      </Heading>
                    </Flex>
                  </Th>
                  <Th letterSpacing={'none'}>
                    <Box height="100%" display="flex" alignItems="center">
                      <Heading textTransform={'none'} fontSize="16px">
                        Questions
                      </Heading>
                    </Box>
                  </Th>
                  <Th letterSpacing={'none'}>
                    <Box height="100%" display="flex" alignItems="center"></Box>
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {data.map((data, index) => {
                  return (
                    <Tr
                      _last={{ borderBottom: 'none' }}
                      borderBottom={'1px solid #E4E5E6'}
                      key={index}
                    >
                      <Td verticalAlign="top" className={classes.tablechange}>
                        <Tooltip label={data.full_name}>
                          <Heading
                            color={'black'}
                            textTransform={'none'}
                            size="small"
                            m={0}
                          >
                            {data.name}
                          </Heading>
                        </Tooltip>
                        <Heading
                          color={'black'}
                          textTransform={'none'}
                          fontWeight={'normal'}
                          size="small"
                          m={0}
                        >
                          {data.title}
                        </Heading>
                      </Td>
                      <Td className={classes.tablechange}>
                        {data.num_questions} Questions
                      </Td>
                      <Td className={classes.tablechange}>
                        <Flex
                          justifyContent="flex-end"
                          width="100%"
                          alignItems="center"
                        >
                          <Link
                            as="a"
                            target="_blank"
                            mr="xsmall"
                            color="primary"
                            textDecoration="underline"
                            href={
                              data.title === 'Lifestyle correlates'
                                ? data.preview_url
                                : buildCheckInPreviewUrl(data.id)
                            }
                          >
                            Preview
                          </Link>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="Options"
                              icon={<MoreHorizIcon />}
                              variant="ghost"
                              _focus={{
                                outline: 'none',
                                outlineStyle: 'none',
                                bgColor: '#E4E5E6'
                              }}
                              _hover={{
                                bgColor: '#E4E5E6',
                                outline: 'none',
                                outlineStyle: 'none'
                              }}
                            />
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  onChange(data.id, false)
                                }}
                              >
                                <Flex
                                  color={'#EB5164'}
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  Unassign
                                </Flex>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Flex>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </Box>
        </>
      ) : (
        <Text
          w="100%"
          p={'16px 24px 16px 24px'}
          border={'1px solid #E4E5E6'}
          borderRadius={'8px'}
          mt={'medium'}
        >
          No assigned {type}
        </Text>
      )}
    </>
  )
}

export const DataTableList = DataTableListComponent
