import React from 'react'
import {
  Select,
  HStack,
  Text,
  Box,
  Flex,
  CloseButton,
  VStack
} from '@chakra-ui/react'
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik'
import { AddCircleIcon } from '@blueprinthq/joy'
import { useParams } from 'react-router-dom'
import { useOrganizationAppointmentTypes } from '../../../../queries'

type Props = {
  resetForm: (values: object) => void
  constants: any
}

type RouteParams = {
  workflowId: string
  organizationId: string
}

type FormikContext = {
  values: {
    appointmentTypes: string[]
    appointmentTypeOperator: string | undefined
  }
  errors: FormikErrors<any>
}

export const AppointmentTypeFilter = ({ resetForm, constants }: Props) => {
  const { values, errors }: FormikContext = useFormikContext()
  const { organizationId } = useParams<RouteParams>()
  const { data, isLoading } = useOrganizationAppointmentTypes(organizationId)

  const options = () =>
    data?.appointmentTypes?.map((at: string) => ({
      display: at,
      value: at
    })) || []

  if (isLoading) return null

  return (
    <HStack spacing="small" align="flex-start" justify="flex-start" w="100%">
      <HStack spacing="xsmall" justify="flex-start" align="center" w="100%">
        <Text w="140px">Appointment type</Text>
        <Field name="appointmentTypeOperator">
          {({ field }: { field: { value: string } }) => (
            <Select
              {...field}
              value={field.value}
              borderColor={
                errors?.appointmentTypeOperator ? 'error' : 'light_gray'
              }
              color={field.value ? 'black' : 'gray'}
              w="90px"
              onChange={e => {
                resetForm({
                  ...values,
                  appointmentTypeOperator: e.target.value
                })
              }}
            >
              <option value="" disabled></option>
              {constants.operators.map(
                (o: { display: string; value: string }) => (
                  <option key={o.value} value={o.value}>
                    {o.display}
                  </option>
                )
              )}
            </Select>
          )}
        </Field>
      </HStack>
      <VStack
        w="100%"
        spacing="small"
        display="flex"
        alignItems={values.appointmentTypes[0] ? 'flex-end' : 'flex-start'}
      >
        <FieldArray
          name="appointmentTypes"
          render={({ push, remove, replace }) => (
            <Box>
              {!Boolean(values.appointmentTypes.length) && (
                <Field name="appointmentTypes.0">
                  {({ field }: { field: { value: string } }) => (
                    <Select
                      {...field}
                      value={field.value}
                      borderColor={
                        errors?.appointmentTypes ? 'error' : 'light_gray'
                      }
                      color={field.value ? 'black' : 'gray'}
                      mt="xsmall"
                    >
                      <option value="" disabled>
                        Choose type
                      </option>
                      {options().map(option => (
                        <option key={option.value} value={option.value}>
                          {option.display}
                        </option>
                      ))}
                    </Select>
                  )}
                </Field>
              )}
              {values?.appointmentTypes?.map((type: string, index: number) => (
                <Flex width="210px">
                  {index > 0 && (
                    <Text mt="xsmall" mr="xsmall">
                      or
                    </Text>
                  )}
                  <Field
                    name={`appointmentTypes.${index}`}
                    key={`appointmentTypes.${index}`}
                  >
                    {({ field }: { field: { value: string } }) => (
                      <Select
                        {...field}
                        borderColor={
                          errors?.appointmentTypes && !type
                            ? 'error'
                            : 'light_gray'
                        }
                        color={field.value ? 'black' : 'gray'}
                        mb="xsmall"
                        w={index > 0 ? '167px' : '190px'}
                      >
                        <option value="" disabled>
                          Choose type
                        </option>
                        {options().map(option => (
                          <option
                            key={option.value}
                            value={option.value}
                            disabled={values.appointmentTypes.includes(
                              option.value
                            )}
                          >
                            {option.display}
                          </option>
                        ))}
                      </Select>
                    )}
                  </Field>
                  <CloseButton
                    ml="5px"
                    alignSelf={'center'}
                    onClick={() => {
                      if (values.appointmentTypes.length === 1) {
                        replace(0, '')
                      } else {
                        remove(index)
                      }
                    }}
                  />
                </Flex>
              ))}
              {Boolean(values?.appointmentTypes[0]) &&
                data &&
                values.appointmentTypes.length <
                  data?.appointmentTypes.length && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => push('')}
                    mt="xsmall"
                    mr="xsmall"
                  >
                    <AddCircleIcon fill="primary" mr="5px" />
                    <Text color="primary">OR</Text>
                  </Box>
                )}
            </Box>
          )}
        />
      </VStack>
    </HStack>
  )
}
