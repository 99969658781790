import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'

export const AssignSymptomTracker = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const { symptomTrackers }: any = config
  const [firstSymptomTracker, ...otherSymptomTrackers] = symptomTrackers

  const body = (
    <Text>
      Assign the <Text as="b">{firstSymptomTracker.title}</Text>{' '}
      {otherSymptomTrackers.map((tracker: { title: string; id: string }) => (
        <Text key={tracker.id}>
          and <Text as="b">{tracker.title}</Text>
        </Text>
      ))}{' '}
      symptom tracker{otherSymptomTrackers.length ? 's' : ''}
    </Text>
  )

  return (
    <WorkflowNode
      heading="Assign Symptom Tracker Action"
      body={body}
      isParent={isParent}
      hasChildren={hasChildren}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
