import React from 'react'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { Flex, Text, Container, GridItem, TableContainer, Table, Thead, Tr, Th, Td, Tbody } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import { RadioButtonLoadingComplete, WarningFilled } from '@components/icons'
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import { FlagsmithFeatures } from '@constants/flagsmith'
import flagsmith from 'flagsmith'

const AuditQuestions = [
  { title: 'Symptoms', key: 'clinical_symptoms' },
  { title: 'Interventions', key: 'clinical_interventions' },
  { title: 'Intervention Response', key: 'client_response_to_interventions'},
  { title: 'Impairment', key: 'functional_impairment'},
  { title: 'Goal Relevance', key: 'goal_relevance'},
  { title: 'Goal Progress', key: 'progress_towards_goal'},
  { title: 'Risk Addressed', key: 'risk_addressed'},
  { title: 'Treatment Goal', key: 'treatment_goal'},
]

const Audit = () => {
  const history = useHistory()
  const { user } = useStoreState(state => state.auth)
  const isAuditReportEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.AUDIT_REPORT
  )

  const { data, isLoading } = useQuery(
    [endpoints.getClinicianDashboardAudit.getCacheId()],
    () => endpoints.getClinicianDashboardAudit.request(),
    {
      enabled: isAuditReportEnabled,
    }
  )

  return (
    <Container maxWidth="1900px" mt={'24px'}>
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 12,
            sm: 12,
            md: 12
          }}
          colStart={{
            base: 0,
            sm: 0,
            md: 0
          }}
        >
          <Text fontSize={'24px'} fontWeight={'bold'} mb={'32px'}>
            Sessions
          </Text>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Clinician</Th>
                  <Th>Client</Th>
                  <Th>Session Date</Th>
                  <Th>Compliance</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.nodes?.map((item, index) => (
                  <Tr cursor="pointer" key={index} onClick={() => history.push(`/patient/${item.client.id}/completed-session/${item.session.id}`)}>
                    <Td>{item.clinician.fullName}</Td>
                    <Td>{item.client.fullName}</Td>
                    <Td>{moment(item.createdAt).format('MM/DD/YYYY h:mm A')}</Td>
                    <Td display="flex" h="61px" gap="8px" pb="20px">
                    {AuditQuestions.map((config) => (
                      <Flex key={config.key} gap="8px" alignItems="center">
                        <Flex>
                        {item.audit?.[config.key]?.answer === 'yes' ? (
                          <RadioButtonLoadingComplete fill="#18E09C" />
                        ) : (
                          <WarningFilled />
                        )}
                        </Flex>
                        <Flex>
                          <Text>
                            {config.title}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                    </Td>
                    <Td>
                      <ArrowRightIcon />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}

export default Audit