import React, { useMemo, useEffect } from 'react'
import Pagination from '@material-ui/lab/Pagination'
import { PrimaryButton } from '../../../../components'
import { makeStyles } from '@material-ui/core'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useTable, useSortBy, useFlexLayout } from 'react-table'
import { ArrowDownIcon, ArrowUpIcon } from '@blueprinthq/joy'
import {
  Flex,
  TableContainer,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  useTheme
} from '@chakra-ui/react'

const EmptyState = ({ styles, excludeEnrollButton }) => {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const { setIsQuickEnrollOpen } = useStoreActions(
    actions => actions.quickEnroll
  )

  return (
    <div className={classes.empty_state}>
      <img
        alt="Future clients"
        id="img"
        className={classes.empty_state_image}
        src="https://res.cloudinary.com/hellojoy/image/upload/v1541260001/empty_state_people_circle_speble.png"
      />
      <p className={classes.empty_state_text}>
        You don&apos;t have any clients yet.
      </p>
      {excludeEnrollButton ? null : (
        <PrimaryButton onClick={() => setIsQuickEnrollOpen(true)} fullWidth>
          Enroll your first client
        </PrimaryButton>
      )}
    </div>
  )
}

// eslint-disable-next-line react/display-name
const MemoizedRow = React.memo(({ row, prepareRow, onClick, classes }) => {
  prepareRow(row)
  return (
    <Tr
      as="div"
      {...row.getRowProps()}
      onClick={() => onClick(row)}
      cursor="pointer"
    >
      {row.cells.map((cell, j) => (
        <Td
          title={cell.value ? cell.value.toString() : ''}
          as="div"
          key={j}
          {...cell.getCellProps()}
          className={classes.cell}
          borderColor="pale_gray"
          paddingBottom="0px"
          paddingTop="0px"
          paddingLeft="xsmall"
          paddingRight="xsmall"
        >
          <div className={classes.ellipsis}>{cell.render('Cell')}</div>
        </Td>
      ))}
    </Tr>
  )
})

export default function PatientsListBase(props) {
  const {
    patientList,
    columns,
    styles,
    onRowClick,
    onSortChange,
    excludeEnrollButton
  } = props

  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const theme = useTheme()

  const { setPage } = useStoreActions(actions => actions.patientList)

  const { selectedPatientTypeOption } = useStoreState(
    state => state.patientList
  )
  const { defaultSort } = selectedPatientTypeOption

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200 // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    state: { sortBy }
  } = useTable(
    {
      columns: useMemo(() => columns, [selectedPatientTypeOption.value]),
      data: patientList,
      defaultColumn,
      disableMultiSort: true,
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    useFlexLayout
  )

  useEffect(() => {
    const sort = [
      { id: defaultSort.sortBy, desc: defaultSort.order === 'desc' }
    ]
    setSortBy(sort)
  }, [selectedPatientTypeOption.value])

  useEffect(() => {
    if (sortBy.length) {
      onSortChange(sortBy)
      setPage(1)
    }
  }, [sortBy])

  if (!patientList.length) {
    return (
      <EmptyState excludeEnrollButton={excludeEnrollButton} styles={styles} />
    )
  }

  const pageCount = props.totalPages || Math.ceil(patientList.length / 50)
  const tableWidth = theme.breakpoints.lg.replace(/\D/g, '') * 16 - 64 // subtract 64 for 32 px of margin on left and right

  const handlePageChange = (evt, page) => {
    props.updatePage(evt, page)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <TableContainer as="div" className={classes.tableContainer}>
        <Table {...getTableProps()} as="div" style={{ minWidth: tableWidth }}>
          <Thead as="div">
            {headerGroups.map((headerGroup, i) => (
              <Tr as="div" key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, j) => (
                  <Th
                    as="div"
                    key={j}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    borderColor="pale_gray"
                    pl="xsmall"
                    pr="xsmall"
                  >
                    <Flex>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownIcon size="sm" fill={'gray.600'} />
                          ) : (
                            <ArrowUpIcon size="sm" fill={'gray.600'} />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody as="div" {...getTableBodyProps()}>
            {rows.map((row, i) => (
              <MemoizedRow
                row={row}
                prepareRow={prepareRow}
                key={i}
                onClick={onRowClick}
                classes={classes}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <div className={classes.pagination}>
        <Pagination
          page={props.currentPage}
          count={pageCount}
          size="large"
          color={props.paginationColor || 'primary'}
          variant="outlined"
          showFirstButton
          showLastButton
          onChange={handlePageChange}
        />
      </div>
    </>
  )
}
