import React, { useCallback, useMemo, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useStoreActions } from 'easy-peasy'

import { HeaderBanner } from './components'
import { Loading } from '../../../../../components'
import { ProfileHeader } from '../../../components'
import { endpoints } from '../../../../../api'
import { PatientName } from '../../../components/header-components'
import { useComponentConfig } from '../../../../../hooks'

export const PatientProfileHeader = ({
  patientId,
  tabs,
  isEvidenceBasedCareEnabled
}) => {
  const componentConfig = useComponentConfig('PatientProfileHeader')
  const [anchorEl, setAnchorEl] = useState(null)

  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const {
    isLoading: isNextDeliveryLoading,
    data: nextDeliveryData
  } = useQuery(
    [endpoints.getClinicianUserNextDeliveryDate.getCacheId(), patientId],
    () =>
      endpoints.getClinicianUserNextDeliveryDate.request({ id: patientId }, {})
  )

  const { isLoading: isAccountLoading, data } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), patientId],
    () => endpoints.getClinicianUserAccount.request({ id: patientId })
  )

  const { data: clinicians, isLoading: isCliniciansLoading } = useQuery(
    [endpoints.getPatientClinicianList.getCacheId(), patientId],
    () => endpoints.getPatientClinicianList.request({ patientId })
  )

  const {
    data: activeAssessmentsData,
    isLoading: isAssessmentsLoading
  } = useQuery(
    [endpoints.getClinicianUserActiveAssessments.getCacheId(), patientId],
    () => endpoints.getClinicianUserActiveAssessments.request({ id: patientId })
  )

  const {
    data: checkinModulesData,
    isLoading: isCheckinModulesLoading
  } = useQuery(
    [endpoints.getCheckInModulesForUser.getCacheId(), patientId],
    () => endpoints.getCheckInModulesForUser.request({ id: patientId })
  )

  const {
    mutate: sendPatientAssessmentLink,
    isLoading: isSendingAssessment
  } = useMutation(endpoints.postSendPatientAssessmentLink.request)

  const handleSendPatientAssessmentLink = useCallback(
    assigneeUser => {
      sendPatientAssessmentLink(
        {
          patient_id: data.id,
          is_reminder: false,
          assigneeUserId: assigneeUser.id
        },
        {
          onSuccess: () => {
            setSnackbarMessage({
              variant: 'success',
              message: `Assessments were sent to ${assigneeUser.info.firstName} ${assigneeUser.info.lastName}.`
            })
          },
          onError: () => {
            setSnackbarMessage({
              variant: 'error',
              message: `Unable to send assessments to ${assigneeUser.info.firstName} ${assigneeUser.info.lastName}.`
            })
          }
        }
      )
    },
    [data]
  )

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const headerAttributes = useMemo(() => {
    if (!data || !nextDeliveryData) return []

    const activeCheckinModules = checkinModulesData
      ? checkinModulesData.filter(c => c.isActive)
      : []

    const params = {
      data: data,
      activeAssessmentsData: activeAssessmentsData || null,
      activeCheckinModules,
      isSendingAssessment,
      handleSendPatientAssessmentLink,
      anchorEl,
      handleMenuOpen,
      handleMenuClose,
      nextDeliveryData,
      clinicians,
      clinicName: data.clinic_name,
      isEvidenceBasedCareEnabled
    }

    return componentConfig.getHeaderAttributes(params)
  }, [
    data,
    activeAssessmentsData,
    nextDeliveryData,
    clinicians,
    anchorEl,
    checkinModulesData,
    isEvidenceBasedCareEnabled
  ])

  if (
    isAccountLoading ||
    isNextDeliveryLoading ||
    isAssessmentsLoading ||
    isCliniciansLoading ||
    isCheckinModulesLoading
  ) {
    return <Loading />
  }

  return (
    <>
      <HeaderBanner patient={data} activeAssessments={activeAssessmentsData} />
      <ProfileHeader
        name={<PatientName patient={data} />}
        attributes={headerAttributes}
        rightComponent={componentConfig.ProfileHeaderRight({
          data: data,
          activeAssessmentsData,
          handleMenuClose,
          handleMenuOpen,
          anchorEl
        })}
        tabs={tabs}
      />
    </>
  )
}
