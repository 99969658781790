import React from 'react'

const MicDictate = ({ width, height, fill = 'white' }) => {
  const aspectRatio = 28 / 36
  const w = width || '28'
  const h = height || (parseFloat(w) / aspectRatio).toString()

  return (
    <svg
      width={w}
      height={h}
      viewBox="-1 -1 30 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
    >
      <g>
        <path
          d="M10.2769 14.4919V22.5565C10.2769 24.7903 12.0701 26.5887 14.2975 26.5887C16.525 26.5887 18.3021 24.7903 18.3021 22.5565L18.3182 14.4919C18.3182 12.2581 16.525 10.4597 14.2975 10.4597C12.0701 10.4597 10.2769 12.2581 10.2769 14.4919Z"
          fill={fill}
        />
        <path
          d="M22.4516 22.5565C21.8806 22.5565 21.4142 22.9839 21.3258 23.5565C20.8192 27.0484 17.683 29.4113 14.2976 29.4113C10.9122 29.4113 7.77609 27.0484 7.26948 23.5565C7.18907 22.9919 6.71463 22.5565 6.14369 22.5565C5.46017 22.5565 4.91336 23.1532 5.00986 23.8306C5.58884 27.7258 8.7893 30.8145 12.625 31.5323C12.818 31.5645 12.9547 31.7258 12.9547 31.9274V34.8629C12.9547 35.4919 13.4613 36 14.0886 36H14.4906C15.1178 36 15.6244 35.4919 15.6244 34.8629V31.9274C15.6244 31.7339 15.7612 31.5726 15.9541 31.5323C19.7899 30.8226 22.9903 27.7258 23.5693 23.8306C23.6738 23.1532 23.119 22.5565 22.4355 22.5565H22.4516Z"
          fill={fill}
        />
        <path
          d="M7.74375 19.3306C7.74375 18.7258 7.26127 18.2419 6.65817 18.2419H2.0746C1.47149 18.2419 0.989014 18.7258 0.989014 19.3306C0.989014 19.9355 1.47149 20.4194 2.0746 20.4194H6.65013C7.25323 20.4194 7.73571 19.9355 7.73571 19.3306H7.74375Z"
          fill={fill}
        />
        <path
          d="M26.3756 18.2419H26.3193C25.7162 18.2419 25.2659 18.7258 25.2659 19.3306C25.2659 19.9355 25.7805 20.4194 26.3836 20.4194C26.9867 20.4194 27.4692 19.9355 27.4692 19.3306C27.4692 18.7258 26.9867 18.2419 26.3836 18.2419H26.3756Z"
          fill={fill}
        />
        <path
          d="M10.9683 2.17742C11.5715 2.17742 12.0539 1.69355 12.0539 1.08871C12.0539 0.483871 11.5715 0 10.9683 0H10.9121C10.309 0 9.85864 0.483871 9.85864 1.08871C9.85864 1.69355 10.3733 2.17742 10.9764 2.17742H10.9683Z"
          fill={fill}
        />
        <path
          d="M22.7249 18.2419H21.3981C20.795 18.2419 20.3125 18.7258 20.3125 19.3306C20.3125 19.9355 20.795 20.4194 21.3981 20.4194H22.7249C23.328 20.4194 23.8105 19.9355 23.8105 19.3306C23.8105 18.7258 23.328 18.2419 22.7249 18.2419Z"
          fill={fill}
        />
        <path
          d="M23.3922 13.6855H21.0682C20.4651 13.6855 19.9827 14.1694 19.9827 14.7742C19.9827 15.379 20.4651 15.8629 21.0682 15.8629H23.3922C23.9953 15.8629 24.4778 15.379 24.4778 14.7742C24.4778 14.1694 23.9953 13.6855 23.3922 13.6855Z"
          fill={fill}
        />
        <path
          d="M4.88099 13.6855C4.27789 13.6855 3.79541 14.1694 3.79541 14.7742C3.79541 15.379 4.27789 15.8629 4.88099 15.8629H6.79484C7.39794 15.8629 7.88042 15.379 7.88042 14.7742C7.88042 14.1694 7.39794 13.6855 6.79484 13.6855H4.88099Z"
          fill={fill}
        />
        <path
          d="M26.9145 9.12097H19.8783C19.2752 9.12097 18.7927 9.60484 18.7927 10.2097C18.7927 10.8145 19.2752 11.2984 19.8783 11.2984H26.9145C27.5176 11.2984 28.0001 10.8145 28.0001 10.2097C28.0001 9.60484 27.5176 9.12097 26.9145 9.12097Z"
          fill={fill}
        />
        <path
          d="M8.58817 11.2984C9.19127 11.2984 9.67375 10.8145 9.67375 10.2097C9.67375 9.60484 9.19127 9.12097 8.58817 9.12097H1.08558C0.482481 9.12097 0 9.60484 0 10.2097C0 10.8145 0.482481 11.2984 1.08558 11.2984H8.58817Z"
          fill={fill}
        />
        <path
          d="M23.923 6.74194H24.711C25.3141 6.74194 25.7966 6.25806 25.7966 5.65323C25.7966 5.04839 25.3141 4.56452 24.711 4.56452H23.923C23.3199 4.56452 22.8374 5.04839 22.8374 5.65323C22.8374 6.25806 23.3199 6.74194 23.923 6.74194Z"
          fill={fill}
        />
        <path
          d="M3.87587 6.74194H20.4732C21.0763 6.74194 21.5588 6.25806 21.5588 5.65323C21.5588 5.04839 21.0763 4.56452 20.4732 4.56452H3.87587C3.27276 4.56452 2.79028 5.04839 2.79028 5.65323C2.79028 6.25806 3.27276 6.74194 3.87587 6.74194Z"
          fill={fill}
        />
        <path
          d="M14.2733 2.17742H16.8064C17.4095 2.17742 17.8919 1.69355 17.8919 1.08871C17.8919 0.483871 17.4095 0 16.8064 0H14.2733C13.6702 0 13.1877 0.483871 13.1877 1.08871C13.1877 1.69355 13.6702 2.17742 14.2733 2.17742Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default MicDictate
