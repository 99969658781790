// @ts-nocheck
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { AddCircleIcon, CheckCircle } from '@blueprinthq/joy'
import {
  Flex,
  Input,
  Text,
  VStack,
  HStack,
  Link,
  Container,
  Stack,
  Divider,
  Box
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { MeasureTypes } from '@constants/measureTypes'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { useTreatmentPlan } from '@hooks'
import { IconButton } from 'amazon-chime-sdk-component-library-react'
import { Select } from '@blueprinthq/joy'
import { AssessmentResultsProvider, useAssessmentResults } from './context'
import {
  formatAssessmentAnswers,
  formatScoreToInterpretation,
} from '@utilities'
import Table from './table'
import Loading  from '@components/loading'
import { useOverlayPanel } from '../..'

export function Results() {
  const {
    assessmentOptions,
    onSelectAssessment,
    assessmentDateOptions,
    onSelectScore,
    state,
    selectedAssessment,
    selectedAssessmentScore,
    formattedAssessmentScoreAnswers,
    isLoading
  } = useAssessmentResults()

  return (
    <Stack flex="1" spacing="0" overflowY="hidden">
      <HStack
        h="100px"
        align="center"
        flex="1"
        borderBottom="1px solid"
        borderColor="pale_gray"
        p="24px"
        gap="24px"
      >
       <Select
          options={assessmentOptions}
          valueKey="assessmentId"
          labelKey="name"
          label="Assessment"
          value={assessmentOptions.find(
            ao => ao.assessmentId === state.selectedAssessmentId
          )}
          onChange={option => onSelectAssessment(option.assessmentId)}
        />
        <Select
          options={assessmentDateOptions}
          valueKey="assessmentScoreId"
          labelKey="date"
          label="Date"
          value={assessmentDateOptions.find(
            ad => ad.assessmentScoreId === state.selectedAssessmentScoreId
          )}
          onChange={option => onSelectScore(option.assessmentScoreId)}
        />
      </HStack>
      <Stack px="24px" h="calc(100vh - 262px)" overflowY="scroll">
      <Box mt="medium">
        {selectedAssessment && (
          <Box>
            <Text>
              <Text fontWeight="bold" as="span">
                {selectedAssessment?.disorder}:
              </Text>
              {` ${formatScoreToInterpretation(
                selectedAssessment,
                selectedAssessmentScore?.total_score
              )} `}
              <Text color="medium_gray" as="span" fontSize="12px">
                ({selectedAssessmentScore?.total_score} out of{' '}
                {selectedAssessment?.max_score})
              </Text>
            </Text>
          </Box>
        )}
        <Box mt="16px">
          <Box
            mt="16px"
            overflowX={{
              base: 'auto',
              md: 'initial'
            }}
          >
            {selectedAssessment && (
              <Table
                answers={formattedAssessmentScoreAnswers}
                isReverseValence={selectedAssessment.is_reverse_valence}
              />
            )}
          </Box>
        </Box>
      </Box>
      </Stack>
    </Stack>
  )
}

const Wrapped = ({
  client,
  preselectedAssessmentId,
  preselectedAssessmentScoreId
}: any) => (
  <AssessmentResultsProvider 
    preselectedAssessmentId={preselectedAssessmentId}
    preselectedAssessmentScoreId={preselectedAssessmentScoreId}
    client={client}
  >
    <Results />
  </AssessmentResultsProvider>
)


export default Wrapped