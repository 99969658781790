import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useQueryClient } from 'react-query'

import { PersonAddIcon } from '@blueprinthq/joy'
import {
  Container,
  Button,
  Text,
  Box,
  Flex,
  Link,
  Tabs,
  Tab,
  TabList,
  useToast
} from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { ClinicianOverride } from '@components'
import { usePermissions } from '@hooks'
import { CLIENT_PRONOUNS } from '@constants/clientPronouns'

import { getClinicControllerGetClientListQueryKey } from '~/clinician-api'

import { endpoints } from '@api'
import { AddClientDialog } from './add-client-dialog'
import { ClientTable } from './client-table'
import { StoreModel } from 'src/store/types'

const tabStatusMap: Record<number, string[]> = {
  0: ['active', 'archived'],
  1: ['active'],
  2: ['archived']
}

const CustomTab = ({ label }: { label: string }) => {
  return (
    <Tab
      _focus={{ outline: 'none' }}
      _selected={{ bg: '#E4E5E6' }}
      px="12px"
      py="5px"
      fontSize="14px"
      borderRadius="2px"
    >
      {label}
    </Tab>
  )
}

export const ClientListHandler: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(1)

  const queryClient = useQueryClient()
  const onClinicianSelectChange = (
    clinicianId: string | null,
    clinicId: string
  ) => {
    setClinicianId(clinicianId)
    setClinicId(clinicId)
  }

  const user = useStoreState((state: StoreModel) => state.auth.user)
  const { hasPermission } = usePermissions()

  const showClinicSelect = hasPermission('read:clinic:activeclients:*')

  const [clinicianId, setClinicianId] = useState<string | null>(user!.id)
  const [clinicId, setClinicId] = useState<string>(user!.clinic_id)

  const [isAddClientOpen, setIsAddClientOpen] = useState(false)
  const toast = useToast()
  const history = useHistory()

  const { mutateAsync: enrollClient, isLoading: isEnrolling } = useMutation(
    endpoints.postSessionEnrollClient.request,
    {
      onError: () => {
        toast({
          title: 'Error creating client',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      },
      onSuccess: () => {
        const clientListQueryKey = getClinicControllerGetClientListQueryKey(
          user!.clinic.id
        )
        queryClient.invalidateQueries({ queryKey: clientListQueryKey })
      }
    }
  )

  const handleEnrollClient = async ({
    firstName,
    lastName,
    dateOfBirth,
    pronoun
  }: {
    firstName: string
    lastName: string
    dateOfBirth: string | null
    pronoun: string | null
  }) => {
    const result: any = await enrollClient({
      data: {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth
          ? moment(dateOfBirth).format('YYYY-MM-DD')
          : null,
        pronoun: CLIENT_PRONOUNS[pronoun as keyof typeof CLIENT_PRONOUNS]
      }
    })
    setIsAddClientOpen(false)
    toast({
      status: 'success',
      isClosable: true,
      duration: 3000,
      render: () => (
        <Box color="white" p={3} bg="success" display="flex">
          <Text mr={2}>Client successfully created</Text>
          <Link
            onClick={() => history.push(`/patient/${result.patientId}`)}
            color="white"
            fontWeight="bold"
          >
            View profile
          </Link>
        </Box>
      )
    })
  }

  return (
    <Container p={8} position="relative" border="none" centerContent maxW="l">
      <Box mb={'medium'} w={'100%'}>
        <Flex
          mb="small"
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Flex
            flex="1"
            justifyContent={{ base: 'space-between', md: 'flex-start' }}
            alignItems="center"
            mb={{ base: 4, md: 0 }}
            w="100%"
          >
            <Text
              as="h1"
              fontSize="xl"
              fontWeight="bold"
              mr={{ base: 0, md: 6 }}
            >
              Clients
            </Text>
            <Tabs
              onChange={index => setTabIndex(index)}
              variant="unstyled"
              borderRadius="4px"
              border="1px #E4E5E6 solid"
              p="5px"
              index={tabIndex}
            >
              <TabList>
                <CustomTab label="All" />
                <CustomTab label="Active" />
                <CustomTab label="Archived" />
              </TabList>
            </Tabs>
          </Flex>
          {showClinicSelect && (
            <Flex w={{ base: '100%', md: 'auto' }} mb={{ base: 4, md: 0 }}>
              <ClinicianOverride
                user={user}
                isSuperAdmin={hasPermission('*:any:*:*')}
                onChange={(clinicianId: string | null, clinicId: string) =>
                  onClinicianSelectChange(clinicianId, clinicId)
                }
                canViewOrgDropdown={false}
                allowAllClinicians={hasPermission(
                  'read:clinic:activeclients:*'
                )}
              />
            </Flex>
          )}
          <Button
            h="40px"
            size="lg"
            ml={{ base: 0, md: 'medium' }}
            w={{ base: '100%', md: 'auto' }}
            onClick={() => setIsAddClientOpen(true)}
          >
            <PersonAddIcon size="sm" fill="white" mr="5px" mt="5px" />
            {'Add Client'}
          </Button>
        </Flex>
        {clinicId && (
          <Box w="100%" overflow="scroll">
            <ClientTable
              clinicId={clinicId}
              clinicianId={clinicianId}
              status={tabStatusMap[tabIndex]}
            />
          </Box>
        )}
      </Box>
      <AddClientDialog
        isOpen={isAddClientOpen}
        onClose={() => setIsAddClientOpen(false)}
        onSubmit={handleEnrollClient}
        isLoading={isEnrolling}
      />
    </Container>
  )
}
