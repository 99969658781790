import { endpoints, SessionEnrollClientRequest } from '@api'
import {
  Headphones,
  InfoIcon,
  InPersonFilled,
  NoHeadsetIcon,
  Online,
  SettingsIcon
} from '@blueprinthq/joy'
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip, VStack
} from '@chakra-ui/react'
import { EndSession, Mic, MicOff } from '@components/icons'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { useAudioCapture, useExperienceManager } from '@hooks'
import { trackEvent } from '@lib/clinician-tracking'
import WarningIcon from '@material-ui/icons/Warning'
import flagsmith from 'flagsmith'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { isMobile, isSafari } from 'react-device-detect'
import { useQuery } from 'react-query'
import { AddClientForm } from './add-client-form'
import { AudioLevelV2 } from './audio-level-v2'
import { AutoStopTimer } from './auto-stop-timer'
import { FreeTierSessionLimitReached } from './free-tier-session-limit-reached'
import { MicrophonePermissionsModal } from './mic-permissions-modal'
import { MicrophoneBlockedWarning } from './microphone-blocked-warning'
import { MicrophoneSelectorV2 } from './microphone-selector-v2'
import { MobileTelehealthToggle } from './mobile-telehealth-toggle'
import { SelectClientDropdown, SelectedClient } from './select-client-dropdown'
import { SelectMenu } from './select-menu'
import { SessionControlsFooter } from './session-controls-footer'
import { SettingsDialog } from './settings-dialog'
import StopRecordingAfter from './stop-recording-after'

type AINotetakerPanelV2Props = {
  sessionId: string
  hasStartedRecording: boolean
  startRecording: () => void
  isRecordingLoading: boolean
  aiNotetakerEnabled: boolean
  recordingDuration: number
  isDictation: boolean
  isEnrolling: boolean
  recordingStartedAt: string | null | undefined
  currentClient: SelectedClient | undefined
  onOpenEndModal: () => void
  onEnrollClientRequest: (client: SessionEnrollClientRequest) => void
  onClientChange: (clientId: string) => void
  setIsAddClientModalOpen: (open: boolean) => void
}

const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const remainingMinutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  if (hours > 0) {
    return `${hours < 10 ? '0' : ''}${hours}:${remainingMinutes < 10 ? '0' : ''
      }${remainingMinutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  return `${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}:${remainingSeconds < 10 ? '0' : ''
    }${remainingSeconds}`
}

export const AINotetakerPanelV2: React.FC<AINotetakerPanelV2Props> = ({
  hasStartedRecording,
  startRecording,
  isRecordingLoading,
  aiNotetakerEnabled,
  recordingDuration,
  isDictation,
  recordingStartedAt,
  currentClient,
  onOpenEndModal,
  onClientChange,
  onEnrollClientRequest,
  setIsAddClientModalOpen,
  isEnrolling
}) => {
  const {
    documentationAutomationFreeTierSessionLimitReached,
  } = useExperienceManager()

  const {
    audioInputs,
    isTelehealth,
    setIsTelehealth,
    isUsingHeadphones,
    isMuted,
    setIsUsingHeadphones,
    testAudioInputs,
    permissionStatus,
    isSettingsOpen,
    isStopRecordingAfterEnabled,
    stopRecordingAfter,
    setIsSettingsOpen,
    setIsStopRecordingAfterEnabled,
    setStopRecordingAfter,
    startContentShare,
    toggleMute,
    promptForDevicePermissions
  } = useAudioCapture()

  const needAudioSharing = isTelehealth && isUsingHeadphones
  const showSafariWarning =
    isSafari &&
    isTelehealth &&
    !isMobile &&
    !isDictation

  const [
    showSessionSettingWarning,
    setShowSessionSettingWarning
  ] = React.useState(false)
  const [showClientWarning, setShowClientWarning] = React.useState(false)
  const [showPermissionModal, setShowPermissionModal] = React.useState(false)

  const handleHeadphonesChange = (value: any) => {
    setIsUsingHeadphones(value === 'headphones')
    trackEvent('Confirm Audio Settings Dialog -> Changed Headphones Input', {
      newHeadphonesSelection: value
    })
  }

  const handleSessionTypeChange = (isTelehealth: boolean) => {
    if (!isTelehealth) {
      setIsUsingHeadphones(false)
    }
    setIsTelehealth(isTelehealth)
    setShowSessionSettingWarning(false)
    trackEvent('Confirm Audio Settings Dialog -> Changed Setting Input', {
      newSettingSelection: isTelehealth ? 'telehealth' : 'in_person'
    })
  }

  const handleStartRecording = async () => {
    let hasError = false

    if (isTelehealth === null || isTelehealth === undefined) {
      setShowSessionSettingWarning(true)
      hasError = true
    }

    if (!currentClient) {
      setShowClientWarning(true)
      hasError = true
    }

    if (hasError) {
      return
    }

    setShowSessionSettingWarning(false)

    if (needAudioSharing) {
      await startContentShare()
    }

    startRecording()
  }

  const handleMuteToggle = () => {
    toggleMute()
  }

  const handleSettingsClick = () => {
    trackEvent('Session -> Clicked Settings')
    setIsSettingsOpen(true)
  }

  useEffect(() => {
    testAudioInputs()
  }, [testAudioInputs])

  useEffect(() => {
    // Telehealth not possible on mobile, and we hide the option
    if (isMobile) {
      setIsTelehealth(false)
    }
  }, [isMobile])

  useEffect(() => {
    if (permissionStatus === 'unknown') {
      setShowPermissionModal(true)
    }
  }, [permissionStatus])

  const handlePermissionModalClose = async () => {
    setShowPermissionModal(false)
    await promptForDevicePermissions()
  }

  const { data: user }: { data: any } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const isInternalDemoOrg = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_IS_DEMO_CLIENTS_IN_START_SESSION
  )

  const { data: allClientsList, isLoading: isAllClientsListLoading } = useQuery(
    [endpoints.getClientList.getCacheId()],
    () =>
      endpoints.getClientList.request({
        limit: 50,
        status: 'active',
        clinicianId: user?.id
      }),
    {
      select: data => {
        return data.filter(n => !n.isDemo || isInternalDemoOrg)
      }
    }
  )

  if (documentationAutomationFreeTierSessionLimitReached) {
    return <FreeTierSessionLimitReached onUpgrade={startRecording} />
  }

  if (allClientsList && allClientsList?.length === 0 && !isAllClientsListLoading) {
    return (
      <AnimatePresence>
        <VStack
          data-testid="add-client-form-container"
          display="flex"
          as={motion.div}
          initial={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20, transition: { duration: 0.2, ease: "easeInOut" } }}
          w="100%"
          justifyContent="center"
          px={{ base: 4, sm: 0 }}
          alignItems="center"
        >
          <Text
            as={motion.p}
            fontSize="32px"
            color="#282828"
            textAlign="center"
            mb="16px"
          >
            Add a client to<br />start a session
          </Text>
          <Box
            as={motion.div}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <AddClientForm
              isLoading={isEnrolling}
              onSubmit={async (...args) => {
                await onEnrollClientRequest(...args)
              }}
              isEvidenceBasedCareEnabled={false}
            />
          </Box>
        </VStack>
      </AnimatePresence>
    )
  }

  if (audioInputs.length === 0 && permissionStatus === 'denied') {
    return (
      <MicrophoneBlockedWarning />
    )
  }

  if (hasStartedRecording) {
    return (
      <VStack
        data-testid="session-timer-controls-container"
        justifyContent="center"
        color="white"
        height="100%"
        minWidth="320px"
        textAlign="center"
        display="flex"
      >
        <Box flex="1" />
        <VStack flex="1" data-testid="centered-session-timer-controls">
          <Text
            fontSize="16px"
            style={{ marginTop: 0 }}
            color={isMuted ? '#282828' : 'primary'}
          >
            {isMuted ? 'Microphone muted' : 'Recording in progress'}
          </Text>
          <Text
            width="100%"
            color={isMuted ? '#282828' : 'primary'}
            fontSize="88px"
            fontWeight="420"
          >
            {formatTime(recordingDuration)}
          </Text>
          <AudioLevelV2 />
          {!isMuted && (
            <Text fontSize="16px" paddingTop="8px" color="#757575">
              A progress note will be generated after you
              <br />
              end the session.
            </Text>
          )}
          {isMuted && (
            <Text fontSize="16px" paddingTop="8px" color="#757575">
              Your notes won't include the conversation
              <br />
              that took place during this period.
            </Text>
          )}
          {isStopRecordingAfterEnabled && (
            <AutoStopTimer recordingStartedAt={recordingStartedAt} />
          )}
        </VStack>
        <SessionControlsFooter
          isMuted={isMuted}
          onMuteToggle={handleMuteToggle}
          onSettingsClick={handleSettingsClick}
          onEndSession={onOpenEndModal}
        />
        <MicrophonePermissionsModal
          isOpen={showPermissionModal}
          onClose={handlePermissionModalClose}
        />
        <SettingsDialog
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          onShareAudio={startContentShare}
          isDictation={isDictation}
        />
      </VStack>
    )
  }

  return (
    <HStack justifyContent="center" w="100%" px={{ base: 4, sm: 0 }}>
      <Stack
        gap="13px"
        alignItems="center"
        textAlign="center"
        maxW={{
          base: '100%',
          sm: '400px'
        }}
        w="100%"
      >
        <Box>
          <Text fontSize="16px" style={{ marginTop: 0 }} color="#757575">
            Not yet recording
          </Text>
          <Text
            width="100%"
            color={hasStartedRecording ? 'primary' : '#CED6FA'}
            fontSize="88px"
            fontWeight="420"
          >
            {formatTime(recordingDuration)}
          </Text>
        </Box>
        <SelectClientDropdown
          allClientsList={allClientsList}
          clinicianId={user?.id}
          setIsAddClientModalOpen={setIsAddClientModalOpen}
          selectedClient={currentClient}
          onChange={onClientChange}
          errorMessage={
            showClientWarning ? 'Please select a client' : undefined
          }
        />
        <MicrophoneSelectorV2 />
        {isMobile && <MobileTelehealthToggle />}
        {!isMobile && (
          <>
            <SelectMenu
              errorMessage={
                showSessionSettingWarning
                  ? 'Please select a session setting'
                  : undefined
              }
              options={[
                {
                  value: 'in_person',
                  label: 'In-Person',
                  icon: <InPersonFilled />
                },
                {
                  value: 'telehealth',
                  label: 'Telehealth',
                  icon: <Online />
                }
              ]}
              placeholder="Session setting"
              value={
                isTelehealth === null || isTelehealth === undefined
                  ? undefined
                  : isTelehealth
                    ? 'telehealth'
                    : 'in_person'
              }
              onChange={value => handleSessionTypeChange(value === 'telehealth')}
            />
            {isTelehealth && !isDictation && (
              <SelectMenu
                options={[
                  {
                    value: 'headphones',
                    label: 'Using headphones',
                    icon: <Headphones />
                  },
                  {
                    value: 'no_headphones',
                    label: 'Not using headphones',
                    icon: <NoHeadsetIcon />
                  }
                ]}
                value={isUsingHeadphones ? 'headphones' : 'no_headphones'}
                onChange={handleHeadphonesChange}
              />
            )}
          </>
        )}
        <Button
          bg="primary"
          color="white"
          fontWeight="430"
          height="56px"
          width="100%"
          variant="body"
          isLoading={isRecordingLoading}
          data-click-event-name="Session -> Clicked Start Recording"
          onClick={() => {
            handleStartRecording()
          }}
          _focus={{
            outline: 'none'
          }}
          id="pendo-session-panel-start-recording"
        >
          {isDictation
            ? 'Dictate'
            : needAudioSharing
              ? 'Share browser audio + begin recording'
              : 'Start Recording'}
        </Button>
        {!isDictation && (
          <StopRecordingAfter
            isEnabled={isStopRecordingAfterEnabled}
            setIsEnabled={setIsStopRecordingAfterEnabled}
            value={stopRecordingAfter}
            onChange={setStopRecordingAfter}
            useV2Styles={true}
          />
        )}
        {showSafariWarning && (
          <Alert
            status="error"
            bg="#FFF2F2"
            borderRadius="8px"
            p={4}
            border={`1px solid rgba(40, 40, 40, 0.05)`}
          >
            <Box mr={2} w="20px" h="20px">
              <Icon
                as={WarningIcon}
                color="red.500"
                mr="16px"
                mt="2px"
                boxSize={6}
              />
            </Box>
            <AlertDescription color="#282828" textAlign="left" marginLeft="16px">
              <b>Important:</b> Due to a limitation of the Safari browser, the AI
              Notetaker feature will not be able to capture participant audio
              duing a telehealth session. For the best results, please use
              Chrome.
            </AlertDescription>
          </Alert>
        )}
      </Stack>
      <MicrophonePermissionsModal
        isOpen={showPermissionModal}
        onClose={handlePermissionModalClose}
      />
    </HStack>
  )
}
