import React, { useCallback, useMemo } from 'react'
import {
  GridItem,
  VStack,
  HStack,
  Box,
  Text,
  Flex,
  Heading
} from '@chakra-ui/react'
import { CloseIcon, LayoutGrid } from '@blueprinthq/joy'
import { FastField } from 'formik'
import _ from 'lodash'

import { InSessionOnlyTooltip, AtLeastOneAssessmentTooltip } from './index'
import { AssessmentCadenceMenu } from '@components'
import { CLIENT_TYPE } from '@constants/index'
import { AssignAssessmentsModal } from '@containers/assign-measures-modal/components'

const AssessmentsComponent = ({
  formHelpers,
  onAssignAssessments,
  onUnassignAssessment,
  assessments,
  isPendingClient
}) => {
  const { values, setFieldValue } = formHelpers

  const onCadenceChange = useCallback(
    (selectedCadence, assigneeIdentifier, assessmentIndex) => {
      setFieldValue(
        `assessments.${assigneeIdentifier}.${assessmentIndex}.cadence`,
        {
          unit: selectedCadence.unit,
          value: selectedCadence.value
        }
      )
    },
    [values.assessments]
  )

  const assignees = useMemo(() => {
    const baseArray = [
      {
        info: { firstName: values.firstName, lastName: values.lastName },
        ref: 'client',
        id: 'client'
      }
    ]

    const participantsArray = values.participants
      .map(p => ({
        info: { firstName: p.firstName, lastName: p.lastName },
        type: p.type.displayName,
        ref: p.ref,
        id: p.ref
      }))
      .filter(p => Boolean(p.info.firstName.trim() && p.info.lastName.trim()))

    return baseArray.concat(participantsArray)
  }, [values.participants, values.firstName, values.lastName])

  const isChildAssignee = useCallback(
    assignee => {
      return (
        values.clientType === CLIENT_TYPE.CHILD &&
        assignee.ref.includes('client')
      )
    },
    [values.clientType]
  )

  const isRequireAssignedAssessment = useMemo(() => {
    return (
      !isPendingClient &&
      Object.entries(values.assessments).flatMap(([ref, assessments]) => {
        return assessments
      }).length === 1
    )
  }, [values.assessments, isPendingClient])

  return (
    <Box>
      {assignees.map((assignee, idx) => (
        <Box key={assignee.ref}>
          {assignees.length > 1 ? (
            <HStack my="small">
              <Heading size="lg">{`${assignee.info.firstName} ${assignee.info.lastName}`}</Heading>
              {assignee.type && (
                <Heading size="lg" color="#a6a6a6" pl="xxsmall">
                  {assignee.type}
                </Heading>
              )}
            </HStack>
          ) : (
            <Box mt="small" />
          )}
          {values.assessments[assignee.ref].length ? (
            <Box>
              {values.assessments[assignee.ref].map((a, assessmentIndex) => (
                <HStack
                  key={a.id}
                  pb={{
                    base: 'small',
                    sm: 'xsmall',
                    md: 'xsmall'
                  }}
                  pt="xsmall"
                  borderBottom="1px solid #E4E5E6"
                  borderTop={assessmentIndex === 0 && '1px solid #E4E5E6'}
                >
                  <LayoutGrid
                    gridColumnGap="small"
                    gridRowGap="small"
                    alignItems="center"
                  >
                    <GridItem
                      colStart={1}
                      colSpan={{
                        base: 3,
                        sm: 5,
                        md: 7
                      }}
                      rowStart={1}
                    >
                      <VStack align="flex-start">
                        <Text fontWeight="bold">{a.title}</Text>
                        <Text>{`${a.subtitle} | ${a.numQuestions} questions`}</Text>
                      </VStack>
                    </GridItem>
                    <GridItem
                      colStart={{
                        base: 1,
                        sm: 6,
                        md: 9
                      }}
                      colSpan={{
                        base: 2,
                        sm: 2,
                        md: 3
                      }}
                      rowStart={{
                        base: 2,
                        sm: 1,
                        md: 1
                      }}
                    >
                      <InSessionOnlyTooltip
                        showTooltip={isChildAssignee(assignee)}
                        firstName={values.firstName}
                      >
                        <FastField
                          name={`assessments.${assignee.ref}.${assessmentIndex}.cadence`}
                        >
                          {({ field }) => {
                            return (
                              <AssessmentCadenceMenu
                                {...field}
                                onChange={onCadenceChange}
                                index={assessmentIndex}
                                assessment={{
                                  ...a,
                                  cadence_unit: a.cadence.unit,
                                  cadence_value: a.cadence.value
                                }}
                                isChildAssignee={isChildAssignee(assignee)}
                                assigneeIdentifier={assignee.ref}
                              />
                            )
                          }}
                        </FastField>
                      </InSessionOnlyTooltip>
                    </GridItem>
                    <GridItem
                      colStart={{
                        base: 4,
                        sm: 8,
                        md: 12
                      }}
                      rowStart={1}
                    >
                      <Flex w="100%" justify="flex-end">
                        <AtLeastOneAssessmentTooltip
                          showTooltip={isRequireAssignedAssessment}
                        >
                          <CloseIcon
                            fill={isRequireAssignedAssessment && '#E4E5E6'}
                            mx="xsmall"
                            cursor={
                              isRequireAssignedAssessment
                                ? 'not-allowed'
                                : 'pointer'
                            }
                            onClick={() => {
                              if (isRequireAssignedAssessment) return
                              onUnassignAssessment(assessmentIndex, assignee.id)
                            }}
                          />
                        </AtLeastOneAssessmentTooltip>
                      </Flex>
                    </GridItem>
                  </LayoutGrid>
                </HStack>
              ))}
            </Box>
          ) : (
            <Text
              w="100%"
              py="small"
              my="small"
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor="#c7c7c7"
            >
              No Assessments assigned.
            </Text>
          )}
          <Box mb="small" />
          <AssignAssessmentsModal
            assigneeUser={assignee}
            assessments={assessments}
            selectedAssessments={values.assessments[assignee.ref]}
            onAssign={onAssignAssessments}
            isEnrollFlow={true}
          />
          {idx < assignees.length - 1 && <Box mt="large" mb="large"></Box>}
        </Box>
      ))}
    </Box>
  )
}

export const Assessments = React.memo(
  AssessmentsComponent,
  (props, nextProps) => _.isEqual(props, nextProps)
)
