import React from 'react'
import { Text } from '@chakra-ui/react'

export const BetaBadge = props => {
  return (
    <Text
      w="29px"
      h="16px"
      bg="#03D596"
      borderRadius="3px"
      py="2px"
      fontWeight="bold"
      textAlign="center"
      fontSize="9px"
      color="white"
      {...props}
    >
      Beta
    </Text>
  )
}
