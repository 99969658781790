import React from 'react'
import { Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'

export const RecommendIntervention = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const { interventions } = config
  const body = (
    <Text>
      Recommend the{' '}
      <Text as="b">
        {interventions
          ?.map((intervention: { name: string }) => intervention.name)
          ?.join(' and ')}
      </Text>{' '}
      intervention{interventions?.length > 1 && 's'}
    </Text>
  )

  return (
    <WorkflowNode
      heading="Recommend Intervention Action"
      body={body}
      hasChildren={hasChildren}
      isParent={isParent}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
