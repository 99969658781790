const RadioButtonLoading = ({ stroke }: { stroke?: string }) => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="12"
      cy="12"
      r="10"
      stroke={stroke || '#2D54E8'}
      strokeWidth="2"
      fill="white"
    />
    <circle
      cx="12"
      cy="12"
      r="6"
      fill="none"
      stroke={stroke || '#2D54E8'}
      strokeWidth="2"
      strokeDasharray="26"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 12 12"
        to="360 12 12"
        dur="1s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
)

export default RadioButtonLoading
