import React from 'react'

const SessionNotes = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M19.5 5V14H14.5V19H5.5V5H19.5ZM19.5 3H5.5C4.4 3 3.5 3.9 3.5 5V19C3.5 20.1 4.4 21 5.5 21H15.5L21.5 15V5C21.5 3.9 20.6 3 19.5 3ZM12.5 14H7.5V12H12.5V14ZM17.5 10H7.5V8H17.5V10Z"
      fill={props.fill || '#2D54E8'}
    />
  </svg>
)

export default SessionNotes
