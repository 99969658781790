import React, { useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import PDFObject from 'pdfobject'
import { makeStyles, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { fetchPdf } from '../../utilities'

const useStyles = makeStyles({
  pdfContainer: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  skeletonContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  pdfEmbed: {
    width: '100%',
    height: '100%'
  }
})

export default function PdfEmbed(props) {
  const classes = useStyles()
  const { cacheKey, url, errorMessage } = props
  const pdfEmbedRef = useRef(null)

  const { data: pdfBuffer, isSuccess, isLoading, error } = useQuery(
    cacheKey,
    () => fetchPdf(url)
  )

  useEffect(() => {
    if (pdfBuffer && isSuccess) {
      PDFObject.embed(pdfBuffer, pdfEmbedRef.current)
    }
  }, [pdfBuffer, isSuccess])

  if (error) {
    return (
      <Grid container justify="center">
        <span style={{ color: 'red' }}>{errorMessage || error.message}</span>
      </Grid>
    )
  }

  return (
    <div className={classes.pdfContainer}>
      {isLoading ? (
        <div className={classes.skeletonContainer}>
          <Skeleton variant="rect" width="100%" height="5%" animation="wave" />
          <div style={{ height: 2 }} />
          <Skeleton variant="rect" width="100%" height="90%" animation="wave" />
        </div>
      ) : null}
      <div className={classes.pdfEmbed} ref={pdfEmbedRef} />
    </div>
  )
}
