import React, { useMemo } from 'react'
import { Box, Menu, MenuItem, MenuList, Checkbox } from '@chakra-ui/react'
import { keyBy } from 'lodash'
import { useQuery } from 'react-query'
import { Chip } from '@handlers/reports/components/chip'
import { CustomMenuButton } from '@handlers/reports/components/filters'
import { endpoints } from '@api'

type ProgramOption = {
  value: string
  label: string
}

type ProgramSelectorProps = {
  organizationId: string
  selectedPrograms: string[]
  onChange: (selectedPrograms: string[]) => void
}

export const ProgramFilter: React.FC<ProgramSelectorProps> = ({
  organizationId,
  selectedPrograms,
  onChange
}) => {
  const { data } = useQuery(
    [endpoints.getPrograms.getCacheId(), organizationId],
    () =>
      endpoints.getPrograms.request({
        organizationId
      }),
    {
      initialData: { programs: [] }
    }
  )

  const options: ProgramOption[] = useMemo(
    () =>
      (data?.programs || []).map((program: any) => ({
        value: program.id,
        label: program.name
      })) || [],
    [data]
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedPrograms
    if (event.target.checked) {
      // Add the program's value to the list of selected programs
      updatedPrograms = [...selectedPrograms, event.target.value]
    } else {
      // Remove the program's value from the list of selected programs
      updatedPrograms = selectedPrograms.filter(
        program => program !== event.target.value
      )
    }
    onChange(updatedPrograms)
  }

  const handleSelectAllToggle = () => {
    onChange([])
  }

  const handleClear = (program: string) => {
    return () => onChange(selectedPrograms.filter(p => p !== program))
  }

  const keyedOptions = useMemo(() => keyBy(options, 'value'), [options])
  const isAllSelected = selectedPrograms.length === 0
  const chips = (
    <>
      {selectedPrograms.length ? (
        selectedPrograms
          .sort()
          .map(program => (
            <Chip
              key={program}
              label={keyedOptions[program].label}
              onClose={handleClear(program)}
            />
          ))
      ) : (
        <Chip label="All" />
      )}
    </>
  )

  if (!options?.length) {
    // We want to hide the program selector if no programs are available,
    // because we don't have a self-serve tool yet for program management
    return null
  }

  return (
    <Box>
      <Menu closeOnSelect={false} placement="bottom-start">
        <CustomMenuButton
          isMenu
          label="Programs"
          chips={chips}
          onClick={() => {}}
        />
        <MenuList>
          <MenuItem>
            <Checkbox
              w="100%"
              key="all"
              value="all"
              isChecked={isAllSelected}
              onChange={handleSelectAllToggle}
            >
              All
            </Checkbox>
          </MenuItem>
          {options.map(option => (
            <MenuItem key={option.value}>
              <Checkbox
                w="100%"
                value={option.value}
                isChecked={selectedPrograms.includes(option.value)}
                onChange={handleInputChange}
              >
                {option.label}
              </Checkbox>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}
