import React, { useState } from 'react'
import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
  Text,
  Box,
  DrawerCloseButton,
  DrawerFooter,
  DrawerBody,
  Button,
  FormControl,
  Select,
  Flex,
  Link,
  VStack,
  Checkbox,
  HStack,
  Tooltip,
  useRadio,
  useRadioGroup
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { Loading } from '@components'
import * as Yup from 'yup'
import { Field, Form, Formik, FieldArray } from 'formik'
import { Refresh } from '@blueprinthq/joy'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { NoteSettings, CircleQuestion } from '@components/icons'
import { getSessionControllerGetProgressNotesForSessionQueryKey } from '~/clinician-api'
import { motion } from 'framer-motion'

const Schema = Yup.object().shape({
  selfReference: Yup.string().required('Required'),
  patientReference: Yup.string().required('Required'),
  noteLength: Yup.string().required('Required'),
  diagnosticSymptoms: Yup.string().required('Required'),
  medicalNecessity: Yup.string().required('Required'),
  actionItems: Yup.string().required('Required'),
  directQuotes: Yup.string().required('Required'),
  formatting: Yup.string().required('Required'),
  mentalStatusExam: Yup.array()
    .of(Yup.string())
    .required('Required')
})

function RadioGroup({ onChange, value, options = [] }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange
  })

  const group = getRootProps()

  return (
    <HStack
      p="4px"
      borderRadius="8px"
      bgColor="#F4F4F4"
      {...group}
      position="relative"
      spacing={0}
      align="stretch"
      w="100%"
    >
      {options.map(({ label, value: optionValue }) => {
        const radio = getRadioProps({ value: optionValue })
        const isChecked = value === optionValue
        return (
          <RadioCard key={optionValue} {...radio} isChecked={isChecked}>
            {label}
          </RadioCard>
        )
      })}
      <Box
        as={motion.div}
        position="absolute"
        borderRadius="md"
        bgColor={'white'}
        border={'1px solid rgba(55, 60, 65, 0.1375)'}
        layout
        initial={false}
        transition={{ type: 'spring', stiffness: 400, damping: 30 }}
        style={{
          width: `32%`,
          height: 'calc(100% - 8px)',
          left: `${
            [1, 34.3, 66.7][options.findIndex(opt => opt.value === value)]
          }%`
        }}
      />
    </HStack>
  )
}

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" marginInlineStart="0px" w="100%" zIndex="1">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius="md"
        _checked={{
          color: 'black'
        }}
        _hover={{
          backgroundColor: props.isChecked ? 'transparent' : '#F9F9F9'
        }}
        _focus={{
          boxShadow: 'none'
        }}
        inset="0"
        px="20px"
        py="4px"
        h="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.children}
      </Box>
    </Box>
  )
}

export const NoteSettingsDrawerV2 = ({
  isOpen,
  onClose,
  progressNoteId,
  sessionId,
  progressNote
}) => {
  const [rememberSettings, setRememberSettings] = useState(true)
  const { user } = useStoreState(state => state.auth)
  const organizationId = user?.clinic?.organization?.id
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(
    [endpoints.getProgressNoteSettings.getCacheId(), organizationId],
    () => endpoints.getProgressNoteSettings.request({ organizationId }),
    {
      enabled: !!organizationId
    }
  )

  const {
    mutateAsync: regenerateNote,
    isLoading: isRegenerating
  } = useMutation(endpoints.postRegenerateWithPreferences.request)

  const handleRegeneration = async values => {
    await regenerateNote(
      {
        progressNoteId,
        preferences: values,
        saveAsDefault: rememberSettings
      },
      {
        onSuccess: async data => {
          onClose()
          await queryClient.invalidateQueries(
            getSessionControllerGetProgressNotesForSessionQueryKey(sessionId)
          )
          await queryClient.invalidateQueries([
            endpoints.getUserAccount.getCacheId()
          ])
          await queryClient.invalidateQueries([
            endpoints.getProgressNoteSettings.getCacheId()
          ])
        }
      }
    )
  }
  if (isLoading || !progressNote) return null

  const preferences = data?.preferences || {}
  const preferenceOptions = data?.preferenceOptions || {}

  const initialValues = {
    ...preferences,
    ...progressNote.preferences
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={handleRegeneration}
    >
      {({ handleChange, handleSubmit, setValues, dirty }) => {
        return (
          <ChakraDrawer isOpen={isOpen} onClose={onClose} size="md">
            <DrawerOverlay />
            <DrawerContent>
              <Box
                height="80px"
                backgroundColor="primary"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px="small"
              >
                <Flex align="center">
                  <NoteSettings fill="white" height="20" width="20" />
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="white"
                    paddingLeft="xsmall"
                    ml={2}
                  >
                    Note preferences
                  </Text>
                </Flex>
                <Flex align="center">
                  <DrawerCloseButton
                    color="white"
                    position="static"
                    _focus={{ border: 'none' }}
                  />
                </Flex>
              </Box>
              <DrawerBody p={0}>
                <Form>
                  <Box px={'24px'} py={6} overflowY="scroll" height="100%">
                    <FormControl>
                      <VStack spacing="24px" align="stretch">
                        <HStack gap="20px" alignItems="center">
                          <Box flex="1">
                            <Text
                              fontSize={'16px'}
                              fontWeight={'semibold'}
                              mb={'8px'}
                            >
                              How you refer to yourself
                            </Text>
                            <Flex gap="16px" flexDirection="column">
                              <Field name="selfReference">
                                {({ field }) => (
                                  <Select
                                    {...field}
                                    value={field.value}
                                    style={{ border: '1px solid #E4E5E6' }}
                                    _focus={{
                                      outline: 'none'
                                    }}
                                    borderRadius="4px"
                                    height="48px"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  >
                                    {preferenceOptions[
                                      'selfReference'
                                    ]?.options?.map(type => (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </option>
                                    ))}
                                  </Select>
                                )}
                              </Field>
                            </Flex>
                          </Box>
                        </HStack>
                        <HStack gap="20px" alignItems="center">
                          <Box flex="1">
                            <Text
                              fontSize={'16px'}
                              fontWeight={'semibold'}
                              mb={'8px'}
                            >
                              How you refer to those you care for
                            </Text>
                            <Flex gap="16px" flexDirection="column">
                              <Field name="patientReference">
                                {({ field }) => (
                                  <Select
                                    {...field}
                                    value={field.value}
                                    style={{ border: '1px solid #E4E5E6' }}
                                    _focus={{
                                      outline: 'none'
                                    }}
                                    borderRadius="4px"
                                    height="48px"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  >
                                    {preferenceOptions[
                                      'patientReference'
                                    ]?.options?.map((type, index) => (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </option>
                                    ))}
                                  </Select>
                                )}
                              </Field>
                            </Flex>
                          </Box>
                        </HStack>
                        <Box>
                          <Text
                            fontSize={'16px'}
                            fontWeight={'semibold'}
                            mb={'8px'}
                          >
                            Length
                          </Text>
                          <Field name="noteLength">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['noteLength']?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Text
                            fontSize={'16px'}
                            fontWeight={'semibold'}
                            mb={'8px'}
                          >
                            Medical Necessity
                          </Text>
                          <Field name="medicalNecessity">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['medicalNecessity']?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Flex alignItems="center" mb={'8px'}>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'semibold'}
                              mr="3px"
                            >
                              Diagnostic Symptoms
                            </Text>
                            <Tooltip
                              hasArrow
                              label="Use brief or clinical language and describe symptoms and behaviors."
                              maxW="250px"
                            >
                              <Flex alignItems="center">
                                <CircleQuestion fill="#A1A1A1" />
                              </Flex>
                            </Tooltip>
                          </Flex>
                          <Field name="diagnosticSymptoms">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['diagnosticSymptoms']
                                    ?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Text
                            fontSize={'16px'}
                            fontWeight={'semibold'}
                            mb={'8px'}
                          >
                            Action Items
                          </Text>
                          <Field name="actionItems">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['actionItems']?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Flex alignItems="center" mb={'8px'}>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'semibold'}
                              mr="3px"
                            >
                              Direct Quotes
                            </Text>
                            <Tooltip
                              hasArrow
                              label="Include direct quotes or summarize information"
                              maxW="250px"
                            >
                              <Flex alignItems="center">
                                <CircleQuestion fill="#A1A1A1" />
                              </Flex>
                            </Tooltip>
                          </Flex>
                          <Field name="directQuotes">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['directQuotes']?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Flex alignItems="center" mb={'8px'}>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'semibold'}
                              mr="3px"
                            >
                              Include Sections of the Mini Mental Status Exam
                              (MMSE)
                            </Text>
                            <Tooltip
                              hasArrow
                              label="Applies to non-intake notes only. At least 3 is recommended for most insurance billing."
                              maxW="250px"
                            >
                              <Flex alignItems="center">
                                <CircleQuestion fill="#A1A1A1" />
                              </Flex>
                            </Tooltip>
                          </Flex>
                          <FieldArray name="mentalStatusExam">
                            {({ push, remove, form }) => (
                              <Box
                                display="grid"
                                gridTemplateColumns="1fr 1fr"
                                gap="15px"
                              >
                                {preferenceOptions[
                                  'mentalStatusExam'
                                ]?.options?.map(option => {
                                  const isChecked = form.values.mentalStatusExam.includes(
                                    option.value
                                  )
                                  return (
                                    <Checkbox
                                      key={option.value}
                                      size="md"
                                      isChecked={isChecked}
                                      onChange={() => {
                                        if (isChecked) {
                                          remove(
                                            form.values.mentalStatusExam.indexOf(
                                              option.value
                                            )
                                          )
                                        } else {
                                          push(option.value)
                                        }
                                      }}
                                    >
                                      {option.label}
                                    </Checkbox>
                                  )
                                })}
                              </Box>
                            )}
                          </FieldArray>

                          <Flex justifyContent="center" mt={'24px'}>
                            <Button
                              borderRadius="4px"
                              onClick={() => setValues(data.defaultPreferences)}
                              variant="outline"
                              m={`0 0 0 0px`}
                              p="8px"
                              h="35px"
                              borderColor="pale_gray"
                              _hover={{ opacity: 0.7 }}
                              _focus={{ outline: 'none' }}
                            >
                              Reset to defaults
                            </Button>
                          </Flex>
                        </Box>
                      </VStack>
                    </FormControl>
                  </Box>
                </Form>
              </DrawerBody>
              <DrawerFooter p={0} width="100%" borderTop="1px #E4E5E6 solid">
                <Box m="small" width="100%">
                  <Box mb={4}>
                    <Checkbox
                      isChecked={rememberSettings}
                      onChange={e => setRememberSettings(e.checked)}
                    >
                      Remember these settings for future notes
                    </Checkbox>
                  </Box>
                  <Button
                    colorScheme="primary"
                    variant="solid"
                    size="lg"
                    w="100%"
                    m={0}
                    leftIcon={<Refresh fill="White" h="16px" w="16px" />}
                    onClick={handleSubmit}
                    isLoading={isRegenerating}
                    isDisabled={isRegenerating || !dirty}
                  >
                    Generate new note
                  </Button>
                </Box>
              </DrawerFooter>
            </DrawerContent>
          </ChakraDrawer>
        )
      }}
    </Formik>
  )
}
