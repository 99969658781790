/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react'
import {
  Flex,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Text,
  Box,
  Heading
} from '@chakra-ui/react'
import { ArrowDownIcon, ArrowUpIcon, Select } from '@blueprinthq/joy'
import { useTable } from 'react-table'
import _ from 'lodash'

export const BillingRulesTableUnmemo = ({
  data,
  licenseLevels,
  onChangeBillingRule,
  orgCptCodes,
  orgModifiers,
  preferencesUpdatedAt
}) => {
  const [collapsedLevels, setCollapsedLevels] = useState(() =>
    licenseLevels.reduce(
      (obj, level) => ({
        ...obj,
        [level.id]: level.count === 0
      }),
      {}
    )
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Payer',
        accessor: 'orgInsurancePayer.name',
        style: {
          width: '100%'
        }
      },
      {
        Header: 'Submit Claim?',
        accessor: 'submitClaim',
        style: {
          width: 124
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => {
          const { orgInsurancePayer, licenseLevel, ...rules } = row.original

          return (
            <Box width="112px">
              <Select
                onChange={value =>
                  onChangeBillingRule(orgInsurancePayer.id, licenseLevel.id, {
                    ...rules,
                    submitClaim: value === 'Yes'
                  })
                }
                size="sm"
                value={value ? 'Yes' : 'No'}
                options={['Yes', 'No']}
                menuProps={{ isLazy: true }}
                simple
              />
            </Box>
          )
        }
      },
      {
        Header: 'CPT Code',
        accessor: 'singleCptCode',
        style: {
          borderLeft: '1px solid',
          borderLeftColor: 'pale_gray',
          width: 124
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => {
          const { orgInsurancePayer, licenseLevel, ...rules } = row.original

          return (
            <Box width="112px">
              <Select
                isDisabled={!rules.submitClaim}
                onChange={value =>
                  onChangeBillingRule(orgInsurancePayer.id, licenseLevel.id, {
                    ...rules,
                    singleCptCode: value
                  })
                }
                size="sm"
                value={value}
                options={orgCptCodes}
                menuProps={{ isLazy: true }}
                simple
              />
            </Box>
          )
        }
      },
      {
        Header: 'Modifier',
        accessor: 'singleModifier',
        style: {
          width: '96px'
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => {
          const { orgInsurancePayer, licenseLevel, ...rules } = row.original

          return (
            <Box width="84px">
              <Select
                isDisabled={!rules.submitClaim}
                onChange={value =>
                  onChangeBillingRule(orgInsurancePayer.id, licenseLevel.id, {
                    ...rules,
                    singleModifier: value
                  })
                }
                size="sm"
                value={value}
                options={orgModifiers}
                menuProps={{ isLazy: true }}
                simple
              />
            </Box>
          )
        }
      },
      {
        Header: 'CPT Code',
        accessor: 'multipleCptCode',
        style: {
          borderLeft: '1px solid',
          borderLeftColor: 'pale_gray',
          width: 124
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => {
          const { orgInsurancePayer, licenseLevel, ...rules } = row.original

          return (
            <Box width="112px">
              <Select
                isDisabled={!rules.submitClaim}
                onChange={value =>
                  onChangeBillingRule(orgInsurancePayer.id, licenseLevel.id, {
                    ...rules,
                    multipleCptCode: value
                  })
                }
                size="sm"
                value={value}
                options={orgCptCodes}
                menuProps={{ isLazy: true }}
                simple
              />
            </Box>
          )
        }
      },
      {
        Header: 'Modifier',
        accessor: 'multipleModifier',
        style: {
          width: '96px',
          borderRight: '1px solid',
          borderRightColor: 'pale_gray'
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => {
          const { orgInsurancePayer, licenseLevel, ...rules } = row.original

          return (
            <Box width="84px">
              <Select
                isDisabled={!rules.submitClaim}
                onChange={value =>
                  onChangeBillingRule(orgInsurancePayer.id, licenseLevel.id, {
                    ...rules,
                    multipleModifier: value
                  })
                }
                size="sm"
                value={value}
                options={orgModifiers}
                menuProps={{ isLazy: true }}
                simple
              />
            </Box>
          )
        }
      }
    ],
    [data, orgCptCodes, orgModifiers]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  })

  return (
    <Table
      {...getTableProps()}
      sx={{
        borderCollapse: 'separate',
        borderColor: 'pale_gray',
        tableLayout: 'fixed',
        minWidth: 760,
        mb: 'medium'
      }}
    >
      <Thead>
        <Tr>
          <Th pb={0} pt="medium" borderStyle="none" width="100%"></Th>
          <Th pb={0} pt="medium" borderStyle="none" width="124px"></Th>
          <Th pb={0} pt="medium" borderStyle="none" width="124px"></Th>
          <Th pb={0} pt="medium" borderStyle="none" width="96px"></Th>
          <Th pb={0} pt="medium" borderStyle="none" width="124px"></Th>
          <Th pb={0} pt="medium" borderStyle="none" width="96px"></Th>
        </Tr>
        <Tr>
          <Th borderStyle="none" colSpan={2} width="100%"></Th>
          <AssessmentTh
            colSpan={2}
            sx={{
              borderRightStyle: 'none'
            }}
          >
            1 assessment
          </AssessmentTh>
          <AssessmentTh colSpan={2}>2+ assessments</AssessmentTh>
        </Tr>
        {headerGroups.map((headerGroup, i) => (
          <Tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => (
              <Th
                key={j}
                {...column.getHeaderProps()}
                borderColor="light_gray"
                pr="0px"
                pl="10px"
                sx={column.style}
              >
                <Flex alignItems="center">{column.render('Header')}</Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      {licenseLevels.map(licenseLevel => (
        <Tbody {...getTableBodyProps()} key={licenseLevel.id}>
          <Tr
            onClick={() =>
              setCollapsedLevels({
                ...collapsedLevels,
                [licenseLevel.id]: !collapsedLevels[licenseLevel.id]
              })
            }
            cursor="pointer"
          >
            <Td colSpan={columns.length} padding={'0px'}>
              <Flex
                width="100%"
                bg="primary"
                height={'32px'}
                align="center"
                justify="space-between"
                pr="small"
                pl="small"
              >
                <Box>
                  <Text color="white">{licenseLevel.displayName}</Text>
                </Box>
                <Flex justify="space-between" align="center">
                  <Text color="white" mr="small">
                    {licenseLevel.count} Users
                  </Text>
                  {collapsedLevels[licenseLevel.id] ? (
                    <ArrowUpIcon fill="white" />
                  ) : (
                    <ArrowDownIcon fill="white" />
                  )}
                </Flex>
              </Flex>
            </Td>
          </Tr>
          {!collapsedLevels[licenseLevel.id] &&
            rows
              .filter(row => row.original.licenseLevel.id === licenseLevel.id)
              .map(row => (
                <MemoizedRow
                  row={row}
                  preferencesUpdatedAt={preferencesUpdatedAt}
                  prepareRow={prepareRow}
                  key={row.original.orgInsurancePayer.id}
                />
              ))}
        </Tbody>
      ))}
    </Table>
  )
}

const AssessmentTh = ({ children, sx = {}, ...rest }) => {
  return (
    <Th
      sx={{
        pt: 'medium',
        border: '1px solid',
        borderLeftColor: 'pale_gray',
        borderRightColor: 'pale_gray',
        borderTopColor: 'pale_gray',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        borderBottomStyle: 'none',
        color: 'blackAlpha',
        width: '220px',
        ...sx
      }}
      {...rest}
    >
      <Heading textAlign="center" fontSize="18px" textTransform="lowercase">
        {children}
      </Heading>
    </Th>
  )
}

const MemoizedRow = React.memo(
  ({ row, prepareRow }) => {
    prepareRow(row)
    return (
      <Tr
        key={row.original.orgInsurancePayer.id}
        {...row.getRowProps()}
        _hover={{
          backgroundColor: 'rgba(45, 84, 232, 0.02)'
        }}
      >
        {row.cells.map((cell, j) => (
          <Td
            _first={{
              pl: 'small',
              borderLeft: '1px solid',
              borderLeftColor: 'pale_gray'
            }}
            _last={{
              borderRight: '1px solid',
              borderRightColor: 'pale_gray'
            }}
            key={j}
            {...cell.getCellProps()}
            textTransform="capitalize"
            borderColor="pale_gray"
            verticalAlign="middle"
            paddingBottom="xsmall"
            paddingTop="xsmall"
            paddingLeft={j % 2 === 0 ? 'xsmall' : 'xxsmall'}
            paddingRight={j % 2 === 0 ? 'xxsmall' : 'xsmall'}
            sx={cell.column.style}
          >
            {cell.render('Cell')}
          </Td>
        ))}
      </Tr>
    )
  },
  (props, nextProps) =>
    _.isEqual(props.row.original, nextProps.row.original) &&
    props.preferencesUpdatedAt === nextProps.preferencesUpdatedAt
)

export const BillingRulesTable = React.memo(
  BillingRulesTableUnmemo,
  (props, nextProps) =>
    props.rulesUpdatedAt === nextProps.rulesUpdatedAt &&
    props.preferencesUpdatedAt === nextProps.preferencesUpdatedAt
)
