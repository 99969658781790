import React, { useMemo } from 'react'
import { Box, Flex, Text, VStack, Link, HStack } from '@chakra-ui/react'
import { DateTime } from 'luxon'
import { Button } from '@blueprinthq/joy'
import { Link as RouterLink } from 'react-router-dom'
import {
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'
import { useStoreActions } from 'easy-peasy'
import { SessionRecapAssessmentDto } from '~/clinician-api/models'
import { SessionRecapResponse } from '~/clinician-api/models'
import { formatTimePassedText } from '../util'
import { buildAssessmentTakeNowUrl } from '@utilities'

function MiniChart({ data, midpoint, maxScore }: any) {
  return (
    <ResponsiveContainer width={'100%'} height={'100%'}>
      {/* @ts-ignore */}
      <LineChart width={'100%'} height={'100%'} data={data}>
        <Line
          type="monotone"
          dataKey="totalScore"
          stroke="#2D54E8"
          strokeWidth={2}
          dot={false}
        />
        <ReferenceLine
          y={midpoint}
          stroke={'#E4E5E6'}
          strokeDasharray={'5 3'}
        />
        <XAxis
          hide={true}
          dataKey="millis"
          type="number"
          domain={['dataMin', 'dataMax']}
        />
        <YAxis
          hide={true}
          dataKey="totalScore"
          type="number"
          domain={[0, maxScore]}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

function getLastCompletedCopy(assessment: SessionRecapAssessmentDto) {
  if (assessment.assignedAssessment.lastCompletedDate === null)
    return `This assessment has not been completed by ${assessment.user.fullName} (${assessment.user.displayType})`

  const lastCompletedDatetime = DateTime.fromISO(
    assessment.assignedAssessment.lastCompletedDate
  ).toLocal()

  return `Last completed by ${assessment.user.fullName} (${
    assessment.user.displayType
  }), ${formatTimePassedText(lastCompletedDatetime)}`
}

interface AssessmentProgressProps {
  recap: SessionRecapResponse | undefined
  clientId: string
  containerProps: any
}

export function AssessmentProgress({
  recap,
  clientId,
  containerProps
}: AssessmentProgressProps) {
  const { openModal: openAssessmentModal } = useStoreActions(
    // @ts-ignore
    actions => actions.modals.assessmentResult
  )

  const assessments = useMemo(() => {
    return recap?.assessments.map((assessment, key) => {
      // pull out data
      const { assignedAssessment, recentScoreTimeline } = assessment
      const { lastCompletedDate, isOverdue } = assignedAssessment
      const { content, name, fullName } = assignedAssessment.assessment
      const { totalScore } = content
      const userId = assignedAssessment.assigneeUserId

      // setup graph data
      const midpoint = Math.floor(totalScore / 2)
      const graphData = recentScoreTimeline.map(score => {
        return {
          millis: DateTime.fromISO(score.completedAt).toMillis(),
          totalScore: score.totalScore
        }
      })

      // get time diff
      const lastCompletedCopy = getLastCompletedCopy(assessment)

      const onClickAssignedAssessment = () => {
        if (lastCompletedDate !== null) {
          openAssessmentModal({
            clientId,
            preselectedAssessmentId: assignedAssessment.assessment.id,
            preselectedAssessmentScoreId:
              assignedAssessment.lastCompletedAssessmentScoreId
          })
        }
      }

      const isNewAssessment = !isOverdue && lastCompletedDate === null

      return (
        <Flex
          onClick={onClickAssignedAssessment}
          cursor={lastCompletedDate !== null ? 'pointer' : 'initial'}
          key={key}
          p={'16px'}
          justifyContent={'space-between'}
          borderBottom={
            key !== recap.assessments.length - 1
              ? '1px solid #E4E5E6'
              : 'initial'
          }
        >
          <VStack align={'stretch'} spacing={'1'}>
            <Text
              color={'#2D54E8'}
              fontWeight={'bold'}
            >{`${fullName} (${name})`}</Text>
            {lastCompletedDate !== null && (
              <HStack spacing={'1'}>
                <Text fontWeight="bold">
                  {assessment.assignedAssessment.assessment.disorder}
                </Text>
                <Box
                  borderRadius={'4px'}
                  backgroundColor={'pale_gray'}
                  py={'4px'}
                  px={'8px'}
                >
                  <Text fontSize={'11px'} fontWeight={'bold'}>
                    {assessment.scoreInterpretation}
                  </Text>
                </Box>
                <Text>{assessment.scoreChangeInterpretation}</Text>
              </HStack>
            )}
            <Box>
              {assessment.subScales.map(subScore => (
                <HStack
                  spacing={'1'}
                  ml="medium"
                  mb="xxsmall"
                  key={subScore.key}
                >
                  <Text fontWeight="bold" as="span">
                    {subScore.display}
                  </Text>
                  {subScore.label && (
                    <Box
                      borderRadius={'4px'}
                      backgroundColor={'pale_gray'}
                      py={'4px'}
                      px={'8px'}
                    >
                      <Text fontSize={'11px'} fontWeight={'bold'}>
                        {subScore.label}
                      </Text>
                    </Box>
                  )}
                  <Text>
                    {subScore.maxValue
                      ? `(${subScore.value} out of ${subScore.maxValue})`
                      : `(${subScore.value})`}
                  </Text>
                </HStack>
              ))}
            </Box>
            <Text color={'#A1A1A1'}>{lastCompletedCopy}</Text>
          </VStack>
          <Box width={'120px'} height="80px">
            {isOverdue || isNewAssessment ? (
              <VStack spacing={'0'}>
                {isOverdue && <Text color={'#E07800'}>Overdue</Text>}
                <Link
                  onClick={e => e.stopPropagation()}
                  as={RouterLink}
                  color={'primary'}
                  to={{
                    pathname: buildAssessmentTakeNowUrl(
                      userId,
                      assignedAssessment.assessment.id
                    )
                  }}
                  _hover={{
                    textDecoration: 'none'
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button>Take Now</Button>
                </Link>
              </VStack>
            ) : (
              <MiniChart
                data={graphData}
                midpoint={midpoint}
                maxScore={totalScore}
              />
            )}
          </Box>
        </Flex>
      )
    })
  }, [recap?.assessments, clientId])

  if (recap?.assessments.length === 0) return null

  return (
    <Box border={'1px solid #E4E5E6'} borderRadius={'8px'} {...containerProps}>
      <VStack align={'stretch'}>{assessments}</VStack>
    </Box>
  )
}
