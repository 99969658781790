import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const Loading = () => (
  <div style={{ display: 'flex', height: '100%' }}>
    <CircularProgress style={{ margin: 'auto' }} color={'primary'} />
  </div>
)

export default Loading
