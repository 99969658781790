import React from 'react'
import { VStack, HStack, Box, Text } from '@chakra-ui/react'
import { CloseIcon } from '@blueprinthq/joy'
import { FieldArray } from 'formik'
import _ from 'lodash'

const SymptomTrackersComponent = ({ formHelpers }) => {
  const { values } = formHelpers

  return (
    <Box
      borderTop={values.symptomTrackers.length && '1px solid #E4E5E6'}
      mt={values.symptomTrackers.length && 'small'}
    >
      <FieldArray
        name="symptomTrackers"
        render={arrayHelpers => {
          return values.symptomTrackers.length ? (
            <Box>
              {values.symptomTrackers.map((st, index) => (
                <HStack
                  key={st.id}
                  py="xsmall"
                  borderBottom="1px solid #E4E5E6"
                >
                  <VStack flex={6} align="flex-start">
                    <Text fontWeight="bold">{st.title}</Text>
                    <Text>{`${st.subtitle} | ${st.numQuestions} questions`}</Text>
                  </VStack>
                  <CloseIcon
                    fill={!st.canToggle && '#E4E5E6'}
                    ml="small"
                    cursor={!st.canToggle ? 'not-allowed' : 'pointer'}
                    onClick={() => {
                      if (!st.canToggle) return
                      arrayHelpers.remove(index)
                    }}
                  />
                </HStack>
              ))}
            </Box>
          ) : null
        }}
      />
    </Box>
  )
}

export const SymptomTrackers = React.memo(
  SymptomTrackersComponent,
  (props, nextProps) => _.isEqual(props, nextProps)
)
