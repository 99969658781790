import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Text,
  Flex,
  Button,
  HStack,
  useToast,
  Tooltip
} from '@chakra-ui/react'
import { endpoints } from '@api'
import { useAudioCapture } from '@hooks'
import {
  useSessionControllerPostSession,
  useSessionControllerPatchSession,
  usePatientControllerGetPatients
} from '~/clinician-api'
import { throttle } from 'lodash'
import { Loading } from '@components'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { useExtension } from '@hooks'
import { SearchSelect } from '@components'
import { components } from 'react-select'
import {
  PersonAddIcon,
  InPersonFilled,
  Online,
  NoHeadsetIcon,
  Headphones,
  InfoIcon
} from '@blueprinthq/joy'
import {
  MicrophoneSelector,
  AudioLevel
} from '@handlers/sessions/components/microphone-selector'
import { RadioSelectedIcon, SearchIcon } from '@blueprinthq/joy'
import { IconSelect } from '../../sessions/components'
import { trackEvent } from '@lib/clinician-tracking'
import { StoreModel } from 'src/store/types'

interface Props {
  sessionId: string | undefined
  setSessionId: (sessionId: string) => void
}

const Control = (props: any) => (
  <components.Control {...props}>
    <SearchIcon name="search" style={{ marginLeft: 16, marginRight: 8 }} />
    {props.children}
  </components.Control>
)

export const SelectClient = ({ sessionId, setSessionId }: Props) => {
  const { ehrClientNames, ehrClientName } = useExtension()
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const [searchInput, setSearchInput] = useState('')
  const [selectedClient, setSelectedClient] = useState<
    { value: string; label: string } | undefined
  >()
  const [isTelehealth, setIsTelehealth] = useState(false)
  const history = useHistory()
  const {
    testAudioInputs,
    hasMicAccess,
    startRecording,
    hasResolvedInputDevicePermissions,
    isUsingHeadphones,
    setIsUsingHeadphones,
    startContentShare,
    promptForDevicePermissions,
    permissionStatus
  } = useAudioCapture()
  const { mutateAsync: createSessionDraft } = useSessionControllerPostSession()
  const { mutateAsync: patchSession } = useSessionControllerPatchSession()
  const toast = useToast()

  useEffect(() => {
    promptForDevicePermissions()
    testAudioInputs()
  }, [hasResolvedInputDevicePermissions])

  const startSession = async (clientId: string) => {
    const sessionDraft = await createSessionDraft({
      data: {
        patientId: clientId,
        isExtension: true,
        isChime: false,
        extensionInitiatedFrom: document.referrer,
        isDictation: false,
        isTelehealth,
        resumeInProgress: true,
        isAssistEnabled: false
      }
    })

    await patchSession({ id: sessionDraft.id, data: { isTelehealth } })

    setSessionId(sessionDraft.id)
  }

  const autoSelectClients = flagsmith.hasFeature(
    FlagsmithFeatures.EXTENSION_AUTO_SELECT_CLIENTS
  )

  const isInternalDemoOrg = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_IS_DEMO_CLIENTS_IN_START_SESSION
  )

  const {
    data: preferredClientList,
    isLoading: isPreferredClientListLoading
  } = useQuery(
    [endpoints.getPreferredClientList.getCacheId(), ehrClientNames],
    () =>
      endpoints.getPreferredClientList.request({
        clinicianId: user!.id,
        preferredClientInfo: {
          clientNames: ehrClientNames
        }
      }),
    {
      initialData: { clientsFromEHR: [], clientsWithSessionLastWeek: [] },
      select: (data: any) => ({
        clientsFromEHR: data.clientsFromEHR.filter(
          (n: any) => !n.isDemo || isInternalDemoOrg
        ),
        clientsWithSessionLastWeek: data.clientsWithSessionLastWeek.filter(
          (n: any) =>
            (!n.isDemo || isInternalDemoOrg) &&
            !data.clientsFromEHR.map((c: any) => c.id).includes(n.id)
        )
      })
    }
  )

  const { data: defaultList, isLoading: isDefaultListLoading } = useQuery(
    [endpoints.getClientList.getCacheId()],
    () =>
      endpoints.getClientList.request({
        limit: 50,
        status: 'active',
        clinicianId: user!.id
      }),
    {
      initialData: [],
      select: (data: any) =>
        data.filter((n: any) => !n.isDemo || isInternalDemoOrg)
    }
  )

  const { refetch, data: searchResults, isLoading: isSearchLoading } = useQuery(
    [
      endpoints.getPatientSearch.getCacheId(),
      'session_client_search',
      searchInput
    ],
    () =>
      endpoints.getPatientSearch.request({
        search: searchInput,
        include_discharged: false
      }),
    {
      enabled: false,
      initialData: [],
      select: (data: any) =>
        data
          .filter((n: any) => !n.is_demo || isInternalDemoOrg)
          .map((n: any) => ({
            id: n.id,
            firstName: n.first_name,
            lastName: n.last_name
          }))
    }
  )

  const { data: autoSelectedClient } = usePatientControllerGetPatients(
    {
      clinicianId: user!.id,
      firstName: ehrClientName?.split(' ')[0]!,
      lastName: ehrClientName?.split(' ')?.[1]!,
      status: 'active',
      limit: 1
    },
    {
      query: {
        enabled: autoSelectClients && !!ehrClientName
      }
    }
  )

  useEffect(() => {
    if (searchInput?.length >= 1) {
      throttledSearch()
    }
  }, [searchInput])

  useEffect(() => {
    const client = autoSelectedClient?.[0]
    if (!!client) {
      startSession(client.id)
      setSelectedClient({ value: client.id, label: ehrClientName as string })
    } else {
      setSelectedClient(undefined)
    }
  }, [autoSelectedClient])

  const throttledSearch = throttle(() => refetch(), 150)

  const handleStartRecording = async () => {
    if (!selectedClient)
      return toast({
        title: 'Error',
        description: 'Select a client to start recording',
        status: 'error',
        isClosable: true,
        duration: 2000
      })

    if (!hasMicAccess)
      return toast({
        title: 'Error',
        description: 'Enable you microphone to start recording',
        status: 'error',
        isClosable: true,
        duration: 2000
      })

    await startRecording({
      noteType: 'soap',
      isTelehealth,
      isUsingHeadphones: isUsingHeadphones
    })

    const isAutoSelectedClient =
      autoSelectedClient?.[0]?.id === selectedClient.value

    const isSuggestedClient = preferredClientList?.clientsFromEHR?.some(
      (c: any) => c.id === selectedClient.value
    )
    const isLastWeekClient = preferredClientList?.clientsWithSessionLastWeek?.some(
      (c: any) => c.id === selectedClient.value
    )

    if (isAutoSelectedClient) {
      trackEvent(`Session -> Selected Client - Auto Suggested Client`)
    } else if (isSuggestedClient) {
      const clientPositionInList = preferredClientList?.clientsFromEHR?.findIndex(
        (c: any) => c.id === selectedClient.value
      )
      trackEvent(
        `Session -> Selected Client - Suggested Client ${clientPositionInList +
          1}`
      )
    } else if (isLastWeekClient) {
      trackEvent('Session -> Selected Client - Session Last Week')
    } else {
      trackEvent('Session -> Selected Client - All Clients')
    }

    trackEvent('Session -> Started Recording')

    history.push(
      `/extension/patient/${selectedClient.value}/session/${sessionId}`
    )
  }

  const patients = searchInput?.length
    ? searchResults
    : defaultList.filter(
        // don't show clients that are in the preferred client list
        (p: any) =>
          !preferredClientList!.clientsFromEHR
            .map((c: any) => c.id)
            .includes(p.id) &&
          !preferredClientList!.clientsWithSessionLastWeek
            .map((c: any) => c.id)
            .includes(p.id)
      )

  if (isDefaultListLoading || isPreferredClientListLoading) {
    return (
      <Box h="70vh" color="white">
        <Loading />
      </Box>
    )
  }

  const selectCLientOptions = [
    {
      label: '',
      options: [
        {
          value: 'newClient',
          label: 'Add a new client'
        }
      ]
    },
    ...(preferredClientList?.clientsFromEHR?.length && !searchInput
      ? [
          {
            label: 'Suggested clients',
            options: preferredClientList?.clientsFromEHR.map((c: any) => ({
              value: c.id,
              label: `${c.firstName} ${c.lastName}`
            }))
          }
        ]
      : []),
    ...(preferredClientList?.clientsWithSessionLastWeek?.length && !searchInput
      ? [
          {
            label: 'Clients you met with last week',
            options: preferredClientList?.clientsWithSessionLastWeek.map(
              (c: any) => ({
                value: c.id,
                label: `${c.firstName} ${c.lastName}`
              })
            )
          }
        ]
      : []),
    {
      label:
        (preferredClientList?.clientsFromEHR?.length ||
          preferredClientList?.clientsWithSessionLastWeek?.length) &&
        !searchInput
          ? 'All clients'
          : '',
      options: patients.map((p: any) => ({
        value: p.id,
        label: `${p.firstName} ${p.lastName}`
      }))
    }
  ]

  return (
    <Flex
      h="100%"
      backgroundColor="primary"
      flexDirection="column"
      justifyContent="space-between"
      id="bp-extension-loaded"
      p="xsmall"
    >
      <Box>
        <Text color="white" as="b">
          Client:
        </Text>
        <SearchSelect
          size="small"
          placeholder="Select or add a client"
          options={selectCLientOptions}
          value={selectedClient}
          filterOption={null}
          onSearch={setSearchInput}
          onChange={async (option: any) => {
            if (option.value === 'newClient') {
              history.push('/start-session/new-client')
            } else {
              await startSession(option.value)
              setSelectedClient(option)
            }
          }}
          Components={{
            Option: (props: any) => (
              <components.Option {...props}>
                <Flex alignItems="center">
                  {props.data.value === 'newClient' && (
                    <Box mt="8px" mr="8px">
                      <PersonAddIcon />
                    </Box>
                  )}
                  {props.data.label}
                </Flex>
              </components.Option>
            ),
            Control
          }}
          errors={null}
        />
        <Box mt="xsmall" mb="xsmall">
          <MicrophoneSelector isExtension />
          {permissionStatus === 'denied' && (
            <Text color="white" mt="xsmall">
              Please allow access in your browser and refresh the page.
            </Text>
          )}
        </Box>
        <Text color="white" as="b">
          Test your microphone:
        </Text>
        <Flex
          alignItems="center"
          border="1px solid #C9C9C9"
          w="100%"
          bg="white"
          borderRadius="6px"
          h="40px"
          pl="small"
          mt="xsmall"
          mb="xsmall"
        >
          <AudioLevel />
        </Flex>
        <Text color="white" as="b">
          Setting:
        </Text>
        <Box mb="xsmall" mt="xsmall">
          <IconSelect
            onChange={async (value: string) => {
              if (sessionId)
                await patchSession({
                  id: sessionId,
                  data: { isTelehealth: value === 'telehealth' }
                })

              setIsTelehealth(true)
            }}
            selectedValue={isTelehealth ? 'telehealth' : 'inPerson'}
            options={[
              { value: 'telehealth', title: 'Telehealth', icon: <Online /> },
              {
                value: 'inPerson',
                title: 'In-Person',
                icon: <InPersonFilled />
              }
            ]}
            height="40px"
          />
        </Box>
        {isTelehealth && (
          <>
            <Flex>
              <Text color="white" as="b">
                Headphones:
              </Text>
              <Tooltip
                label={`When using headphones, we can only capture what your client is saying if you are running your telehealth session in another browser tab. Select 'Using headphones' and you will be prompted to share audio from your telehealth tab.`}
              >
                <Box ml={1} w="20px" h="20px">
                  <InfoIcon fill="white" />
                </Box>
              </Tooltip>
            </Flex>
            <Box mb="xsmall" mt="xsmall">
              <IconSelect
                onChange={async (value: string) => {
                  const isUsingHeadphones = value === 'headphones'
                  setIsUsingHeadphones(isUsingHeadphones)
                  if (sessionId)
                    await patchSession({
                      id: sessionId as string,
                      data: { isUsingHeadphones }
                    })
                }}
                selectedValue={
                  isUsingHeadphones ? 'headphones' : 'noHeadphones'
                }
                options={[
                  {
                    value: 'headphones',
                    title: 'Using headphones',
                    icon: <Headphones />
                  },
                  {
                    value: 'noHeadphones',
                    title: 'Not using headphones',
                    icon: <NoHeadsetIcon />
                  }
                ]}
                height="40px"
              />
            </Box>
          </>
        )}
      </Box>
      <Button
        aria-label="Start Recording"
        bg="black"
        onClick={handleStartRecording}
        isFullWidth
        size="lg"
        borderRadius="4px"
        background="#282828"
        m={0}
      >
        <HStack spacing={2}>
          <RadioSelectedIcon fill="#68E19F" />
          <Text fontWeight="bold" color="white">
            Start Recording
          </Text>
        </HStack>
      </Button>
    </Flex>
  )
}
