/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type UpdateCheckInNotificationDtoFrequencyType = typeof UpdateCheckInNotificationDtoFrequencyType[keyof typeof UpdateCheckInNotificationDtoFrequencyType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCheckInNotificationDtoFrequencyType = {
  weekly: 'weekly',
  one_time: 'one_time',
} as const;
