import React from 'react'
import { Box, Text } from '@chakra-ui/react'

import { WorkflowNode } from '../node'
import { NodeProps } from '../../node-catalog'
import { FilterCatalog } from '../../drawers/triggers/upcoming-appointment-trigger/filters/filter-catalog'

import { snakeToCapital } from '@utilities'

export const UpcomingAppointmentTrigger = ({
  config,
  hasChildren,
  isParent,
  isSelected,
  onClick
}: NodeProps) => {
  const { filters, timeUnit, timeValue }: any = config
  let body

  if (!timeUnit || !timeValue) {
    body = (
      <Box>
        <Text>Please configure this Trigger</Text>
      </Box>
    )
  } else if (!filters || !filters.length) {
    body = (
      <Box>
        <Text>
          Client has an upcoming appointment in{' '}
          <Text as="b">
            {timeValue} {timeUnit}
          </Text>
        </Text>
      </Box>
    )
  } else {
    body = (
      <Box>
        <Text mb="small">
          Client has an upcoming appointment in{' '}
          <Text as="b">
            {timeValue} {timeUnit}
          </Text>
        </Text>
        {filters.map((f: any, idx: number) => {
          if (f.filterType === 'appointmentType') {
            return (
              <>
                {idx > 0 && (
                  <Box key={`appointmentTypeAnd-${idx}`} my="small">
                    <Text>and</Text>
                  </Box>
                )}
                <Text key={`appointmentType-${idx}`}>
                  Appointment type
                  <Text as="b">
                    {' '}
                    {f.filterOperator} {f.filterValues.join(' or ')}
                  </Text>
                </Text>
              </>
            )
          }
          if (f.filterType === 'clientStatus') {
            const [firstValue, ...otherValues] = f.filterValues
            return (
              <Text key={`clientStatus-${idx}`} mt="small">
                Client status
                <Text as="b" display="inline">
                  {' '}
                  {f.filterOperator} {snakeToCapital(firstValue)}
                </Text>
                {!!otherValues.length &&
                  otherValues.map((v: string, idx: number) => (
                    <Text key={`clientStatus-${idx}`} display="inline">
                      {' '}
                      or <Text as="b">{snakeToCapital(v)}</Text>
                    </Text>
                  ))}
              </Text>
            )
          }
          if (f.filterType === 'clientAge') {
            const { filterType, filterOperator, filterValues } = f
            return (
              <Text key={`clientAge-${idx}`} mt="small">
                Client age is{' '}
                <Text as="b">
                  {
                    FilterCatalog[filterType].constants.operators.find(
                      (o: { display: string; value: string }) =>
                        o.value === filterOperator
                    ).display
                  }{' '}
                  {filterOperator === 'between-inclusive'
                    ? `${filterValues[0]} and ${filterValues[1]}`
                    : filterValues[0]}
                </Text>
              </Text>
            )
          }
          if (f.filterType === 'programAssignment') {
            const [firstValue, ...otherValues] = f.filterValues
            return (
              <Text key={`programAssignment-${idx}`} mt="small">
                Client <Text as="b">{f.filterOperator} </Text>
                assigned to program <Text as="b"> {firstValue.name}</Text>
                {!!otherValues.length &&
                  otherValues.map((v: any, idx: number) => (
                    <Text key={`programAssignment-${idx}`} display="inline">
                      {' '}
                      or <Text as="b">{v.name}</Text>
                    </Text>
                  ))}
              </Text>
            )
          }
          return null
        })}
      </Box>
    )
  }

  return (
    <WorkflowNode
      heading="Upcoming Appointment Trigger"
      body={body}
      isParent={isParent}
      hasChildren={hasChildren}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}
