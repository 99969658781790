import React, { useState, useCallback } from 'react'
import {
  Box,
  Text,
  Flex,
  Link,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  useToast,
  VStack
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Button, Checkbox } from '@blueprinthq/joy'

import { endpoints } from '@api'

export function SubmitInterventionModal({
  handleClose,
  isOpen,
  homeworkModules,
  clientResponses,
  clientInterventionId,
  turnOffUnloadAlert
}) {
  const [assignHomework, setAssignHomework] = useState(false)

  let match = useRouteMatch('/patient/:clientId/interventions/:interventionId')

  const history = useHistory()

  const queryClient = useQueryClient()
  const toast = useToast()
  const { mutateAsync: executeSubmitIntervention, isLoading } = useMutation(
    endpoints[
      clientInterventionId ? 'putClientIntervention' : 'postClientIntervention'
    ].request,
    {
      onError: () => {
        handleClose()
        toast({
          title: 'Error',
          description: 'There was an error submitting this activity',
          status: 'error',
          isClosable: true,
          duration: 2000,
          position: 'bottom-left'
        })
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([
            endpoints.getInterventionOverview.getCacheId(),
            {
              clientId: match.params.clientId,
              interventionId: match.params.interventionId
            }
          ]),
          queryClient.invalidateQueries([
            endpoints.getClientIntervention.getCacheId(),
            {
              clientId: match.params.clientId,
              interventionId: match.params.interventionId
            }
          ])
        ])

        // turn off tab close listener
        turnOffUnloadAlert()

        // replace so you cant navigate back
        history.replace({
          pathname: `/patient/${match.params.clientId}/interventions/${match.params.clientId}/complete`,
          search: `?type=finished`
        })
      }
    }
  )

  const submit = useCallback(async () => {
    const formattedResponses = Object.keys(clientResponses).map(k => ({
      interventionItemId: k,
      response: { value: clientResponses[k] }
    }))

    const body = {
      clientId: match.params.clientId,
      interventionId: match.params.interventionId,
      data: {
        isComplete: true,
        responses: formattedResponses,
        assignHomework
      }
    }

    if (clientInterventionId) {
      body.clientInterventionId = clientInterventionId
    }

    await executeSubmitIntervention(body)
  }, [assignHomework, clientResponses])

  return (
    <Modal
      onClose={handleClose}
      isOpen={isOpen}
      preserveScrollBarGap
      sx={{
        '*': {
          fontSize: '16px'
        }
      }}
    >
      <ModalOverlay />
      <ModalContent p="small" maxWidth={'407px'}>
        <ModalHeader
          id="pendo-intervention-submit-modal-header-tag"
          textAlign="center"
          pb={'8px'}
        >
          <Text fontSize={'24px'}>Submit Intervention</Text>
          <Text fontWeight={'normal'}>
            Are you sure you want to submit this intervention?
          </Text>
        </ModalHeader>
        {homeworkModules && homeworkModules.length > 0 && (
          <ModalBody>
            <Flex flexDirection={'column'}>
              <Checkbox
                id={`pendotag-int-${match.params.interventionId}-hw-assign-AFTER-all`}
                isChecked={assignHomework}
                onChange={e => {
                  setAssignHomework(e.target.checked)
                }}
                fontWeight={'bold'}
                mb={'16px'}
              >
                Assign the recommended homework:
              </Checkbox>
              <VStack spacing={'16px'}>
                {homeworkModules.map((hw, key) => {
                  return (
                    <Box
                      key={key}
                      border={'1px solid #E4E5E6'}
                      borderRadius={'8px'}
                      padding={'16px'}
                      width={'100%'}
                    >
                      <VStack
                        spacing={'2px'}
                        sx={{
                          '*': {
                            width: '100%'
                          }
                        }}
                      >
                        <Text fontWeight={'bold'}>{hw.title}</Text>
                        <Text>{hw.subtitle}</Text>
                        <Flex
                          direction={'row'}
                          justifyContent={'space-between'}
                        >
                          <Text>
                            {`${hw.numQuestions} question${
                              hw.numQuestions > 1 ? 's' : ''
                            }`}
                          </Text>
                          {hw.previewURL && (
                            <Link
                              id={`pendotag-int-${match.params.interventionId}-hw-preview-AFTER-${hw.id}`}
                              as="a"
                              color="primary"
                              target="_blank"
                              href={hw.previewURL}
                              textAlign={'right'}
                            >
                              Preview
                            </Link>
                          )}
                        </Flex>
                      </VStack>
                    </Box>
                  )
                })}
              </VStack>
            </Flex>
          </ModalBody>
        )}
        <ModalFooter>
          <Flex justify="space-between" w="100%">
            <Button
              w="164px"
              size="lg"
              onClick={() => {
                handleClose()
              }}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              id={`pendotag-intervention-submission-${match.params.interventionId}`}
              size="lg"
              w="164px"
              isLoading={isLoading}
              onClick={submit}
            >
              Submit
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
