import React from 'react'
import { Button } from '@blueprinthq/joy'

export const SelectableButton = ({ selected, ...rest }) => {
  return (
    <Button
      bg={selected ? 'primary' : 'white'}
      color={selected ? 'white' : 'black'}
      variant="outline"
      width="172px"
      height="54px"
      borderRadius="0px"
      {...rest}
    />
  )
}
