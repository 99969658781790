import React, { useContext } from 'react'

import { drawerCatalog } from '../drawer-catalog'
import { WorkflowsContext } from '@context'
import { EditActionDrawer } from './edit-action-drawer'
import { EditTriggerDrawer } from './edit-trigger-drawer'

type DrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
}

export const EditDrawer = ({ isOpen, closeDrawer }: DrawerProps) => {
  const { selectedNode } = useContext(WorkflowsContext)

  const isActionDrawer =
    selectedNode &&
    (Object.keys(drawerCatalog.actions).includes(selectedNode.type) ||
      selectedNode.type === 'new-action-node')

  return isActionDrawer ? (
    <EditActionDrawer isOpen={isOpen} closeDrawer={closeDrawer} />
  ) : (
    <EditTriggerDrawer isOpen={isOpen} closeDrawer={closeDrawer} />
  )
}
