// @ts-nocheck

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useToast,
  Heading,
  Text,
  Box,
  HStack,
  Button,
  Slide,
  Center,
  Flex,
  Stack,
  Skeleton
} from '@chakra-ui/react'
import { Loading } from '@components'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { endpoints } from '@api'
import SendIcon from '@material-ui/icons/Send'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { AssessmentList } from './assessment-list'
import { AssignAssessmentsModal } from '@containers/assign-measures-modal'
import { AdjustDeliveryModal } from '@containers'
import CalendarToday from '@material-ui/icons/CalendarToday'
import {
  openLinkInNewTab,
  buildAssessmentTakeNowUrl,
  buildAssessmentPreviewUrl
} from '@utilities'
import { MeasureTypes } from '@constants/measureTypes'
import { useStoreActions } from 'easy-peasy'
export default function AssessmentSelector(props) {
  const { user_id, patient } = props
  const queryClient = useQueryClient()
  const toast = useToast()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})
  const [singleCheckedItems, setSingleCheckedItems] = useState({})
  const [isAllChecked, setIsAllChecked] = useState({})
  const [unassignLoading, setUnassignLoading] = useState(false)

  const openAssignAssessmentsModal = useStoreActions(
    actions => actions.modals.assignMeasures.openModal
  )

  const {
    isLoading: areAssessmentsLoading,
    data: clientAssessmentsGroupedByAssignee,
    dataUpdatedAt
  } = useQuery(
    [endpoints.getAllAssessmentsForClientByAssignee.getCacheId(), user_id],
    () =>
      endpoints.getAllAssessmentsForClientByAssignee.request({ id: user_id }),
    {
      initialData: []
    }
  )

  const {
    mutate: sendPatientAssessmentLink,
    isLoading: isSendingAssessment
  } = useMutation(endpoints.postSendPatientAssessmentLink.request)

  const { mutateAsync: executeUnassignAssessments } = useMutation(
    endpoints.deleteClientUnassignAssessments.request,
    {
      onMutate: async ({ data }) => {
        await queryClient.cancelQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])

        const previousAssessmentListByAssignee = queryClient.getQueryData([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])
        const previousActiveAssessmentList = queryClient.getQueryData([
          endpoints.getClinicianUserActiveAssessments.getCacheId(),
          user_id
        ])

        const nextAssessmentList = previousAssessmentListByAssignee
          .find(paba => paba.assigneeUserId === data.assigneeUserId)
          .assessments.map(assessment => {
            if (data.clinicAssessmentIds.includes(assessment.id)) {
              assessment.isActive = false
            }
            return assessment
          })

        queryClient.setQueryData(
          [
            endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
            user_id
          ],
          previousAssessmentListByAssignee.map(paba => {
            if (paba.assigneeUserId === data.assigneeUserId) {
              return {
                assigneeUserId: data.assigneeUserId,
                assessments: nextAssessmentList
              }
            }
            return paba
          })
        )
        queryClient.setQueryData(
          [endpoints.getClinicianUserActiveAssessments.getCacheId(), user_id],
          () =>
            clientAssessmentsGroupedByAssignee
              .flatMap(aba => aba.assessments)
              .filter(assessment => assessment.isActive === true)
        )

        return {
          previousAssessmentListByAssignee,
          previousActiveAssessmentList
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
          user_id
        ])
      },
      onError: (err, newlyInactive, context) => {
        toast({
          title: 'Error',
          description: 'There was an error unassigning this measure',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
        queryClient.setQueryData(
          [
            endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
            user_id
          ],
          context.previousAssessmentListByAssignee
        )
        queryClient.setQueryData(
          [endpoints.getClinicianUserActiveAssessments.getCacheId(), user_id],
          context.previousActiveAssessmentList
        )
      }
    }
  )

  const { mutateAsync: executeUpdateAssessmentCadence } = useMutation(
    endpoints.patchClinicianUserAssessmentsV2.request,
    {
      onMutate: async ({ assessment_id, data }) => {
        await queryClient.cancelQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])

        const previousAssessmentListByAssignee = queryClient.getQueryData([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])

        const nextAssessmentList = previousAssessmentListByAssignee
          .find(paba => paba.assigneeUserId === data.assigneeUserId)
          .assessments.map(a => {
            if (a.id === assessment_id) {
              a.cadence_value = data.cadence_value
              a.cadence_unit = data.cadence_unit
            }
            return a
          })

        queryClient.setQueryData(
          [
            endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
            user_id
          ],
          previousAssessmentListByAssignee.map(paba => {
            if (paba.assigneeUserId === data.assigneeUserId) {
              return {
                assigneeUserId: data.assigneeUserId,
                assessments: nextAssessmentList
              }
            }
            return paba
          })
        )

        return { previousAssessmentListByAssignee }
      },
      onSuccess: async () => {
        toast({
          title: 'Saved!',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        await queryClient.invalidateQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])
        await queryClient.invalidateQueries([
          endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
          user_id
        ])
        await queryClient.invalidateQueries([
          endpoints.getClinicianUserActiveAssessments.getCacheId(),
          user_id
        ])
      },
      onError: async (err, newCadence, context) => {
        toast({
          title: 'Error',
          description: 'There was an error updating the cadence',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
        await queryClient.setQueryData(
          [
            endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
            user_id
          ],
          context.previousAssessmentListByAssignee
        )
      }
    }
  )

  const { mutateAsync: assignClientAssessments } = useMutation(
    endpoints.postClientAssignAssessments.request,
    {
      onSuccess: () => {
        toast({
          title: 'Assessment assigned',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        queryClient.invalidateQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])
        queryClient.invalidateQueries([
          endpoints.getClinicianUserActiveAssessments.getCacheId(),
          user_id
        ])
        queryClient.invalidateQueries([
          endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
          user_id
        ])
      },
      // If the mutation fails, use the context we returned above
      onError: () => {
        toast({
          title: 'Error',
          description: 'There was an error assigning this measure',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  const handleSendPatientAssessmentLink = useCallback(
    (assigneeUser, patientId, clinicAssessmentIds) => {
      sendPatientAssessmentLink(
        {
          patient_id: patientId.id,
          is_reminder: false,
          assigneeUserId: assigneeUser,
          clinicAssessmentIds
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
              user_id
            ])

            queryClient.invalidateQueries([
              endpoints.getClinicianUserAccount.getCacheId(),
              patientId.id
            ])

            toast({
              title: 'Assessments sent',
              status: 'success',
              isClosable: true,
              duration: 2000,
              position: 'bottom'
            })
          },
          onError: () => {
            toast({
              title: 'Error',
              description: `Unable to send assessments.`,
              status: 'error',
              isClosable: true,
              duration: 2000,
              position: 'bottom'
            })
          }
        }
      )
    }
  )

  const onAssignAssessments = useCallback(
    (ids, assigneeUserId) => {
      const assignments = ids.map(a => ({
        clinicAssessmentId: a.id,
        cadence: null,
        assigneeUserId
      }))
      assignClientAssessments({
        clientId: user_id,
        data: {
          assignments
        }
      })
    },
    [user_id]
  )

  const onUnassignAssessment = useCallback(
    async (measure, assigneeUserId) => {
      const activeAssessments = clientAssessmentsGroupedByAssignee
        .flatMap(aba => aba.assessments)
        .filter(assessment => assessment.isActive === true)

      const checkMeasure = clientAssessmentsGroupedByAssignee
        .flatMap(aba => aba.assessments)
        .filter(assessment => assessment.assessment_id === measure)

      await executeUnassignAssessments({
        data: {
          clinicAssessmentIds: [checkMeasure[0].id],
          assigneeUserId
        },
        clientId: user_id
      })
    },
    [user_id, clientAssessmentsGroupedByAssignee]
  )

  const onUpdateAssessment = useCallback(
    async (assessmentId, { unit, value, assigneeUserId }) => {
      await executeUpdateAssessmentCadence({
        id: user_id,
        assessment_id: assessmentId,
        data: {
          cadence_unit: unit,
          cadence_value: value,
          assigneeUserId
        }
      })
    },
    [user_id, clientAssessmentsGroupedByAssignee]
  )

  const assignableAssessments = useMemo(
    () =>
      clientAssessmentsGroupedByAssignee.map(aa => {
        return {
          assigneeUserId: aa.assigneeUserId,
          assessments: aa.assessments
        }
      }),
    [clientAssessmentsGroupedByAssignee]
  )

  if (
    areAssessmentsLoading ||
    clientAssessmentsGroupedByAssignee.length === 0
  ) {
    return <Skeleton height="250px" borderRadius="8px" mt="8px" />
  }

  const handleCheckboxChange = (assessment_id, assigneeUser, isChecked) => {
    setCheckedItems(prevState => {
      const newState = { ...prevState }

      // Ensure that there is an array for this assessment_id
      if (!newState[assessment_id]) {
        newState[assessment_id] = []
      }

      if (isChecked) {
        // Add assigneeUser if it's not already in the array
        if (!newState[assessment_id].includes(assigneeUser)) {
          newState[assessment_id].push(assigneeUser)
        }
      } else {
        // Remove the assigneeUser from the array
        newState[assessment_id] = newState[assessment_id].filter(
          user => user !== assigneeUser
        )

        // If the array becomes empty, remove the key
        if (newState[assessment_id].length === 0) {
          delete newState[assessment_id]
        }
      }

      return newState
    })
  }

  const handleAllCheckboxChange = (cleanedActiveAssessments, assigneeUser) => {
    const currentIsAllChecked = isAllChecked[assigneeUser.id] || false

    setIsAllChecked(prevState => ({
      ...prevState,
      [assigneeUser.id]: !currentIsAllChecked
    }))

    if (currentIsAllChecked) {
      setCheckedItems(prevState => {
        const newState = { ...prevState }
        cleanedActiveAssessments.forEach(assessment => {
          if (
            newState[assessment.assessment_id] &&
            newState[assessment.assessment_id].includes(assigneeUser)
          ) {
            newState[assessment.assessment_id] = newState[
              assessment.assessment_id
            ].filter(user => user !== assigneeUser)

            if (newState[assessment.assessment_id].length === 0) {
              delete newState[assessment.assessment_id]
            }
          }
        })
        return newState
      })
    } else {
      setCheckedItems(prevState => {
        const newState = { ...prevState }
        cleanedActiveAssessments.forEach(assessment => {
          if (!newState[assessment.assessment_id]) {
            newState[assessment.assessment_id] = []
          }
          if (!newState[assessment.assessment_id].includes(assigneeUser)) {
            newState[assessment.assessment_id].push(assigneeUser)
          }
        })
        return newState
      })
    }
  }
  function filterObjectKeysByArrayIds(obj1, arr2) {
    const idsInArr2 = new Set(arr2.map(item => item.assessment_id))
    for (const key in obj1) {
      if (idsInArr2.has(key)) {
        delete obj1[key]
      }
    }
    toast({
      title: 'Assessments unassigned',
      status: 'success',
      isClosable: true,
      duration: 1200
    })
  }

  const resetAllTaskBar = () => {
    setCheckedItems({})
    setIsAllChecked({})
    setSingleCheckedItems({})
    setUnassignLoading(false)
  }

  const unassignAssessments = (checked, data) => {
    setUnassignLoading(true)
    for (const key in checked) {
      if (checked.hasOwnProperty(key)) {
        checked[key].forEach(value => {
          onUnassignAssessment(key, value.id)
        })
      }
    }
    filterObjectKeysByArrayIds(checked, data)
    setUnassignLoading(false)
  }

  const sendNowAssessments = (checked, data, assignee) => {
    setUnassignLoading(true)
    const grouped = {}

    for (const key in checked) {
      const checkMeasure = clientAssessmentsGroupedByAssignee
        .flatMap(aba => aba.assessments)
        .filter(assessment => assessment.assessment_id === key)
      for (const item of checked[key]) {
        if (!grouped[item.id]) {
          grouped[item.id] = { ...item, keys: [checkMeasure[0].id] }
        } else {
          grouped[item.id].keys.push(checkMeasure[0].id)
        }
      }
    }

    for (const key in grouped) {
      if (grouped.hasOwnProperty(key)) {
        const element = grouped[key]
        handleSendPatientAssessmentLink(element.id, assignee, element.keys)
      }
    }
    resetAllTaskBar()
  }

  const takeNowAssessments = (checked, data, assignee) => {
    setUnassignLoading(true)
    const grouped = {}

    for (const key in checked) {
      for (const item of checked[key]) {
        const checkMeasure = clientAssessmentsGroupedByAssignee
          .flatMap(aba => aba.assessments)
          .filter(assessment => assessment.assessment_id === key)
        if (!grouped[item.id]) {
          grouped[item.id] = { ...item, keys: [checkMeasure[0].assessment_id] }
        } else {
          grouped[item.id].keys.push(checkMeasure[0].assessment_id)
        }
      }
    }

    const links = []
    for (const key in grouped) {
      if (grouped.hasOwnProperty(key)) {
        const element = grouped[key]
        const link = buildAssessmentTakeNowUrl(
          key,
          element.keys.toString(),
          false
        )
        links.push(link)
      }
    }

    let finalLink = process.env.REACT_APP_CLIENT_APP_URL
    if (links.length > 1) {
      let linksStr = ''
      for (let i = links.length - 1; i >= 0; i--) {
        linksStr =
          i !== links.length - 1
            ? `${links[i]}&more=${encodeURIComponent(linksStr)}`
            : links[i]
      }
      finalLink += linksStr
    } else {
      finalLink += links[0]
    }

    openLinkInNewTab(finalLink)
    resetAllTaskBar()
  }

  function getSoonestNextSendDates(assessments) {
    const userDates = {}
    const todaysDate = new Date()

    for (let assessment of assessments) {
      const userId = assessment.assigneeUser.id
      const assessmentDate = new Date(assessment.nextAdministrationDate)

      // If the assessmentDate is after the referenceDate and either the user doesn't have a date yet or the assessmentDate is sooner than their current date
      if (
        !userDates[userId] ||
        assessmentDate < new Date(userDates[userId].nextAdministrationDate)
      ) {
        userDates[userId] = assessment
      }
    }

    return Object.values(userDates)
  }

  function getMatchingAssessmentsWithClosestDate(users) {
    let closestDate = null
    let matchedAssessments = []
    let assessments = []

    clientAssessmentsGroupedByAssignee.map(cagba => {
      const activeAssessments = cagba.assessments.filter(
        assessment => assessment.isActive
      )
      assessments.push(...activeAssessments)
    })

    for (const assessmentId of Object.keys(users)) {
      for (const userAssessment of users[assessmentId]) {
        const selectedAssessment = assessments.filter(
          assessment =>
            assessment.assessment_id === assessmentId &&
            assessment.assigneeUser.id === userAssessment.id &&
            assessment.nextAdministrationDate !== null
        )
        if (selectedAssessment.length !== 0) {
          matchedAssessments.push(...selectedAssessment)
          const nextAdminDate = new Date(
            selectedAssessment[0].nextAdministrationDate
          )
          if (!closestDate || nextAdminDate < closestDate) {
            closestDate = nextAdminDate
          }
        }
      }
    }
    return getSoonestNextSendDates(matchedAssessments)
  }

  const matchedAssessments = getMatchingAssessmentsWithClosestDate(
    Object.keys(singleCheckedItems).length !== 0
      ? singleCheckedItems
      : checkedItems
  )

  const allAssessments = assignableAssessments[0].assessments.map(a => ({
    measureId: a.id,
    title: `${a.full_name} ${a.name !== a.full_name ? '(' + a.name + ')' : ''}`,
    subtitle: a.disorder,
    numQuestions: a.num_questions,
    previewUrl: buildAssessmentPreviewUrl(a.assessment_id),
    takeNowUrl: buildAssessmentTakeNowUrl(
      clientAssessmentsGroupedByAssignee[0].assigneeUserId,
      a.assessment_id
    ),
    isAssigned:
      a.isActive &&
      a.assigneeUser.id === clientAssessmentsGroupedByAssignee[0].assigneeUserId
  }))

  return (
    <Stack spacing="0" flex="1">
      {matchedAssessments.length !== 0 && isModalOpen && (
        <div style={{ display: 'none' }}>
          <AdjustDeliveryModal
            patient={patient}
            user_id={user_id}
            soonestSendDatesByUser={matchedAssessments}
            isModalOpen={isModalOpen}
            handleModal={() => setIsModalOpen(!isModalOpen)}
            resetAllTaskBar={resetAllTaskBar}
            checkedAssessments={
              Object.keys(singleCheckedItems).length !== 0
                ? singleCheckedItems
                : checkedItems
            }
          />
        </div>
      )}

      {!areAssessmentsLoading &&
        clientAssessmentsGroupedByAssignee.map(cagba => {
          const assignee = patient.assigneeUsers.find(
            assigneeUser => assigneeUser.id === cagba.assigneeUserId
          )
          const assignableAssessmentsForAssigneePatients = assignableAssessments.find(
            aa => aa.assigneeUserId === cagba.assigneeUserId
          ).assessments

          const allAssessmentsForEachAssignee = assignableAssessmentsForAssigneePatients.map(
            a => ({
              measureId: a.id,
              title: `${a.full_name} ${
                a.name !== a.full_name ? '(' + a.name + ')' : ''
              }`,
              subtitle: a.disorder,
              numQuestions: a.num_questions,
              previewUrl: buildAssessmentPreviewUrl(a.assessment_id),
              takeNowUrl: buildAssessmentTakeNowUrl(
                cagba.assigneeUserId,
                a.assessment_id
              ),
              isAssigned:
                a.isActive && a.assigneeUser.id === cagba.assigneeUserId
            })
          )

          const filteredAssessments = [...cagba.assessments]

          const screenerIdx = filteredAssessments.findIndex(a => a.is_screener)
          if (screenerIdx > -1) {
            const [screener] = filteredAssessments.splice(screenerIdx, 1)
            filteredAssessments.unshift(screener)
          }
          return assignee ? (
            <div key={assignee.id}>
              {clientAssessmentsGroupedByAssignee.length > 1 && (
                <HStack justify={'space-between'}>
                  <HStack>
                    <Heading size="small">{`${assignee.info.firstName} ${assignee.info.lastName}`}</Heading>
                    <Heading size="small" color="#a6a6a6" pl="xxxsmall">
                      {assignee.info.title}
                    </Heading>
                  </HStack>

                  <Button
                    onClick={() => {
                      openAssignAssessmentsModal({
                        clientId: user_id,
                        measureType: MeasureTypes.ASSESSMENT,
                        allMeasures: allAssessmentsForEachAssignee,
                        onAssign: data =>
                          onAssignAssessments(data, cagba.assigneeUserId),
                        assigneeUser: {
                          id: assignee.id,
                          info: {
                            firstName: assignee.info.firstName,
                            lastName: assignee.info.lastName
                          }
                        }
                      })
                    }}
                  >
                    Assign Assessments to {assignee.info.firstName}
                  </Button>
                </HStack>
              )}
              <AssessmentList
                patient={patient}
                assigneeUser={assignee}
                onUpdateAssessment={onUpdateAssessment}
                onUnassignAssessment={onUnassignAssessment}
                assessments={cagba.assessments}
                dataUpdatedAt={dataUpdatedAt}
                checkedItems={checkedItems}
                handleCheckboxChange={(a, b, c) =>
                  handleCheckboxChange(a, b, c)
                }
                handleSendNow={sendNowAssessment => {
                  sendNowAssessments(
                    sendNowAssessment,
                    filteredAssessments,
                    patient
                  )
                }}
                handleTakeNow={takeNowAssessment => {
                  takeNowAssessments(
                    takeNowAssessment,
                    filteredAssessments,
                    patient
                  )
                }}
                handleOpenModal={deliveryItems => {
                  setSingleCheckedItems(deliveryItems)
                  setIsModalOpen(true)
                }}
                handleUnassignAssessment={itemToUnassign => {
                  unassignAssessments(itemToUnassign, filteredAssessments)
                }}
                handleAllCheckboxChange={handleAllCheckboxChange}
                isAllChecked={isAllChecked}
              />
              <Slide
                direction="bottom"
                in={Object.values(checkedItems).some(
                  val => val && val.length > 0
                )}
                style={{ zIndex: 10 }}
              >
                <Center>
                  <Box
                    color="white"
                    h={'auto'}
                    mb={'medium'}
                    w={'672px'}
                    bg="#282828"
                    rounded="lg"
                    shadow="md"
                    p={'small'}
                  >
                    <Flex>
                      <Button
                        borderRadius={'4px'}
                        rightIcon={
                          <OpenInNewIcon style={{ height: 16, width: 16 }} />
                        }
                        height={'32px'}
                        margin={'0px 12px 0px 0px'}
                        padding={'0px 12px'}
                        isLoading={unassignLoading}
                        loadingText="Loading..."
                        disabled={unassignLoading}
                        onClick={() => {
                          takeNowAssessments(
                            checkedItems,
                            filteredAssessments,
                            patient
                          )
                        }}
                      >
                        Take Now
                      </Button>
                      <Button
                        borderRadius={'4px'}
                        rightIcon={
                          <SendIcon style={{ height: 16, width: 16 }} />
                        }
                        height={'32px'}
                        margin={'0px 12px 0px 0px'}
                        padding={'0px 12px'}
                        isLoading={unassignLoading}
                        loadingText="Loading..."
                        disabled={unassignLoading}
                        onClick={() => {
                          sendNowAssessments(
                            checkedItems,
                            filteredAssessments,
                            patient
                          )
                        }}
                      >
                        Send Now
                      </Button>
                      {matchedAssessments.length !== 0 && (
                        <Button
                          borderRadius={'4px'}
                          rightIcon={
                            <CalendarToday style={{ height: 16, width: 16 }} />
                          }
                          height={'32px'}
                          margin={'0px 12px 0px 0px'}
                          padding={'0px 12px'}
                          isLoading={unassignLoading}
                          loadingText="Loading..."
                          disabled={unassignLoading}
                          onClick={() => {
                            setIsModalOpen(true)
                          }}
                        >
                          Adjust Next Send
                        </Button>
                      )}
                      <Button
                        borderRadius={'4px'}
                        color={'#FFFFFF'}
                        variant="outline"
                        borderColor={'dark_gray'}
                        height={'32px'}
                        margin={'0px 12px 0px 0px'}
                        padding={'0px 12px'}
                        isLoading={unassignLoading}
                        loadingText="Loading..."
                        disabled={unassignLoading}
                        onClick={() => {
                          unassignAssessments(checkedItems, filteredAssessments)
                        }}
                      >
                        Unassign
                      </Button>
                      <Center>
                        <Text>
                          {// Count the total number of checked items across all assessment_ids
                          Object.values(checkedItems).reduce(
                            (acc, current) => acc + current.length,
                            0
                          )}{' '}
                          selected
                        </Text>
                      </Center>
                    </Flex>
                  </Box>
                </Center>
              </Slide>

              <Box mt="40px"></Box>
            </div>
          ) : null
        })}
    </Stack>
  )
}
