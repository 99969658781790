import React from 'react'

const Send = props => {
  return (
    <svg
      width={props.width || '25px'}
      height={props.height || '17px'}
      viewBox="0 0 22 15"
      version="1.1"
    >
      <g
        id="Enroll"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Quick-enroll"
          transform="translate(-1280.000000, -182.000000)"
          fill={props.fill || '#FFFFFF'}
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(952.000000, 53.000000)">
            <g id="Group-2" transform="translate(314.000000, 112.000000)">
              <path
                d="M35.7896193,17 L14,21.3981757 L20.4033034,24.696741 L35.7896193,17 Z M36,17.0928811 L22.343917,26.9003744 L23.4011244,27.5718036 L30.3770014,32 L36,17.0928811 Z M32.5776151,19.1243308 L19.3151254,25.7594523 L20.3011487,31.1081972 L21.5469654,27.154754 C21.5474006,27.1530005 21.5491686,27.1523895 21.549631,27.1506015 C21.5514535,27.1448524 21.5553432,27.1407877 21.5576008,27.1350359 C21.5684702,27.1058414 21.5822827,27.0775156 21.5990383,27.0509751 C21.6062845,27.0399152 21.6131989,27.0294212 21.621351,27.018805 C21.6299545,27.0077452 21.6384058,26.9973256 21.6479151,26.9871531 C21.6528983,26.9814039 21.6584989,26.9768955 21.6643851,26.9715874 C21.6797807,26.9565478 21.6934762,26.9413461 21.7111376,26.9285196 L32.5776151,19.1243308 Z M22.0803428,27.6563399 L20.8451616,31.6392726 L22.6843875,30.4416829 L24.551766,29.2264528 L24.002449,28.8772289 L22.0803428,27.6563399 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default Send
