export const SESSION_PREP = {
  lastSessionRecap: [
    "You discussed the client's feelings of low energy and difficulty with daily tasks.",
    'You introduced CBT techniques: Behavioral Experimentation, Graded Task Assignment, Pleasurable Activities Scheduling, and Cognitive Restructuring.',
    'Client talked about feeling isolated and having trouble concentrating at work.',
    'You worked on this treatment goal: Client will improve mood and reduce depressive symptoms by engaging in pleasurable activities and completing behavioral experiments.'
  ],
  thisSessionFocusAreas: [
    "Review client's experience with making the bed as a behavioral experiment.",
    'Discuss any changes in mood or productivity from breaking down work tasks.',
    "Explore client's engagement with video gaming and its impact on mood."
  ],
  beforeYouBegin: {
    items: [
      {
        action: 'REVIEW_ASSESSMENT_RESULTS',
        data: {
          completedAssessment: {
            assessmentId: '6bdf26c0-e2d8-11e8-be06-fdb78b82093e',
            name: 'PHQ-9',
            score: 5,
            totalScore: 27
          }
        }
      },
      {
        action: 'REVIEW_WORKSHEET_RESULTS',
        data: {
          completedWorksheets: [{}, {}]
        }
      },
      {
        action: 'VIEW_ASSESSMENT_SUGGESTIONS',
        data: {
          suggestedAssessments: [{}, {}]
        }
      }
    ]
  }
}
