import React from 'react'
import { Box, GridItem, Text } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import { HighRiskToggle } from '@components'

export const AssessmentTriggerBlurb = ({
  assessmentScore,
  assessmentContent
}) => {
  if (
    assessmentScore.high_risk_triggers &&
    assessmentScore.high_risk_triggers.length > 0
  ) {
    const triggerQuestionsAndAnswers = assessmentScore.high_risk_triggers.map(
      trigger => {
        // Get the user response value for the question that triggered high-risk
        const triggeringQuestionId = trigger.question_key
        const triggeringUserResponse = assessmentScore.answers.find(
          answer => answer.key === triggeringQuestionId
        )
        if (!triggeringUserResponse) return null
        const triggeringUserResponseValue = triggeringUserResponse.value

        // Get question copy
        const questions = assessmentContent.sections[0].questions
        const questionMatchIdx = questions.findIndex(
          question => question.key === triggeringQuestionId
        )
        if (!questionMatchIdx) return null
        const questionMatch = questions[questionMatchIdx]
        const questionCopy = `${questionMatchIdx + 1}. ${questionMatch.title}`

        // Get answer copy
        const defaultAnswers = assessmentContent.sections[0].answers
        const answersSet = questionMatch.answers || defaultAnswers
        const answerMatch = answersSet.find(
          answer => answer.value === triggeringUserResponseValue
        )
        if (!answerMatch) return null
        const answerCopy = answerMatch.title

        return (
          <LayoutGrid key={trigger.question_id}>
            <GridItem colSpan={8}>
              <Text>{questionCopy}</Text>
            </GridItem>
            <GridItem colSpan={4}>
              <Text align="right">{answerCopy}</Text>
            </GridItem>
          </LayoutGrid>
        )
      }
    )

    return (
      <Box
        style={{
          marginTop: '30px',
          padding: '10px 30px',
          border: 'solid #B5342A',
          borderWidth: '2px'
        }}
      >
        <HighRiskToggle isHighRisk={true} isInert={true} />
        {triggerQuestionsAndAnswers}
      </Box>
    )
  } else return null
}
