import React from 'react'
import moment from 'moment'
import {
  Box,
  Text,
  Stack,
  HStack,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Flex,
  useToast
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { useStoreState, useStoreActions } from 'easy-peasy'
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { ArrowLeft } from '@components/icons'
import { loadStripe } from '@stripe/stripe-js'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

const stripeStyle = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#1a202c',
      fontWeight: '500',
      fontFamily: 'TofinoPersonal, Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#1a202c'
      },
      '::placeholder': {
        color: '#999999'
      }
    },
    invalid: {
      iconColor: '#e02031',
      color: '#e02031'
    }
  }
}

export const EbcUpgradePlanModal = () => {
  const elements = useElements()
  const stripe = useStripe()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const { isOpen, hasAnyEbcPlan, clinicId } = useStoreState(
    (state: any) => state.modals.ebcUpgradePlan
  )
  const { closeModal } = useStoreActions(
    (actions: any) => actions.modals.ebcUpgradePlan
  )

  const { openModal: openEbcMarketingModal } = useStoreActions(
    (actions: any) => actions.modals.ebcMarketingModal
  )

  const { data: paywallData } = useQuery(
    [endpoints.getPaywallValidation.getCacheId(), 'ebcModal'],
    endpoints.getPaywallValidation.request,
    {
      initialData: {},
      enabled: isOpen
    }
  )

  // @ts-ignore
  const { clinicianCount } = paywallData

  const { mutateAsync: startFreeTrial } = useMutation(
    endpoints.startEbcFreeTrial.request,
    {
      onSuccess: (data: any) => {
        closeModal()
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        )
        queryClient.invalidateQueries([
          endpoints.getPaywallValidation.getCacheId(),
          'ebcModal'
        ])
        queryClient.invalidateQueries(endpoints.getClinicBilling.getCacheId())
        queryClient.invalidateQueries(endpoints.getClinicInvoices.getCacheId())
        toast({
          title: 'Your free trial has started!',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
      },
      onError: (err: any) => {
        console.log(err)
        toast({
          title: 'Something went wrong. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  )
  const { mutateAsync: startEBCSubscription } = useMutation(
    endpoints.startEBCSubscription.request,
    {
      onSuccess: (data: any) => {
        closeModal()
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        )
        queryClient.invalidateQueries(endpoints.getClinicBilling.getCacheId())
        queryClient.invalidateQueries(endpoints.getClinicInvoices.getCacheId())
        toast({
          title: 'Your subscription has started!',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
      },
      onError: (err: any) => {
        console.log(err)
        toast({
          title: 'Something went wrong. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  )

  const handleChange = (event: any) => {
    setError(event.error ? event.error.message : null)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const cardElement = elements?.getElement(CardNumberElement)

    if (!cardElement) {
      console.error('Card Element not available')
    }

    setIsSubmitting(true)
    try {
      const {
        // @ts-ignore
        paymentMethod,
        // @ts-ignore
        paymentMethodError
        // @ts-ignore
      } = await stripe?.createPaymentMethod({ type: 'card', card: cardElement })
      const data = { paymentMethodId: paymentMethod.id, clinicId }
      if (hasAnyEbcPlan) {
        await startEBCSubscription(data)
      } else {
        await startFreeTrial(data)
      }
    } catch (err) {
      console.log(err)
    }
    setIsSubmitting(false)
  }

  const pricePerClinician = clinicianCount > 1 ? 75 : 49
  const clinicianText = clinicianCount > 1 ? 'Clinicians' : 'Clinician'

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader position="relative">
          <Box>
            <IconButton
              variant="ghost"
              aria-label="Close"
              position="absolute"
              _focus={{ outline: 'none' }}
              style={{ margin: 0, top: '14px', left: '16px' }}
              icon={<ArrowLeft />}
              onClick={() => {
                closeModal()
                if (!hasAnyEbcPlan) {
                  openEbcMarketingModal()
                }
              }}
            />
          </Box>
          <Text fontSize="24px" textAlign="center">
            {hasAnyEbcPlan ? 'Evidence-Based Care' : 'Start your 30 day trial'}
          </Text>
        </ModalHeader>
        <Box height="16px" />
        <form id="stripe-payment-form" onSubmit={handleSubmit}>
          <ModalBody padding="0px 24px 0 24px">
            <Flex justifyContent="space-between">
              <Flex>
                <Text>
                  {clinicianCount} {clinicianText}
                </Text>
              </Flex>
              <Flex>
                <Text>
                  ${pricePerClinician} per month x {clinicianCount}{' '}
                  {clinicianText} ={' '}
                  <b>${pricePerClinician * clinicianCount} per month</b>
                </Text>
              </Flex>
            </Flex>
            <Box height="16px" />
            <Stack w="100%" spacing="16px">
              {error && (
                <div
                  style={{ textAlign: 'center' }}
                  className="errorMessage"
                  role="alert"
                >
                  {error}
                </div>
              )}
              <Box
                w="100%"
                padding="16px"
                style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
              >
                <CardNumberElement
                  options={stripeStyle}
                  onChange={handleChange}
                />
              </Box>
              <HStack spacing="24px" w="100%">
                <Box
                  w="100%"
                  padding="16px"
                  style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
                >
                  <CardExpiryElement
                    options={stripeStyle}
                    onChange={handleChange}
                  />
                </Box>
                <Box
                  w="100%"
                  padding="16px"
                  style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
                >
                  <CardCvcElement
                    options={stripeStyle}
                    onChange={handleChange}
                  />
                </Box>
              </HStack>
            </Stack>

            <Box height="16px" />
            <Flex
              height="56px"
              bg="rgba(45, 84, 232, 0.04)"
              justifyContent="center"
              alignItems="center"
            >
              <Text>
                Total due today: $
                {hasAnyEbcPlan ? pricePerClinician * clinicianCount : '0'}
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter paddingTop="0px" flexDirection="column">
            <Flex w="100%">
              <Button
                size="lg"
                my="small"
                isFullWidth
                type="submit"
                isLoading={isSubmitting}
              >
                {hasAnyEbcPlan ? 'Subscribe' : 'Start your 30 day trial'}
              </Button>
            </Flex>
            <Flex>
              <Text fontSize="12px">
                {hasAnyEbcPlan
                  ? `You will be charged $${pricePerClinician} per clinician per month starting today.`
                  : `After your trial ends, you will be charged ${pricePerClinician} per clinician per month starting ${moment()
                      .add(30, 'days')
                      .format(
                        'MMMM D, YYYY'
                      )}. You can always cancel before then.`}
              </Text>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export const EbcUpgradePlanModalConnected = () => {
  const user = useStoreState((state: any) => state.auth.user)

  if (!user) {
    return null
  }

  return (
    <Elements stripe={stripePromise}>
      <EbcUpgradePlanModal />
    </Elements>
  )
}
