import React, { useMemo, useState } from 'react'
import {
  Box,
  Flex,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Button,
  Input,
  Select,
  Link,
  FormErrorMessage,
  useToast
} from '@chakra-ui/react'
import {
  Formik,
  Form,
  Field,
  FieldProps,
  FormikProps,
  useFormikContext
} from 'formik'
import { NewTabIcon } from '@blueprinthq/joy'
import { useQueryClient } from 'react-query'

import { Clipboard } from '@components/icons'
import * as Yup from 'yup'
import { useCheckInsControllerV1GetCheckIns } from '~/public-api'
import {
  usePatientControllerAssignCheckIns,
  getSessionControllerGetSuggestedContentQueryKey,
  usePatientControllerUpdateCheckInNotification,
  getPatientControllerGetAssignedCheckInsQueryKey
} from '~/clinician-api'
import { buildCheckInPreviewUrl } from '@utilities'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { AssignedCheckInDto } from '~/clinician-api/models'

interface AssignWorksheetFieldProps {
  checkInId: string
  parentPath?: string
  client?: {
    id: string
    first_name: string
    last_name: string
  }
}

export const AssignWorksheetFieldsSchema = Yup.object().shape({
  daysOfWeek: Yup.array(Yup.string())
    .when('frequency', {
      is: (value: string) => value === 'weekly',
      then: (y) => y.min(1, 'Select at least one day'),
    })
    .default([]),
  timeOfDay: Yup.string().when('frequency', {
    is: (value: string) => value === 'weekly',
    then: (y) => y.required('Required'),
  })
    .nullable()
    .default(null),
  frequency: Yup.string().default('weekly')
})

interface AssignWorksheetFormProps {
  assignedCheckIn?: AssignedCheckInDto
  checkInId: string
  patientId: string
  contentSuggestionId?: string
  children: (props: any) => React.ReactNode
  sessionId?: string
  onUpdate?: () => void
  onSubmit?: () => void
}

const DAYS = [
  {
    key: 'Sun',
    label: 'S',
    value: 7
  },
  {
    key: 'Mon',
    label: 'M',
    value: 1
  },
  {
    key: 'Tue',
    label: 'T',
    value: 2
  },
  {
    key: 'Wed',
    label: 'W',
    value: 3
  },
  {
    key: 'Thu',
    label: 'T',
    value: 4
  },
  {
    key: 'Fri',
    label: 'F',
    value: 5
  },
  {
    key: 'Sat',
    label: 'S',
    value: 6
  }
]

export const AssignWorksheetForm = ({
  children,
  checkInId,
  patientId,
  contentSuggestionId,
  sessionId,
  onSubmit,
  onUpdate,
  assignedCheckIn
}: AssignWorksheetFormProps) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutate: assignCheckIns } = usePatientControllerAssignCheckIns({
    mutation: {
      onSuccess() {
        if (sessionId) {
          const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
            sessionId
          )
          queryClient.invalidateQueries({ queryKey: suggestionListKey })
        }
        toast({
          title: 'Worksheet assigned',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        queryClient.invalidateQueries({
          queryKey: getPatientControllerGetAssignedCheckInsQueryKey(patientId)
        })
        if (onSubmit) {
          onSubmit()
        }
      }
    }
  })

  const {
    mutate: updateNotification
  } = usePatientControllerUpdateCheckInNotification({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: getPatientControllerGetAssignedCheckInsQueryKey(patientId)
        })
        if (onUpdate) {
          onUpdate()
        }
      }
    }
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        assignedCheckIn
          ? {
              daysOfWeek: assignedCheckIn.daysOfWeek
                ? assignedCheckIn.daysOfWeek
                : [],
              timeOfDay: assignedCheckIn.timeOfDay
                ? DateTime.fromISO(assignedCheckIn.timeOfDay).toLocal().toFormat('HH:mm')
                : null,
              frequency: assignedCheckIn.frequencyType,
            }
          : AssignWorksheetFieldsSchema.getDefault()
      }
      validationSchema={AssignWorksheetFieldsSchema}
      onSubmit={values => {
        if (assignedCheckIn) {
          updateNotification({
            checkInId: assignedCheckIn.checkInId,
            patientId: patientId,
            data: {
              frequencyType: values.frequency as 'one_time' | 'weekly',
              // @ts-ignore
              daysOfWeek: values.daysOfWeek,
              timeOfDay: values.timeOfDay ? DateTime.fromISO(values.timeOfDay).toString() : null
            }
          })
        } else {
          assignCheckIns({
            patientId,
            data: {
              frequencyType: values.frequency as 'one_time' | 'weekly',
              checkInId,
              // @ts-ignore
              daysOfWeek: values.daysOfWeek,
              // @ts-ignore
              timeOfDay: values.timeOfDay ? DateTime.fromISO(values.timeOfDay).toString() : null,
              suggestionId: contentSuggestionId
            }
          })
        }
      }}
    >
      {(formikProps: FormikProps<any>) => (
        <Form>{children({ formikProps })}</Form>
      )}
    </Formik>
  )
}

function getNextDate(
  daysOfWeek: string[],
  time: { hours: number; minutes: number } | null
): DateTime | null {
  const now = DateTime.now()

  let dayOfWeek = now.weekday

  if (time) {
    const dayTime = now.startOf('day').plus({
      hours: time.hours,
      minutes: time.minutes
    })
    if (dayTime < now) {
      // @ts-ignore
      dayOfWeek = now.plus({ days: 1 }).weekday
    }
  }

  if (daysOfWeek.length) {
    const daysToValues = daysOfWeek
      // @ts-ignore
      .map(dow => DAYS.find(d => dow === d.key).value)
      .sort((a, b) => a - b)

    let nextDayOfWeek = daysToValues[0]
    const laterInWeek = daysToValues.find(v => v >= dayOfWeek)

    if (laterInWeek) {
      const offset = laterInWeek - now.weekday
      const nextDay = now.plus({ days: offset })
      return nextDay
    } else {
      const offset = 7 - now.weekday + nextDayOfWeek
      const nextDay = now.plus({ days: offset })
      return nextDay
    }
  }
  return null
}

export const DeliveryMessageText = ({
  worksheetTitle,
  parentPath,
  client,
}: any) => {
  const { values } = useFormikContext<any>()

  const deliveryMessageSpans = useMemo(() => {
    let deliverySpan = []

    if (client) {
      const frequency = _.get(
        values,
        `${parentPath ? parentPath + '.' : ''}frequency`,
      )

      deliverySpan.push(
        <>
          <Text as="span" fontWeight="bold">
            {client.first_name} {client.last_name}
          </Text>{' '}
          will receive a{' '}
        </>
      )

      if (frequency === 'one_time') {
        deliverySpan.push(
          <>
            <Text as="span" fontWeight="bold">
              one-time
            </Text>{' '}
          </>
        )
      }

      deliverySpan.push(
        <>
          delivery of the{' '}
          <Text as="span" fontWeight="bold">
            {worksheetTitle}
          </Text>{' '}
          worksheet
        </>
      )

      if (frequency === 'weekly') {
        const daysOfWeek = _.get(
          values,
          `${parentPath ? parentPath + '.' : ''}daysOfWeek`,
          []
        )
        const timeOfDay = _.get(
          values,
          `${parentPath ? parentPath + '.' : ''}timeOfDay`,
          ''
        )
  
        let time: { hours: number; minutes: number } | null = null
  
        if (timeOfDay) {
          // @ts-ignore
          const [hours, minutes] = timeOfDay.split(':')
          time = {
            hours,
            minutes
          }
        }
  
        const nextDate = getNextDate(daysOfWeek, time)
  
        if (daysOfWeek.length) {
          deliverySpan.push(
            <>
              {' '}
              <Text as="span" fontWeight="bold">
                every {daysOfWeek.join(', ')}
              </Text>{' '}
            </>
          )
        }
  
        if (nextDate && time) {
          const nextTime = nextDate
            ?.startOf('day')
            .plus({ hours: time.hours, minutes: time.minutes })
  
          deliverySpan.push(
            <>
              {' '}
              at{' '}
              <Text as="span" fontWeight="bold">
                {nextTime.toFormat('t')}
              </Text>
            </>
          )
        }
  
        if (nextDate) {
          const dateStr = nextDate.toFormat('LLL dd, yyyy')
          deliverySpan.push(
            <>
              {' '}
              starting on{' '}
              <Text as="span" fontWeight="bold">
                {dateStr}
              </Text>
            </>
          )
        }
      }


      if (frequency === 'one_time') {
        deliverySpan.push(
          <>
            {' '}
            <Text as="span" fontWeight="bold">
              today
            </Text>
          </>
        )
      }
    }

    return deliverySpan
  }, [client, values, worksheetTitle])

  return <Text>{deliveryMessageSpans}.</Text>
}

export const AssignWorksheetFields = ({
  checkInId,
  parentPath,
  client
}: AssignWorksheetFieldProps) => {
  const { data, isLoading } = useCheckInsControllerV1GetCheckIns(checkInId)

  const { values } = useFormikContext<any>()

  if (!data || isLoading) return null

  return (
    <Box>
      <Flex
        direction="row"
        gap="small"
        border="1px solid"
        borderColor="pale_gray"
        borderRadius="4px"
        p="small"
        justifyContent="left"
      >
        <Flex flexDir="column" justifyContent="center">
          <Clipboard />
        </Flex>
        <Flex flex="1" direction="column" justifyContent="center">
          <Text fontWeight="bold">{data.title}</Text>
          <Text textColor="gray">{data.questionCount} questions</Text>
        </Flex>
        <Flex alignItems="center" color="primary">
          <Link
            mr={2}
            href={buildCheckInPreviewUrl(data.id, data.versionId)}
            target="_blank"
            _hover={{ textDecoration: 'none' }}
            _focus={{ outline: 'none' }}
          >
            Preview
          </Link>
          <NewTabIcon h="16px" w="16px" fill="#2d54e8" />
        </Flex>
      </Flex>
      <VStack
        spacing="small"
        mt="small"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box w="100%">
          <Field name={`${parentPath ? parentPath + '.' : ''}frequency`}>
            {({ field, meta, form }: FieldProps) => (
              <FormControl isInvalid={!!meta.error}>
                <FormLabel fontWeight="bold">Frequency:</FormLabel>
                <Select
                  borderColor="pale_gray"
                  width="100%"
                  {...field}
                  onChange={(e) => {
                    if (e.target.value === 'one_time') {
                      form.setFieldValue(`${parentPath ? parentPath + '.' : ''}daysOfWeek`, [])
                      form.setFieldValue(`${parentPath ? parentPath + '.' : ''}timeOfDay`, null)
                    }
                    field.onChange(e)
                  }}
                >
                  <option value="weekly">Weekly</option>
                  <option value="one_time">One-time</option>
                </Select>
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
        {_.get(values, `${parentPath ? parentPath + '.' : ''}frequency`) ===
          'weekly' && (
          <>
            <Box>
              <Field name={`${parentPath ? parentPath + '.' : ''}daysOfWeek`}>
                {({ field, form, meta }: FieldProps) => (
                  <FormControl isInvalid={!!meta.error}>
                    <FormLabel fontWeight="bold">Repeat on:</FormLabel>
                    <DayOfWeekSelector
                      selectedDays={field.value}
                      onChange={selectedDays => {
                        const sortedDays = selectedDays.sort((a, b) => {
                          const aDay = DAYS.findIndex(d => a === d.key)
                          const bDay = DAYS.findIndex(d => b === d.key)

                          return aDay - bDay
                        })
                        form.setFieldValue(field.name, sortedDays)
                      }}
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box>
              <Field name={`${parentPath ? parentPath + '.' : ''}timeOfDay`}>
                {({ field, meta }: FieldProps) => (
                  <FormControl isInvalid={!!meta.error}>
                    <FormLabel fontWeight="bold">Time of day:</FormLabel>
                    <Input {...field} type="time" />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>
          </>
        )}
        {client && (
          <Box
            px="22px"
            py={4}
            bg="rgba(45, 84, 232, 0.04)"
            borderRadius="8px"
            border="1px solid #E4E5E6"
          >
            <DeliveryMessageText
              client={client}
              worksheetTitle={data.title}
              parentPath={parentPath}
            />
          </Box>
        )}
      </VStack>
    </Box>
  )
}

interface DayOfWeekSelectorProps {
  selectedDays: string[]
  showEveryDayOption?: boolean
  onChange: (selectedDays: string[]) => void
}

export const DayOfWeekSelector = ({
  selectedDays,
  showEveryDayOption = false,
  onChange
}: DayOfWeekSelectorProps) => {
  const [isEveryDaySelected, setIsEveryDaySelected] = useState(false)

  const handleDaySelect = (value: string) => {
    let newState: string[] = [...selectedDays]

    setIsEveryDaySelected(false)

    if (selectedDays.includes(value)) {
      newState = newState.filter(d => d !== value)
      onChange(newState)
    } else {
      newState.push(value)
      onChange(newState)
    }
  }

  const handleEveryDaySelected = () => {
    setIsEveryDaySelected(!isEveryDaySelected)
    if (!isEveryDaySelected) {
      onChange(DAYS.map(d => d.key))
    } else {
      onChange([])
    }
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        gap="xsmall"
        mb="xsmall"
      >
        {DAYS.map((day, index) => {
          const isSelected =
            !isEveryDaySelected && selectedDays.includes(day.key)

          return (
            <Button
              key={index}
              variant={isSelected ? 'solid' : 'outline'}
              width="100%"
              maxH="40px"
              m={0}
              borderRadius="full"
              borderColor="pale_gray"
              onClick={() => handleDaySelect(day.key)}
            >
              {day.label}
            </Button>
          )
        })}
      </Box>
      {showEveryDayOption && (
        <Box>
          <Button
            variant={isEveryDaySelected ? 'solid' : 'outline'}
            h="40px"
            isFullWidth
            m={0}
            borderRadius="4px"
            borderColor="pale_gray"
            onClick={handleEveryDaySelected}
          >
            Every Day
          </Button>
        </Box>
      )}
    </Box>
  )
}
