export const ACTIVE_STRIPE_SELF_SERVE_PRICES = Object.freeze({
  development: {
    starter: {
      monthly: 'price_1NG3SgKw0TYkh728NMBX4h1b',
      annual: 'price_1NG7R9Kw0TYkh728V7yUN57B'
    },
    solo: {
      monthly: 'price_1N4AStKw0TYkh728aZmqN6ea',
      annual: 'price_1N4AYpKw0TYkh728zUCqjI4i'
    },
    group: {
      monthly: 'price_1N4AXpKw0TYkh728l5jClrnt',
      annual: 'price_1N4AZ9Kw0TYkh728R5gmVvi0'
    },
    enterprise: {
      monthly: 'price_1NACWpKw0TYkh728imh93bsJ',
      annual: 'price_1NACT0Kw0TYkh728GtzCW94Z'
    },
    legacy_basic: {
      monthly: 'price_1JPrLqKw0TYkh72833HX6fJM',
      annual: 'price_1JPrLqKw0TYkh728ooFTIllW'
    },
    legacy_basic_ii: {
      monthly: 'price_1I6KZ7Kw0TYkh728Et70MtU3',
      annual: 'price_1JJ0JgKw0TYkh728EV6Ze8oy'
    }
  },
  staging: {
    starter: {
      monthly: 'price_1NG3SgKw0TYkh728NMBX4h1b',
      annual: 'price_1NG7R9Kw0TYkh728V7yUN57B'
    },
    solo: {
      monthly: 'price_1N4AStKw0TYkh728aZmqN6ea',
      annual: 'price_1N4AYpKw0TYkh728zUCqjI4i'
    },
    group: {
      monthly: 'price_1N4AXpKw0TYkh728l5jClrnt',
      annual: 'price_1N4AZ9Kw0TYkh728R5gmVvi0'
    },
    enterprise: {
      monthly: 'price_1NACWpKw0TYkh728imh93bsJ',
      annual: 'price_1NACT0Kw0TYkh728GtzCW94Z'
    },
    legacy_basic: {
      monthly: 'price_1JPrLqKw0TYkh72833HX6fJM',
      annual: 'price_1JPrLqKw0TYkh728ooFTIllW'
    },
    legacy_basic_ii: {
      monthly: 'price_1I6KZ7Kw0TYkh728Et70MtU3',
      annual: 'price_1JJ0JgKw0TYkh728EV6Ze8oy'
    }
  },
  qa: {
    starter: {
      monthly: 'price_1NG3SgKw0TYkh728NMBX4h1b',
      annual: 'price_1NG7R9Kw0TYkh728V7yUN57B'
    },
    solo: {
      monthly: 'price_1N4AStKw0TYkh728aZmqN6ea',
      annual: 'price_1N4AYpKw0TYkh728zUCqjI4i'
    },
    group: {
      monthly: 'price_1N4AXpKw0TYkh728l5jClrnt',
      annual: 'price_1N4AZ9Kw0TYkh728R5gmVvi0'
    },
    enterprise: {
      monthly: 'price_1NACWpKw0TYkh728imh93bsJ',
      annual: 'price_1NACT0Kw0TYkh728GtzCW94Z'
    },
    legacy_basic: {
      monthly: 'price_1JPrLqKw0TYkh72833HX6fJM',
      annual: 'price_1JPrLqKw0TYkh728ooFTIllW'
    },
    legacy_basic_ii: {
      monthly: 'price_1I6KZ7Kw0TYkh728Et70MtU3',
      annual: 'price_1JJ0JgKw0TYkh728EV6Ze8oy'
    }
  },
  production: {
    starter: {
      monthly: 'price_1NKsmXKw0TYkh728agd25LoQ',
      annual: 'price_1NKsmXKw0TYkh728lk2vWoOc'
    },
    solo: {
      monthly: 'price_1NKsmnKw0TYkh728T7u4ROZU',
      annual: 'price_1NKsmnKw0TYkh7283nDjFgUV'
    },
    group: {
      monthly: 'price_1NKsmiKw0TYkh7287tbAy0il',
      annual: 'price_1NKsmiKw0TYkh728gBAB8MRV'
    },
    enterprise: {
      monthly: 'price_1NKsnqKw0TYkh728u744dsza',
      annual: 'price_1NL4NGKw0TYkh728k2VNsRrK'
    },
    legacy_basic: {
      monthly: 'price_1JProWKw0TYkh728MceKdQqA',
      annual: 'price_1KfNiGKw0TYkh728Agn4oSc3'
    },
    legacy_basic_ii: {
      monthly: 'price_1I6KZ7Kw0TYkh728Et70MtU3',
      annual: 'price_1JJ0JgKw0TYkh728EV6Ze8oy'
    }
  }
})
