import React from 'react'

let TimescaleSelectorWrapper = () => null
if (process.env.REACT_APP_THEME === 'tempus') {
  import('./tempus/wrapper').then(
    ({ default: timescaleSelectorWrapper }) =>
      (TimescaleSelectorWrapper = timescaleSelectorWrapper)
  )
} else {
  import('./blueprint/wrapper').then(
    ({ default: timescaleSelectorWrapper }) =>
      (TimescaleSelectorWrapper = timescaleSelectorWrapper)
  )
}

export default function TimescaleSelector(props) {
  return <TimescaleSelectorWrapper {...props} />
}
