import React from 'react'
import { Box, Text, Flex, Button, Link, Center, Stack } from '@chakra-ui/react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { CloseIcon } from '@blueprinthq/joy'
import { InterventionIcon } from '@icons'
import { endpoints } from '@api'

type InterventionRecommendation = {
  id: string
  name: string
  interventionId: string
}

export const InterventionRecommendationsList = ({
  clientId,
  interventionRecommendations,
  openInNewTab
}: {
  clientId: string
  interventionRecommendations: InterventionRecommendation[] | undefined
  openInNewTab: boolean
}) => {
  const location = useLocation()
  const queryClient = useQueryClient()

  const {
    mutate: dismissInterventionRecommendation
  }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.dismissInterventionRecommendation.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getInterventionRecommendations.getCacheId()
        )
      }
    }
  )
  return (
    <Box>
      {interventionRecommendations?.map((ir: InterventionRecommendation) => (
        <Flex
          key={`intervention-recommendation-${ir.id}`}
          justify="space-between"
          p={5}
          border="1px solid #e4e5e6"
          borderTop="none"
          _first={{ borderTop: '1px solid #e4e5e6', borderTopRadius: '10px' }}
          _last={{
            borderBottom: '1px solid #e4e5e6',
            borderBottomRadius: '10px'
          }}
        >
          <Flex alignItems="center">
            <InterventionIcon />
            <Text ml="small">{ir.name}</Text>
          </Flex>
          <Flex alignItems="center">
            <Link
              as={RouterLink}
              color="primary"
              mr="small"
              to={{
                pathname: `/patient/${clientId}/interventions/${ir.interventionId}/summary`,
                state: { prevLocation: location.pathname }
              }}
            >
              Learn More
            </Link>
            <Link
              as={RouterLink}
              color="primary"
              to={{
                pathname: `/patient/${clientId}/interventions/${ir.interventionId}/form`
              }}
              isExternal={openInNewTab}
            >
              <Button mr="small">Start</Button>
            </Link>
            <Box
              _hover={{ cursor: 'pointer' }}
              onClick={() =>
                dismissInterventionRecommendation({
                  clientId,
                  interventionRecommendationId: ir.id
                })
              }
            >
              <CloseIcon />
            </Box>
          </Flex>
        </Flex>
      ))}
    </Box>
  )
}

export const InterventionRecommendationsBox = ({
  clientId,
  openInNewTab
}: {
  clientId: string
  openInNewTab: boolean
}) => {
  const location = useLocation()

  const {
    data: interventionRecommendations
  }: { data: InterventionRecommendation[] | undefined } = useQuery(
    [endpoints.getInterventionRecommendations.getCacheId(), clientId],
    // @ts-ignore
    () => endpoints.getInterventionRecommendations.request({ clientId })
  )

  if (!Boolean(interventionRecommendations?.length)) return null

  return (
    <Box
      borderRadius={'8px'}
      border={'1px solid #E4E5E6'}
      padding={'24px 32px 24px 32px'}
      w="100%"
      pb={{ base: 'xxlarge', md: 'medium' }}
      minHeight={{ base: '730px', md: '0' }}
      mb="large"
    >
      <Flex justifyContent="space-between">
        <Box>
          <Text as="b" fontSize="md">
            Interventions
          </Text>
          <Text mt="xxsmall" mb="small">
            Recommended Interventions for next session
          </Text>
        </Box>
        <Link
          as={RouterLink}
          color="primary"
          mr="small"
          to={{
            pathname: `${location.pathname}/settings/interventions`,
            state: { prevLocation: location.pathname }
          }}
        >
          <Text fontSize={'16px'} fontWeight={'430'}>
            View all
          </Text>
        </Link>
      </Flex>
      <InterventionRecommendationsList
        clientId={clientId}
        interventionRecommendations={interventionRecommendations}
        openInNewTab={openInNewTab}
      />
    </Box>
  )
}

export const InterventionRecommendations = ({
  clientId,
  openInNewTab
}: {
  clientId: string
  openInNewTab: boolean
}) => {
  const {
    data: interventionRecommendations
  }: { data: InterventionRecommendation[] | undefined } = useQuery(
    [endpoints.getInterventionRecommendations.getCacheId(), clientId],
    // @ts-ignore
    () => endpoints.getInterventionRecommendations.request({ clientId })
  )

  if (!Boolean(interventionRecommendations?.length)) return null

  return (
    <Center mt={'24px'}>
      <Stack flex="1">
        <Stack mb={'16px'} spacing="1">
          <Text textStyle="lg" fontWeight="bold">
            Recommended
          </Text>
        </Stack>
        <InterventionRecommendationsList
          clientId={clientId}
          interventionRecommendations={interventionRecommendations}
          openInNewTab={openInNewTab}
        />
      </Stack>
    </Center>
  )
}
