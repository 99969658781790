import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
  Container,
  GridItem,
  Flex,
  Text,
  Box,
  useBreakpointValue,
  Stack
} from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import { LayoutGrid } from '@blueprinthq/joy'
import { endpoints } from '@api'
import { useStoreActions } from 'easy-peasy'
import { useHistory } from 'react-router-dom'

import { MicAIOn, Upload, MicDictate, MicUpload } from '@components/icons'
import { ConsentModule, SampleSessionsModule } from './components'
import { UploadModalV2 } from '../sessions/components/upload-modal-v2'
import { useComponentRenderTracker } from '@hooks'
import { SessionTypeCTA } from './components/session-type-cta-group'

export const SessionGetStarted = () => {
  useComponentRenderTracker('Viewed Sessions Get Started Page')
  const {
    isAdmin,
    isSessionRecordingV2Enabled,
    isDocumentationAutomationEnabled,
    documentationAutomationFreeTierSessionLimitReached
  } = useExperienceManager()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const history = useHistory()

  const isCtaHorizontal = useBreakpointValue({
    base: false,
    lg: true
  })

  const ctaPadding = useBreakpointValue({
    base: '32px',
    md: 'small'
  })

  const openDocAutomationPlanModal = useStoreActions(
    actions => actions.modals.manageScribePlan.openModal
  )

  const openDocAutomationSessionLimitReachedModal = useStoreActions(
    actions => actions.modals.docAutomationSessionLimitReached.openModal
  )

  const { data: user } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const onRecordAudioClick = () => {
    if (isSessionRecordingV2Enabled) {
      history.push('/new-session')
    } else {
      history.push('/start-session/select-client?signup=true')
    }
  }

  const onDictateClick = () => {
    if (isSessionRecordingV2Enabled) {
      history.push('/dictate/new-session')
    } else {
      history.push('/start-session/dictate/select-client')
    }
  }

  useEffect(() => {
    if (!isDocumentationAutomationEnabled) {
      history.replace('/sessions')
    }
  }, [isDocumentationAutomationEnabled])

  if (!user) return null

  return (
    <Container mt={'32px'}>
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 12,
            sm: 6,
            md: 6,
            lg: 4
          }}
          colStart={{
            base: 0,
            sm: 2,
            md: 4,
            lg: 5
          }}
        >
          <Flex
            direction="column"
            align="space-between"
            justify="center"
            mb="large"
          >
            <Text as="h1" fontSize="xl" fontWeight="bold" align="center">
              {`👋 Hi ${user?.first_name}`}
            </Text>
            <Text align="center">
              Choose from the options below to get started:
            </Text>
          </Flex>
          <Stack
            direction="column"
            spacing="xsmall"
            w="100%"
            mb={{ base: '32px', md: '40px' }}
          >
            <SessionTypeCTA
              id="pendo-sessions-get-started-start-session"
              onClick={onRecordAudioClick}
              Icon={<MicAIOn width="24px" height="24px" />}
              title="Record Audio"
              description="Use during live sessions"
              isHorizontal={isCtaHorizontal}
            />
            <SessionTypeCTA
              id="pendo-sessions-start-session-dictate"
              Icon={<MicDictate width="24px" height="24px" />}
              title="Dictate Notes"
              description="Turn client visits into notes"
              onClick={onDictateClick}
              isHorizontal={isCtaHorizontal}
            />
            <SessionTypeCTA
              id="pendo-sessions-start-session-upload"
              Icon={
                <MicUpload width="24px" height="24px" padding="0 0 0 3px" />
              }
              title="Upload Recording"
              description="Convert audio into notes"
              onClick={() => {
                if (documentationAutomationFreeTierSessionLimitReached) {
                  if (isAdmin) {
                    openDocAutomationPlanModal()
                  } else {
                    openDocAutomationSessionLimitReachedModal()
                  }
                } else {
                  setUploadModalOpen(true)
                }
              }}
              isHorizontal={isCtaHorizontal}
            />
          </Stack>

          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            mb="medium"
          >
            <SampleSessionsModule isGetStarted containerProps={{ mb: '0px' }} />
            {isAdmin && <ConsentModule isGetStarted />}
          </Flex>
        </GridItem>
      </LayoutGrid>
      <UploadModalV2
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
      />
    </Container>
  )
}
