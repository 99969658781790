import React, { useState, useMemo } from 'react'
import { Textarea, Box } from '@chakra-ui/react'
import { SessionRecapResponse } from '~/clinician-api/models'
import _ from 'lodash'
import { useSessionControllerUpdatePsychotherapyNote } from '~/clinician-api'

interface PsychotherapyNoteEditorProps {
  sessionId: string
  recap: SessionRecapResponse | undefined
  v2Enabled?: boolean
}

export const PsychotherapyNoteEditor = ({
  recap,
  sessionId,
  v2Enabled = false
}: PsychotherapyNoteEditorProps) => {
  const [text, setText] = useState<string>(
    recap?.psychotherapyNote ? recap.psychotherapyNote : ''
  )

  const { mutate: updateNote } = useSessionControllerUpdatePsychotherapyNote()

  const _throttledUpdateNote = useMemo(
    () => _.throttle(updateNote, 750, { trailing: true }),
    [updateNote]
  )

  const onUpdatePsychotherapyNote = (val: string) => {
    setText(val)

    _throttledUpdateNote({
      data: {
        note: val
      },
      id: sessionId
    })
  }

  if (v2Enabled) {
    return (
      <Box bgColor="#FEFAF3" height="100%">
        <Textarea
          value={text}
          p={0}
          minH="200px"
          height="100vh"
          placeholder="Add your notes here..."
          border="none"
          _focus={{
            outline: 'none'
          }}
          onChange={e => onUpdatePsychotherapyNote(e.target.value)}
        ></Textarea>
      </Box>
    )
  }

  return (
    <Box
      p="xsmall"
      bgColor="#FEFAF3"
      border="1px solid"
      borderColor="pale_gray"
      borderRadius="4px"
    >
      <Textarea
        value={text}
        minH="200px"
        placeholder="Add your notes here..."
        border="none"
        _focus={{
          outline: 'none'
        }}
        onChange={e => onUpdatePsychotherapyNote(e.target.value)}
      ></Textarea>
    </Box>
  )
}
