import React, { useState, useCallback, useRef, useEffect } from 'react'
import { GridItem, Container, Box, useToast } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import { BlueForm } from '@blueprinthq/joy-business'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import { useQuery, useQueryClient, useMutation } from 'react-query'

import { endpoints } from '@api'
import { Loading } from '@components'
import { SubmitInterventionModal, Footer } from './components'

export function InterventionForm() {
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false)

  const history = useHistory()

  let formikRef = useRef(null)

  let match = useRouteMatch(
    '/patient/:clientId/interventions/:interventionId/form'
  )

  const location = useLocation()
  const clientInterventionId = new URLSearchParams(location.search).get(
    'clientInterventionId'
  )

  const handleCloseSubmitModal = () => {
    setIsSubmitModalOpen(false)
  }

  const { data: clientIntervention, isLoading } = useQuery(
    [
      endpoints.getClientIntervention.getCacheId(),
      {
        clientId: match.params.clientId,
        interventionId: match.params.interventionId,
        clientInterventionId
      }
    ],
    () =>
      endpoints.getClientIntervention.request({
        clientId: match.params.clientId,
        interventionId: match.params.interventionId,
        clientInterventionId
      })
  )

  const turnOffUnloadAlert = () => {
    window.onbeforeunload = () => null
  }

  const turnOnUnloadAlert = () => {
    window.onbeforeunload = e => {
      e.preventDefault()
      return e.returnValue
    }
  }

  useEffect(() => {
    if (clientIntervention && clientIntervention.isComplete)
      turnOffUnloadAlert()
    else turnOnUnloadAlert()
  }, [clientIntervention])

  const toast = useToast()
  const queryClient = useQueryClient()
  const { mutateAsync: executeSaveInterventionProgress } = useMutation(
    endpoints[
      clientInterventionId ? 'putClientIntervention' : 'postClientIntervention'
    ].request,
    {
      onError: () => {
        toast({
          title: 'Error',
          description: 'There was an error saving this activity',
          status: 'error',
          isClosable: true,
          duration: 2000,
          position: 'bottom-left'
        })
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([
            endpoints.getInterventionOverview.getCacheId(),
            {
              clientId: match.params.clientId,
              interventionId: match.params.interventionId
            }
          ]),
          queryClient.invalidateQueries([
            endpoints.getClientIntervention.getCacheId(),
            {
              clientId: match.params.clientId,
              interventionId: match.params.interventionId,
              clientInterventionId
            }
          ])
        ])

        turnOnUnloadAlert()

        // replace so you cant navigate back
        history.replace({
          pathname: `/patient/${match.params.clientId}/interventions/${match.params.clientId}/complete`,
          search: `?type=draft`
        })
      }
    }
  )

  const handleSaveDraft = useCallback(async () => {
    if (!formikRef.current.values) return

    const formattedResponses = Object.entries(formikRef.current.values).map(
      ([interventionItemId, value]) => ({
        interventionItemId,
        response: { value }
      })
    )

    const body = {
      clientId: match.params.clientId,
      interventionId: match.params.interventionId,
      data: {
        isComplete: false,
        responses: formattedResponses
      }
    }

    if (clientInterventionId) {
      body.clientInterventionId = clientInterventionId
    }

    await executeSaveInterventionProgress(body)
  }, [formikRef.current, clientInterventionId])

  const renderContent = useCallback(() => {
    if (isLoading) {
      return <Loading />
    }

    return (
      <LayoutGrid w="100%" h="100%">
        <GridItem
          colStart={{
            base: 1,
            sm: 1,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 9,
            md: 10
          }}
        >
          <BlueForm
            title={clientIntervention.name}
            form={clientIntervention.items}
            isDisabled={clientIntervention.isComplete}
            initialValues={clientIntervention.responses}
            innerRef={formikRef}
          />
        </GridItem>
      </LayoutGrid>
    )
  }, [isLoading, clientIntervention])

  return (
    <Container>
      <Box
        mt={{
          sm: '16px',
          md: '32px'
        }}
        mb={'144px'} // footer height (80px) + 64px requested by troyer
      >
        {renderContent()}
      </Box>
      <Footer
        hidden={clientIntervention && clientIntervention.isComplete}
        handleSaveDraft={() => handleSaveDraft()}
        handleSubmit={() => setIsSubmitModalOpen(true)}
      />
      <SubmitInterventionModal
        isOpen={isSubmitModalOpen}
        handleClose={handleCloseSubmitModal}
        homeworkModules={clientIntervention && clientIntervention.homework}
        clientResponses={formikRef.current && formikRef.current.values}
        clientInterventionId={clientInterventionId}
        turnOffUnloadAlert={turnOffUnloadAlert}
      />
    </Container>
  )
}
