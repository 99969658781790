import React from 'react'
import { Text, Flex, Box, Heading, VStack, Divider } from '@chakra-ui/react'
import { DateTime } from 'luxon'
import { useStoreActions } from 'easy-peasy'
import { ArrowRightIcon } from '@blueprinthq/joy'

import { SessionRecapResponse } from '~/clinician-api/models'
import { formatTimePassedText } from '../util'

interface ReviewHomeworkProps {
  clientId: string
  recap: SessionRecapResponse | undefined
}

export const ReviewHomework = ({ clientId, recap }: ReviewHomeworkProps) => {
  // @ts-ignore
  const openWorksheetResultModal = useStoreActions(
    // @ts-ignore
    actions => actions.modals.worksheetScores.openModal
  )

  return (
    <Box w="100%">
      <Text mt="xxsmall" color="gray">
        Review the completed worksheets{' '}
        {recap?.previousSession?.isWithinLast4Weeks
          ? 'since last session'
          : 'in the last 4 weeks'}
      </Text>

      <Box my="small">
        {recap?.worksheets.length ? (
          <Box border="1px solid" borderColor="pale_gray" borderRadius="8px">
            <VStack
              spacing="0px"
              divider={<Divider color="pale_gray" mb="0px" mt="0px" />}
            >
              {recap?.worksheets.map(completedWorksheet => (
                <Flex
                  w="100%"
                  p="small"
                  justify="space-between"
                  onClick={() =>
                    openWorksheetResultModal({
                      patientId: clientId,
                      preselectedCheckInScoreId: completedWorksheet.id
                    })
                  }
                  _hover={{
                    bgColor: 'blue.50',
                    cursor: 'pointer'
                  }}
                >
                  <Box overflow="hidden">
                    <Text fontWeight="bold">
                      {completedWorksheet.worksheet.title}
                    </Text>
                    <Text mt="xsmall" fontStyle="italic" isTruncated>
                      {completedWorksheet.answers[0].value}
                    </Text>
                  </Box>
                  <Flex width="190px" minWidth="190px" justify="space-between">
                    <Text color="dark_gray" whiteSpace="nowrap">
                      {formatTimePassedText(
                        DateTime.fromISO(completedWorksheet.completedAt)
                      )}
                    </Text>
                    <ArrowRightIcon />
                  </Flex>
                </Flex>
              ))}
            </VStack>
          </Box>
        ) : (
          <Box w="100%" p="small" bg="pale_gray" borderRadius="8px">
            <Text color="dark_gray">No homework to review</Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}
