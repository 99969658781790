import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { Flex } from '@chakra-ui/react'
import { TrashIcon } from '@icons'
import { useTreatmentPlan } from '@hooks'
import { Button } from '../Button'
import Toolbar from '../Toolbar'
import { PencilIcon } from '../Icon'

const Actions: React.FC = () => {
  const {
    onCopyAll,
    setIsEditing,
    openDeleteConfirmationModal
  } = useTreatmentPlan()

  return (
    <Toolbar>
      <Flex justifyContent="flex-end" gap="16px" flex="1">
        <Button colorScheme="primary" onClick={onCopyAll}>
          Copy All
        </Button>
        <Button
          variant="outline"
          onClick={() => setIsEditing(true)}
          leftIcon={<PencilIcon />}
        >
          Edit
        </Button>
        <Button
          variant="outline"
          onClick={openDeleteConfirmationModal}
          leftIcon={<TrashIcon fill="#EB5164" />}
        >
          Delete
        </Button>
      </Flex>
    </Toolbar>
  )
}

export default Actions
