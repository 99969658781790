import React, { useCallback } from 'react'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  useToast
} from '@chakra-ui/react'
import { Button, IconButton, MoreHorizontalIcon } from '@blueprinthq/joy'
import { useStoreActions } from 'easy-peasy'

import { formatPhone } from '@utilities'
import { endpoints } from '../../../api'
import { PARTICIPANT_TYPE } from '../../../constants'

export const ParticipantsSection = ({ client }) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const openUpsertParticipantModal = useStoreActions(
    actions => actions.modals.upsertParticipant.openModal
  )
  const closeUpsertParticipantModal = useStoreActions(
    actions => actions.modals.upsertParticipant.closeModal
  )

  const { data: clientParticipants } = useQuery(
    [endpoints.getClientParticipants.getCacheId(), client.id],
    () => endpoints.getClientParticipants.request({ clientId: client.id }),
    {
      initialData: []
    }
  )

  const { mutate: addClientParticipant } = useMutation(
    endpoints.postClientParticipant.request,
    {
      onSuccess() {
        toast({
          title: 'Saved!',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        queryClient.invalidateQueries([
          endpoints.getClinicianUserAccount.getCacheId(),
          client.id
        ])
        queryClient.invalidateQueries([
          endpoints.getClientParticipants.getCacheId(),
          client.id
        ])
      }
    }
  )

  const { mutate: editClientParticipant } = useMutation(
    endpoints.patchClientParticipant.request,
    {
      onSuccess() {
        toast({
          title: 'Saved!',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        queryClient.invalidateQueries([
          endpoints.getClinicianUserAccount.getCacheId(),
          client.id
        ])
        queryClient.invalidateQueries([
          endpoints.getClientParticipants.getCacheId(),
          client.id
        ])
      }
    }
  )

  const { mutate: clientParticipantMarkInactive } = useMutation(
    endpoints.patchClientParticipantMarkInactive.request,
    {
      onSuccess() {
        queryClient.invalidateQueries([
          endpoints.getClinicianUserAccount.getCacheId(),
          client.id
        ])
        queryClient.invalidateQueries([
          endpoints.getClientParticipants.getCacheId(),
          client.id
        ])

        toast({
          title: 'Saved!',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
      },
      onError({ error }) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          isClosable: true,
          duration: 8000
        })
      }
    }
  )

  const upsertParticipantSubmit = useCallback(
    async (
      { firstName, lastName, email, phoneNumber, type },
      existingParticipant
    ) => {
      const participantData = {
        firstName,
        lastName,
        email,
        phoneNumber: phoneNumber.length ? formatPhone(phoneNumber) : null,
        type: type.id
      }
      if (existingParticipant) {
        await editClientParticipant({
          clientId: client.id,
          participantId: existingParticipant.id,
          data: participantData
        })
      } else {
        await addClientParticipant({
          clientId: client.id,
          data: participantData
        })
      }
      closeUpsertParticipantModal()
    },
    [client.id]
  )

  const onSetEditingParticipant = useCallback(
    participant => {
      openUpsertParticipantModal({
        participant,
        onSubmit: upsertParticipantSubmit
      })
    },
    [upsertParticipantSubmit]
  )

  return (
    <Box mt="xlarge">
      <Heading mb="xsmall" fontSize="lg">
        Participants
      </Heading>
      <Text mb="small">
        A participant can take assessments on behalf of the client.
      </Text>
      <Box>
        {clientParticipants.length ? (
          <VStack mb="small" spacing="small">
            {clientParticipants.map(participant => (
              <ParticipantItem
                key={participant.id}
                participant={participant}
                onMarkInactive={participantId => {
                  clientParticipantMarkInactive({
                    clientId: client.id,
                    participantId
                  })
                }}
                onSetEditingParticipant={onSetEditingParticipant}
              />
            ))}
          </VStack>
        ) : null}
        <Button
          onClick={() =>
            openUpsertParticipantModal({
              participant: null,
              onSubmit: upsertParticipantSubmit
            })
          }
          variant="link"
        >
          Add Participant
        </Button>
      </Box>
    </Box>
  )
}

const ParticipantItemEntry = ({ name, value, children }) => {
  return (
    <HStack justify="space-between">
      <Text color="dark_gray" w="120px">
        {name}
      </Text>
      <Box as="span">
        {value}
        <Box as="span">{children}</Box>
      </Box>
    </HStack>
  )
}

const ParticipantItem = ({
  participant,
  onMarkInactive,
  onSetEditingParticipant
}) => {
  const history = useHistory()
  return (
    <Box
      border="1px solid"
      borderColor="dark_gray"
      p="small"
      w="100%"
      borderRadius="md"
    >
      <HStack alignItems="flex-start">
        <VStack align="flex-start" w="100%">
          <ParticipantItemEntry
            name="Relationship"
            value={PARTICIPANT_TYPE[participant.type.toUpperCase()].displayName}
          />
          <ParticipantItemEntry
            name="Name"
            value={`${participant.firstName} ${participant.lastName}`}
          />
          <ParticipantItemEntry name="Email" value={participant.email} />
          <ParticipantItemEntry name="Phone" value={participant.phoneNumber} />
          <ParticipantItemEntry
            name="Assessments"
            value={
              participant.assignedAssessments.length
                ? participant.assignedAssessments.map(aa => aa.name).join(', ')
                : ''
            }
          >
            <Button
              variant="link"
              ml={participant.assignedAssessments.length ? 'xsmall' : '0'}
              my="0"
              onClick={() =>
                history.push(
                  `${
                    history.location.pathname.split('account')[0]
                  }outcome_measures`
                )
              }
            >
              Assign
            </Button>
          </ParticipantItemEntry>
        </VStack>
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<MoreHorizontalIcon />}
              variant="ghost"
              sx={{
                paddingInline: '0px',
                margin: '0px',
                height: 'auto'
              }}
            />
            <MenuList>
              <MenuItem onClick={() => onSetEditingParticipant(participant)}>
                Edit
              </MenuItem>
              <MenuItem
                color="severe"
                onClick={() => onMarkInactive(participant.id)}
              >
                Remove
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    </Box>
  )
}
