import React from 'react'
import ChimeProvider from './chime-provider'
import ManualAudioCaptureProvider from './manual-audio-capture-provider'

export type DeviceType = {
  deviceId: string
  label: string
}

interface AudioCaptureContextType {
  isReady: boolean
  audioInputs: DeviceType[]
  selectedAudioInput: string
  setSelectedAudioInput: (deviceId: string) => void
  testAudioInputs: () => Promise<void>
  hasMicAccess: boolean
  setHasMicAccess: React.Dispatch<React.SetStateAction<boolean>>
  promptForDevicePermissions: () => void
  isMuted: boolean
  toggleMute: () => void
  disableEchoCancelation: React.Dispatch<React.SetStateAction<boolean>>
  isRecording: boolean
  isRecordingLoading: boolean
  startRecording: ({
    isTelehealth,
    isUsingHeadphones,
    noteType
  }: {
    isTelehealth: boolean
    isUsingHeadphones: boolean
    noteType: string
  }) => Promise<void>
  stopRecording: () => Promise<void>
  startContentShare: () => Promise<void>
  stopContentShare: () => void
  logData: object
  isUsingHeadphones: boolean
  setIsUsingHeadphones: React.Dispatch<React.SetStateAction<boolean>>
  isTelehealth: boolean | null
  setIsTelehealth: React.Dispatch<React.SetStateAction<boolean | null>>
  hasDetectedAudio: boolean
  showMicWarning: boolean
  setShowMicWarning: React.Dispatch<React.SetStateAction<boolean>>
  isUploadComplete: boolean
  setRecordingCutoffTime: (timestamp: string) => void
  selectedAudioInputLabel: string | undefined
  isSettingsOpen: boolean
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>
  stopRecordingAfter: string
  setStopRecordingAfter: React.Dispatch<React.SetStateAction<string>>
  isStopRecordingAfterEnabled: boolean
  setIsStopRecordingAfterEnabled: (enabled: boolean) => void
  hasResolvedInputDevicePermissions: boolean
  permissionStatus: string | null
}

export const AudioCaptureContext = React.createContext<
  AudioCaptureContextType | undefined
>(undefined)

interface AudioCaptureProviderProps {
  isChime?: boolean
  sessionId?: string
  hasStartedRecording?: boolean
  refetchRecap?: () => void
  children: React.ReactNode
}

export const AudioCaptureProvider: React.FC<AudioCaptureProviderProps> = ({
  isChime,
  sessionId,
  refetchRecap,
  hasStartedRecording,
  children
}) => {
  return isChime ? (
    <ChimeProvider
      sessionId={sessionId!}
      hasStartedRecording={hasStartedRecording!}
      refetchRecap={refetchRecap!}
    >
      {children}
    </ChimeProvider>
  ) : (
    <ManualAudioCaptureProvider
      sessionId={sessionId}
      refetchRecap={refetchRecap}
    >
      {children}
    </ManualAudioCaptureProvider>
  )
}

export default AudioCaptureProvider
