import React from 'react'
import {
  Box,
  VStack,
  HStack,
  Flex,
  Text,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Tooltip,
  Alert,
  AlertDescription
} from '@chakra-ui/react'
import { MicrophoneSelector } from './microphone-selector'
import {
  ArrowDownIcon,
  RadioSelectedIcon,
  NoHeadsetIcon,
  Headphones,
  InPersonFilled,
  Online,
  InfoIcon
} from '@blueprinthq/joy'
import { DialogHeader, DialogBody } from '@handlers/sessions/components/dialog'
import StopRecordingAfter from './stop-recording-after'
import { trackEvent } from '@lib/clinician-tracking'
import { useExperienceManager, useAudioCapture } from '@hooks'

import { isMobile, isTablet } from 'react-device-detect'

type ConfirmAudioSettingsDialogPropsT = {
  onClose: () => void
  startRecording: () => void
  handleShareAudio: () => Promise<void>
  isExtension: boolean
  isDictation: boolean
  isWidget: boolean
}

export const ConfirmAudioSettingsDialog = ({
  onClose,
  handleShareAudio,
  startRecording,
  isExtension,
  isDictation,
  isWidget
}: ConfirmAudioSettingsDialogPropsT) => {
  const {
    isTelehealth,
    setIsTelehealth,
    isUsingHeadphones,
    setIsUsingHeadphones,
    isStopRecordingAfterEnabled,
    setIsStopRecordingAfterEnabled,
    stopRecordingAfter,
    setStopRecordingAfter
  } = useAudioCapture()

  const { isSafari } = useExperienceManager()

  const showSafariWarning = isSafari && isTelehealth && !isMobile

  const HeadphonesButton = () => {
    if (isUsingHeadphones) {
      return (
        <HStack spacing={3}>
          <Headphones />
          <Text color="black">Using headphones</Text>
        </HStack>
      )
    }
    return (
      <HStack spacing={3}>
        <NoHeadsetIcon />
        <Text color="black">Not using headphones</Text>
      </HStack>
    )
  }

  const handleSettingChange = (isTelehealth: boolean) => {
    if (!isTelehealth) {
      setIsUsingHeadphones(false)
    }
    setIsTelehealth(isTelehealth)
    trackEvent('Confirm Audio Settings Dialog -> Changed Setting Input', {
      newSettingSelection: isTelehealth ? 'telehealth' : 'in_person'
    })
  }

  const handleHeadphonesChange = (event: any) => {
    setIsUsingHeadphones(event.target.value === 'headphones')
    trackEvent('Confirm Audio Settings Dialog -> Changed Headphones Input', {
      newHeadphonesSelection: event.target.value
    })
  }

  const isTelehealthAllowed = !isMobile && !isTablet
  return (
    <>
      <DialogHeader text="Confirm Audio Settings" onClose={onClose} />
      <DialogBody>
        <Box maxWidth="750px" margin="0 auto">
          <VStack spacing={4}>
            <MicrophoneSelector />
            <Box w="100%">
              <Box mb={2}>
                <Flex>
                  <Text fontWeight="bold">Setting:</Text>
                  <Tooltip label="We optimize audio settings to improve note quality.">
                    <Box ml={1} w="20px" h="20px">
                      <InfoIcon />
                    </Box>
                  </Tooltip>
                </Flex>
                {!isTelehealthAllowed && (
                  <Text color="#757575" fontSize="xs">
                    *Telehealth is not supported on mobile devices
                  </Text>
                )}
              </Box>
              <Flex
                w="100%"
                h="46px"
                align="center"
                bg="#E4E5E6"
                borderRadius="4px"
                _hover={{ cursor: 'pointer' }}
                px={1}
              >
                <HStack
                  justifyContent="center"
                  w="50%"
                  h="38px"
                  bg={isTelehealth ? 'inherit' : 'white'}
                  borderRadius="4px"
                  onClick={() => handleSettingChange(false)}
                >
                  <InPersonFilled fill={isTelehealth ? '#757575' : 'black'} />
                  <Text color={isTelehealth ? '#757575' : 'black'}>
                    In-Person
                  </Text>
                </HStack>
                <HStack
                  justifyContent="center"
                  spacing={3}
                  w="50%"
                  h="38px"
                  bg={isTelehealth ? 'white' : 'inherit'}
                  borderRadius="4px"
                  onClick={() =>
                    isTelehealthAllowed ? handleSettingChange(true) : () => {}
                  }
                  cursor={isTelehealthAllowed ? 'pointer' : 'not-allowed'}
                >
                  <Online fill={isTelehealth ? 'black' : '#757575'} />
                  <Text
                    color={isTelehealth ? 'black' : '#757575'}
                    textDecoration={
                      isTelehealthAllowed ? 'none' : 'line-through'
                    }
                  >
                    Telehealth
                  </Text>
                </HStack>
              </Flex>
            </Box>
            {isTelehealth && !isDictation && (
              <Box w="100%">
                <Flex>
                  <Text fontWeight="bold" mb={2}>
                    Headphones:
                  </Text>
                  <Tooltip
                    label={`When using headphones, we can only capture what your client is saying if you are running your telehealth session in another browser tab. Select 'Using headphones' and you will be prompted to share audio from your telehealth tab.`}
                  >
                    <Box ml={1} w="20px" h="20px">
                      <InfoIcon />
                    </Box>
                  </Tooltip>
                </Flex>
                <Menu placement="bottom-start" matchWidth>
                  <MenuButton
                    border="1px solid #E4E5E6"
                    m="0"
                    h="48px"
                    w="100%"
                    bg="white"
                    borderRadius="4px"
                    as={Button}
                    rightIcon={<ArrowDownIcon />}
                  >
                    <HeadphonesButton />
                  </MenuButton>
                  <MenuList w="100%">
                    <MenuItem
                      isDisabled={isUsingHeadphones}
                      onClick={handleHeadphonesChange}
                      value="headphones"
                      color="black"
                      w="100%"
                      icon={<Headphones />}
                    >
                      Using headphones
                    </MenuItem>
                    <MenuItem
                      isDisabled={!isUsingHeadphones}
                      onClick={handleHeadphonesChange}
                      value="no_headphones"
                      color="black"
                      w="100%"
                      icon={<NoHeadsetIcon />}
                    >
                      Not using headphones
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            )}
            {showSafariWarning && (
              <Box w="100%">
                <Alert status="error" bg="#EB5164" borderRadius="8px" p={4}>
                  <Box mr={2} w="20px" h="20px">
                    <InfoIcon fill="white" />
                  </Box>
                  <AlertDescription color="white">
                    Important: Due to a limitation of the Safari browser, the AI
                    Notetaker feature will not be able to capture participant
                    audio duing a telehealth session. For the best results,
                    please use Chrome.
                  </AlertDescription>
                </Alert>
              </Box>
            )}
          </VStack>
          <Box mt={6}>
            {isUsingHeadphones && !isMobile && !isTablet ? (
              <>
                <Button
                  aria-label="Start Recording"
                  bg="black"
                  isFullWidth
                  onClick={handleShareAudio}
                  size="lg"
                  m={0}
                >
                  <HStack spacing={2}>
                    <RadioSelectedIcon fill="#68E19F" />
                    <Text fontWeight="bold" color="white">
                      {isExtension || isWidget
                        ? 'Share audio + record'
                        : 'Share browser audio + begin recording'}
                    </Text>
                  </HStack>
                </Button>
              </>
            ) : (
              <Button
                aria-label="Start Recording"
                bg="black"
                onClick={startRecording}
                isFullWidth
                size="lg"
                m={0}
              >
                <HStack spacing={2}>
                  <RadioSelectedIcon fill="#68E19F" />
                  <Text fontWeight="bold" color="white">
                    {isDictation ? 'Start Dictating' : 'Start Recording'}
                  </Text>
                </HStack>
              </Button>
            )}
          </Box>
          {!isDictation && (
            <Box mt={4}>
              <StopRecordingAfter
                isEnabled={isStopRecordingAfterEnabled}
                setIsEnabled={setIsStopRecordingAfterEnabled}
                value={stopRecordingAfter}
                onChange={setStopRecordingAfter}
              />
            </Box>
          )}
        </Box>
      </DialogBody>
    </>
  )
}
