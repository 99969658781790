import React from 'react'
import { useQuery } from 'react-query'
import { Container, GridItem } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'

import { Loading } from '../../components'
import { endpoints } from '../../api'

export function InsightsUtilization() {
  const {
    data,
    isLoading
  } = useQuery(endpoints.getInsightsUtilizationEmbed.getCacheId(), () =>
    endpoints.getInsightsUtilizationEmbed.request()
  )

  return (
    <Container
      sx={{
        marginTop: 'large',
        paddingBottom: 'xxlarge'
      }}
    >
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 4,
            sm: 8,
            md: 12
          }}
        >
          <div style={{ width: '100%' }}>
            <div style={{ width: '100%', height: isLoading ? 400 : 1500 }}>
              {isLoading ? (
                <Loading />
              ) : (
                <iframe height="100%" width="100%" src={data.embedUrl} />
              )}
            </div>
          </div>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
