import React, { Component } from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { Loading, JoyTable } from '../../../../../../components'
import { Chevron } from '../../../../../../components/icons'
import LifestyleCharts from '../lifestyle-charts'
import SymptomsBlock from '../symptoms-block'
import { find, orderBy, chunk, flatten, sortBy } from 'lodash'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { isMobile } from 'react-device-detect'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import styles from './section-modal-styles'
import { withStyles } from '@material-ui/core'
import { X } from '../../../../../../components/icons'
import moment from 'moment'
import { Text } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import {
  formatScoreToLabel,
  formatAssessmentAnswers
} from '../../../../../../utilities'

class SectionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_check_in_id: '',
      checkins: [],
      symptom_distribution: [],
      assessment_scores: [],
      module_scores: [],
      symptom_chart_data: [],
      raw_checkin_data: [],
      isDailyView: props.isDailyView || false,
      currentWeekIndex: 0,
      week_array: [],
      weekly_formated_scores: [],
      selectedAssigneeUserId: null
    }
  }

  componentDidMount() {
    const { content } = this.props
    if (content === 'symptoms' || content === 'lifestyle') {
      this.fetchCheckinScores(this.props.user_id)
    }
    if (content === 'progress') {
      this.setFirstAssessment()
    }
    if (content === 'treatment') {
      this.loadTreatmentData()
    }
  }

  setFirstAssessment = () => {
    const { assessmentData } = this.props
    if (assessmentData && assessmentData.length) {
      this.setAssessment(assessmentData[0].assessment_id)
    }
  }

  setAssessment = assessmentId => {
    const { assessmentData } = this.props
    const selectedAssessment = assessmentData.find(
      a => a.assessment_id === assessmentId
    )
    if (!selectedAssessment) return null

    const scores = sortBy(selectedAssessment.scores, 'created_at').reverse()
    const selectedScore = scores[0]

    const selectedScoreAssigneeScores = scores.filter(
      score => score.assigneeUser.id === selectedScore.assigneeUser.id
    )

    this.setState({
      selected_assessment_id: selectedAssessment.assessment_id,
      assessment_scores: selectedScoreAssigneeScores,
      selected_assessment_score_id: selectedScore.id,
      selectedAssigneeUserId: selectedScore.assigneeUser.id
    })
  }

  loadTreatmentData = () => {
    if (this.props.treatmentData && this.props.treatmentData.length) {
      const selected_module = this.props.treatmentData[0]
      const selected_module_id = selected_module.module_id
      const selected_module_scores = find(this.props.treatmentData, {
        module_id: selected_module_id
      }).scores
      const selected_module_score_id = selected_module_scores[0].id

      this.setState(
        {
          selected_module_id: selected_module_id,
          module_scores: selected_module_scores,
          selected_module_score_id: selected_module_score_id
        },
        () => {
          const week_array = this.fillAndGroupByWeek(
            selected_module_scores,
            selected_module
          )
          const weekly_formated_scores = week_array.map(week_scores =>
            this.formatWeeklyModuleAnswers(
              week_scores.reverse(),
              selected_module
            )
          )
          this.setState({ weekly_formated_scores, week_array })
        }
      )
    }
  }

  standardizeCheckinData = value => {
    if (value === null) {
      return null
    }
    value = value + 2 //Convert from -2<>2 to 0<>4
    const normalized = (value - 0) / (4 - 0)
    return normalized
  }

  fetchCheckinScores = user_id => {
    let limit = 29
    if (isMobile) {
      limit = 6
    }
    this.setState(
      {
        loading: true
      },
      () => {
        const path =
          process.env.REACT_APP_NODE_API_ROOT_URL +
          `/clinicians/clients/${user_id}/check_in_scores/symptoms?` +
          '&limit=' +
          limit
        this.props.api.GET(path).then(json => {
          let symptom_chart_data = json.map(score => {
            let obj = { created_at: score.created_at }
            const scores = score.scores
            for (var s = 0; s < scores.length; s++) {
              const key = scores[s].display
              const value = scores[s].value
              obj[key] = value
            }
            return obj
          })
          this.setState({
            loading: false,
            symptom_chart_data: orderBy(
              symptom_chart_data,
              ['created_at'],
              ['asc']
            ),
            raw_checkin_data: json
          })
        })
      }
    )
  }

  handleAssessmentChange = e => {
    this.setAssessment(e.target.value)
  }

  handleModuleChange = e => {
    const module = find(this.props.treatmentData, { module_id: e.target.value })
    const scores = module.scores
    const week_array = this.fillAndGroupByWeek(scores, module)
    const weekly_formated_scores = week_array.map(week_scores =>
      this.formatWeeklyModuleAnswers(week_scores.reverse(), module)
    )
    this.setState({
      week_array,
      weekly_formated_scores,
      currentWeekIndex: 0,
      selected_module_id: e.target.value,
      module_scores: scores,
      selected_module_score_id: scores[scores.length - 1].id //by default, make the last one in the list (the most recent) the default
    })
  }

  handleAssessmentDateChange = e => {
    this.setState({
      selected_assessment_score_id: e.target.value
    })
  }

  handleAssessmentAssigneeUserChange = e => {
    const { assessmentData } = this.props

    const selectedAssigneeUserId = e.target.value

    const selectedAssessment = assessmentData.find(
      a => a.assessment_id === this.state.selected_assessment_id
    )

    const assigneeAssessmentScores = selectedAssessment.scores.filter(
      score => score.assigneeUser.id === selectedAssigneeUserId
    )

    this.setState({
      selectedAssigneeUserId,
      assessment_scores: assigneeAssessmentScores,
      selected_assessment_score_id: assigneeAssessmentScores[0].id
    })
  }

  handleModuleDateChange = e => {
    this.setState({
      selected_module_score_id: e.target.value
    })
  }

  handleJumpToPoint = (assessment_id, score_id) => {
    const scores = find(this.props.assessmentData, {
      assessment_id: assessment_id
    }).scores
    this.setState({
      selected_assessment_id: assessment_id,
      assessment_scores: scores,
      selected_assessment_score_id: score_id
    })
  }

  formatModuleAnswers = (answers, module) => {
    return module.content.map(section => {
      const questions = section.questions
      const data = questions.map(question => {
        const answer = answers.find(a => a.key === question.key) || {}
        const recorded_answer_value = answer.value
        if (section.type === 'free_text') {
          return {
            key:
              !recorded_answer_value || recorded_answer_value.length < 1
                ? "This question's response was left empty by the client"
                : recorded_answer_value,
            value: null,
            italicize:
              !recorded_answer_value || recorded_answer_value.length < 1
          }
        }
        const formatted_question = question.display
        const possible_answers = question.answers
        const ans = find(possible_answers, {
          value: parseInt(recorded_answer_value)
        })
        let formatted_answer = ans ? ans.display : '--'
        return {
          key: formatted_question,
          value: formatted_answer
        }
      })
      return { data, header: section.title }
    })
  }

  formatWeeklyModuleAnswers = (scores_by_day, module) => {
    let scores_by_key = {}
    scores_by_day.forEach(score_by_day => {
      score_by_day.answers.forEach(answer => {
        let new_score_array = [{ day: score_by_day.day, value: answer.value }]
        scores_by_key[answer.key] = scores_by_key[answer.key]
          ? scores_by_key[answer.key].concat(new_score_array)
          : new_score_array
      })
    })

    return (
      // line below prepends array of sections with a special type of section with no data and just a header for dates
      [
        {
          data: null,
          header: scores_by_day.map(score => {
            if (score.created_at) {
              return moment(score.created_at).format('ddd M/D h:mma')
            }
            return moment(score.day).format('ddd M/D')
          })
        }
      ].concat(
        module.content.map(section => {
          const questions = section.questions
          const data = questions.map(question => {
            const formatted_question = question.display
            const possible_answers = question.answers
            const answers = scores_by_key[question.key].map(answer_obj => {
              if (section.type === 'free_text') {
                const display = !answer_obj.value
                  ? "This question's response was left empty by the client"
                  : answer_obj.value
                return { display, italicize: !answer_obj.value }
              } else if (answer_obj.value === '--') {
                return { display: '--' }
              }
              return find(possible_answers, {
                value: parseInt(answer_obj.value)
              })
            })
            let formatted_answers = answers.map(ans => ({
              value: ans ? ans.display : '--',
              italicize: ans.italicize
            }))
            return {
              key: formatted_question,
              value: formatted_answers
            }
          })
          return { data, header: section.title }
        })
      )
    )
  }

  renderTreatmentTables = sections => {
    return sections.map(section => {
      return (
        <div key={section.header} className={this.props.classes.table}>
          <JoyTable
            headers={[section.header.toUpperCase(), '']}
            data={section.data}
          />
        </div>
      )
    })
  }

  renderWeeklyTreatmentTables = sections => {
    return (
      <div className={this.props.classes.table}>
        <JoyTable data={sections} isWeekly={true} />
      </div>
    )
  }

  renderAssessmentScore = () => {
    const { classes } = this.props
    if (
      this.state.assessment_scores &&
      this.state.selected_assessment_score_id
    ) {
      const assessment = find(this.props.assessmentData, {
        assessment_id: this.state.selected_assessment_id
      })

      const isBPDS = assessment.content.display_name === 'BPDS'

      const assessment_scores = assessment.scores
      const assessment_score = find(assessment_scores, {
        id: this.state.selected_assessment_score_id
      })
      const total_score = assessment_score.total_score
      const mapping = assessment.content.sections[0].mapping
      const sub_scores =
        assessment_score.snapshot_scores || assessment_score.sub_scores

      return (
        <div>
          <p className={classes.assessment_score_result}>
            {formatScoreToLabel(total_score, mapping)}
          </p>
          {sub_scores &&
            sub_scores.length > 0 &&
            sub_scores.map(sub_score => {
              if (isBPDS && assessment_score.snapshot_scores) {
                return this.renderBPDSSnapshotScore(sub_score)
              }

              return (
                <div className={classes.sub_score} key={sub_score.display}>
                  <span>
                    {sub_score.display}:{' '}
                    {sub_score.value +
                      (sub_score.label ? ` (${sub_score.label})` : '')}
                  </span>
                </div>
              )
            })}
          <JoyTable
            headers={[]}
            data={formatAssessmentAnswers(assessment_score.answers, assessment)}
          />
        </div>
      )
    }

    return null
  }

  renderBPDSSnapshotScore(snapshotScore) {
    const { classes } = this.props
    if (
      snapshotScore.follow_up_asmts &&
      snapshotScore.follow_up_asmts.length > 0
    ) {
      const [followUpAssessment] = snapshotScore.follow_up_asmts
      return (
        <div className={classes.sub_score}>
          <span>
            {snapshotScore.domain === 'default' && <br />}
            {snapshotScore.domain === 'default'
              ? 'Client did not screen positive for any of the above domains, so we assigned a general well-being measure'
              : `${snapshotScore.domain_display_name}: ${snapshotScore.score} ${
                  snapshotScore.interpretation
                    ? ` (${snapshotScore.interpretation})`
                    : ''
                }`}
          </span>
          <Text as="span" fontWeight="bold">
            {`  → ${followUpAssessment.name}`}
            <Text as="span" fontWeight="normal">
              {` | ${followUpAssessment.num_questions} Questions | ${followUpAssessment.cadence} `}
            </Text>
            <Text
              as="span"
              fontWeight="normal"
              textDecoration="underline"
              color="primary"
            >
              <NavLink
                to={`/patient/${this.props.user_id}/settings/outcome_measures`}
              >
                View
              </NavLink>
            </Text>
          </Text>
        </div>
      )
    }

    return (
      <div className={classes.sub_score}>
        <span>
          {snapshotScore.domain_display_name}:{' '}
          {snapshotScore.score +
            (snapshotScore.interpretation
              ? ` (${snapshotScore.interpretation})`
              : '')}
        </span>
      </div>
    )
  }

  createDayArray = (start_day, stop_day) => {
    let day_array = []
    let current_day = start_day
    while (current_day.isSameOrBefore(stop_day, 'day')) {
      day_array.push(current_day.format('MMM DD YYYY'))
      current_day = current_day.add(1, 'day')
    }
    return day_array
  }

  fillAndGroupByWeek = (module_scores, module) => {
    const oldest_score = module_scores[module_scores.length - 1]
    const questions = module.content.flatMap(c => c.questions)
    const empty_answers = questions.map(question => {
      return { key: question.key, value: '--' }
    })
    const day_array = this.createDayArray(moment(oldest_score.day), moment())
    let master_array = []
    day_array.reverse().forEach(day => {
      const matching_days = module_scores.filter(score =>
        moment(score.day).isSame(day, 'day')
      )
      if (matching_days.length) {
        const complete_matching_days = matching_days.map(d => {
          d.answers = this.tempFillMissingAnswers(d.answers, questions)
          return d
        })
        master_array.push(complete_matching_days)
      } else {
        master_array.push({ answers: empty_answers, day })
      }
    })
    const week_array = chunk(master_array, 7).map(week => flatten(week))
    return week_array
  }

  tempFillMissingAnswers = (answers, questions) => {
    if (answers.length === questions.length) return answers

    const complete_answers = [...answers]
    questions.forEach(q => {
      let answer = complete_answers.find(a => a.key === q.key)
      if (!answer) {
        answer = { key: q.key, value: '--' }
        complete_answers.push(answer)
      }
    })
    return complete_answers
  }

  renderModuleScore = () => {
    if (this.state.module_scores && this.state.selected_module_score_id) {
      const module = find(this.props.treatmentData, {
        module_id: this.state.selected_module_id
      })
      const module_scores = module.scores
      const module_score = find(module_scores, {
        id: this.state.selected_module_score_id
      })

      return (
        <div>
          {this.state.isDailyView
            ? this.renderTreatmentTables(
                this.formatModuleAnswers(module_score.answers, module)
              )
            : this.state.week_array.length
            ? this.renderWeeklyTreatmentTables(
                this.state.weekly_formated_scores[this.state.currentWeekIndex]
              )
            : null}
        </div>
      )
    }
    return null
  }

  goBackOneWeek = () => {
    this.setState({
      currentWeekIndex: this.state.currentWeekIndex + 1
    })
  }
  goForwardOneWeek = () => {
    this.setState({
      currentWeekIndex: this.state.currentWeekIndex - 1
    })
  }

  renderPrevNext = () => {
    const current_week_array = this.state.week_array[
      this.state.currentWeekIndex
    ]
    const { classes } = this.props
    return (
      <div className={classes.prev_next}>
        {this.state.currentWeekIndex === this.state.week_array.length - 1 ? (
          <div className={classes.placeholder}></div>
        ) : (
          <div>
            <div
              onClick={this.goBackOneWeek}
              className={cx(classes.prev, classes.clickable)}
              tabIndex="0"
              role="button"
              onKeyDown={e => {
                const key = e.which
                if (key === 13 || key === 32) this.goBackOneWeek()
              }}
            >
              <div className={classes.chevron_flip}>
                <Chevron fill={'#2d54e8'} />
              </div>
              {`Previous week`}
            </div>
          </div>
        )}
        <p className={classes.table_title}>
          {this.state.week_array.length &&
            `Week Beginning ${moment(current_week_array[0].day).format(
              'dddd M/D'
            )}`}
        </p>
        {this.state.currentWeekIndex === 0 ? (
          <div className={classes.placeholder}></div>
        ) : (
          <div
            onClick={this.goForwardOneWeek}
            className={cx(classes.next, classes.clickable)}
            tabIndex="0"
            role="button"
            onKeyDown={e => {
              const key = e.which
              if (key === 13 || key === 32) this.goForwardOneWeek()
            }}
          >
            {`Next week`}
            <Chevron fill={'#2d54e8'} />
          </div>
        )}
      </div>
    )
  }

  renderContent = () => {
    const { content, classes } = this.props

    if (this.state.loading) {
      return (
        <div style={{ marginTop: '100px', marginBottom: '100px' }}>
          <Loading />
        </div>
      )
    }

    if (content === 'symptoms') {
      return (
        <div className={classes.container}>
          <DialogTitle id="simple-dialog-title">
            <div className={classes.dialog_title}>
              <span>Select symptoms and compare severity over time</span>
              <span className={classes.close} onClick={this.props.handleModal}>
                <X fill="#333333" />
              </span>
            </div>
          </DialogTitle>
          <div className={classes.content}>
            <SymptomsBlock
              chartType={'symptomsOverTime'}
              symptomsOverTime={this.state.symptom_chart_data}
              data={this.state.raw_checkin_data}
              checkins={this.state.checkins}
              user_id={this.props.user_id}
              user_role={this.props.user_role}
              api={this.props.api}
            />
          </div>
        </div>
      )
    }
    if (content === 'lifestyle') {
      return (
        <div className={classes.container}>
          <DialogTitle id="simple-dialog-title">
            <div className={classes.dialog_title}>
              <span>Lifestyle data</span>
              <span className={classes.close} onClick={this.props.handleModal}>
                <X fill="#333333" />
              </span>
            </div>
          </DialogTitle>
          <p className={classes.subtitle}>
            These charts are generated from check-in and phone sensor data.
          </p>
          <div className={classes.content}>
            <LifestyleCharts
              insightsData={this.props.insightsData}
              checkins={this.state.checkins}
            />
          </div>
        </div>
      )
    }
    if (content === 'progress') {
      const assessments = this.props.assessmentData
      const assessment_scores = this.state.assessment_scores

      const assigneeUsers = _.flatten(
        assessments
          .filter(
            assessment =>
              assessment.assessment_id === this.state.selected_assessment_id
          )
          .map(assessment => assessment.scores)
      ).map(score => score.assigneeUser)
      const uniqueAssigneeUsers = _.uniqBy(assigneeUsers, 'id')

      return (
        <div className={classes.container}>
          <DialogTitle id="simple-dialog-title">
            <div className={classes.dialog_title}>
              <span>Assessment results</span>
              <span className={classes.close} onClick={this.props.handleModal}>
                <X fill="#333333" />
              </span>
            </div>
          </DialogTitle>
          {this.props.assessmentData && this.props.assessmentData.length ? (
            <div className={classes.content}>
              <label aria-label="assessment_picker">
                <Select
                  variant="outlined"
                  className={classes.assessment_select}
                  native
                  value={this.state.selected_assessment_id}
                  onChange={e => this.handleAssessmentChange(e)}
                  input={
                    <OutlinedInput
                      className={classes.outlined_select}
                      labelWidth={0}
                    />
                  }
                >
                  {assessments.map(assessment => {
                    return (
                      <option
                        key={assessment.assessment_id}
                        value={assessment.assessment_id}
                      >
                        {assessment.name}
                      </option>
                    )
                  })}
                </Select>
              </label>
              <label aria-label="assignee_user">
                <Select
                  variant="outlined"
                  className={classes.assessment_select}
                  native
                  value={this.state.selectedAssigneeUserId}
                  onChange={e => this.handleAssessmentAssigneeUserChange(e)}
                  input={
                    <OutlinedInput
                      className={classes.outlined_select}
                      labelWidth={0}
                    />
                  }
                >
                  {uniqueAssigneeUsers.map(user => {
                    return (
                      <option key={user.id} value={user.id}>
                        {user.info.firstName} {user.info.lastName}
                      </option>
                    )
                  })}
                </Select>
              </label>
              <label aria-label="date_picker">
                <Select
                  variant="outlined"
                  native
                  value={this.state.selected_assessment_score_id}
                  onChange={e => this.handleAssessmentDateChange(e)}
                  input={
                    <OutlinedInput
                      className={classes.outlined_select}
                      labelWidth={0}
                    />
                  }
                >
                  {assessment_scores.map(assessment_score => {
                    return (
                      <option
                        key={assessment_score.id}
                        value={assessment_score.id}
                      >
                        {moment(assessment_score.created_at).format(
                          'ddd MM/DD/YY h:mm a'
                        )}
                      </option>
                    )
                  })}
                </Select>
              </label>
              {this.renderAssessmentScore()}
            </div>
          ) : (
            <div className={classes.content}>
              <p>
                Once your client completes their first assessment, you will see
                results here. Check back soon!
              </p>
            </div>
          )}
        </div>
      )
    }
    if (content === 'treatment') {
      const modules = this.props.treatmentData
      const module_scores = this.state.module_scores
      return (
        <div className={classes.container}>
          <DialogTitle id="simple-dialog-title">
            <div className={classes.dialog_title}>
              <div className={classes.title_week_day}>
                <span>Worksheets</span>
                <span className={classes.daily_weekly}>
                  <p
                    className={
                      this.state.isDailyView
                        ? classes.selected_option
                        : classes.other_option
                    }
                    onClick={() => this.setState({ isDailyView: true })}
                    tabIndex="0"
                    role="button"
                    onKeyDown={e => {
                      const key = e.which
                      if (key === 13 || key === 32)
                        this.setState({ isDailyView: true })
                    }}
                  >
                    Daily view
                  </p>
                  {!this.props.isOnlyDailyView && (
                    <>
                      <p>|</p>
                      <p
                        className={
                          !this.state.isDailyView
                            ? classes.selected_option
                            : classes.other_option
                        }
                        onClick={() => this.setState({ isDailyView: false })}
                        tabIndex="0"
                        role="button"
                        onKeyDown={e => {
                          const key = e.which
                          if (key === 13 || key === 32)
                            this.setState({ isDailyView: false })
                        }}
                      >
                        Weekly view
                      </p>
                    </>
                  )}
                </span>
              </div>
              <span className={classes.close} onClick={this.props.handleModal}>
                <X fill="#333333" />
              </span>
            </div>
          </DialogTitle>
          {this.props.treatmentData && this.props.treatmentData.length ? (
            <div className={classes.content}>
              <Select
                variant="outlined"
                className={classes.assessment_select}
                native
                value={this.state.selected_module_id}
                onChange={e => this.handleModuleChange(e)}
                input={
                  <OutlinedInput
                    className={classes.outlined_select}
                    labelWidth={0}
                  />
                }
              >
                {modules.map(module => {
                  return (
                    <option key={module.module_id} value={module.module_id}>
                      {module.title}
                    </option>
                  )
                })}
              </Select>
              {this.state.isDailyView ? (
                <Select
                  variant="outlined"
                  native
                  value={this.state.selected_module_score_id}
                  onChange={e => this.handleModuleDateChange(e)}
                  input={
                    <OutlinedInput
                      className={classes.outlined_select}
                      labelWidth={0}
                    />
                  }
                >
                  {module_scores.map(module_score => {
                    return (
                      <option key={module_score.id} value={module_score.id}>
                        {moment(module_score.created_at).format(
                          'ddd MM/DD/YY h:mm a'
                        )}
                      </option>
                    )
                  })}
                </Select>
              ) : (
                this.renderPrevNext()
              )}
              {this.renderModuleScore()}
            </div>
          ) : (
            <div className={classes.content}>
              <p>
                Once your patient completes their first check-in, you will see
                results here. Check back soon!
              </p>
            </div>
          )}
        </div>
      )
    }
    return null
  }

  render() {
    const { isModalOpen } = this.props
    if (!isModalOpen) {
      return null
    }

    return (
      <Dialog
        open={this.props.isModalOpen}
        aria-labelledby="simple-dialog-title"
        onClose={this.props.handleModal}
        maxWidth={'lg'}
        fullWidth={true}
        fullScreen={isMobile ? true : false}
        TransitionComponent={Transition}
      >
        {this.renderContent()}
      </Dialog>
    )
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default withStyles(styles)(SectionModal)
