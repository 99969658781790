import React from 'react'

const Caution = props => (
  <svg
    className={props.className}
    width="16px"
    height="16px"
    viewBox="0 0 44 38"
    version="1.1"
  >
    <g
      id="caution"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        transform="translate(-143.000000, -37.000000)"
        fill={props.fill ? props.fill : '#f3a53c'}
      >
        <g transform="translate(143.000000, 37.000000)">
          <path d="M0.867223642,38 L43.1327764,38 C43.4089187,38 43.6327764,37.7761424 43.6327764,37.5 C43.6327764,37.4120287 43.6095663,37.3256145 43.5654895,37.2494819 L33,19 L33,19 L22.4327131,0.74741361 C22.2943558,0.50843274 21.9884627,0.426861257 21.7494819,0.565218603 C21.6738761,0.608990359 21.6110586,0.67180785 21.5672869,0.74741361 L0.434510499,37.2494819 C0.296153154,37.4884627 0.377724637,37.7943558 0.616705507,37.9327131 C0.69283818,37.97679 0.779252345,38 0.867223642,38 Z M23,34 L21,34 C20.4477153,34 20,33.5522847 20,33 L20,31 C20,30.4477153 20.4477153,30 21,30 L23,30 C23.5522847,30 24,30.4477153 24,31 L24,33 C24,33.5522847 23.5522847,34 23,34 Z M23,26 L21,26 C20.4477153,26 20,25.5522847 20,25 L20,15 C20,14.4477153 20.4477153,14 21,14 L23,14 C23.5522847,14 24,14.4477153 24,15 L24,25 C24,25.5522847 23.5522847,26 23,26 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default Caution
