import React, { useState, useEffect } from 'react'
import { HStack, VStack, Box, Button } from '@chakra-ui/react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'

const style = {
  base: {
    iconColor: '#c4f0ff',
    color: '#1a202c',
    fontWeight: '500',
    fontFamily: 'TofinoPersonal, Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#1a202c'
    },
    '::placeholder': {
      color: '#999999'
    }
  },
  invalid: {
    iconColor: '#e02031',
    color: '#e02031'
  }
}

const CreditCardForm = ({
  onComplete,
  onRevertToSaved
}: {
  onComplete: (isComplete: boolean) => void
  onRevertToSaved?: () => void
}) => {
  const [isNumberComplete, setIsNumberComplete] = useState(false)
  const [isExpComplete, setIsExpComplete] = useState(false)
  const [isCvcComplete, setIsCvcComplete] = useState(false)

  const [error, setError] = useState(null)

  useEffect(() => {
    onComplete(isNumberComplete && isExpComplete && isCvcComplete)
  }, [isNumberComplete, isExpComplete, isCvcComplete, onComplete])

  const handleNumberChange = async (event: any) => {
    setIsNumberComplete(event.complete)
    setError(event.error ? event.error.message : null)
  }

  const handleExpChange = async (event: any) => {
    setIsExpComplete(event.complete)
    setError(event.error ? event.error.message : null)
  }

  const handleCvcChange = async (event: any) => {
    setIsCvcComplete(event.complete)
    setError(event.error ? event.error.message : null)
  }

  return (
    <Box w="100%" position="relative">
      {onRevertToSaved && (
        <Box position="absolute" right={0} top={-55}>
          <Button
            variant="link"
            textDecoration="none"
            _focus={{ outline: 'none' }}
            onClick={onRevertToSaved}
          >
            Revert to saved
          </Button>
        </Box>
      )}
      {error && (
        <div className="errorMessage" role="alert">
          {error}
        </div>
      )}

      <VStack w="100%" spacing="24px">
        <Box
          w="100%"
          padding="16px"
          style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
        >
          <CardNumberElement
            options={{
              placeholder: 'Card Number',
              showIcon: true,
              style: style,
              // @ts-ignore
              disableLink: true
            }}
            onChange={handleNumberChange}
          />
        </Box>
        <HStack spacing="24px" w="100%">
          <Box
            w="100%"
            padding="16px"
            style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
          >
            <CardExpiryElement
              options={{
                placeholder: 'Expiration date (MM/YY)',
                style: style
              }}
              onChange={handleExpChange}
            />
          </Box>
          <Box
            w="100%"
            padding="16px"
            style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
          >
            <CardCvcElement
              options={{ placeholder: 'Security Code', style: style }}
              onChange={handleCvcChange}
            />
          </Box>
        </HStack>
      </VStack>
    </Box>
  )
}

export default CreditCardForm
