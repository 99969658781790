import { useMemo } from 'react'
import config from '../config'

export function useComponentConfig (componentName) {
  if (!componentName) {
    throw new Error('useComponentConfig() requires a componentName')
  }
  
  if (!config.components || !config.components[componentName]) {
    throw new Error(`useComponentConfig() could not find config for "${componentName}"`)
  }

  const componentConfig = useMemo(() => {
    return config.components[componentName]
  }, [componentName])

  return componentConfig
}