import React from 'react'

export const TrashIcon = ({
  fill,
  width,
  height
}: {
  fill?: string
  width?: string
  height?: string
}) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 16V6H3V16H11ZM4.5 0H9.5L10.5 1H14V3H0V1H3.5L4.5 0ZM1 4H13V16C13 17.1 12.1 18 11 18H3C1.9 18 1 17.1 1 16V4ZM5 8H6V14H5V8ZM8 8H9V14H8V8Z"
      fill={fill || '#282828'}
    />
  </svg>
)
