import { useStoreState } from 'easy-peasy'
import { fromPairs, zip, union, find } from 'lodash'


const permissionWeights = {
  own: 1,
  clinic: 2,
  org: 3,
  any: 4
}

class Permissions {

  // determines if the user has a given permission
  static has (userPermissions, permissionString) {
    const permissionToCheck = Permissions._parse(permissionString)

    return Boolean(find(userPermissions, permission => {
      // originally all permissions were parsed in _fetch but parsing each permission here
      // reduces overall execution time (initing the class + running has()) by ~50%, even
      // when the permission is not found, meaning every permission is parsed and checked
      const parsedPermission = Permissions._parse(permission)

      return (
        (parsedPermission.action === '*' || permissionToCheck.action === parsedPermission.action) &&
        (parsedPermission.resource === '*' || permissionToCheck.resource === parsedPermission.resource) &&
        (parsedPermission.attribute === '*' || permissionToCheck.attribute === parsedPermission.attribute) &&
        (permissionToCheck.possession === '^' || permissionWeights[permissionToCheck.possession] <= permissionWeights[parsedPermission.possession])
      )
    }))
  }

  // finds all unique permissions given to a user and puts them into an array
  // note: this only identifies unique strings, not strings that uniquely identify a permission.
  // example: if a user has 2 roles and both roles have `read:clinic:reports:utilization`,
  // only one instance of this string will be returned. However if one of those roles has a
  // permission `read:clinic:activeclients:*` and another has `read:clinic:activeclients:list`,
  // both permissions strings will be kept, even though one is a subset of the other, making it
  // redundant.
  static mergePermissions (user) {
    const roles = user ? user.roles : []
    if (!roles.length) return []
    const permissions = union(...roles.map(role => role.permissions))

    return permissions
  }

  // takes in a string like '<action>:<possession>:<resource>:<attribute>'
  // returns object like { 'action': <action>, 'possession': <possession>, 'resource': <resource>, 'attribute': <attribute> }
  static _parse (permissionString) {
    return fromPairs(zip(['action', 'possession', 'resource', 'attribute'], permissionString.split(':')))
  }
}

export function usePermissions() {
  const { user } = useStoreState(state => state.auth)

  const mergedPermissions = Permissions.mergePermissions(user)

  function hasPermission(permission) {
    return Permissions.has(mergedPermissions, permission)
  }

  return {
    hasPermission
  }
}