import React, { useEffect, useState } from 'react'
import {
  Flex,
  Text,
  UnorderedList,
  ListItem,
  Container,
  Stack,
  Box
} from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import { useSessionControllerGetSessionPrep } from '~/clinician-api'
import { SessionControllerGetSessionPrepActions } from '~/clinician-api/models'
import { useParams } from 'react-router-dom'
import { useAssistPanelContext } from '../context'
import { ActionConfig } from './config'
import { ItemT, ClientT } from './types'
import BeforeYouBeginItem from './before-you-begin-item'
import Empty from './empty'
import Loading, { SessionPrepLoading } from './loading'
import { DisclaimerBanner } from '../../shared/disclaimer-banner'
import { SESSION_PREP } from '@handlers/sessions/components/shared/assist/demo-data'
import { endpoints } from '@api'
import { useQuery } from 'react-query'

const Prep = ({ client }: { client: ClientT }) => {
  const [showDemoLoading, setShowDemoLoading] = useState(true)

  const { sessionId } = useParams<{ sessionId: string }>()
  const { switchTab, setOverlayPanel } = useAssistPanelContext()
  const { isPlusPlanEnabled, isDemoClinician } = useExperienceManager()

  const { data: lastSession }: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId()],
    () =>
      endpoints.getClinicianClientLastSession.request({
        id: client.id
      })
  )

  const showDemoExperience = client.is_demo && isDemoClinician

  const {
    data: sessionPrep,
    isLoading: isSessionPrepLoading
  } = useSessionControllerGetSessionPrep(
    sessionId,
    {
      // @ts-ignore
      actions: [
        SessionControllerGetSessionPrepActions.VIEW_TREATMENT_PLAN,
        SessionControllerGetSessionPrepActions.VIEW_ASSESSMENT_SUGGESTIONS,
        SessionControllerGetSessionPrepActions.VIEW_PATIENT_HIGH_RISK_DETAILS,
        SessionControllerGetSessionPrepActions.TAKE_OVERDUE_ASSESSMENTS,
        SessionControllerGetSessionPrepActions.REVIEW_ASSESSMENT_RESULTS,
        SessionControllerGetSessionPrepActions.VIEW_WORKSHEET_SUGGESTIONS,
        SessionControllerGetSessionPrepActions.REVIEW_WORKSHEET_RESULTS
      ]
    },
    {
      query: {
        refetchInterval: (data: any) => {
          if (data?.isLoading) {
            return 5000
          }
          return false
        }
      },
      enabled: !showDemoExperience
    }
  )

  useEffect(() => {
    if (showDemoExperience) {
      setTimeout(() => {
        setShowDemoLoading(false)
      }, 1000)
    } else {
      setShowDemoLoading(false)
    }
  }, [showDemoExperience])

  const isLoading = isSessionPrepLoading || sessionPrep?.isLoading
  const isEmpty =
    !sessionPrep?.lastSessionRecap?.length &&
    !sessionPrep?.thisSessionFocusAreas?.length &&
    !sessionPrep?.beforeYouBegin?.items?.length

  if (showDemoExperience && showDemoLoading) {
    return <Loading />
  }

  if (isLoading) {
    return <Loading />
  }

  if (isEmpty) {
    return <Empty isFirstSession={!lastSession} />
  }

  const lastSessionRecap = showDemoExperience
    ? SESSION_PREP.lastSessionRecap
    : sessionPrep?.lastSessionRecap
  const thisSessionFocusAreas = showDemoExperience
    ? SESSION_PREP.thisSessionFocusAreas
    : sessionPrep?.thisSessionFocusAreas
  const beforeYouBegin = showDemoExperience
    ? SESSION_PREP.beforeYouBegin
    : sessionPrep?.beforeYouBegin

  return (
    <Container position="relative" h="calc(100vh - 175px)">
      <Flex
        flexDirection="column"
        gap="16px"
        pt="16px"
        pb="64px"
        flex="1"
        overflowY="auto"
      >
        {isPlusPlanEnabled && <DisclaimerBanner />}
        {!!lastSessionRecap?.length && (
          <Flex flexDirection="column">
            <Text fontWeight="bold">Last session recap:</Text>
            <UnorderedList px="4px">
              {lastSessionRecap.map((recap: string) => (
                <ListItem key={recap}>{recap}</ListItem>
              ))}
            </UnorderedList>
          </Flex>
        )}
        {!!thisSessionFocusAreas?.length && (
          <Flex flexDirection="column">
            <Text fontWeight="bold">This session focus areas:</Text>
            <UnorderedList px="4px">
              {thisSessionFocusAreas.map((focusArea: string) => (
                <ListItem key={focusArea}>{focusArea}</ListItem>
              ))}
            </UnorderedList>
          </Flex>
        )}
        {!!beforeYouBegin?.isLoading ? (
          <SessionPrepLoading />
        ) : (
          !!beforeYouBegin?.items?.length && (
            <Flex flexDirection="column" gap="8px">
              <Text fontWeight="bold">Before you begin:</Text>
              <Stack spacing="0" gap="8px">
                {beforeYouBegin?.items?.map((item: ItemT) => {
                  const {
                    text,
                    subtext,
                    icon: IconComponent,
                    backgroundColor,
                    textColor,
                    subtextColor,
                    buttonText,
                    handleClick
                  } = ActionConfig[item.action]

                  const { data } = item

                  return (
                    <BeforeYouBeginItem
                      key={item.action}
                      backgroundColor={backgroundColor}
                      textColor={textColor}
                      subtextColor={subtextColor}
                      icon={<IconComponent />}
                      buttonText={buttonText}
                      text={text({ client })}
                      subtext={subtext({ client, item })}
                      handleClick={
                        showDemoExperience
                          ? () => {}
                          : () =>
                              handleClick({ setOverlayPanel, switchTab, data })
                      }
                    />
                  )
                })}
              </Stack>
            </Flex>
          )
        )}
      </Flex>
    </Container>
  )
}

export default Prep
