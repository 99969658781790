import React, { useMemo } from 'react'
import {
  Text,
  Box,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalCloseButton
} from '@chakra-ui/react'
import { useQuery } from 'react-query'

import { endpoints } from '../../../../../../api'
import LifestyleCharts from '../lifestyle-charts'

export const LifestyleModal = ({ clientId, ...rest }) => {
  const { data } = useQuery(
    [clientId, endpoints.getClientLifestyleGraph.getCacheId()],
    () => endpoints.getClientLifestyleGraph.request({ clientId }),
    {
      initialData: []
    }
  )

  const graphData = useMemo(() => {
    return data.reduce((obj, param) => {
      obj[param.type] = param.data
      return obj
    }, {})
  }, [data])

  return (
    <Modal size="6xl" closeOnEsc {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box>Lifestyle Data</Box>
          <Text color="#4a4a4a" fontWeight="normal" mt="xsmall">
            These charts are generated from check-in and phone sensor data.
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LifestyleCharts insightsData={graphData} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
