import React from 'react'
import { Box } from '@chakra-ui/react'

interface PanelBodyPropsType {
  children: React.ReactNode
  px?: string
  pb?: string
  height?: object
  overflowY?: object
}

const PanelBody: React.FC<PanelBodyPropsType> = ({ px, height, children, overflowY, pb }) => (
  <Box
    data-testid="panel-body"
    px={px || '24px'}
    pb={pb || '24px'}
    height={height || { base: 'auto', md: 'calc(100vh - 228px)' }}
    overflowY={overflowY || { base: 'auto', md: 'scroll' }}
  >
    {children}
  </Box>
)

export default PanelBody
