import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Box, Text, Heading, Select, Button } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { ArrowRightIcon } from '@blueprinthq/joy'
import { useStoreState, useStoreActions, Actions } from 'easy-peasy'
import { FlagsmithFeatures } from '@constants/flagsmith'
import flagsmith from 'flagsmith'

import { Loading } from '@components'
import { endpoints } from '@api'
import { Organization } from 'src/api/endpoints'
import { usePermissions } from '@hooks'

import { WorkflowModal } from './workflow-modal'
import { StoreModel } from 'src/store/types'

type Workflow = {
  id: string
  name: string
  description: string
  organizationId: string | null
  isEnabled: boolean
  trigger: any
  index: number
}

const WorkflowSelect = ({
  id,
  name,
  description,
  organizationId,
  isEnabled,
  index
}: Workflow) => {
  const history = useHistory()
  const redirectUrl = `/organizations/${organizationId}/workflows/${id}`

  return (
    <Flex
      justify="space-between"
      p={5}
      onClick={() => history.push(redirectUrl)}
      border="1px solid #e4e5e6"
      borderTop="none"
      _hover={{ cursor: 'pointer' }}
      _first={{ borderTop: '1px solid #e4e5e6', borderTopRadius: '4px' }}
      _last={{ borderBottom: '1px solid #e4e5e6', borderBottomRadius: '4px' }}
      data-intercom-target={`workflows-select-${index}`}
    >
      <Box>
        <Text mb={1} as="b">
          {name}
        </Text>
        <Text color="dark_gray">{description}</Text>
      </Box>
      <Flex alignItems="center">
        <Flex
          width="48px"
          justify="center"
          bg={isEnabled ? '#DCEEDE' : 'pale_gray'}
          color={isEnabled ? '#33703B' : 'dark_gray'}
          borderRadius="4px"
          sx={{
            fontSize: '14px',
            mr: 'small',
            padding: 'xxsmall'
          }}
        >
          {isEnabled ? 'Live' : 'Draft'}
        </Flex>
        <ArrowRightIcon alignSelf="center" />
      </Flex>
    </Flex>
  )
}

interface ModalToggle {
  isOpen: boolean
  openModal: Function
  closeModal: Function
}

const useModalToggle = (): ModalToggle => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return { isOpen, openModal, closeModal }
}

export const WorkflowsTab = () => {
  const { setSelectedOrganizationId }: any = useStoreActions(
    (actions: Actions<StoreModel>) => actions.workflows
  )
  const { selectedOrganizationId } = useStoreState((state: StoreModel) => state.workflows)
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const modalToggle = useModalToggle()
  const { hasPermission } = usePermissions()
  const hasEditPermission = hasPermission('*:org:workflows:*')
  const isSuperAdmin = hasPermission('*:any:*:*')

  useEffect(() => {
    if (!selectedOrganizationId)
      setSelectedOrganizationId(user?.clinic?.organization_id)
  }, [user])

  const { data: orgData, isLoading: isOrgsLoading } = useQuery(
    endpoints.getAllOrganizations.getCacheId(),
    () => endpoints.getAllOrganizations.request(),
    {
      placeholderData: {
        organizations: []
      },
      enabled: isSuperAdmin
    }
  )

  const {
    data: workflows,
    isLoading
  }: { data: Workflow[] | undefined; isLoading: boolean } = useQuery(
    [endpoints.getOrganizationWorkflows.getCacheId(), selectedOrganizationId],
    // @ts-ignore
    () =>
      endpoints.getOrganizationWorkflows.request({
        organizationId: selectedOrganizationId
      }),
    { enabled: Boolean(selectedOrganizationId) }
  )

  const showAppointmentWorkflows = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_APPOINTMENT_WORKFLOWS
  )

  if (isLoading || isOrgsLoading) return <Loading />

  return (
    <>
      <Box>
        <Flex
          mb={'medium'}
          justify="space-between"
          alignItems="center"
          flexDirection="row"
        >
          <Box w="100%">
            <Heading mb="xxsmall">Workflows</Heading>
            <Text>Manage the care pathways for your organization</Text>
          </Box>
          {hasEditPermission && (
            <Button
              size={'lg'}
              onClick={() => modalToggle.openModal()}
              data-intercom-target={`create-workflow`}
            >
              Create Workflow
            </Button>
          )}
          <WorkflowModal
            isOpen={modalToggle.isOpen}
            onClose={modalToggle.closeModal}
            selectedOrganizationId={selectedOrganizationId}
          />
        </Flex>
      </Box>
      <Flex justify="space-between" alignItems="center" flexDirection="row">
        {isSuperAdmin && orgData?.organizations && (
          <Select
            value={selectedOrganizationId!}
            style={{ border: '1px solid #E4E5E6' }}
            _focus={{
              outline: 'none'
            }}
            mb={'small'}
            borderRadius="4px"
            height="48px"
            onChange={e => setSelectedOrganizationId(e.target.value)}
          >
            {orgData?.organizations?.map((org: Organization) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </Select>
        )}
      </Flex>
      <Box mb="xxlarge">
        {/* @ts-ignore */}
        {workflows?.map((workflow: Workflow, i: number) => {
          if (
            workflow?.trigger?.trigger_type ===
              'upcoming-appointment-trigger' &&
            !showAppointmentWorkflows
          )
            return null

          return (
            // @ts-ignore
            <WorkflowSelect
              key={workflow.id}
              id={workflow.id}
              name={workflow.name}
              description={workflow.description}
              organizationId={selectedOrganizationId!}
              // @ts-ignore
              isEnabled={workflow.is_enabled}
              index={i}
            />
          )
        })}
      </Box>
    </>
  )
}
