import React, { useMemo } from 'react'
import {
  Flex,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Text,
  Avatar,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Heading
} from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'
import { ArrowDownIcon, ArrowUpIcon } from '@blueprinthq/joy'

import { cloudinaryAssetToUrl } from '../../../../utilities'
import { getEffectSizeProps, TIME_PERIODS } from '../constants'
import { get } from 'lodash'

const AvgScoreIntervalPopover = ({ children, intervalData, pointChange }) => {
  const { color, label } = getEffectSizeProps(intervalData.effectSize.value)

  return (
    <Popover trigger="hover">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        sx={{
          background: 'white',
          padding: 'small',
          border: '1px solid',
          borderColor: 'light_gray'
        }}
      >
        <PopoverArrow />
        <PopoverBody textTransform="initial">
          <Text>Treatment response:</Text>
          <Heading fontSize="lg" color={color}>
            {label}
          </Heading>
          <Box pt="xsmall">
            <Text>Avg. Score: {intervalData.avgScore.value}</Text>
            <Text>Point change since baseline: {pointChange}</Text>
            <Text>Effect Size: {intervalData.effectSize.value}</Text>
          </Box>
          <Box mt="xsmall">
            <Text fontWeight="bold">{`* Completed ${intervalData.assessmentCount.toLocaleString()} times by ${intervalData.clientCount.toLocaleString()} clients`}</Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const AvgScoreText = ({ baseline, comparisonPeriod }) => {
  if (!comparisonPeriod || !comparisonPeriod.effectSize)
    return <Text fontSize="sm">-</Text>

  const comparisonPeriodAvgScore = comparisonPeriod.avgScore
    ? comparisonPeriod.avgScore.value
    : 0

  const averageScoreDifference =
    comparisonPeriodAvgScore - baseline.avgScore.value
  const isIncrease = averageScoreDifference > 0

  const effectProps = getEffectSizeProps(comparisonPeriod.effectSize.value)

  const pointChange = `${isIncrease ? '+' : '-'}${Math.abs(
    averageScoreDifference
  ).toFixed(1)}`

  return (
    <AvgScoreIntervalPopover
      intervalData={comparisonPeriod}
      pointChange={pointChange}
    >
      <Text fontSize="sm" whiteSpace="nowrap">
        {parseFloat(comparisonPeriodAvgScore).toFixed(1)}{' '}
        <Text
          as="span"
          fontSize="sm"
          sx={{
            color: effectProps.color
          }}
        >{`(${pointChange})`}</Text>
      </Text>
    </AvgScoreIntervalPopover>
  )
}

const ScoreHeaderText = ({ text }) => {
  return (
    <Box>
      <Box as="span" whiteSpace="nowrap">
        AVG Score
      </Box>
      <br />
      <Box as="span" whiteSpace="nowrap">
        {text}
      </Box>
    </Box>
  )
}

export const ClinicianOutcomesTable = ({ data, onRowClick }) => {
  const flattenedData = useMemo(() => {
    return data?.map(clinicianRow => {
      const periods = Object.values(TIME_PERIODS).reduce((all, period) => {
        const foundIntervalPeriod = clinicianRow.intervals.find(
          interval => interval.period === period
        )
        all[period] = foundIntervalPeriod
        return all
      }, {})

      return {
        clinician: clinicianRow.clinician,
        clinicianClientCount: clinicianRow.clinicianClientCount,
        clinicianClientAssessmentCount:
          clinicianRow.clinicianClientAssessmentCount,
        periods
      }
    })
  }, [data])

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Clinician',
        accessor: row => {
          return `${row.clinician.firstName} ${row.clinician.lastName}`
        },
        style: {
          width: 210
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => (
          <Flex alignItems="center">
            <Avatar
              width="24px"
              height="24px"
              src={cloudinaryAssetToUrl(value.avatar, {
                transformations: {
                  resize: {
                    height: 96,
                    width: 96,
                    type: 'scale'
                  }
                }
              })}
              name={value}
              bg="pale_gray"
              size="sm"
              color="black"
              fontWeight="bold"
              mr="xsmall"
            />
            <Text fontSize="sm">{value}</Text>
          </Flex>
        )
      },
      {
        id: 'clientCount',
        Header: (
          <Box>
            Client <br /> Count
          </Box>
        ),
        accessor: 'clinicianClientCount',
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => <Text fontSize="sm">{value}</Text>
      },
      {
        id: 'clinicianClientAssessmentCount',
        Header: (
          <Box>
            Assessments <br /> Completed
          </Box>
        ),
        accessor: 'clinicianClientAssessmentCount',
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => <Text fontSize="sm">{value}</Text>,
        defaultCanSort: true
      },
      {
        id: 'baselineAvg',
        Header: <ScoreHeaderText text="Baseline" />,
        accessor: row => {
          return get(row, `periods.${TIME_PERIODS.BASELINE}.avgScore.value`)
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => {
          return (
            <Text fontSize="sm">
              {value ? parseFloat(value).toFixed(1) : '-'}
            </Text>
          )
        }
      },
      {
        id: 'week4',
        Header: <ScoreHeaderText text="4 Weeks" />,
        accessor: row => {
          return get(row, `periods.${TIME_PERIODS.WEEK_4}.avgScore.value`)
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => (
          <AvgScoreText
            baseline={row.original.periods[TIME_PERIODS.BASELINE]}
            comparisonPeriod={row.original.periods[TIME_PERIODS.WEEK_4]}
          />
        )
      },
      {
        id: 'week8',
        Header: <ScoreHeaderText text="8 Weeks" />,
        accessor: row => {
          return get(row, `periods.${TIME_PERIODS.WEEK_8}.avgScore.value`)
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => (
          <AvgScoreText
            baseline={row.original.periods[TIME_PERIODS.BASELINE]}
            comparisonPeriod={row.original.periods[TIME_PERIODS.WEEK_8]}
          />
        )
      },
      {
        id: 'week12',
        Header: <ScoreHeaderText text="12 Weeks" />,
        accessor: row => {
          return get(row, `periods.${TIME_PERIODS.WEEK_12}.avgScore.value`)
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => (
          <AvgScoreText
            baseline={row.original.periods[TIME_PERIODS.BASELINE]}
            comparisonPeriod={row.original.periods[TIME_PERIODS.WEEK_12]}
          />
        )
      },
      {
        id: 'week16',
        Header: <ScoreHeaderText text="16 Weeks" />,
        accessor: row => {
          return get(row, `periods.${TIME_PERIODS.WEEK_16}.avgScore.value`)
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => (
          <AvgScoreText
            baseline={row.original.periods[TIME_PERIODS.BASELINE]}
            comparisonPeriod={row.original.periods[TIME_PERIODS.WEEK_16]}
          />
        )
      },
      {
        id: 'week20',
        Header: <ScoreHeaderText text="20 Weeks" />,
        accessor: row => {
          return get(row, `periods.${TIME_PERIODS.WEEK_20}.avgScore.value`)
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => (
          <AvgScoreText
            baseline={row.original.periods[TIME_PERIODS.BASELINE]}
            comparisonPeriod={row.original.periods[TIME_PERIODS.WEEK_20]}
          />
        )
      },
      {
        id: 'discharge',
        Header: <ScoreHeaderText text="Discharge" />,
        accessor: row => {
          return get(row, `periods.${TIME_PERIODS.DISCHARGE}.avgScore.value`)
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => (
          <AvgScoreText
            baseline={row.original.periods[TIME_PERIODS.BASELINE]}
            comparisonPeriod={row.original.periods[TIME_PERIODS.DISCHARGE]}
          />
        )
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data: flattenedData,
      disableSortRemove: true,
      initialState: {
        sortBy: [{ id: 'clinicianClientAssessmentCount', desc: true }]
      }
    },
    useSortBy
  )

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup, i) => (
          <Tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => (
              <Th
                key={j}
                borderColor="light_gray"
                verticalAlign="middle"
                userSelect="none"
                sx={column.style}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <Flex alignItems="center">
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownIcon size="sm" fill={'gray.600'} />
                      ) : (
                        <ArrowUpIcon size="sm" fill={'gray.600'} />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <Tr
              key={i}
              {...row.getRowProps()}
              cursor="pointer"
              _hover={{
                bg: 'pale_gray'
              }}
              onClick={() => onRowClick(row.original)}
            >
              {row.cells.map((cell, j) => (
                <Td
                  key={j}
                  {...cell.getCellProps()}
                  borderColor="light_gray"
                  verticalAlign="middle"
                >
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}
