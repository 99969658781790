import React from 'react'
import styles from './passwordrules.module.css'
import { Check, X } from '../icons'

import * as Yup from 'yup'
import YupPassword from 'yup-password'
YupPassword(Yup)

// the rules in this library coincide with the DEFAULT_PASSWORD_RULES
const DEFAULT_PASSWORD_SCHEMA =  Yup.string().password().required('Required')

const DEFAULT_PASSWORD_RULES = [
  {
    description: 'No spaces',
    regex: /^\S+$/,
    isValid: false
  },
  {
    description: 'At least one number',
    regex: /.*[0-9]+.*/,
    isValid: false
  },
  {
    description: 'Uppercase and lowercase',
    regex: /(?=.*[a-z])(?=.*[A-Z])/,
    isValid: false
  },
  {
    description: 'At least 8 characters',
    regex: /^.{8,}$/,
    isValid: false
  },
  {
    description: 'At least one special character',
    regex: /[.,!&^%$#@()/_*\]<>{}"~`|'?[\\;:-]/,
    isValid: false
  }
]

const PasswordRules = props => {
  const rules = props.rules || DEFAULT_PASSWORD_RULES
  let xfill = '#a1a1a1'
  if (props.password.length) {
    xfill = '#ED384E'
  }

  for (var r = 0; r < rules.length; r++) {
    const isValid = props.password.match(rules[r].regex)
    if (isValid) {
      rules[r].isValid = true
    } else {
      rules[r].isValid = false
    }
  }

  const renderedRules = rules.map((rule, index) => {
    return (
      <div key={index} className={styles.password_rule}>
        {rule.isValid ? <Check /> : <X fill={xfill} />}
        <span className={styles.password_rule_description}>
          {rule.description}
        </span>
      </div>
    )
  })

  return (
    <div className={styles.rules}>
      <span className={styles.header}>Passwords must have...</span>
      {renderedRules}
    </div>
  )
}

export { PasswordRules as default, DEFAULT_PASSWORD_SCHEMA }
