import React from 'react'

const Chevron = props => (
  <img
    width="34px"
    alt={props.alt || 'chevron'}
    src="https://res.cloudinary.com/hellojoy/image/upload/v1607554556/chevron_right_wx5gkc.png"
  />
)
export default Chevron
