import React, { useMemo, useState, useEffect, Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LayoutGrid } from '@blueprinthq/joy'
import {
  Container,
  Flex,
  GridItem,
  Box,
  SimpleGrid,
  Tabs,
  TabList,
  Tab,
  Text
} from '@chakra-ui/react'
import { useStoreActions } from 'easy-peasy'
import { useExperienceManager } from '@hooks'

const Attribute = ({ children }) => (
  <Box
    as="span"
    sx={{
      fontWeight: 'bold',
      whiteSpace: 'nowrap'
    }}
  >
    {children}
  </Box>
)

const Value = ({ children }) => (
  <Box
    as="span"
    sx={{
      marginLeft: 2,
      lineHeight: '20px',
      fontSize: 16,
      color: '#333333'
    }}
  >
    {children}
  </Box>
)

export function ProfileHeader({
  name,
  attributes,
  rightComponent = null,
  tabs = null
}) {
  const location = useLocation()

  const { isEvidenceBasedCareEnabled } = useExperienceManager()
  const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
    if (!tabs) {
      return 0
    }

    return tabs.findIndex(
      element => location.pathname.search(element.matcher) !== -1
    )
  })

  const openEbcMarketingModal = useStoreActions(
    (actions: any) => actions.modals.ebcMarketingModal.openModal
  )

  useEffect(() => {
    if (!tabs) {
      return
    }

    const index = tabs.findIndex(
      element => location.pathname.search(element.matcher) !== -1
    )
    setSelectedTabIndex(index)
  }, [location])

  const rootMatch = location.pathname.match(/\/.*patient\/\w+-\w+-\w+-\w+-\w+/g)
  const rootPath = rootMatch[0]

  const isTempus = useMemo(() => process.env.REACT_APP_THEME === 'tempus', [
    process.env.REACT_APP_THEME
  ])

  return (
    <Box>
      <Container direction="column">
        <LayoutGrid
          sx={{
            minHeight: isEvidenceBasedCareEnabled ? 146 : '80px',
            pb: isTempus ? null : isEvidenceBasedCareEnabled ? 'medium' : null,
            pt: 'medium'
          }}
        >
          <GridItem
            colStart={{
              base: 1,
              sm: 1,
              md: isTempus ? 2 : 1
            }}
            colEnd={{
              base: 5,
              sm: 7,
              md: isTempus ? 8 : 11
            }}
          >
            <Box
              sx={{
                fontSize: 30,
                color: '#333333',
                fontWeight: 'bold',
                marginBottom: 22,
                textTransform: 'capitalize'
              }}
            >
              {name}
            </Box>
          </GridItem>
          {rightComponent}
          <GridItem
            colStart={{
              base: 1,
              sm: 1,
              md: isTempus ? 2 : 1
            }}
            colEnd={{
              base: 5,
              sm: 9,
              md: isTempus ? 8 : 13
            }}
          >
            <SimpleGrid
              columns={{
                base: 1,
                md: attributes.length
              }}
              spacingX="large"
            >
              {attributes.map((col, i) => (
                <Flex
                  key={`profile-header-attr-unique-${i}`}
                  flexDirection="column"
                  mb={{
                    base: isEvidenceBasedCareEnabled ? 'medium' : 0,
                    md: 0
                  }}
                >
                  {col.map((attr, i) =>
                    attr.hideIfNull && attr.value === null ? (
                      <Fragment key={`profile-header-col-${i}`}></Fragment>
                    ) : (
                      !attr.isHidden && (
                        <Fragment key={`profile-header-col-${i}`}>
                          <Flex justify="space-evenly" width="100%">
                            <Box width="40%">
                              <Text
                                key={`text-col-profile${i}`}
                                color="#282828"
                                fontWeight="bold"
                              >
                                {attr.name}
                              </Text>
                            </Box>
                            <Box width="60%">
                              <Text
                                as="div"
                                color="#282828"
                                align="left"
                                ml={'20px'}
                                fontSize="16px"
                              >
                                {attr.ValueRender
                                  ? attr.ValueRender(attr.value)
                                  : attr.value}
                              </Text>
                            </Box>
                          </Flex>
                        </Fragment>
                      )
                    )
                  )}
                </Flex>
              ))}
              {attributes.map((attr, i) => (
                <Flex
                  sx={{ flexDirection: 'row', alignItems: 'flex-start' }}
                  key={`profile-attr-${i}`}
                >
                  <Attribute>{attr.name}</Attribute>
                  <Value>
                    {attr.ValueRender
                      ? attr.ValueRender(attr.value)
                      : attr.value}
                  </Value>
                </Flex>
              ))}
            </SimpleGrid>
          </GridItem>
        </LayoutGrid>
      </Container>
      {tabs && (
        <Container position="relative">
          <LayoutGrid>
            <GridItem
              colSpan={{
                base: 4,
                sm: 8,
                md: 12
              }}
            >
              <Box position="sticky" overflowX="auto" top="0">
                <Tabs index={selectedTabIndex}>
                  <TabList flex={1}>
                    {tabs.map((tab, i) => {
                      if (tab.isDisabled) {
                        return (
                          <Tab
                            key={`profile-tab-${tab.key}-${i}`}
                            className={`pendo-${tab.key}-tab-tag`}
                            _focus={{
                              outline: 'none'
                            }}
                            height={'48px'}
                            justifyContent="flex-start"
                            p={0}
                            mr={'24px'}
                            whiteSpace="nowrap"
                            onClick={openEbcMarketingModal}
                            opacity={0.3}
                          >
                            {tab.display}
                          </Tab>
                        )
                      } else {
                        return (
                          <Tab
                            key={`profile-tab-${tab.key}-${i}`}
                            className={`pendo-${tab.key}-tab-tag`}
                            as={Link}
                            _focus={{
                              outline: 'none'
                            }}
                            height={'48px'}
                            to={`${rootPath}${tab.path}`}
                            justifyContent="flex-start"
                            p={0}
                            mr={'24px'}
                            whiteSpace="nowrap"
                          >
                            {tab.display}
                          </Tab>
                        )
                      }
                    })}
                  </TabList>
                </Tabs>
              </Box>
            </GridItem>
          </LayoutGrid>
        </Container>
      )}
    </Box>
  )
}
