import React from 'react'
import { Heading, Box, Text } from '@chakra-ui/react'
import { TextField, Button } from '@blueprinthq/joy'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'

import { useHistory } from 'react-router-dom'

const SearchSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required')
})

export const SearchInviteForm = () => {
  const history = useHistory()

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values, actions) => {
        const encodedEmail = encodeURIComponent(values.email)
        history.push(`/accept-invite?email=${encodedEmail}`)
        actions.setSubmitting(false)
      }}
      validationSchema={SearchSchema}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <Box w="100%" pb="medium">
            <Heading w="100%" mb="4px" size="lg">
              Create your account
            </Heading>
            <Text>Enter your email address to continue.</Text>
          </Box>
          <Box pb={errors.email && touched.email && 'small'}>
            <Field name="email">
              {({ field }) => (
                <TextField
                  {...field}
                  id="email"
                  label="Email Address"
                  isInvalid={errors.email && touched.email}
                  errorText={errors.email}
                />
              )}
            </Field>
          </Box>
          <Box sx={{ mt: 'small' }}>
            <Button
              size="lg"
              isLoading={isSubmitting}
              type="submit"
              margin={0}
              isFullWidth
            >
              Next
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
