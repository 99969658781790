import React, { useState, useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import { useMutation, useQueryClient } from 'react-query'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { PrimaryButton, SecondaryButton } from '../../components'
import { endpoints } from '../../api'
import { useLocation, useHistory } from 'react-router-dom'
import { useStyles } from './styles'

export const DeclineClientModal = ({
  clientId,
  firstName,
  isOpen,
  handleCloseModal
}) => {
  const queryClient = useQueryClient()
  const [errorMsg, setErrorMsg] = useState(null)
  const classes = useStyles()

  const location = useLocation()
  const history = useHistory()

  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const resetAndCloseModal = useCallback(() => {
    handleCloseModal()
    // Avoid user from seeing value being changed well closing modal
    setTimeout(() => {
      setErrorMsg('')
    }, 150)
  })

  const { mutate: executeDeclineClient } = useMutation(
    endpoints.patchCliniciansClientsDecline.request,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          endpoints.getPatientList.getCacheId()
        )
        resetAndCloseModal()
        setSnackbarMessage({
          variant: 'success',
          message: `${firstName} was successfully declined!`
        })

        if (location.pathname.includes('pending-client')) {
          history.replace('/patients/pending')
        }
      },
      onError: () =>
        setErrorMsg(
          'Oops, something went wrong. Please refresh the page and try again.'
        )
    }
  )

  return (
    <Dialog
      open={isOpen}
      onClose={resetAndCloseModal}
      maxWidth={'xs'}
      fullWidth={true}
      fullScreen={isMobile}
    >
      <div className={classes.dialog}>
        <DialogTitle className={classes.centerText}>Decline Client</DialogTitle>
        <DialogContent className="content">
          <DialogContentText className={classes.centerText}>
            Are you sure you want to decline {firstName}?
          </DialogContentText>
          {errorMsg && <p className="errorMessage">{errorMsg}</p>}
          <div className={classes.buttonContainer}>
            <SecondaryButton
              onClick={resetAndCloseModal}
              round
              className="button"
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              round
              className="button"
              onClick={() => executeDeclineClient({ clientId })}
            >
              Confirm
            </PrimaryButton>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export const DeclineClientModalConnected = () => {
  const user = useStoreState(state => state.auth.user)
  const { isOpen, clientId, firstName } = useStoreState(
    state => state.modals.declineClient
  )
  const handleCloseModal = useStoreActions(
    actions => actions.modals.declineClient.closeModal
  )

  return clientId && firstName && user ? (
    <DeclineClientModal
      isOpen={isOpen}
      clientId={clientId}
      firstName={firstName}
      handleCloseModal={handleCloseModal}
    />
  ) : null
}
