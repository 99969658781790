import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  button: {
    height: 41,
    width: 150
  },
  contents: {
    paddingBottom: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minHeight: '100px'
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  errorContainer: {
    height: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '5px 0'
  },
  title: {
    textAlign: 'center',
    paddingTop: '24px',
    paddingBottom: '10px'
  },
  leftText: {
    textAlign: 'left',
    color: 'black',
    marginBottom: '24px',
    fontSize: '15px'
  },
  blackLink: {
    color: 'black',
    textDecoration: 'underline',
    fontSize: '15px'
  },
  errorText: {
    color: 'red',
    textAlign: 'center'
  },
  dateInput: {
    marginBottom: '5px',
    marginTop: '15px',
    width: '275px'
  },
  link: {
    color: '#2d54e8',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',
    marginLeft: '7px'
  }
})