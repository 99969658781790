import React from 'react'
import { Cell } from './column-components'
import { SecondaryButton, Popover } from '../../components'
import { Mobile, Overdue, Warning } from '../../components/icons'
import config from '../../config'
import { NavLink } from 'react-router-dom'
import { Text } from '@chakra-ui/react'
import { ClientListActions } from '../../constants/clientListActions'
import moment from 'moment'
import { ArrowRightIcon, Button, UpdateIcon } from '@blueprinthq/joy'

export const genericColumn = (label, accessor, props = {}) => ({
  Header: label,
  accessor,
  ...props
})

export const nameColumn = () => ({
  Header: 'name',
  accessor: 'name',
  Cell({ row, value }) {
    return <NavLink to={row.values.path}>{value}</NavLink>
  }
})

export const clinicianColumn = () =>
  genericColumn(
    'clinician',
    d => `${d.clinician.first_name} ${d.clinician.last_name}`,
    { id: 'clinician', disableSortBy: true }
  )

export const assignClinicianColumn = openAssignClinicianModal => ({
  Header: 'clinician',
  accessor: d => `${d.clinician.first_name} ${d.clinician.last_name}`,
  id: 'clinician',
  disableSortBy: true,
  Cell({ row, value }) {
    return (
      <Cell onClick={e => e.stopPropagation()}>
        {row.original.clinician.first_name ? (
          value
        ) : (
          <Button
            variant="link"
            sx={{
              justifyContent: 'flex-start',
              ml: 0
            }}
            onClick={() =>
              openAssignClinicianModal({
                name: row.original.name,
                id: row.original.patient_id
              })
            }
          >
            Assign Clinician
          </Button>
        )}
      </Cell>
    )
  }
})

export const dateOfBirthColumn = () =>
  genericColumn(
    'date of birth',
    d =>
      `${moment(d.date_of_birth).format('MM/DD/YYYY')} (${moment().diff(
        d.date_of_birth,
        'years'
      )})`,
    { id: 'date_of_birth', disableSortBy: true }
  )

export const phoneColumn = () =>
  genericColumn(
    'phone',
    d => {
      const match = d.phone && d.phone.match(/^(\+1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) return `(${match[2]}) ${match[3]}-${match[4]}`
    },
    { id: 'phone', disableSortBy: true }
  )

export const timestampColumn = props =>
  genericColumn(
    props.label,
    d => {
      const date = moment(d[props.accessor])
      if (!date.isValid()) return ''
      else return date.format('MM/DD/YYYY h:mm a')
    },
    { id: props.accessor, disableSortBy: props.disableSortBy }
  )

export const timeSinceColumn = props =>
  genericColumn(
    props.label,
    d => {
      const date = moment(d[props.accessor])
      if (!date.isValid()) return ''
      else return `${date.fromNow()} (${date.format('MM/YY')})`
    },
    { id: props.accessor, disableSortBy: props.disableSortBy }
  )

// Future consideration: add some kind of priority system to determine which action to show
export const actionColumn = (allowedActions, actionFunctions) => ({
  Header: '',
  accessor: 'patient_id',
  disableSortBy: true,
  Cell({ row }) {
    const { original } = row
    if (
      allowedActions.includes(ClientListActions.VIEW_SCREENER_RESULTS) &&
      original.active_screener_assessment_score_id
    ) {
      return (
        <Cell onClick={e => e.stopPropagation()}>
          <NavLink
            to={{
              pathname: original.path,
              search: `?view_outcome_measure=${original.active_screener_assessment_score_id}`
            }}
          >
            <Text
              color="primary"
              fontSize="sm"
              display="block"
              textDecoration="underline"
              _hover={{
                cursor: 'pointer'
              }}
            >
              View Screener Results
            </Text>
          </NavLink>
        </Cell>
      )
    }

    if (
      allowedActions.includes(ClientListActions.REINVITE_TO_DOWNLOAD) &&
      (original.email || original.phone)
    ) {
      return (
        <Cell>
          <SecondaryButton
            grayText
            small
            onClick={e => {
              e.stopPropagation()
              actionFunctions.sendInvite(original.patient_id)
            }}
          >
            Resend Invite
          </SecondaryButton>
        </Cell>
      )
    }

    // more conditionals needed? this is also technically the same thing as `clientPageLink`
    if (allowedActions.includes(ClientListActions.FINISH_ENROLLING)) {
      return (
        <Cell onClick={e => e.stopPropagation()}>
          <NavLink
            to={{
              pathname: `/pending-client/${original.patient_id}`
            }}
          >
            <Text
              color="primary"
              fontSize="16px"
              display="block"
              textDecoration="underline"
              _hover={{
                cursor: 'pointer'
              }}
            >
              Finish enrolling
            </Text>
          </NavLink>
        </Cell>
      )
    }

    return <Cell></Cell>
  },
  width: 135
  //show: !isMobileOnly
})

// rename?
export const clientPageLinkColumn = onRowClick => ({
  Header: '',
  accessor: 'path',
  Cell(props) {
    return (
      <Cell
        tabIndex="0"
        role="button"
        onKeyDown={e => {
          const key = e.which
          if (key === 13 || key === 32) onRowClick(props)
        }}
      >
        <ArrowRightIcon />
      </Cell>
    )
  },
  width: 40,
  disableSortBy: true
})

// rename?
export const activeClientIconColumns = () => [
  {
    Header: '',
    accessor: 'is_high_risk',
    Cell(props) {
      return (
        // Use new icon?
        props.value && (
          <Cell>
            <Popover
              region={<Warning fill="#DB1544" width="18px" height="18px" />}
              popoverText="This client has been marked as High-Risk"
            />
          </Cell>
        )
      )
    },
    width: 24
  },
  {
    Header: '',
    accessor: 'has_overdue_assessments',
    Cell(props) {
      return (
        props.value && (
          <Cell>
            <Popover
              region={<UpdateIcon fill={'#EF8552'} />}
              popoverText={
                'This client has at least one overdue outcome measure'
              }
            />
          </Cell>
        )
      )
    },
    width: 24
  }
]
