import React, { useMemo } from 'react'
import { Box, Stack, Text, VStack, HStack, Divider } from '@chakra-ui/react'
import BlurSection from '../blur-section'
import { usePatientControllerGetCheckInScores } from '~/clinician-api'
import { DateTime } from 'luxon'
import { ArrowRightIcon, Button } from '@blueprinthq/joy'
import { useStoreActions } from 'easy-peasy'

const WorksheetSection = ({ patient, sendInvite }) => {
  const { data } = usePatientControllerGetCheckInScores(patient.id, {
    query: {
      initialData: {
        scores: [],
        versions: []
      }
    }
  })

  const openWorksheetResultModal = useStoreActions(
    // @ts-ignore
    actions => actions.modals.worksheetScores.openModal
  )

  const scoresWithCheckIn = useMemo(() => {
    return data.scores.map(score => ({
      ...score,
      checkIn: data.versions.find(v => v.versionId === score.versionId)
    }))
  }, [data])

  const scoresInLastWeek = useMemo(() => {
    const weekAgoDate = DateTime.local().minus({ days: 7 })

    return data.scores.filter(s => DateTime.fromISO(s.createdAt) > weekAgoDate)
  }, [data.scores])

  return (
    <Box
      id="worksheets-section"
      className={`summary-section`}
      sx={{
        borderRadius: '8px',
        border: '1px solid #E4E5E6',
        padding: '24px 32px 24px 32px',
        maxHeight: '392px',
        height: '392px',
        overflow: 'auto'
      }}
    >
      <Stack mb={'16px'} flex="1">
        <Stack
          justify={{
            base: 'start',
            md: 'space-between'
          }}
          direction={{
            base: 'row',
            md: 'row'
          }}
        >
          <Text textStyle="lg" fontWeight="bold">
            Worksheets
          </Text>
          <Box>
            {patient.hasApp && (
              <Button
                variant="link"
                textDecoration="none"
                onClick={() =>
                  openWorksheetResultModal({
                    patientId: patient.id
                  })
                }
              >
                View All
              </Button>
            )}
          </Box>
        </Stack>
        <Stack
          direction={{
            base: 'row',
            md: 'row'
          }}
        >
          <Box w={'100%'}>
            <Text fontSize={'16px'} fontWeight={'430'}>
              Completed worksheets.
            </Text>
          </Box>
        </Stack>
      </Stack>

      {patient.hasApp ? (
        <HStack justify="space-between" alignItems="flex-start">
          <Box w="30%">
            <Box>
              <Text fontSize="x-large" color="primary" fontWeight="bold">
                {scoresInLastWeek.length}
              </Text>
              <Text>In the last week</Text>
            </Box>
            <Box mt="small">
              <Text fontSize="x-large" color="primary" fontWeight="bold">
                {data.scores.length}
              </Text>
              All Time
            </Box>
          </Box>
          <VStack
            flex={1}
            alignItems="flex-start"
            divider={
              <Divider
                color="pale_gray"
                mt="0px !important"
                mb="0px !important"
              />
            }
            borderLeft="1px solid"
            borderColor="pale_gray"
          >
            {scoresWithCheckIn.slice(0, 5).map(s => (
              <HStack
                key={s.id}
                p="xsmall"
                alignItems="center"
                justify="space-between"
                w="100%"
                _hover={{
                  bg: 'gray.200',
                  cursor: 'pointer'
                }}
                onClick={() =>
                  openWorksheetResultModal({
                    patientId: patient.id,
                    preselectedCheckInScoreId: s.id
                  })
                }
              >
                <Box>
                  <Text fontSize="small" fontWeight="bold">
                    {s.checkIn.title}
                  </Text>
                  <Text fontSize="x-small" color="gray">
                    Completed {DateTime.fromISO(s.createdAt).toRelative()}
                  </Text>
                </Box>
                <Box>
                  <ArrowRightIcon />
                </Box>
              </HStack>
            ))}
          </VStack>
        </HStack>
      ) : (
        <BlurSection
          sectionName="worksheets"
          action={sendInvite}
          patient={patient}
        />
      )}
    </Box>
  )
}

export default WorksheetSection
