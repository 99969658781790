import React from 'react'
import { HStack, Text, Button } from '@chakra-ui/react'
import { useStoreActions } from 'easy-peasy'
import { usePermissions, useExperienceManager } from '@hooks'

export const PaywallBanner = ({ paywallData, user }) => {
  const { hasPermission } = usePermissions()
  const { isDocumentationAutomationEnabled } = useExperienceManager()
  const {
    hasPayingSubscription,
    clientCount,
    isTrialing,
    daysRemainingInTrial,
    trialType,
    canceledEBCSubscription
  } = paywallData

  const openPlansModal = useStoreActions(
    actions => actions.modals.choosePlan.openModal
  )

  // Business logic for when to show this banner or not -- don't show if there is an activeSubscription or we don't yet have data back from the endpoint
  if (
    Object.keys(paywallData).length === 0 ||
    (hasPayingSubscription && !isTrialing) ||
    (!isDocumentationAutomationEnabled && !isTrialing)
  ) {
    return null
  }

  const isFinancial = hasPermission('*:org:billing:*')

  return (
    <HStack p="xsmall" w="100%" justify="center" spacing="4px" bg="#2341B2">
      <Text fontSize="14px" as="b" color="white">
        {isTrialing
          ? `${daysRemainingInTrial} days remaining on your free trial.`
          : clientCount >= 5
          ? `You've reached the 5 client limit on the Starter plan.`
          : `You have ${5 - clientCount} client enrollment${
              5 - clientCount > 1 ? 's' : ''
            } remaining on the Starter plan.`}
      </Text>
      {canceledEBCSubscription ? null : isFinancial ? (
        <Button
          onClick={() => {
            openPlansModal()
          }}
          variant="link"
          color="white"
          fontSize="14px"
          style={{
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingBottom: '2px',
            paddingTop: '2px'
          }}
        >
          {trialType === 'gatedTrial' ? `Change or cancel plan` : `Upgrade`}
        </Button>
      ) : clientCount >= 5 ? (
        <Text fontSize="14px" color="white">
          Please contact your account admin to upgrade your account.
        </Text>
      ) : null}
    </HStack>
  )
}
