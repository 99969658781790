import { action } from 'easy-peasy'

export const enrollModel = {
  enroll: {
    responseData: {},
    setSubmittedViewState: action((state, { responseData }) => {
      state.responseData = responseData
    })
  }
}
