import {
  ClientAgeFilter,
  CLIENT_AGE_FILTER_OPERATORS
} from '../client-age-filter'
import { FilterCatalogMap } from './types'

export const ALL_WORKFLOW_FILTERS: FilterCatalogMap = {
  clientAge: {
    title: 'Client Age',
    component: ClientAgeFilter,
    constants: {
      operators: CLIENT_AGE_FILTER_OPERATORS
    }
  }
}
