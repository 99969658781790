import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const StepNumber = ({ number }: { number: number | string }) => (
  <Box
    w="32px"
    h="32px"
    borderRadius="50%"
    bg="gray.100"
    display="flex"
    justifyContent="center"
    alignItems="center"
    color="primary"
  >
    <Text fontWeight="bold">{number}</Text>
  </Box>
)

export default StepNumber
