import React, { useState, useEffect, useMemo, useContext } from 'react'
import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
  Text,
  Box,
  DrawerCloseButton,
  VStack,
  HStack,
  Flex
} from '@chakra-ui/react'
import { ActionDrawer } from './action-drawer'
import { drawerCatalog, drawerData } from '../drawer-catalog'
import { NodeType } from '../node-catalog'
import { WorkflowsContext } from '@context'
import { FormattedNode } from '../format-nodes'
import { TextField } from '@blueprinthq/joy'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'

type DrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
}

export const EditActionDrawer = ({ isOpen, closeDrawer }: DrawerProps) => {
  const [selectedAction, setSelectedAction]: [
    NodeType | undefined,
    Function
  ] = useState<NodeType | undefined>()
  const [DrawerComponent, setDrawerComponent] = useState<React.FC | undefined>()
  const { selectedNode, setSelectedNode } = useContext(WorkflowsContext)

  useEffect(() => {
    setSelectedAction(selectedNode?.type)
  }, [selectedNode])

  useEffect(() => {
    const DrawerComponent = drawerCatalog.actions[selectedAction as NodeType]
    // @ts-ignore
    setDrawerComponent(() => DrawerComponent)
    if (selectedAction) {
      setSelectedNode({
        ...(selectedNode as FormattedNode),
        type: selectedAction
      })
    }
  }, [selectedAction])

  return (
    <ChakraDrawer isOpen={isOpen} onClose={closeDrawer} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <Box
          height="80px"
          backgroundColor="primary"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl="small"
          pr="small"
        >
          <VStack spacing={1} alignItems="flex-start">
            <Text color="white">
              {selectedAction && drawerData[selectedAction].name}
            </Text>
            <Text color="white" fontSize="small">
              {selectedAction && drawerData[selectedAction].description}
            </Text>
          </VStack>
          <DrawerCloseButton color="white" position="static" />
        </Box>
        <Box p="small" overflowY="scroll" height="100%">
          {DrawerComponent ? (
            <ActionDrawer closeDrawer={closeDrawer}>
              <DrawerComponent />
            </ActionDrawer>
          ) : (
            <ActionList setSelectedAction={setSelectedAction} />
          )}
        </Box>
      </DrawerContent>
    </ChakraDrawer>
  )
}

const ActionList = ({ setSelectedAction }: { setSelectedAction: Function }) => {
  const [searchInput, setSearchInput] = useState('')
  const showAppointmentWorkflows: Boolean = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_APPOINTMENT_WORKFLOWS
  )

  const searchResults = useMemo(() => {
    let allActions = Object.keys(drawerCatalog.actions)

    if (!showAppointmentWorkflows) {
      allActions = allActions.filter(
        action =>
          action !== 'enroll-ehr-client' &&
          action !== 'send-appointment-reminder' &&
          action !== 'unarchive-ehr-client'
      )
    }

    return allActions.filter(action =>
      drawerData[action].name.toLowerCase().includes(searchInput.toLowerCase())
    )
  }, [searchInput])

  const clientResults = useMemo(
    () =>
      searchResults.filter(
        (action: any) => drawerData[action].userType === 'client'
      ),
    [searchResults]
  )

  const clinicianResults = useMemo(
    () =>
      searchResults.filter(
        (action: any) => drawerData[action].userType === 'clinician'
      ),
    [searchResults]
  )

  return (
    <VStack spacing="xsmall" mb="small" alignItems="flex-start">
      <TextField
        label=""
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        placeholder="Search Actions"
      />
      {clientResults.length && (
        <>
          <Box py="xsmall">
            <Text as="b" color="gray" mb="xsmall">
              Client
            </Text>
          </Box>
          {clientResults.map((drawerType: string) => {
            return (
              <ActionBox
                key={drawerType}
                drawerType={drawerType}
                setSelectedAction={setSelectedAction}
              />
            )
          })}
        </>
      )}
      {clinicianResults.length && (
        <>
          <Box py="xsmall">
            <Text as="b" color="gray">
              Clinician
            </Text>
          </Box>
          {clinicianResults.map((drawerType: string) => {
            return (
              <ActionBox
                key={drawerType}
                drawerType={drawerType}
                setSelectedAction={setSelectedAction}
              />
            )
          })}
        </>
      )}
    </VStack>
  )
}

const ActionBox = ({ drawerType, setSelectedAction }: any) => {
  const Icon: any = drawerData[drawerType].icon
  return (
    <HStack
      w="100%"
      h="48px"
      borderRadius="4px"
      borderWidth={1}
      borderStyle="solid"
      borderColor="light_gray"
      _hover={{ cursor: 'pointer', backgroundColor: 'light_gray' }}
      key={drawerType}
      onClick={() => setSelectedAction(drawerType)}
    >
      <Flex w="48px" h="100%" bg="primary" align="center" justify="center">
        <Icon fill="white" />
      </Flex>
      <Text>{drawerData[drawerType].name}</Text>
    </HStack>
  )
}
