import React from 'react'

const ArrowUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.06 10.6666L8 7.61329L4.94 10.6666L4 9.72663L8 5.72663L12 9.72663L11.06 10.6666Z"
      fill="#282828"
    />
  </svg>
)

export default ArrowUp
