import { Center } from '@chakra-ui/react'
import React from 'react'

export const EmptyState = () => (
  <Center
    border="1px solid"
    borderColor="pale_gray"
    borderRadius="8px"
    p="large"
  >
    No sessions yet
  </Center>
)
