import React from 'react'
import { Container, Flex, Text, Box } from '@chakra-ui/react'
import moment from 'moment'
import { useTreatmentPlan } from '@hooks'
import Actions from './actions'

const ListItem = ({ title, items }: { title: string, items: any}) => (
  <Flex flexDirection="column">
    <Text>
      <b>{title}: </b>
    </Text>
    <Box h="16px" />
    {items.length ? (
      items.map((item: any, index: number) => (
        <Text key={index}>{item?.label}</Text>
      ))
    ) : (
      <Text>No {title.toLowerCase()} found</Text>
    )}
    <br />
  </Flex>
)

const ReadOnly = () => {
  const { 
    initialSelectedDiagnoses,
    initialSelectedFocuses,
    treatmentPlan,
    client
  } = useTreatmentPlan()

  const { first_name = '', last_name = '', date_of_birth } = client
  const { updatedAt, rawText } = treatmentPlan

  return (
    <Flex flexDirection="column">
      <Container h="calc(100vh - 306px)" overflowY="scroll">
        <Flex flexDirection="column" py="16px">
          <Text>
            <b>Name: </b>
            {`${first_name} ${last_name}`}
          </Text>
          <Text>
            <b>DOB: </b>
            {date_of_birth ? moment(date_of_birth).format('M/D/YYYY') : 'N/A'}
          </Text>
          <Text>
            <b>Last Updated: </b>
            {updatedAt ? moment(updatedAt).format('M/D/YYYY') : 'N/A'}
          </Text>
          <br />
          <ListItem title="Diagnosis" items={initialSelectedDiagnoses} />
          <ListItem title="Focus of Treatment" items={initialSelectedFocuses} />
          <Text fontWeight="bold">Treatment Plan:</Text>
          <Text py="16px" whiteSpace="pre-line">{rawText || 'No treatment plan available'}</Text>
        </Flex>
      </Container>
      <Actions />
    </Flex>
  )
}

export default ReadOnly