import { COLORS } from '../constants'

export const TIME_PERIODS = {
  BASELINE: 'baseline',
  WEEK_4: 'week_4',
  WEEK_8: 'week_8',
  WEEK_12: 'week_12',
  WEEK_16: 'week_16',
  WEEK_20: 'week_20',
  OVER_WEEK_20: 'over_week_20',
  DISCHARGE: 'discharge'
}

export const X_AXIS_LABEL_MAP = {
  [TIME_PERIODS.BASELINE]: 'Baseline',
  [TIME_PERIODS.WEEK_4]: '4 Weeks',
  [TIME_PERIODS.WEEK_8]: '8 Weeks',
  [TIME_PERIODS.WEEK_12]: '12 Weeks',
  [TIME_PERIODS.WEEK_16]: '16 Weeks',
  [TIME_PERIODS.WEEK_20]: '20 Weeks',
  [TIME_PERIODS.OVER_WEEK_20]: '>20 Weeks',
  [TIME_PERIODS.DISCHARGE]: 'Discharge'
}

export const getEffectSizeProps = value => {
  if (value >= 0.8) {
    return {
      label: 'Highly Positive',
      color: COLORS.GREEN
    }
  } else if (value >= 0.5) {
    return {
      label: 'Moderately Positive',
      color: COLORS.GREEN
    }
  } else if (value >= 0.2) {
    return {
      label: 'Mildly Positive',
      color: COLORS.GREEN
    }
  } else if (value >= -0.1) {
    return {
      label: 'None',
      color: COLORS.GRAY
    }
  } else if (value >= -0.4) {
    return {
      label: 'Mildly Negative',
      color: COLORS.RED
    }
  } else if (value >= -0.7) {
    return {
      label: 'Moderately Negative',
      color: COLORS.RED
    }
  } else {
    return {
      label: 'Highly Negative',
      color: COLORS.RED
    }
  }
}
