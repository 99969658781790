import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { SelectInput } from './select-input'
import { endpoints } from '@api'

type ClinicSelectorProps = {
  onChange: (clinicId: string) => void
  organizationId: string
  clinicId: string
  showSelectAll?: boolean
}

const ClinicSelector: React.FC<ClinicSelectorProps> = ({
  onChange,
  organizationId,
  clinicId,
  showSelectAll
}) => {
  const { data, isLoading } = useQuery(
    [endpoints.getOrganizationClinicsV2.getCacheId(), organizationId],
    () => endpoints.getOrganizationClinicsV2.request({ organizationId }),
    {
      enabled: !!organizationId,
      placeholderData: [],
      refetchOnWindowFocus: false,
      onSuccess: (data: any[]) => {
        if (data.length) {
          onChange(data[0].id)
        }
      }
    }
  )

  const handleChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(evt.target.value)
  }

  let options = useMemo(
    () =>
      (data as any[]).map((c: any) => ({
        value: c.id,
        label: c.name
      })),
    [data]
  )

  if (showSelectAll) {
    const selectAllOption = { value: 'all', label: 'All' }
    options = [selectAllOption, ...options]
  }

  return (
    <SelectInput
      options={options}
      value={clinicId}
      isLoading={isLoading}
      onChange={handleChange}
    />
  )
}

export default ClinicSelector
