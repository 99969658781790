import React from 'react'
import WhiteLogo from '../../images/brand/white_logo.svg'
import { Flex, Box } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const PublicNavBar = ({ focusMode }) => {
  return (
    <div style={{ height: '80px' }}>
      <Box
        w="100%"
        bg={'primary'}
        position="fixed"
        top="0"
        right="0"
        left="0"
        zIndex={5}
        h={'80px'}
      >
        <Flex alignItems="center" justifyContent="space-between" h="100%">
          <Flex flex={1} justifyContent="center" alignItems="center" h="100%">
            <Link to={'/'}>
              <img
                alt="logo"
                src={WhiteLogo}
                style={{
                  height: '100%',
                  maxHeight: '50px',
                  objectFit: 'contain'
                }}
              />
            </Link>
          </Flex>
        </Flex>
      </Box>
      <Box h={'60px'} />
    </div>
  )
}
