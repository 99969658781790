import React, { useCallback } from 'react'
import { Box, Text, Flex, Link } from '@chakra-ui/react'
import { Checkbox } from '@blueprinthq/joy'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { useMutation, useQueryClient } from 'react-query'
import _ from 'lodash'
import { useStoreActions } from 'easy-peasy'

import { TableTemplate } from '@components'
import { endpoints } from '@api'

export function HomeworkSection({ homeworkModules, client, interventionId }) {
  const queryClient = useQueryClient()
  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const { mutateAsync: executeAssignCheckinModule } = useMutation(
    endpoints.postClinicianUserCheckinModulesAssign.request,
    {
      onMutate: async ({ data }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([
          endpoints.getInterventionOverview.getCacheId(),
          {
            clientId: client.id,
            interventionId
          }
        ])

        // Snapshot the previous value
        const previousInterventionOverview = queryClient.getQueryData([
          endpoints.getInterventionOverview.getCacheId(),
          {
            clientId: client.id,
            interventionId
          }
        ])

        const updatedHomeworkList = previousInterventionOverview.homework.map(
          h => {
            if (data.module_id === h.id) {
              h.isAssigned = data.new_value
            }
            return h
          }
        )
        const copy = _.cloneDeep(previousInterventionOverview)
        copy.homework = updatedHomeworkList
        const nextInterventionOverview = copy

        // Optimistically update to the new value
        queryClient.setQueryData(
          [
            endpoints.getInterventionOverview.getCacheId(),
            {
              clientId: client.id,
              interventionId
            }
          ],
          nextInterventionOverview
        )

        // Return a context with the previous values
        return { previousInterventionOverview }
      },
      onError: (err, data, context) => {
        setSnackbarMessage({
          variant: 'error',
          message: 'There was an error editing modules',
          position: 'bottom-left'
        })
        queryClient.setQueryData(
          [
            endpoints.getInterventionOverview.getCacheId(),
            {
              clientId: client.id,
              interventionId
            }
          ],
          context.previousInterventionOverview
        )
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          endpoints.getInterventionOverview.getCacheId(),
          {
            clientId: client.id,
            interventionId
          }
        ])
        setSnackbarMessage({
          variant: 'success',
          message: 'Saved!',
          position: 'bottom-left'
        })
      }
    }
  )

  const handleClick = useCallback(async (e, h) => {
    if (e.target.innerHTML === 'Preview') return
    e.preventDefault()
    const input = e.target.closest('tr').querySelector('input')
    const data = {
      new_value: !input.checked,
      module_id: h.id
    }
    await executeAssignCheckinModule({
      data,
      clientId: client.id
    })
  })

  return (
    <Box id="pendo-intervention-homework-section-tag">
      <Text my="xsmall" fontWeight="bold">
        Homework
      </Text>
      <Text>
        {`After completing this intervention, we recommend assigning the following check-in${
          homeworkModules.length > 1 ? 's' : ''
        } as homework for ${client.first_name}:`}
      </Text>
      <TableTemplate
        content={homeworkModules.map(h => (
          <TableRow
            key={h.id}
            onClick={e => handleClick(e, h)}
            style={{ cursor: 'pointer' }}
          >
            <TableCell>
              <Flex direction="column" w="100%" h="100%">
                <Text fontWeight="bold" mb="xxsmall">
                  {h.title}
                </Text>
                <Text>{h.subtitle}</Text>
              </Flex>
            </TableCell>
            <TableCell>
              <Text whiteSpace="nowrap" fontWeight="bold">
                {`${h.numQuestions} questions`}
              </Text>
            </TableCell>
            <TableCell style={{ minWidth: '115px' }}>
              <Flex w="100%" justify="space-between">
                <Link
                  id={`pendotag-int-${interventionId}-hw-preview-BEFORE-${h.id}`}
                  as="a"
                  color="primary"
                  textDecoration="underline"
                  target="_blank"
                  href={h.previewURL}
                >
                  Preview
                </Link>
                <Checkbox
                  id={`pendotag-int-${interventionId}-hw-assign-BEFORE-${h.id}`}
                  isChecked={h.isAssigned}
                  onChange={() => {}}
                />
              </Flex>
            </TableCell>
          </TableRow>
        ))}
      />
    </Box>
  )
}
