import React, { useMemo, useState, useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useBreakpointValue,
  Text,
  Box,
  ModalBody,
  HStack,
  VStack
} from '@chakra-ui/react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useQuery } from 'react-query'
import _ from 'lodash'
import { Select } from '@blueprinthq/joy'
import moment from 'moment'

import { endpoints } from '@api'
import {
  formatAssessmentAnswers,
  formatScoreToInterpretation
} from '@utilities'
import { Loading } from '@components'
import {
  AssessmentResultSubScores,
  AssessmentResultTable,
  AssessmentTriggerBlurb,
  ContentErrorBoundary
} from './components'

export const AssessmentResultModal = ({
  clientId,
  isOpen,
  handleCloseModal,
  preselectedAssessmentId = null,
  preselectedAssessmentScoreId = null,
  assessmentScoreIds = null
}) => {
  const { data: assessmentScores, isLoading } = useQuery(
    [
      endpoints.getPatientAssessmentScores.getCacheId(),
      clientId,
      assessmentScoreIds
    ],
    () =>
      endpoints.getPatientAssessmentScores.request({
        patientId: clientId,
        ids: assessmentScoreIds,
        includeFreeTextQuestions: true
      }),
    {
      enabled: clientId !== null,
      initialData: []
    }
  )

  const modalSize = useBreakpointValue({
    base: 'full',
    md: '6xl'
  })

  return (
    <Modal
      onClose={handleCloseModal}
      size={modalSize}
      isOpen={isOpen}
      preserveScrollBarGap
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton color="white" mt="xsmall" />
        <ModalHeader
          bg="primary"
          color="white"
          fontSize="lg"
          borderTopRadius="md"
        >
          Assessment Results
        </ModalHeader>
        <ModalBody>
          {isLoading || !assessmentScores.length ? (
            <Loading />
          ) : (
            <ContentErrorBoundary>
              <AssessmentResultModalContent
                handleCloseModal={handleCloseModal}
                clientId={clientId}
                assessmentScores={assessmentScores}
                preselectedAssessmentId={preselectedAssessmentId}
                preselectedAssessmentScoreId={preselectedAssessmentScoreId}
              />
            </ContentErrorBoundary>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const AssessmentResultModalContent = ({
  clientId,
  handleCloseModal,
  assessmentScores,
  preselectedAssessmentId,
  preselectedAssessmentScoreId
}) => {
  const [state, setState] = useState(() => {
    if (assessmentScores.length) {
      const [firstAssessment] = assessmentScores
      const selectedAssessmentId =
        preselectedAssessmentId || firstAssessment.assessment_id
      const selectedAssessment = assessmentScores.find(
        as => as.assessment_id === selectedAssessmentId
      )
      const selectedScoreId =
        preselectedAssessmentScoreId || selectedAssessment.scores.at(-1).id
      const selectedScore = selectedAssessment.scores.find(
        score => score.id === selectedScoreId
      )

      return {
        selectedAssessmentId,
        selectedAssigneeUserId:
          selectedScore &&
          selectedScore.assigneeUser &&
          selectedScore.assigneeUser.id,
        selectedAssessmentScoreId: selectedScoreId
      }
    }

    return {
      selectedAssessmentId: null,
      selectedAssigneeUserId: null,
      selectedAssessmentScoreId: null
    }
  })

  const selectedAssessment = useMemo(() => {
    return assessmentScores.find(
      as => as.assessment_id === state.selectedAssessmentId
    )
  }, [assessmentScores, state.selectedAssessmentId])

  const selectedAssessmentScores = useMemo(() => {
    const scores = _.sortBy(selectedAssessment.scores, 'created_at').reverse()
    return scores
  }, [selectedAssessment, state.selectedAssigneeUserId])

  const selectedAssessmentAssigneeScores = useMemo(() => {
    return selectedAssessmentScores.filter(
      score => score.assigneeUser.id === state.selectedAssigneeUserId
    )
  }, [selectedAssessmentScores])

  const selectedAssessmentScore = useMemo(() => {
    const score = selectedAssessmentScores.find(
      as => as.id === state.selectedAssessmentScoreId
    )

    return score
  }, [selectedAssessmentScores, state.selectedAssessmentScoreId])

  const assigneeUsers = useMemo(() => {
    const uniqUsers = _.uniqBy(
      selectedAssessmentScores.map(score => score.assigneeUser),
      'id'
    )

    return uniqUsers.map(user => ({
      userId: user.id,
      name: `${user.info.firstName} ${user.info.lastName}`
    }))
  }, [selectedAssessmentScores])

  const assessmentOptions = useMemo(() => {
    return assessmentScores.map(as => ({
      assessmentId: as.assessment_id,
      name: as.name
    }))
  }, [assessmentScores])

  const assessmentDateOptions = useMemo(() => {
    return selectedAssessmentAssigneeScores.map(as => ({
      assessmentScoreId: as.id,
      date: moment(as.created_at).format('ddd MM/DD/YY h:mm a')
    }))
  }, [selectedAssessmentAssigneeScores])

  const formattedAssessmentScoreAnswers = useMemo(() => {
    return formatAssessmentAnswers(
      selectedAssessmentScore.answers,
      selectedAssessment
    )
  }, [selectedAssessmentScore, selectedAssessment])

  const onSelectAssessment = useCallback(
    assessmentId => {
      const selectedAssessment = assessmentScores.find(
        as => as.assessment_id === assessmentId
      )
      const assigneeUserId = selectedAssessment.scores[0].assigneeUser.id
      setState({
        selectedAssessmentId: selectedAssessment.assessment_id,
        selectedAssigneeUserId: assigneeUserId,
        selectedAssessmentScoreId: selectedAssessment.scores
          .filter(s => s.assigneeUser.id === assigneeUserId)
          .at(-1).id
      })
    },
    [assessmentScores]
  )

  const onSelectAssignee = useCallback(
    assigneeUserId => {
      const selectedAssessmentScore = selectedAssessmentScores.find(
        as => as.assigneeUser.id === assigneeUserId
      )
      setState(state => ({
        ...state,
        selectedAssigneeUserId: assigneeUserId,
        selectedAssessmentScoreId: selectedAssessmentScore.id
      }))
    },
    [selectedAssessmentScores]
  )

  const onSelectScore = useCallback(assessmentScoreId => {
    setState(state => ({
      ...state,
      selectedAssessmentScoreId: assessmentScoreId
    }))
  }, [])

  const optionsOrientation = useBreakpointValue({
    base: 'vertical',
    md: 'horizontal'
  })

  const optionsWidth = optionsOrientation === 'vertical' ? '100%' : 'inherit'

  const options = (
    <>
      <Box w={optionsWidth}>
        <Select
          options={assessmentOptions}
          valueKey="assessmentId"
          labelKey="name"
          label="Assessment"
          value={assessmentOptions.find(
            ao => ao.assessmentId === state.selectedAssessmentId
          )}
          onChange={option => onSelectAssessment(option.assessmentId)}
        />
      </Box>
      <Box w={optionsWidth}>
        <Select
          options={assigneeUsers}
          valueKey="userId"
          labelKey="name"
          label="Assignee"
          value={assigneeUsers.find(
            au => au.userId === state.selectedAssigneeUserId
          )}
          onChange={option => onSelectAssignee(option.userId)}
        />
      </Box>
      <Box w={optionsWidth}>
        <Select
          options={assessmentDateOptions}
          valueKey="assessmentScoreId"
          labelKey="date"
          label="Date"
          value={assessmentDateOptions.find(
            ad => ad.assessmentScoreId === state.selectedAssessmentScoreId
          )}
          onChange={option => onSelectScore(option.assessmentScoreId)}
        />
      </Box>
    </>
  )

  return (
    <Box mb="medium">
      {optionsOrientation === 'horizontal' ? (
        <HStack spacing="small" mt="small">
          {options}
        </HStack>
      ) : (
        <VStack spacing="small" mt="small" alignItems="self-start">
          {options}
        </VStack>
      )}
      <Box mt="medium">
        <Box>
          <Text fontSize="lg">
            <Text fontWeight="bold" as="span" fontSize="lg">
              {selectedAssessment.disorder}:
            </Text>
            {` ${formatScoreToInterpretation(
              selectedAssessment,
              selectedAssessmentScore.total_score
            )} `}
            ({selectedAssessmentScore.total_score} out of{' '}
            {selectedAssessment.max_score})
          </Text>
        </Box>
        <Box mt="medium">
          <AssessmentTriggerBlurb
            assessmentScore={selectedAssessmentScore}
            assessmentContent={selectedAssessment.content}
          />
          <AssessmentResultSubScores
            handleCloseModal={handleCloseModal}
            clientId={clientId}
            assessmentScore={selectedAssessmentScore}
          />
          <Box
            mt="medium"
            overflowX={{
              base: 'auto',
              md: 'initial'
            }}
          >
            <AssessmentResultTable
              answers={formattedAssessmentScoreAnswers}
              isReverseValence={selectedAssessment.is_reverse_valence}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export const AssessmentResultModalConnected = () => {
  const {
    isOpen,
    clientId,
    preselectedAssessmentId,
    preselectedAssessmentScoreId,
    assessmentScoreIds
  } = useStoreState(state => state.modals.assessmentResult)

  const handleCloseModal = useStoreActions(
    actions => actions.modals.assessmentResult.closeModal
  )

  return (
    <AssessmentResultModal
      isOpen={isOpen}
      clientId={clientId}
      assessmentScoreIds={assessmentScoreIds}
      preselectedAssessmentId={preselectedAssessmentId}
      preselectedAssessmentScoreId={preselectedAssessmentScoreId}
      handleCloseModal={handleCloseModal}
    />
  )
}
