import { Formik, Field, Form, useField } from 'formik'
import { Heading, Box } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { SelectWithOptout } from '../../../components/forms/select-with-optout'
import * as Yup from 'yup'
import { useExperienceManager } from '@hooks'

const AdditionalInfoSchema = Yup.object().shape({
  gender: Yup.array()
    .of(Yup.string())
    .optional(),
  is_hispanic_or_latino: Yup.string()
    .optional()
    .nullable(),
  race: Yup.array()
    .of(Yup.string())
    .optional(),
  treatment_approach: Yup.array()
    .of(Yup.string())
    .optional(),
  readiness_measurement_based_care: Yup.string()
    .optional()
    .nullable()
})

export default ({ account, onSubmit, fieldOptions }) => {
  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  if (!isEvidenceBasedCareEnabled) {
    return null
  }

  return (
    <Box
      mt="10"
      padding={5}
      backgroundColor="rgba(45, 84, 232, 0.04)"
      boxShadow="xs"
    >
      <Heading size="lg">Help us improve our platform</Heading>
      <Box mb="medium">
        We will never share or sell your personal information.
      </Box>
      <Formik
        initialValues={{
          gender: account.gender || [],
          is_hispanic_or_latino: account.is_hispanic_or_latino,
          race: account.race || [],
          treatment_approach: account.treatment_approach || [],
          readiness_measurement_based_care:
            account.readiness_measurement_based_care
        }}
        enableReinitialize
        validationSchema={AdditionalInfoSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <SelectWithOptout
              options={fieldOptions['gender_categories']}
              name="gender"
              labelText="What is your gender?"
              placeholder="Select all that apply"
              multiple
              optOutValue="rather_not_say"
            />
            <SelectWithOptout
              options={fieldOptions['is_hispanic_or_latino']}
              name="is_hispanic_or_latino"
              labelText="Are you Hispanic, Latino, or of Spanish origin?"
              placeholder="Choose one"
            />
            <SelectWithOptout
              options={fieldOptions['race_categories']}
              name="race"
              labelText="How would you describe yourself?"
              placeholder="Select all that apply"
              multiple={true}
              optOutValue="rather_not_say"
            />
            <SelectWithOptout
              options={fieldOptions['clinician_treatment_approaches']}
              name="treatment_approach"
              labelText="Which best describes your treatment approach?"
              placeholder="Select all that apply"
              multiple={true}
            />
            <SelectWithOptout
              options={fieldOptions['readiness_measurement_based_care']}
              name="readiness_measurement_based_care"
              labelText="Which best describes your readiness to adopt Measurement-Based Care?"
              placeholder="Choose one"
            />
            <Button
              size="lg"
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!isValid || !dirty}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
