import { action } from 'easy-peasy'

export const chimeModel = {
  volume: {
    audioLevel: 0,
    setAudioLevel: action((state, audioLevel) => {
      state.audioLevel = audioLevel
    })
  }
}
