import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { Program } from '../drawers/actions/assign-program'

type Programs = {
  programs: Program[]
}

type QueryResult = {
  data: Programs | undefined
  isLoading: boolean
}

export const useOrganizationPrograms = (organizationId: string): QueryResult =>
  useQuery(
    [endpoints.getOrganizationPrograms.getCacheId(), organizationId],
    () =>
      // @ts-ignore
      endpoints.getOrganizationPrograms.request({ organizationId })
  )
