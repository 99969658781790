import React from 'react'
import {
  Flex,
  Text,
  Box,
  Avatar,
  Button,
  useBreakpointValue
} from '@chakra-ui/react'

import { ArrowRightIcon } from '@blueprinthq/joy'
import { formatDuration } from '@handlers/sessions/util'
import { Link } from 'react-router-dom'
import moment from 'moment'

export const Session = ({
  patientId,
  sessionId,
  fullName,
  startedAt,
  completedAt,
  isUpload,
  clinicianName,
  inProgress,
  isDictation,
  sx = {}
}) => {
  const buttonSize = useBreakpointValue({
    base: 'sm',
    sm: 'md'
  })
  return (
    <Box
      display="flex"
      w="100%"
      background="transparent"
      cursor="pointer"
      as={Link}
      p="12px 16px"
      _hover={{ bg: '#F9F9F9' }}
      to={
        inProgress
          ? isDictation
            ? `/patient/${patientId}/dictate/session/${sessionId}`
            : `/patient/${patientId}/session/${sessionId}`
          : `/patient/${patientId}/completed-session/${sessionId}`
      }
    >
      <Flex py={1} w="100%" alignItems="center">
        <Box _hover={{ cursor: 'pointer' }}>
          <Avatar
            name={fullName}
            sx={{
              marginRight: 'small',
              background: '#F9F9F9',
              color: 'black',
              fontWeight: 'bold'
            }}
          />
        </Box>
        <Flex grow="1" wrap="wrap" justifyContent="space-between">
          <Flex
            w="100%"
            sx={{
              paddingBottom: 'xsmall'
            }}
          >
            <Box>
              <Text
                sx={{
                  fontSize: 'md',
                  fontWeight: 'bold',
                  display: 'inline',
                  wordBreak: 'break-word'
                }}
              >
                {fullName}
              </Text>
              <Text fontSize="xsm">
                <Flex flexDirection="row">
                  <Text as="span" fontSize="inherit">
                    {clinicianName}
                  </Text>
                  <Text
                    as="span"
                    fontSize="inherit"
                    mx="10px"
                    color="light_gray"
                  >
                    |
                  </Text>
                  {!isUpload && (
                    <>
                      <Text as="span" fontSize="inherit">
                        {moment(startedAt).format('h:mma')}
                      </Text>
                      <Text
                        as="span"
                        fontSize="inherit"
                        mx="10px"
                        color="light_gray"
                      >
                        |
                      </Text>
                      <Text as="span" fontSize="inherit">
                        {formatDuration(
                          startedAt,
                          completedAt || moment.utc().format()
                        )}
                      </Text>
                    </>
                  )}
                </Flex>
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Box>
          {inProgress ? (
            <Button size={buttonSize} m={0}>
              {isDictation ? 'Resume Dictation' : 'Resume Session'}
            </Button>
          ) : (
            <ArrowRightIcon />
          )}
        </Box>
      </Flex>
    </Box>
  )
}
