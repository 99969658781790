import { Button, Text, VStack } from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import React from 'react'

export const FreeTierSessionLimitReached = ({ onUpgrade }: { onUpgrade: () => void }) => {
  const { isExtension, isWidget } = useExperienceManager()

  const handleUpgradeClick = () => {
    if (isExtension || isWidget) {
      window.open(
        `${process.env.REACT_APP_CLINICIAN_APP_URL}/sessions?upgrade=true`,
        '_blank'
      )

      return
    }

    onUpgrade()
  }

  return (
    <VStack
      justifyContent="center"
      minWidth="320px"
      maxWidth="420px"
      gap="13px"
      alignItems="center"
      textAlign="center"
    >
      <Text fontSize="32px" color="#282828">
        Ready to Go Further?
      </Text>
      <Text fontSize="16px" color="#282828">
        You've reached your 5 free notes for this month.<br></br>
        Skip the limits and upgrade now.
      </Text>
      <Button
        bg="primary"
        fontWeight="430"
        height="48px"
        width="100%"
        color="white"
        variant="body"
        _hover={{ bg: 'primary' }}
        onClick={handleUpgradeClick}
      >
        Upgrade
      </Button>
    </VStack>
  )
}