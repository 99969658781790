import React, { useEffect } from 'react'
import { Dialog } from '@material-ui/core'
import {
  Box,
  Text,
  Stack,
  VStack,
  UnorderedList,
  ListItem,
  Container,
  Button,
  IconButton,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { Select } from '@blueprinthq/joy'
import { ArrowLeft } from '@components/icons'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import * as clinicianTracking from '@lib/clinician-tracking'
import { standardErrorDescription } from '../../constants/Copy'

const CancelIndividualPlanModal = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const {
    isOpen,
    planType,
    planName,
    subscriptionId,
    clinicId
  } = useStoreState((state: any) => state.modals.cancelIndividualPlan)
  const { closeModal } = useStoreActions(
    (actions: any) => actions.modals.cancelIndividualPlan
  )

  useEffect(() => {
    if (isOpen) {
      clinicianTracking.trackEvent(`Viewed Cancel ${planType} Plan Modal`)
    }
  }, [isOpen])

  const { data: cancellationReasons }: any = useQuery(
    [endpoints.getAccountDeletionReasons.getCacheId()],
    endpoints.getAccountDeletionReasons.request
  )

  const { mutateAsync: cancelPlan } = useMutation(
    endpoints.patchIndividualBillingPlan.request,
    {
      onSuccess() {
        closeModal()
        toast({
          title: `Your ${
            planType === 'scribe'
              ? 'Documentation Automation'
              : 'Evidence-Based Care'
          } plan has been cancelled.`,
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        )
        queryClient.invalidateQueries(endpoints.getClinicBilling.getCacheId())
      },
      onError(err: any) {
        toast({
          title: `We ran into an issue canceling your ${
            planType === 'scribe'
              ? 'Documentation Automation'
              : 'Evidence-Based Care'
          } plan.`,
          description: standardErrorDescription(),
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  const handleSubmit = async (values: any) => {
    clinicianTracking.trackEvent(
      `Cancel ${planType.toUpperCase()} Dialog -> Clicked confirm cancel`
    )
    try {
      const data = {
        action: 'cancel',
        clinicId,
        subscriptionId,
        planType,
        planName,
        cancellationReason: values.cancellationReason,
        cancellationFeedback: values.cancellationFeedback
      }

      await cancelPlan({ data })
    } catch (err) {
      console.log(err)
    }
  }

  const getPlanSpecificContent = (planType: string) => {
    switch (planType) {
      case 'scribe':
        return (
          <>
            <Text as="b">
              When you cancel your Documentation Automation plan...
            </Text>
            <UnorderedList>
              <ListItem>
                You will have until the end of the current billing cycle to use
                your remaining notes. At that time, all clinicians will lose
                access to the AI notetaker tool.
              </ListItem>
              <ListItem>
                You will no longer be billed for Documentation Automation
                (unless you have outstanding/overdue invoices).
              </ListItem>
            </UnorderedList>
          </>
        )
      default:
        return (
          <>
            <Text as="b">When you cancel your Evidence-Based Care plan...</Text>
            <UnorderedList>
              <ListItem>
                You will have until the end of the current billing cycle to
                continue using the Evidence-Based Care tools. At that time, all
                Assessments, Worksheets, Symptom Trackers, and Interventions
                will be unassigned from all clients and all clinicians will lose
                access to these tools.
              </ListItem>
              <ListItem>
                You will no longer be billed for Evidence-Based Care (unless you
                have outstanding/overdue invoices).
              </ListItem>
            </UnorderedList>
          </>
        )
    }
  }

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth={true}>
      <Box padding="24px">
        <Stack w="100%" spacing="16px">
          <Box position="relative">
            <Box position="absolute" top="0px" height="40px" width="40px">
              <IconButton
                variant="ghost"
                aria-label="Close"
                style={{ margin: 0 }}
                icon={<ArrowLeft />}
                onClick={closeModal}
              />
            </Box>
            <Text textAlign="center" fontSize="24px" fontWeight="bold">
              {`Cancel ${
                planType === 'scribe'
                  ? 'Documentation Automation'
                  : 'Evidence-Based Care'
              }`}
            </Text>
          </Box>
          <Container
            maxWidth="45rem"
            overflowY="clip"
            height="100%"
            centerContent
            px={{
              base: 'small'
            }}
          >
            <Box w="100%">
              <Box mb="medium">{getPlanSpecificContent(planType)}</Box>
              <Formik
                initialValues={{
                  cancellationReason: null
                }}
                onSubmit={async (values: any) => {
                  await handleSubmit(values)
                }}
                validationSchema={Yup.object().shape({
                  cancellationReason: Yup.object()
                    .shape({
                      id: Yup.string().required(
                        'Cancellation Reason is required'
                      )
                    })
                    .required('Cancellation Reason is required')
                })}
              >
                {({ errors, touched, isSubmitting }: any) => (
                  <Form style={{ width: '100%' }}>
                    <VStack align="left" spacing="medium">
                      <VStack align="left" spacing="small" w="100%">
                        <Text as="b">Why are you canceling this plan?</Text>
                        <Field name="cancellationReason">
                          {({ field, form }: any) => (
                            <Select
                              matchWidth
                              {...field}
                              onChange={(value: any) =>
                                form.setFieldValue('cancellationReason', value)
                              }
                              options={cancellationReasons.filter(
                                (r: {
                                  id: string
                                  display: string
                                  type: string
                                }) => ['any', planType].includes(r.type)
                              )}
                              valueKey="id"
                              labelKey="display"
                              label="Select a reason"
                              isRequired
                              isInvalid={
                                errors.cancellationReason &&
                                touched.cancellationReason
                              }
                              errorText={'Cancellation reason is required'}
                            />
                          )}
                        </Field>
                      </VStack>
                      <VStack align="left" spacing="small" w="100%">
                        <Text as="b">
                          Do you have any other feedback for our team on how we
                          can improve Blueprint?
                        </Text>
                        <Field name="cancellationFeedback">
                          {({ form }: any) => (
                            <Textarea
                              placeholder="Leave feedback..."
                              _placeholder={{ color: 'medium_gray' }}
                              borderColor="light_gray"
                              _focus={{
                                outline: 'none'
                              }}
                              onChange={(e: any) =>
                                form.setFieldValue(
                                  'cancellationFeedback',
                                  e.target.value
                                )
                              }
                            ></Textarea>
                          )}
                        </Field>
                      </VStack>
                      <Button
                        bg="#EB5164"
                        size="lg"
                        isFullWidth
                        isLoading={isSubmitting}
                        isDisabled={isSubmitting}
                        type="submit"
                      >
                        Confirm Cancellation
                      </Button>
                    </VStack>
                  </Form>
                )}
              </Formik>
            </Box>
          </Container>
        </Stack>
      </Box>
    </Dialog>
  )
}

export const CancelIndividualPlanModalConnected = () => {
  const user = useStoreState((state: any) => state.auth.user)

  return user ? <CancelIndividualPlanModal /> : null
}
