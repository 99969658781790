import React from 'react'

export const ExpandIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9998 9.58579L14.4141 11L18.0708 7.34326V10H20.0708V6V4H18.0708H14.0708L14.0708 6L16.5856 6L12.9998 9.58579ZM11.071 14.4142L9.65674 13L6 16.6567V14H4V18V20H6H10V18H7.48517L11.071 14.4142Z"
      fill="white"
    />
  </svg>
)
