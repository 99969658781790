import React from 'react'
import { HStack, Text } from '@chakra-ui/react'
import Pill from './pill'

type RiskLevelPanelProps = {
  value: boolean | undefined
  onClick: (value: boolean) => void
}

const RiskLevelPanel: React.FC<RiskLevelPanelProps> = ({ value, onClick }) => {
  return (
    <HStack spacing="0" flex="1" gap="8px">
      <Pill activeValue={true} value={value} onClick={onClick}>
        <Text fontWeight="bold">Is</Text>
        <Text>&nbsp;</Text>
        <Text>high risk</Text>
      </Pill>
      <Pill activeValue={false} value={value} onClick={onClick}>
        <Text fontWeight="bold">Is not</Text>
        <Text>&nbsp;</Text>
        <Text>high risk</Text>
      </Pill>
    </HStack>
  )
}

export default RiskLevelPanel
