import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { RadioButtonLoadingComplete, WarningFilled } from '@components/icons'
import { FlagsmithFeatures } from '@constants/flagsmith'
import flagsmith from 'flagsmith'
import { isEmpty } from 'lodash'

const AuditQuestions = [
  { title: 'Symptoms', key: 'clinical_symptoms' },
  { title: 'Interventions', key: 'clinical_interventions' },
  { title: 'Intervention Response', key: 'client_response_to_interventions'},
  { title: 'Impairment', key: 'functional_impairment'},
  { title: 'Goal Relevance', key: 'goal_relevance'},
  { title: 'Goal Progress', key: 'progress_towards_goal'},
  { title: 'Risk Addressed', key: 'risk_addressed'},
  { title: 'Treatment Goal', key: 'treatment_goal'},
]

const AuditReport = ({ audit }: any) => {
  const isAuditReportEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.AUDIT_REPORT
  )

  if (!isAuditReportEnabled || isEmpty(audit)) {
    return null
  }

  return (
    <Flex
      flexDirection="column"
      border="1px solid"
      borderColor="pale_gray"
      borderRadius="8px"
      p="large"
      gap="16px"
    >
      <Flex>
        <Text fontWeight="bold">Compliance</Text>
      </Flex>
      {AuditQuestions.map(config => (
        <Flex key={config.key} gap="8px">
          <Flex>
            {audit?.[config.key]?.answer === 'yes' ? (
              <RadioButtonLoadingComplete fill="#18E09C" />
            ) : (
              <WarningFilled />
            )}
          </Flex>
          <Flex>
            <Text>
              <b>{config.title}:</b>&nbsp;
              {audit?.[config.key]?.question}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

export default AuditReport
