/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type AssignCheckInDtoDaysOfWeekItem = typeof AssignCheckInDtoDaysOfWeekItem[keyof typeof AssignCheckInDtoDaysOfWeekItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignCheckInDtoDaysOfWeekItem = {
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun',
} as const;
