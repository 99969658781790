// @ts-nocheck

import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
  ReactNode
} from 'react'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { endpoints } from '@api'
import { useStoreActions } from 'easy-peasy'
import {
  buildAssessmentTakeNowUrl,
  buildAssessmentPreviewUrl,
  openLinkInNewTab
} from '@utilities'

interface ContextPropsT {
  onSelect: (id: string) => void
  selectedAssessments: string[]
  setSelectedAssessments: React.Dispatch<React.SetStateAction<string[]>>
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  assigneeUser: any
  setAssigneeUser: React.Dispatch<React.SetStateAction<any>>
  checkedItems: Record<string, any[]>
  setCheckedItems: React.Dispatch<React.SetStateAction<Record<string, any[]>>>
  singleCheckedItems: Record<string, any[]>
  setSingleCheckedItems: React.Dispatch<
    React.SetStateAction<Record<string, any[]>>
  >
  isAllChecked: Record<string, boolean>
  setIsAllChecked: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  unassignLoading: boolean
  setUnassignLoading: React.Dispatch<React.SetStateAction<boolean>>
  assessmentsQuery: any
  sendPatientAssessmentLinkMutation: any
  executeUnassignAssessmentsMutation: any
  executeUpdateAssessmentCadenceMutation: any
  assignClientAssessmentsMutation: any
  filteredAssessments: any[]
  countOfAssessmentsToAssign: number
  isClientEnrolled: boolean,
  assignableAssessments: any
}

const AssessmentContext = createContext<ContextPropsT | undefined>(undefined)

export const AssessmentProvider = ({ children, client }: { children: ReactNode, client: any }) => {
  const [selectedAssessments, setSelectedAssessments] = useState<string[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [assigneeUser, setAssigneeUser] = useState<any>(null)
  const [checkedItems, setCheckedItems] = useState<Record<string, any[]>>({})
  const [singleCheckedItems, setSingleCheckedItems] = useState<
    Record<string, any[]>
  >({})
  const [isAllChecked, setIsAllChecked] = useState<Record<string, boolean>>({})
  const [unassignLoading, setUnassignLoading] = useState<boolean>(false)

  const toast = useToast()
  const queryClient = useQueryClient()
  const user_id = client.id

  const assessmentsQuery = useQuery(
    [endpoints.getAllAssessmentsForClientByAssignee.getCacheId(), user_id],
    () =>
      endpoints.getAllAssessmentsForClientByAssignee.request({ id: user_id }),
    {
      initialData: []
    }
  )

  const clientAssessmentsGroupedByAssignee = assessmentsQuery?.data || []

  const assignableAssessments = useMemo(
    () =>
      clientAssessmentsGroupedByAssignee.map(aa => {
        return {
          assigneeUserId: aa.assigneeUserId,
          assessments: aa.assessments
        }
      }),
    [clientAssessmentsGroupedByAssignee]
  )

  const sendPatientAssessmentLinkMutation = useMutation(
    endpoints.postSendPatientAssessmentLink.request
  )

  const executeUnassignAssessmentsMutation = useMutation(
    endpoints.deleteClientUnassignAssessments.request,
    {
      onMutate: async ({ data }) => {
        await queryClient.cancelQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])

        const previousAssessmentListByAssignee = queryClient.getQueryData([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])
        const previousActiveAssessmentList = queryClient.getQueryData([
          endpoints.getClinicianUserActiveAssessments.getCacheId(),
          user_id
        ])

        const nextAssessmentList = previousAssessmentListByAssignee
          .find(paba => paba.assigneeUserId === data.assigneeUserId)
          .assessments.map(assessment => {
            if (data.clinicAssessmentIds.includes(assessment.id)) {
              assessment.isActive = false
            }
            return assessment
          })

        queryClient.setQueryData(
          [
            endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
            user_id
          ],
          previousAssessmentListByAssignee.map(paba => {
            if (paba.assigneeUserId === data.assigneeUserId) {
              return {
                assigneeUserId: data.assigneeUserId,
                assessments: nextAssessmentList
              }
            }
            return paba
          })
        )
        queryClient.setQueryData(
          [endpoints.getClinicianUserActiveAssessments.getCacheId(), user_id],
          () =>
            clientAssessmentsGroupedByAssignee
              .flatMap(aba => aba.assessments)
              .filter(assessment => assessment.isActive === true)
        )

        return {
          previousAssessmentListByAssignee,
          previousActiveAssessmentList
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
          user_id
        ])
      },
      onError: (err, newlyInactive, context) => {
        toast({
          title: 'Error',
          description: 'There was an error unassigning this measure',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
        queryClient.setQueryData(
          [
            endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
            user_id
          ],
          context.previousAssessmentListByAssignee
        )
        queryClient.setQueryData(
          [endpoints.getClinicianUserActiveAssessments.getCacheId(), user_id],
          context.previousActiveAssessmentList
        )
      }
    }
  )

  const executeUpdateAssessmentCadenceMutation = useMutation(
    endpoints.patchClinicianUserAssessmentsV2.request,
    {
      onMutate: async ({ assessment_id, data }) => {
        await queryClient.cancelQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])

        const previousAssessmentListByAssignee = queryClient.getQueryData([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])

        const nextAssessmentList = previousAssessmentListByAssignee
          .find(paba => paba.assigneeUserId === data.assigneeUserId)
          .assessments.map(a => {
            if (a.id === assessment_id) {
              a.cadence_value = data.cadence_value
              a.cadence_unit = data.cadence_unit
            }
            return a
          })

        queryClient.setQueryData(
          [
            endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
            user_id
          ],
          previousAssessmentListByAssignee.map(paba => {
            if (paba.assigneeUserId === data.assigneeUserId) {
              return {
                assigneeUserId: data.assigneeUserId,
                assessments: nextAssessmentList
              }
            }
            return paba
          })
        )

        return { previousAssessmentListByAssignee }
      },
      onSuccess: async () => {
        toast({
          title: 'Saved!',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        await queryClient.invalidateQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])
        await queryClient.invalidateQueries([
          endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
          user_id
        ])
        await queryClient.invalidateQueries([
          endpoints.getClinicianUserActiveAssessments.getCacheId(),
          user_id
        ])
      },
      onError: async (err, newCadence, context) => {
        toast({
          title: 'Error',
          description: 'There was an error updating the cadence',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
        await queryClient.setQueryData(
          [
            endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
            user_id
          ],
          context.previousAssessmentListByAssignee
        )
      }
    }
  )

  const assignClientAssessmentsMutation = useMutation(
    async () => {
      const assignments = selectedAssessments.map(id => ({
        clinicAssessmentId: id,
        cadence: null,
        assigneeUserId
      }))

      await endpoints.postClientAssignAssessments.request({
        clientId: user_id,
        data: {
          assignments
        }
      })
    },
    {
      onSuccess: () => {
        toast({
          title: 'Assessment assigned',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        queryClient.invalidateQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          user_id
        ])
        queryClient.invalidateQueries([
          endpoints.getClinicianUserActiveAssessments.getCacheId(),
          user_id
        ])
        queryClient.invalidateQueries([
          endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
          user_id
        ])
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'There was an error assigning this measure',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  const handleSendPatientAssessmentLink = useCallback(
    (assigneeUser, patientId, clinicAssessmentIds) => {
      sendPatientAssessmentLinkMutation.mutate(
        {
          patient_id: patientId.id,
          is_reminder: false,
          assigneeUserId: assigneeUser,
          clinicAssessmentIds
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
              user_id
            ])

            queryClient.invalidateQueries([
              endpoints.getClinicianUserAccount.getCacheId(),
              patientId.id
            ])

            toast({
              title: 'Assessments sent',
              status: 'success',
              isClosable: true,
              duration: 2000,
              position: 'bottom'
            })
          },
          onError: () => {
            toast({
              title: 'Error',
              description: `Unable to send assessments.`,
              status: 'error',
              isClosable: true,
              duration: 2000,
              position: 'bottom'
            })
          }
        }
      )
    },
    [queryClient, sendPatientAssessmentLinkMutation, toast, user_id]
  )

  const assigneeUserId = clientAssessmentsGroupedByAssignee[0]?.assigneeUserId

  const onUnassignAssessment = useCallback(
    async (measure, assigneeUserId) => {
      const activeAssessments = clientAssessmentsGroupedByAssignee
        .flatMap(aba => aba.assessments)
        .filter(assessment => assessment.isActive === true)

      const checkMeasure = clientAssessmentsGroupedByAssignee
        .flatMap(aba => aba.assessments)
        .filter(assessment => assessment.assessment_id === measure)

      await executeUnassignAssessments({
        data: {
          clinicAssessmentIds: [checkMeasure[0].id],
          assigneeUserId
        },
        clientId: user_id
      })
    },
    [user_id, clientAssessmentsGroupedByAssignee]
  )

  const onUpdateAssessment = useCallback(
    async (assessmentId, { unit, value, assigneeUserId }) => {
      await executeUpdateAssessmentCadence({
        id: user_id,
        assessment_id: assessmentId,
        data: {
          cadence_unit: unit,
          cadence_value: value,
          assigneeUserId
        }
      })
    },
    [user_id, clientAssessmentsGroupedByAssignee]
  )

  const allAssessments = assignableAssessments?.[0]?.assessments?.map(a => ({
    measureId: a.id,
    clinicAssessmentId: a.id,
    assessmentId: a.assessment_id,
    cadenceValue: a.cadence_value,
    cadenceUnit: a.cadence_unit,
    title: `${a.full_name} ${a.name !== a.full_name ? '(' + a.name + ')' : ''}`,
    subtitle: a.disorder,
    numQuestions: a.num_questions,
    previewUrl: buildAssessmentPreviewUrl(a.assessment_id),
    takeNowUrl: buildAssessmentTakeNowUrl(
      clientAssessmentsGroupedByAssignee[0].assigneeUserId,
      a.assessment_id
    ),
    isAssigned:
      a.isActive &&
      a.assigneeUser.id === clientAssessmentsGroupedByAssignee[0].assigneeUserId
  })) || []

  const filteredAssessments = useMemo(() => {
    return allAssessments
      .map(m => ({
        ...m,
        isSelected: selectedAssessments.includes(m.measureId)
      }))
      .filter(m =>
        `${m.title.toLowerCase()} ${m.subtitle.toLowerCase()}`.includes(
          searchText.toLowerCase()
        )
      )
  }, [searchText, allAssessments])

  const onSelect = useCallback(
    id => {
      const updatedSelectedAssessments = selectedAssessments.includes(id)
        ? selectedAssessments.filter(m => m !== id) // deselect
        : [...selectedAssessments, id] // select
      setSelectedAssessments(updatedSelectedAssessments)
    },
    [selectedAssessments]
  )

  const countOfAssessmentsToAssign = selectedAssessments.length

  const value = {
    onSelect,
    selectedAssessments,
    setSelectedAssessments,
    searchText,
    setSearchText,
    assessmentsQuery,
    sendPatientAssessmentLinkMutation,
    executeUnassignAssessmentsMutation,
    executeUpdateAssessmentCadenceMutation,
    assignClientAssessmentsMutation,
    handleSendPatientAssessmentLink,
    filteredAssessments,
    countOfAssessmentsToAssign,
    isClientEnrolled: client.enrollment_timestamp !== null,
    assignableAssessments: assignableAssessments[0]?.assessments || []
  }

  return (
    <AssessmentContext.Provider value={value}>
      {children}
    </AssessmentContext.Provider>
  )
}

export const useAssessmentLibraryContext = () => {
  const context = useContext(AssessmentContext)
  if (!context) {
    throw new Error('useAssessment must be used within an AssessmentProvider')
  }
  return context
}
