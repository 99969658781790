import React from 'react'

const Bell = props => {
  return (
    <svg
      height={props.height || '22px'}
      width={props.width || '24px'}
      version="1.1"
      viewBox="0 0 512 512"
    >
      <g
        id="Bell"
        stroke="grey"
        strokeWidth="15"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="bell"
          fill={props.fill || 'grey'}
          fillRule="nonzero"
          transform="translate(-1280.000000, -182.000000)"
        >
          <g id="Group" transform="translate(952.000000, 53.000000)">
            <g id="Group-2" transform="translate(314.000000, 112.000000)">
              <path
                d="M381.7,225.9c0-97.6-52.5-130.8-101.6-138.2c0-0.5,0.1-1,0.1-1.6c0-12.3-10.9-22.1-24.2-22.1c-13.3,0-23.8,9.8-23.8,22.1   c0,0.6,0,1.1,0.1,1.6c-49.2,7.5-102,40.8-102,138.4c0,113.8-28.3,126-66.3,158h384C410.2,352,381.7,339.7,381.7,225.9z M107.2,368   c8.6-9,16.4-18.4,22.7-31.8c12-25.3,17.4-59.2,17.4-110.2c0-46.4,12.5-80.4,37.1-101.2c22.9-19.3,51.7-23.3,71.8-23.3   c20.1,0,48.9,4,71.6,23.3c24.5,20.7,37,54.5,37,100.9c0,83.8,14.9,117.3,40.3,142.3H107.2z"
                id="Bell-Shape"
              />
              <path
                d="M256.2,448c26.8,0,48.8-19.9,51.7-43H204.5C207.3,428.1,229.4,448,256.2,448z"
                id="Ringer-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Bell
