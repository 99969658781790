import React from 'react'

import { SupportResource } from '../safety-net-editor'

import {
  Box,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react'

import {
  CallFilledIcon,
  TextFilledIcon,
  ArrowRightIcon,
  LinkIcon
} from '@blueprinthq/joy'

interface SafetynetPreviewModalProps {
  supportResources: SupportResource[] | undefined
  isOpen: boolean
  onClose: Function
}

const ResourceCard = ({ resource }: { resource: SupportResource }) => {
  const { displayText, type, value } = resource

  const handleResourceType = (type: string, value: any) => {
    switch (type.toLowerCase()) {
      case 'call':
        return {
          href: `tel:${value}`,
          image: <CallFilledIcon fill="white" />,
          displayDescription: (
            <>
              <Text as="span">{'Call: '}</Text>
              <Text as="span" textDecoration="underline">
                {value}
              </Text>
            </>
          )
        }
      case 'sms':
        return {
          href: `sms:${value}`,
          image: <TextFilledIcon fill="white" />,
          displayDescription: (
            <>
              <Text as="span">{'Text: '}</Text>
              <Text as="span" textDecoration="underline">
                {value}
              </Text>
            </>
          )
        }
      case 'web':
        const isSafetyPlan = displayText === 'Safety Plan'
        return {
          href: value,
          image: <LinkIcon fill="white" />,
          displayDescription: (
            <>
              <Text as="span">{'Web: '}</Text>
              <Text as="span" textDecoration="underline">
                {isSafetyPlan ? 'View' : value}
              </Text>
            </>
          )
        }
      default:
        return {}
    }
  }

  const { href, image, displayDescription } = handleResourceType(type, value)

  if (!href) return null

  return (
    <Link
      href={href}
      w="100%"
      _hover={{
        textDecoration: 'none'
      }}
    >
      <Flex w="100%" padding="small" alignItems="center">
        <Box
          p={2}
          bgColor="primary"
          borderRadius={8}
          mr="small"
          minWidth="40px"
          minHeight="40px"
        >
          {image}
        </Box>
        <Box width="100%">
          <Text fontWeight="bold">{displayText}</Text>
          <Text wordBreak="break-all">{displayDescription}</Text>
        </Box>
        <Box>
          <ArrowRightIcon size="md" />
        </Box>
      </Flex>
    </Link>
  )
}

const SafetyNetPrompt = ({
  resources
}: {
  resources: SupportResource[] | undefined
}) => {
  return (
    <Flex direction="column">
      <Box>
        <Text textAlign="left" fontWeight="bold" fontSize="lg">
          Your Safety Net
        </Text>
      </Box>
      <Box mt="xsmall" textAlign="left">
        <Text>
          If you are thinking about suicide or would like immediate emotional
          support, please use one of the crisis resources below.
        </Text>
      </Box>
      <VStack
        border="1px solid"
        borderColor="pale_gray"
        spacing="0"
        mt="medium"
        mb="medium"
        w="100%"
        borderRadius="8px"
        divider={
          <StackDivider
            borderBottom="1px solid"
            borderBottomColor="pale_gray"
          />
        }
      >
        {resources?.map((resource, i) => (
          <ResourceCard resource={resource} key={i} />
        ))}
      </VStack>
      <Box>
        <Box mb="small">
          If your issue is an emergency, call 911 or go to your nearest
          emergency room.
        </Box>
        <Box>
          Blueprint does not offer crisis counseling or emergency services and
          does not notify your clinician in real-time.
        </Box>
      </Box>
    </Flex>
  )
}

export const SafetyNetPreviewModal = ({
  supportResources,
  isOpen,
  onClose
}: SafetynetPreviewModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SafetyNetPrompt resources={supportResources} />
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}
