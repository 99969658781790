import React, { Component } from 'react'
import styles from './clinicianlist.module.css'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'

export default class ClinicianList extends Component {
  state = {
    anchorEl: null
  }

  handleClinicianOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClinicianClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClinicianClick = selectedClinicianId => {
    this.props.handleClinicianClick(selectedClinicianId)
    this.setState({ anchorEl: null })
  }

  getClinicianName = clinician => {
    return clinician.first_name + ' ' + clinician.last_name
  }

  render() {
    const { anchorEl } = this.state
    const { clinicians, selectedClinicianId } = this.props

    let name = this.props.user.name
    if (clinicians.length && selectedClinicianId) {
      const clinician = clinicians.find(
        clinician => clinician.id === selectedClinicianId
      ) || clinicians[0]

      name = this.getClinicianName(clinician)
    }

    return (
      <>
        <Button
          id="menu"
          classes={{
            root: styles.clinician_button
          }}
          variant="outlined"
          onClick={this.handleClinicianOpen}
        >
          <div className={styles.name_arrow}>
            <span>{name}</span>
            <ArrowIcon className={styles.arrow_icon} />
          </div>
        </Button>
        <Menu
          id="clinicians-list"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={this.handleClinicianClose}
        >
          {clinicians.map(clinician => (
            <MenuItem
              key={clinician.id}
              selected={this.state.clinicianId === clinician.id}
              onClick={() => this.handleClinicianClick(clinician.id)}
            >
              {this.getClinicianName(clinician)}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }
}
