import React, { useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import { Box, useBoolean, Text, Stack, Link } from '@chakra-ui/react'
import Grid from '@material-ui/core/Grid'

import { endpoints } from '../../../../../../api'
import SectionHeader from '../section-header'
import TimescaleSelector from '../timescale-selector'
import SectionModal from '../section-modal'
import SymptomsBlock from '../symptoms-block'
import BlurSection from '../blur-section'

export const TopSymptomsSection = ({
  user_id: clientId,
  sendInvite,
  patient,
  ...rest
}) => {
  const [isModalOpen, setModal] = useState(false)
  const [selectedTimeScale, setSelectedTimeScale] = useState('all')

  const { data } = useQuery(
    [endpoints.getClientSymptoms.getCacheId(), clientId],
    () => endpoints.getClientSymptoms.request({ clientId }),
    {
      initialData: []
    }
  )

  const handleTimescaleChange = ({ value, unit }) => {
    if (!unit) {
      setSelectedTimeScale('all')
    } else {
      setSelectedTimeScale(`${value}_${unit}`)
    }
  }

  const selectedTimeScaleEntry = useMemo(
    () => data.find(d => d.timeScale === selectedTimeScale),
    [data, selectedTimeScale]
  )

  if (data.length === 0) return null

  return (
    <>
      <Box
        borderRadius={'8px'}
        border={'1px solid #E4E5E6'}
        padding={'24px 32px 24px 32px'}
        w={'100%'}
        maxHeight={'392px'}
        height={'392px'}
        overflow={'auto'}
      >
        <Stack mb={'16px'} flex="1">
          <Stack
            justify={{
              base: 'start',
              md: 'space-between'
            }}
            direction={{
              base: 'row',
              md: 'row'
            }}
          >
            <Box w={{ base: '80%', md: '60%' }}>
              <Text textStyle="lg" fontWeight="bold">
                Top Symptoms
              </Text>
            </Box>
            <Box w={{ base: '20%', md: '40%' }}>
              {patient.hasApp && (
                <Link
                  textAlign={'end'}
                  onClick={() => setModal(!isModalOpen)}
                  color={'#2D54E8'}
                >
                  <Text fontSize={'16px'} fontWeight={'430'}>
                    View all
                  </Text>
                </Link>
              )}
            </Box>
          </Stack>
          <Stack
            direction={{
              base: 'row',
              md: 'row'
            }}
          >
            <Box w={'100%'}>
              <Text fontSize={'16px'} fontWeight={'430'}>
                Generated from check-ins.
              </Text>
            </Box>
          </Stack>
        </Stack>

        {patient.hasApp ? (
          <>
            <TimescaleSelector handleTimeScaleChange={handleTimescaleChange} />
            <Grid container>
              <Grid item xs={12}>
                <SymptomsBlock
                  variant={''}
                  chartType={'top_symptoms'}
                  symptom_distribution={selectedTimeScaleEntry.data.slice(0, 3)}
                  user_id={clientId}
                  {...rest}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <BlurSection
            sectionName="lifestyle"
            action={sendInvite}
            patient={patient}
          />
        )}
      </Box>
      <SectionModal
        isModalOpen={isModalOpen}
        content="symptoms"
        handleModal={() => setModal(!isModalOpen)}
        user_id={clientId}
        {...rest}
      />
    </>
  )
}
