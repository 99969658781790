import React, { Component } from 'react'
import config from '../../config'

export default class Logo extends Component {
  render() {
    const { color, variant, style = {} } = this.props
    if (config.logo) {
      return (
        <img
          className={this.props.className}
          style={config.logo.style}
          src={config.logo.url}
          alt="logo"
        />
      )
    }

    if (color === 'white') {
      if (variant === 'pdf') {
        return (
          <img
            className={this.props.className}
            style={{ maxWidth: '200px', ...style }}
            src="https://res.cloudinary.com/hellojoy/image/upload/v1553776897/White_XL_2x_hgf8de.png"
            alt="logo"
          />
        )
      } else {
        return (
          <img
            className={this.props.className}
            style={{ maxWidth: '343px', ...style }}
            src="https://res.cloudinary.com/hellojoy/image/upload/v1553776897/White_XL_2x_hgf8de.png"
            alt="logo"
          />
        )
      }
    } else {
      return (
        <img
          className={this.props.className}
          style={{ maxWidth: '343px', ...style }}
          src="https://res.cloudinary.com/hellojoy/image/upload/v1553000305/blueprint_fsmkn7.png"
          alt="logo"
        />
      )
    }
  }
}
