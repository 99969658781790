import React, { useContext } from 'react'
import { Text, Box, Select, Flex, CloseButton } from '@chakra-ui/react'
import { Formik, Form, Field, FieldArray } from 'formik'
import { useParams } from 'react-router-dom'
import { AddCircleIcon } from '@blueprinthq/joy'
import * as Yup from 'yup'

import { WorkflowsContext } from '@context'
import { EditDrawerFooter } from '../../edit-drawer/edit-drawer-footer'
import { FormattedNode } from '../../format-nodes'
import { Loading } from '@components'
import { useOrganizationPrograms } from '../../queries'

export type Program = {
  id: string
  name: string
}

type RouteParams = {
  workflowId: string
  organizationId: string
}

type Props = {
  onSubmit: (node: FormattedNode) => void
  closeDrawer: () => void
  onDelete: (node: FormattedNode | undefined) => void
}

const schema = Yup.object().shape({
  programIds: Yup.array()
    .of(Yup.string().test(str => Boolean(str && str !== '')))
    .min(1)
    .required()
})

export const AssignProgram = ({ onSubmit, closeDrawer, onDelete }: Props) => {
  const { selectedNode } = useContext(WorkflowsContext)
  const config = selectedNode?.config
  const { organizationId } = useParams<RouteParams>()

  const { data, isLoading } = useOrganizationPrograms(organizationId)

  const allPrograms = data?.programs

  if (isLoading) return <Loading />

  const initialValues = {
    programIds: config?.programs?.map((p: any) => p.programId) || ['']
  }

  const handleConfig = (programIds: string[]) => {
    return programIds.map(programId => {
      const program = allPrograms?.find(
        (program: Program) => program.id === programId
      )

      return {
        programId: program?.id
      }
    })
  }

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={values =>
          onSubmit({
            ...selectedNode,
            config: { programs: handleConfig(values.programIds) }
          } as FormattedNode)
        }
      >
        {({ values, errors }) => (
          <Form>
            <FieldArray
              name="programIds"
              render={({ push, remove, replace }) => (
                <Box>
                  {!Boolean(values.programIds.length) && (
                    <Field name="programIds.0">
                      {({ field }: { field: { value: string } }) => (
                        <Select
                          {...field}
                          value={field.value}
                          borderColor={
                            errors?.programIds ? 'error' : 'light_gray'
                          }
                          color={field.value ? 'black' : 'gray'}
                          mt="xsmall"
                        >
                          <option value="" disabled>
                            Choose Program
                          </option>
                          {allPrograms?.map((program: Program) => (
                            <option
                              key={program.id}
                              value={program.id}
                              disabled={values.programIds.includes(program.id)}
                            >
                              {program.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    </Field>
                  )}
                  {values?.programIds?.map(
                    (programId: string, index: number) => (
                      <Flex>
                        <Field
                          name={`programIds.${index}`}
                          key={`programIds.${index}`}
                        >
                          {({ field }: { field: { value: string } }) => (
                            <Select
                              {...field}
                              borderColor={
                                errors?.programIds && !programId
                                  ? 'error'
                                  : 'light_gray'
                              }
                              color={field.value ? 'black' : 'gray'}
                              mb="xsmall"
                            >
                              <option value="" disabled>
                                Choose Program
                              </option>
                              {allPrograms?.map((program: Program) => (
                                <option
                                  key={program.id}
                                  value={program.id}
                                  disabled={values.programIds.includes(
                                    program.id
                                  )}
                                >
                                  {program.name}
                                </option>
                              ))}
                            </Select>
                          )}
                        </Field>
                        {programId && (
                          <CloseButton
                            onClick={() => {
                              if (values.programIds.length === 1) {
                                replace(0, '')
                              } else {
                                remove(index)
                              }
                            }}
                          />
                        )}
                      </Flex>
                    )
                  )}
                  {Boolean(values?.programIds[0]) && (
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      _hover={{ cursor: 'pointer' }}
                      onClick={() => push('')}
                      mt="xsmall"
                    >
                      <AddCircleIcon fill="primary" mr="xsmall" />
                      <Text color="primary">Add Program</Text>
                    </Box>
                  )}
                </Box>
              )}
            />
            <EditDrawerFooter
              onDelete={() => onDelete(selectedNode)}
              onCancel={closeDrawer}
            />
          </Form>
        )}
      </Formik>
    </Box>
  )
}
