import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, makeStyles } from '@material-ui/core'
import { Check } from '../icons'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
  base: {
    minWidth: props => (props.fullWidth ? '100%' : 0),
    maxWidth: 335,
    // color: theme.palette.primary.contrastText,
    height: 50,

    '&:hover': {
      backgroundColor: theme.palette.primary.hover
    }
  },
  round: {
    borderRadius: 25
  },
  success: {
    backgroundColor: theme.palette.success.main,

    '&.Mui-disabled': {
      backgroundColor: theme.palette.success.main
    }
  },
  disabled: {
    backgroundColor: theme.palette.disabled.main
  },
  loading: {
    backgroundColor: theme.palette.primary.main,

    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))

export default function PrimaryButton(props) {
  const classes = useStyles(props)

  return (
    <Button
      aria-label={props.ariaLabel || ''}
      variant="contained"
      color="primary"
      onClick={props.onClick}
      className={cx(classes.base, props.className, {
        [classes.round]: props.round,
        [classes.success]: props.success,
        [classes.disabled]: props.disabled,
        [classes.loading]: props.loading
      })}
      disabled={props.disabled || props.loading || props.success}
      type={props.type ? props.type : 'button'}
    >
      {props.loading ? (
        <CircularProgress color="secondary" size={20} />
      ) : props.success ? (
        <Check fill="#ffffff" />
      ) : (
        props.children
      )}
    </Button>
  )
}
