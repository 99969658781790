import React, { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import { Container, Flex, GridItem } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'

import { endpoints } from '../../../api'
import { FiltersSidebar, OutcomesContent } from './components'
import { Loading } from '../../../components'

export function InsightsOutcomes() {
  const history = useHistory()
  const { filters, loaded: filtersLoaded } = useStoreState(
    state => state.insights
  )

  const outcomesParams = useMemo(() => {
    if (!filtersLoaded) return {}
    const outcomesSelection = filters.find(filter => filter.id === 'outcomes')
      .selected
    const clinicianSelection = filters.find(
      filter => filter.id === 'clinicians'
    ).selected
    const ageSelection = filters.find(filter => filter.id === 'ages').selected
    const clinicSelection = filters.find(filter => filter.id === 'clinics')
      .selected

    const selectedClinicIds = clinicSelection.map(option => option.value)
    const selectedClinicianIds = clinicianSelection.map(option => option.value)
    const hasCliniciansAllSelected = selectedClinicianIds.some(
      id => id === 'all'
    )
    const hasClinicsAllSelected = selectedClinicIds.some(id => id === 'all')

    const ageHasAllSelected = ageSelection.some(
      option => option.value === 'all'
    )
    const ageRangeSelection = ageHasAllSelected
      ? null
      : ageSelection.map(option => option.value)

    return {
      assessment_id: outcomesSelection.value,
      clinician_ids: hasCliniciansAllSelected ? null : selectedClinicianIds,
      age_range: ageRangeSelection,
      clinic_ids: hasClinicsAllSelected ? null : selectedClinicIds
    }
  }, [filters, filtersLoaded])

  const { data, refetch, isFetching, isPreviousData } = useQuery(
    [endpoints.getInsightsOutcomes.getCacheId(), outcomesParams],
    () => endpoints.getInsightsOutcomes.request(outcomesParams),
    {
      enabled: false,
      keepPreviousData: true,
      initialData: {
        patient_count: 0,
        assessment_count: 0,
        intervals: []
      }
    }
  )

  useEffect(() => {
    history.push('/insights/treatment-outcomes')
  }, [])

  useEffect(() => {
    // Refetch whenever filters change
    if (filtersLoaded && isPreviousData) {
      refetch()
    }
  }, [filters, isPreviousData, data])

  return (
    <Container
      sx={{
        marginTop: 'large',
        paddingBottom: 'xxlarge'
      }}
    >
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 1,
            sm: 2
          }}
        >
          <FiltersSidebar />
        </GridItem>
        <GridItem
          colSpan={{
            base: 3,
            sm: 6,
            md: 10
          }}
        >
          {!filtersLoaded ? (
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                height: '100%'
              }}
            >
              <Loading />
            </Flex>
          ) : (
            <OutcomesContent
              data={data}
              isLoading={data.intervals.length === 0 || isFetching}
            />
          )}
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
