import React, { useState } from 'react'
import {
  Text,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Box
} from '@chakra-ui/react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import { useQuery } from 'react-query'
import { useExperienceManager } from '@hooks'
import { endpoints } from '@api'
import { Group } from '@components/icons'
import { TrashcanIcon, Assist, AccountIcon, PeopleIcon } from '@blueprinthq/joy'
import { IconSelect } from './'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { NoteTypeDropdown } from './note-type-dropdown'
import { TreatmentApproachDropdown } from './treatment-approach-dropdown'

type ProgressNoteTypeT = {
  id: string
  sessionType: string
  display: string
  noteGroup: string
}

interface EndSessionModalProps {
  isOpen: boolean
  onClose: () => void
  noteType: string
  treatmentApproach: string | null
  onEnd: (args: any) => Promise<void>
  onDiscard: () => Promise<void>
  isEnding: boolean
  isUploadingAudio: boolean
}

const sessionTypes = [
  {
    value: 'individual',
    title: 'Individual',
    icon: <AccountIcon />
  },
  {
    value: 'couple',
    title: 'Couple',
    icon: <PeopleIcon />
  },
  {
    value: 'group',
    title: 'Group',
    icon: <Group />
  }
]

const EndSessionModalComponent = ({
  isOpen,
  onClose,
  noteType,
  treatmentApproach,
  onEnd,
  onDiscard,
  isEnding,
  isUploadingAudio
}: EndSessionModalProps) => {
  const [isDiscardSelected, setIsDiscardSelected] = useState<boolean>(false)

  const { data: user }: any = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const {
    data: { progressNoteTypes }
  }: any = useQuery(
    [
      endpoints.getProgressNoteTypes.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteTypes.request({
        organizationId: user.clinic.organization_id
      }),
    {
      initialData: {
        progressNoteTypes: []
      },
      enabled: !!user
    }
  )

  const { data, isLoading } = useQuery(
    [
      endpoints.getProgressNoteSettings.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteSettings.request({
        organizationId: user?.clinic?.organization_id
      }),
    {
      enabled: !!user?.clinic?.organization_id
    }
  )

  const handleEndSession = async (
    noteType: string,
    treatmentApproach: string | null
  ) => {
    await onEnd({
      noteType,
      treatmentApproach: treatmentApproach === 'none' ? null : treatmentApproach
    })
  }

  const handleDeleteSession = async () => {
    await onDiscard()
  }

  const handleClose = () => {
    setIsDiscardSelected(false)
    onClose()
  }

  const noteTypeOptions = progressNoteTypes.map((note: ProgressNoteTypeT) => {
    const id = note.id
    const sessionType = note.sessionType
    const display = note.display
    const noteGroup = note.noteGroup
    return {
      id,
      sessionType,
      display,
      noteGroup
    }
  })

  const treatmentApproachOptions =
    // @ts-ignore
    data?.preferenceOptions?.treatmentApproach?.options?.map((option: any) => {
      return {
        id: option.value,
        display: option.label
      }
    }) || []

  const groupedNoteTypes = _.groupBy(noteTypeOptions, 'sessionType')

  const sessionTypeOptions = sessionTypes.filter(t =>
    Object.keys(groupedNoteTypes).includes(t.value)
  )

  const defaultNoteType = noteTypeOptions.find(
    (t: ProgressNoteTypeT) =>
      t.id === noteType ??
      t.id === user?.clinic?.organization?.default_note_type
  )

  const defaultTreatmentApproach = treatmentApproachOptions.find((o: any) =>
    treatmentApproach ? o.id === treatmentApproach : o.id === 'none'
  )

  const { isDocumentationAutomationEnabled } = useExperienceManager()

  const DiscardContent = () => (
    <VStack spacing={6}>
      <Text textAlign="center">
        This session will be discarded and no progress note summary will be
        generated. This cannot be undone.
      </Text>
      <VStack w="100%">
        <Button
          bg="error"
          isFullWidth
          size="lg"
          onClick={handleDeleteSession}
          isLoading={isEnding}
        >
          Delete Session
        </Button>
        <Button
          _focus={{ outline: 'none' }}
          variant="outline"
          isFullWidth
          size="lg"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </VStack>
    </VStack>
  )

  if (!defaultNoteType || isLoading) return null
  return (
    <DialogContainer isOpen={isOpen} onClose={handleClose}>
      <DialogHeader
        text={isDiscardSelected ? 'Are you sure?' : 'End Session?'}
        onClose={handleClose}
      />
      <DialogBody>
        <Box m="0 auto" maxWidth="750px">
          {isDiscardSelected ? (
            <DiscardContent />
          ) : (
            <Formik
              initialValues={{
                noteType: defaultNoteType,
                sessionType: defaultNoteType.sessionType,
                treatmentApproach: defaultTreatmentApproach
              }}
              validationSchema={Yup.object({
                noteType: Yup.object().required(
                  'Progress note type is required'
                )
              })}
              onSubmit={async values =>
                await handleEndSession(
                  values.noteType.id,
                  values.treatmentApproach.id
                )
              }
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <VStack spacing={6} w="100%">
                    {isDocumentationAutomationEnabled && (
                      <>
                        <Field name="sessionType">
                          {({ field }: any) => (
                            <FormControl>
                              <FormLabel fontWeight="bold">
                                Session Type:
                              </FormLabel>
                              <IconSelect
                                {...field}
                                onChange={(value: string) => {
                                  setFieldValue(field.name, value)
                                  setFieldValue(
                                    'noteType',
                                    groupedNoteTypes[value][0]
                                  )
                                }}
                                selectedValue={field.value}
                                options={sessionTypeOptions}
                              />
                            </FormControl>
                          )}
                        </Field>
                        <Field name="noteType">
                          {({ field }: any) => (
                            <FormControl>
                              <FormLabel fontWeight="bold">
                                Note Type:
                              </FormLabel>
                              <NoteTypeDropdown
                                optionValue={field.value.id}
                                onChange={value => {
                                  setFieldValue(
                                    'noteType',
                                    progressNoteTypes.find(
                                      (note: ProgressNoteTypeT) =>
                                        note.id === value
                                    )
                                  )
                                }}
                                progressNoteTypes={
                                  groupedNoteTypes[values.sessionType]
                                }
                              />
                            </FormControl>
                          )}
                        </Field>
                        <Field name="treatmentApproach">
                          {({ field }: any) => (
                            <FormControl>
                              <FormLabel fontWeight="bold">
                                Primary Treatment Approach:
                              </FormLabel>

                              <TreatmentApproachDropdown
                                optionValue={field.value.id}
                                onChange={value => {
                                  const selectedOption = treatmentApproachOptions.find(
                                    (o: any) => o.id === value
                                  )
                                  setFieldValue(
                                    'treatmentApproach',
                                    selectedOption
                                  )
                                }}
                                options={treatmentApproachOptions}
                              />
                            </FormControl>
                          )}
                        </Field>
                      </>
                    )}
                    <VStack w="100%">
                      {isDocumentationAutomationEnabled ? (
                        <Button
                          bg="primary"
                          isFullWidth
                          size="lg"
                          type="submit"
                          isLoading={isEnding}
                          loadingText={
                            isUploadingAudio ? 'Uploading audio...' : ''
                          }
                          leftIcon={<Assist fill="white" />}
                        >
                          Generate Note
                        </Button>
                      ) : (
                        <Button
                          bg="primary"
                          isFullWidth
                          size="lg"
                          onClick={() =>
                            handleEndSession(
                              user?.clinic?.organization?.default_note_type ||
                                'soap',
                              null
                            )
                          }
                        >
                          End Session
                        </Button>
                      )}
                      <Button
                        variant="ghost"
                        disabled={isEnding}
                        onClick={() => setIsDiscardSelected(true)}
                        leftIcon={<TrashcanIcon fill="error" />}
                      >
                        Delete Session
                      </Button>
                    </VStack>
                  </VStack>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </DialogBody>
    </DialogContainer>
  )
}

export const EndSessionModalV2 = React.memo(
  EndSessionModalComponent,
  (props, nextProps) => {
    const { onClose, onEnd, onDiscard, ...restProps } = props

    const {
      onClose: nextOnClose,
      onEnd: nextOnEnd,
      onDiscard: nextOnDiscard,
      ...restNextProps
    } = nextProps

    return _.isEqual(restProps, restNextProps)
  }
)
