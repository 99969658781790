import React from 'react'
import TreatmentPlan from '@handlers/sessions/components/treatment-plan'

export default (props: any) => {
  const { patient } = props
  return (
    <TreatmentPlan
      client={patient}
      containerProps={{ boxShadow: 'none', spacing: 4 }}
      emptyStateText="Integrate a treatment plan into future notes for golden thread documentation."
    />
  )
}
