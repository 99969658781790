import WarningIcon from '@material-ui/icons/Warning'
import { VStack, Text, Button, Box, Icon } from '@chakra-ui/react'
import React from 'react'

export const MicrophoneBlockedWarning = () => {
  return (
    <VStack
      justifyContent="center"
      minWidth="320px"
      maxWidth="420px"
      gap="13px"
      alignItems="center"
      textAlign="center"
    >
      <Text fontSize="32px" color="#C6162C">
        Microphone access is currently blocked
      </Text>
      <Button
        bg="primary"
        fontWeight="430"
        disabled={true}
        height="56px"
        width="100%"
        color="white"
        variant="body"
        _hover={{ bg: 'primary' }}
      >
        Start Recording
      </Button>
      <Box
        bg="#FFF2F2"
        borderRadius="md"
        p={4}
        pr={8}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        border={`1px solid rgba(40, 40, 40, 0.05)`}
      >
        <Icon
          as={WarningIcon}
          color="red.500"
          mr="16px"
          mt="2px"
          boxSize={6}
        />
        <Text mb="4px" textAlign="left">
          You will need to allow access in your browser and possibly reload
          the page before we can start recording
        </Text>
      </Box>
    </VStack>
  )
}