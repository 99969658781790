import React from 'react'
import {
  Box,
  Select,
  FormControl,
  FormErrorMessage,
  HStack
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { Field } from 'formik'
import _ from 'lodash'

import { endpoints } from '@api'
import { CheckboxSelector } from '@components'

const ClinicianComponent = ({ formHelpers }) => {
  const { values, errors, touched, resetConflict } = formHelpers

  const { user } = useStoreState(state => state.auth)

  const { data: clinicians } = useQuery(
    [endpoints.getClinicClinicians.getCacheId(), values.clinic],
    () =>
      endpoints.getClinicClinicians.request({
        clinicDisplayId: encodeURIComponent(values.clinic.displayId),
        showVisibleOnly: true
      }),
    {
      initialData: { clinicians: [] },
      select: data =>
        data.clinicians.map(c => ({
          id: c.id,
          name: `${c.first_name} ${c.last_name}${
            c.id === user.id ? ' (You)' : ''
          }`
        })),
      enabled: Boolean(user)
    }
  )

  return (
    <Box
      w={{
        base: '180px',
        sm: 'auto'
      }}
    >
      <HStack spacing="medium" align="center">
        <FormControl
          maxWidth="244px"
          isInvalid={!!errors.clinician && touched.clinician}
        >
          <Field name="clinician">
            {({ field, form }) => (
              <Select
                {...field}
                placeholder="Select a clinician"
                value={field.value?.id}
                onChange={evt => {
                  const value = clinicians.find(c => c.id === evt.target.value)
                  form.setFieldValue('clinician', value)
                  if (value) {
                    form.setFieldValue(
                      'secondaryClinicians',
                      form.values.secondaryClinicians.filter(
                        clinician => clinician.value !== value.id
                      )
                    )
                  }
                  resetConflict('clinician_id')
                }}
                borderColor="#C9C9C9"
                width="244px"
                maxWidth="244px"
                minHeight="56px"
              >
                {clinicians.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
            )}
          </Field>
          <FormErrorMessage>Must select clinician</FormErrorMessage>
        </FormControl>
        <FormControl minHeight="56px" width="300px">
          <Field name="secondaryClinicians">
            {({ field, form }) => (
              <CheckboxSelector
                items={clinicians
                  .filter(
                    clinician =>
                      clinician.id !==
                      (form.values.clinician ? form.values.clinician.id : null)
                  )
                  .map(clinician => ({
                    label: clinician.name,
                    value: clinician.id
                  }))}
                label="Secondary Clinician(s)"
                selectAllLabel="Select All Clinicians"
                alreadySelected={field.value}
                limitTags={10}
                onChange={selections => {
                  form.setFieldValue('secondaryClinicians', selections)
                }}
              />
            )}
          </Field>
        </FormControl>
      </HStack>
    </Box>
  )
}

export const Clinician = React.memo(ClinicianComponent, (props, nextProps) =>
  _.isEqual(props, nextProps)
)
