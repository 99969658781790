import React from 'react'
import {
  Box,
  Stack,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Link
} from '@chakra-ui/react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useMutation, useQueryClient } from 'react-query'
import { useExperienceManager } from '@hooks'
import { endpoints } from '@api'

export const DocumentationAutomationMarketingModal = () => {
  const queryClient = useQueryClient()

  const { isOpen } = useStoreState(
    state => state.modals.documentationAutomationMarketingModal
  )

  const { isAdmin } = useExperienceManager()

  const handleCloseModal = useStoreActions(
    actions => actions.modals.documentationAutomationMarketingModal.closeModal
  )

  const { mutateAsync: startFreeTrial, isLoading } = useMutation(
    endpoints.tryDocAutomationFree.request,
    {
      onSuccess: () => {
        handleCloseModal()
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        )
        queryClient.invalidateQueries(
          endpoints.getOrganizationProvidersV2.getCacheId()
        )
        queryClient.invalidateQueries(endpoints.getClinicBilling.getCacheId())
      }
    }
  )

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent overflow="scroll">
        <ModalHeader p="24px 24px 0px 24px">
          <Text textAlign="center" fontWeight="bold" fontSize="24px">
            AI Notetaker
          </Text>
          <Box height="40px" />
        </ModalHeader>
        <ModalCloseButton _focus={{ outline: 'none' }} />
        <Stack padding="0 24px 0px 24px" gap="24px">
          <Flex>
            <Text>
              Blueprint’s AI Notetaker listens, transcribes, and automatically
              writes a first-draft progress note for you. Save 5+ hours per week
              on documentation so you can spend more time focusing on your
              clients!{' '}
              <Link
                color="primary"
                textDecoration="underline"
                href={`${process.env.REACT_APP_HELP_URL}/en/articles/8558032-overview-ai-notetaker`}
                isExternal
              >
                Learn more
              </Link>
            </Text>
          </Flex>
          <Flex style={{ marginTop: 0 }} justifyContent="center">
            {isAdmin ? (
              <Button
                bg="#282828"
                width="241px"
                onClick={() => {
                  startFreeTrial()
                }}
                isDisabled={isLoading}
              >
                Try for free
              </Button>
            ) : (
              <Text fontWeight="bold">
                Contact your admin to turn on this feature
              </Text>
            )}
          </Flex>
          <Flex style={{ marginTop: 0 }} justifyContent="center">
            <img src="https://res.cloudinary.com/hellojoy/image/upload/v1706632360/da-marketing.png" />
          </Flex>
        </Stack>
      </ModalContent>
    </Modal>
  )
}

export const DocumentationAutomationMarketingModalConnected = () => {
  const user = useStoreState(state => state.auth.user)

  if (!user) {
    return null
  }

  return <DocumentationAutomationMarketingModal />
}
