import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import moment from 'moment'

import { EnrollNewClient } from '@handlers/enroll-new-client'
import { endpoints } from '@api'
import { Loading } from '@components'

export function PendingClientProfile(props) {
  const id = props.computedMatch.params.id

  const { isLoading, data: client } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), id],
    () => endpoints.getClinicianUserAccount.request({ id })
  )
  const {
    data: clientParticipants,
    isLoading: isParticpantsLoading
  } = useQuery([endpoints.getClientParticipants.getCacheId(), id], () =>
    endpoints.getClientParticipants.request({ clientId: id })
  )

  const {
    data: assessmentsByAssignee,
    isLoading: isAssessmentsLoading
  } = useQuery(
    [
      endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
      id,
      client && client.clinician_id
    ],
    () =>
      endpoints.getAllAssessmentsForClientByAssignee.request({
        id: id,
        clinicianId: client && client.clinician_id
      }),
    {
      enabled: !!client
    }
  )

  const {
    data: clientCheckInModules,
    isLoading: isCheckInModulesLoading
  } = useQuery([endpoints.getCheckInModulesForUser.getCacheId(), id], () =>
    endpoints.getCheckInModulesForUser.request({ id: id })
  )

  const { data: clinicianList, isLoading: isClinicianListLoading } = useQuery(
    [endpoints.getPatientClinicianList.getCacheId(), client && client.id],
    () =>
      endpoints.getPatientClinicianList.request({
        patientId: client.id
      }),
    {
      enabled: !!client
    }
  )

  const pendingClientData = useMemo(() => {
    // Wait for all data to be loaded
    if (
      !client ||
      !clientParticipants ||
      !assessmentsByAssignee ||
      !clientCheckInModules ||
      !clinicianList
    )
      return null

    const assessments = assessmentsByAssignee.reduce((all, entry) => {
      const matchingParticipant = clientParticipants.find(
        p => p.userId === entry.assigneeUserId
      )

      const ref = matchingParticipant
        ? `participant:${matchingParticipant.id}`
        : 'client'

      all[ref] = entry.assessments
        .filter(a => a.isActive)
        .map(a => ({
          assigneeUserId: entry.assigneeUserId,
          subtitle: a.disorder,
          assessmentId: a.assessment_id,
          title: `${a.full_name} ${
            a.name !== a.full_name ? '(' + a.name + ')' : ''
          }`,
          numQuestions: a.num_questions,
          id: a.id,
          isScreener: a.is_screener,
          cadence: {
            unit: a.cadence_unit,
            value: a.cadence_value
          }
        }))
      return all
    }, {})

    const cleanedModules = clientCheckInModules.map(m => ({
      ...m,
      numQuestions: m.num_questions,
      canToggle: m.can_toggle
    }))

    const symptomTrackers = cleanedModules.filter(
      m => m.type === 'symptom' && m.isActive
    )

    const worksheets = cleanedModules.filter(
      m => m.type === 'treatment_activity' && m.isActive
    )

    const secondaryClinicians = clinicianList
      .filter(clinician => clinician.clinician_rank === 'secondary')
      .map(c => ({
        value: c.id,
        label: `${c.first_name} ${c.last_name}`
      }))

    return {
      ...client,
      symptomTrackers,
      worksheets,
      assessments,
      participants: clientParticipants,
      pendingClientId: id,
      dobISO: moment.utc(client.date_of_birth).format('MM/DD/YYYY'),
      phone: client.phone_number ? client.phone_number.substring(2) : '',
      secondaryClinicians,
      clinician: client.clinician_id
        ? {
            id: client.clinician_id,
            name: `${client.clinician_first_name} ${client.clinician_last_name}`
          }
        : null,
      errorMessage: client.enrollment_error && client.enrollment_error.message,
      conflicts: client.enrollment_error && client.enrollment_error.conflicts
    }
  }, [
    client,
    clientParticipants,
    assessmentsByAssignee,
    clientCheckInModules,
    clinicianList
  ])

  if (
    !pendingClientData ||
    isLoading ||
    isClinicianListLoading ||
    isParticpantsLoading ||
    isAssessmentsLoading ||
    isCheckInModulesLoading
  ) {
    return <Loading />
  }

  return (
    <EnrollNewClient
      {...props}
      pendingClientData={pendingClientData}
      isPendingClient
    />
  )
}
