import React, { useMemo } from 'react'
import { Box, Text, Heading, Skeleton } from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import _ from 'lodash'

import { OutcomesChart } from './outcomes-chart'
import { insightsLegendTooltip } from '../../../../constants/Copy'
import { usePermissions } from '../../../../hooks'

export function getEffectSizeLabel(effectSize) {
  if (effectSize === null) return 'Not Available'
  if (parseFloat(effectSize) < 0.2) {
    return 'None'
  } else if (parseFloat(effectSize) < 0.4) {
    return 'Mild'
  } else if (parseFloat(effectSize) < 0.7) {
    return 'Moderate'
  } else {
    return 'Highly Effective'
  }
}

export const OutcomesContent = ({ data, isLoading }) => {
  const { hasPermission } = usePermissions()
  const { filters, loaded: filtersLoaded } = useStoreState(
    state => state.insights
  )

  const selectedOutcome = useMemo(() => {
    const outcomesSelection = filters.find(filter => filter.id === 'outcomes')
      .selected
    return outcomesSelection.data
  }, [filters])

  const chartData = useMemo(() => {
    // Remove discharge week if null
    return data.intervals
      .filter(interval => interval.effect_size !== null)
      .map(int => {
        let interval = _.cloneDeep(int)
        // Set discharge week as graph friendly
        if (interval.week !== 0 && !interval.week) {
          interval.week = 40
        }

        // Normalize effect sizes for chart, recharts has issues with charting small decimals, so multiply everything by 100
        const avg = Math.min(Math.max(interval.effect_size.mid * 100, 0), 100)
        const high = Math.min(
          parseFloat(interval.effect_size.high) * 100 - avg,
          100
        )
        const low = Math.max(
          avg - parseFloat(interval.effect_size.low) * 100,
          0
        )
        interval.error = [
          avg - low < 0 ? avg : low,
          Math.max(high + avg > 100 ? 100 - avg : high, 0)
        ]
        interval.effect_size.normalized_mid = avg

        return interval
      })
  }, [data])

  const possessionText = useMemo(() => {
    if (hasPermission('read:any:reports:outcomes')) {
      return 'at all of Blueprint'
    } else if (hasPermission('read:org:reports:outcomes')) {
      return 'at your organization'
    } else if (hasPermission('read:clinic:reports:outcomes')) {
      return 'at your clinic'
    } else {
      return 'assigned to you'
    }
  }, [hasPermission])

  if (!filtersLoaded) return null

  return (
    <Box>
      <Heading>Population Outcomes</Heading>
      <Box mt="small">
        <Text as="span">
          {`Based on the `}{' '}
          <Text as="span" fontWeight={'bold'}>
            {selectedOutcome.name}: {selectedOutcome.disorder}
          </Text>{' '}
          {`assessment being completed `}
          <Skeleton isLoaded={!isLoading} width="auto" display="inline-block">
            <Text as="span" fontWeight={'bold'}>
              {data.assessment_count} times
            </Text>
          </Skeleton>
          {` by `}
          <Skeleton isLoaded={!isLoading} width="auto" display="inline-block">
            <Text as="span" fontWeight={'bold'}>
              {data.patient_count} clients
            </Text>
          </Skeleton>
          {` ${possessionText}.`}
        </Text>
      </Box>
      <Box mt="xsmall">
        <Text>
          {`Using the results from these completed assessments, we can calculate average treatment response across various populations, as it pertains to this assessment.`}
        </Text>
      </Box>
      <Box
        sx={{
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px',
          padding: 'small',
          borderRadius: 8,
          mt: 'large'
        }}
      >
        <OutcomesChart
          width={'100%'}
          height={380}
          data={chartData}
          selectedOutcome={selectedOutcome}
          isLoading={isLoading}
        />
      </Box>
      <Box mt="xlarge">
        <Text fontWeight="bold" mb="xsmall">
          How is effect size calculated?
        </Text>
        <Text maxWidth="900px">{insightsLegendTooltip()}</Text>
      </Box>
    </Box>
  )
}
