import React, { useCallback, useState } from 'react'
import { Text, Link, useToast } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { DiscardInterventionModal } from '@handlers/intervention/components'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { isMobile } from 'react-device-detect'

export const DiscardButton = ({
  patient,
  clientIntervention,
  intervention,
  refetch
}) => {
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false)
  const toast = useToast()
  const queryClient = useQueryClient()

  const { mutateAsync: executeDeleteClientIntervention } = useMutation(
    endpoints.deletePartiallyCompletedIntervention.request,
    {
      onError: () => {
        toast({
          title: 'Error',
          description: 'There was an error discarding this activity',
          status: 'error',
          isClosable: true,
          duration: 2000,
          position: 'bottom-left'
        })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          endpoints.getInterventionOverview.getCacheId(),
          {
            clientId: patient.id,
            interventionId: intervention.id
          }
        ])
        refetch()
      }
    }
  )

  const discardClientIntervention = useCallback(async () => {
    await executeDeleteClientIntervention({
      clientId: patient.id,
      interventionId: intervention.id,
      clientInterventionId: clientIntervention.id
    })
  }, [clientIntervention, intervention, patient])

  return (
    <>
      <Link
        id={`pendo-intervention-view-link-tag-${intervention.id}`}
        as={RouterLink}
        onClick={() => setIsDiscardModalOpen(true)}
        color="red"
        ml={!isMobile ? '24px' : '0px'}
      >
        <Text color={'red'} fontSize={'16px'}>
          Discard
        </Text>
      </Link>

      <DiscardInterventionModal
        isOpen={isDiscardModalOpen}
        handleClose={() => setIsDiscardModalOpen(false)}
        discard={discardClientIntervention}
      />
    </>
  )
}
