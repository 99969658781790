import React from 'react'

const MicOff = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 12H17.8C17.8 12.74 17.64 13.43 17.37 14.05L18.6 15.28C19.16 14.3 19.5 13.19 19.5 12ZM15.48 12.17C15.48 12.11 15.5 12.06 15.5 12V6C15.5 4.34 14.16 3 12.5 3C10.84 3 9.5 4.34 9.5 6V6.18L15.48 12.17ZM4.77 4L3.5 5.27L9.51 11.28V12C9.51 13.66 10.84 15 12.5 15C12.72 15 12.94 14.97 13.15 14.92L14.81 16.58C14.1 16.91 13.31 17.1 12.5 17.1C9.74 17.1 7.2 15 7.2 12H5.5C5.5 15.41 8.22 18.23 11.5 18.72V22H13.5V18.72C14.41 18.59 15.27 18.27 16.04 17.82L20.23 22L21.5 20.73L4.77 4Z"
      fill={props.fill || '#282828'}
    />
  </svg>
)

export default MicOff
