import * as Yup from 'yup'
import gPhoneNumber from 'google-libphonenumber'

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance()

const YUP_PHONE_METHOD = 'phone'
const CLDR_REGION_CODE_SIZE = 2

const allValidCountryCodes = countryCode =>
  countryCode.every(code => typeof code === 'string') &&
  countryCode.every(code => code.length === CLDR_REGION_CODE_SIZE)

const isValidNumberForRegions = (number, countryCode) => {
  return countryCode.some(code =>
    phoneUtil.isValidNumberForRegion(number, code)
  )
}

Yup.addMethod(Yup.string, YUP_PHONE_METHOD, function yupPhone(
  countryCodes,
  strict = false,
  errorMessage = ''
) {
  let codes = Array.isArray(countryCodes) ? countryCodes : [countryCodes]
  const errMsg =
    typeof errorMessage === 'string' && errorMessage
      ? errorMessage
      : allValidCountryCodes(codes)
      ? `\${path} must be a valid phone number for region ${codes.join(',')}`
      : '${path} must be a valid phone number.'
  // @ts-ignore
  return this.test(YUP_PHONE_METHOD, errMsg, value => {
    if (!allValidCountryCodes(codes)) {
      // if not valid countryCode, then set default country to United States (US)
      codes = ['US']
      strict = false
    }

    // This is what .required() is for
    if (value === undefined || value === '') return true

    try {
      // if we start doing business in a country where area codes don't start with +1
      // we will need to change our strategy here
      const phoneNumber = phoneUtil.parseAndKeepRawInput(value, codes[0])
      if (!phoneUtil.isPossibleNumber(phoneNumber)) {
        return false
      }

      const regionCodeFromPhoneNumber = phoneUtil.getRegionCodeForNumber(
        phoneNumber
      )
      /* check if the countryCode provided should be used as
       default country code or strictly followed
     */
      return strict
        ? isValidNumberForRegions(phoneNumber, codes)
        : phoneUtil.isValidNumberForRegion(
            phoneNumber,
            regionCodeFromPhoneNumber
          )
    } catch {
      return false
    }
  })
})
