import React from 'react'

const Upload = ({ fill = 'none', width }) => (
  <svg
    width={width || '16'}
    height={width || '16'}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 16H15V10H19L12 3L5 10H9V16ZM5 18H19V20H5V18Z" fill={fill} />
  </svg>
)

export default Upload
