import React from 'react'
import { X } from '../icons'
import styles from './pill.module.css'

const Pill = props => (
  <div style={{ backgroundColor: props.color }} className={styles.pill}>
    <span className={styles.label}>{props.label}</span>
    <span onClick={props.onClick} className={styles.x}>
      <X fill="#ffffff" />
    </span>
  </div>
)

export default Pill
