import React, { useMemo, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useStoreActions } from 'easy-peasy'
import { Box, Text, VStack } from '@chakra-ui/react'

import { endpoints } from '../../../../api'
import { FilterSelect } from './filter-select'
import { usePermissions } from '../../../../hooks'

const ageSelectOptionsOutside = [
  {
    label: 'All Ages',
    value: 'all',
    data: 'all'
  },
  {
    label: '0-13 Years',
    value: '0-13',
    data: {
      min: 0,
      max: 13
    }
  },
  {
    label: '14-17 Years',
    value: '14-17',
    data: {
      min: 14,
      max: 17
    }
  },
  {
    label: '18-25 Years',
    value: '18-25',
    data: {
      min: 18,
      max: 25
    }
  },
  {
    label: '26-35 Years',
    value: '26-35',
    data: {
      min: 26,
      max: 35
    }
  },
  {
    label: '36-60 Years',
    value: '36-60',
    data: {
      min: 36,
      max: 60
    }
  },
  {
    label: '61+ Years',
    value: '61-999',
    data: {
      min: 61,
      max: 999
    }
  }
]

export function FiltersSidebar() {
  const { hasPermission } = usePermissions()
  const { updateFilters, setFiltersLoaded } = useStoreActions(
    actions => actions.insights
  )

  const { data, isLoading } = useQuery(
    endpoints.getInsightsFilters.getCacheId(),
    () => endpoints.getInsightsFilters.request(),
    {
      initialData: {
        assessments: [],
        clinicians: [],
        clinics: []
      }
    }
  )

  const assessmentSelectOptions = useMemo(() => {
    return data.assessments.map(assessment => {
      return {
        label: `${assessment.name}: ${assessment.disorder}`,
        value: assessment.id,
        data: assessment
      }
    })
  }, [data])

  const clinicianSelectOptions = useMemo(() => {
    return [
      {
        label: 'All Clinicians',
        value: 'all'
      }
    ].concat(
      data.clinicians.map(clinician => {
        return {
          label: `${clinician.first_name} ${clinician.last_name}`,
          value: clinician.id,
          data: clinician
        }
      })
    )
  }, [data])

  const clinicsSelectOptions = useMemo(() => {
    if (data.clinics) {
      return [
        {
          label: 'All Clinics',
          value: 'all'
        }
      ].concat(
        data.clinics.map(clinic => {
          return {
            label: clinic.name,
            value: clinic.id,
            data: clinic
          }
        })
      )
    }
    return []
  }, [data])

  const ageSelectOptions = useMemo(() => ageSelectOptionsOutside, [data])

  useEffect(() => {
    if (assessmentSelectOptions.length) {
      const defaultFilters = [
        {
          id: 'outcomes',
          selected: assessmentSelectOptions[0]
        },
        {
          id: 'clinicians',
          selected: [clinicianSelectOptions[0]]
        },
        {
          id: 'ages',
          selected: [ageSelectOptions[0]]
        }
      ]
      if (data.clinics) {
        defaultFilters.push({
          id: 'clinics',
          selected: [clinicsSelectOptions[0]]
        })
      }
      updateFilters(defaultFilters)
      setFiltersLoaded(true)
    }
  }, [
    assessmentSelectOptions,
    clinicianSelectOptions,
    ageSelectOptions,
    clinicsSelectOptions
  ])

  if (isLoading) {
    return null
  }

  return (
    <VStack spacing="medium" alignItems="normal">
      <Box>
        <Text fontWeight="bold" fontSize="18px">
          FILTERS
        </Text>
      </Box>
      {hasPermission('read:org:reports:outcomes') && (
        <FilterSelect
          label="Clinics"
          filterId="clinics"
          options={clinicsSelectOptions}
          multiple
        />
      )}
      <FilterSelect
        label="Assessments"
        filterId="outcomes"
        options={assessmentSelectOptions}
      />
      {hasPermission('read:clinic:reports:outcomes') && (
        <FilterSelect
          label="Clinicians"
          filterId="clinicians"
          options={clinicianSelectOptions}
          multiple
        />
      )}
      <FilterSelect
        label="Ages"
        filterId="ages"
        options={ageSelectOptions}
        type="checkbox"
      />
    </VStack>
  )
}
