import React, { useMemo } from 'react'
import {
  Flex,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Text,
  Box,
  VStack
} from '@chakra-ui/react'
import { ArrowDownIcon, ArrowUpIcon } from '@blueprinthq/joy'
import { useTable, useSortBy, HeaderProps, CellProps } from 'react-table'
import {
  CheckInContentDtoV2NodesItem,
  CheckInDtoV2,
  CheckInScoreDtoV1
} from '~/clinician-api/models'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { mapCheckInAnswerToValue } from './utils'

interface AnswerDayItem {
  date: DateTime
  answers: {
    value: string | undefined
    date: string
  }[]
}

interface WeeklyAnswerItem {
  node: CheckInContentDtoV2NodesItem
  days: {
    [key: string]: AnswerDayItem
  }
}

export const WorksheetWeeklyAnswersTable = ({
  checkInVersions,
  startOfWeekDate,
  scores
}: {
  checkInVersions: CheckInDtoV2[]
  startOfWeekDate: Date
  scores: CheckInScoreDtoV1[]
}) => {
  const data = useMemo(() => {
    const start = DateTime.fromJSDate(startOfWeekDate).startOf('day')

    const days = Array.from({ length: 7 }, (_, index) =>
      start.plus({ days: index })
    )

    const scoresInWeek = scores.filter(s =>
      days.some(dayDate =>
        dayDate.hasSame(DateTime.fromISO(s.createdAt), 'day')
      )
    )

    const versionsInWeek = scoresInWeek.length
      ? checkInVersions.filter(cv =>
          scoresInWeek.find(sw => sw.versionId === cv.versionId)
        )
      : [checkInVersions[0]]

    const nodes = _.uniqBy(
      versionsInWeek.flatMap(cv => cv.content.nodes),
      node => node.id
    )

    const model = nodes
      .filter(n => n.type !== 'heading')
      .map(node => {
        const item: WeeklyAnswerItem = {
          node,
          days: {}
        }

        days.forEach((dayDate, index) => {
          const dayAnswers = scores
            .filter(s => dayDate.hasSame(DateTime.fromISO(s.createdAt), 'day'))
            .map(s => {
              const answer = s.answers.find(a => a.key === node.id)
              return {
                value: mapCheckInAnswerToValue(answer, node),
                date: s.createdAt
              }
            })
            .filter(a => a.value !== undefined)

          const key = `day${index + 1}`

          item.days[key] = {
            date: dayDate,
            answers: dayAnswers
          }
        })

        return item
      })

    return model
  }, [startOfWeekDate, scores, checkInVersions])

  const columns = useMemo(
    () => [
      {
        Header: 'Question',
        accessor: 'node',
        Cell: ({ value, row }: CellProps<WeeklyAnswerItem>) => {
          return (
            <Box>
              <Text>
                {row.index + 1}. {value.label}
              </Text>
            </Box>
          )
        },
        style: {
          width: 220
        },
        id: 'node'
      },
      ...Array.from({ length: 7 }, (_, index) => ({
        Header: ({ column, rows }: HeaderProps<WeeklyAnswerItem>) => {
          if (rows.length === 0) return null

          const date = rows[0].original.days[column.id]?.date

          return (
            <Box>
              <Text fontSize="xsm" fontWeight="bold">
                {date?.toFormat('ccc')}
              </Text>
              <Text fontSize="xsm" color="medium_gray" fontWeight="normal">
                {date?.toFormat('D')}
              </Text>
            </Box>
          )
        },
        Cell: ({ value }: CellProps<WeeklyAnswerItem, AnswerDayItem>) => {
          if (value.answers.length === 0) {
            return <Text>-</Text>
          }

          return (
            <VStack alignItems="start" height="120px" overflowY="auto">
              {value.answers.map(a => (
                <Box>
                  <Text fontSize="xsm" color="medium_gray">
                    {DateTime.fromISO(a.date).toFormat('t')}
                  </Text>
                  <Text>{a.value}</Text>
                </Box>
              ))}
            </VStack>
          )
        },
        id: `day${index + 1}`,
        accessor: `days.day${index + 1}`
      }))
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable<WeeklyAnswerItem>(
    {
      // @ts-ignore
      columns,
      data,
      disableSortRemove: true
    },
    useSortBy
  )

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup, i) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                borderColor="light_gray"
                // @ts-ignore
                sx={column.style}
              >
                <Flex alignItems="center">
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownIcon size="sm" fill={'gray.600'} />
                      ) : (
                        <ArrowUpIcon size="sm" fill={'gray.600'} />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <Tr
              {...row.getRowProps()}
              bg={i % 2 === 0 ? 'transparent' : 'rgba(45, 84, 232, 0.04)'}
            >
              {row.cells.map((cell, j) => (
                <Td
                  {...cell.getCellProps()}
                  borderColor="light_gray"
                  verticalAlign="middle"
                  pt="small"
                  pb="small"
                >
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}
