import React, { useCallback, useMemo, useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
  PrimaryButton,
  SecondaryButton,
  Loading,
  CheckboxSelector
} from '../../components'
import { endpoints } from '../../api'
import { useStyles } from './styles'

export const ManageAssignedCliniciansModal = () => {
  const classes = useStyles()
  const [selectedClinicianIds, setSelectedClinicianIds] = useState([])
  const [displayError, setDisplayError] = useState(null)

  const { isOpen, patientId } = useStoreState(
    state => state.modals.assignAdditionalClinician
  )
  const handleCloseModal = useStoreActions(
    actions => actions.modals.assignAdditionalClinician.closeModal
  )
  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const queryClient = useQueryClient()

  const { isLoading: isPatientLoading, data: patient } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), patientId],
    () => endpoints.getClinicianUserAccount.request({ id: patientId }),
    {
      enabled: isOpen && !!patientId
    }
  )

  const { data, isLoading } = useQuery(
    [endpoints.getPatientClinicianList.getCacheId(), patient && patient.id],
    () =>
      endpoints.getPatientClinicianList.request({
        patientId: patient.id
      }),
    {
      enabled: isOpen && !!patient,
      initialData: []
    }
  )

  useEffect(() => {
    const assignedClinicianIds = data.filter(c => c.assigned).map(c => c.id)
    setSelectedClinicianIds(assignedClinicianIds)
  }, [isOpen, data])

  const {
    mutateAsync: executeAssignAdditionalClinicians,
    isLoading: isAssignLoading
  } = useMutation(endpoints.postManageAssignedClinicians.request, {
    onError: () => {
      setDisplayError(
        'There was an error processing your clinician assignment request. Please refresh the page and try again.'
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(endpoints.getPatientList.getCacheId())
      await queryClient.invalidateQueries(
        endpoints.getPatientClinicianList.getCacheId()
      )
      setSnackbarMessage({
        variant: 'success',
        message: `Selected clinician(s) have been updated for ${patient.first_name} ${patient.last_name}`
      })
      handleCloseWrapper()
    }
  })

  const submit = useCallback(async () => {
    if (!selectedClinicianIds.length) {
      setDisplayError('Please select a clinician')
      return
    }

    const data = {
      clinician_ids: selectedClinicianIds
    }
    await executeAssignAdditionalClinicians({ data, clientId: patient.id })
  }, [executeAssignAdditionalClinicians, patient, selectedClinicianIds])

  const handleCloseWrapper = useCallback(() => {
    setDisplayError(null)
    handleCloseModal()
  }, [handleCloseModal])

  const handleCliniciansChange = useCallback(
    selections => {
      setSelectedClinicianIds(selections.map(selection => selection.value))
    },
    [setSelectedClinicianIds]
  )

  const applicableClinicians = useMemo(
    () =>
      data.map(c => ({
        label: `${c.first_name} ${c.last_name}`,
        value: c.id,
        disabled: c.clinician_rank === 'primary'
      })),
    [data]
  )
  const selectedClinicians = useMemo(
    () =>
      selectedClinicianIds.map(id =>
        applicableClinicians.find(ac => ac.value === id)
      ),
    [selectedClinicianIds, applicableClinicians]
  )

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleCloseWrapper()}
      maxWidth={'xs'}
      fullWidth={true}
    >
      {isAssignLoading || isLoading || isPatientLoading ? (
        <div style={{ height: '256px' }}>
          <Loading />
        </div>
      ) : (
        <div>
          <DialogTitle className={classes.centerText}>
            Manage assigned clinicians
          </DialogTitle>
          <DialogContent className={classes.contents}>
            <DialogContentText className={classes.centerText}>
              {
                "Select clinicians you'd like to grant or remove access to this client's account."
              }
            </DialogContentText>
            <CheckboxSelector
              items={applicableClinicians}
              label="Clinician(s)"
              selectAllLabel="Select All Clinicians"
              alreadySelected={selectedClinicians}
              onChange={handleCliniciansChange}
              limitTags={10}
            />
            <div className={classes.buttonContainer}>
              <DialogContentText className={classes.errorText}>
                {displayError || null}
              </DialogContentText>
              <div className={classes.buttonRow}>
                <SecondaryButton
                  fullWidth={false}
                  onClick={() => handleCloseWrapper()}
                  round
                  className={classes.button}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  fullWidth={false}
                  onClick={() => submit()}
                  round
                  className={classes.button}
                >
                  Confirm
                </PrimaryButton>
              </div>
            </div>
          </DialogContent>
        </div>
      )}
    </Dialog>
  )
}

export const ManageAssignedCliniciansModalConnected = () => {
  const user = useStoreState(state => state.auth.user)
  const { isOpen, patientId } = useStoreState(
    state => state.modals.assignAdditionalClinician
  )
  const handleCloseModal = useStoreActions(
    actions => actions.modals.assignAdditionalClinician.closeModal
  )

  return patientId && user ? (
    <ManageAssignedCliniciansModal
      isOpen={isOpen}
      patientId={patientId}
      handleCloseModal={handleCloseModal}
    />
  ) : null
}
