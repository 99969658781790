import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import history from '../../history'
import styles from './table.module.css'
import cx from 'classnames'
import { Link } from 'react-router-dom'

export default class JoyTable extends Component {
  handleClick = path => {
    history.push(path)
  }

  renderHeaders = headers => {
    const cells = headers.map((cell, index) => {
      return (
        <TableCell
          className={cx(this.props.isWeekly ? styles.dates : styles.cell)}
          key={index}
        >
          {cell}
        </TableCell>
      )
    })
    return (
      <TableHead className={styles.tableHead}>
        <TableRow style={{ verticalAlign: 'top' }}>{cells}</TableRow>
      </TableHead>
    )
  }

  renderWeeklySectionHeader = header_row => {
    return (
      // section header
      <TableRow className={styles.section_header} align={'left'}>
        <TableCell
          style={{
            verticalAlign: 'middle',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            borderBottom: 0,
            paddingLeft: 0
          }}
        >
          {header_row}
        </TableCell>
      </TableRow>
    )
  }

  renderWeeklyCells = row => {
    return Object.keys(row).map((key, idx) => {
      var item = row[key]
      if (Array.isArray(item)) {
        return item.map((value, index) => {
          return (
            <TableCell
              className={cx(styles.weekly_cell, value.italicize && styles.italics)}
              align={'left'}
              key={`${index + '.' + key}`}
            >
              {value.value}
            </TableCell>
          )
        })
      } else {
        return (
          <TableCell
            className={cx(styles.weekly_cell)}
            align={'left'}
            key={`${idx + '.' + key}`}
          >
            {item}
          </TableCell>
        )
      }
    })
  }

  renderCells = row => {
    return Object.keys(row).map((key, idx) => {
      var item = row[key]
      if (key === 'id') {
        return (
          <TableCell className={styles.cell} key={`${idx + '.' + key}`}>
            <Link to={item}>View Details</Link>
          </TableCell>
        )
      } else {
        return (
          <TableCell className={styles.cell, row.italicize && styles.italics} key={`${idx + '.' + key}`}>
            {item}
          </TableCell>
        )
      }
    })
  }

  renderWeeklySection = section => {
    const { clickable } = this.props
    const section_header = this.renderWeeklySectionHeader(section.header)
    const rows = section.data.map((row, index) => {
      return (
        <TableRow
          className={cx(styles.row, clickable && styles.clickable)}
          hover
          key={index}
        >
          {this.renderWeeklyCells(row)}
        </TableRow>
      )
    })
    return <TableBody>{[section_header].concat(rows)}</TableBody>
  }

  renderBody = data => {
    const { clickable, mini, sameColorRows } = this.props
    if (!data) {
      return null
    }
    const rows = data.map((row, index) => {
      return (
        <TableRow
          className={cx(
            styles.row,
            clickable && styles.clickable,
            mini && styles.mini,
            sameColorRows && styles.sameColorRows
          )}
          hover
          onClick={() => {
            this.handleClick(row.id)
          }}
          key={index}
        >
          {this.renderCells(row)}
        </TableRow>
      )
    })
    return <TableBody>{rows}</TableBody>
  }
  render() {
    const { data, headers, isWeekly } = this.props
    if (isWeekly) {
      return (
        <Table>
          {this.renderHeaders([''].concat(data[0].header))}
          {data
            .filter(section => section.data)
            .map(section => this.renderWeeklySection(section))}
        </Table>
      )
    }
    return (
      <Table>
        {headers.length ? this.renderHeaders(headers) : null}
        {this.renderBody(data)}
      </Table>
    )
  }
}
