import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import Snackbar from '@material-ui/core/Snackbar'

import { JoySnackbarContent } from '../../components'

export const AppSnackbar = () => {
  const { message, variant, isOpen, duration, position } = useStoreState(
    state => state.snackbar
  )
  const closeSnackbar = useStoreActions(
    actions => actions.snackbar.closeSnackbar
  )

  const mapPositionToAnchorOrigin = position => {
    switch (position) {
      case 'bottom-left':
        return {
          vertical: 'bottom',
          horizontal: 'left'
        }
      case 'bottom-right':
        return {
          vertical: 'bottom',
          horizontal: 'left'
        }
      case 'bottom':
        return {
          vertical: 'bottom',
          horizontal: 'center'
        }
      case 'top-left':
        return {
          vertical: 'top',
          horizontal: 'left'
        }
      case 'top-right':
        return {
          vertical: 'top',
          horizontal: 'left'
        }
      case 'top':
        return {
          vertical: 'top',
          horizontal: 'center'
        }
      default:
        return {
          vertical: 'bottom',
          horizontal: 'center'
        }
    }
  }

  return (
    <Snackbar
      anchorOrigin={mapPositionToAnchorOrigin(position)}
      open={isOpen}
      autoHideDuration={duration}
      onClose={closeSnackbar}
    >
      <JoySnackbarContent
        onClose={closeSnackbar}
        variant={variant || 'success'}
        message={message}
      />
    </Snackbar>
  )
}
