import config from './config'
import { createTheme } from '@material-ui/core/styles'

const baseTheme = {
  palette: {
    primary: {
      light: '#2994FF',
      main: '#2d54e8',
      dark: '#09319E',
      contrastText: '#fff',
      error: '#DB1544',
      hover: '#394ddc',
      banner: '#EF8552'
    },
    secondary: {
      main: '#ffffff',
      hover: '#EDEDED'
    },
    success: {
      // not sure which of these is the official 'success color' as both are in the app
      main: '#07CC6C',
      dark: '#0a7b54'
      // main: '#3bab6a'
    },
    error: {
      main: '#E02030'
    },
    disabled: {
      main: '#4a4a4a'
    },
    white: {
      main: '#ffffff'
    },
    text: {
      contrastOnWhite: '#757575',
      contrastOnLightGray: '#6e6e6e',
      contrastOnGray: '#616161',
      contrastOnDarkGray: '#5c6366'
    },
    overdue: {
      main: '#e07800'
    },
    trends: {
      positive: '#2e8552',
      negative: '#e02030'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
}
const theme = createTheme(config.theme || baseTheme) // use Material UI theme structure

export default theme
