// TertiaryButton has the SecondaryButton look by default, and the PrimaryButton look on hover
import React from 'react'
import { SecondaryButton } from '../'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  base: {
    width: 260,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  }
}))

export default function TertiaryButton(props) {
  const classes = useStyles()
  return <SecondaryButton {...props} className={classes.base} />
}
