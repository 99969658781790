import React from 'react'
import { Box, Grid, GridItem, Select, Button, useToast, IconButton } from '@chakra-ui/react'
import { TextField } from '@blueprinthq/joy'
import { Formik, Form, Field } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'

import { endpoints } from '@api'
import { SupportResource } from './safety-net-editor'

const defaultResource: SupportResource = {
  id: undefined,
  type: '',
  displayText: '',
  value: ''
}

const schema = Yup.object().shape({
  type: Yup.string().required('Resource Name is required'),
  displayText: Yup.string().required(),
  value: Yup.string().required('Resource Value is required')
})

export const ResourceForm = ({
  onSave,
  clinicId,
  onCancel
}: {
  onSave: Function
  clinicId: string,
  onCancel: Function
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutate: saveResource }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.createSupportResource.request,
    {
      onSuccess: () => {
        onSave()
        queryClient.invalidateQueries(
          endpoints.getClinicSafetyNetResources.getCacheId()
        )
        toast({
          description: 'Resource created!',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  return (
    <Formik
      initialValues={defaultResource}
      validationSchema={schema}
      onSubmit={values => saveResource({ clinicId, resource: values })}
    >
      {({ errors }) => (
        <Form>
          <Grid templateColumns="repeat(18, 1fr)" gap={3} w="100%" mb="xsmall">
            <GridItem colSpan={6}>
              <Field name="displayText">
                {({ field }: { field: { value: string } }) => (
                  <TextField
                    {...field}
                    label=""
                    value={field.value}
                    placeholder="Resource name"
                    borderColor={errors.type ? 'error' : 'light_gray'}
                  />
                )}
              </Field>
            </GridItem>
            <GridItem colSpan={3}>
              <Field name="type">
                {({ field }: { field: { value: string } }) => (
                  <Select
                    {...field}
                    value={field.value}
                    borderColor={errors.type ? 'error' : 'light_gray'}
                    size="lg"
                    fontSize="md"
                  >
                    <option />
                    <option value="sms">Text</option>
                    <option value="call">Call</option>
                    <option value="web">Web</option>
                  </Select>
                )}
              </Field>
            </GridItem>
            <GridItem colSpan={8}>
              <Field name="value">
                {({ field }: { field: { value: string } }) => (
                  <TextField
                    {...field}
                    label=""
                    value={field.value}
                    placeholder="Resource value"
                    borderColor={errors.value ? 'error' : 'light_gray'}
                  />
                )}
              </Field>
            </GridItem>
            <GridItem colSpan={1}>
              {/* This makes the resource form fields line up with the resources above */}
              <IconButton aria-label='blank' background={'white'} _hover={{ cursor: 'unset' }}></IconButton>
            </GridItem>
            <GridItem colSpan={17}>
              <Box float="right">
                <Button variant="outline" onClick={() => { onCancel() }}>Cancel</Button>
                <Button type="submit">Save</Button>
              </Box>
            </GridItem>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
