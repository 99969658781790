import React, { useCallback } from 'react'
import { Dialog } from '@material-ui/core'
import { Box } from '@chakra-ui/react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { Plans } from './plans'

export const ChoosePlanModal = () => {
  const { isOpen } = useStoreState(state => state.modals.choosePlan)
  const handleCloseModal = useStoreActions(
    actions => actions.modals.choosePlan.closeModal
  )

  const handleCloseWrapper = useCallback(() => {
    handleCloseModal()
  }, [handleCloseModal])

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleCloseWrapper()}
      maxWidth={'lg'}
      fullWidth={true}
    >
      <Box padding="24px">
        <Plans />
      </Box>
    </Dialog>
  )
}

export const ChoosePlanModalConnected = () => {
  const user = useStoreState(state => state.auth.user)
  const { isOpen } = useStoreState(state => state.modals.choosePlan)
  const handleCloseModal = useStoreActions(
    actions => actions.modals.choosePlan.closeModal
  )

  return user ? (
    <ChoosePlanModal isOpen={isOpen} handleCloseModal={handleCloseModal} />
  ) : null
}
