import React, { useState, useCallback } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { TransferPatientModalContent } from './components'
import { Loading } from '../../components'
import { endpoints } from '../../api'
import { useStyles } from './style'
import { usePermissions } from '../../hooks'

export const TransferPatientModal = ({
  patientId,
  isOpen,
  handleCloseModal
}) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const classes = useStyles()
  const { hasPermission } = usePermissions()
  const user = useStoreState(state => state.auth.user)
  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const [selectedClinician, setSelectedClinician] = useState(null)
  const [displayError, setDisplayError] = useState(null)

  const { isLoading: isPatientLoading, data: patient } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), patientId],
    () => endpoints.getClinicianUserAccount.request({ id: patientId }),
    {
      enabled: isOpen && !!patientId
    }
  )

  const { isLoading: isClinicianListLoading, data: clinicianList } = useQuery(
    [endpoints.getPatientClinicianList.getCacheId(), patientId],
    () =>
      endpoints.getPatientClinicianList.request({
        patientId: patientId
      }),
    {
      enabled: isOpen,
      initialData: []
    }
  )

  const closeAndResetModal = useCallback(() => {
    handleCloseModal()
    setSelectedClinician(null)
    reset()
  })

  const goBack = useCallback(() => {
    history.replace({
      ...history.location,
      pathname: '/patients/active'
    })
  }, [history])

  const {
    mutateAsync: executeTransferPatient,
    isSuccess: isTransferSuccess,
    isLoading: isTransferLoading,
    reset
  } = useMutation(endpoints.postTransferPatientToClinician.request, {
    onError: () => {
      setDisplayError(
        'There was an error processing your transfer request. Please refresh the page and try again.'
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(endpoints.getPatientList.getCacheId())

      setSnackbarMessage({
        variant: 'success',
        message: `${patient.first_name} ${patient.last_name} has been transferred to ${selectedClinician.first_name} ${selectedClinician.last_name}`
      })

      if (hasPermission('update:clinic:activeclients:profile')) {
        closeAndResetModal()
        await queryClient.invalidateQueries(
          endpoints.getPatientClinicianList.getCacheId()
        )
      } else {
        closeAndResetModal()
        goBack()
      }
    }
  })

  const submit = useCallback(async () => {
    if (!selectedClinician) {
      setDisplayError('Please select a clinician')
      return
    }

    const data = {
      clinician_id_to_assign: selectedClinician.id,
      isClinician:
        user.roles.find(role => role.name === 'clinician') &&
        user.roles.length === 1
    }

    await executeTransferPatient({ data, clientId: patient.id })
    setSelectedClinician(null)
  }, [patient, user, selectedClinician, executeTransferPatient])

  const handleSelectClinician = useCallback(
    clinician => {
      setSelectedClinician(clinician)
    },
    [setSelectedClinician]
  )

  const handleCloseWrapper = useCallback(() => {
    if (hasPermission('update:clinic:activeclients:profile')) {
      closeAndResetModal()
    } else {
      goBack()
    }
  }, [isTransferSuccess, user, closeAndResetModal, goBack])

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      maxWidth={'xs'}
      fullWidth={true}
    >
      {isClinicianListLoading || isPatientLoading || isTransferLoading ? (
        <div style={{ height: '256px' }}>
          <Loading />
        </div>
      ) : (
        <div>
          <DialogTitle className={classes.centerText}>
            Transfer Client
          </DialogTitle>
          <DialogContent className={classes.contents}>
            <TransferPatientModalContent
              clinicianList={clinicianList}
              selectedClinician={selectedClinician}
              handleSelectClinician={handleSelectClinician}
              submit={submit}
              handleCloseModal={handleCloseWrapper}
              error={displayError}
            />
          </DialogContent>
        </div>
      )}
    </Dialog>
  )
}

export const TransferPatientModalConnected = () => {
  const user = useStoreState(state => state.auth.user)
  const { isOpen, patientId } = useStoreState(
    state => state.modals.transferPatient
  )
  const handleCloseModal = useStoreActions(
    actions => actions.modals.transferPatient.closeModal
  )

  return patientId && user ? (
    <TransferPatientModal
      isOpen={isOpen}
      patientId={patientId}
      handleCloseModal={handleCloseModal}
    />
  ) : null
}
