import { useEffect, useState, useRef } from 'react'
import { useStoreState } from 'easy-peasy'
import flagsmith from 'flagsmith'
import { DateTime } from 'luxon'
import { cloudinaryAssetToUrl } from '@utilities'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import * as clinicianTracking from '../lib/clinician-tracking'

export function useUserServices() {
  const { isAuthenticated, user } = useStoreState(state => state.auth)
  const [flagsmithFlagsLoaded, setFlagsmithFlagsLoaded] = useState(false)
  const componentIsMounted = useRef(true)

  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, [])

  useEffect(async () => {
    if (user && isAuthenticated) {
      datadogLogs.setUser(user)
      datadogRum.setUser(user)
      try {
        await flagsmith.identify(user.id).then(async () => {
          try {
            flagsmith.init({
              cacheFlags: false,
              environmentID: process.env.REACT_APP_BULLET_TRAIN_ENV_ID,
              // Occurs whenever flags are changed
              onChange: () => {
                // Need to verify component is mounted when changing state in async function within a useEffect hook
                if (componentIsMounted.current) {
                  setFlagsmithFlagsLoaded(true)
                }
              },

              onError: error => {
                if (error.detail)
                  console.error(`Flagsmith Error: ${error.detail}`)
              }
            })

            await flagsmith.setTraits({
              user_id: user.id,
              clinic_id: user.clinic_id,
              user_role: user.user_role,
              full_name: `${user.first_name} ${user.last_name}`,
              organization_id: user.clinic.organization_id,
              organization_created_at: parseInt(
                DateTime.fromISO(user.clinic.organization.created_at).toFormat(
                  'X'
                )
              )
            })
          } catch (error) {
            datadogLogs.logger.error('error_flagsmith_init', {}, error)
          }
        })
      } catch (error) {
        datadogLogs.logger.error('error_flagsmith_identify', {}.error)
      }

      // Intercom
      if (
        window.Intercom &&
        process.env.REACT_APP_HIDE_INTERCOM !== 'true' &&
        process.env.REACT_APP_ENV !== 'development'
      ) {
        // prettier-ignore
        window.Intercom('update', {
          app_id: process.env.REACT_APP_INTERCOM_KEY,
          user_id: user.id,
          email: user.email,
          phone: user.phone_number,
          name: user.first_name + ' ' + user.last_name,
          created_at: user.created_at,
          // This is technically an attribute of the clinic (Company in Intercom-speak)
          // but it is already in use on People objects in Intercom and used in segments
          // to filter out clinicians from certain organizations, like Tempus. So we will
          // leave this on the user for now, but maybe someday move it to the Company
          // if we rebuild the segments in Intercom.
          "organization_id": user.clinic.organization_id,
          // All users of the clinician app are Staff for Intercom purposes.
          "blueprint_user_type": 'staff',
          // The permissions attribute of Intercom needs to be a single string.
          "permissions": user.roles.map(r => r.name).join('|'),
          initial_product: user.clinic.initial_product,
          trial_type: user.clinic.trial_type,
          license_level: user.license_level,
          clinic_id: user.clinic.id,
          hubspot_bridge_id: user.clinic.hubspot_bridge_id,
          persona: user.persona,
          number_of_clinicians: user.number_of_clinicians,
          company: {
            company_id: user.clinic_id,
            name: user.clinic.name,
            created_at: user.clinic.created_at,
            "clinic_display_id": user.clinic.display_id,
            "organization_id": user.clinic.organization_id,
            "has_pto": user.clinic.has_pto,
            "contract_type": user.clinic.contract_type
          }
        })
      }

      // Mixpanel
      clinicianTracking.identify(user.id)

      clinicianTracking.registerProperties({
        email: user.email,
        userRole: user.user_role,
        clinicId: user.clinic_id,
        clinicName: user.clinic_name,
        organization_id: user.organization_id,
        state: user.state,
        clinic_is_archived: user.clinic_is_archived
      })

      // Segment user identification
      if (window.analytics) {
        window.analytics.identify(user.id, {
          email: user.email,
          user_role: user.user_role,
          clinic_id: user.clinic_id,
          clinic_name: user.clinic_name,
          organization_id: user.organization_id,
          sate: user.state,
          clinic_is_archived: user.clinic_is_archived
        })
      }

      // Pendo user identification
      if (window.pendo) {
        window.pendo.initialize({
          visitor: {
            // Inidividual user
            id: user.id,
            environment: process.env.REACT_APP_ENV,
            user_type: 'staff', // Only 'staff' in client portal.
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            roles: user.roles ? user.roles.map(r => r.name) : [],
            license_level: user.license_level,
            practicing_type: user.practicing_type,
            ehr_identifier: user.ehr_identifier,
            persona: user.persona
          },

          account: {
            // User's Clinic
            // Attempt to fall back to clinic_id.
            id: user.clinic ? user.clinic.id : user.clinic_id,
            environment: process.env.REACT_APP_ENV,
            name: user.clinic ? user.clinic.name : null,
            organization_id: user.clinic ? user.clinic.organization_id : null,
            clinic_is_archived: user.clinic ? user.clinic.is_archived : null,
            contract_type: user.clinic ? user.clinic.contract_type : null,
            sales_rep: user.clinic ? user.clinic.sales_rep : null,
            initial_product: user.clinic.initial_product,
            trial_type: user.clinic.trial_type,
            number_of_clinicians: user.clinic.number_of_clinicians,
            ehr_identifier: user.clinic.ehr_identifier,
            total_eligible_patients: user.clinic
              ? user.clinic.total_eligible_patients
              : null
          }
        })
      }

      // Canny user identification
      if (window.Canny) {
        window.Canny('identify', {
          appID: process.env.REACT_APP_CANNY_APP_ID,
          user: {
            avatarURL: cloudinaryAssetToUrl(user.avatar),
            companies: [
              {
                created: new Date(user.clinic.created_at).toISOString(),
                id: user.clinic.id,
                name: user.clinic.name
              }
            ],
            created: new Date(user.created_at).toISOString(),
            email: user.email,
            id: user.id,
            name: user.first_name + ' ' + user.last_name
          }
        })
      }
    }
  }, [user, isAuthenticated])

  return {
    flagsmithFlagsLoaded
  }
}
