import { componentConfig } from './components'

export const baseConfig = {
  appName: 'Blueprint',
  allowedDomains: ['form.jotform.com'],
  mobileAppName: 'Blueprint',
  mobileAppInviteURL: `${process.env.REACT_APP_CLIENT_APP_URL}/app-invite`,
  showLinkToSignUp: false,
  termsAndConditionsURL: `${process.env.REACT_APP_WWW_URL}/platform-services-agreement`,
  privacyPolicyURL: `${process.env.REACT_APP_WWW_URL}/privacy`,
  blurredImageURLs: {
    engagement:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605042795/Blurred/Engagement_blur.png',
    lifestyle:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605042794/Blurred/Lifestyle_blur.png',
    topSymptoms:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605042793/Blurred/Top_symptoms_blur.png',
    worksheets:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605042878/Blurred/Worksheets_blur.png',
    outcomeMeasures:
      'https://res.cloudinary.com/hellojoy/image/upload/v1625243896/Blurred/Outcome_measures_blue3.png'
  },
  helpEmail: process.env.REACT_APP_HELP_EMAIL,
  components: componentConfig,
  baaURL: `${process.env.REACT_APP_WWW_URL}/baa`
}
