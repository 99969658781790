import React from 'react'

const SessionAgenda = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M11.5 7H17.5V9H11.5V7ZM11.5 11H17.5V13H11.5V11ZM11.5 15H17.5V17H11.5V15ZM7.5 7H9.5V9H7.5V7ZM7.5 11H9.5V13H7.5V11ZM7.5 15H9.5V17H7.5V15ZM20.6 3H4.4C3.9 3 3.5 3.4 3.5 3.9V20.1C3.5 20.5 3.9 21 4.4 21H20.6C21 21 21.5 20.5 21.5 20.1V3.9C21.5 3.4 21 3 20.6 3ZM19.5 19H5.5V5H19.5V19Z"
      fill={props.fill || '#2D54E8'}
    />
  </svg>
)

export default SessionAgenda
