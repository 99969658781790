import React from 'react'

const Clock = ({}) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 1.08334H9.75V3.25H16.25V1.08334ZM11.9167 15.1667H14.0833V8.66667H11.9167V15.1667ZM20.6158 8.00584L22.1542 6.4675C21.6883 5.915 21.1792 5.395 20.6267 4.94L19.0883 6.47834C17.4092 5.135 15.2967 4.33334 13 4.33334C7.61583 4.33334 3.25 8.69917 3.25 14.0833C3.25 19.4675 7.605 23.8333 13 23.8333C18.395 23.8333 22.75 19.4675 22.75 14.0833C22.75 11.7867 21.9483 9.67417 20.6158 8.00584ZM13 21.6667C8.8075 21.6667 5.41667 18.2758 5.41667 14.0833C5.41667 9.89084 8.8075 6.5 13 6.5C17.1925 6.5 20.5833 9.89084 20.5833 14.0833C20.5833 18.2758 17.1925 21.6667 13 21.6667Z"
        fill="#2D54E8"
      />
    </svg>
  )
}

export default Clock
