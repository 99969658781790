import React, { useRef } from 'react'
import {
  Box,
  Avatar,
  Menu,
  MenuList,
  MenuButton,
  MenuItem
} from '@chakra-ui/react'
import { Button, IconButton, PhotoAddIcon } from '@blueprinthq/joy'
import Editor from '@containers/avatar-editor'

const AVATAR_SIZE = 128

export const AvatarEditor = ({ user }) => {
  const menuButtonRef = useRef()

  const onClickWholeAvatar = () => {
    menuButtonRef.current.click()
  }

  return (
    <Box>
      <Box position="relative" width={AVATAR_SIZE} height={AVATAR_SIZE}>
        <Editor user={user}>
          {({ isLoading, avatarImg, onAddFile, onRemoveAvatar }) => (
            <>
              <Button
                onClick={onClickWholeAvatar}
                isLoading={isLoading}
                width="100%"
                height="100%"
                variant="ghost"
                padding="0px"
                paddingInline="0px"
                borderRadius="50%"
              >
                <Avatar
                  src={avatarImg}
                  name={`${user.first_name} ${user.last_name}`}
                  bg="pale_gray"
                  size="2xl"
                  padding="0px"
                  paddingInline="0px"
                  fontWeight="bold"
                />
              </Button>
              <Box position="absolute" right="0px" bottom="0px">
                <Menu>
                  <MenuButton
                    ref={menuButtonRef}
                    as={IconButton}
                    icon={<PhotoAddIcon fill="black" />}
                    padding="0px"
                    paddingInline="0px"
                    margin="0px"
                    size="sm"
                    shadow="md"
                    background="white"
                  />
                  <MenuList zIndex={10000}>
                    <MenuItem onClick={onAddFile}>
                      {avatarImg ? 'Replace' : 'Add'} Photo
                    </MenuItem>
                    <MenuItem
                      isDisabled={!avatarImg}
                      onClick={onRemoveAvatar}
                      color="severe"
                    >
                      Delete Photo
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </>
          )}
        </Editor>
      </Box>
    </Box>
  )
}
