import React, { useState, useEffect } from 'react'
import { Stack, HStack, Text, Skeleton } from '@chakra-ui/react'
import { useAssistPanelContext } from '../../context'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { useTreatmentPlan, useExperienceManager } from '@hooks'
import { useRouteMatch } from 'react-router-dom'

import {
  useSessionControllerGetSuggestedContent,
  useSessionControllerGetDemoSuggestedContent
} from '~/clinician-api'
import ContentSuggestionCard from '@handlers/sessions/components/shared/assist/assessments/suggestion-card'
import { Pagination } from '@handlers/sessions/components/shared/pagination'
import { buildAssessmentPreviewUrl } from '@utilities'

const Suggestions = () => {
  const [showDemoLoading, setShowDemoLoading] = useState(true)
  const { client } = useTreatmentPlan()
  let match: any = useRouteMatch('/patient/:patientId/session/:sessionId')
  const [suggestedContentIndex, setSuggestedContentIndex] = useState(0)
  const { setOverlayPanel } = useAssistPanelContext()

  const lastSessionQuery: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId()],
    async () => {
      return await endpoints.getClinicianClientLastSession.request({
        id: client.id
      })
    }
  )

  const sessionId = lastSessionQuery?.data?.id

  const { isDemoClinician } = useExperienceManager()
  const showDemoExperience = client.is_demo && isDemoClinician

  const suggestionsQuery = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: (data: any) => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      },
      enabled: !!sessionId && !showDemoExperience
    }
  })

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId || match.params.sessionId,
    { demoContext: 'in-session' },
    {
      query: {
        enabled: showDemoExperience
      }
    }
  )

  useEffect(() => {
    if (showDemoExperience) {
      setTimeout(() => {
        setShowDemoLoading(false)
      }, 1000)
    } else {
      setShowDemoLoading(false)
    }
  }, [showDemoExperience])

  const suggestion = showDemoExperience
    ? demoData?.suggestedAssessments[suggestedContentIndex]
    : suggestionsQuery?.data?.suggestedAssessments?.[suggestedContentIndex]

  if (suggestionsQuery.isLoading || showDemoLoading) {
    return (
      <Stack>
        <HStack justifyContent="space-between">
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" width="100px" />
        </HStack>
        <Skeleton height="113px" borderRadius="8px" />
      </Stack>
    )
  }

  if (!suggestion) {
    return (
      <Stack spacing="0" gap="8px">
        <HStack justifyContent="space-between">
          <Text fontWeight="bold">Suggestions</Text>
        </HStack>
        <HStack
          spacing="0"
          p="16px"
          gap="12px"
          border="1px solid"
          borderColor="pale_gray"
          borderRadius="8px"
        >
          <Text>No suggestions available.</Text>
        </HStack>
      </Stack>
    )
  }

  const count = showDemoExperience
    ? demoData?.suggestedAssessments?.length
    : suggestionsQuery?.data?.suggestedAssessments?.length || 0
  const onPrevious = () => setSuggestedContentIndex(prev => prev - 1)
  const onNext = () => setSuggestedContentIndex(prev => prev + 1)

  const handleAssign = () => {
    if (client.enrollment_timestamp) {
      if (suggestion) {
        setOverlayPanel('ASSIGN_ASSESSMENTS', {
          assessment: suggestion,
          sessionId
        })
      }
    } else {
      setOverlayPanel('ENROLL_CLIENT', {
        assessment: suggestion,
        sessionId
      })
    }
  }

  return (
    <Stack spacing="0" gap="8px">
      <HStack justifyContent="space-between">
        <Text fontWeight="bold">Suggestions</Text>
        <Pagination
          current={suggestedContentIndex + 1}
          total={count}
          onNext={onNext}
          onPrev={onPrevious}
        />
      </HStack>
      <ContentSuggestionCard
        isDemo={showDemoExperience}
        type="assessment"
        suggestion={{ ...suggestion, name: suggestion.assessmentName }}
        sessionId={sessionId}
        onAssign={handleAssign}
        previewUrl={buildAssessmentPreviewUrl(suggestion.assessmentId)}
      />
    </Stack>
  )
}

export default Suggestions
