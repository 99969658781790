import React from 'react'
import { Divider, Text, Box } from '@chakra-ui/react'
import Loading from '@components/loading'
import _ from 'lodash'
import moment from 'moment'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'

import { SessionListItem } from '@components'
import { Session } from './session'
import { EmptyState } from './empty-state'

export const SessionContainer = ({ isLoading, data, refetchSessions }) => {
  if (isLoading) {
    return <Loading />
  }

  const isDeleteSessionsEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.DELETE_SESSIONS
  )

  const groupedSessions = _.groupBy(data.nodes, s =>
    moment(s.isRecordingInProgress ? s.startedAt : s.completedAt)
      .local()
      .format('YYYY-MM-DD')
  )

  const dates = Object.keys(groupedSessions)

  return dates.length === 0 ? (
    <Box>
      <Text mb={4}>
        Today
        <Text as="span" ml={1} color="#A1A1A1">
          {moment()
            .local()
            .format('MMM D')}
        </Text>
      </Text>
      <EmptyState />
    </Box>
  ) : (
    dates.map((date, idx) => {
      const sessions = groupedSessions[date]
      return (
        <Box key={idx} mb={8}>
          <Text mb={4}>
            {moment()
              .local()
              .format('MM-DD-YY') ===
            moment(date)
              .local()
              .format('MM-DD-YY')
              ? 'Today'
              : moment(date)
                  .local()
                  .format('dddd')}
            <Text as="span" ml={1} color="#A1A1A1">
              {moment(date)
                .local()
                .format('MMM D')}
            </Text>
          </Text>
          <Box
            border="1px solid"
            borderColor="pale_gray"
            borderRadius="8px"
            overflow="hidden"
          >
            {sessions.map((session, i) => {
              const isLast = i === sessions.length - 1
              return (
                <React.Fragment key={session.id}>
                  {isDeleteSessionsEnabled ? (
                    <SessionListItem
                      sessionId={session.id}
                      patientId={session.patientId}
                      header={session.client.fullName}
                      startedAt={moment(
                        session.recordingStartedAt || session.startedAt
                      )
                        .local()
                        .format()}
                      completedAt={moment(session.completedAt)
                        .local()
                        .format()}
                      hasAudio={session.hasAudio}
                      isUpload={session.isUpload}
                      clinicianName={session.clinician.fullName}
                      inProgress={session.isRecordingInProgress}
                      isDictation={session.isDictation}
                      onDelete={refetchSessions}
                      showMenu={false}
                    />
                  ) : (
                    <Session
                      sessionId={session.id}
                      patientId={session.patientId}
                      fullName={session.client.fullName}
                      startedAt={moment(
                        session.recordingStartedAt || session.startedAt
                      )
                        .local()
                        .format()}
                      completedAt={moment(session.completedAt)
                        .local()
                        .format()}
                      hasAudio={session.hasAudio}
                      isUpload={session.isUpload}
                      clinicianName={session.clinician.fullName}
                      inProgress={session.isRecordingInProgress}
                      isDictation={session.isDictation}
                    />
                  )}

                  {!isLast && <Divider />}
                </React.Fragment>
              )
            })}
          </Box>
        </Box>
      )
    })
  )
}
