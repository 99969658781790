import React from 'react'

const PoweredByBp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="187"
      height="20"
      viewBox="0 0 187 20"
      fill="none"
    >
      <path
        d="M0.263672 15V3.576H3.92767C7.39967 3.576 8.80767 4.392 8.80767 7.32C8.80767 10.264 7.41567 11.08 3.92767 11.08H1.73567V15H0.263672ZM1.73567 4.856V9.768H4.15167C6.07167 9.768 7.30367 9.496 7.30367 7.32C7.30367 5.128 6.07167 4.856 4.15167 4.856H1.73567Z"
        fill="#282828"
      />
      <path
        d="M17.6545 10.728C17.6545 13.528 16.7425 15.208 13.7505 15.208C10.7425 15.208 9.84655 13.528 9.84655 10.728C9.84655 7.912 10.7425 6.216 13.7505 6.2C16.7425 6.184 17.6545 7.912 17.6545 10.728ZM11.3185 10.728C11.3185 12.92 11.8945 13.992 13.7505 13.992C15.5905 13.992 16.1665 12.92 16.1665 10.728C16.1665 8.52 15.5905 7.4 13.7505 7.4C11.8945 7.4 11.3185 8.52 11.3185 10.728Z"
        fill="#282828"
      />
      <path
        d="M24.7449 7.832L22.7449 15H21.1129L18.3929 6.392H19.9449L21.9129 13.592L23.9129 6.392H25.5929L27.6889 13.624L29.5929 6.392H31.1289L28.5049 15H26.8409L24.7449 7.832Z"
        fill="#282828"
      />
      <path
        d="M39.541 12.392C39.525 13.416 38.901 15.208 35.781 15.208C32.869 15.208 31.909 13.528 31.909 10.728C31.909 7.912 32.869 6.216 35.781 6.216C38.565 6.216 39.605 7.656 39.605 11.08H33.381C33.445 13.08 34.117 13.976 35.781 13.976C37.605 13.976 38.085 12.968 38.101 12.392H39.541ZM35.781 7.384C34.245 7.384 33.557 8.2 33.413 9.88H38.085C37.877 8.072 37.157 7.384 35.781 7.384Z"
        fill="#282828"
      />
      <path
        d="M41.5758 15V8.856L41.4158 6.392H42.6638L42.8718 7.976C43.1438 7.368 43.9438 6.216 45.9758 6.216V7.56C43.7198 7.56 43.1438 9.32 43.0158 9.832V15H41.5758Z"
        fill="#282828"
      />
      <path
        d="M54.4004 12.392C54.3844 13.416 53.7604 15.208 50.6404 15.208C47.7284 15.208 46.7684 13.528 46.7684 10.728C46.7684 7.912 47.7284 6.216 50.6404 6.216C53.4244 6.216 54.4644 7.656 54.4644 11.08H48.2404C48.3044 13.08 48.9764 13.976 50.6404 13.976C52.4644 13.976 52.9444 12.968 52.9604 12.392H54.4004ZM50.6404 7.384C49.1044 7.384 48.4164 8.2 48.2724 9.88H52.9444C52.7364 8.072 52.0164 7.384 50.6404 7.384Z"
        fill="#282828"
      />
      <path
        d="M62.4672 15L62.2752 13.864C61.9232 14.424 61.1552 15.16 59.3792 15.16C56.9632 15.16 55.9872 13.48 55.9872 10.744C55.9872 7.976 56.9472 6.232 59.3312 6.232C60.8352 6.232 61.7152 6.952 62.1152 7.4V2.952H63.5552V12.696L63.7152 15H62.4672ZM59.6832 13.848C61.0272 13.848 61.7312 13.192 62.1152 12.6V8.776C61.7792 8.312 61.0272 7.528 59.6832 7.528C58.0992 7.528 57.4272 8.696 57.4272 10.744C57.4272 12.76 58.0992 13.848 59.6832 13.848Z"
        fill="#282828"
      />
      <path
        d="M69.1658 15L69.3258 12.696V2.952H70.7498V7.4C71.1178 6.968 71.9818 6.232 73.5498 6.232C75.9338 6.232 76.8938 7.976 76.8938 10.744C76.8938 13.48 75.9018 15.16 73.5018 15.16C71.7578 15.16 70.9418 14.424 70.5738 13.864L70.3978 15H69.1658ZM73.1978 7.528C71.8698 7.528 71.0858 8.328 70.7498 8.792V12.6C71.1178 13.192 71.8218 13.848 73.1978 13.848C74.7658 13.848 75.4538 12.76 75.4538 10.744C75.4538 8.696 74.7658 7.528 73.1978 7.528Z"
        fill="#282828"
      />
      <path
        d="M80.095 18.6L81.279 15H80.655L77.487 6.392H79.1031L81.6311 13.944L84.111 6.392H85.6311L81.423 18.6H80.095Z"
        fill="#282828"
      />
      <path
        d="M92.0304 10.8957C91.9073 9.87397 91.8814 1.53938 91.8966 1.24081C91.9027 1.11928 91.9559 1.00675 92.0472 0.925731C92.1384 0.843211 92.257 0.8012 92.3802 0.807202C92.6342 0.819205 92.8304 1.03076 92.8197 1.28432C92.8076 1.53188 92.7133 9.56639 92.8486 10.7787C93.1133 13.1523 95.1709 14.8837 97.9281 14.4396C98.1912 14.3781 98.3539 14.117 98.2916 13.8575C98.2292 13.5979 97.9661 13.4389 97.7015 13.4989C97.4643 13.5544 97.2179 13.5829 96.9731 13.5829C95.1861 13.5634 94.0334 12.2161 93.8585 10.9602C93.7991 10.5611 93.7642 9.17629 93.7626 7.15529C94.6341 6.33458 95.7716 5.88297 96.9715 5.88297C97.1829 5.88297 97.3943 5.89647 97.6012 5.92498C97.7411 5.94299 97.8825 5.96849 98.0209 6C98.2825 6.05852 98.5471 5.89497 98.6079 5.63691C98.6688 5.37734 98.503 5.11778 98.2399 5.05776C98.0726 5.02026 97.9008 4.98875 97.7319 4.96624C97.481 4.93323 97.2255 4.91523 96.9715 4.91523C95.8112 4.91523 94.7086 5.25731 93.7642 5.90698C93.7673 5.33934 93.7679 4.51709 93.7684 3.70789C93.7691 2.56864 93.7699 1.45515 93.7779 1.11478C93.7779 0.499625 93.0418 0 92.3772 0H92.3133C91.6502 0 91.01 0.498124 91.01 1.10578L91.0085 1.16129C90.9735 2.37659 91.0541 10.4441 91.0541 10.4441C91.1332 12.123 91.6715 13.8755 93.4372 15.2213C93.5193 15.2843 93.6197 15.3128 93.7231 15.3128C93.8797 15.3128 94.0288 15.2378 94.12 15.1133C94.263 14.9182 94.2371 14.6542 94.0698 14.4891C92.9399 13.6669 92.2114 12.4051 92.0304 10.8957Z"
        fill="#2D54E8"
      />
      <path
        d="M100.751 6.43361H100.758C100.895 6.43511 101.023 6.48762 101.12 6.58365C102.209 7.66392 102.819 9.09978 102.836 10.6242C102.869 13.832 100.253 16.4696 97.002 16.5041C96.6613 16.5071 96.3222 16.4816 95.9891 16.4291C95.8538 16.4066 95.7367 16.3346 95.6561 16.2251C95.577 16.1155 95.545 15.982 95.5663 15.8485C95.6104 15.5739 95.872 15.3863 96.1534 15.4299C96.4286 15.4734 96.7115 15.4944 96.9913 15.4914C99.6786 15.4644 101.84 13.2843 101.811 10.6347C101.797 9.37434 101.294 8.18905 100.393 7.29633C100.194 7.09828 100.194 6.7772 100.395 6.58065C100.491 6.48762 100.617 6.43511 100.751 6.43361Z"
        fill="#2D54E8"
      />
      <path
        d="M99.5341 11.9805C99.7136 11.6294 99.817 11.2573 99.8444 10.8717C99.8489 10.8132 99.8489 10.7757 99.8489 10.7397L99.8504 10.7037C99.8504 10.6632 99.8504 10.6242 99.8489 10.5851V10.5581C99.8368 10.2701 99.7805 9.982 99.6816 9.70443C99.2877 8.60315 98.2673 7.84246 97.0826 7.76894H96.8894C96.8256 7.76894 96.7784 7.77044 96.7328 7.77345L96.6841 7.77645C96.6339 7.77945 96.5822 7.78545 96.5321 7.79145L96.5062 7.79445C96.4499 7.80195 96.3937 7.81095 96.3374 7.82146L96.2963 7.83046L96.275 7.83496C96.2385 7.84246 96.2036 7.84996 96.1686 7.85897C96.0849 7.87997 96.0028 7.90398 95.9192 7.93248C95.6713 8.01801 95.4371 8.13654 95.2242 8.28057C95.1238 8.34959 95.0021 8.3751 94.882 8.35259C94.7618 8.33158 94.6554 8.26557 94.5869 8.16504C94.5185 8.06452 94.4911 7.94449 94.5139 7.82596C94.5352 7.70593 94.6037 7.6039 94.704 7.53489C94.9839 7.34434 95.2896 7.1913 95.6135 7.07877C95.7002 7.04876 95.7869 7.02326 95.8751 6.99925L95.9207 6.98575C95.9861 6.96924 96.0332 6.95874 96.0819 6.94824L96.1595 6.93173C96.2355 6.91823 96.31 6.90623 96.3845 6.89573C96.386 6.89573 96.4119 6.89272 96.4119 6.89272C96.4818 6.88372 96.5503 6.87772 96.6187 6.87172L96.6796 6.86872C96.7389 6.86572 96.8012 6.86272 96.8621 6.86272L96.9016 6.86122H96.9275L97.0096 6.86272L97.1282 6.86722C99.0383 6.98725 100.587 8.47862 100.752 10.3376L100.755 10.3721L100.759 10.4167C100.761 10.449 100.763 10.4818 100.764 10.5146L100.769 10.6737V10.6977L100.758 10.9527L100.758 10.9574C100.754 11.0149 100.751 11.0544 100.746 11.0953L100.743 11.1193C100.64 12.0045 100.345 12.6737 99.6908 13.2948C99.6056 13.3758 99.4931 13.4209 99.3729 13.4209C99.2482 13.4209 99.1281 13.3698 99.0414 13.2813C98.8665 13.1028 98.871 12.8147 99.0535 12.6422C99.2893 12.4186 99.3759 12.2881 99.525 12.0045C99.5235 12.0045 99.528 11.9925 99.5341 11.9805Z"
        fill="#2D54E8"
      />
      <path
        d="M162.073 1.87247C162.015 1.6129 161.811 1.39685 161.552 1.32483C161.279 1.24831 161.002 1.29332 160.792 1.44936C160.597 1.5949 160.485 1.81845 160.485 2.06152C160.485 2.48762 160.845 2.83421 161.289 2.83421C161.531 2.83421 161.774 2.71868 161.927 2.53263C162.076 2.35259 162.127 2.11853 162.073 1.87247Z"
        fill="#2D54E8"
      />
      <path
        d="M105.67 1.89798C105.67 1.5964 105.92 1.35034 106.227 1.35034C106.533 1.35034 106.782 1.5964 106.782 1.89798V15.6714C106.782 15.973 106.534 16.2191 106.227 16.2191C105.92 16.2191 105.67 15.973 105.67 15.6714V1.89798Z"
        fill="#2D54E8"
      />
      <path
        d="M160.733 5.74644C160.733 5.44486 160.982 5.1988 161.289 5.1988C161.597 5.1988 161.846 5.44486 161.844 5.74794V15.6699C161.844 15.9715 161.595 16.2175 161.289 16.2175C160.982 16.2175 160.733 15.9715 160.733 15.6699V5.74644Z"
        fill="#2D54E8"
      />
      <path
        d="M120.184 5.2123H120.147C119.851 5.2123 119.609 5.45086 119.609 5.74343V12.7877C119.609 12.8792 119.583 12.9647 119.534 13.0368C118.708 14.2641 117.147 15.5739 115.235 15.5739C113.25 15.5739 111.918 14.1515 111.918 12.036V5.74193C111.918 5.44936 111.676 5.2108 111.38 5.2108H111.343C111.047 5.2108 110.805 5.44936 110.805 5.74193V12.0735C110.805 13.4749 111.238 14.6422 112.059 15.4494C112.805 16.183 113.852 16.5866 115.007 16.5866C117.35 16.5866 118.979 14.9797 119.63 14.201L119.645 15.6939C119.648 15.9835 119.889 16.219 120.184 16.219C120.48 16.219 120.722 15.9805 120.722 15.6879V5.74193C120.722 5.44936 120.48 5.2123 120.184 5.2123Z"
        fill="#2D54E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.564 4.88522H129.649C131.293 4.90472 132.811 5.56789 133.815 6.70367C134.764 7.77794 135.182 9.18679 134.993 10.6722C134.961 10.9227 134.745 11.1103 134.49 11.1103H124.884C124.989 12.4441 125.558 13.5529 126.531 14.3181C127.426 15.0233 128.645 15.4088 129.968 15.4088H130.022C131.914 15.3908 133.171 14.6317 133.894 13.9985C134 13.904 134.143 13.859 134.285 13.874C134.426 13.889 134.554 13.9625 134.637 14.075C134.792 14.2851 134.757 14.5731 134.558 14.7472C133.856 15.3578 132.335 16.3841 130.022 16.4036H129.97C128.125 16.4036 126.484 15.7899 125.344 14.6707C124.3 13.6489 123.748 12.2566 123.748 10.6452C123.748 8.99024 124.32 7.56039 125.401 6.50863C126.465 5.47487 127.978 4.88522 129.564 4.88522ZM124.884 10.1575H133.882C134.011 9.11477 133.697 8.10652 132.999 7.31433C132.204 6.4126 130.983 5.89047 129.649 5.87997C128.437 5.87397 127.299 6.26406 126.448 6.99024C125.54 7.76294 125 8.85671 124.884 10.1575Z"
        fill="#2D54E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.8 5.02776C141.473 5.02776 139.854 6.59565 139.321 7.1928L139.306 5.73744C139.303 5.44787 139.061 5.21231 138.768 5.21231C138.471 5.21231 138.229 5.44937 138.229 5.74194L138.21 19.4689C138.21 19.6114 138.266 19.7434 138.366 19.844C138.468 19.9445 138.604 20 138.746 20H138.76C139.057 20 139.299 19.7614 139.299 19.4689V14.5746C139.916 15.1658 141.443 16.3826 143.548 16.3826C144.944 16.3826 146.314 15.8365 147.306 14.8822C148.422 13.808 149.013 12.3136 149.013 10.5611C149.016 6.75919 146.312 5.02776 143.8 5.02776ZM143.428 15.3683C141.555 15.3683 140.138 14.2881 139.46 13.6444C139.373 13.5619 139.323 13.4434 139.323 13.3203V8.47412C139.323 8.36459 139.361 8.26107 139.431 8.18005C140.272 7.2048 141.679 6.04351 143.615 6.04351C144.786 6.04351 145.859 6.47562 146.638 7.25882C147.468 8.09303 147.906 9.24982 147.906 10.6047C147.905 13.3638 146.022 15.3683 143.428 15.3683Z"
        fill="#2D54E8"
      />
      <path
        d="M156.499 4.98725C156.771 4.98725 157.049 5.02475 157.325 5.09677C157.46 5.13128 157.571 5.2183 157.636 5.33833C157.705 5.46136 157.719 5.6069 157.676 5.73893C157.594 5.9925 157.319 6.14103 157.051 6.07352C156.856 6.0255 156.654 6.0015 156.435 6.0015C155.224 6.0015 154.114 6.83871 153.136 8.48762C153.098 8.55514 153.077 8.63015 153.077 8.70817V15.7119C153.077 15.991 152.846 16.219 152.563 16.219H152.479C152.197 16.219 151.965 15.991 151.965 15.7119V5.71793C151.965 5.43886 152.197 5.2108 152.479 5.2108H152.505C152.787 5.2108 153.016 5.43586 153.019 5.71343L153.035 7.29482C153.894 5.82595 155.15 4.98725 156.499 4.98725Z"
        fill="#2D54E8"
      />
      <path
        d="M171.442 4.94674C169.359 4.94674 167.798 6.4066 166.84 7.67142L166.825 5.74794C166.822 5.45236 166.577 5.2123 166.278 5.2123C165.975 5.2123 165.73 5.45536 165.73 5.75244V15.6789C165.73 15.976 165.976 16.2191 166.278 16.2191H166.294C166.597 16.2191 166.842 15.976 166.842 15.6789V9.02776C166.842 8.92873 166.872 8.83571 166.928 8.75769C168.276 6.90173 169.725 5.96099 171.237 5.96099C173.378 5.96099 174.762 7.47037 174.762 9.80645V15.6789C174.762 15.976 175.008 16.2191 175.31 16.2191H175.326C175.629 16.2191 175.874 15.976 175.874 15.6789V9.74494C175.872 6.82971 174.132 4.94674 171.442 4.94674Z"
        fill="#2D54E8"
      />
      <path
        d="M186.668 14.9272C186.801 14.9722 186.904 15.0668 186.959 15.1943C187.062 15.4313 186.962 15.7074 186.732 15.8229C185.932 16.2265 185.178 16.4231 184.43 16.4231H184.355C182.232 16.4006 180.859 14.9737 180.859 12.7892V6.20555H178.871C178.598 6.20555 178.374 5.98499 178.374 5.71492V5.70142C178.374 5.43135 178.598 5.2108 178.871 5.2108H180.859V2.33008C180.859 2.06001 181.083 1.83946 181.356 1.83946H181.473C181.747 1.83946 181.971 2.06001 181.971 2.33008V5.2108H185.513C185.786 5.2108 186.01 5.43135 186.01 5.70142V5.71492C186.01 5.98499 185.786 6.20555 185.513 6.20555H181.971V12.7472C181.971 13.9655 182.617 15.3983 184.439 15.4284C185.183 15.4419 185.838 15.1823 186.261 14.9587C186.386 14.8927 186.535 14.8807 186.668 14.9272Z"
        fill="#2D54E8"
      />
    </svg>
  )
}

export default PoweredByBp
