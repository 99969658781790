import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Text,
  Flex,
  ModalBody,
  ModalFooter,
  Input,
  useToast
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { standardErrorDescription } from '../../../../constants/Copy'

export default function BillingContactModal({
  isModalOpen,
  handleModal,
  selectedClinic,
  currentEmail
}) {
  const queryClient = useQueryClient()
  const toast = useToast()
  const [email, setEmail] = useState(currentEmail || '')
  const [loading, setLoading] = useState(false)

  const handleFormSubmit = async () => {
    setLoading(true)
    const data = {
      clinicId: selectedClinic.id,
      newEmail: email
    }
    await changeInvoice({
      data
    })
  }

  const { mutateAsync: changeInvoice, err } = useMutation(
    endpoints.updateClinicInvoice.request,
    {
      onSuccess() {
        queryClient.invalidateQueries([
          endpoints.getClinicBilling.getCacheId(),
          selectedClinic && selectedClinic.id
        ])
        toast({
          title: 'You have successfully updated who your invoices are sent to.',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        setLoading(false)
        handleModal()
      },
      onError() {
        toast({
          title: 'Oops, something went wrong.',
          description: standardErrorDescription(),
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        setLoading(false)
        console.error(err)
      }
    }
  )

  return (
    <>
      <Modal
        onClose={handleModal}
        size={'md'}
        isOpen={isModalOpen}
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'24px'}>Billing Email</ModalHeader>
          <ModalCloseButton
            h={'24px'}
            w={'24px'}
            top={'18px'}
            right={'18px'}
            fontWeight={'bold'}
          />
          <ModalBody pb={'16px'}>
            <Text fontSize={'13px'} lineHeight={'17.73px'}>
              The email address we'll use to send invoices, renewal reminders,
              and payment confirmations.
            </Text>
            <Input
              border={'1px solid #E4E5E6'}
              height={'56px'}
              placeholder={'Email address'}
              value={email}
              onChange={e => setEmail(e.target.value)}
              borderRadius={'4px'}
              mt={'16px'}
            />
          </ModalBody>
          <ModalFooter pt={'0px'} display="block">
            <Flex flex={1} justify={'center'} align={'center'}>
              <Button
                size="lg"
                backgroundColor="white"
                onClick={handleModal}
                isFullWidth
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                size="lg"
                isLoading={loading}
                loadingText="Loading.."
                disabled={loading}
                type="submit"
                onClick={handleFormSubmit}
                isFullWidth
              >
                Update
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
