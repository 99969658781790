import React, { useMemo } from 'react'
import { Box, Menu, MenuList, MenuItem, Checkbox } from '@chakra-ui/react'
import { Chip } from './chip'
import { keyBy } from 'lodash'
import { CustomMenuButton } from './filters'

const ageRangeOptions = [
  {
    label: '0-13 Years',
    value: '0-13'
  },
  {
    label: '14-17 Years',
    value: '14-17'
  },
  {
    label: '18-25 Years',
    value: '18-25'
  },
  {
    label: '26-35 Years',
    value: '26-35'
  },
  {
    label: '36-60 Years',
    value: '36-60'
  },
  {
    label: '61+ Years',
    value: '61-999'
  }
]

export const AgeRangeSelector = ({ ages, onChange }) => {
  const handleChange = evt => {
    const values = evt.target.checked
      ? [...ages, evt.target.value]
      : ages.filter(o => o !== evt.target.value)

    onChange(values)
  }

  const handleSelectAllToggle = () => {
    onChange([])
  }

  const handleClear = ageRange => {
    return () => onChange(ages.filter(range => range !== ageRange))
  }

  const keyedOptions = useMemo(() => keyBy(ageRangeOptions, 'value'), [])
  const isAllSelected = ages.length === 0
  const chips = (
    <>
      {ages.length ? (
        ages
          .sort()
          .map(ageRange => (
            <Chip
              key={ageRange}
              label={keyedOptions[ageRange].label}
              onClose={handleClear(ageRange)}
            />
          ))
      ) : (
        <Chip label="All" />
      )}
    </>
  )

  return (
    <Box>
      <Menu closeOnSelect={false}>
        <CustomMenuButton isMenu label="Ages" chips={chips} />
        <MenuList>
          <MenuItem>
            <Checkbox
              w="100%"
              key="all"
              value="all"
              isChecked={isAllSelected}
              onChange={handleSelectAllToggle}
            >
              All Ages
            </Checkbox>
          </MenuItem>
          {ageRangeOptions.map(option => (
            <MenuItem key={option.value}>
              <Checkbox
                w="100%"
                value={option.value}
                isChecked={ages.includes(option.value)}
                onChange={handleChange}
              >
                {option.label}
              </Checkbox>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}
