import React from 'react'

const Mobile = () => (
  <svg
    width="12px"
    height="21px"
    viewBox="0 0 12 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-18.000000, -31.000000)"
        fill="#4A4A4A"
        fillRule="nonzero"
      >
        <g>
          <g transform="translate(18.000000, 31.000000)">
            <path d="M9.75708423,0 L2.24173753,0 C1.00528249,0 0,0.997022255 0,2.22107532 L0,18.7793913 C0,20.0034444 1.00528249,21 2.24173753,21 L9.75731988,21 C10.9933036,21 12,20.0034444 12,18.7793913 L12,2.22107532 C11.9997644,0.997022255 10.993068,0 9.75708423,0 Z M4.88266599,0.710025444 L7.11639141,0.710025444 C7.36688726,0.710025444 7.56436188,0.951989422 7.56436188,1.26161932 C7.56436188,1.57124921 7.36688726,1.81344652 7.11639141,1.81344652 L4.88266599,1.81344652 C4.63240579,1.81344652 4.43422422,1.57124921 4.43422422,1.26161932 C4.43422422,0.951989422 4.63217014,0.710025444 4.88266599,0.710025444 Z M5.99870393,20.1973423 C5.36197789,20.1973423 4.84378375,19.684248 4.84378375,19.053555 C4.84378375,18.4230953 5.36197789,17.910001 5.99870393,17.910001 C6.63542996,17.910001 7.15315288,18.4230953 7.15315288,19.053555 C7.15338845,19.6840146 6.63542996,20.1973423 5.99870393,20.1973423 Z M10.763545,17.2398751 L1.23433419,17.2398751 L1.23433419,2.41287319 L10.763545,2.41287319 L10.763545,17.2398751 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default Mobile
