import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { HStack, Heading } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { useStoreActions } from 'easy-peasy'
import { DataTableList } from './components'
import { Button } from '@blueprinthq/joy'
import { Loading } from '../../components'
import { endpoints } from '../../api/index'
import { MeasureTypes } from '@constants/measureTypes'
import { useAssignCheckInsModal } from '@containers/assign-check-ins-modal'

export default function CheckinModuleSelector(props) {
  const { patient, user_id } = props

  const queryClient = useQueryClient()
  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const checkInsModal = useAssignCheckInsModal()

  const [symptomTrackers, setSymptomTrackers] = useState([])
  const [worksheets, setWorksheets] = useState([])

  const { isLoading: areCheckinModsLoading, data: allCheckinMods } = useQuery(
    [endpoints.getCheckInModulesForUser.getCacheId(), user_id],
    () => endpoints.getCheckInModulesForUser.request({ id: user_id })
  )

  const { mutateAsync: executeAssignCheckinModule } = useMutation(
    endpoints.postClinicianUserCheckinModulesAssign.request,
    {
      onError: () => {
        setSnackbarMessage({
          variant: 'error',
          message: 'There was an error editing modules'
        })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          endpoints.getCheckInModulesForUser.getCacheId(),
          user_id
        ])
        setSnackbarMessage({
          variant: 'success',
          message: 'Saved!'
        })
      }
    }
  )

  useEffect(() => {
    allCheckinMods &&
      setSymptomTrackers(allCheckinMods.filter(mod => mod.type === 'symptom'))
    allCheckinMods &&
      setWorksheets(
        allCheckinMods.filter(mod => mod.type === 'treatment_activity')
      )
  }, [allCheckinMods])

  const postCheckInData = (module_id, isChecked) => {
    const data = {
      new_value: isChecked,
      module_id
    }

    return executeAssignCheckinModule({ data, clientId: user_id })
  }

  const renderMeasures = () => {
    const { type } = props
    if (type === 'symptomTrackers') {
      const activeSymptomTrackers = symptomTrackers.filter(
        tracker => tracker.isActive
      )

      return (
        <>
          <HStack justify={'space-between'}>
            <Heading>Symptom Trackers</Heading>
            <Button
              onClick={() => {
                checkInsModal.open({
                  clinicId: patient.clinic_id,
                  patientId: patient.id,
                  type: MeasureTypes.SYMPTOM
                })
              }}
            >
              Assign Symptom Trackers
            </Button>
          </HStack>
          <DataTableList
            data={activeSymptomTrackers}
            label="Assigned"
            type="Symptom Trackers"
            column="Symptom"
            onChange={(id, val) => postCheckInData(id, val)}
          />
        </>
      )
    }
    if (type === 'worksheets') {
      const activeWorkSheets = worksheets.filter(tracker => tracker.isActive)

      return (
        <>
          <HStack justify={'space-between'}>
            <Heading>Worksheets</Heading>
            <Button
              onClick={() => {
                checkInsModal.open({
                  clinicId: patient.clinic_id,
                  patientId: patient.id,
                  type: MeasureTypes.WORKSHEET
                })
              }}
            >
              Assign Worksheets
            </Button>
          </HStack>
          <DataTableList
            data={activeWorkSheets}
            label="Assigned"
            type="Worksheets"
            column="Worksheet"
            onChange={(id, val) => postCheckInData(id, val)}
          />
        </>
      )
    }
  }

  if (areCheckinModsLoading) {
    return <Loading />
  }

  return <div>{renderMeasures()}</div>
}
