import React from 'react'
import {
  Text,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Flex,
  Box,
  HStack
} from '@chakra-ui/react'
// import { timeToRelativeOrDate } from '@utilities'

import { ArrowRightIcon } from '@blueprinthq/joy'

export const CompletedInterventions = () => {
  const completedInterventions = [
    { id: 1, createdAt: 'Nov 3, 2024', name: 'Leaves on a Stream' },
    { id: 2, createdAt: 'Oct 1, 2024', name: 'Challenging Unhelpful Thinking' },
    { id: 3, createdAt: 'Sept 9, 2024', name: 'Leaves on a Stream' }
  ]

  return (
    <Box>
      <Text fontWeight="bold" mb="xsmall">
        Completed
      </Text>
      <Box border="1px solid" borderColor="#E4E5E6" borderRadius="8px">
        {completedInterventions.length ? (
          <Table variant="unstyled">
            <Thead>
              <Tr borderBottom={'1px solid #E4E5E6'}>
                {['Date', 'Intervention'].map(header => (
                  <Th>
                    <Flex height="100%" alignItems="center">
                      <Text
                        fontSize="12px"
                        height="16px"
                        w="90px"
                        textTransform="none"
                        fontWeight="normal"
                      >
                        {header}
                      </Text>
                    </Flex>
                  </Th>
                ))}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {completedInterventions.map(ci => (
                <Tr
                  borderBottom={'1px solid #E4E5E6'}
                  key={ci.id}
                  _last={{ borderBottom: 'none' }}
                  _hover={{ bg: '#F7F8F9', cursor: 'pointer' }}
                >
                  <Td>
                    <Text>{ci.createdAt}</Text>
                  </Td>
                  <Td>
                    <Text>{ci.name}</Text>
                  </Td>
                  <Td textAlign="right">
                    <ArrowRightIcon />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <HStack spacing="0" p="16px">
            <Text>No completed interventions.</Text>
          </HStack>
        )}
      </Box>
    </Box>
  )
}
