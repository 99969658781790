import React, { useEffect } from 'react'
import {
  Switch,
  Redirect,
  useHistory,
  useParams,
  useRouteMatch,
  generatePath,
  useLocation,
  Route
} from 'react-router-dom'
import * as uuid from 'uuid'
import { useStoreState } from 'easy-peasy'

import {
  ClientListHandler,
  Login,
  SingleSignOn,
  SingleSignOnCallback,
  Patients,
  ForgotPassword,
  ForgotPasswordCode,
  PatientProfile,
  Logout,
  IntakeProfile,
  InsightsOutcomes,
  InsightsOutcomesV2,
  ClientSatisfaction,
  InsightsUtilization,
  PreEnrollProfile,
  AcceptInvite,
  UserSettings,
  ReportsClaims,
  PendingClientProfile,
  ClinicianDashboard,
  SessionDashboard,
  EnrollNewClient,
  Referral,
  Signup,
  SessionActivity,
  SessionNotes,
  InterventionSummary,
  InterventionForm,
  InterventionComplete,
  InsightsEHRIntegrationIssues,
  EHRAppointments,
  Upgrade,
  Delete,
  StartSession,
  Audit,
  ExtensionStartSession,
  ExtensionSessionNotes,
  WidgetStartSession,
  WidgetSessionNotes,
  ClientSessions,
  LibraryWorksheets,
  LibrarySymptomTrackers,
  CheckInEditor
} from '@handlers'
import {
  AppSnackbar,
  DeclineClientModalConnected,
  TransferPatientModalConnected,
  ManageAssignedCliniciansModalConnected,
  AdjustEnrollmentDateModalConnected,
  ContactInfoModalConnected,
  AssignMeasuresModalConnected,
  UpsertParticipantModalConnected,
  AssessmentResultModalConnected,
  ClientInterventionListModalConnected,
  InterventionListModalConnected,
  AssignWorksheetModalConnected,
  WorksheetResultModalConnected
} from '@containers'
import history from './history'
import { PrivateRoute, PublicRoute } from '@lib'
import { api } from '@api'
import {
  createClient,
  readClients,
  readOutcomes,
  readUtilization,
  readBilling,
  readEhrAppts,
  superAdmin
} from '@constants/permissions'
import { TABS as CLIENT_LIST_TABS } from '@constants/clientList'
import { WorkflowEditor } from '@handlers/settings/workflows'
import { WorkflowsContextProvider } from './context/workflows-context'

const Globals = () => (
  <>
    <ManageAssignedCliniciansModalConnected />
    <DeclineClientModalConnected />
    <TransferPatientModalConnected />
    <AdjustEnrollmentDateModalConnected />
    <ContactInfoModalConnected />
    <AssignMeasuresModalConnected />
    <UpsertParticipantModalConnected />
    <AssessmentResultModalConnected />
    <ClientInterventionListModalConnected />
    <InterventionListModalConnected />
    <AssignWorksheetModalConnected />
    <WorksheetResultModalConnected />
    <AppSnackbar />
  </>
)

function ScrollToTop() {
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [])

  return null
}

function IdParamDecoderRedirect({ children }) {
  const params = useParams()
  const location = useLocation()
  const match = useRouteMatch()
  const { id } = params
  if (uuid.validate(id)) {
    return <>{children}</>
  }
  const remainingPath = location.pathname.replace(match.url, '')
  const patientUUID = api.decode(id)
  const redirectLink = generatePath(match.path + remainingPath, {
    id: patientUUID
  })
  const redirectLinkWithQueryParams = redirectLink + location.search
  return <Redirect to={redirectLinkWithQueryParams} />
}

function BadClientListTypeRedirect({ children }) {
  const params = useParams()

  const { patientTypeOptions } = useStoreState(state => state.patientList)
  const option = patientTypeOptions.find(o => o.value === params.type)

  if (option) {
    return <>{children}</>
  }

  if (params.type.toLowerCase() === 'prospective')
    return <Redirect to={`/patients/${CLIENT_LIST_TABS.PENDING}`} />

  if (params.type.toLowerCase() === 'archived')
    return <Redirect to={`/patients/${CLIENT_LIST_TABS.DISCHARGED}`} />

  return <Redirect to={`/patients/${CLIENT_LIST_TABS.ACTIVE}`} />
}

const billingReportRoute = '/reports/claims'

const ConditionalPageWrapper = ({ children }) => {
  const location = useLocation()
  const isSessionNotesRoute =
    location.pathname.includes('/completed-session/') &&
    !location.pathname.includes('/extension')
  return <div className={isSessionNotesRoute ? '' : 'Page'}>{children}</div>
}

const Routes = () => {
  const location = useLocation()

  return (
    <>
      <ScrollToTop />
      <ConditionalPageWrapper>
        <Switch>
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/sso/callback" component={SingleSignOnCallback} />
          <PublicRoute path="/sso" component={SingleSignOn} />
          <PublicRoute path="/signup" component={Signup} />
          <PublicRoute path="/forgot-password" component={ForgotPassword} />
          <PublicRoute
            path="/forgot-password-code"
            component={ForgotPasswordCode}
          />
          <PublicRoute path="/accept-invite" component={AcceptInvite} />
          <PrivateRoute
            path="/dashboard"
            component={ClinicianDashboard}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/sessions"
            component={props => <SessionDashboard {...props} />}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute path="/audit" component={Audit} />
          <PrivateRoute
            exact
            path="/new-session"
            component={SessionActivity}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            hideNavBar
            focusMode
          />
          <PrivateRoute
            path="/dictate/new-session"
            component={() => <SessionActivity isDictation />}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            hideNavBar
            focusMode
          />
          <PrivateRoute
            path="/start-session"
            component={StartSession}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            hideNavBar
          />
          <PrivateRoute
            path="/new-client"
            component={EnrollNewClient}
            requiredPermissions={createClient}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/dictate/new-client"
            component={() => <EnrollNewClient isDictation />}
            requiredPermissions={createClient}
            permissionsRedirect={billingReportRoute}
          />
          <Redirect exact path="/patients/enroll" to="/new-client" />
          <PrivateRoute
            exact
            path="/scribe/client-list"
            component={ClientListHandler}
            requiredPermissions={readClients}
          />
          <PrivateRoute
            exact
            path="/patients/:type"
            component={props => (
              <BadClientListTypeRedirect>
                <Patients {...props} />
              </BadClientListTypeRedirect>
            )}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />

          <PrivateRoute
            exact
            path="/patient/:id/session/:sessionId"
            component={SessionActivity}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            hideNavBar
            focusMode
          />
          <PrivateRoute
            exact
            path="/patient/:id/dictate/session/:sessionId"
            component={() => <SessionActivity isDictation />}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            hideNavBar
            focusMode
          />
          <PrivateRoute
            exact
            path="/patient/:id/session/:sessionId/recap"
            component={SessionNotes}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            focusMode
          />
          <PrivateRoute
            exact
            path="/patient/:id/interventions/:interventionId/summary"
            component={InterventionSummary}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            exact
            path="/patient/:id/interventions/:interventionId/form"
            component={InterventionForm}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            focusMode
          />
          <PrivateRoute
            exact
            path="/patient/:id/interventions/:interventionId/complete"
            component={InterventionComplete}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            focusMode
          />
          <PrivateRoute
            path="/patient/:id"
            component={props => (
              <IdParamDecoderRedirect>
                <Route
                  exact
                  path="/patient/:id"
                  render={() => <PatientProfile {...props} />}
                />
                <Route
                  path="/patient/:id/settings"
                  render={() => <PatientProfile {...props} />}
                />
                <Route
                  exact
                  path="/patient/:id/completed-session/:sessionId"
                  render={() => <SessionNotes {...props} />}
                />
              </IdParamDecoderRedirect>
            )}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            exact
            path="/pre-enroll-patient/:id"
            component={PreEnrollProfile}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/pre-enroll-patient/:id/settings"
            component={PreEnrollProfile}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/intake-patient/:id"
            component={IntakeProfile}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            exact
            path="/pending-client/:id"
            component={PendingClientProfile}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/pending-client/:id/intake-pdf"
            component={IntakeProfile}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            hideNavBar
          />
          <PrivateRoute exact path="/logout" component={Logout} />
          <PrivateRoute
            path="/settings"
            component={UserSettings}
            overflowY={{
              base: 'auto',
              sm: 'auto',
              md: location.pathname.includes('/settings/update-your-plan')
                ? 'hidden'
                : 'auto'
            }}
            focusMode={
              location.pathname.includes('/settings/update-your-plan') ||
              location.pathname.includes('/settings/account/delete')
            }
          />
          <PrivateRoute
            requiredPermissions={readBilling}
            path="/reports/claims"
            component={ReportsClaims}
          />
          <PrivateRoute
            path="/insights/utilization"
            component={InsightsUtilization}
            requiredPermissions={readUtilization}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/insights/ehr-integration-issues"
            component={InsightsEHRIntegrationIssues}
            requiredPermissions={readUtilization}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/insights/ehr-appointments"
            component={EHRAppointments}
            requiredPermissions={readEhrAppts}
            permissionsRedirect="/dashboard"
          />
          <PrivateRoute
            path="/insights/outcomes"
            component={InsightsOutcomes}
            requiredPermissions={readOutcomes}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/insights/treatment-outcomes"
            component={InsightsOutcomesV2}
            requiredPermissions={readOutcomes}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/insights/client-satisfaction"
            component={ClientSatisfaction}
            requiredPermissions={readOutcomes}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/library/worksheets"
            component={LibraryWorksheets}
          />
          <PrivateRoute
            path="/library/symptom-trackers"
            component={LibrarySymptomTrackers}
          />
          <PrivateRoute
            path="/library/checkin-editor"
            component={CheckInEditor}
            requiredPermissions={superAdmin}
          />
          <Redirect from="/patients" to="/patients/active" />
          <PublicRoute
            exact
            path="/"
            component={props =>
              props.isAuthenticated ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <PrivateRoute
            path="/refer"
            component={props => <Referral {...props} />}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/upgrade/payment"
            component={props => <Upgrade {...props} />}
            requiredPermissions={readBilling}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            path="/delete"
            component={props => <Delete {...props} />}
            requiredPermissions={readClients}
            permissionsRedirect="/dashboard"
          />
          <PrivateRoute
            path="/organizations/:organizationId/workflows/:workflowId"
            component={props => (
              <WorkflowsContextProvider>
                <WorkflowEditor {...props} />
              </WorkflowsContextProvider>
            )}
            hideNavBar
          />
          <PublicRoute
            exact
            hideNavBar
            path="/extension/login"
            component={Login}
          />
          <PrivateRoute
            exact
            hideNavBar
            path="/extension/start-session"
            component={ExtensionStartSession}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            exact
            hideNavBar
            path="/extension/patient/:id/completed-session/:sessionId"
            component={ExtensionSessionNotes}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PublicRoute
            exact
            hideNavBar
            path="/extension"
            component={props =>
              props.isAuthenticated ? (
                <Redirect to="/extension/start-session" />
              ) : (
                <Redirect to="/extension/login" />
              )
            }
          />
          <PrivateRoute
            exact
            path="/extension/patient/:id/session/:sessionId"
            component={SessionActivity}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            hideNavBar
            focusMode
          />
          <PublicRoute
            exact
            hideNavBar
            path="/widget/login"
            component={Login}
          />
          <PrivateRoute
            exact
            hideNavBar
            path="/widget/start-session"
            component={WidgetStartSession}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PrivateRoute
            exact
            hideNavBar
            path="/widget/patient/:id/completed-session/:sessionId"
            component={WidgetSessionNotes}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
          />
          <PublicRoute
            exact
            hideNavBar
            path="/widget"
            component={props =>
              props.isAuthenticated ? (
                <Redirect to="/widget/start-session" />
              ) : (
                <Redirect to="/widget/login" />
              )
            }
          />
          <PrivateRoute
            exact
            path="/widget/patient/:id/session/:sessionId"
            component={SessionActivity}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            hideNavBar
            focusMode
          />
          <PrivateRoute
            exact
            path="/widget/patient/:id/dictate/session/:sessionId"
            component={() => <SessionActivity isDictation />}
            requiredPermissions={readClients}
            permissionsRedirect={billingReportRoute}
            hideNavBar
            focusMode
          />
          <PrivateRoute
            exact
            path="/widget/patient/:clientId/sessions"
            component={ClientSessions}
            requiredPermissions={readClients}
            hideNavBar
            focusMode
          />
        </Switch>
        <Globals />
      </ConditionalPageWrapper>
    </>
  )
}

export default Routes
