import React, { useState } from 'react'
import Actions from './actions'
import Fields from './fields'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { FormValues } from './types'
import { Container, Flex, Stack, Box, Divider } from '@chakra-ui/react'
import { useTreatmentPlan } from '@hooks'

const validationSchema = Yup.object().shape({
  selectedDiagnosis: Yup.mixed(),
  selectedFocuses: Yup.array().of(Yup.mixed()),
  treatmentPlanText: Yup.string()
})

const validate = (values: FormValues) => {
  const errors: any = {}

  if (
    !values.selectedDiagnoses?.filter(d => d?.value)?.length &&
    !values.selectedFocuses?.filter(f => f?.value)?.length
  ) {
    errors.selectedDiagnoses =
      'Either diagnosis or focus of treatment must be selected'
    errors.selectedFocuses =
      'Either diagnosis or focus of treatment must be selected'
  }

  return errors
}

const TreatmentPlanForm = () => {
  const {
    treatmentPlanQuery,
    acceptMutation,
    updateMutation,
    initialSelectedDiagnoses,
    initialSelectedFocuses,
    treatmentPlan,
    isUnaccepted,
    client
  } = useTreatmentPlan()

  const initialValues = {
    selectedDiagnoses: initialSelectedDiagnoses,
    selectedFocuses: initialSelectedFocuses,
    treatmentPlanText: treatmentPlan?.rawText || ''
  }

  const onSubmit = async (
    values: FormValues,
    { setTouched }: FormikHelpers<FormValues>
  ) => {
    const {
      selectedDiagnosis,
      selectedDiagnoses,
      selectedFocuses,
      treatmentPlanText
    } = values

    const focusOfTreatmentIds = selectedFocuses
      ?.filter(focus => focus?.value)
      ?.map(focus => focus?.value)

    const diagnosisIds = selectedDiagnoses
      ?.filter(diagnosis => diagnosis?.value)
      ?.map(diagnosis => diagnosis?.value)

    const data = {
      focusOfTreatmentIds,
      diagnosisIds,
      treatmentPlanText
    }

    setTouched({})

    if (isUnaccepted) {
      acceptMutation.mutate(data)
    } else {
      updateMutation.mutate(data)
    }
  }

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={validate}
      onSubmit={onSubmit}
    >
      <Form>
        <Fields />
        <Actions />
      </Form>
    </Formik>
  )
}

export default TreatmentPlanForm