import React from 'react'

export const MagicEditIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00004 3.73334L6.66671 4.66668L5.73337 3.00001L6.66671 1.33334L5.00004 2.26668L3.33337 1.33334L4.26671 3.00001L3.33337 4.66668L5.00004 3.73334ZM13 10.2667L11.3334 9.33334L12.2667 11L11.3334 12.6667L13 11.7333L14.6667 12.6667L13.7334 11L14.6667 9.33334L13 10.2667ZM14.6667 1.33334L13 2.26668L11.3334 1.33334L12.2667 3.00001L11.3334 4.66668L13 3.73334L14.6667 4.66668L13.7334 3.00001L14.6667 1.33334ZM9.58004 4.86001C9.32004 4.60001 8.90004 4.60001 8.64004 4.86001L0.860039 12.64C0.600039 12.9 0.600039 13.32 0.860039 13.58L2.42004 15.14C2.68004 15.4 3.10004 15.4 3.36004 15.14L11.1334 7.36668C11.3934 7.10668 11.3934 6.68668 11.1334 6.42668L9.58004 4.86001ZM8.89337 8.52001L7.48004 7.10668L9.10671 5.48001L10.52 6.89334L8.89337 8.52001Z"
        fill="#282828"
      />
    </svg>
  )
}
