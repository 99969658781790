import React from 'react'
import { Payment } from './payment'
import { Container, GridItem, Box } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'

export const PaymentPage = ({
  isSubmitting,
  setIsSubmitting,
  isError,
  storePaymentAndCreateAccount,
  resetFormError
}) => {
  return (
    <Container
      marginTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
      maxWidth="100vw" // Ensure the Container does not exceed the viewport width
      overflowX="hidden" // Prevent horizontal scrolling
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%">
            <Payment
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              isError={isError}
              storePaymentAndCreateAccount={storePaymentAndCreateAccount}
              resetFormError={resetFormError}
            />
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
