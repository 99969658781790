import React, { useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useStoreActions } from 'easy-peasy'
import { BasicImageEditor } from '@components'
import { cloudinaryAssetToUrl } from '@utilities'
import { useDisclosure } from '@chakra-ui/react'
import { endpoints } from '@api'

// No more than 10mb
const MAX_FILE_SIZE = 10 * 1024 * 1024

const AVATAR_SIZE = 128

const transformAvatarAsset = asset =>
  cloudinaryAssetToUrl(asset, {
    transformations: {
      resize: {
        height: AVATAR_SIZE * 4,
        width: AVATAR_SIZE * 4,
        type: 'scale'
      }
    }
  })

const AvatarEditor = ({ user, children }) => {
  const [avatarImg, setAvatarImg] = useState(
    () => user.avatar && transformAvatarAsset(user.avatar)
  )
  const [selectedImage, setImage] = useState()

  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setImage(null)
    }
  })
  const fileRef = useRef()
  const queryClient = useQueryClient()

  const { mutateAsync: updateClinicianAvatar, isLoading } = useMutation(
    endpoints.postClinicianAvatar.request,
    {
      onSuccess(asset) {
        setAvatarImg(transformAvatarAsset(asset))
        setSnackbarMessage({
          variant: 'success',
          message: 'Avatar uploaded!'
        })
        queryClient.invalidateQueries(endpoints.getUserAccount.getCacheId())
      }
    }
  )

  const { mutateAsync: deleteClinicianAvatar } = useMutation(
    endpoints.deleteClinicianAvatar.request,
    {
      onSuccess() {
        queryClient.invalidateQueries(endpoints.getUserAccount.getCacheId())
      }
    }
  )

  const onAddFile = () => {
    fileRef.current.click()
  }

  const onChangeFile = event => {
    event.stopPropagation()
    event.preventDefault()
    if (event.target.files.length) {
      const file = event.target.files[0]
      if (file.size >= MAX_FILE_SIZE) {
        alert('File cannot be larger than 10mb.')
      } else {
        setImage(file)
        onOpen()
        // Need to reset input value so next 'onChange' will fire
        event.target.value = null
      }
    }
  }

  const onApplyImage = async croppedImageBlob => {
    onClose()
    updateClinicianAvatar({
      image: croppedImageBlob
    })
  }

  const onRemoveAvatar = () => {
    deleteClinicianAvatar()
    setAvatarImg(null)
  }

  const renderProps = {
    onAddFile,
    onRemoveAvatar,
    onChangeFile,
    avatarImg,
    isLoading
  }

  return (
    <div>
      <input
        type="file"
        id="file"
        ref={fileRef}
        style={{ display: 'none' }}
        onChange={onChangeFile}
        accept="image/*"
      />
      <BasicImageEditor
        imageFile={selectedImage}
        onClose={onClose}
        isOpen={isOpen}
        onApplyImage={onApplyImage}
      />
      {children(renderProps)}
    </div>
  )
}

export default AvatarEditor
