import React, { useMemo, useCallback } from 'react'
import { useQuery } from 'react-query'
import { Box, Heading, Flex } from '@chakra-ui/react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { ClinicianOutcomesTable } from './clinician-outcomes-table'
import {
  AvgScoreChart,
  EffectSizeChart,
  ClientCountChart
} from './outcomes-chart'
import { Loading } from '@components'
import { CompletionCount } from '@handlers/reports/components'
import { X_AXIS_LABEL_MAP, TIME_PERIODS } from '../constants'
import { endpoints } from '@api'
import { keyBy, get } from 'lodash'

const Section = ({ isLoading, children }) => (
  <Box
    sx={{
      position: 'relative',
      borderRadius: 8,
      mb: 'large',
      backgroundColor: 'white'
    }}
  >
    <Box p="5">
      {isLoading && (
        <Flex
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute'
          }}
        >
          <Loading />
        </Flex>
      )}
      {children}
    </Box>
  </Box>
)

export const OutcomesContent = () => {
  const {
    selectedAssessment,
    filtersV2: {
      clinicIds,
      clinicianId,
      ages,
      startDate,
      endDate,
      baselineScoreMin,
      baselineScoreMax,
      programIds
    }
  } = useStoreState(state => state.insights)

  const { updateFiltersV2 } = useStoreActions(actions => actions.insights)

  const query = useMemo(() => {
    if (selectedAssessment && clinicIds?.length) {
      return {
        assessment_id: selectedAssessment.id,
        clinic_ids: clinicIds,
        age_range: ages,
        clinician_ids: [clinicianId],
        start_date: startDate,
        end_date: endDate,
        baseline_score_min: baselineScoreMin,
        baseline_score_max: baselineScoreMax,
        program_ids: programIds
      }
    } else {
      return null
    }
  }, [
    selectedAssessment,
    clinicIds,
    clinicianId,
    ages,
    startDate,
    endDate,
    baselineScoreMin,
    baselineScoreMax,
    programIds
  ])

  const { data, isFetching, isLoading } = useQuery(
    [endpoints.getPopulationOutcomes.getCacheId(), query],
    () => endpoints.getPopulationOutcomes.request(query),
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
      placeholderData: {
        clientCount: 0,
        assessmentCount: 0,
        intervals: []
      }
    }
  )

  const {
    data: clinicianOutcomesData,
    isLoading: isLoadingClinicianData,
    isFetching: isFetchingClinicianData
  } = useQuery(
    [endpoints.getClinicianOutcomes.getCacheId(), query],
    () => endpoints.getClinicianOutcomes.request(query),
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
      placeholderData: []
    }
  )

  const { intervals, assessmentCount, clientCount, assessment } = data

  const onClinicianRowClick = useCallback(
    rowData => {
      updateFiltersV2({ clinicianId: rowData.clinician.id })
    },
    [updateFiltersV2]
  )

  const keyedIntervals = useMemo(() => keyBy(intervals, 'period'), [intervals])
  const baseline = keyedIntervals[TIME_PERIODS.BASELINE]

  const avgScoreChartData = useMemo(
    () =>
      Object.keys(X_AXIS_LABEL_MAP).map(i => {
        const data = keyedIntervals[i]
        if (data) {
          return {
            interval: i,
            label: X_AXIS_LABEL_MAP[i],
            value: data.avgScore.value,
            errorHigh: data.avgScore.error.high,
            errorLow: data.avgScore.error.low,
            clientCount: data.clientCount,
            assessmentCount: data.assessmentCount,
            baselineAvg: baseline ? baseline.avgScore.value : null
          }
        } else {
          return { interval: i, label: X_AXIS_LABEL_MAP[i] }
        }
      }),
    [baseline, keyedIntervals]
  )

  const effectSizeChartData = useMemo(
    () =>
      Object.keys(X_AXIS_LABEL_MAP).map(i => {
        const data = keyedIntervals[i]
        if (data) {
          return {
            interval: i,
            label: X_AXIS_LABEL_MAP[i],
            value: data.effectSize.value,
            errorHigh: data.effectSize.error.high,
            errorLow: data.effectSize.error.low,
            clientCount: data.clientCount,
            assessmentCount: data.assessmentCount
          }
        } else {
          return { interval: i, label: X_AXIS_LABEL_MAP[i] }
        }
      }),
    [keyedIntervals]
  )

  const clientCountChartData = useMemo(
    () =>
      Object.keys(X_AXIS_LABEL_MAP).map(i => {
        const data = keyedIntervals[i]
        if (data) {
          return {
            interval: i,
            label: X_AXIS_LABEL_MAP[i],
            value: data.clientCount
          }
        } else {
          return { interval: i, label: X_AXIS_LABEL_MAP[i] }
        }
      }),
    [keyedIntervals]
  )

  const outcomeDataLoaded = !isLoading && !isFetching
  const clinicianDataLoaded =
    !isLoadingClinicianData && !isFetchingClinicianData
  return (
    <Box>
      {selectedAssessment && (
        <CompletionCount
          isLoaded={outcomeDataLoaded}
          assessmentCount={assessmentCount}
          clientCount={clientCount}
        />
      )}
      <Section isLoading={!outcomeDataLoaded}>
        <ClientCountChart data={clientCountChartData} />
      </Section>
      <Section isLoading={!outcomeDataLoaded}>
        <AvgScoreChart
          data={avgScoreChartData}
          assessment={assessment}
          isReverseValence={get(assessment, 'is_reverse_valence', false)}
        />
      </Section>
      <Section isLoading={!outcomeDataLoaded}>
        <EffectSizeChart data={effectSizeChartData} />
      </Section>
      <Section isLoading={!clinicianDataLoaded}>
        <Heading size="lg" my="2">
          Avg Scores by Clinician
        </Heading>
        <Box mt="small" overflowX="auto">
          <ClinicianOutcomesTable
            data={clinicianOutcomesData}
            onRowClick={onClinicianRowClick}
          />
        </Box>
      </Section>
    </Box>
  )
}
