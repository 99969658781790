import React, { useMemo } from 'react'
import { ArrowDownIcon, ArrowUpIcon, CloseIcon } from '@blueprinthq/joy'
import {
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Box,
  Text,
  Flex,
  IconButton,
  Tooltip,
  Switch
} from '@chakra-ui/react'
import { useTable, useSortBy, CellProps, Column } from 'react-table'

import {
  getClinicControllerGetAssignedCheckInsQueryKey,
  useClinicControllerToggleCheckInDefault,
  useClinicControllerUnassignCheckIn
} from '~/clinician-api'
import { CheckInDtoV2 } from '~/clinician-api/models'
import { useQueryClient } from 'react-query'

interface WorksheetsTableProps {
  checkIns: CheckInDtoV2[]
  clinicId: string
}

export const AssignedCheckInsTable = ({
  checkIns,
  clinicId
}: WorksheetsTableProps) => {
  const queryClient = useQueryClient()
  const { mutate: unassignCheckIns } = useClinicControllerUnassignCheckIn({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(
          getClinicControllerGetAssignedCheckInsQueryKey(clinicId)
        )
      }
    }
  })

  const { mutate: toggleDefault } = useClinicControllerToggleCheckInDefault({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(
          getClinicControllerGetAssignedCheckInsQueryKey(clinicId)
        )
      }
    }
  })

  const columns = useMemo<Column<CheckInDtoV2>[]>(
    () => [
      {
        id: 'title',
        Header: 'Library',
        accessor: 'title',
        Cell: ({ value, row }: CellProps<CheckInDtoV2>) => {
          return (
            <Box maxWidth="450px">
              <Text fontWeight="bold" mb="xsmall">
                {value}
              </Text>
              <Text mb="xsmall">{row.original.subtitle}</Text>
              <Text>{row.original.questionCount} questions</Text>
            </Box>
          )
        },
        defaultCanSort: true
      },
      {
        id: 'default',
        Header: 'Default for new clients',
        accessor: 'isDefault',
        Cell: ({ value, row }: CellProps<CheckInDtoV2>) => {
          return (
            <Box>
              <Switch
                isDisabled={!row.original.canToggle}
                isChecked={value}
                onChange={e => {
                  toggleDefault({
                    checkInId: row.original.id,
                    clinicId
                  })
                }}
              />
            </Box>
          )
        }
      },
      {
        Header: '',
        Cell: ({ row }: CellProps<CheckInDtoV2>) => {
          return (
            <Box>
              <Tooltip label="Remove from library">
                <IconButton
                  isDisabled={!row.original.canToggle}
                  onClick={() =>
                    unassignCheckIns({
                      clinicId,
                      data: {
                        checkInIds: [row.original.id]
                      }
                    })
                  }
                  aria-label="unassign"
                  icon={<CloseIcon />}
                  variant="ghost"
                />
              </Tooltip>
            </Box>
          )
        },
        id: 'more'
      }
    ],
    [checkIns]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data: checkIns,
      disableSortRemove: true,
      initialState: {
        sortBy: [{ id: 'title', desc: false }]
      }
    },
    useSortBy
  )

  return (
    <Box
      w="100%"
      border="1px solid"
      borderColor="#E4E5E6"
      borderRadius="4px"
      mb={'small'}
      bg="white"
      verticalAlign={'middle'}
    >
      <Table variant="unstyled" {...getTableProps()}>
        <Thead borderBottom={'1px solid #E4E5E6'}>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j: number) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  borderColor="pale_gray"
                  whiteSpace={'nowrap'}
                  paddingLeft={'24px'}
                >
                  <Flex
                    textTransform={'capitalize'}
                    fontSize="16px"
                    letterSpacing={0}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownIcon size="sm" fill={'gray.600'} />
                        ) : (
                          <ArrowUpIcon size="sm" fill={'gray.600'} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <Tr
                {...row.getRowProps()}
                borderBottom={'1px solid #E4E5E6'}
                _last={{ borderBottom: 'none' }}
              >
                {row.cells.map(cell => (
                  <Td
                    {...cell.getCellProps()}
                    textTransform="capitalize"
                    borderColor="pale_gray"
                    verticalAlign="middle"
                    style={{
                      paddingLeft: '24px',
                      paddingRight: '0px',
                      paddingTop: '8px',
                      paddingBottom: '8px'
                    }}
                  >
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}
