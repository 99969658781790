import { themeConfig } from './theme'
import { componentConfig } from './components'

const config = {
  appName: 'Tempus',
  globalCSSPath: './css/tempus/index.css', // relative route from src/utilization.js
  components: componentConfig,
  theme: themeConfig,
  showLinkToSignUp: false,
  orderFormUrl: 'https://tempus.jotform.com/203014812590143',
  logo: {
    url:
      'https://res.cloudinary.com/hellojoy/image/upload/v1609888197/tempus-logo_s2uj5u.png',
    style: {
      maxWidth: '150px',
      height: 'auto',
      marginTop: '8px'
    }
  },
  mobileAppName: 'Tempus',
  summaryTabs: [
    'Molecular Results',
    'Reference Information',
    'Client Tracker'
  ],
  termsAndConditionsURL: 'https://www.tempus.com/providersponsor-termsofuse/',
  blurredImageURLs: {
    engagement:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605041970/Tempus%20Misc/engagement-blurred-tempus_xgas6y.png',
    lifestyle:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605041973/Tempus%20Misc/lifestyle-blurred-tempus_bn4ikf.png',
    topSymptoms:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605041971/Tempus%20Misc/top-symptoms-blurred-tempus_pfiux9.png',
    worksheets:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605041972/Tempus%20Misc/worksheets-blurred-tempus_mbyq0h.png'
  },
  helpEmail: 'help@tempus.com',
  poweredByLogoURL:
    'https://res.cloudinary.com/hellojoy/image/upload/v1609888451/powered_by_blueprint_dmgf43.png'
}

export default config
