import React, { useState } from 'react'
import { Alert, Stack, Text, Flex, Button } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'

interface UploadedAlertProps {
  onGenerate: () => void
  isGenerating: boolean
  isVisible?: boolean
}

const UploadedAlert: React.FC<UploadedAlertProps> = ({
  onGenerate,
  isGenerating,
  isVisible = false
}) => {
  const history = useHistory()
  const location = useLocation()

  if (!isVisible) return null

  const onDismiss = () => {
    const params = new URLSearchParams(location.search)
    params.delete('treatmentPlanUploadSuccess')
    history.replace({ search: params.toString() })
  }

  return (
    <Alert
      status="info"
      borderRadius="md"
      colorScheme="#F7F8FE"
      p="small"
      width="auto"
    >
      <Stack>
        <Text fontWeight="bold">Treatment Plan Uploaded</Text>
        <Text>
          Do you want to generate a new note that incorporates the treatment
          plan?
        </Text>
        <Flex gap="8px">
          <Button
            borderRadius="4px"
            size="sm"
            gap="8px"
            onClick={async () => {
              await onGenerate()
              onDismiss()
            }}
            isLoading={isGenerating}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9999 4.74667H7.47992L9.30659 2.86667C7.48659 1.06667 4.53992 1 2.71992 2.8C0.899921 4.60667 0.899921 7.52 2.71992 9.32667C4.53992 11.1333 7.48659 11.1333 9.30659 9.32667C10.2133 8.43333 10.6666 7.38667 10.6666 6.06667H11.9999C11.9999 7.38667 11.4133 9.1 10.2399 10.26C7.89992 12.58 4.09992 12.58 1.75992 10.26C-0.573412 7.94667 -0.593412 4.18667 1.74659 1.87333C4.08659 -0.44 7.83992 -0.44 10.1799 1.87333L11.9999 0V4.74667Z"
                fill="white"
              />
            </svg>
            Generate new note
          </Button>
          <Button
            borderRadius="4px"
            size="sm"
            variant="link"
            style={{ textDecoration: 'none' }}
            onClick={() => onDismiss()}
            isDisabled={isGenerating}
          >
            No thanks
          </Button>
        </Flex>
      </Stack>
    </Alert>
  )
}

export default UploadedAlert
