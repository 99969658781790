import React, { useContext } from 'react'
import { Text, Box, Select, Flex, CloseButton } from '@chakra-ui/react'
import { Formik, Form, Field, FieldArray } from 'formik'
import { useQuery } from 'react-query'
import { AddCircleIcon } from '@blueprinthq/joy'
import * as Yup from 'yup'

import { WorkflowsContext } from '@context'
import { EditDrawerFooter } from '../../edit-drawer/edit-drawer-footer'
import { FormattedNode } from '../../format-nodes'
import { Loading } from '@components'
import { endpoints } from '@api'

type Props = {
  onSubmit: (node: FormattedNode) => void
  closeDrawer: () => void
  onDelete: (node: FormattedNode | undefined) => void
}

type CheckInModule = {
  id: string
  title: string
  type: string
}

const schema = Yup.object().shape({
  symptomTrackerIds: Yup.array()
    .of(Yup.string().test(str => Boolean(str)))
    .min(1)
    .required()
})

export const AssignSymptomTracker = ({
  onSubmit,
  closeDrawer,
  onDelete
}: Props) => {
  const { selectedNode } = useContext(WorkflowsContext)
  const config = selectedNode?.config

  const {
    data: allCheckInModules,
    isLoading
  }: {
    data: CheckInModule[] | undefined
    isLoading: boolean
  } = useQuery([endpoints.getClinicianCheckInModules.getCacheId()], () =>
    // @ts-ignore
    endpoints.getClinicianCheckInModules.request()
  )

  if (isLoading) return <Loading />

  const initialValues = {
    symptomTrackerIds: config?.symptomTrackers?.map(
      (t: CheckInModule) => t.id
    ) || ['']
  }

  const allSymptomTrackers = allCheckInModules?.filter(
    m => m.type === 'symptom'
  )

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={values =>
          onSubmit({
            ...selectedNode,
            config: { symptomTrackerIds: values.symptomTrackerIds }
          } as FormattedNode)
        }
      >
        {({ values, errors }) => (
          <Form>
            <FieldArray
              name="symptomTrackerIds"
              render={({ push, remove, replace }) => (
                <Box>
                  {!Boolean(values.symptomTrackerIds.length) && (
                    <Field name="symptomTrackerIds.0">
                      {({ field }: { field: { value: string } }) => (
                        <Select
                          {...field}
                          value={field.value}
                          borderColor={
                            errors?.symptomTrackerIds ? 'error' : 'light_gray'
                          }
                          color={field.value ? 'black' : 'gray'}
                          mt="xsmall"
                        >
                          <option value="" disabled>
                            Choose Symptom Tracker
                          </option>
                          {allSymptomTrackers?.map((tracker: CheckInModule) => (
                            <option
                              key={tracker.id}
                              value={tracker.id}
                              disabled={values.symptomTrackerIds.includes(
                                tracker.id
                              )}
                            >
                              {tracker.title}
                            </option>
                          ))}
                        </Select>
                      )}
                    </Field>
                  )}
                  {values?.symptomTrackerIds?.map(
                    (symptomTrackerId: string, index: number) => (
                      <Flex>
                        <Field
                          name={`symptomTrackerIds.${index}`}
                          key={`symptomTrackerIds.${index}`}
                        >
                          {({ field }: { field: { value: string } }) => (
                            <Select
                              {...field}
                              borderColor={
                                errors?.symptomTrackerIds && !symptomTrackerId
                                  ? 'error'
                                  : 'light_gray'
                              }
                              color={field.value ? 'black' : 'gray'}
                              mb="xsmall"
                            >
                              <option value="" disabled>
                                Choose Symptom Tracker
                              </option>
                              {allSymptomTrackers?.map(
                                (tracker: CheckInModule) => (
                                  <option
                                    key={tracker.id}
                                    value={tracker.id}
                                    disabled={values.symptomTrackerIds.includes(
                                      tracker.id
                                    )}
                                  >
                                    {tracker.title}
                                  </option>
                                )
                              )}
                            </Select>
                          )}
                        </Field>
                        {symptomTrackerId && (
                          <CloseButton
                            ml="xsmall"
                            onClick={() => {
                              if (values.symptomTrackerIds.length === 1) {
                                replace(0, '')
                              } else {
                                remove(index)
                              }
                            }}
                          />
                        )}
                      </Flex>
                    )
                  )}
                  {Boolean(values?.symptomTrackerIds[0]) && (
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      _hover={{ cursor: 'pointer' }}
                      onClick={() => push('')}
                      mt="xsmall"
                    >
                      <AddCircleIcon fill="primary" mr="xsmall" />
                      <Text color="primary">Add Symptom Tracker</Text>
                    </Box>
                  )}
                </Box>
              )}
            />
            <EditDrawerFooter
              onDelete={() => onDelete(selectedNode)}
              onCancel={closeDrawer}
            />
          </Form>
        )}
      </Formik>
    </Box>
  )
}
