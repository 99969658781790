import React from 'react'
import { Flex, Heading, Stack, Skeleton } from '@chakra-ui/react'
import {
  PlanTypeToFeatures,
} from '../update-your-plan/constants/plans'
import { useHistory } from 'react-router-dom'
import { CancelSubscription, PlanCard, DeleteAccount } from './components'
import {
  useClinicControllerGetPlanOptions,
  useClinicControllerGetCurrentPlan
} from '~/clinician-api'
import { useStoreState } from 'easy-peasy'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { useExperienceManager } from '@hooks'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { StoreModel } from 'src/store/types'

const planTitle: Record<string, string> = {
  standard: 'Standard',
  plus: 'Plus'
}

interface PlanDisplay {
  title: string;
  price: string;
  priceEmbelishment: string;
  features: string[];
  planType: string;
  sessionCount?: number;
  priceId?: string;
  isCurrentPlan: boolean;
  buttonText: string;
  buttonVariant: string;
  buttonAction: () => void;
}


const Plans = ({ selectedClinic }: any) => {
  const { search } = useLocation()
  const queryParams = queryString.parse(search)
  const history = useHistory()
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const { isAdmin, hasPlusPlanFreeTrial } = useExperienceManager()

  const {
    data: planOptions,
    isLoading: isLoadingPlanOptions
  } = useClinicControllerGetPlanOptions(user!.clinic.id)

  const {
    data: currentPlan,
    isLoading: isLoadingCurrentPlan
  } = useClinicControllerGetCurrentPlan(user!.clinic.id)

  if (!isAdmin) {
    return <Redirect to="/sessions" />
  }

  const isLoading = isLoadingPlanOptions || isLoadingCurrentPlan

  let plansToDisplay: PlanDisplay[] = []
  
  if (!isLoading && planOptions) {
    if (!currentPlan || currentPlan.isFree) {
      
      const standardPlan = planOptions
        .find(plan => plan.planType === 'standard' && plan.sessionCount === 30)

      const plusPlan = planOptions
        .find(plan => plan.planType === 'plus' && plan.sessionCount === 30)

      if (!standardPlan || !plusPlan) {
        return []
      }

      plansToDisplay = [standardPlan, plusPlan]
        .filter(Boolean)
        .map(plan => ({
          title: planTitle[plan.planType],
          price: `$${(plan.priceCents / 100)}/month`,
          priceEmbelishment: 'Starting at',
          features: PlanTypeToFeatures[plan.planType],
          planType: plan.planType,
          sessionCount: plan.sessionCount,
          priceId: plan.priceId,
          isCurrentPlan: false,
          buttonText: `Choose ${planTitle[plan.planType]}`,
          buttonVariant: 'solid',
          buttonAction: () => {
            const queryParamsToForward = queryString.stringify({
              ...queryParams,
              plan: plan.planType,
              sessionCount: plan.sessionCount
            })
            history.push(
              `/settings/update-your-plan?${queryParamsToForward}`
            )
          }
        }))
    } else {
      const standardPlan = planOptions.find(
        (plan: any) => (plan.sessionCount === currentPlan.sessionCount) && plan.planType === 'standard'
      )

      const plusPlan = planOptions.find(
        (plan: any) => (plan.sessionCount === currentPlan.sessionCount) && plan.planType === 'plus'
      )

      if (!standardPlan || !plusPlan) {
        return []
      }

      plansToDisplay = [standardPlan, plusPlan]
        .filter(Boolean)
        .map(plan => ({
          title: planTitle[plan.planType],
          price: `$${(plan.priceCents / 100)}/month`,
          priceEmbelishment: 'Starting at',
          features: PlanTypeToFeatures[plan.planType],
          planType: plan.planType,
          sessionCount: plan.sessionCount,
          priceId: plan.priceId,
          isCurrentPlan: plan.priceId === currentPlan.priceId,
          buttonText:
            plan.priceId === currentPlan.priceId
              ? 'Update your plan'
              : `Choose ${planTitle[plan.planType]}`,
          buttonVariant: 'outline',
          buttonAction: () => {
            const queryParamsToForward = queryString.stringify({
              ...queryParams,
              plan: plan.planType,
              sessionCount: plan.sessionCount
            })
            history.push(
              `/settings/update-your-plan?${queryParamsToForward}`
            )
          }
        }))
    }
  }

  const onCancelSubscriptionClick = () => {
    history.push('/settings/subscription/cancel')
  }

  const onDeleteAccountClick = () => {
    history.push('/settings/account/delete')
  }

  return (
    <Stack spacing="0" flex="1" gap="40px" mb="40px">
      <Flex flexDirection="column" gap="24px">
        <Heading>Plans</Heading>
        <Flex
          gap="24px"
          flexDirection={{
            base: 'column',
            sm: 'column',
            md: 'row'
          }}
        >
          {plansToDisplay.map(plan => {
            const isCurrentPlan =
              plan.planType === currentPlan?.planType && !currentPlan?.isFree
            const isFreeTrial = currentPlan?.isFree && plan.planType === 'plus'
            const isDisabled = hasPlusPlanFreeTrial && plan.planType === 'plus'
            return (
              <Skeleton
                isLoaded={!isLoading}
                key={plan.title}
                flex="1"
                display="flex"
              >
                <PlanCard
                  key={plan.title}
                  title={plan.title}
                  price={`${plan.price}`}
                  priceEmbelishment="Starting at"
                  features={plan.features}
                  isCurrentPlan={isCurrentPlan}
                  hasPlusPlanFreeTrial={hasPlusPlanFreeTrial}
                  buttonText={plan.buttonText}
                  buttonVariant={plan.buttonVariant}
                  buttonAction={plan.buttonAction}
                  showCurrentTrial={isFreeTrial}
                  isDisabled={isDisabled}
                />
              </Skeleton>
            )
          })}
          <Skeleton isLoaded={!isLoading} flex="1" display="flex">
            <PlanCard
              title="Enterprise"
              price="Custom pricing"
              priceEmbelishment="Volume discounts available"
              features={PlanTypeToFeatures.enterprise}
              buttonText="Schedule a call"
              buttonVariant="outline"
              buttonAction={() =>
                window.location.href = `https://blueprint.ai/schedule-call`
              }
            />
          </Skeleton>
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="16px" flex="1">
        <Skeleton isLoaded={!isLoading} flex="1" display="flex">
          {(currentPlan && !currentPlan?.isFree) && (
            <CancelSubscription onClick={onCancelSubscriptionClick} />
          )}
        </Skeleton>
        <Skeleton isLoaded={!isLoading} flex="1" display="flex">
          <DeleteAccount onClick={onDeleteAccountClick} />
        </Skeleton>
      </Flex>
    </Stack>
  )
}

export default Plans
