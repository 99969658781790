const styles = theme => ({
  container: {
    padding: '0px 16px 32px'
  },
  close: {
    cursor: 'pointer'
  },
  dialog_title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title_week_day: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '350px'
  },
  subtitle: {
    padding: '0 24px 20px',
    color: theme.palette.text.contrastOnWhite
  },
  content: {
    padding: '0 24px'
  },
  assessment_score_result: {
    margin: '32px 0 16px',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  assessment_select: {
    marginRight: '16px'
  },
  table: {
    marginBottom: '20px',
    width: '100%',
    overflowX: 'scroll'
  },
  daily_weekly: {
    width: '220px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    fontSize: '16px',
    justifySelf: 'auto'
  },
  other_option: {
    cursor: 'pointer'
  },
  selected_option: {
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  clickable: {
    cursor: 'pointer',
    fontSize: '16px',
    color: theme.palette.primary.main
  },
  prev_next: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  table_title: {
    fontSize: '18px',
    fontWeight: 600
  },
  placeholder: {
    width: '100px'
  },
  next: {
    width: '95px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  prev: {
    width: '125px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  chevron_flip: {
    transform: 'rotate(180deg)',
    transformOrigin: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sub_scor: {
    height: '20px',
    paddingBottom: '8px'
  }
})

export default styles
