import React from 'react'
import { Box, Heading } from '@chakra-ui/react'

export class ContentErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const modalError = new Error('Assessment Result Modal Error', {
      cause: error
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box mb="medium" mt="small">
          <Heading as="h3" size="lg">
            Something went wrong.
          </Heading>
          There was a problem loading assessment data. If the problem persists
          reach out to our support team to report the issue.
        </Box>
      )
    }

    return this.props.children
  }
}
