import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Box, GridItem, Heading, Flex, Text, useToast } from '@chakra-ui/react'
import { LayoutGrid, Button, Radio, RadioGroup } from '@blueprinthq/joy'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { Formik, Form, Field } from 'formik'
import moment from 'moment'
import { useMutation } from 'react-query'
import { pick, isEmpty } from 'lodash'

import { FormSections } from './form-sections'
import { SubmittedView } from './components'
import { newClientEnrollmentValidationSchema } from '@constants/formValidation'
import { CLIENT_TYPE, PARTICIPANT_TYPE } from '@constants/index'
import { signUpSource } from '@constants/enrollment'
import { queryClient } from '../../index'
import { endpoints } from '@api'
import { EnrollBanner } from '@handlers/enroll/components'
import { CannotToggleClientTypeTooltip } from './components'
import { renderSubstringAsLink, calculateAge, formatPhone } from '@utilities'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { EhrIntegrationOptInButton } from './components/ehr-integration-opt-in-button'
import flagsmith from 'flagsmith'

export default function EnrollNewClient({
  pendingClientData = {},
  isPendingClient = false
}) {
  const toast = useToast()
  const { user } = useStoreState(state => state.auth)
  const { setSubmittedViewState } = useStoreActions(actions => actions.enroll)
  const openDeclineClientModal = useStoreActions(
    actions => actions.modals.declineClient.openModal
  )
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false) // for some reason the built in formik isSubmitting flag wasnt working, but this does

  const showOptInButton = flagsmith.hasFeature(
    FlagsmithFeatures.EHR_INTEGRATION_CLIENT_OPT_IN
  )

  const { mutate: executeEnrollClient } = useMutation(
    endpoints.postClinicianEnrollClientV2.request,
    {
      onError: (error, variables) => {
        setIsSubmitting(false)
        variables.formHelpers.setFieldValue(
          'errorMessage',
          (error && error.error && error.error.message) ||
            'Oops, something went wrong. Please refresh the page and try again.'
        )
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  )

  const assessmentsWithMappedAssignees = useCallback(
    values => {
      return Object.entries(values.assessments).flatMap(
        ([assigneeRef, assessments], idx) => {
          let assigneeUserId = null

          const [type, id] = assigneeRef.split(':')

          if (type === 'client') {
            // the first group of assessments will always be the client's assigned assessments
            assigneeUserId = values.pendingClientUserId || null
          } else {
            const participant = values.participants.find(
              p => p.ref === assigneeRef
            )
            if (participant) {
              assigneeUserId = participant.userId || null
            }
          }

          return assessments.map(asmt => ({
            ...asmt,
            clinicAssessmentId: asmt.id,
            assigneeRef:
              !isPendingClient && type === 'participant'
                ? `participant:${idx - 1}`
                : assigneeRef,
            assigneeUserId
          }))
        }
      )
    },
    [isPendingClient]
  )

  const _onSubmit = useCallback(
    async (values, formHelpers, isSaveOnly = false) => {
      setIsSubmitting(true)
      const data = {
        saveOnly: isSaveOnly,
        contact: {
          ...pick(values, ['firstName', 'lastName', 'email']),
          dateOfBirth: values.dateOfBirth,
          phoneNumber: values.phoneNumber.length
            ? formatPhone(values.phoneNumber)
            : null
        },
        participants: values.participants.map((p, idx) => ({
          ...p,
          // Check length?
          phoneNumber: values.participants[idx].phoneNumber
            ? formatPhone(values.participants[idx].phoneNumber)
            : null,
          type: values.participants[idx].type.id
        })),
        assessments: assessmentsWithMappedAssignees(values),
        checkInModules: values.symptomTrackers.concat(values.worksheets),
        careTeam: {
          primaryClinicianId: values.clinician.id,
          secondaryClinicianIds: values.secondaryClinicians.map(
            clinician => clinician.value
          )
        },
        dateOfInvite:
          values.nowOrLater === 'now'
            ? null
            : moment(values.dateOfInvite, 'MM/DD/YYYY hh:mm a'),
        signUpSource: signUpSource.CLINICIAN_WEB_ENROLL,
        overrideContactInfo: values.overrideContactInfo,
        pendingPatientId: values.pendingClientId,
        programEnrollments: values.programEnrollments
      }

      if (values.clientType === CLIENT_TYPE.CHILD) {
        data.contact.email = null
        data.contact.phoneNumber = null
        data.checkInModules = []
      }

      await executeEnrollClient(
        { data, formHelpers },
        {
          onSuccess: async response => {
            setIsSubmitting(false)

            if (isSaveOnly) {
              toast({
                description: 'Saved pending client details!'
              })
            } else {
              setIsSubmitted(true)
              setSubmittedViewState({
                responseData: {
                  ...response.data,
                  firstName: values.firstName,
                  dateOfInvite: values.dateOfInvite,
                  nowOrLater: values.nowOrLater
                }
              })
              const smsSent = response.data.comms_sent.includes('sms')
              if (smsSent) {
                if (window.Intercom) {
                  window.Intercom('trackEvent', 'enrollment-invite')
                }
                window.analytics.track('Enrollment invite sent (frontend)', {
                  type: 'Full-enroll',
                  platform: 'clinician_app'
                })
              } else {
                window.analytics.track('Enrollment (frontend)', {
                  type: 'Full-enroll',
                  platform: 'clinician_app'
                })
              }
              await Promise.all([
                queryClient.invalidateQueries(
                  endpoints.getPatientList.getCacheId()
                ),
                queryClient.invalidateQueries(
                  endpoints.getClinicianUserAccount.getCacheId()
                ),
                queryClient.invalidateQueries(
                  endpoints.getPatientCounts.getCacheId()
                ),
                queryClient.invalidateQueries(
                  endpoints.getPaywallValidation.getCacheId()
                )
              ])
            }
          }
        }
      )
    }
  )

  const initialValues = useMemo(() => {
    const participants = pendingClientData.participants
      ? [
          ...pendingClientData.participants.map(p => ({
            ...p,
            phoneNumber: p.phoneNumber || '',
            ref: `participant:${p.id}`,
            type: Object.values(PARTICIPANT_TYPE).find(pt => pt.id === p.type)
          }))
        ]
      : []

    let assessments = { client: [] }

    if (participants.length) {
      participants.forEach(p => {
        assessments[p.ref] = []
      })
    }

    if (!isEmpty(pendingClientData.assessments)) {
      assessments = { ...assessments, ...pendingClientData.assessments }
    }

    let data = {
      pendingClientId: pendingClientData.pendingClientId || null,
      pendingClientUserId: pendingClientData.user_id || null,
      firstName: pendingClientData.first_name || '',
      lastName: pendingClientData.last_name || '',
      medicalRecord: pendingClientData.medical_record || null,
      dateOfBirth:
        pendingClientData.dobISO &&
        moment(pendingClientData.dobISO, 'MM/DD/YYYY').isValid()
          ? moment(pendingClientData.dobISO, 'MM/DD/YYYY').format('MM/DD/YYYY')
          : '',
      phoneNumber: pendingClientData.phone || '',
      email: pendingClientData.email || '',
      clinician: pendingClientData.clinician || {
        id: user.id,
        name: `${user.first_name} ${user.last_name}`
      },
      secondaryClinicians: pendingClientData.secondaryClinicians || [],
      clinic: pendingClientData.pendingClientId
        ? pendingClientData.clinic_id
          ? {
              id: pendingClientData.clinic_id,
              name: pendingClientData.clinic_name,
              displayId: pendingClientData.clinic_display_id
            }
          : {
              id: user.clinic.id,
              name: user.clinic.name,
              displayId: user.clinic.display_id
            }
        : {
            id: user.clinic.id,
            name: user.clinic.name,
            displayId: user.clinic.display_id
          },
      nowOrLater: moment(pendingClientData.date_of_invite).isValid()
        ? 'later'
        : 'now',
      dateOfInvite: pendingClientData.date_of_invite
        ? moment(pendingClientData.date_of_invite).format('MM/DD/YYYY hh:mm a')
        : moment()
            .add(1, 'd')
            .format('MM/DD/YYYY hh:mm a'),
      dev_overrideContactInfo:
        pendingClientData.dev_overrideContactInfo || false,
      clientType:
        pendingClientData.dobISO &&
        calculateAge(new Date(pendingClientData.dobISO)) < 13
          ? CLIENT_TYPE.CHILD
          : CLIENT_TYPE.ADULT,
      organizationId: pendingClientData.organization_id,
      participants,
      assessments,
      programEnrollments: [],
      symptomTrackers: pendingClientData.symptomTrackers || [],
      worksheets: pendingClientData.worksheets || [],
      conflicts: pendingClientData.conflicts || [],
      errorMessage: pendingClientData.errorMessage || ''
    }

    return data
  }, [
    pendingClientData.assessments,
    pendingClientData.clinic_display_id,
    pendingClientData.clinic_id,
    pendingClientData.clinic_name,
    pendingClientData.clinician,
    pendingClientData.conflicts,
    pendingClientData.date_of_invite,
    pendingClientData.dev_overrideContactInfo,
    pendingClientData.dobISO,
    pendingClientData.email,
    pendingClientData.errorMessage,
    pendingClientData.first_name,
    pendingClientData.last_name,
    pendingClientData.medical_record,
    pendingClientData.participants,
    pendingClientData.pendingClientId,
    pendingClientData.phone,
    pendingClientData.secondaryClinicians,
    pendingClientData.symptomTrackers,
    pendingClientData.user_id,
    pendingClientData.worksheets,
    user.clinic.display_id,
    user.clinic.id,
    user.clinic.name,
    user.first_name,
    user.id,
    user.last_name
  ])

  return (
    <Formik
      validationSchema={newClientEnrollmentValidationSchema}
      initialValues={initialValues}
      onSubmit={async (values, formHelpers) => {
        _onSubmit(values, formHelpers)
      }}
      enableReinitialize
      initialTouched={{
        dateOfInvite: true,
        firstName: pendingClientData.pendingClientId,
        lastName: pendingClientData.pendingClientId,
        email: pendingClientData.pendingClientId,
        phoneNumber: pendingClientData.pendingClientId,
        dateOfBirth: pendingClientData.pendingClientId,
        clinician: pendingClientData.pendingClientId,
        clinic: pendingClientData.pendingClientId,
        participants: !!pendingClientData.participants
      }}
      validateOnMount={pendingClientData.pendingClientId}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {formHelpers => (
        <Form>
          {!isSubmitted && (
            <EnrollBanner pendingClientData={pendingClientData} />
          )}
          <Box
            px="medium"
            pb="medium"
            minHeight="100vh"
            overflow="hidden"
            bg="#F5F5F7"
          >
            <LayoutGrid>
              {isSubmitted ? (
                <SubmittedView
                  formHelpers={formHelpers}
                  setIsSubmitted={setIsSubmitted}
                  pendingClientId={pendingClientData.pendingClientId}
                />
              ) : (
                <GridItem
                  colSpan={{
                    base: 4,
                    sm: 8
                  }}
                  colStart={{
                    md: 3
                  }}
                >
                  <Flex justify={'space-between'} align="flex-end">
                    <Heading mt="medium">
                      {`${
                        pendingClientData.pendingClientId ? 'Pending' : 'New'
                      } Client`}
                    </Heading>
                    {showOptInButton && (
                      <EhrIntegrationOptInButton
                        pendingClientData={pendingClientData}
                      />
                    )}
                  </Flex>
                  <Field name="clientType">
                    {({ field, form }) => (
                      <Box id="adult-child-selector">
                        <CannotToggleClientTypeTooltip
                          showTooltip={
                            !isNaN(new Date(form.values.dateOfBirth))
                          }
                        >
                          <Box width="315px">
                            <RadioGroup
                              {...field}
                              stackProps={{ spacing: 'small', my: 'medium' }}
                              onChange={value => {
                                form.setFieldValue('clientType', value)
                              }}
                            >
                              <Radio
                                isDisabled={
                                  !isNaN(new Date(form.values.dateOfBirth))
                                }
                                value={CLIENT_TYPE.ADULT}
                              >
                                Adult
                              </Radio>
                              <Radio
                                isDisabled={
                                  !isNaN(new Date(form.values.dateOfBirth))
                                }
                                value={CLIENT_TYPE.CHILD}
                              >
                                Child (12 and under)
                              </Radio>
                            </RadioGroup>
                          </Box>
                        </CannotToggleClientTypeTooltip>
                      </Box>
                    )}
                  </Field>
                  {formHelpers.values.errorMessage ? (
                    <Box mb="small">
                      <Text color="error">
                        {renderSubstringAsLink(
                          formHelpers.values.errorMessage,
                          'contact us',
                          process.env.REACT_APP_HELP_EMAIL,
                          true
                        )}
                      </Text>
                    </Box>
                  ) : null}
                  <FormSections
                    formHelpers={formHelpers}
                    pendingClientData={pendingClientData}
                    isPendingClient={isPendingClient}
                  />
                  {Boolean(pendingClientData.pendingClientId) && (
                    <Flex align="center" width="100%" mt="medium">
                      <Text mr="-10px">
                        Don&apos;t want to enroll this client?
                      </Text>
                      <Button
                        textDecoration="underline"
                        color="#e02031"
                        variant="text"
                        onClick={() => {
                          openDeclineClientModal({
                            clientId: pendingClientData.pendingClientId,
                            firstName: pendingClientData.first_name
                          })
                        }}
                      >
                        Decline {pendingClientData.first_name}
                      </Button>
                    </Flex>
                  )}
                  {isPendingClient ? (
                    <Button
                      isLoading={isSubmitting}
                      mt="xsmall"
                      onClick={async () => {
                        const errors = await formHelpers.validateForm()
                        if (!isEmpty(errors)) {
                          formHelpers.setTouched({
                            ...formHelpers.touched,
                            ...errors
                          })
                        } else {
                          _onSubmit(formHelpers.values, formHelpers, true)
                        }
                      }}
                      size="lg"
                      type="button"
                    >
                      {`Save without enrolling`}
                    </Button>
                  ) : null}
                  <Button
                    mt="xsmall"
                    isLoading={isSubmitting}
                    size="lg"
                    type="submit"
                  >
                    {`${isPendingClient ? 'Enroll' : 'Enroll new'} client`}
                  </Button>
                </GridItem>
              )}
            </LayoutGrid>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
