// ProgramModal.jsx
import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input
} from '@chakra-ui/react'
import { Program } from 'src/api/endpoints'

interface ProgramModalProps {
  isOpen: boolean
  onClose: () => void
  handleUpdateProgram: (name: string) => void
  handleCreateProgram: (name: string) => void
  program: Program | null
}

const ProgramModal: React.FC<ProgramModalProps> = ({
  isOpen,
  onClose,
  handleCreateProgram,
  handleUpdateProgram,
  program
}) => {
  const [name, setName] = useState(program?.name || '')

  useEffect(() => {
    setName(program?.name || '')
  }, [program])

  const handleSubmit = () => {
    if (program) {
      handleUpdateProgram(name)
    } else {
      handleCreateProgram(name)
    }
    setName('')
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{program ? 'Edit Program' : 'Create Program'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input autoFocus value={name} onChange={e => setName(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant={'ghost'} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ProgramModal
