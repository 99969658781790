import React, { useCallback, useEffect, useMemo } from 'react'
import { GridItem, VStack, Box, Flex, Text } from '@chakra-ui/react'
import { TextField, Select, CloseIcon, LayoutGrid } from '@blueprinthq/joy'
import { FastField, FieldArray, Field } from 'formik'
import _ from 'lodash'

import { PARTICIPANT_TYPE, CLIENT_TYPE } from '@constants/index'

const ParticipantsComponent = ({
  formHelpers,
  onAddParticipant,
  onRemoveParticipant
}) => {
  const { values, errors, touched } = formHelpers

  // add blank participant when switching new client to child
  // remove when switching back to adult, if blank participant was not touched
  useEffect(() => {
    if (
      !values.participants.length &&
      !touched.participants &&
      values.clientType === CLIENT_TYPE.CHILD
    ) {
      onAddParticipant()
    }

    if (
      values.participants.length === 1 &&
      !touched.participants &&
      values.clientType === CLIENT_TYPE.ADULT
    ) {
      onRemoveParticipant(values.participants[0].ref)
    }
  }, [values.clientType, touched.participants])

  const arrayErrorText = useMemo(
    () =>
      touched.participants &&
      errors.participants &&
      typeof errors.participants === 'string'
        ? errors.participants
        : null,
    [errors, touched]
  )

  const checkIfInvalid = useCallback(
    (field, index) => {
      return (
        errors.participants &&
        touched.participants &&
        errors.participants[index] &&
        touched.participants[index] &&
        errors.participants[index][field] &&
        touched.participants[index][field]
      )
    },
    [values, errors, touched]
  )

  const getErrorText = useCallback(
    (field, index) => {
      return errors.participants &&
        errors.participants[index] &&
        errors.participants[index][field]
        ? errors.participants[index][field]
        : ''
    },
    [values, errors, touched]
  )

  return (
    <Box mt={values.participants.length && 'small'}>
      <FieldArray
        name="participants"
        render={() => {
          return values.participants.length ? (
            <VStack spacing="medium">
              {values.participants.map((participant, index) => (
                <LayoutGrid
                  key={index}
                  gridColumnGap="small"
                  gridRowGap="small"
                  w="100%"
                >
                  <GridItem
                    colSpan={{
                      base: 3,
                      sm: 7,
                      md: 3
                    }}
                    colStart={1}
                  >
                    <FastField name={`participants.${index}.type`}>
                      {({ field, form }) => (
                        <Select
                          {...field}
                          onChange={value => {
                            form.setFieldValue(
                              `participants.${index}.type`,
                              value
                            )
                          }}
                          options={Object.values(PARTICIPANT_TYPE)}
                          valueKey="id"
                          labelKey="displayName"
                        />
                      )}
                    </FastField>
                  </GridItem>
                  <GridItem
                    colStart={{
                      base: 1,
                      md: 4
                    }}
                    colSpan={{
                      base: 4,
                      sm: 8,
                      md: 4
                    }}
                    rowStart={{
                      base: 2,
                      md: 1
                    }}
                    mb={checkIfInvalid('firstName', index) && 'small'}
                  >
                    <Field name={`participants.${index}.firstName`}>
                      {({ field, ...other }) => {
                        return (
                          <TextField
                            {...field}
                            label="First Name"
                            isInvalid={checkIfInvalid('firstName', index)}
                            errorText={getErrorText('firstName', index)}
                            isRequired
                          />
                        )
                      }}
                    </Field>
                  </GridItem>
                  <GridItem
                    colStart={{
                      base: 1,
                      md: 8
                    }}
                    colSpan={{
                      base: 4,
                      sm: 8,
                      md: 4
                    }}
                    rowStart={{
                      base: 3,
                      md: 1
                    }}
                    mb={checkIfInvalid('lastName', index) && 'small'}
                  >
                    <Field name={`participants.${index}.lastName`}>
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Last Name"
                          isInvalid={checkIfInvalid('lastName', index)}
                          errorText={getErrorText('lastName', index)}
                          isRequired
                        />
                      )}
                    </Field>
                  </GridItem>
                  <GridItem
                    colStart={{
                      md: 4
                    }}
                    colSpan={{
                      base: 4,
                      sm: 8,
                      md: 4
                    }}
                    rowStart={{
                      base: 4,
                      md: 2
                    }}
                    mb={checkIfInvalid('email', index) && 'small'}
                  >
                    <Field name={`participants.${index}.email`}>
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Email Address"
                          isInvalid={checkIfInvalid('email', index)}
                          errorText={getErrorText('email', index)}
                        />
                      )}
                    </Field>
                  </GridItem>
                  <GridItem
                    colSpan={{
                      base: 4,
                      sm: 8,
                      md: 4
                    }}
                    rowStart={{
                      base: 5,
                      md: 2
                    }}
                    mb={checkIfInvalid('phoneNumber', index) && 'small'}
                  >
                    <Field name={`participants.${index}.phoneNumber`}>
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Cell Phone"
                          leftIcon={'+1'}
                          isInvalid={checkIfInvalid('phoneNumber', index)}
                          errorText={getErrorText('phoneNumber', index)}
                          type="tel"
                        />
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Flex w="100%" justify="flex-end">
                      <CloseIcon
                        mr="xsmall"
                        cursor="pointer"
                        onClick={() => {
                          onRemoveParticipant(participant.ref)
                        }}
                      />
                    </Flex>
                  </GridItem>
                </LayoutGrid>
              ))}
            </VStack>
          ) : null
        }}
      />
      {arrayErrorText && (
        <Text mt="small" color="error">
          {arrayErrorText}
        </Text>
      )}
    </Box>
  )
}

export const Participants = React.memo(
  ParticipantsComponent,
  (props, nextProps) => _.isEqual(props, nextProps)
)
