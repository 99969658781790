import React from 'react'
import { DialogBody, DialogContainer, DialogHeader } from '../../../dialog'
import { Button, Text, VStack } from '@chakra-ui/react'
import { useTreatmentPlan } from '@hooks'

const DeleteConfirmationDialog = ({ isOpen, onClose }: any) => {
  const { deleteMutation } = useTreatmentPlan()

  return (
    <DialogContainer onClose={onClose} isOpen={isOpen}>
      <DialogHeader text="Are you sure?" onClose={onClose} />
      <DialogBody>
        <VStack spacing={6}>
          <Text textAlign="center">
            Are you sure you want to delete this Treatment Plan and remove
            golden thread documentation? This cannot be undone.
          </Text>
          <VStack w="100%">
            <Button
              bg="error"
              isFullWidth
              size="lg"
              onClick={() => deleteMutation.mutate({})}
              isLoading={deleteMutation.isLoading}
            >
              Delete Treatment Plan
            </Button>
            <Button
              _focus={{ outline: 'none' }}
              variant="outline"
              isFullWidth
              size="lg"
              onClick={onClose}
            >
              Cancel
            </Button>
          </VStack>
        </VStack>
      </DialogBody>
    </DialogContainer>
  )
}

export default DeleteConfirmationDialog