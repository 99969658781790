import React, { Component } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textTransform: 'none',
    backgroundColor: 'white',
    width: '100%',
    minWidth: '175px',
    height: '35px',
    fontWeight: 'normal',
    fontSize: '14px',
    boxShadow: 'none',
    padding: '10.5px 14px',
    border: '1px solid #d1d1d1',
    color: 'black',
    '&:hover': {
      border: '1px solid black',
      backgroundColor: 'white'
    }
  },
  name_arrow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  arrow_icon: {
    color: '#595959'
  },
  menu_item: {
    paddingLeft: '15px'
  }
}

class DropDownMenu extends Component {
  state = {
    anchorEl: null
  }

  static defaultProps = {
    contents: [
      {
        display: 'Example',
        id: '0' // typically uuid
      }
    ],
    placeholder: 'Click me',
    handleClickItem: () => null,
    selected: null
  }

  static propsTypes = {
    contents: PropTypes.array,
    placeholder: PropTypes.string,
    handleClickItem: PropTypes.func,
    selected: PropTypes.object
  }

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClickItemWrapper = async item => {
    await this.props.handleClickItem(item)
    this.handleClose()
  }

  render() {
    const { contents, placeholder, selected, classes } = this.props
    const { anchorEl } = this.state
    return (
      <div>
        <Button
          className={classes.button}
          variant="contained"
          onClick={this.handleOpen}
          id="dropdown-button"
        >
          <div className={classes.name_arrow}>
            <span id="dropdown-button-span">
              {selected ? selected.display : placeholder}
            </span>
            <ArrowIcon className={classes.arrow_icon} />
          </div>
        </Button>
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          open={
            !!anchorEl &&
            (anchorEl.id === 'dropdown-button' ||
              anchorEl.id === 'dropdown-button-span')
          }
          onClose={this.handleClose}
        >
          {contents.map(item => (
            <MenuItem
              key={item.id}
              selected={selected ? selected.id === item.id : null}
              onClick={() => this.handleClickItemWrapper(item)}
              className={classes.menu_item}
            >
              {item.display}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

export default withStyles(styles)(DropDownMenu)
