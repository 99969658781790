export interface AssignableWorksheet {
  checkInId: string
  contentSuggestionId?: string
}

export interface AssignableAssessment {
  clinicAssessmentId: string
  cadenceValue: number
  cadenceUnit: string
  contentSuggestionId?: string
}

export interface AssignAssessmentsFormValues {
  clinicAssessmentId: string
  cadence: string
  nextAdministrationDate: string | undefined
}

export interface AssistEnrollmentFormValues {
  deliveryMethod: string
  email: string
  phone: string
  assessment: {
    clinicAssessmentId: string
    cadence: string
  } | null
  checkIn: {
    daysOfWeek: (string | undefined)[]
    timeOfDay: string | null
    frequency: string
  } | null
}

export interface Patient {
  id: string
  user_id: string
  first_name: string
  last_name: string
  enrollment_timestamp: string
  assessmentDeliveryMethods: string[]
  email: string
  phone_number: string
  age_range: string
  high_risk_timestamp: string
  is_demo?: boolean
}

export enum SessionPanelTab {
  PREP = 'PREP',
  ASSESSMENTS = 'ASSESSMENTS',
  WORKSHEETS = 'WORKSHEETS',
  INTERVENTIONS = 'INTERVENTIONS',
  TREATMENT_PLAN = 'TREATMENT_PLAN'
}
