import React from 'react'
import { Box } from '@chakra-ui/react'
import { useExperienceManager, useExtension } from '@hooks'

export const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { isWidget } = useExperienceManager()
  return (
    <Box
      bg={isWidget ? 'white' : 'primary'}
      display="flex"
      flexDirection="column"
      height="100%"
      overflowY="auto"
    >
      {children}
    </Box>
  )
}
