import React from 'react'
import { Box, HStack, Image } from '@chakra-ui/react'
import { Switch, Route, Redirect } from 'react-router-dom'
import SelectClient from './select-client'
import EnrollClient from './enroll-client'
import UnionSVG from '../../images/brand/union.svg'
import WhiteLogo from '../../images/brand/white_logo.svg'
import { useExperienceManager } from '@hooks'
import { Wrapper, NavBar } from '@handlers/integration-ui/components'

const ConditionalWrapper = ({ children }) => {
  const { isExtension, isWidget } = useExperienceManager()

  if (isExtension || isWidget) {
    return (
      <Wrapper>
        <NavBar showBackButton />
        <Box h="100%" mt="0px !important">
          {children}
        </Box>
      </Wrapper>
    )
  }

  return (
    <Box
      h="100vh"
      minH="100vh"
      w="100%"
      bg="primary"
      backgroundRepeat="no-repeat"
      backgroundImage={UnionSVG}
      backgroundPosition="left top"
      overflow="scroll"
    >
      <Box style={{ margin: '0 16px' }}>
        <HStack
          style={{ height: '80px', margin: 0 }}
          justifyContent={{ base: 'center', md: 'space-between' }}
          alignItems="center"
          my="medium"
        >
          <Image loading="eager" src={WhiteLogo} paddingLeft="16px" />
        </HStack>
      </Box>
      {children}
    </Box>
  )
}

const StartSession = () => {
  return (
    <ConditionalWrapper>
      <Switch>
        <Route
          path="/start-session/new-client"
          component={EnrollClient}
          exact
        />
        <Route
          path="/start-session/dictate/new-client"
          component={() => <EnrollClient isDictation />}
          exact
        />
        <Route
          path="/start-session/select-client"
          component={SelectClient}
          exact
        />
        <Route
          path="/start-session/dictate/select-client"
          component={() => <SelectClient isDictation />}
          exact
        />
        <Redirect to="/sessions" />
      </Switch>
    </ConditionalWrapper>
  )
}

export default StartSession
