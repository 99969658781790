import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  HStack,
  Text,
  Box
} from '@chakra-ui/react'
import { Formik, Field, Form } from 'formik'
import { TextField, Button, Select } from '@blueprinthq/joy'
import { useStoreActions, useStoreState } from 'easy-peasy'

import * as Yup from 'yup'
import { PARTICIPANT_TYPE } from '../../constants'

const UpsertParticipantSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
      .email()
      .when('phoneNumber', {
        is: value => Boolean(value),
        then: schema => schema.optional(),
        otherwise: schema => schema.required('Email and/or Phone required')
      }),
    phoneNumber: Yup.string()
      .phone(['US', 'CA'], true, 'Invalid U.S. or Canadian phone number.')
      .when('email', {
        is: value => Boolean(value),
        then: schema => schema.optional(),
        otherwise: schema => schema.required('Phone and/or Email required')
      }),
    type: Yup.object()
      .shape({
        id: Yup.string(),
        displayName: Yup.string()
      })
      .required()
  },
  ['email', 'phoneNumber']
)

export const UpsertParticipantModal = ({
  participant,
  onClose,
  onSubmit,
  isMigration,
  ...rest
}) => {
  const modalHeaderText = isMigration
    ? 'Migrate to Participant'
    : participant
    ? 'Edit Participant'
    : 'Add Participant'

  return (
    <Modal onClose={onClose} preserveScrollBarGap {...rest}>
      <ModalOverlay />
      <ModalContent p="small">
        <ModalHeader>{modalHeaderText}</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            firstName:
              participant && participant.firstName ? participant.firstName : '',
            lastName:
              participant && participant.lastName ? participant.lastName : '',
            email: participant && participant.email ? participant.email : '',
            phoneNumber:
              participant && participant.phoneNumber
                ? participant.phoneNumber
                : '',
            type:
              participant && participant.type
                ? PARTICIPANT_TYPE[participant.type.toUpperCase()]
                : PARTICIPANT_TYPE.PARENT_GUARDIAN
          }}
          validationSchema={UpsertParticipantSchema}
          onSubmit={async (values, actions) => {
            if (onSubmit) {
              await onSubmit(values, participant)
            }
            actions.setSubmitting(false)
          }}
        >
          {({ errors, touched, isSubmitting, dirty }) => (
            <Form>
              <ModalBody>
                {isMigration && (
                  <Box bg="pale_gray" p="small" borderRadius="4px" mb="small">
                    <Text fontWeight="bold">
                      Migrating Client Contact to Participant
                    </Text>
                    <Text>
                      Client will lose access to the Blueprint app because they
                      are under 13, but the Participant will be able to complete
                      assessments using the web.
                    </Text>
                  </Box>
                )}
                <VStack spacing="medium" alignItems="flex-start">
                  <HStack>
                    <Field name="firstName">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="First Name"
                          isInvalid={errors.firstName && touched.firstName}
                          errorText={errors.firstName}
                          isRequired
                        />
                      )}
                    </Field>
                    <Field name="lastName">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Last Name"
                          isInvalid={errors.lastName && touched.lastName}
                          errorText={errors.lastName}
                          isRequired
                        />
                      )}
                    </Field>
                  </HStack>
                  <Field name="email">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Email Address"
                        isInvalid={errors.email && touched.email}
                        errorText={errors.email}
                      />
                    )}
                  </Field>
                  <Field name="phoneNumber">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Cell Phone"
                        isInvalid={errors.phoneNumber && touched.phoneNumber}
                        errorText={errors.phoneNumber}
                        type="tel"
                      />
                    )}
                  </Field>
                  <Field name="type">
                    {({ field, form }) => (
                      <Select
                        {...field}
                        onChange={value => form.setFieldValue('type', value)}
                        options={Object.values(PARTICIPANT_TYPE)}
                        valueKey="id"
                        labelKey="displayName"
                        label="Relationship to Client"
                        isInvalid={errors.type && touched.type}
                        errorText={errors.type}
                      />
                    )}
                  </Field>
                  <HStack w="100%">
                    <Button
                      onClick={onClose}
                      mt="xsmall"
                      size="lg"
                      variant="outline"
                      isFullWidth
                    >
                      Cancel
                    </Button>
                    <Button
                      isDisabled={!dirty}
                      mt="xsmall"
                      size="lg"
                      isLoading={isSubmitting}
                      type="submit"
                      isFullWidth
                    >
                      Save
                    </Button>
                  </HStack>
                </VStack>
              </ModalBody>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export const UpsertParticipantModalConnected = () => {
  const { isOpen, participant, onSubmit, isMigration } = useStoreState(
    state => state.modals.upsertParticipant
  )

  const handleCloseModal = useStoreActions(
    actions => actions.modals.upsertParticipant.closeModal
  )
  return (
    <UpsertParticipantModal
      isOpen={isOpen}
      isMigration={isMigration}
      participant={participant}
      onSubmit={onSubmit}
      onClose={handleCloseModal}
    />
  )
}
