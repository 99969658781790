import React from 'react'
import { Box } from '@chakra-ui/react'

export const Header = props => (
  <Box
    border="5px"
    sx={{
      textAlign: 'left',
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 700,
      letterSpacing: '1.5px',
      color: '#282828'
    }}
    {...props}
  >
    {props.children}
  </Box>
)

export const Cell = props => (
  <Box
    sx={{
      width: '100%',
      height: '48px',
      color: '#282828',
      fontWeight: 400,
      fontSize: '16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }}
    {...props}
  >
    {props.children}
  </Box>
)
