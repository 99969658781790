import React from 'react'
import { Button, Flex, Text } from '@chakra-ui/react'

const CancelSubscription = ({ onClick }: { onClick: () => void }) => {
  return (
    <Flex
      p="24px"
      borderRadius="8px"
      border="1px solid"
      borderColor="pale_gray"
      flexDirection="column"
      width="100%"
      justify-content="flex-start"
      align-items="flex-start"
    >
      <Text fontWeight="bold">Want to cancel your subscription?</Text>
      <Text>
        Your clinic's info and user accounts will be securely retained so your
        subscription can be reactivated in the future.
      </Text>
      <Text>
        <Button
          variant="link"
          color="#EB5164"
          textDecoration="none"
          onClick={onClick}
          m="0"
          _hover={{
            textDecoration: 'none'
          }}
          _focus={{
            outline: 'none'
          }}
        >
          Cancel your subscription
        </Button>
      </Text>
    </Flex>
  )
}

export default CancelSubscription
