export const createClient = ['create:own:activeclients:account']

// This permission set is mainly to differentiate "clinical" and "non-clinical" users.
export const readClients = [
  'read:own:activeclients:profile',
  'read:own:preenrollclients:profile',
  'read:clinic:prospectiveclients:profile'
]

export const readOutcomes = ['read:own:reports:outcomes']

export const readUtilization = ['read:clinic:reports:utilization']

export const readBilling = ['*:org:billing:*']
export const readEhrAppts = ['*:org:appointments:*']

export const readWorkflows = ['read:org:workflows:show']
export const readOrgSettings = ['read:org:settings:show']

export const superAdmin = ['*:any:*:*']
