export const AGE_RANGES = Object.freeze({
  child: {
    display: 'Child (<13 years old)',
    min: 0,
    max: 12
  },
  teen: {
    display: 'Teen (13-17 years old)',
    min: 13,
    max: 17
  },
  adult: {
    display: 'Adult (18-59 years old)',
    min: 18,
    max: 59
  },
  older_adult: {
    display: 'Older Adult (60+ years old)',
    min: 60,
    max: null
  }
})
