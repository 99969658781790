import React, { useEffect } from 'react'
import {
  Button,
  VStack,
  Stack,
  Flex,
  Text,
  Image,
  useDisclosure,
  useToast,
  UnorderedList,
  ListItem
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import { useExperienceManager } from '@hooks'
import ContentSuggestionCard from '@handlers/sessions/components/shared/assist/assessments/suggestion-card'
import AssistUpgradePanel from '@handlers/sessions/components/shared/assist/upgrade-panel'
import { Pagination } from '@handlers/sessions/components/shared/pagination'
import { AssignAssessmentsModal } from './assign-assessments-modal'
import { AssignWorksheetsModal } from '../shared/assist/worksheets/assign-worksheets-modal'
import CoolKidsBust from '@handlers/sessions/components/shared/assist/svgs/cool-kids-bust'
import {
  usePatientControllerAssistEnroll,
  useSessionControllerGetSuggestedContent,
  getSessionControllerGetSuggestedContentQueryKey,
  useSessionControllerGetContentExclusionReasons,
  useSessionControllerGetDemoSuggestedContent
} from '~/clinician-api'
import Waitlist from '../in-session-assist-waitlist-panel'
import { endpoints } from '@api'
import Setup from './setup'
import TreatmentPlanGate from './treatment-plan-gate'
import { useTreatmentPlan } from '@hooks'
import { SettingsOutlined } from '@components/icons'
import { buildAssessmentPreviewUrl, buildCheckInPreviewUrl } from '@utilities'
import {
  Patient,
  AssignableAssessment,
  AssignableWorksheet
} from '@handlers/sessions/types'
import { DisclaimerBanner } from '../shared/disclaimer-banner'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'

const SuggestionLoader = () => {
  return (
    <Stack spacing="0" gap="24px" flex="1">
      <VStack gap="16px" spacing="0" flex="1">
        <Flex justifyContent="center">
          <Image
            height="150"
            width="100"
            src="https://res.cloudinary.com/hellojoy/image/upload/v1723750496/Coffee_djkv99.gif"
            alt="Coffee"
          />
        </Flex>
        <VStack spacing="0" gap="4px">
          <Text fontWeight="bold">Generating suggestions...</Text>
          <Text color="dark_gray">This could take a minute</Text>
        </VStack>
      </VStack>
    </Stack>
  )
}

interface NoSuggestedContentProps {
  client: Patient
  toggleOverlay: () => void
  exclusionReasons: string[]
}

const NoSuggestedContent: React.FC<NoSuggestedContentProps> = ({
  client,
  toggleOverlay,
  exclusionReasons
}) => {
  return (
    <Stack spacing="0" gap="32px">
      <Stack spacing="0" gap="24px" flex="1">
        <VStack spacing="0" gap="16px" flex="1">
          <CoolKidsBust />
          <VStack spacing="0" gap="4px">
            <Text fontWeight="bold">No suggestions at this time</Text>
            <Text color="dark_gray">
              {exclusionReasons.length
                ? "We don't yet support the following:"
                : "We'll try again next session"}
            </Text>
          </VStack>
          {exclusionReasons.length && (
            <VStack spacing="0">
              <UnorderedList
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                {exclusionReasons.map((reason: string, index: number) => (
                  <ListItem color="dark_gray" key={index}>
                    {reason}
                  </ListItem>
                ))}
              </UnorderedList>
            </VStack>
          )}
        </VStack>
      </Stack>
      <VStack spacing="0">
        <Button
          variant="outline"
          h="35px"
          borderColor="pale_gray"
          borderRadius="4px"
          textDecoration="none"
          pl="8px"
          pr="8px"
          onClick={toggleOverlay}
        >
          <Flex align="center" justify="space-between" gap="8px">
            <SettingsOutlined />
            <Text>Assist setup for {client.first_name}</Text>
          </Flex>
        </Button>
      </VStack>
    </Stack>
  )
}

interface AssistPanelProps {
  sessionId: string
  client: Patient
  toggleOverlay: () => void
  account: any
  session: any
}

const AssistPanel = ({
  sessionId,
  client,
  toggleOverlay,
  account,
  session
}: AssistPanelProps) => {
  const [assessmentIdx, setAssessmentIdx] = React.useState<number>(0)
  const [worksheetIdx, setWorksheetIdx] = React.useState<number>(0)
  const [interventionIdx, setInterventionIdx] = React.useState<number>(0)
  const [
    selectedAssessment,
    setSelectedAssessment
  ] = React.useState<AssignableAssessment | null>(null)
  const [
    selectedWorksheet,
    setSelectedWorksheet
  ] = React.useState<AssignableWorksheet | null>(null)
  const [showDemoLoading, setShowDemoLoading] = React.useState<boolean>(true)

  const showInterventionSuggestions = flagsmith.hasFeature(
    FlagsmithFeatures.INTERVENTION_SUGGESTIONS
  )

  const toast = useToast()
  const queryClient = useQueryClient()
  const {
    initialSelectedFocuses,
    treatmentPlan,
    canGenerateQuery,
    generateMutation
  } = useTreatmentPlan()

  const {
    isOpen: isAssignAssessmentsModalOpen,
    onOpen: openAssignAssessmentsModal,
    onClose: closeAssignAssessmentsModal
  } = useDisclosure()

  const {
    isOpen: isAssignWorksheetsModalOpen,
    onOpen: openAssignWorksheetsModal,
    onClose: closeAssignWorksheetsModal
  } = useDisclosure()

  useEffect(() => {
    if (!isAssignAssessmentsModalOpen) {
      setSelectedAssessment(null)
    }
  }, [isAssignAssessmentsModalOpen])

  useEffect(() => {
    if (!isAssignWorksheetsModalOpen) {
      setSelectedWorksheet(null)
    }
  }, [isAssignWorksheetsModalOpen])

  const {
    data: suggestions,
    isLoading
  } = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      }
    }
  })

  const {
    isPlusPlanEnabled,
    isEvidenceBasedCareEnabled,
    isDemoClinician
  } = useExperienceManager()
  const showDemoExperience = client.is_demo && isDemoClinician

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId,
    { demoContext: 'post-session' },
    {
      query: {
        enabled: showDemoExperience
      }
    }
  )

  const {
    data: exclusionReasonsData
  } = useSessionControllerGetContentExclusionReasons<{
    exclusionReasons: string[]
  }>(sessionId)

  const {
    mutateAsync: enrollInAssist,
    isLoading: isEnrolling
  } = usePatientControllerAssistEnroll()

  const handleWorksheetEnroll = (values: any) => {
    if (selectedWorksheet) {
      enrollInAssist(
        {
          patientId: client.id,
          data: {
            email: values.email,
            phone: values.phone,
            assessments: [],
            checkIns: [
              {
                daysOfWeek: values.checkIn.daysOfWeek,
                timeOfDay: values.checkIn.timeOfDay,
                frequencyType: values.checkIn.frequency,
                suggestionId: selectedWorksheet.contentSuggestionId,
                checkInId: selectedWorksheet.checkInId
              }
            ]
          }
        },
        {
          onSuccess: () => {
            closeAssignWorksheetsModal()
            toast({
              title: 'Assigned!',
              status: 'success',
              isClosable: true,
              duration: 1200
            })
            const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
              sessionId
            )
            queryClient.invalidateQueries({ queryKey: suggestionListKey })
            queryClient.invalidateQueries([
              endpoints.getClinicianUserAccount.getCacheId(),
              client.id
            ])
          },
          onError: () => {
            toast({
              title: 'Error',
              description: 'There was an error assigning this measure',
              status: 'error',
              isClosable: true,
              duration: 2000
            })
          }
        }
      )
    }
  }

  useEffect(() => {
    if (showDemoExperience) {
      setTimeout(() => {
        setShowDemoLoading(false)
      }, 2000)
    } else {
      setShowDemoLoading(false)
    }
  }, [showDemoExperience])

  const exclusionReasons = exclusionReasonsData?.exclusionReasons || []
  const assessmentSuggestion = showDemoExperience
    ? demoData?.suggestedAssessments[assessmentIdx]
    : suggestions?.suggestedAssessments[assessmentIdx]
  const worksheetSuggestion = showDemoExperience
    ? demoData?.suggestedWorksheets[worksheetIdx]
    : suggestions?.suggestedWorksheets[worksheetIdx]
  const interventionSuggestion = showDemoExperience
    ? demoData?.suggestedInterventions[interventionIdx]
    : suggestions?.suggestedInterventions[interventionIdx]

  const worksheetCount = showDemoExperience
    ? demoData?.suggestedWorksheets?.length
    : suggestions?.suggestedWorksheets.length || 0
  const assessmentCount = showDemoExperience
    ? demoData?.suggestedAssessments?.length
    : suggestions?.suggestedAssessments.length || 0
  const interventionCount = showDemoExperience
    ? demoData?.suggestedInterventions?.length
    : suggestions?.suggestedInterventions.length || 0

  if (!isPlusPlanEnabled) {
    if (isEvidenceBasedCareEnabled) {
      return <Waitlist />
    }

    return <AssistUpgradePanel sessionId={session?.id} />
  }

  if (
    isLoading ||
    suggestions?.isGenerationComplete === false ||
    treatmentPlan?.isLoading ||
    showDemoLoading
  ) {
    return <SuggestionLoader />
  }

  if (!treatmentPlan || !!treatmentPlan?.fileKey) {
    return (
      <TreatmentPlanGate
        treatmentPlan={treatmentPlan}
        canGenerateQuery={canGenerateQuery}
        generateMutation={generateMutation}
      />
    )
  }

  const hasFocusOfTreatment = initialSelectedFocuses?.filter(f => f).length > 0
  const hasAgeRange = !!client.age_range
  const hasProviderType = !!account?.license_level
  const hasTreatmentApproach = !!session?.treatmentApproach
  const hasTreatmentSetting = !!account?.treatment_setting
  const hasHighRisk = !!client?.high_risk_timestamp

  if (
    !hasFocusOfTreatment ||
    !hasAgeRange ||
    !hasProviderType ||
    !hasTreatmentApproach ||
    !hasTreatmentSetting ||
    !hasHighRisk
  ) {
    return (
      <Setup
        toggleOverlay={toggleOverlay}
        client={client}
        hasFocusOfTreatment={hasFocusOfTreatment}
        hasAgeRange={!!client.age_range}
        hasProviderType={!!account?.license_level}
        hasTreatmentApproach={!!session?.treatmentApproach}
        hasTreatmentSetting={!!account?.treatment_setting}
        hasHighRisk={!!client?.high_risk_timestamp}
      />
    )
  }

  if (
    assessmentCount === 0 &&
    worksheetCount === 0 &&
    interventionCount === 0
  ) {
    return (
      <NoSuggestedContent
        client={client}
        toggleOverlay={toggleOverlay}
        exclusionReasons={exclusionReasons}
      />
    )
  }

  const onAssessmentAssigned = () => {
    const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
      sessionId
    )
    queryClient.invalidateQueries({ queryKey: suggestionListKey })
  }

  const onAssessmentAssignClick = () => {
    if (assessmentSuggestion) {
      setSelectedAssessment({
        clinicAssessmentId: assessmentSuggestion.clinicAssessmentId,
        cadenceValue: assessmentSuggestion.cadenceValue,
        cadenceUnit: assessmentSuggestion.cadenceUnit,
        contentSuggestionId: assessmentSuggestion.contentSuggestionId
      })
      openAssignAssessmentsModal()
    }
  }

  const onWorksheetAssignClick = () => {
    if (worksheetSuggestion) {
      setSelectedWorksheet({
        checkInId: worksheetSuggestion.checkInId,
        contentSuggestionId: worksheetSuggestion.contentSuggestionId
      })
      openAssignWorksheetsModal()
    }
  }

  const handleBrowseLibraryClick = () => {
    openAssignAssessmentsModal()
  }

  const handleBrowseWorksheetLibraryClick = () => {
    openAssignWorksheetsModal()
  }

  const handleSetWorksheet = (worksheet: any | null) => {
    setSelectedWorksheet(worksheet ? { checkInId: worksheet.id } : null)
  }

  const showDisclaimer =
    (assessmentSuggestion || worksheetSuggestion || interventionSuggestion) &&
    isPlusPlanEnabled

  return (
    <Stack spacing="0" gap="32px" flex="1">
      {showDisclaimer && <DisclaimerBanner />}
      {assessmentSuggestion && (
        <Stack spacing={2}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontWeight="bold">Assessments</Text>
            <Button
              variant="link"
              textDecoration="none"
              onClick={handleBrowseLibraryClick}
            >
              Browse library
            </Button>
          </Flex>
          <ContentSuggestionCard
            isDemo={showDemoExperience}
            type="assessment"
            suggestion={{
              ...assessmentSuggestion,
              name: assessmentSuggestion.assessmentName
            }}
            sessionId={sessionId}
            onAssign={onAssessmentAssignClick}
            previewUrl={buildAssessmentPreviewUrl(
              assessmentSuggestion.assessmentId
            )}
          />
          {assessmentCount && assessmentCount > 1 && (
            <Flex justifyContent="center">
              <Pagination
                current={assessmentIdx + 1}
                total={assessmentCount || 0}
                onNext={() => setAssessmentIdx(prev => prev + 1)}
                onPrev={() => setAssessmentIdx(prev => prev - 1)}
              />
            </Flex>
          )}
        </Stack>
      )}
      {worksheetSuggestion && (
        <Stack spacing={2}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontWeight="bold">Worksheets</Text>
            <Button
              variant="link"
              textDecoration="none"
              onClick={handleBrowseWorksheetLibraryClick}
            >
              Browse library
            </Button>
          </Flex>
          <ContentSuggestionCard
            isDemo={showDemoExperience}
            type="worksheet"
            suggestion={{
              ...worksheetSuggestion,
              name: worksheetSuggestion?.worksheetName
            }}
            sessionId={sessionId}
            onAssign={onWorksheetAssignClick}
            previewUrl={buildCheckInPreviewUrl(worksheetSuggestion.checkInId)}
          />
          {worksheetCount && worksheetCount > 1 && (
            <Flex justifyContent="center">
              <Pagination
                current={worksheetIdx + 1}
                total={worksheetCount || 0}
                onNext={() => setWorksheetIdx(prev => prev + 1)}
                onPrev={() => setWorksheetIdx(prev => prev - 1)}
              />
            </Flex>
          )}
        </Stack>
      )}
      {interventionSuggestion && showInterventionSuggestions && (
        <Stack spacing={2}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontWeight="bold">Interventions</Text>
            <Button variant="link" textDecoration="none" onClick={() => {}}>
              Browse library
            </Button>
          </Flex>
          <ContentSuggestionCard
            isDemo={true}
            type="intervention"
            suggestion={{
              ...interventionSuggestion,
              name: interventionSuggestion?.interventionName
            }}
            sessionId={sessionId}
            onAssign={() => {}}
            previewUrl={''}
          />
          {interventionCount && interventionCount > 1 && (
            <Flex justifyContent="center">
              <Pagination
                current={interventionIdx + 1}
                total={interventionCount || 0}
                onNext={() => setInterventionIdx(prev => prev + 1)}
                onPrev={() => setInterventionIdx(prev => prev - 1)}
              />
            </Flex>
          )}
        </Stack>
      )}
      <VStack spacing="0">
        <Button
          variant="outline"
          h="35px"
          borderColor="pale_gray"
          borderRadius="4px"
          textDecoration="none"
          pl="8px"
          pr="8px"
          onClick={toggleOverlay}
        >
          <Flex align="center" justify="space-between" gap="8px">
            <SettingsOutlined />
            <Text>Assist setup for {client.first_name}</Text>
          </Flex>
        </Button>
      </VStack>
      {isAssignAssessmentsModalOpen && (
        <AssignAssessmentsModal
          onSuccess={onAssessmentAssigned}
          client={client}
          isOpen={isAssignAssessmentsModalOpen}
          onClose={closeAssignAssessmentsModal}
          assessment={selectedAssessment}
          selectAssessment={setSelectedAssessment}
        />
      )}
      {isAssignWorksheetsModalOpen && (
        <AssignWorksheetsModal
          onEnroll={handleWorksheetEnroll}
          isEnrolling={isEnrolling}
          sessionId={sessionId}
          worksheet={selectedWorksheet}
          client={client}
          isOpen={isAssignWorksheetsModalOpen}
          onClose={closeAssignWorksheetsModal}
          setWorksheet={handleSetWorksheet}
        />
      )}
    </Stack>
  )
}

export default AssistPanel
