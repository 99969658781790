import React from 'react'
import { Box, Flex, Heading, Select, Text } from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { Select as JoySelect } from '@blueprinthq/joy'

import { Loading } from '../../../components'
import { ClinicName } from './clinic-name'
import { KioskUrl } from './kiosk-url'
import CoBranding from './co-branding'
import { usePermissions } from '../../../hooks'
import { useExperienceManager, useClinicSelectControl } from '@hooks'

export const GeneralTab = ({ clinics, selectedClinic, setSelectedClinic }) => {
  const { user } = useStoreState(state => state.auth)
  const { isEvidenceBasedCareEnabled } = useExperienceManager()
  const { hasPermission } = usePermissions()

  const isSuperAdmin = hasPermission('read:any:clinics:list')

  const HeaderText = () => {
    return (
      <Box mb={'medium'} w={'100%'}>
        <Heading mb="xxsmall">General</Heading>
        <Text>Manage your organizations settings</Text>
      </Box>
    )
  }

  const { clinicSelectEnabled } = useClinicSelectControl(clinics.length)

  return (
    <>
      <Box mb="xxlarge">
        <Box
          width={{
            base: '100%',
            sm: 'auto'
          }}
        >
          <Flex justify="space-between" alignItems="center">
            <Box w={'100%'}>
              <HeaderText boxWidth="100%" />
              {clinicSelectEnabled && (
                <Box mb="medium">
                  {isSuperAdmin ? (
                    <Select
                      value={selectedClinic.id}
                      style={{ border: '1px solid #E4E5E6' }}
                      _focus={{
                        outline: 'none'
                      }}
                      borderRadius="4px"
                      height="48px"
                      onChange={e =>
                        setSelectedClinic(
                          clinics.find(c => c.id === e.target.value)
                        )
                      }
                    >
                      {clinics.map(c => (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <JoySelect
                      value={selectedClinic}
                      options={clinics}
                      valueKey="id"
                      labelKey="name"
                      onChange={value => setSelectedClinic(value)}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
        <Box
          mb="medium"
          overflowX={{
            base: 'auto',
            md: 'initial'
          }}
        >
          {!user ? (
            <Loading />
          ) : (
            <ClinicName user={user} clinic={selectedClinic} />
          )}
        </Box>

        <Box
          mb="medium"
          overflowX={{
            base: 'auto',
            md: 'initial'
          }}
        >
          {' '}
          {!user ? (
            <Loading />
          ) : (
            isEvidenceBasedCareEnabled && (
              <KioskUrl user={user} clinic={selectedClinic} />
            )
          )}
        </Box>

        <Box>
          {isEvidenceBasedCareEnabled && (
            <CoBranding user={user} clinic={selectedClinic} />
          )}
        </Box>
      </Box>
    </>
  )
}
