import React from 'react'
import { FormControl, FormLabel, Skeleton, Select } from '@chakra-ui/react'

export interface SelectInputOption {
  value: string
  label: string
}

type SelectInputProps = {
  label?: string
  value?: string
  options: SelectInputOption[]
  isLoading: boolean
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
  placeholder?: string
}

export const SelectInput: React.FC<SelectInputProps> = ({
  label,
  value = '*',
  options,
  isLoading,
  onChange,
  disabled = false,
  placeholder = ''
}) => {
  return (
    <FormControl>
      <FormLabel fontWeight="bold">{label}</FormLabel>
      <Skeleton isLoaded={!isLoading}>
        <Select
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          sx={{ backgroundColor: 'white' }}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Skeleton>
    </FormControl>
  )
}
