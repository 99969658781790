import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { Flex, Box } from '@chakra-ui/react'
import { TrashIcon } from '@icons'
import { useTreatmentPlan } from '@hooks'
import Toolbar from '../Toolbar'
import { PencilIcon } from '../Icon'
import { Button } from '../Button'

const Actions: React.FC = () => {
  const { resetForm } = useFormikContext<any>()
  const {
    initialSelectedFocuses,
    initialSelectedDiagnoses,
    treatmentPlan,
    deleteMutation,
    acceptMutation,
    updateMutation,
    uploadMutation,
    regenerateMutation,
    client,
    onCopyAll,
    isEditing,
    setIsEditing,
    openDeleteConfirmationModal
  } = useTreatmentPlan()

  const isDeleting = deleteMutation.isLoading
  const isUpdating = updateMutation.isLoading
  const isAccepting = acceptMutation.isLoading

  const handleDiscardChanges = () => {
    setIsEditing(false)
    resetForm({
      values: {
        selectedDiagnoses: initialSelectedDiagnoses,
        selectedFocuses: initialSelectedFocuses,
        treatmentPlanText: treatmentPlan?.rawText
      }
    })
  }

  return (
    <Toolbar>
      <Flex justifyContent="flex-start" flex="1" gap="16px">
        {treatmentPlan?.acceptedAt ? (
          <>
            <Button
              variant="outline"
              onClick={handleDiscardChanges}
              isDisabled={isUpdating}
            >
              Discard Changes
            </Button>
            <Button
              colorScheme="primary"
              type="submit"
              isLoading={isUpdating}
            >
              Save & Update
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outline"
              onClick={openDeleteConfirmationModal}
              leftIcon={<TrashIcon fill="#EB5164" />}
            >
              Delete
            </Button>
            <Button
              colorScheme="primary"
              type="submit"
              isLoading={isAccepting}
            >
              Save & Accept
            </Button>
          </>
        )}
      </Flex>
    </Toolbar>
  )
}

export default Actions
