import React, { useMemo } from 'react'
import { GridItem, Flex, useToast } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useExperienceManager } from '@hooks'

import { EnrollmentOutcomes } from '@components'
import { generateCopy } from '@utilities'

export const SubmittedView = ({
  setIsSubmitted,
  formHelpers,
  pendingClientId
}) => {
  const history = useHistory()
  const toast = useToast()
  const { responseData } = useStoreState(state => state.enroll)
  const { firstName, dateOfInvite, nowOrLater } = responseData

  const { setSubmittedViewState } = useStoreActions(actions => actions.enroll)
  const { paywallData } = useExperienceManager()
  const { hasPayingSubscription, clientCount } = paywallData
  const disableEnrollment = !hasPayingSubscription && clientCount >= 5

  const viewProfileUrl = useMemo(() => {
    if (responseData.action_taken) {
      return `${
        responseData.action_taken.includes('PRE')
          ? '/pre-enroll-patient/'
          : '/patient/'
      }${responseData.patient_id}`
    }

    return ''
  }, [responseData])

  const { title, subtext, message } = useMemo(() => {
    return (
      generateCopy(responseData, firstName, {
        dateOfInvite: nowOrLater === 'now' ? null : dateOfInvite
      }) || {}
    )
  }, [responseData, firstName, dateOfInvite])

  return (
    <GridItem
      colSpan={{
        base: 4,
        sm: 6
      }}
      colStart={{
        sm: 2,
        md: 4
      }}
      mt="xxlarge"
      pt="xxlarge"
    >
      <Flex direction="column" align="center" justify="space-around">
        <EnrollmentOutcomes
          title={title || ''}
          subtext={subtext || ''}
          message={message || ''}
        />
        <Button
          isFullWidth
          mb="small"
          size="lg"
          onClick={() => {
            history.push(viewProfileUrl)
          }}
        >
          View Profile
        </Button>
        <Button
          variant="link"
          onClick={() => {
            if (disableEnrollment) {
              toast({
                title: 'Please upgrade your plan to enroll additional clients.',
                description:
                  "You've reached the 3 client limit on the Starter plan.",
                status: 'error',
                duration: 3000,
                isClosable: true
              })
            } else {
              formHelpers.resetForm()
              setIsSubmitted(false)
              setSubmittedViewState({ responseData: {} })
              return pendingClientId
                ? history.replace('/patients/pending')
                : null
            }
          }}
        >
          {pendingClientId
            ? 'Back to pending clients'
            : 'Enroll another client'}
        </Button>
      </Flex>
    </GridItem>
  )
}
