import React from 'react'
import { VStack, Text, Heading, Box, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { CircleCheck } from '../../../../components/icons'

export const ScheduleSuccess = () => {
  return (
    <VStack spacing="24px" marginTop="48px">
      <CircleCheck width="72px" height="72px" fill="#68E19F" />
      <Heading align="center" w="100%" mb="4px" size="lg">
        You're all set!
      </Heading>
      <Box w="100%">
        <Text w="100%" align="left" as="b">
          Need to reschedule or cancel?
        </Text>
        <Text w="100%" align="left">
          Check your email for a confirmation which will contain links to
          reschedule or cancel your meeting.
        </Text>
      </Box>
      <Box w="100%">
        <Text w="100%" align="left" as="b">
          End up here by mistake?
        </Text>
        <Text w="100%" align="left">
          Return to the{' '}
          <Link as={RouterLink} to="/signup" color="primary">
            beginning
          </Link>{' '}
          of our signup flow.
        </Text>
      </Box>
    </VStack>
  )
}
