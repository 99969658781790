import { trackEvent } from "@lib/clinician-tracking"
import React from "react"
import { ListItem, Text, HStack, Tag } from "@chakra-ui/react"

interface ClientListItemProps {
  firstName: string
  lastName: string
  clientId: string
  onSelect: (clientId: string) => void
  trackingLabel: string
  isDemo?: boolean
}

export const SelectClientDropdownItem = ({
  firstName,
  lastName,
  clientId,
  onSelect,
  trackingLabel,
  isDemo
}: ClientListItemProps) => {
  return (
    <ListItem
      px="16px"
      py="12px"
      height="48px"
      color="black"
      textAlign="left"
      _hover={{ bg: 'gray.100', cursor: 'pointer' }}
      onMouseDown={() => {
        trackEvent(`Session -> Selected Client - ${trackingLabel}`)
        onSelect(clientId)
      }}
    >
      <HStack spacing={2}>
        <Text fontSize="16px">{`${firstName} ${lastName}`}</Text>
        {isDemo && (
          <Tag
            size="sm"
            fontWeight="600"
            bg="#282828"
            color="white"
            fontSize="10px"
          >
            DEMO CLIENT
          </Tag>
        )}
      </HStack>
    </ListItem>
  )
}