import React, { useMemo } from 'react'
import { Flex, Table, Thead, Th, Tbody, Tr, Td, Text } from '@chakra-ui/react'
import { ArrowDownIcon, ArrowUpIcon } from '@blueprinthq/joy'
import { useTable, useSortBy } from 'react-table'

function getPointChangeColor(value, isOpposite) {
  if (!value) return 'black'

  if (isOpposite) {
    return value < 0 ? 'pink' : 'success'
  }

  return value < 0 ? 'success' : 'pink'
}

function getPointChangeText(value, isOpposite) {
  if (!value) return '-'

  if (isOpposite) {
    return value < 0 ? `↓ ${Math.abs(value)}` : `↑ ${Math.abs(value)}`
  }

  return value < 0 ? `↓ ${Math.abs(value)}` : `↑ ${Math.abs(value)}`
}

const PointChangeIndicator = ({ value, isOpposite }) => {
  return (
    <Text color={getPointChangeColor(value, isOpposite)}>
      {getPointChangeText(value, isOpposite)}
    </Text>
  )
}

export const AssessmentResultTable = ({ answers, isReverseValence }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Question',
        accessor: 'index',
        Cell: ({ row, value }) => {
          return `${value + 1}. ${row.original.key}`
        },
        style: {
          width: 500
        },
        id: 'index'
      },
      {
        Header: 'Answer',
        accessor: 'value',
        style: {
          width: 400
        }
      },
      {
        Header: 'Score',
        accessor: 'score',
        Cell: ({ row, value }) => {
          if (!row.original.isFreeText) {
            return `${value} / ${row.original.maxScore}`
          } else {
            return `-`
          }
        }
      },
      {
        Header: 'Since last',
        accessor: 'sinceLast',
        Cell: ({ row, value }) => {
          if (!row.original.isFreeText) {
            return (
              <PointChangeIndicator
                value={value}
                isOpposite={isReverseValence || row.original.isReverseValence}
              />
            )
          } else {
            return `-`
          }
        },
        style: {
          width: 100
        }
      },
      {
        Header: 'Since baseline',
        accessor: 'sinceBaseline',
        Cell: ({ row, value }) => {
          if (!row.original.isFreeText) {
            return (
              <PointChangeIndicator
                value={value}
                isOpposite={isReverseValence || row.original.isReverseValence}
              />
            )
          } else {
            return `-`
          }
        },
        style: {
          width: 100
        }
      }
    ],
    [answers]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data: answers,
      disableSortRemove: true,
      initialState: {
        sortBy: [{ id: 'index', desc: false }]
      }
    },
    useSortBy
  )

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup, i) => (
          <Tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => (
              <Th
                key={j}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                borderColor="light_gray"
                sx={column.style}
              >
                <Flex alignItems="center">
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownIcon size="sm" fill={'gray.600'} />
                      ) : (
                        <ArrowUpIcon size="sm" fill={'gray.600'} />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <Tr
              key={i}
              {...row.getRowProps()}
              bg={i % 2 === 0 ? 'transparent' : 'rgba(45, 84, 232, 0.04)'}
            >
              {row.cells.map((cell, j) => (
                <Td
                  key={j}
                  {...cell.getCellProps()}
                  borderColor="light_gray"
                  verticalAlign="middle"
                  pt="small"
                  pb="small"
                >
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}
