import { action } from 'easy-peasy'

export const patientProfileModal = {
  patientProfile: {
    activeTab: 0,
    setActiveTab: action((state, value) => {
      state.activeTab = value
    })
  }
}
