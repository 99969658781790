import React from 'react'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import cx from 'classnames'
import { InfoIcon, UpdateIcon } from '@blueprinthq/joy'
import { Flex, Button as ChakraButton } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import styles from './header-banner-styles'
import { isPatientMissingContactInfo } from '../../../../utilities'
import { Overdue } from '../../../../components/icons'
import { useExperienceManager } from '@hooks'

const useStyles = makeStyles(styles)

export const HeaderBanner = ({
  patient,
  activeAssessments,
  ignoreOverdue = false
}) => {
  const history = useHistory()
  const classes = useStyles()
  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  const openArchivePatientModal = useStoreActions(
    actions => actions.modals.archivePatient.openModal
  )

  if (!patient) return null

  const {
    is_archived,
    archive_date,
    archive_reason,
    has_overdue_assessments,
    first_name,
    medical_record,
    id
  } = patient

  const renderBannerWithAction = ({
    onClick,
    containerColor,
    copy,
    buttonText,
    isLink = false,
    isBold = true
  }) => {
    return (
      <div
        className={cx(
          classes.container,
          classes[containerColor],
          !isBold && classes.nonBold
        )}
      >
        <span>{copy}</span>
        {isLink ? (
          <ChakraButton
            variant="link"
            onClick={onClick}
            color="white"
            ml={2}
            _focus={{ outline: 'none' }}
            _active={{ background: 'transparent' }}
          >
            {buttonText}
          </ChakraButton>
        ) : (
          <Button
            onClick={onClick}
            className={classes.button}
            variant="contained"
          >
            {buttonText}
          </Button>
        )}
      </div>
    )
  }

  if (medical_record === 'demo') {
    return null
  }

  if (is_archived) {
    if (isEvidenceBasedCareEnabled) {
      return renderBannerWithAction({
        onClick: () => openArchivePatientModal({ patientId: id }),
        containerColor: 'black_container',
        copy: `Discharged on ${moment(archive_date).format(
          'l'
        )}. ${archive_reason}.`,
        buttonText: 'Unarchive'
      })
    } else {
      return renderBannerWithAction({
        onClick: () => openArchivePatientModal({ patientId: id }),
        containerColor: 'black_container',
        copy: `This client profile was archived on ${moment(
          archive_date
        ).format('l')}.`,
        buttonText: 'Unarchive',
        isLink: true,
        isBold: false
      })
    }
  }

  if (!isEvidenceBasedCareEnabled) {
    return null
  }

  if (!activeAssessments || !activeAssessments.length) {
    return renderBannerWithAction({
      onClick: () =>
        history.push(`/patient/${patient.id}/settings/outcome_measures`),
      containerColor: 'amber_container',
      copy: `${first_name} hasn't been assigned any assessments yet.`,
      buttonText: 'Assign Assessments'
    })
  }

  if (!ignoreOverdue && has_overdue_assessments) {
    return (
      <div className={cx(classes.header_container, classes.amber_container)}>
        <UpdateIcon fill={'white'} />
        <p
          style={{ marginLeft: '8px' }}
        >{`${first_name} has an overdue Assessment.`}</p>
        <Link
          className={classes.link_button}
          to={`/patient/${patient.id}/settings/outcome_measures`}
        >
          Manage
        </Link>
      </div>
    )
  }

  if (isPatientMissingContactInfo(patient)) {
    if (
      patient.assigneeUsers.length &&
      patient.assigneeUsers.some(au => au.type === 'participant')
    )
      return null

    return renderBannerWithAction({
      onClick: () => history.push(`/patient/${patient.id}/settings/account`),
      containerColor: 'red_container',
      copy: (
        <Flex sx={{ alignItems: 'center' }}>
          {' '}
          <InfoIcon fill={'white'} sx={{ marginRight: 15 }} /> We need a contact
          method or a participant for {patient.first_name}.{' '}
        </Flex>
      ),
      buttonText: 'Add contact info'
    })
  }

  return null
}
