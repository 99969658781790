import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { Loading } from '../../../components'
import { PatientListContainer } from '../PatientListContainer'
import { PatientsListHeader } from '../PatientsListHeader'
import { useToast, Container, GridItem } from '@chakra-ui/react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { endpoints } from '../../../api'
import { usePermissions } from '../../../hooks'
import { LayoutGrid } from '@blueprinthq/joy'
import { useExperienceManager } from '@hooks'

export default function PatientsBase(props) {
  const { user } = useStoreState(state => state.auth)
  const { selectedClinician, selectedClinic, page } = useStoreState(
    state => state.patientList
  )
  const { selectClinician, selectClinic, setPage } = useStoreActions(
    actions => actions.patientList
  )
  const allCliniciansOption = { id: 'all', display: 'All Clinicians' }
  const [canViewAllClinicians, setCanViewAllClinicians] = useState(false)

  useEffect(() => {
    if (!selectedClinic.id) {
      const initialClinic = user
        ? {
            id: user.clinic.id,
            display: user.clinic.name,
            display_id: user.clinic.display_id
          }
        : {}
      selectClinic(initialClinic)
    }
  }, [user])

  useEffect(() => {
    const shouldSeeAllClinicians =
      user &&
      (selectedClinic.id !== user.clinic.id ||
        !user.roles.some(r => r.name === 'clinician') ||
        !user.hasClients)
    const initialClinician = user
      ? {
          id: user.id,
          display: `${user.first_name} ${user.last_name}`,
          clinic_id: user.clinic.id
        }
      : {}
    if (selectedClinic.id && !selectedClinician.id) {
      selectClinician(
        shouldSeeAllClinicians ? allCliniciansOption : initialClinician
      )
    }
  }, [user, selectedClinic])

  const toast = useToast()

  const { mutateAsync: executeReinvite } = useMutation(
    endpoints.postReinviteToDownload.request,
    {
      onSuccess: () =>
        toast({
          description: 'Invitation was sent!',
          status: 'success',
          isClosable: true,
          duration: 2000
        }),
      onError: () =>
        toast({
          title: 'Error',
          description: 'Invitation was not sent.',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
    }
  )

  const { hasPermission } = usePermissions()

  const isSuperAdmin = hasPermission('read:any:activeclients:list')
  const isOrgAdmin = hasPermission('read:org:activeclients:list')

  const { data: allClinicList } = useQuery(
    [endpoints.getAllClinics.getCacheId()],
    () => endpoints.getAllClinics.request(),
    {
      initialData: { clinics: [] }, // because of how data is returned from this query
      select: data =>
        data &&
        data.clinics.map(c => ({
          id: c.id,
          display: c.name,
          display_id: c.display_id
        })),
      enabled: isSuperAdmin
    }
  )

  const { data: orgClinicList } = useQuery(
    [endpoints.getOrganizationClinics.getCacheId(), selectedClinic.display_id],
    () =>
      endpoints.getOrganizationClinics.request({
        organizationId: user.clinic.organization_id
      }),
    {
      initialData: [],
      select: data =>
        data.map(c => ({
          id: c.id,
          display: c.name,
          display_id: c.display_id
        })),
      enabled: isOrgAdmin && !isSuperAdmin
    }
  )

  const { isLoading: areCliniciansLoading, data: clinicianList } = useQuery(
    [endpoints.getClinicClinicians.getCacheId(), selectedClinic.display_id],
    () =>
      endpoints.getClinicClinicians.request({
        clinicDisplayId: encodeURIComponent(selectedClinic.display_id),
        showVisibleOnly: true
      }),
    {
      initialData: { clinicians: [] }, // because of how data is returned from this query
      select: data =>
        data.clinicians.map(c => ({
          id: c.id,
          clinic_id: c.clinic_id || null,
          display: `${c.first_name} ${c.last_name}`
        })),
      enabled: Boolean(selectedClinic.display_id)
    }
  )

  const { data: counts } = useQuery(
    [
      selectedClinic,
      selectedClinician.id === 'all' ? undefined : selectedClinician.id,
      endpoints.getPatientCounts.getCacheId()
    ],
    () =>
      endpoints.getPatientCounts.request({
        clinic_id: selectedClinic && selectedClinic.id,
        clinician_id:
          selectedClinician.id === 'all' ? undefined : selectedClinician.id
      })
  )

  const clinicList = useMemo(() => {
    return allClinicList.length ? allClinicList : orgClinicList
  }, [allClinicList, orgClinicList])

  const handleSelectClinic = clinic => {
    const currentClinic = clinicList.find(c => clinic.id === c.id)
    selectClinician({})
    selectClinic(currentClinic)
    setPage(1)
  }

  const sendPatientDownloadInvite = useCallback(async patientId => {
    await executeReinvite({ userId: patientId })
  }, [])

  const handleSelectClinician = clinician => {
    const currentClinician = clinicianList.find(c => {
      return clinician.id === c.id
    })

    selectClinician(currentClinician ? currentClinician : allCliniciansOption)
    setPage(1)
  }
  // page must be decremented once due to a discrempency between where react-table and matierial-ui start counting pages (0 vx 1)
  const updatePage = (event, page) => {
    setPage(page)
  }
  const { isAdmin } = useExperienceManager()

  useEffect(() => {
    if (user) {
      setCanViewAllClinicians(
        isAdmin
          ? true
          : !user.roles.some(r => r.name === 'supervisor_clinician')
      )
    }
  }, [user])

  return (
    <Container my="large" bg="#ffffff" h="auto">
      <LayoutGrid>
        <GridItem colSpan={12}>
          <PatientsListHeader
            counts={counts}
            allCliniciansOption={allCliniciansOption}
            currentClinician={selectedClinician}
            currentClinic={selectedClinic}
            handleSelectClinician={handleSelectClinician}
            handleSelectClinic={handleSelectClinic}
            clinicList={clinicList}
            clinicianList={clinicianList}
            canViewAllClinicians={canViewAllClinicians}
            {...props}
          />
          {areCliniciansLoading ? (
            <div style={{ height: '50vh' }}>
              <Loading />
            </div>
          ) : (
            <PatientListContainer
              currentClinician={selectedClinician}
              currentClinic={selectedClinic}
              sendInvite={sendPatientDownloadInvite}
              currentPage={page}
              updatePage={updatePage}
              styles={props.styles}
            />
          )}
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
