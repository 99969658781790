import React from 'react'

let PatientPreferencesMenuWrapper = () => null
if (process.env.REACT_APP_THEME === 'tempus') {
  import('./tempus/wrapper').then(
    ({ default: patientPreferencesMenuWrapper }) =>
      (PatientPreferencesMenuWrapper = patientPreferencesMenuWrapper)
  )
} else {
  import('./blueprint/wrapper').then(
    ({ default: patientPreferencesMenuWrapper }) =>
      (PatientPreferencesMenuWrapper = patientPreferencesMenuWrapper)
  )
}

export function PatientPreferencesMenu(props) {
  return <PatientPreferencesMenuWrapper {...props} />
}
