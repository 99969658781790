import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

const styles = {
  subMenuItem: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}

class SubMenuItem extends React.Component {
  state = {
    menuOpen: false
  }

  handleClick = () => {
    if (this.state.menuOpen) {
      this.setState({ menuOpen: false })
    } else {
      this.setState({ menuOpen: true })
    }
  }

  render() {
    const { title, menuItems, classes } = this.props
    return (
      <>
        <MenuItem
          onClick={this.handleClick}
          className={classNames(classes.subMenuItem)}
          id="submenu-header"
        >
          {title}
          {this.state.menuOpen ? <ArrowDownIcon /> : <ArrowRightIcon />}
        </MenuItem>
        {this.state.menuOpen &&
          menuItems.map(item => {
            return (
              <MenuItem
                key={item.key}
                onClick={item.onClick}
                id={`submenu-item-${item.key}`}
              >
                &nbsp;&nbsp;&nbsp; {item.display}
              </MenuItem>
            )
          })}
      </>
    )
  }
}

export default withStyles(styles)(SubMenuItem)
