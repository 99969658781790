import React, { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Box, Flex, Tooltip, Text } from '@chakra-ui/react'
import { HighRiskIcon } from '@blueprinthq/joy'

import { endpoints } from '@api'

HighRiskToggle.defaultProps = {
  isInert: false
}

export function HighRiskToggle({ clientId, isHighRisk, isInert }) {
  const queryClient = useQueryClient()

  const { mutate: toggleIsHighRisk, isLoading } = useMutation(
    endpoints.patchClientHighRisk.request,
    {
      // optimistic
      onMutate: async updatedHighRisk => {
        const accountQueryId = [
          endpoints.getClinicianUserAccount.getCacheId(),
          clientId
        ]
        await queryClient.cancelQueries(accountQueryId)
        const prev = queryClient.getQueryData(accountQueryId)
        queryClient.setQueryData(accountQueryId, old => ({
          ...old,
          is_high_risk: updatedHighRisk.data.isHighRisk
        }))

        return { prev }
      },
      // revert optimistic
      onError: (err, x, context) => {
        queryClient.setQueryData(
          endpoints.getClinicianUserAccount.getCacheId(),
          context.prev
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getInterventionRecommendations.getCacheId()
        )
      }
    }
  )

  const memoizedToggle = useCallback(() => {
    if (isLoading) return
    toggleIsHighRisk({
      clientId: clientId,
      data: {
        isHighRisk: !isHighRisk
      }
    })
  }, [isLoading, toggleIsHighRisk, isHighRisk])

  return (
    <Tooltip
      closeOnClick={false}
      placement="bottom-start"
      label={
        isInert ? null : isHighRisk ? 'Remove High-Risk' : 'Mark as High-Risk'
      }
    >
      <Flex
        ml={{ base: 0, sm: 'xsmall' }}
        width={'min-content'}
        direction="row"
        alignItems="center"
        cursor={isInert ? 'default' : 'pointer'}
        height="24px"
        borderRadius="12px"
        backgroundColor={isHighRisk ? 'error' : 'pale_gray'}
        transition={
          isHighRisk ? 'background-color 1s' : 'background-color 0.8s ease 0.5s'
        }
        onClick={isInert ? null : memoizedToggle}
      >
        <Box
          width="auto"
          maxWidth={isHighRisk ? '200px' : '0'}
          transition={isHighRisk ? 'max-width 1s' : 'max-width 0.5s'}
          whiteSpace="nowrap"
        >
          <Text fontWeight={400} fontSize={12} color="white" ml="12px" mr="9px">
            High-Risk
          </Text>
        </Box>
        <HighRiskIcon
          aria-label="Toggle High Risk"
          size="md"
          transition={isHighRisk ? 'fill 1s' : 'fill 0.8s ease 0.5s'}
          fill={isHighRisk ? 'error' : 'pale_gray'}
        />
      </Flex>
    </Tooltip>
  )
}
