import React from 'react'
import { Box, HStack, Text, VStack, Flex, Switch } from '@chakra-ui/react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const ReminderSection = ({
  title,
  subtitle,
  messageType,
  switchesEnabled,
  clientFirstName,
  account,
  commPrefsData,
  children
}) => {
  const resubscribePhoneNumber = parsePhoneNumberFromString(
    process.env.REACT_APP_TWILIO_PHONE_NUMBER
  ).formatNational()

  return (
    <Box w="100%" bg="white" rounded="lg">
      <Box pb={4}>
        <Text fontWeight="bold">{title}</Text>
        <Text>{subtitle}</Text>
      </Box>
      {switchesEnabled && (
        <Box>
          <VStack pb={2} spacing={0}>
            <Flex pt={2} w="100%" justify="space-between">
              <Text>Push Notification</Text>
              <HStack spacing="small">
                {!commPrefsData.isPushSubscribed && (
                  <Text color="medium_gray">Unsubscribed</Text>
                )}
                <Switch
                  isDisabled
                  size="lg"
                  isChecked={commPrefsData.push.includes(messageType)}
                />
              </HStack>
            </Flex>
            {commPrefsData.push.includes(messageType) &&
              !commPrefsData.isPushSubscribed && (
                <Text w="100%" color="dark_gray">
                  {`Ensure ${clientFirstName} has Blueprint notifications enabled in their phone's settings.`}
                </Text>
              )}
          </VStack>
          <VStack spacing={0}>
            <Flex
              w="100%"
              py={2}
              borderTop="1px solid"
              borderTopColor="light_gray"
              justify="space-between"
            >
              <Text>Email</Text>
              <HStack spacing="small">
                {!commPrefsData.isEmailSubscribed && (
                  <Text color="medium_gray">Unsubscribed</Text>
                )}
                <Switch
                  isDisabled
                  size="lg"
                  isChecked={commPrefsData.email.includes(messageType)}
                />
              </HStack>
            </Flex>
          </VStack>
          <VStack spacing={0} borderTop="1px solid" borderColor="light_gray">
            <Flex
              pt={2}
              pb={commPrefsData.isSmsSubscribed ? 2 : 0}
              w="100%"
              justify="space-between"
            >
              <Text>Text Message</Text>
              <HStack spacing="small">
                {(!Boolean(account.phone_number) && (
                  <Text color="medium_gray">No phone on file</Text>
                )) ||
                  (!commPrefsData.isSmsSubscribed && (
                    <Text color="medium_gray">Unsubscribed</Text>
                  ))}
                <Switch
                  isDisabled
                  size="lg"
                  isChecked={commPrefsData.sms.includes(messageType)}
                />
              </HStack>
            </Flex>
            {!commPrefsData.isSmsSubscribed && (
              <Text pb={2} w="100%" color="dark_gray">
                {`To resubscribe, have ${clientFirstName} text “START” to ${resubscribePhoneNumber}.`}
              </Text>
            )}
          </VStack>
        </Box>
      )}
      {children}
    </Box>
  )
}
