import React from 'react'
import { Box, Tooltip } from '@chakra-ui/react'
import { InfoIcon } from '@blueprinthq/joy'

import { ClientInfo } from './client-info'
import { Participants } from './participants'
import { Clinician } from './clinician'
import { Invite } from './invite'
import { Assessments } from './assessments'
import { SymptomTrackers } from './symptom-trackers'
import { Worksheets } from './worksheets'
import { SubmittedView } from './submitted-view'
import { Clinic } from './clinic'

const ParticipantsTooltip = ({ firstName }) => {
  return (
    <Box as="span" display="inline-block" ml="xsmall">
      <Tooltip
        maxWidth="200px"
        placement="bottom-start"
        label={`People who can complete assessments on behalf of ${firstName ||
          'the client'}.`}
        hasArrow
        shouldWrapChildren
      >
        <InfoIcon h="20px" w="20px" />
      </Tooltip>
    </Box>
  )
}

const EnrollmentFormTooltip = ({ label }) => {
  return (
    <Box as="span" display="inline-block" ml="xsmall">
      <Tooltip
        maxWidth="200px"
        placement="bottom-start"
        label={label}
        hasArrow
        shouldWrapChildren
      >
        <InfoIcon h="20px" w="20px" />
      </Tooltip>
    </Box>
  )
}

const AtLeastOneAssessmentTooltip = ({ showTooltip, children }) => {
  if (!showTooltip) return <>{children}</>

  return (
    <Tooltip
      maxWidth="200px"
      placement="bottom-start"
      label={'At least 1 assessment is required'}
      hasArrow
      shouldWrapChildren
    >
      {children}
    </Tooltip>
  )
}

const InSessionOnlyTooltip = ({ children, firstName, showTooltip }) => {
  if (!showTooltip) return <>{children}</>

  return (
    <Tooltip
      maxWidth="200px"
      placement="bottom-start"
      label={`Since ${firstName ||
        'the client'} is a child and does not have an email or phone number on file, they’re limited to taking assessments in session only.`}
      hasArrow
      shouldWrapChildren
    >
      {children}
    </Tooltip>
  )
}

const CannotToggleClientTypeTooltip = ({ children, showTooltip }) => {
  if (!showTooltip) return <>{children}</>

  return (
    <Tooltip
      maxWidth="200px"
      placement="bottom-start"
      label="Cannot change client type with current date of birth."
      hasArrow
      closeOnClick={false}
    >
      {children}
    </Tooltip>
  )
}

export {
  ClientInfo,
  Participants,
  ParticipantsTooltip,
  EnrollmentFormTooltip,
  Clinician,
  Invite,
  Assessments,
  SymptomTrackers,
  Worksheets,
  SubmittedView,
  InSessionOnlyTooltip,
  AtLeastOneAssessmentTooltip,
  CannotToggleClientTypeTooltip,
  Clinic
}
