const styles = theme => ({
  tableContainer: {
    marginTop: 24,
    border: '1px solid',
    borderColor: '#E4E5E6',
    borderRadius: 4
  },
  table: {
    boxShadow: 'none',
    border: 0
  },
  cell: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down(414)]: {
      fontSize: 14
    }
  },
  black: {
    color: '#4a4a4a',
    fontWeight: 500
  },
  red: {
    color: '#DB1544',
    fontWeight: 300
  },
  centerAlign: {
    justifyContent: 'center'
  },
  chevron: {
    padding: '0 0'
  },
  multiline: {
    height: 'initial',
    padding: '4px 0'
  },
  empty_state: {
    paddingBottom: '40px',
    textAlign: 'center',
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
    padding: '0 20px',
    boxSizing: 'border-box',

    '&:hover #img': {
      WebkitFilter: 'grayscale(0%)',
      filter: 'grayscale(0%)'
    }
  },
  empty_state_text: {
    margin: '40px 0 20px',
    fontSize: '18px',
    fontWeight: 300
  },
  empty_state_image: {
    maxWidth: '265px',
    flex: '0 0',
    marginTop: '100px',
    WebkitFilter: 'grayscale(100%)',
    filter: 'grayscale(100%)',
    transition: 'all 180ms ease-in',
    marginLeft: 'auto',
    marginRight: 'auto',

    '&:hover': {
      WebkitFilter: 'grayscale(0%)',
      filter: 'grayscale(0%)'
    }
  },
  empty_state_button: {
    margin: '100px'
  },
  empty_state_button_container: {
    textDecoration: 'none !important',
    width: '100%'
  },
  tableHeader: {
    textAlign: 'left',
    whiteSpace: 'pre-line !important',
    fontSize: '14px',
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    fontWeight: 500,
    wordWrap: 'break-word',
    boxShadow: 'none !important',

    [theme.breakpoints.down(414)]: {
      fontSize: '12px',
      letterSpacing: '0',
      wordWrap: 'break-word'
    }
  },
  pagination: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '40px 0'
  },
  overdue_cell: {
    [theme.breakpoints.down(414)]: {
      paddingLeft: '10px'
    }
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
})

export default styles
