import React from 'react'
import FileUploadIcon from '../../../components/icons/upload'
import { LayoutGrid, Hidden } from '@blueprinthq/joy'
import { GridItem, Text, Box, Button, Avatar } from '@chakra-ui/react'
import AvatarEditor from '@containers/avatar-editor'

const AvatarNotification = ({ user }) => (
  <Box
    shadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
    p="32px 40px"
    mb="large"
    w="100%"
    borderRadius="8px"
  >
    <LayoutGrid templateColumns="repeat(12, 1fr)">
      <GridItem
        colSpan={{
          base: 12,
          lg: 9
        }}
      >
        <Text fontWeight="bold" fontSize="lg">
          Upload your photo
        </Text>
        <Text as="h2" fontSize="md" mb="small">
          Add a photo so your clients can identify you.
        </Text>
        <AvatarEditor user={user}>
          {({ onAddFile }) => (
            <Button
              leftIcon={<FileUploadIcon fill="black" />}
              onClick={onAddFile}
              variant="outline"
            >
              Upload Photo
            </Button>
          )}
        </AvatarEditor>
      </GridItem>
      <Hidden sm md>
        <GridItem
          colSpan={{
            base: 3,
            lg: 3
          }}
        >
          <Avatar
            name={`${user.first_name} ${user.last_name}`}
            bg="pale_gray"
            size="2xl"
            padding="0px"
            paddingInline="0px"
            fontWeight="bold"
          />
        </GridItem>
      </Hidden>
    </LayoutGrid>
  </Box>
)

export default AvatarNotification
