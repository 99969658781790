import React, { useState, useEffect } from 'react'
import { VStack, HStack, Box, Text, Heading } from '@chakra-ui/react'
import { Formik, Field, Form } from 'formik'
import { Button, TextField } from '@blueprinthq/joy'
import * as Yup from 'yup'
import Cookies from 'js-cookie'
import { useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

export const Schedule = ({ storeDemoFormData, isError }) => {
  const [signupError, setSignupError] = useState(null)
  const { persona } = useStoreState(state => state.clinicianSignup)
  const history = useHistory()

  //CHILI PIPER CONFIG
  const cpTenantDomain = 'blueprint-health'
  const cpRouterName = 'Inbound_GetStarted'

  useEffect(() => {
    if (!persona || isEmpty(persona)) {
      history.push('/signup')
    }

    const head = document.querySelector('head')
    const script = document.createElement('script')
    const url = 'https://js.chilipiper.com/marketing.js'

    script.setAttribute('src', url)
    head.appendChild(script)

    return () => {
      head.removeChild(script)
    }
  })

  return (
    <Formik
      style={{ width: '100%' }}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        organizationName: '',
        number_of_clinicians: '',
        valueDetails: ''
      }}
      onSubmit={async (values, actions) => {
        const hubspotutk = Cookies.get('hubspotutk')
        const referralId = Cookies.get('bp_referral') ?? ''
        values.hubspotutk = hubspotutk
        values.referralId = referralId
        values.pageUri = window.location.href

        actions.setSubmitting(false)
        const data = {
          ...values,
          ...persona
        }
        await storeDemoFormData(data)

        window.ChiliPiper.submit(cpTenantDomain, cpRouterName, {
          map: true,
          lead: values,
          onSuccess: history.push('/signup/schedule-success')
        })
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email')
          .required('Email is required'),
        phone: Yup.string()
          .phone(['US', 'CA'], true, 'Invalid phone number')
          .required('Phone is required'),
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        organizationName: Yup.string().required(
          'Organization name is required'
        ),
        number_of_clinicians: Yup.string().required(
          'Number of clinicians is required'
        )
      })}
      validate={() => setSignupError(null)}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form style={{ width: '100%' }}>
          <VStack gap={'small'} mt={'0px'}>
            <Box w="100%" pb="small">
              <Heading w="100%" mb="4px" size="lg">
                Get started for free
              </Heading>
              <Text>
                Drop your details below to schedule a time to get access to the
                Blueprint platform
              </Text>
            </Box>
            <HStack
              w="100%"
              gap="16px"
              style={{ marginTop: 0 }}
              pb={
                ((errors.firstName && touched.firstName) ||
                  (errors.lastName && touched.lastName)) &&
                'small'
              }
            >
              <Box w="100%">
                <Field name="firstName">
                  {({ field }) => (
                    <TextField
                      {...field}
                      w="100%"
                      isRequired
                      label="First Name"
                      isInvalid={errors.firstName && touched.firstName}
                      errorText={errors.firstName}
                    />
                  )}
                </Field>
              </Box>
              <Box
                style={{ marginInlineStart: 0, WebkitMarginStart: 0 }}
                w="100%"
              >
                <Field name="lastName">
                  {({ field }) => (
                    <TextField
                      {...field}
                      w="100%"
                      isRequired
                      label="Last Name"
                      isInvalid={errors.lastName && touched.lastName}
                      errorText={errors.lastName}
                    />
                  )}
                </Field>
              </Box>
            </HStack>
            <HStack
              w="100%"
              gap={'16px'}
              style={{ marginTop: 0 }}
              pb={
                ((errors.email && touched.email) ||
                  (errors.phone && touched.phone)) &&
                'small'
              }
            >
              <Box w="100%">
                <Field name="email">
                  {({ field }) => (
                    <TextField
                      {...field}
                      w="100%"
                      isRequired
                      label="Email address"
                      isInvalid={errors.email && touched.email}
                      errorText={errors.email}
                    />
                  )}
                </Field>
              </Box>
              <Box
                style={{ marginInlineStart: 0, WebkitMarginStart: 0 }}
                w="100%"
              >
                <Field name="phone">
                  {({ field }) => (
                    <TextField
                      {...field}
                      w="100%"
                      isRequired
                      label="Phone number"
                      leftIcon={'+1'}
                      isInvalid={errors.phone && touched.phone}
                      errorText={errors.phone}
                      type="tel"
                    />
                  )}
                </Field>
              </Box>
            </HStack>
            <Box
              w="100%"
              style={{ marginTop: 0 }}
              pb={
                errors.organizationName && touched.organizationName && 'small'
              }
            >
              <Field name="organizationName">
                {({ field }) => (
                  <TextField
                    {...field}
                    w="100%"
                    isRequired
                    label="Organization name"
                    isInvalid={
                      errors.organizationName && touched.organizationName
                    }
                    errorText={errors.organizationName}
                  />
                )}
              </Field>
            </Box>
            <Box
              w="100%"
              style={{ marginTop: 0 }}
              pb={
                errors.number_of_clinicians &&
                touched.number_of_clinicians &&
                'small'
              }
            >
              <Field name="number_of_clinicians">
                {({ field }) => (
                  <TextField
                    {...field}
                    w="100%"
                    isRequired
                    label="Number of clinicians in your organization"
                    type="number"
                    isInvalid={
                      errors.number_of_clinicians &&
                      touched.number_of_clinicians
                    }
                    errorText={errors.number_of_clinicians}
                  />
                )}
              </Field>
            </Box>
            <Box
              w="100%"
              style={{ marginTop: 0 }}
              pb={errors.valueDetails && touched.valueDetails && 'small'}
            >
              <Field name="valueDetails">
                {({ field }) => (
                  <TextField
                    {...field}
                    w="100%"
                    isRequired
                    label="What are you hoping to gain from using Blueprint?"
                    isInvalid={errors.valueDetails && touched.valueDetails}
                    errorText={errors.valueDetails}
                  />
                )}
              </Field>
            </Box>
            {signupError && <Text color="error">{signupError}</Text>}
            <Box style={{ marginTop: 0 }} w="100%">
              {isError && (
                <Box mb="xsmall">
                  <Text color="severe">
                    Oops, something went wrong. Please refresh the page and try
                    again.
                  </Text>
                </Box>
              )}
              <Button
                size="lg"
                isFullWidth
                isLoading={isSubmitting}
                type="submit"
              >
                Next
              </Button>
            </Box>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}
